import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { Observable } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
@Component({
  selector: 'formly-autocomplete',
  templateUrl: './formly-autocomplete.component.html',
  styleUrls: ['./formly-autocomplete.component.scss']
})
export class FormlyAutocompleteComponent  extends FieldType{
  _formControl = new FormControl();
  filter:Observable<any>;
  ngModule:any;
  selectedItem = false;
  private onChange:any;
  ngOnInit(){

    this.formControl.valueChanges
    .pipe(filter(value=>!value && this.ngModule != (value || "")))
    .subscribe(value=>{
      this._formControl.setValue("",{emitEvent:false});
      this.onChange instanceof Function && this.onChange();
    });

    this.filter = this._formControl.valueChanges
    .pipe(
      startWith(''),
      filter(()=>this.to.filter instanceof Function),
      map((value)=>{
        if(this.ngModule != value && value instanceof Object){
          this.formControl.setValue(value);
          this.onChange instanceof Function && this.onChange(value);
        }

        return value;
      }),
      filter(item=>!(item instanceof Object)),
      map(value=>{
        if(!value && this.selectedItem){
          this.onChange instanceof Function && this.onChange();
          this.selectedItem = false;
          this.formControl.setValue(value);
        }
        return value;
      }),
      switchMap(term => this.props.filter(term))
    );

    if(this.props.onChange)
      this.onChange = this.props.onChange;
  }

  clear(){
    this.ngModule = "";
    this.formControl.setValue("");
    this.onChange instanceof Function && this.onChange();
  }

  displayWith(option:any) {
    return (option || {name:""}).name || "";
  }
}
