<app-loader></app-loader>

<ng-container *ngIf="isAuth || getNotification">
  <app-health-check-notification
    #appHealthCheckNotification
  ></app-health-check-notification>
</ng-container>

<mat-sidenav-container autosize>
  <mat-sidenav
    #drawer
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)"
    *ngIf="isAuth && !this.account.menu[0]?.icon"
  >
    <mat-toolbar>
      <img class="logotipo" src="assets/img/icone_tkg.png" alt="Logotipo" />
    </mat-toolbar>
    <mat-nav-list>
      <a
        role="listitem"
        *ngFor="let item of account?.menu"
        mat-list-item
        [routerLink]="isWeb ? item.resource.route : isHandset ? item.resource.route_handset : item.resource.route_mobile"
        routerLinkActive
        #rlg="routerLinkActive"
        [matTooltip]="item.name"
        matTooltipShowDelay="800"
        matTooltipPosition="right"
        matTooltipClass="tooltip"
        style="padding-left: 9px; padding-right: 9px"
      >
        <i
          style="min-width: 24px"
          class="material-icons {{
            rlg.isActive ? 'active-list-item' : 'icon_cor_original'
          }}"
        >
          {{ item.resource.icon }}
        </i>
      </a>
      <!-- <a
        *ngIf="
          this.account.system_client.resource.environment?.showPainelStatusMenu
        "
        mat-list-item
        (click)="modalPainelStatus()"
        matTooltip="Painel de Status"
        matTooltipPosition="right"
        matTooltipClass="tooltip"
      >
        <i class="material-icons icon_cor_original"> tv </i>
      </a> -->
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav
    #drawer
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)"
    *ngIf="isAuth && this.account.menu[0]?.icon"
    [ngClass]="expanded.open ? 'bigSidebar' : 'smallSidebar'"
    style="background-color: #171717"
  >
    <mat-toolbar style="max-width: 59px">
      <img class="logotipo" src="assets/img/icone_tkg.png" alt="Logotipo" />
    </mat-toolbar>

    <mat-nav-list>
      <a
        role="listitem"
        *ngFor="let item of account?.menu"
        mat-list-item
        [matTooltip]="(item.translation_name) ? (item.translation_name | translate) : item.title"
        matTooltipPosition="right"
        matTooltipClass="tooltip"
        class="hover-primary"
        style="max-width: 56px"
      >
        <i
          style="min-width: 24px"
          class="material-icons hover-primary  {{
            item.active ? 'active-list-item' : 'icon_cor_original'
          }}"
          (click)="onExpended(item)"
        >
          {{ item.icon }}
        </i>
      </a>
      <!-- <a
        *ngIf="
          this.account.system_client.resource.environment?.showPainelStatusMenu
        "
        mat-list-item
        (click)="modalPainelStatus()"
        matTooltip="Painel de Status"
        matTooltipPosition="right"
        matTooltipClass="tooltip"
      >
        <i class="material-icons icon_cor_original"> tv </i>
      </a> -->
    </mat-nav-list>
    <div
      *ngIf="this.expanded.open"
      style="background-color: #171717; position: absolute; top: 0; left: 59px"
    >
      <div class="openNav">
        <div style="display: flex; align-items: center">
          <p class="titleMenu">{{ this.expanded.title }}</p>
          <div class="buttonClose" (click)="onCloseExpended(drawer)">
            <i
              class="material-icons icon_cor_original"
              style="
                color: #171717;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              chevron_left
            </i>
          </div>
        </div>
        <div class="linkMenu" *ngFor="let menu of expanded.route">
          <a
            (click)="onCloseExpended(drawer)"
            mat-list-item
            [routerLink]="isWeb ? menu.resource.route : isHandset ? menu.resource.route_handset : menu.resource.route_mobile"
            routerLinkActive
            #rlg="routerLinkActive"
            class=" {{
              rlg.isActive ? 'active-list-item' : 'icon_cor_original'
            }}"
          >
            {{ (menu.resource.translation_name) ?
            (menu.resource.translation_name | translate) : menu.name }}
          </a>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <div>
    <mat-toolbar
      [ngClass]="shToolbar"
      [ngStyle]="{ 'background-color': colorToolbar }"
      class="bar-element mat-elevation-z2"
    >
      <div class="nav-bar">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div class="app-header-title" *ngIf="!searchShow">
          <span>
            <img
              [src]="account?.system_client.resource.logo_path"
              class="espaco_logo"
              alt="Logotipo"
              *ngIf="!isHandset"
            />
          </span>
          <span class="font_titulo" [ngClass]="fontClass"
            >{{ !isHandset ? ' | ' + appTitle : (appTitle.length > 9 &&
            isHandset && showTitle) ? appTitle.substring(0, 10) + '...' :
            appTitle }}</span
          >
        </div>

        <!-- <button
          [ngClass]="arrowClass"
          mat-icon-button
          (click)="toggleTitle()"
          *ngIf="isHandset"
        >
          <mat-icon aria-label="Refresh">{{ arrowClass }}</mat-icon>
        </button> -->
        <app-search
          scope="global"
          class="app-search"
          *ngIf="displaySearch && showTitle"
        ></app-search>
        <div [ngClass]="{ 'toolbar-reverse': customToolbar?.reverse }">
          <!-- Custom toolbar -->
          <ng-container *ngTemplateOutlet="customToolbar?.template || null">
          </ng-container>

          <!-- buttons -->
          <div *ngIf="!searchShow && showTitle">
            <!--
            Adicionar todos os botões da barra superior. Sua visibilidade vai depender
            de configuração específica no *-routing-modules.ts de cada módulo e
            da existência dos apelidos dos referidos botões na chave environment.top_bar_buttons
            dentro do campo resource, da tabela system_client
          -->

            <button
              [ngClass]="fontClass"
              mat-icon-button
              (click)="onDownload()"
              matTooltip="Download Relatório"
              *ngIf="btnDownloadReport && showTitle && globalService.btnReportsDownload"
              [disabled]="loading"
            >
              <mat-icon aria-label="Refresh">save_alt</mat-icon>
            </button>

            <!--BE SURE TO REVERT THIS-->
            <button
              [ngClass]="fontClass"
              mat-icon-button
              aria-hidden="true"
              (click)="modalPainelStatus()"
              matTooltip="Painel Status"
              *ngIf="btnStatusPanel && showTitle"
              [disabled]="loading"
            >
              <mat-icon aria-label="Refresh">tv</mat-icon>
            </button>

            <button
              mat-icon-button
              (click)="openSearch($event)"
              matTooltip="Pesquisar"
              *ngIf="searchEnabled"
            >
              <mat-icon aria-label="Open search bar">search</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="openFilter($event)"
              matTooltip="Filtrar"
              *ngIf="filterEnabled"
            >
              <mat-icon aria-label="Open filter dialog"
                >{{filterCount}}</mat-icon
              >
            </button>

            <span class="admin_painel" [ngClass]="fontClass" *ngIf="isWeb"
              >{{ account?.full_name }}</span
            >
            <button
              *ngIf="isWeb"
              mat-flat-button
              color="primary"
              class="tkg-listen"
              (click)="goToLink('https://docs.google.com/forms/d/e/1FAIpQLSewijWGog-v9KUhdjnYfSE-2WL1S97E71hDA2ufCIROBOfubg/viewform?usp=sf_link')"
            >
              Queremos te ouvir
            </button>
            <button
              mat-icon-button
              [ngClass]="fontClass"
              [matMenuTriggerFor]="appMenu"
              *ngIf="showTitle"
            >
              <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #appMenu="matMenu" xPosition="before">
              <span class="user-menu" [ngClass]="fontClass"
                >{{ account?.full_name }}</span
              >
              <button
                *ngIf="isHandset$"
                mat-flat-button
                color="primary"
                class="tkg-listen-mobile"
                (click)="goToLink('https://docs.google.com/forms/d/e/1FAIpQLSewijWGog-v9KUhdjnYfSE-2WL1S97E71hDA2ufCIROBOfubg/viewform?usp=sf_link')"
              >
                Queremos te ouvir
              </button>
              <a
                href="https://trackage.zendesk.com"
                target="_blank"
                mat-menu-item
              >
                <mat-icon>post_add</mat-icon>{{ "Business_model.support" |
                translate:{Default: "Suporte"} }} & FAQs
              </a>
              <a
                href="https://university.trackage.com.br"
                target="_blank"
                mat-menu-item
              >
                <mat-icon>school</mat-icon>Trackage University
              </a>
              <button mat-menu-item>
                <mat-icon>support_agent</mat-icon>
                <span>WhatsApp: (19) 3199-8184</span>
              </button>
              <button mat-menu-item>
                <mat-icon>email</mat-icon>
                <span
                  >{{ 'Business_model.support_email' | translate:{Default:
                  'E-mail: ajuda@trackage.zendesk.com'} }}
                </span>
              </button>
              <span class="user-menu" [ngClass]="fontClass"></span>

              <button mat-menu-item (click)="accountDetail()">
                <mat-icon>person</mat-icon>{{ "Business_model.my_account" |
                translate:{Default: "Minha conta"} }}
              </button>
              <button mat-menu-item (click)="switchAccount()">
                <mat-icon>switch_account</mat-icon>
                Trocar de conta
              </button>
              <button (click)="logout()" mat-menu-item>
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
              </button>
            </mat-menu>

            <button
              mat-icon-button
              (click)="toggleSound()"
              [ngClass]="fontClass"
              *ngIf="showTitle"
            >
              <mat-icon
                >{{ this.account?.has_sound ? 'volume_up' :
                'volume_off'}}</mat-icon
              >
            </button>
          </div>
          <!-- fim buttons -->
        </div>
      </div>
    </mat-toolbar>
  </div>

  <div id="app-content" style="width: 100%">
    <div style="height: 100%; box-sizing: border-box">
      <main style="height: -webkit-fill-available; padding: 16px 16px 0">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</mat-sidenav-container>
