import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '../shared/report-service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, tap, debounceTime } from 'rxjs/operators';
import { CarrierService } from '../../carrier/shared/carrier.service';
import { DriversService } from '../../drivers/shared/drivers.service';
import { SupportDataService } from '../../shared/support-data.service';
import { UserService } from '../../user/shared/user.service';
import { EventsService } from '../../shared/events.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-event-report-list',
  templateUrl: './event-report-list.component.html',
  styleUrls: ['./event-report-list.component.scss']
})
export class EventReportListComponent implements OnInit {
  private reportChunkStarted:Boolean=false;
  form = this.formBuilder.group({
    filter_type: [0],
    driver_id: [0],
    carrier_id: [0],
    operation: [0],
    dock_space: [0],
    sys_user: [0],
    day: [0],
    dt_start: [0],
    dt_end: [0]
  });

  active_filters: any = [];
  optionsFilters: any = {
    driver_id: null,
    carrier_id: null,
    operation: null,
    sys_user: null,
    dock_space: null,
    day: null,
    dt_start: null,
    dt_end: null,
    export: false
  };
  searching = false;
  driverFilter: UntypedFormControl = new UntypedFormControl();
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  boardHorseFilter: UntypedFormControl = new UntypedFormControl();
  boardCartFilter: UntypedFormControl = new UntypedFormControl();
  operationFilter: UntypedFormControl = new UntypedFormControl();
  dockFilter: UntypedFormControl = new UntypedFormControl();
  dtFilter: UntypedFormControl = new UntypedFormControl();
  entryFilter: UntypedFormControl = new UntypedFormControl();
  exitFilter: UntypedFormControl = new UntypedFormControl();
  userFilter: UntypedFormControl = new UntypedFormControl();
  lacreFilter: UntypedFormControl = new UntypedFormControl();
  dayFilter: UntypedFormControl = new UntypedFormControl();
  carriers: any = [];
  drivers: any = [];
  boardHorses: any = [];
  boardCarts: any = [];
  operations: any = [];
  docks: any = [];
  dts: any = [];
  ids: any = [];
  lacres: any = [];
  users: any = [];
  downloadObserver: Subscription;

  displayedColumns: string[] = [
    'carreta', 'cavalo', 'transportadora', 'operacao', 'suboperation', 'id', 'status', 'sche_time', 'sche_created_at', 'sche_confirmed_at',
    'sche_denied_at', 'sche_aproved_at', 'sche_pendencia_opentech','sche_pendencia_opentech_detail',
    'checkin_anterior', 'checkin', 'direcionado', 'no_estacionamento', 'na_doca', 'carregado', 'descarregado', 'notas_prontas', 'notas_entregues',
    'finished', 'doca', 'responsavel', 'lacre', 'parqueado',
    'appointment', 'checkin_mht', 'duracao_jornada'];
  filters: any = [
    // { id: 1, name: 'Motorista', altName: 'driver_id' },
    { id: 2, name: 'Transportadora', altName: 'carrier_id' },
    // { id: 3, name: 'Placa cavalo', altName: 'board_horse' },
    // { id: 4, name: 'Placa carreta', altName: 'board_cart' },
    // { id: 5, name: 'Status', altName: 'status' },
    { id: 6, name: 'Operação', altName: 'operation' },
    { id: 7, name: 'Doca', altName: 'dock_space' },
    // { id: 8, name: 'DT', altName: 'dt' },
    // { id: 9, name: 'Data entrada', altName: 'entry' },
    // { id: 10, name: 'Data saída', altName: 'exit' },
    { id: 11, name: 'Usuário final', altName: 'sys_user' },
    { id: 12, name: 'Dia', altName: 'day'},
    { id: 13, name: 'Período', altName: 'range'}
  ];
  dataSource = new MatTableDataSource<any>();
  pageSize: number;
  length: number;
  showListEmpty = false;
  private filter_options: Object = {};
  @Output() event: EventEmitter<any> = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:true}) sort: MatSort;

  constructor(
    public datePipe: DatePipe,
    private events: EventsService,
    public reportService: ReportService,
    private formBuilder: UntypedFormBuilder,
    public carrierService: CarrierService,
    public driverService: DriversService,
    public supportDataService: SupportDataService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.optionsFilters.export = false;
    this.loadComponentsObservers();
    this.loadReport();
    this.loadCarrier();
    this.loadDriver();
    this.loadOperations();
    this.loadDocks();
    this.loadUsers();
    // console.log(this.listEvents)
    this.downloadObserver = this.events.currentEvent.subscribe((ev: string) => {
      if (ev === 'download_data') {
        this.downloadReport();
      }
    })
  }
  ngOnDestroy(){
    this.downloadObserver.unsubscribe()
  }

  //Adiciona o observer apenas uma única vez para que não sejam criados multiplos observers
  loadComponentsObservers(){
    /// Ao entrar na página
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          };
          Object.assign(options, this.filter_options);
          return this.reportService.eventReport(options).pipe(
            catchError(() => {
              return observableOf({data:[],total:0,per_page:0});
            })
          );
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        })
      ).subscribe(data => {
        this.dataSource.data = data;
      });
  }

  loadReport(filter_options?:any) {
    // const opt = option ? option : {};
    // this.reportService.eventReport(opt).subscribe((r: any) => {
    //   this.dataSource.data = r.data;
    // });
    /// Ao entrar na página
    this.paginator.pageIndex = 0;
    const options = {
      orderBy: this.sort.active,
      sortedBy: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
    };

    Object.assign(options, filter_options);
    this.filter_options = filter_options;

    this.reportService.eventReport(options)
    .pipe(
      tap((response: Response | any) => {
        if (!response.data.length && this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        }

        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
      }),
      map((response: Response | any) => {

        return response.data;
      }),
      catchError(() => {
        return observableOf([]);
      })
    )
    .subscribe(data => {
      this.dataSource.data = data;
    });

  }

  loadDriver() {
    this.driverFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.driverFilter.value}` || ''
        };
        return this.driverService.index(options).pipe(
          tap(() => this.searching = false)
        );
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        return observableOf([]);
      })
    ).subscribe(data => {
      this.drivers = data;
    });
    this.driverService.index().subscribe((r: any) => {
      this.drivers = r.data;
    });
  }

  loadCarrier() {
    this.carrierFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'carrier_client.updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.carrierFilter.value}` || ''
        };
        return this.carrierService.index(options).pipe(
          tap(() => this.searching = false),
          catchError(() => {
            return observableOf({data:[],total:0,per_page:0});
          })
        );
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      })
    ).subscribe(data => {
      this.carriers = data;
    });
    this.carrierService.index().subscribe((r: any) => {
      this.carriers = r.data;
    });

  }

  loadOperations() {
    this.operationFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.operationFilter.value}` || ''
        };
        return this.supportDataService.operation(options);
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.operations = data.resource;
    });
    this.supportDataService.operation().subscribe((r: any) => {
      this.operations = r.data.resource;
    });

  }

  loadDocks() {
    this.dockFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.dockFilter.value}` || ''
        };
        return this.supportDataService.dockSpaces(options);
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.docks = data.resource instanceof Array ? data.resource : (data.resource['1']||[]);
    });
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.docks = r.data.resource instanceof Array ? r.data.resource : (r.data.resource['1']||[]);
    });

  }

  loadUsers() {
    this.userFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.userFilter.value}` || ''
        };
        return this.userService.index(options);
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.users = data;
    });
    this.userService.index().subscribe((r: any) => {
      this.users = r.data;
    });
  }

  addFilter() {
    const filter = this.filters.filter((f:any) => f.id === this.form.value.filter_type);

    if (filter['0']['altName'] == 'sys_user') {
      this.active_filters.push({
        filter: filter[0]['name'],
        value: this.form.value[filter['0']['altName']]['full_name'],
        altName: filter['0']['altName'],
        search: this.form.value[filter['0']['altName']]['full_name']
      });
      this.optionsFilters[filter['0']['altName']] = [this.form.value[filter['0']['altName']]['full_name'], ...(this.optionsFilters[filter['0']['altName']]||"").split("|")].filter(Boolean).join("|");
    } else if (filter['0']['altName'] == 'day') {
      this.active_filters.push({
        filter: filter[0]['name'],
        value: this.datePipe.transform(this.form.value[filter['0']['altName']], 'dd/MM/yyyy'),
        altName: filter['0']['altName'],
        search: this.datePipe.transform(this.form.value[filter['0']['altName']], 'yyyy-MM-dd')
      });
      this.optionsFilters[filter['0']['altName']] = [this.datePipe.transform(this.form.value[filter['0']['altName']], 'yyyy-MM-dd'), ...(this.optionsFilters[filter['0']['altName']]||"").split("|")].filter(Boolean).join("|");

    } else if (filter['0']['altName'] == 'range') {
      // Busca o índice do filtro no array
      const fIndex = this.active_filters.findIndex((item:any) => item.altName == "range");

      // Se o filtro existir, remove
      if (fIndex >= 0) {
        this.active_filters.splice(fIndex, 1);
      }

      const dt_start = this.datePipe.transform(this.form.value.dt_start, 'dd/MM/yyyy');
      const dt_end = this.datePipe.transform(this.form.value.dt_end, 'dd/MM/yyyy');
      this.active_filters.push({
        filter: filter[0]['name'],
        value: `${dt_start} - ${dt_end}`,
        altName: filter['0']['altName']
      });
      this.optionsFilters.dt_start = this.datePipe.transform(this.form.value.dt_start, 'yyyy-MM-dd');
      this.optionsFilters.dt_end = this.datePipe.transform(this.form.value.dt_end, 'yyyy-MM-dd');
    }
    else {
      this.active_filters.push({
        filter: filter[0]['name'],
        value: this.form.value[filter['0']['altName']]['name'],
        altName: filter['0']['altName'],
        search:this.form.value[filter['0']['altName']]['id']
      });
      this.optionsFilters[filter['0']['altName']] = [this.form.value[filter['0']['altName']]['id'], ...(this.optionsFilters[filter['0']['altName']]||"").split("|")].filter(Boolean).join("|");
    }

    this.loadReport(this.optionsFilters);
  }

  deleteFilter(idx:number, altName:string) {
    const filter = {search:"",...this.active_filters.splice(idx, 1).shift()};
    if(altName == "range"){
      this.optionsFilters.dt_start = '';
      this.optionsFilters.dt_end = '';
    }else{
      this.optionsFilters[altName] = this.optionsFilters[altName].split("|").filter((x:string)=>(x && x != filter.search)).join("|");
    }
    this.loadReport(this.optionsFilters);
  }

  downloadReport(): void {
    if(this.reportChunkStarted)
      return;

    this.reportChunkStarted = true;
    this.optionsFilters.export = true;
    const chunks:any[] = [];

    const reportChunks = (numberOfChunk:number)=>{
      this.optionsFilters.chunk = numberOfChunk;
      this.reportService.eventReport(this.optionsFilters)
      .pipe(
        catchError(() => {
          return observableOf({csv:"",rows:0});
        })
      )
      .subscribe(
        (r: any) => {
          try{
            chunks.push(r.csv || "");
            if(numberOfChunk <= 10 && r.rows >= 10000){
              reportChunks(numberOfChunk + 1);
            }else if(chunks.length > 0){
              this.createFileFromBlob(
                chunks.reduce((textConcat, text, index)=>{
                  if(index > 0 && text)
                    return textConcat + text.substring(text.indexOf("\n") + 1)
                  return textConcat + text;
                }),
                'report.csv'
              );
              this.optionsFilters.export = false;
              this.reportChunkStarted = false;
            }

          }catch(ex){
            this.optionsFilters.export = false;
            this.reportChunkStarted = false;
          }
      })
    }
    reportChunks(0);

  }

  createFileFromBlob(file:any, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob(["\uFEFF" + file], { type: 'text/csv; charset=utf-18' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}
