<div class="container">
  <div style="width: 100%;">
    <h3>Maestro</h3>
  </div>
  <mat-card>
    <div class="pagination">
      <!-- <button (click)="previousPage()">Anterior</button> -->
      <!-- <button (click)="nextPage()">Próxima</button> -->
      <button class="week" type="button" aria-label="Toggle page" mat-icon-button (click)="previousPage()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <span>Semana: {{formatDate(firstDayOfWeek)}} à {{formatDate(lastDayOfWeek)}} </span>
      <button class="week" type="button" aria-label="Toggle page" mat-icon-button (click)="nextPage()">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </div>
      <mat-card-content class="container">
        <div echarts id="barChart" style="height: 400px; width: 700px;" (click)="onChartEvent()"></div>
      </mat-card-content>
  </mat-card>

  <div style="width: 100%;">
    <h3>Aplicativo</h3>
  </div>
  <mat-card>
    <div class="pagination">
      <!-- <button (click)="previousPage()">Anterior</button> -->
      <!-- <button (click)="nextPage()">Próxima</button> -->
      <button class="week" type="button" aria-label="Toggle page" mat-icon-button (click)="previousPage()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <span>Semana: {{formatDate(firstDayOfWeek)}} à {{formatDate(lastDayOfWeek)}} </span>
      <button class="week" type="button" aria-label="Toggle page" mat-icon-button (click)="nextPage()">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </div>
      <mat-card-content class="container">
        <div echarts id="barChart2" style="height: 400px; width: 700px;"></div>
      </mat-card-content>
  </mat-card>

  <div style="width: 100%;">
    <h3>Integração</h3>
  </div>
  <mat-card>
    <div class="pagination">
      <!-- <button (click)="previousPage()">Anterior</button> -->
      <!-- <button (click)="nextPage()">Próxima</button> -->
      <button class="week" type="button" aria-label="Toggle page" mat-icon-button (click)="previousPage()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <span>Semana: {{formatDate(firstDayOfWeek)}} à {{formatDate(lastDayOfWeek)}} </span>
      <button class="week" type="button" aria-label="Toggle page" mat-icon-button (click)="nextPage()">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </div>
      <mat-card-content class="container">
        <div echarts id="barChart3" style="height: 400px; width: 700px;"></div>
      </mat-card-content>
  </mat-card>

  <div style="width: 100%;">
    <h3>Últimas listagens de notificações</h3>
  </div>
  <table style="margin-bottom: 35px;">
    <tr *ngFor="let incident of topIncidents">
      <td><span>{{formatDate(incident.created_at)}}</span> - {{incident.name}}</td>
      <button mat-icon-button (click)="getIncidentById(incident.id)"><mat-icon>open_in_new</mat-icon></button>
    </tr>
    <mat-divider></mat-divider>
  </table>
</div>
