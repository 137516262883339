import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CheckinService } from "../../checkin/shared/checkin.service";
import { UntypedFormGroup } from "@angular/forms";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { forkJoin, Observable } from "rxjs";
import { SupportDataService } from "src/app/shared/support-data.service";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";

@Component({
  selector: "app-dynamic-dialog",
  templateUrl: "./dynamic-dialog.component.html",
  styleUrls: ["./dynamic-dialog.component.scss"],
})
export class DynamicDialogComponent implements OnInit {
  dialogResult: any = {};
  message: string = "";
  title!: string;

  files = [];
  documents: any[] = [];
  loading = false;
  offset!: string;
  form = new UntypedFormGroup({});
  model: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  account: any;

  constructor(
    private formlyJsonschema: FormlyJsonschema,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: UntypedFormBuilder,
    public supportDataService: SupportDataService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DynamicDialogComponent>,
    private checkinService: CheckinService,
  ) {}

  ngOnInit() {
    console.log("-----");
    console.log(this.data);
    const accountStorage: string = localStorage.getItem("account") || "";
    this.account = JSON.parse(accountStorage);

    forkJoin([this.getFormFields()]).subscribe((result) => {
      this.processFormFields(result[0]);
      if (this.data.action == "EDIT" || this.data.id) {
        console.log("preenche form");
        this.fillFormForEdit();
      }
    });
  }

  fillFormForEdit() {
    this.model = { ...this.data.item.resource };
  }

  getFormFields(): Observable<any> {
    return this.supportDataService.generic({ name: this.data.form_name });
  }

  processFormFields(r: any) {
    this.fields = r.data.resource;
    // this.fields = [this.formlyJsonschema.toFieldConfig(r.data.resource)];
  }

  // Criar funções para gravar em módulos diferentes
  onSubmit() {
    if (this.data.action.flow_control == "scheduled_checkin") {
      console.log(this.form.value);
      const formData = this.adjustFormData({ ...this.form.value });
      let data = {
        id: this.data.item.id,
        process: ["data_from_schedule", "data_from_device"],
        ...formData,
      };
      data["status"] = this.data.hasOwnProperty("status")
        ? this.data.action.status
        : "waiting";
      if (!data.id) {
        this.checkinService.newCheckin(data).subscribe((r: any) => {
          this.dialogRef.close();
        });
      }
    }
    if (this.data.action.flow_control == "manual_checkin") {
      const formData = this.adjustFormData({ ...this.form.value });
      let data = {
        id: this.data.item.id,
        process: ["data_from_device"],
        ...formData,
      };
      data["status"] = this.data.hasOwnProperty("status")
        ? this.data.action.status
        : "waiting";
      if (!data.id) {
        this.checkinService.newCheckin(data).subscribe((r: any) => {
          this.dialogRef.close();
        });
      }
    }
    if (this.data.action.flow_control == "default") {
      const formData = this.adjustFormData({ ...this.form.value });
      let data = {
        id: this.data.item.id,
        ...formData,
      };
      if (this.data.action.status.length > 0) {
        data.status = this.data.action.status;
      }
      if (!data.id) {
        this.checkinService.newCheckin(data).subscribe((r: any) => {
          this.dialogRef.close();
        });
      } else {
        this.checkinService
          .updateField(this.data.action.urlAPI, data)
          .subscribe({
            next: (r: any) => {
              if (r.status == 200) {
                this.dialogRef.close(r.status);
              }
            },
            error: (res: any) => {
              this.dialogRef.close();
              if (res.error.message) {
                this.snackBar.open(res.error.message, "Fechar", {
                  duration: 3000,
                });
              }
            },
          });
      }
    }
  }

  adjustFormData(form: any) {
    let newFormData: any = {};
    const keys = Object.keys(form);
    keys.forEach((item: any) => {
      if (Array.isArray(form[item])) {
        newFormData[item] = form[item];
      } else if (typeof form[item] === "object" && form[item] != null) {
        newFormData[item] = form[item].id;
      } else {
        newFormData[item] = form[item];
      }
    });

    return newFormData;
  }
}
