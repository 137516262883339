<mat-progress-bar *ngIf="saving" mode="indeterminate"></mat-progress-bar>
<h1 mat-dialog-title>Turno</h1>
<div mat-dialog-content>
  <form [formGroup]="shiftForm" (ngSubmit)="onSubmit()" id="shift">
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
  </form>

</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="secondary">Cancelar</button>
  <button
    form="shift"
    mat-button
    color="primary"
    type="submit"
    cdkFocusInitial
    [disabled]="!shiftForm.valid || saving"
  >
    Salvar
  </button>
</div>
