
import * as moment from 'moment';
import { forkJoin, merge, of } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { debounceTime, switchMap, catchError, map, filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StageAlert } from '../media.interface';
import { HubService } from '../shared/hub.service';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-stage-alerts',
  templateUrl: './stage-alerts.component.html',
  styleUrls: ['./stage-alerts.component.scss']
})
export class StageAlertsComponent implements OnInit{
  @ViewChild(MatPaginator) paginator: MatPaginator;

  alertsSource:StageAlert[]=[]
  form = new UntypedFormGroup({
    range: new UntypedFormControl(),
    department_id: new UntypedFormControl(''),
    operation_id: new UntypedFormControl('')
  });

  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[];

  model: any = {
    range:{
      start: new Date(),
      end: new Date()
    }
  };

  defaultFields:FormlyFieldConfig[] = [
      {
        key: 'range',
        type: 'formly-datepicker-range',
        props: {
          label: 'Período',
          placeholder: 'Selecione o período',
          appearance: 'outline'
        },
        defaultValue:{
         start: new Date(),
         end: new Date(),
        }
      },
      {
        key: 'department_id',
        type: 'select',
        props: {
          label: 'Departamento',
          placeholder: 'Selecione o departamento',
          appearance: 'outline'
        },
      },
      {
         key: 'operation_id',
         type: 'select',
         props: {
           label: 'Operação',
           placeholder: 'Selecione a operação',
           appearance: 'outline'
         },
         hideExpression: true,
         expressionProperties:{
           'props.disabled':(model)=>!model.department_id,
         }
       }
  ];

  constructor(
    private dialog: MatDialog,
    public hubService: HubService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService,
    public dialogRef: MatDialogRef<StageAlertsComponent>
  ) { }

  ngOnInit() {
    forkJoin([
      this.supportDataService.generic({name: 'departments'}).pipe(map<any,any[]>((x:any)=>x?.data?.resource)),
      this.supportDataService.generic({name: 'operation_type'}).pipe(map<any,any[]>((x:any)=>x?.data?.resource))
    ])
    .subscribe( ([departments, operations]) => {
      departments = [{ label: 'Limpar'}].concat(
        (departments || []).map(item=>{
          item.label = item.name;
          item.value = item.id;
          return item;
        })
      );

      operations = [{ label: 'Limpar'}].concat(
        (operations || []).map(item=>{
          item.label = item.name;
          item.value = item.id;
          return item;
        })
      );

      this.defaultFields[1].props!.options = departments;

      this.defaultFields[2].props!.options = operations;

      this.fields = this.defaultFields;

      this.form.get("department_id")?.valueChanges
      .pipe(filter(()=>!this.defaultFields[2].hideExpression))
      .pipe(debounceTime(500))
      .subscribe((value:any)=>{
        if(value){
          this.defaultFields[2].props!.options = [{ label: 'Limpar'}].concat(
            operations.filter(item=>(item.department || item.department_id) == value)
          )
        }else{
          this.form.get("operation_id")?.setValue("",{emitEvent:false});
          this.defaultFields[2].props!.options = operations;
        }
      })

      merge(
        this.form.valueChanges.pipe(
          debounceTime(500),
          map(()=>this.paginator.pageIndex = 0)
        ),
        this.paginator.page
      )
      .pipe(
        switchMap(()=>{
        let start = "";
        let end = "";

        if(this.model.range){
          start = moment(this.model.range.start).format('YYYY-MM-DD');
          end = moment(this.model.range.end).format('YYYY-MM-DD');
        }

        const options = {
          pageSize: this.paginator.pageSize || 10,
          page: this.paginator.pageIndex + 1,
          stage_group: this.data.stage_group || "",
          step_name: this.data.step_name || "",
          department_id: this.model.department_id || "",
          operation_id: this.model.operation_id || "",
          start,
          end
        };

        return this.hubService.stageAlerts(options)
        .pipe(
          catchError(error => of([]))
        );
      }))
      .pipe(map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response;
      }))
      .pipe(map<any,StageAlert[]>((x:any)=>x?.data))
      .subscribe((data:StageAlert[]=[])=>{
        this.alertsSource = data;
      });

    })
  }
}
