import { WssService } from 'src/app/shared/websocket/wss.service';
import { DockManagementService } from './../shared/dock-management.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DockAssignDialogComponent } from '../dock-assign-dialog/dock-assign-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from "./../../../environments/environment.prod";
import { Subscription } from 'rxjs';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-dock-add-edit-dialog',
  templateUrl: './dock-add-edit-dialog.component.html',
  styleUrls: ['./dock-add-edit-dialog.component.scss']
})


export class DockAddEditDialogComponent implements OnInit {
  wsSubscription!:Subscription;
  msgTitle: string = "Atualizado em tempo real";
  msg: string = "Esta doca pode ser atualizada a qualquer momento.";
  showMsg = true;
  bg: string = 'unset-bg';
  dock?: any;
  typeList: any = [];
  departmentList: any = [];
  hasData = false;
  disable_on_save = false;
  form = this.formBuilder.group({
    id: [null],
    name: [null, [Validators.required]],
    type: [null, [Validators.required]],
    department_id: [null],
    can_block: [true],
    busy: [false],
    maintenance: [false],
    automatic_queue: [false]
  });


  constructor(
    private dialog: MatDialog,
    private websocketService: WssService,
    private formBuilder: UntypedFormBuilder,
    private dockManagementService: DockManagementService,
    public dialogRef: MatDialogRef<DockAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.typeList = this.data.dock_types;
    this.departmentList = this.data.departments;
  }

  ngOnInit() {
    console.log(this.data)
    this.subcribeWebsocket();
    if(this.data.action == 'EDIT') {
      this.getDockById();
    } else {
      this.hasData = true;
    }
  }


  subcribeWebsocket() {
    let client = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wsSubscription = this.websocketService
      .connect(`${environment.wsUrl}/docks/${client}`)
      .subscribe(
        (msg) => {
        console.log('ws msg');
        console.log(msg);
        const wsMsg: any = Object.assign({}, msg)
        if(wsMsg.hasOwnProperty('action')){
          if(wsMsg.action === 2) {

            console.log('busca a doca atualizada pelo id')
            // this.getDocks();
            // Update -> Define mensagem de update
            if(wsMsg.msg) {
              // this.inlineMsg(wsMsg);
            } else {
              // this.inlineMsg('Doca atualiza!');
            }
          }
        }
      })
  }

  inlineMsg(WsMsg: any) {
    // Checa se a doca que foi escolhida no form é a mesma da atualização... se for, limpar o formulário
    // e forçar o usuário
    this.bg = 'set-bg';
    let complementary_message = '';
    if(this.form.value.dock_space_id) {
      if((this.form.value.dock_space_id == WsMsg.dock_id) && WsMsg.busy) {
      }
    }
    this.msgTitle = 'Nova atualização recebida';
    this.msg = `${WsMsg.msg} ${complementary_message}`;
    setTimeout(()=>{
      this.bg = 'unset-bg';
      this.msgTitle = 'Atualizado em tempo real';
      this.msg = `Esta doca pode ser atualizada a qualquer momento.`;
    }, 4000);

  }

  getDockById() {
    this.dockManagementService.show(this.data.item.id).subscribe((r: any) => {
      this.dock = r.data;
      this.updateForm();
    });
  }

  updateForm() {
    this.form.patchValue({
      name: this.dock.name,
      type: this.dock.type,
      department_id: this.dock.department_id,
      busy: this.dock.busy,
      can_block: this.dock.can_block,
      maintenance: this.dock.maintenance,
      automatic_queue: this.dock.automatic_queue

    });
    this.hasData = true;
  }

  onSubmit() {
    this.disable_on_save = true;
    if(this.data.action == 'ADD') {
      const options = {
        origin_id: 1,
        name: this.form.value.name,
        type: this.form.value.type,
        department_id: this.form.value.department_id,
        busy: this.form.value.busy,
        can_block: this.form.value.can_block,
        maintenance: this.form.value.maintenance,
        automatic_queue: this.form.value.automatic_queue
      };
      this.dockManagementService.create(options).subscribe((r: any) => {
        this.dialogRef.close(r);
      });
    } else {
      const options = {
        origin_id: 1,
        origin_ref_id: this.data.item.id,
        id: this.data.item.id,
        name: this.form.value.name,
        type: this.form.value.type,
        department_id: this.form.value.department_id,
        busy: this.form.value.busy,
        can_block: this.form.value.can_block,
        maintenance: this.form.value.maintenance,
        automatic_queue: this.form.value.automatic_queue

      };
      this.dockManagementService.update(options, this.data.item.id).subscribe((r: any) => {
        this.dialogRef.close(r);
      });
    }
  }

  onDelete(id: any) {
    console.log(id);
    this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Atenção!",
        message:
          `Tem certeza que deseja apagar '${this.dock.name}'?`,
        btnConfirm: "Prosseguir",
        btnCancel: "Cancelar",
        id: id
      },
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.dockManagementService.destroy(id).subscribe((r: any) => {
          this.dialogRef.close(r);
        })
      }
    });
  }
}



