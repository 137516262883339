import { NavigationExtras, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ROUTE_JOURNEY_TIMELINE_STATUS } from "../timelines.const";
import { JourneyStatusTimelineDialogComponent } from "./journey-status-timeline-dialog/journey-status-timeline-dialog.component";

export default class JourneyStatusTimelineRender{
  private static dialog: MatDialog;
  private static router: Router;

  static initializer(dialog: MatDialog, router:Router){
    JourneyStatusTimelineRender.dialog = dialog;
    JourneyStatusTimelineRender.router = router;
    return ()=>true;
  }

  static open(data:any, props:any){
    if(props?.mode === "page"){
      const navigationExtras: NavigationExtras = {
        state: {
          data,
          props
        }
      };
      JourneyStatusTimelineRender.router.navigate([ROUTE_JOURNEY_TIMELINE_STATUS],navigationExtras);
    }else{
      JourneyStatusTimelineRender.dialog.open(JourneyStatusTimelineDialogComponent, {
        data:{data, props},
        width: '80vw',
        maxHeight: '80vh',
        minHeight: '50vh'
      })
      .afterClosed()
      .subscribe(result => {

      });
    }
  }

}
