import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { DriversService } from '../../drivers/shared/drivers.service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { map, catchError } from 'rxjs/operators'
import { Driver } from '../../drivers/drivers.interface';
import { Subject, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentsService } from '../shared/documents.service';

@Component({
  selector: 'app-documents-dialog',
  templateUrl: './documents-dialog.component.html',
  styleUrls: ['./documents-dialog.component.scss']
})
export class DocumentsDialogComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;
  files: any[] = [];

  form = this.formBuilder.group({
    id: [this.data.item.id || null],
    order_number: [this.data.item.resource.order_number || ''],
    driver_id: [this.data.item.resource.driver_id || ''],
    send_at: [this.data.item.resource.send_at || ''],
    notes: [this.data.item.resource.notes],
    documents: [this.data.item.resource.documents || []]
  });
  documents: any = [];
  document: any;

  errors: any = {};

  drivers: Driver[] = [];
  driverFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  protected _onDestroy = new Subject<void>();
  translateSource: any;

  constructor(
    public documentsService: DocumentsService,
    public driversService: DriversService,
    public dialogRef: MatDialogRef<DocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) {

  }

  ngOnInit() {
    this.loadDrivers();
    if (this.data.item.id) {
      this.documents = this.data.item.resource.documents;
    }
  }

  loadDrivers() {
    const options = {
      pageSize: 1000
    };
    this.driversService.index(options).subscribe((r: any) => {
      this.drivers = r.data;
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.documentsService.upload(formData).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.documents.push(event!.body);
        }

      });
  }

  uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  sendDocs() {
    this.form.value.documents = this.documents;
    const form = this.form.value;
    const id = form.id;

    const data = {
      id: id,
      name: 'remessa',
      resource: this.form.value
    }
    if (data.id) {
      this.documentsService.update(data, data.id).subscribe((r: any) => {
        this.dialogRef.close();
      });
    } else {
      this.documentsService.create(data).subscribe((r: any) => {
        this.dialogRef.close();
      });
    }
  }

  deleteFile(item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.documentsService.delete(item).subscribe((r: any) => {
          if (r.success) {
            const idx = this.documents.findIndex((v: any) => v.fileName === item.fileName);
            this.documents.splice(idx, idx >= 0 ? 1 : 0);
          }
        });
      }
    });
  }

  getFile(item: any): void {
    this.documentsService.downloadFile(item)
      .subscribe(
        (val) => {
          this.createFileFromBlob(val, item.fileName);
        });
  }

  createFileFromBlob(file: Blob, fileName: any) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
