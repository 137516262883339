import { JwtModule } from '@auth0/angular-jwt';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import KeycloakInitializer from './keycloak-initializer';
import { environment } from '../../environments/environment';
import { AccountService } from '../account/shared/account.service';
import { Router } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    KeycloakAngularModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: KeycloakInitializer.token,
        allowedDomains: environment.whitelistedDomains,
      },
    }),
  ],
  providers:[
    {
      provide: APP_INITIALIZER,
      useFactory: KeycloakInitializer.initializer,
      multi: true,
      deps: [KeycloakService, AccountService, Router]
    }
  ]
})

export class AuthModule { }
