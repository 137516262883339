import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from "src/app/shared/support-data.service";
import { DockManagementService } from "src/app/docks/shared/dock-management.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-schedule-dock-window-dialog",
  templateUrl: "./schedule-dock-window-dialog.component.html",
  styleUrls: ["./schedule-dock-window-dialog.component.scss"],
})
export class ScheduleDockWindowDialogComponent implements OnInit {
  form = this.formBuilder.group({
    department_id: [this.data.department_id, Validators.required],
    dock_id: [this.data.dock_id, Validators.required],
    start: [this.data.start, Validators.required],
    end: [this.data.end, Validators.required],
    slot: [this.data.slot, Validators.required],
  });
  departments: any[] = [];
  docks: any[] = [];
  validationMsgs: any[] = [];
  account: any;
  showSuboperation = false;
  allDataLoaded = false;
  nonEditableField = false;

  constructor(
    public dialogRef: MatDialogRef<ScheduleDockWindowDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private dockService: DockManagementService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getDepartments();
    this.getDocks();
  }

  close() {
    this.dialogRef.close();
  }

  getDocks() {
    this.dockService.index({}).subscribe((r: any) => {
      this.docks = r.data;
    });
  }

  getDepartments() {
    this.supportDataService
      .generic({ name: "departments" })
      .subscribe((r: any) => {
        this.departments = r.data.resource;

        if (this.departments.length === 1) {
          this.form.patchValue({ department_id: this.departments[0].id });
        } else if (!this.data.id) {
          //Se não possuir data.id, é uma nova janela para um cliente com mais de um departamento
          //Então desativa o campo de Operações até que seja informado um Departamento
          this.form.controls.operation_id.disable();
        }
      });
  }

  setLimitBySlotSize(value: boolean | null) {
    if (value) {
      this.form.get("limitScheduleSlot")!.setValidators(Validators.required);
    } else {
      this.form.get("limitScheduleSlot")!.clearValidators();
    }
    this.cdr.detectChanges();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      department_id: this.form.value.department_id,
      resource: {
        dock_id: this.form.value.dock_id,
        start: this.form.value.start,
        end: this.form.value.end,
        slot: this.form.value.slot,
      },
    };

    if (options.id) {
      this.agendamentoService
        .updateDockScheduleWindow(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    } else {
      this.agendamentoService
        .storeDockScheduleWindow(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    }
  }
}
