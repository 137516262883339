<h1 class="mat-align-center" mat-dialog-title>{{data.title}}
</h1>

<div mat-dialog-content *ngIf="showComponent">
  <form [formGroup]="form" novalidate>
    <mat-form-field >
      <mat-label>{{data.subtitle}}</mat-label>
      <input matInput formControlName="newReason">
      <button *ngIf="form.value.newReason" matSuffix mat-icon-button aria-label="Clear" (click)="addNewReason()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
    </form>
  <mat-selection-list #reasons [(ngModel)]="selectedReasons">
    <mat-list-option color="primary" *ngFor="let reason of invalidationReasons" [value]="reason">
      {{reason.name}}
    </mat-list-option>
  </mat-selection-list>
</div>

<mat-dialog-actions align="end">
  <button (click)="cancel()" mat-button color="secondary">
    Cancelar
  </button>
  <button (click)="confirm()" cdkFocusInitial mat-raised-button style="color: white;" color="primary">
    Confirmar
  </button>
</mat-dialog-actions>
