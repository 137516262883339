<h2 mat-dialog-title>Top 5 Ofensores - {{ nameOfensor }}</h2>
<h5 mat-dialog-title></h5>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>Tempo</th>
      <td mat-cell *matCellDef="let element">{{ element.time }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="submit" mat-raised-button mat-dialog-close="true">
    Fechar
  </button>
</mat-dialog-actions>
