import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

  constructor(
    private http: HttpClient
  ) { }

  last(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/telemetries-last`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}
