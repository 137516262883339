<div class="tkg-toolbar">
  <div class="tkg-toolbar-item" *ngIf="departmentList.length > 1 && account.docks_shared === false">
    <p class="tkg-ft-title">Áreas</p>
    <mat-button-toggle-group name="fontStyle" aria-label="Areas" [value]="departmentFilter" (change)="onChangeArea($event)">
      <mat-button-toggle value="0">Todas</mat-button-toggle>
      <mat-button-toggle *ngFor="let item of departmentList" [value]="item.id">{{item.name}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="tkg-toolbar-item">
    <p class="tkg-ft-title">Tipo</p>
    <mat-button-toggle-group name="fontStyle" aria-label="Tipo de vaga" [value]="typeFilter" (change)="onChangeType($event)">
      <mat-button-toggle value="0">Todos</mat-button-toggle>
      <mat-button-toggle *ngFor="let item of typeList" [value]="item.id">{{item.name}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="tkg-toolbar-item">
    <p class="tkg-ft-title">Situação</p>
    <mat-button-toggle-group name="fontStyle" aria-label="Vagas" [value]="dockFilter" (change)="onChangeBusy($event)">
      <mat-button-toggle value="all">{{dockFilter == 'all' ? 'Todas ' : ''}}<mat-chip>{{dockNumbers.total}}</mat-chip></mat-button-toggle>
      <mat-button-toggle value="busy">{{dockFilter == 'busy' ? 'Ocupadas ' : ''}}<mat-chip class="ocupada">{{dockNumbers.busy}}</mat-chip></mat-button-toggle>
      <mat-button-toggle value="unbusy">{{dockFilter == 'unbusy' ? 'Livres ' : ''}}<mat-chip class="livre">{{dockNumbers.unbusy}}</mat-chip></mat-button-toggle>
      <mat-button-toggle value="maintenance">{{dockFilter == 'maintenance' ? 'Manutenção ' : ''}}<mat-chip class="manutencao">{{dockNumbers.maintenance}}</mat-chip></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<mat-grid-list [cols]="columns" rowHeight="65px" [gutterSize]="'8px'" >
  <!-- <mat-grid-tile *ngFor="let item of dockList" class="tkg-cell" [ngClass]="item.maintenance ? 'manutencao' : item.busy ? 'ocupada' : 'livre'" (click)="addEditAction('EDIT', item.id)">{{item.name}}</mat-grid-tile> -->
  <mat-grid-tile *ngFor="let item of dockList" class="tkg-cell" [ngClass]="{'manutencao': item.maintenance, 'ocupada': item.busy, 'livre': !item.busy, 'set-feedback': ((item.id == dock_id || item.id == release_dock_id) && show_feedback)}" (click)="addEditAction('EDIT', item.id)"><div class="tkg-cell-item">{{item.name}}</div></mat-grid-tile>
</mat-grid-list>
<button
  mat-mini-fab
  (click)="addEditAction('ADD')"
  class="mat-fab-bottom-right"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
