<form [formGroup]="form">
  <h2 mat-dialog-title>{{'Driver.driversCnh.cnh' | translate:{Default:"CNH"} }} - {{form.value.name}}</h2>
  <div mat-dialog-content class="mat-typography" *ngIf="data.item.driver.resource.photo">
    <img [src]="this.data.item.driver.resource.photo" height="360" layout-align="center" />
  </div>
  <mat-card *ngIf="!data.item.driver.resource.photo">
    <div style="text-align:center">
      {{'Driver.driversCnh.no_picture' | translate:{Default: "-- Não Existe Foto da CNH"} }}
    </div>
  </mat-card>
  <mat-dialog-actions align="end">
    <form>
      <button mat-raised-button color="primary" (click)="exibirAvisoFoto()">
        {{'Driver.driversCnh.edit_button' | translate:{Default: "Editar"} }}
      </button>
    </form>
    <button type="submit" mat-button color="warn" mat-dialog-close="true">
      {{'Driver.driversCnh.close_button' | translate:{Default: "Fechar"} }}
    </button>

    <input type="file" id="uploadFoto" (change)="fileEvent($event)" style="display: none;">
  </mat-dialog-actions>

</form>
