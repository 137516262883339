import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CarrierService } from "../shared/carrier.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Carrier } from "../carrier";
import { merge, of as observableOf, Subscription } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
} from "rxjs/operators";
import { CarrierDetailComponent } from "../carrier-detail/carrier-detail.component";
import { DialogConfirmComponent } from "../../shared/dialog-confirm/dialog-confirm.component";
import { SearchService } from "../../shared/search.service";
import { Search } from "../../shared/search";
import { MaskUtil } from "../../shared/util/mask.util";
import { RefreshService } from "../../shared/refresh.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
// import { PermissionService } from "../../permission/shared/permission.service";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";

@Component({
  selector: "app-carrier-list",
  templateUrl: "./carrier-list.component.html",
  styleUrls: ["./carrier-list.component.scss"],
})
export class CarrierListComponent implements OnInit, OnDestroy {
  maskUtil = MaskUtil;
  searchWatcher!: Subscription;
  refreshWatcher!: Subscription;
  search!: string;
  translateSource: any;
  pageSize!: number;
  length!: number;
  haveExternalID = false;
  displayedColumns: string[] = [];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<Carrier>();
  dataSourceAll = new MatTableDataSource<Carrier>();
  data: Carrier[] = [];
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private refreshService: RefreshService,
    // private permissionService: PermissionService,
    public breakpointObserver: BreakpointObserver,
    private translate: TranslateService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });

    translate.get("Carrier.carrierList").subscribe((res: string) => {
      this.translateSource = res;
    });
  }

  activateLayout() {
    const account = JSON.parse(localStorage.getItem("account")!);
    let first = ["actions"];
    let columns = ["name", "cnpj", "carrier_client.created_at"];
    if (account.system_client.resource.environment.external_id === true)
      columns.push("external_id");
    columns = [ ...first, ...columns];
    this.displayedColumns = columns;
  }

  showStatus(item: any) {
    return this.translateSource.status[item.resource.status];
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  onOpen(event: any, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(CarrierDetailComponent, {
      panelClass: ["dialog-medium"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (action === "EDIT") {
          this.reload();
          this.snackBar.open("Transportadora atualizada com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        } else if (action === "ADD") {
          // If has previous page navigate to first page or reload in self page.
          // (this.dataSource.paginator.hasPreviousPage()) ? this.dataSource.paginator.firstPage() : this.reload();
          this.reload();
          this.snackBar.open("Transportadora criada com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      }
      this.reload();
    });
  }

  delete(event: any, data: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o item ${data.name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.carrierService.destroy(data.id).subscribe({
          next: () => {
          this.reload();
          this.snackBar.open("Transportadora removida com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }, 
        error: (error: any) => {
          if (error.error && error.error.message) {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: "Exclusão não permitida.",
                message: `${error.error.message}`,
              },
            });
          }
        }});
      }
    });
  }

  getSearch(event: any) {
    let val = event.target.value;

    this.dataSource.data = this.dataSourceAll.data.filter((item: any) => {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    });
  }

  ngOnInit() {
    this.sort.sort(<MatSortable>{
      id: "name",
      start: "asc",
    });
    const account = JSON.parse(localStorage.getItem("account")!);
    this.haveExternalID =
      account.system_client.resource.environment.external_id === true;

    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: this.sort.active
              ? this.sort.active
              : "carrier_carriers.name",
            sortedBy: this.sort.direction ? this.sort.direction : "ASC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            search: `${this.searchFilter.value}` || "",
          };
          return this.carrierService.index(options);
        }),
        map((response: any) => {
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        console.log("atualiza no subscribe");
        this.dataSource.data = data;
        // this.dataSourceAll.data = data;
      });

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.searchWatcher = this.searchService.value.subscribe(
      (search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      }
    );

    this.refreshWatcher = this.refreshService.refresh.subscribe(
      (refresh: Event) => {
        this.reload({ refresh: refresh });
      }
    );
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active
              ? this.sort.active
              : "carrier_carriers.name",
            sortedBy: this.sort.direction ? this.sort.direction : "ASC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            page: this.paginator.pageIndex + 1,
            search: "",
          };
          return this.carrierService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
      });
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
    this.refreshWatcher.unsubscribe();
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }
}
