<form [formGroup]="form" novalidate>
  <h2 mat-dialog-title>
    {{
      form.value.name
        ? form.value.name
        : data.action == "EDIT"
        ? translateSource.save
        : translateSource.add
    }}
  </h2>
  <span style="margin: 0 24px;" class="error-alert">{{ errorLabel }}</span>
  <mat-dialog-content class="mat-typography">
    <div formGroupName="resource">
      <div *ngIf="!(mode === 'driverAlreadyExists')">
        <mat-form-field>
          <mat-label>{{ "Business_model.name" | translate:{Default: 'Nome'} }}</mat-label>
          <input
            type="text"
            formControlName="name"
            matInput
            placeholder="{{ 'Business_model.name' | translate:{Default: 'Nome'} }}"
          />
          <mat-hint class="mat-error">
            <div *ngFor="let error of [].concat(errors.name, errors['resource.name'])">{{ error }}</div>
          </mat-hint>
        </mat-form-field>
      </div>

      <div *ngIf="!(mode === 'driverAlreadyExists')" class="carrier">
        <mat-form-field *ngIf="!(isCarrier && ((this.account.carriers.length === 1) ?? false))" class="carrierInput">
          <mat-label>{{ "Business_model.standard_carrier" | translate:{Default: 'Transportadora Padrão'} }}</mat-label>
          <mat-select formControlName="carrier_id" placeholder="{{ 'Business_model.carrier' | translate:{Default: 'Transportadora'} }}">
            <mat-option>
              <ngx-mat-select-search [formControl]="carrierFilter" placeholderLabel="{{'Business_model.search_carrier' | translate:{Default: 'Buscar Transportadora'}
                      }}" noEntriesFoundLabel="{{
                        'Messages.no_result' | translate:{Default: 'Nenhum resultado encontrado'}
                      }}"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!isCarrier" (click)="clearFilter()">{{ 'Messages.no_carrier' | translate:{Default: 'Sem Transportadora'} }}</mat-option>
            <mat-option *ngFor="let item of carriers" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-hint>
            <div class="mat-error" *ngFor="let error of errors.carrier_id">
              {{ error }}
            </div>
          </mat-hint>
        </mat-form-field>

      </div>

      <div>
        <mat-form-field *ngIf="!(mode === 'driverAlreadyExists')">
          <mat-label>{{ "Business_model.driver_license" | translate:{Default: 'CNH'} }}</mat-label>
          <input
            type="text"
            formControlName="cnh"
            [textMask]="{ mask: maskUtil.maskCNH() }"
            matInput
            placeholder="{{ 'Business_model.driver_license' | translate:{Default: 'CNH'} }}"
          />
          <mat-hint class="mat-error">
            <div *ngFor="let error of errors.cnh">{{ error }}</div>
          </mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'Business_model.driver_document' | translate:{Default: 'CPF'} }}</mat-label>
          <input
            type="text"
            formControlName="cpf"
            [textMask]="{ mask: maskUtil.maskCPF() }"
            matInput
            placeholder="{{ 'Business_model.driver_document' | translate:{Default: 'CPF'} }}"
          />
          <mat-hint class="mat-error">
            <div *ngFor="let error of errors['resource.cpf']">{{ error }}</div>
          </mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="!(mode === 'driverAlreadyExists')">
          <mat-label>{{ 'Business_model.phone' | translate:{Default: 'Telefone'} }}</mat-label>
          <input
            mask="(00) 00000-0000"
            type="tel"
            formControlName="phone"
            matInput
            placeholder="{{ 'Business_model.phone' | translate:{Default: 'Telefone'} }}"
          />
          <mat-hint class="mat-error">
            <div *ngFor="let error of errors.phone">{{ error }}</div>
          </mat-hint>
        </mat-form-field>
      </div>

      <div
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        *ngIf="showEditPass"
      >
        <mat-checkbox
          color="primary"
          class="pass"
          formControlName="changePass"
          (change)="setPass($event.checked)"
        >
          {{ "Business_model.change_password" | translate:{Default: 'Trocar Senha'} }}
        </mat-checkbox>
      </div>

      <div
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        *ngIf="!(mode === 'driverAlreadyExists') && showInputPass"
      >
        <mat-form-field>
          <mat-label>{{ 'Business_model.password' | translate:{Default: 'Senha'} }}</mat-label>
          <input
            type="password"
            formControlName="password"
            matInput
            placeholder="{{ 'Business_model.password' | translate:{Default: 'Senha'} }}"
          />
          <mat-hint class="mat-error">
            <div *ngFor="let error of errors.password">{{ error }}</div>
          </mat-hint>
        </mat-form-field>
      </div>

      <mat-slide-toggle color="primary" formControlName="sms" *ngIf="showSMS">
        {{ "Business_model.sms" | translate:{Default: 'SMS'} }}
      </mat-slide-toggle>

      <mat-slide-toggle
        *ngIf="!(mode === 'driverAlreadyExists')"
        color="primary"
        class="checkbox"
        formControlName="default_carrier"
      >
        {{ "Business_model.standard_carrier" | translate:{Default: 'Transportadora Padrão'} }}
      </mat-slide-toggle>
    </div>

    <mat-slide-toggle *ngIf="!(mode === 'driverAlreadyExists')" color="primary" formControlName="active">
      {{ "Business_model.active" | translate:{Default: 'Ativo'} }}
    </mat-slide-toggle>

    <div *ngIf="canCreateValet">
      <mat-slide-toggle color="primary" class="isValetToggle" formControlName="is_valet">
        {{ "Business_model.driver_valet" | translate:{Default: 'Manobrista'} }}
      </mat-slide-toggle>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="submit" mat-button color="warn" (click)="close()">
      {{ "Business_model.cancel" | translate:{Default: 'Cancelar'} }}
    </button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      style="color: white;"
      [disabled]="!form.valid"
      (click)="onSubmit()"
    >
      {{ data.action == "EDIT" ? translateSource.save : translateSource.add }}
    </button>
  </mat-dialog-actions>
</form>
