<!-- <app-loading-table *ngIf="showLoadingData"></app-loading-table> -->
<div class="message" *ngIf="showMessage">{{ message }}</div>
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z8">
  <app-paginate-sort-table
    *ngIf="!showListEmpty"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [totalItems]="totalItems"
    [columnsSchema]="columnsSchema"
    tableId="report_docks"
    [paginate]="paginate"
    [actions]="actions_list"
    (action)="action($event)"
    (performOnChangePageOnParent)="performOnChangePage($event)"
    (performSortChangeOnParent)="performSortChange($event)"
  >
  </app-paginate-sort-table>
</div>
<button
  mat-mini-fab
  class="mat-fab-bottom-right"
  color="primary"
  (click)="new_pre_scheduling()"
>
  <mat-icon>add</mat-icon>
</button>
