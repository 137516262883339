import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataLogService {
  constructor(private http: HttpClient) {}

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/data-log`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }
}
