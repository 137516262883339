<mat-tab-group animationDuration="0ms">
  <mat-tab label="Turnos" *ngIf="tabs.shift.active != false">
    <app-tab-shift [viewport$]="viewport$"></app-tab-shift>
  </mat-tab>

  <mat-tab label="Horários dos turnos" *ngIf="tabs.shift_schedules.active != false">
    <app-tab-shift-schedules [viewport$]="viewport$"></app-tab-shift-schedules>
  </mat-tab>

  <mat-tab label="Pausas dos turnos" *ngIf="tabs.extras_hours_shift.active != false">
    Content 3
  </mat-tab>

  <mat-tab label="Horas extras" *ngIf="tabs.extras_hours_shift.active != false">
    Content 3
  </mat-tab>

  <mat-tab label="Dias sem atividades" *ngIf="tabs.extras_hours_shift.active != false">
    Content 3
  </mat-tab>
</mat-tab-group>
