import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PainelMotoristaService } from 'src/app/painel-motorista-loreal/shared/painelmotorista.service';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { CheckinService } from 'src/app/checkin/shared/checkin.service';
import { timer, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountService } from "../../account/shared/account.service";

@Component({
  selector: 'app-modal-panel-status',
  templateUrl: './modal-panel-status.component.html',
  styleUrls: ['./modal-panel-status.component.scss']
})
export class ModalPanelStatusComponent implements OnInit {

  displayedColumns: string[];
  drivers: any = [];
  lastUpdate: any;
  operations: any = [];
  translateSource: any;
  //paginação
  page: number = 1;
  last_page: number = 5;
  subscription: Subscription;
  statusText: string;
  options = {};
  listStatus: any = [];
  dockSpaces: any = [];
  account: any;
  environment: any;
  logo: string;

  constructor(
    public checkinService: CheckinService,
    public painelMotoristaService: PainelMotoristaService,
    public supportDataService: SupportDataService,
    public dialogRef: MatDialogRef<any>,
    public breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
  ) { }

  ngOnInit() {
    this.accountService.identity().then(identity => {
      this.account = identity;
      this.environment = this.account.system_client.resource;
      this.logo = this.environment.logo_path;
    })
    this.refreshData();
    this.getOperations();
    this.getListStatus();
    this.getDockSpaces();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateDate() {
    this.lastUpdate = new Date();
  }

  refreshData() {

    this.options = {
      orderBy: 'id',
      sortedBy: 'ASC',
      page: this.page,
      pageSize: 15,
      with: 'carrier',
    };

    this.subscription = timer(0, 20000).pipe(
      switchMap(() => this.checkinService.index(this.options))
    ).subscribe({
      next: (r: any) => {


        this.updateDate();

        r.data.forEach((element: { resource: any; }) => {
          element.resource['time_operation_active'] = this.calcData(0, this.lastUpdate, 0, element.resource)
        });

        this.last_page = r.last_page;


        this.page = this.page + 1;
        if (this.page > this.last_page) {
          this.page = 1;
        }

        this.options = {
          orderBy: 'id',
          sortedBy: 'ASC',
          page: this.page,
          //adiciona pageSiza
          pageSize: 5,
          with: 'carrier',
        };

        this.drivers = r.data;
        this.loadCheckin(this.options);
      }, error: (e: any) => {

      }
    });

  }

  loadCheckin(options: {}) {
    this.checkinService.index(options).subscribe({
      next: (r: any) => {

        this.lastUpdate = new Date();

        r.data.forEach((element: { resource: any; }) => {
          element.resource['time_operation_active'] = this.calcData(0, this.lastUpdate, 0, element.resource)
        });

        this.drivers = r.data;
      }, error: (e: any) => {

      }
    });
  }

  nameOfOperation(id: number) {
    if (id) {
      const op = this.operations.filter((item: any) => {
        return item.id === id;
      });
      if (op.length > 0)
        return op[0].name;
      else
        return ""
    }
  }

  getOperations() {
    this.supportDataService.operation()
      .subscribe({
        next: (r: any) => {
          this.operations = r.data.resource;
        }, error: (e: any) => {
          console.log('erro');
        }
      });
  }

  calcData(date1: number, date2: number, tipo: number, resource?: any) {
    if (date1 == 0) {
      date1 = resource[`${resource.status}_created_at`]
    }

    let difference_ms = 0;
    let d: string;
    let hr: string;
    let min: string;
    let s: string;

    const dt1 = new Date(date1);
    const dt2 = new Date(date2);

    const date1_ms = dt1.getTime();
    const date2_ms = dt2.getTime();

    difference_ms = (date2_ms - date1_ms);

    if (tipo === 0) {
      s = this.padLeft(Math.floor((difference_ms / 1000) % 60), '0', 2) + 's';
      min = this.padLeft(Math.floor((difference_ms / 1000 / 60) % 60), '0', 2) + 'm';
      hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60)), '0', 2) + 'h';

      if ((difference_ms / 1000 / 60 / 60) > 24) {
        hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60) - (Math.floor(difference_ms / 1000 / 60 / 60 / 24) * 24)), '0', 2) + 'h';
        d = this.padLeft(Math.floor(difference_ms / 1000 / 60 / 60 / 24), '0', 2) + 'd';
        if (resource) {
          resource['alert'] = this.verificaAlertaTempo(resource.status, hr, min, d);
        }
        return [d, hr, min, s].join(':');
      } else {
        hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60)), '0', 2) + 'h';
        if (resource) {
          resource['alert'] = this.verificaAlertaTempo(resource.status, hr, min);
        }
        return [hr, min, s].join(':');
      }

    } else if (tipo === 1) {

      s = this.padLeft(Math.floor((difference_ms / 1000) % 60), '0', 2);
      min = this.padLeft(Math.floor((difference_ms / 1000 / 60) % 60), '0', 2);
      hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60)), '0', 2);

      if ([hr, min, s].join(':') > '00:02:10') {
        return 'offline';
      } else {
        return 'online';
      }

    } else {
      return '';
    }
  }

  padLeft(text: number, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substring((size * -1), size);
  }

  verificaAlertaTempo(status: string, hora: string, minuto: string, d?: any) {
    hora = hora.replace('h', '');
    minuto = minuto.replace('m', '');

    if (d) {
      return 2;
    } else if (status === 'waiting') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 15, 0, 5);
    } else if (status === 'denied') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 15, 0, 25);
    } else if (status === 'released') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 15, 0, 25);
    } else if (status === 'waiting_direction') {
      return this.verificaTempo(Number(hora), Number(minuto), 2, 0, 4, 0);
    } else if (status === 'directed_dock') {
      return this.verificaTempo(Number(hora), Number(minuto), 2, 0, 4, 0);
    } else if (status === 'loaded') {
      return this.verificaTempo(Number(hora), Number(minuto), 1, 0, 2, 0);
    } else if (status === 'outbound') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 30, 1, 0);
    } else if (status === 'finish_loaded') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 30, 1, 0);
    } else if (status === 'withdrawn') {
      return this.verificaTempo(Number(hora), Number(minuto), 1, 0, 2, 0);
    } else {
      return '';
    }
  }

  verificaTempo(hora: number, minuto: number, hora_media: number, minuto_media: number, hora_critica: number, minuto_critico: number) {
    if (hora > hora_critica) {
      return 2;
    } else if (hora >= hora_critica && minuto >= minuto_critico) {
      return 2;
    } else if (hora >= hora_media && minuto >= minuto_media) {
      return 1;
    } else {
      return 0;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  showStatus(resource: any) {
    const st = this.listStatus.filter((item: any) => item.id === resource.status);
    if (resource.status === 'on_dock') {
      return this.getDockName(resource.dock_space_id);
    } else {
      return st[0].alternate_name;
    }
  }

  getListStatus() {
    this.supportDataService.status().subscribe((r: any) => {
      this.listStatus = r.data.resource;
    });
  }

  getDockSpaces() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.dockSpaces = r.data.resource;
    });
  }

  getDockName(id: string) {
    const dn = this.dockSpaces.filter((item: any) => item.id === parseInt(id));
    return dn[0].name;
  }

  getClassAlert(alert: number) {
    if (alert === 1) {
      return 'alert-medium';
    } else if (alert === 2) {
      return 'alert-critical';
    } else if (alert === 0) {
      return 'alert-normal';
    } else {
      return '';
    }
  }

}
