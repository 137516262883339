import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

import { CoreModule } from './../../../core/core.module';
import { CardTableComponent } from './card-table/card-table.component';
import { Card1InfoComponent } from './card-1-info/card-1-info.component';
import { Card2InfoComponent } from './card-2-info/card-2-info.component';
import { CardChartsBarComponent } from './card-charts-bar/card-charts-bar.component';
import { CardDynamicTableComponent } from './card-dynamic-table/card-dynamic-table.component';
import { CardEchartsComponent } from './card-echarts/card-echarts.component';

@NgModule({
    imports: [
      MatDividerModule,
      MatCardModule,
      CoreModule,
      CommonModule,
      NgxEchartsModule
    ],
    exports: [
        Card1InfoComponent,
        CardTableComponent,
        Card2InfoComponent,
        CardChartsBarComponent,
        CardDynamicTableComponent,
        CardEchartsComponent
    ],
    declarations: [
        Card1InfoComponent,
        CardTableComponent,
        Card2InfoComponent,
        CardChartsBarComponent,
        CardDynamicTableComponent,
        CardEchartsComponent
    ]
})
export class KpiModule { }


