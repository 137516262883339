<div class="tkg-title-group">
  <p class="tkg-title">{{ "Business_model.schedule_window" | translate: {Default: "Janela de Agendamento"} }}</p>
  <button mat-button mat-stroked-button color="primary" class="tkg-new-btn" (click)="addEdit()">{{ "Business_model.new"
    | translate: {Default: "Novo"} }}</button>
</div>
<div class="tkg-description">{{ "Messages.set_time"
  | translate: {Default: "Defina o horário de início e fim do agendamento e o intervalo de tempo por operação."} }}</div>
<table class="tkg-table">
  <thead>
    <tr>
      <th *ngIf="!hiddenElements?.department === true">{{ "Business_model.department" | translate: {Default: "Departamento"} }}<br /></th>
      <th>{{ "Business_model.operation" | translate: {Default: "Operação"} }}<br /></th>
      <th *ngIf="!hiddenElements?.suboperation === true">{{ "Business_model.suboperation" | translate: {Default: "Sub-operação"} }}<br /></th>
      <th >{{ "Business_model.vehicle_type" | translate: {Default: "Tipo de Veículo"} }}<br /></th>
      <th>{{ "Business_model.start" | translate: {Default: "Início"} }}<br /></th>
      <th>{{ "Business_model.end" | translate: {Default: "Fim"} }}<br /></th>
      <th>{{ "Business_model.interval" | translate: {Default: "Intervalo"} }}<br /></th>
      <th>{{ "Business_model.limitscheduleslot" | translate: {Default: "Limite Slot"} }}<br /></th>
      <th style="width: 80px; text-align: center;">{{ "Business_model.actions" | translate: {Default: "Ações"} }}<br /></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of windows">
      <td *ngIf="!hiddenElements?.department === true">{{item.department}}</td>
      <td>{{item.operation}}</td>
      <td *ngIf="!hiddenElements?.suboperation === true">{{item.suboperation}}</td>
      <td>{{item.vehicletype}}</td>
      <td>{{item.start}}</td>
      <td>{{item.end}}</td>
      <td>{{formatTimeSlot(item.slot)}}</td>
      <td>{{item.limitscheduleslot}}</td>
      <td>
        <button mat-icon-button color="primary" (click)="addEdit(item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delete(item.id)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="windows.length === 0">
      <td colspan="100%"><p class="no-record">{{ "Messages.no_register" | translate: {Default: "Nenhum registro encontrado!"} }}</p></td>
    </tr>

  </tbody>
</table>
