import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationStart, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent {

  selectIdiom: Array<string>;
  languages = [
    ["1", "In-EUA", "en", "English"],
    ["2", "Es-ES", "es", "Español"],
    ["3", "Pt-BR", "pt-BR", "Português"]
  ];

  constructor (
    private translate: TranslateService,
    private router: Router
  ) {
    this.selectIdiom = this.languages[0];
  }

  onSelectIdiom(language: string[]) {
    this.selectIdiom = language;
    this.translate.use(language[2]);
    this.router.navigate([this.router.url])
  }
}
