import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { DocumentsService } from '../../documents/shared/documents.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;

  documents:any[] = [];
  document: any;
  files:{data: File, inProgress: boolean, progress: number}[] = [];
  uploadBtnDisabled: boolean = false;
  importBtnDisabled: boolean = true;
  acceptFileType: string;
  constructor(
    public documentsService: DocumentsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.acceptFileType = this.data.fileType;
  }

  uploadFile(file:any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.documentsService.upload(formData).pipe(
      map((event:any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.documents.push(event.body);
          this.uploadBtnDisabled = true;
          this.importBtnDisabled = false;
        }
      });
  }

  uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  deleteFile(item:any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.documentsService.delete(item).subscribe((r: any) => {
          if (r.success) {
            const idx = this.documents.findIndex(v => v.fileName === item.fileName);
            this.documents.splice(idx, idx >= 0 ? 1 : 0);
            this.files.splice(idx, idx >= 0 ? 1 : 0);
            this.uploadBtnDisabled = false;
            this.importBtnDisabled = true;
          }
        }, (e: any) => {

        });
      }
    });
  }

  import(){
    const systemClientId = JSON.parse(localStorage.getItem("account") || "{}").system_client?.id;
    const data = this.documents[0]

    switch(systemClientId){
      case 3:
        this.importLoreal(data);
        break;

      case 5:
        this.importBraskem(data);
        break;

      case 8:
        this.importJandira(data);
        break;

      case 16:
        this.importCLM(data);
        break;

      case 23:
          this.importLorealJarinu(data);
          break;

      case 109:
        this.importBraskemTriunfo(data);
        break;

      case 205:
        this.importDHLPepsico(data);
        break;

      default:
        this.batchScheduling(data)

    }

  }

  importLoreal(data:any){
    this.documentsService.importLoreal(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  importBraskem(data:any){
    this.documentsService.importBraskem(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  importBraskemTriunfo(data:any){
    this.documentsService.importBraskemTriunfo(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  importCLM(data:any){
    this.documentsService.importCLM(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  importLorealJarinu(data:any){
    this.documentsService.importLorealJarinu(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  importJandira(data:any){
    this.documentsService.importJandira(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  getFile(item:any){

  }

  importDHLPepsico(data:any){
    this.documentsService.importDHLPepsico(data).subscribe((r:any) => {
      if(r.error) {
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(r.message, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
    })
  }

  batchScheduling(data:any){
    this.importBtnDisabled = true;
    this.documentsService.batchScheduling(data)
    .subscribe({
      next:(r:any) => {
        if(r.error) {
          this.snackBar.open(r.message, "OK", {
            duration: 10000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
          this.dialog.open(DialogAlertComponent,{
            data:{
              dialog_type: "error",
              title: "Ocorreu um erro.",
              message: r.message
            }
          });
        } else {
          this.dialogRef.close();
          this.snackBar.open(r.message, "OK", {
            duration: 10000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error:(err:any)=>{
        this.dialog.open(DialogAlertComponent,{
          data:{
            dialog_type: "error",
            title: "Erro inesperado.",
            message: "Não foi possível executar o agendamento em massa."
          }
        })
      }
    })
    .add(()=>{
      this.importBtnDisabled = false;
    })
  }

}
