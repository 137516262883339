import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { KeycloakService } from "keycloak-angular";
import { catchError, map, tap } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Token } from "../token";
import { Account } from "../account";
import { environment } from "../../../environments/environment";
import KeycloakInitializer from '../../auth/keycloak-initializer';

@Injectable({
  providedIn: "root",
})
export class AccountService {
  helper: JwtHelperService = new JwtHelperService();
  @Output() event: EventEmitter<any> = new EventEmitter();


  public get account(){
    const account = localStorage.getItem("account");
    if(!account)
      return null;

    return JSON.parse(account);
  }

  private set account(value:Account|null){
    value ? localStorage.setItem("account", JSON.stringify(value)) : localStorage.removeItem("account");
  }

  get realmAccess(){
    return KeycloakInitializer.realmAccess
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    protected readonly keycloak: KeycloakService
  ) {}

  login(data: any): Observable<Token> {

    const login = new Observable<Token>((subscriber) => {
      KeycloakInitializer.login(
        data.realm,
        data.clientId,
        data.loginHint,
        data.urlRedirect
      ).then((v)=>{
        const token:Token = {
          token_type: "",
          expires_in: 60,
          access_token: "",
          refresh_token: ""
        };

        subscriber.next(token);
        subscriber.complete();
      })
    });

    login.subscribe()

    return login;
  }

  token(): any {
    const helper = new JwtHelperService();
    const token = KeycloakInitializer.token();
    return helper.decodeToken(token);
  }

  logout() {
    this.clearAccount();
    this.event.subscribe(({action})=>{
      action === "logout" && KeycloakInitializer.logout();
    })
    this.event.emit({ action: "logout" });
  }

  isAuthenticated(): boolean {
    return KeycloakInitializer.isAuthenticated();
  }

  create(data: any, params?: any): Observable<Account> {
    const url = `${environment.apiUrl}/users`;
    const options = { params: params };

    return this.http.post<Account>(url, data, options).pipe(
      tap((response: Response | any) => {
        this.event.emit({
          action: "create",
          data: Object.assign(response, {
            email: data.email,
            password: data.password,
          }),
        });
      }),
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  passwordToken(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/accounts/password/token`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      tap((response: Response | any) => {
        this.event.emit({
          action: "password_token",
          data: data,
        });
      }),
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  passwordReset(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/accounts/password/reset`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      tap((response: Response | any) => {
        this.event.emit({
          action: "password_reset",
          data: data,
        });
      }),
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  identity(renew?: boolean): Promise<Account> {
    return new Promise<Account>(async (resolve, reject) => {
      if (this.token()) {
        if (this.account != null && !renew) {
          resolve(this.account);
        } else {
          this.show().subscribe((account: Account) => {
            resolve(account);
          });
        }
      } else {
        // resolve();
      }
    });
  }

  show(options?: any): Observable<Account> {
    const jti = this.token()?.jti;
    const url = `${environment.apiUrl}/accounts/${jti}`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<Account>(url, { params }).pipe(
      tap((response: Account) => {
        this.account = response;
        this.event.emit({ action: "show", data: response });
      }),
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  update(data: any, id: string, params?: any): Observable<Account> {
    const url = `${environment.apiUrl}/accounts/${id}`;
    const options = { params: params };

    return this.http.put<Account>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  checkVersion() {
    const url = `${environment.apiUrl}/check-version`;
    return this.http
      .get(url)
      .pipe(catchError((err) => throwError(err || "Server error")));
  }

  updateSound(data: any): Observable<Account> {
    const url = `${environment.apiUrl}/updateSound`;

    return this.http.put<Account>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getClients<T=any>(email:string):Observable<T>{
    const url = `${environment.apiUrl}/user-clients/${email}`;

    return this.http.get<T>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  clearAccount():void{
    this.account = null;
  }

  logoutUserKeycloak(realm:string|number, userId:string){
    const url = `${environment.apiUrl}/user-keycloak-logout/${realm}/${userId}`;

    return this.http.get<boolean>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

}
