import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DockManagementService {

  constructor(private http: HttpClient) {}

  index(options?: any): Observable<any> {
    const httpParams = options;

    const params = new HttpParams({ fromObject: httpParams });

    const url = `${environment.apiUrl}/docks`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  show(id: string): Observable<any> {
    const url = `${environment.apiUrl}/docks/${id}`;

    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  update(data: any, id: string, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/docks/${id}`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/docks`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/docks/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

}
