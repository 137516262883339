// import { WebcamModule } from 'ngx-webcam';
import { BrowserModule } from '@angular/platform-browser';
import { DriversFotoPerfilWebcamComponent } from './drivers-foto-perfil/drivers-foto-perfil-webcam/drivers-foto-perfil-webcam.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriversListComponent } from './drivers-list/drivers-list.component';
import { DriversRoutingModule } from './drivers-routing.module';
import { CoreModule } from '../core/core.module';
import { DriverApprovalDialogComponent } from './driver-approval-dialog/driver-approval-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DriversEditComponent } from './drivers-edit/drivers-edit.component';
import { DriversCnhComponent } from './drivers-cnh/drivers-cnh.component';
import { DriversFotoPerfilComponent } from './drivers-foto-perfil/drivers-foto-perfil.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    declarations: [
      DriversListComponent,
      DriverApprovalDialogComponent,
      DriversEditComponent,
      DriversCnhComponent,
      DriversFotoPerfilComponent,
      DriversFotoPerfilWebcamComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        BrowserModule,
        DriversRoutingModule,
        NgxMatSelectSearchModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TextMaskModule
        // NgxMatSelectSearchModule,
        // WebcamModule
    ],
    providers:[
      provideNgxMask()
    ]
})
export class DriversModule { }
