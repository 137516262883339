import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { DriversService } from "src/app/drivers/shared/drivers.service";
// import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { SearchService } from "src/app/shared/search.service";
//import { DriverDetailComponent } from "../driver-detail/driver-detail.component";

@Component({
  selector: "app-driver-panel",
  templateUrl: "./driver-panel.component.html",
  styleUrls: ["./driver-panel.component.scss"],
})
export class DriverPanelComponent implements OnInit {
  form = this.formBuilder.group({
    red_time: [45],
    orange_time: [20],
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() reloadEvent = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private driverService: DriversService
  ) {}

  // open(action: string, item: any = {}) {
  //   const dialogRef = this.dialog.open(DriverPanelComponent, {
  //     panelClass: ["dialog-medium", "dialog-fullscreen"],
  //     disableClose: true,
  //     data: {
  //       item: JSON.parse(JSON.stringify(item)),
  //       action: action,
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((data) => {
  //     if (data) {
  //       if (action === "EDIT") {
  //         console.log("editando");
  //         this.snackBar.open("Usuário atualizado com sucesso!", "OK", {
  //           duration: 3000,
  //           horizontalPosition: "left",
  //           verticalPosition: "bottom",
  //         });
  //       } else if (action === "ADD") {
  //         console.log("criando");
  //         // If has previous page navigate to first page or reload in self page.
  //         this.paginator.hasPreviousPage()
  //           ? this.paginator.firstPage()
  //           : this.reload();
  //         //this.reload();
  //         this.snackBar.open("Target criado com sucesso!", "OK", {
  //           duration: 3000,
  //           horizontalPosition: "left",
  //           verticalPosition: "bottom",
  //         });
  //       }
  //     }
  //     this.ngOnInit();
  //   });
  // }

  // reload(params?: any) {
  //   return this.reloadEvent.emit(params);
  // }

  // delete(data) {
  //   const dialogRef = this.dialog.open(DialogConfirmComponent, {
  //     data: {
  //       title: "Confirma a exclusão?",
  //       message: `Tem certeza que deseja excluir o item ${data.full_name}?`,
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((confirm) => {
  //     console.log("deletando");
  //     if (confirm) {
  //       this.driverService.destroy(data.id).subscribe((response) => {
  //         this.reload();
  //         this.snackBar.open("Target removido com sucesso!", "OK", {
  //           duration: 3000,
  //           horizontalPosition: "left",
  //           verticalPosition: "bottom",
  //         });
  //       });
  //     }
  //     this.ngOnInit();
  //   });
  // }

  ngOnInit() {}
}
