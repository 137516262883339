<h3>{{data.title || "Tempo dos motoristas"}}</h3>
<div mat-dialog-content class="table-container">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="board_horse">
      <th mat-header-cell *matHeaderCellDef>Placa do Cavalo</th>
      <td mat-cell *matCellDef="let element"> {{element.board_horse}} </td>
    </ng-container>

    <ng-container matColumnDef="board_cart">
      <th mat-header-cell *matHeaderCellDef>Placa da Carreta</th>
      <td mat-cell *matCellDef="let element"> {{element.board_cart}} </td>
    </ng-container>

    <ng-container matColumnDef="driver_name">
      <th mat-header-cell *matHeaderCellDef> Motorista </th>
      <td mat-cell *matCellDef="let element"> {{element.driver_name}} </td>
    </ng-container>

    <ng-container matColumnDef="operation_name">
      <th mat-header-cell *matHeaderCellDef> Operação </th>
      <td mat-cell *matCellDef="let element"> {{element.operation_name}} </td>
    </ng-container>

    <ng-container matColumnDef="suboperation_name">
      <th mat-header-cell *matHeaderCellDef> Sub-operação </th>
      <td mat-cell *matCellDef="let element"> {{element.suboperation_name}} </td>
    </ng-container>

    <ng-container matColumnDef="stage_time">
      <th mat-header-cell *matHeaderCellDef> Tempo na Etapa </th>
      <td mat-cell *matCellDef="let element"> {{element.stage_time}} </td>
    </ng-container>

    <tr class="mat-row empty" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <div style="margin: 10px 0;">
          {{ searching ? "Processando resultados ..." : "Sem resultados" }}
        </div>
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="box-container mat-elevation-z2" style="position: sticky;z-index: 2;bottom: 0;">
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

<mat-dialog-actions align="end">
  <button type="button" mat-button color="primary" mat-dialog-close>Fechar</button>
</mat-dialog-actions>