import { of } from "rxjs";
import { delay, tap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AccountService } from '../shared/account.service';

@Component({
  selector: 'app-dialog-reauthenticate',
  templateUrl: './dialog-reauthenticate.component.html',
  styleUrls: ['./dialog-reauthenticate.component.scss']
})
export class DialogReauthenticateComponent {

  constructor(
    private accountService:AccountService,
    private dialogRef: MatDialogRef<DialogReauthenticateComponent>
  ){
    this.dialogRef.backdropClick()
    .subscribe(()=>{
      this.dialogRef.close();
      this.accountService.logout();
    });

    of(void 0)
    .pipe(delay(1000*90))
    .subscribe(()=>{
      this.dialogRef.close();
      this.accountService.logout();
    })
  }

  login(){
    const access = this.accountService.realmAccess.access;
    this.accountService.login({...access, loginHint: access?.user})
  }

  logout(){
    this.accountService.logout();
  }
}
