import { Component } from '@angular/core';
import { SupportDataService } from '../../support-data.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-formuly-ss-status',
  templateUrl: './formuly-ss-status.component.html',
  styleUrls: ['./formuly-ss-status.component.scss']
})
export class FormulySsStatusComponent extends FieldType {
  constructor(private supportDataService: SupportDataService) {
    super();
  }

  _formControl = new FormControl();
  filter: any = [];
  ngModule: any;
  selectedItem = false;

  ngOnInit() {
    this._formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        startWith(""),
        map((value) => value),
      )
      .subscribe((value) => {
        if (typeof value === "string") {
          this.filter = this.supportDataService.scheduleStatus({ search: value }).pipe(
            map((response: any) => response.data.resource) // Acessa o array de resource aqui
          );
        }
      });
  }
  

  clear() {
    this.ngModule = "";
    this.formControl.setValue("");
  }

  displaySelected(opt: any) {
    console.log(opt)
    if (opt) {
      this.formControl.setValue({ id: opt.id, name: opt.name });
      return opt && opt.name ? opt.name : "";
    }
  }

}
