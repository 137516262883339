<div class="container-filters">
  <div class="filters">
    <div *ngIf="isWeb && filterEnabled('carrier')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.carrier }}</mat-label>
        <mat-select [formControl]="filterCarrierSelected">
          <mat-option (click)="getFilter('carrier')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let tr of carriers" [value]="tr.id" (click)="getFilter('carrier_id', tr.id)">
            {{tr.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="isWeb && filterEnabled('department')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.department }}</mat-label>
        <mat-select [formControl]="filterDepartmentSelected">
          <mat-option (click)="getFilter('department_id')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let st of departments" [value]="st.id" (click)="getFilter('department_id', st.id)">
            {{st.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="isWeb && filterEnabled('operation')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.operation }}</mat-label>
        <mat-select [formControl]="filterOperationSelected">
          <mat-option (click)="getFilter('operation')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let st of operations" [value]="st.id" (click)="getFilter('operation', st.id)">
            {{st.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="isWeb && filterEnabled('vehicle_type')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.vehicle_type }}</mat-label>
        <mat-select [formControl]="filterVehicleTypeSelected">
          <mat-option (click)="getFilter('vehicle_type')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let vt of vehicle_types" [value]="vt.id" (click)="getFilter('vehicle_type', vt.id)">
            {{vt.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="isWeb && filterEnabled('status')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.status }}</mat-label>
        <mat-select>
          <mat-option (click)="getFilter('status')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let ls of listStatus" [value]="ls.id" (click)="getFilter('status', ls.id)">
            {{ls.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="isWeb && filterEnabled('checkin_mht')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.checkin_mht }}</mat-label>
        <mat-select>
          <mat-option (click)="getFilter('checkin_mht')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let ck of listCheckinMht" [value]="ck.value" (click)="getFilter('checkin_mht', ck.value)">
            {{ck.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="isWeb && filterEnabled('search')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.search }}</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="container">
  <div class="mat-elevation-z8 table-container">
    <table mat-table [dataSource]="dataSource" matSort [ngClass]="{
      'tablet-table': breakpointObserver.isMatched(breakpoints.TabletLandscape)}">

      <ng-container matColumnDef="board" sticky>
        <th mat-header-cell *matHeaderCellDef>Placa</th>
        <td mat-cell *matCellDef="let element">{{element.resource.board}}</td>
      </ng-container>

      <ng-container matColumnDef="vehicle_type">
        <th mat-header-cell *matHeaderCellDef>Tipo de Veiculo</th>
        <td mat-cell *matCellDef="let element">{{showVehicleType(element)}}</td>
      </ng-container>

      <ng-container matColumnDef="lacre">
        <th mat-header-cell *matHeaderCellDef>Lacre</th>
        <td mat-cell *matCellDef="let element">
          {{element.resource.lacre ? element.resource.lacre : '--'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="appointment">
        <th mat-header-cell *matHeaderCellDef>Appointment</th>
        <td mat-cell *matCellDef="let element">
          {{element.resource.appointment ? element.resource.appointment : '--'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="checkin_mht">
        <th mat-header-cell *matHeaderCellDef>Checkin MHT</th>
        <td mat-cell *matCellDef="let element">
          {{element.resource.checkin_mht ? element.resource.checkin_mht : '--'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef>Transportadora</th>
        <td mat-cell *matCellDef="let element">{{element.carrier? element.carrier.name : '--'}}</td>
      </ng-container>

      <ng-container matColumnDef="event_id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">
          {{showId(element.event)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="department" >
        <th mat-header-cell *matHeaderCellDef>Departamento</th>
        <td mat-cell *matCellDef="let element">
          {{element.checkin && element.checkin.resource.department_id ? showDepartment(element.checkin.resource.department_id) : '--'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-chip-listbox>
            <mat-chip [style.background-color]="getStatusColor(element?.resource.status)" [style.color]="'#ffffff'">
              {{showStatus(element?.resource)}}
            </mat-chip>
          </mat-chip-listbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="changeStatus(element, action)" *ngFor="let action of listAction"
              [disabled]="disableBtn(element.resource.status, action.active_on_status)">
              {{action.name}}
            </button>

          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.TabletLandscape);"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>

  <div class="paginator mat-elevation-z4" style="position: sticky; z-index: 2; bottom: 0;">
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
