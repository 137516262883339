<mat-progress-bar *ngIf="saving" mode="indeterminate"></mat-progress-bar>
<h2 mat-dialog-title>{{ data.type == "shift" ? "Turno" : "Pausa" }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="shiftForm" (ngSubmit)="onSubmit()">
    <div>
      <mat-form-field appearance="outline" fxFlex="100%" style="width: 100%;">
        <mat-label>Nome</mat-label>
        <input formControlName="name" type="text" placeholder="Nome" matInput>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Turno</mat-label>
        <mat-select formControlName="shift_shifts_id">
          <mat-option>Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let item of shiftShifts" [value]="item.id">
            {{item.name | uppercase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="margin-bottom: 24px;">
      <mat-button-toggle-group
        multiple
        name="weekday"
        formControlName="weekday"
        aria-label="Dias da semana"
      >
        <mat-button-toggle [value]="weekDay.Monday">Segunda</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Tuesday">Terça</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Wednesday">Quarta</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Thursday">Quinta</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Friday">Sexta</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Saturday">Sábado</mat-button-toggle>
        <mat-button-toggle [value]="weekDay.Sunday">Domingo</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="timer-container">
      <div class="timer">
        <span class="before">Início</span>
        <ngx-mat-timepicker
          formControlName="start"
          [showSpinners]="true"
          [stepHour]="1"
          [stepMinute]="1"
          [showSeconds]="false"
        >
      </ngx-mat-timepicker>


    </div>
    <div>
      <mat-form-field appearance="outline" style="width: 90px;">
        <mat-label>Duração</mat-label>
        <input matInput placeholder="00:00" formControlName="duration" matInput [readonly]="false">
      </mat-form-field>
    </div>
      <div class="timer">
        <span class="before">Término</span>
        <ngx-mat-timepicker
          formControlName="end"
          [showSpinners]="true"
          [stepHour]="1"
          [stepMinute]="1"
          [showSeconds]="false"
          value="00:00"
        >
      </ngx-mat-timepicker>


    </div>
    <mat-hint *ngIf="turnOfday">Turno com virada de dia</mat-hint>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="false"
    [disabled]="this.saving"
  >
    {{ data.action != dialogAction.VIEW ? "Cancelar" : "Fechar" }}
  </button>
  <button
    *ngIf="data.action != dialogAction.VIEW"
    mat-button
    cdkFocusInitial
    color="primary"
    [disabled]="!this.shiftForm.valid || this.saving"
    (click)="onSubmit()"
  >
    Salvar
  </button>
</mat-dialog-actions>

