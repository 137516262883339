import { DashboardDynamicStructureComponent } from './dashboard-dynamic-structure/dashboard-dynamic-structure.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuard } from '../account/shared/route.guard';

const routes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/dashboard-dynamic',
    component: DashboardDynamicStructureComponent,
    data: {
      title: 'Dashboard',
      search: false,
      refresh: false
    }
  }
];

export const DashboardDynamicRoutingRoutes = RouterModule.forChild(routes);
