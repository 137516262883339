import { FieldType } from "@ngx-formly/core";
import { Component, OnInit } from '@angular/core';
import { AgendamentoService } from 'src/app/multiagendamento/shared/agendamento.service';

@Component({
  selector: 'formly-schedule-code',
  templateUrl: './formly-schedule-code.component.html',
  styleUrls: ['./formly-schedule-code.component.scss']
})
export class FormlyScheduleCodeComponent extends FieldType<any> implements OnInit {
  checkboxValue = true;
  initialGeneratedScheduleCode: string;
  currentScheduleCode: string;

  constructor(
    public agendamentoService: AgendamentoService
  ) {
    super();
  }

  ngOnInit() {
    //Se já possuir um código, preencher o campo com o seu valor
    if(this.formControl.value) {
      this.currentScheduleCode = this.formControl.value;
      this.formControl.patchValue(this.currentScheduleCode);

      //Se a opção alwaysAuto for verdadeira, desabilitar o form pois o código sera gerenciado automaticamente
      if(this.field.props?.alwaysAuto) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
        this.checkboxValue = false;
      }

    }

    this.generateInitialCode();
  }

  //Chama o método na API para gerar um código númerico aleatório
  generateInitialCode() {
    this.agendamentoService.generateScheduleCodeV1().subscribe((r: any) => {
      this.initialGeneratedScheduleCode = r;

      //Se já possuir valor no campo, não preencher o campo com o código gerado
      if(!this.formControl.value) {
        this.formControl.patchValue(this.initialGeneratedScheduleCode);
        this.formControl.disable();
       }

    });
  }

  changeScheduleCode() {
    //Ao marcar o checkbox, desabilitar o campo e passar o valor gerado no método generateInitialCode()
    if (this.checkboxValue) {
      this.formControl.patchValue(this.initialGeneratedScheduleCode);
      this.formControl.disable();
    } else {
      //Ao desmarcar o checkbox, habilitar o campo e passar o valor que foi passado pelo formControl, ou deixa o campo vazio se não possui-lo
      this.formControl.patchValue(this.currentScheduleCode ? this.currentScheduleCode : "");
      this.formControl.enable();
    }
  }

}
