import { Component, OnInit, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-finish-options-dialog',
  templateUrl: './finish-options-dialog.component.html',
  styleUrls: ['./finish-options-dialog.component.scss']
})
export class FinishOptionsDialogComponent implements OnInit {
  form = this.formBuilder.group({
    finish_option_id: [1, Validators.required]
  });

  options: any = [
    { "id": 1, "name": "Carreta completa", "checked": true, have_cart: false, have_horse: false },
    { "id": 2, "name": "Apenas cavalo", "checked": false, have_cart: true, have_horse: false },
    { "id": 3, "name": "Apenas motorista", "checked": false, have_cart: true, have_horse: true }
  ];

  dialogResult: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public supportData: SupportDataService,
    public dialogRef: MatDialogRef<FinishOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }
  onSubmit() {
    let { finish_option_id } = this.form.value;

    let {
      item: { id },
      action: { status }
    } = this.data;

    this.dialogResult = {
      ...this.form.value,
      id,
      status
    };

    if (finish_option_id === 1) {
      this.dialogResult.to_be_recovered = false;
    } else {
      // Verifica as opções adicionais
      let {have_cart, have_horse} = this.options.find((item: any) => item.id === finish_option_id)

      this.dialogResult = {
        ...this.dialogResult,
        to_be_recovered: true,
        have_cart,
        have_horse
      }
    }

    console.log(this.dialogResult);

    this.dialogRef.close(this.dialogResult);
  }
}
