<div class="tkg-title-group"><p class="tkg-title">{{ "Business_model.schedule_status" | translate:{Default: "Status do Pré Agendamento"} }}</p>
    <button mat-button mat-stroked-button color="primary" class="tkg-new-btn" (click)="addEdit()">{{ "Business_model.new" | translate:{Default: "Novo"} }}</button></div>
    <div class="tkg-description">
      {{ "Messages.choose_schedule_status" | translate:{Default: "Defina qual será o status do pré agendamento no momento da crição ou edição."} }}
    </div>
  <table class="tkg-table">
    <thead>
      <tr>
        <th *ngIf="!hiddenElements?.department === true">{{ "Business_model.department" | translate:{Default: "Departamento"} }}<br /></th>
        <th>{{ "Business_model.operation" | translate:{Default: "Operação"} }}<br /></th>
        <th *ngIf="!hiddenElements?.suboperation === true">{{ "Business_model.suboperation" | translate:{Default: "Sub-operação"} }}<br /></th>
        <th>{{ "Business_model.schedule_status_created" | translate:{Default: "Status ao criar"} }}<br /></th>
        <th>{{ "Business_model.schedule_status_edit" | translate:{Default: "Status ao Editar"} }}<br /></th>
        <th>{{ "Business_model.schedule_status_information" | translate:{Default: "Status ao editar informações"} }}<br /></th>
        <th>{{ "Business_model.groups" | translate:{Default: "Grupo(s)"} }}<br /></th>
        <th style="width: 80px; text-align: center;">{{ "Business_model.actions" | translate:{Default: "Ações"} }}<br /></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of status">
        <td *ngIf="!hiddenElements?.department === true">{{item.department}}</td>
        <td>{{item.operation}}</td>
        <td *ngIf="!hiddenElements?.suboperation === true">{{item.suboperation}}</td>
        <!--<td>{{item.suboperation == null ? "-" : item.suboperation}}</td>-->
        <td>{{showScheduleStatusName(item.status_on_create)}}</td>
        <td>{{showListScheduleStatusName(item.status_on_edit)}}</td>
        <td>{{showScheduleStatusName(item.status_on_info_edit)}}</td>
        <td>{{showGroupName(item.groups)}}</td>
        <td>
          <button mat-icon-button color="primary" (click)="addEdit(item)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(item.id)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="status.length === 0">
        <td colspan="100%"><p class="no-record">{{ "Messages.no_register" | translate: {Default: "Nenhum registro encontrado!"} }}</p></td>
      </tr>
    </tbody>
  </table>
  