import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Subject, Observable, merge, startWith, switchMap, tap, map, catchError, of } from 'rxjs';

import { ShiftsService } from '../../shared/shifts.service';
import { ShiftShiftDialogComponent } from '../../dialogs/shift-shift-dialog/shift-shift-dialog.component';
import { IndexShiftScheduleResponseInterface, ShiftActions, ShiftScheduleInterface, Viewport } from '../../shifts';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-tab-shift-schedules',
  templateUrl: './tab-shift-schedules.component.html',
  styleUrls: ['./tab-shift-schedules.component.scss']
})
export class TabShiftSchedulesComponent implements AfterViewInit{

  @Input() viewport$:Observable<Viewport>;

  @ViewChild('sort', {static:false}) sort: MatSort;
  @ViewChild('paginator', {static:false}) paginator: MatPaginator;

  pageSize = 10;
  trashed = false;
  viewTable = true;
  actionEnum = ShiftActions;
  shiftSchedulesSubject$ = new Subject<any>();

  shiftBreakColumns:Array<[string, string, boolean, any?]>=[
    ["Nome","name", true],
    ["Dias da semana", "weekday", false, "weekdaynamely"],
    ["Turno", "shift_name", false],
    ["Início","start", false],
    ["Termino","end", false],
    ["Duração","duration", false]
  ];

  shiftSchedules: Record<string, any> = {
    name:"shift",
    columns:this.shiftBreakColumns.map(item=>({
      key:item[1],
      name:item[0],
      sort:item[2],
      pipe:item[3],
    })),
    displayedColumns:[...this.shiftBreakColumns.map(item=>item[1]),'actions'],
    dataSource:[]
  };

  constructor(
    private dialog: MatDialog,
    private shiftsService: ShiftsService
  ){}

  ngAfterViewInit(): void {
    this.initService()
    .subscribe((data)=>{
      this.shiftSchedules.dataSource = data;
    });
    this.viewport$.subscribe((viewport)=>{
      this.viewTable = !((["200-500", "500-800"] as any[]).includes(viewport));
    })
  }


  open(action:ShiftActions, data?:any){
    return this.dialog.open(ShiftShiftDialogComponent, {
      disableClose:true,
      data:{
        action,
        data
      },
      maxWidth:540,
      minWidth:320
    })
    .beforeClosed()
    .subscribe((reload:boolean)=>{
      reload && this.shiftSchedulesSubject$.next(void 0);
    })
  }


  destroy(element:ShiftScheduleInterface){
    const message = `Deseja excluir o item "${element.resource.name}"?`;
    const title = "Exclusão de turno.";

    this.dialog.open(DialogConfirmComponent, {
      data:{
        title,
        message
      }
    })
    .beforeClosed()
    .subscribe((destroy:boolean)=>{
      destroy
      &&
      this.shiftsService
      .destroyShiftSchedules(element.id!)
      .subscribe(()=>this.shiftSchedulesSubject$.next(void 0))
    })
  }

  private initService(){
    this.sort.sort(<MatSortable>{
        id: 'name',
        start: 'asc'
      }
    );

    this.sort.sortChange.subscribe(() =>this.paginator.pageIndex = 0);

    return  merge(...[this.sort.sortChange, this.paginator.page, this.shiftSchedulesSubject$].filter(i=>i))
      .pipe(
        startWith(null),
        switchMap(() => {
          this.shiftSchedules.dataSource = [];

          const options:any = {
            page: this.paginator?.pageIndex + 1,
            orderBy: this.sort?.active,
            sortedBy: this.sort?.direction
          };

          return this.shiftsService.indexShiftSchedules(options);
        }),
        tap((response: IndexShiftScheduleResponseInterface) => {
          if(this.paginator){
            if (!response.data.length && this.paginator?.hasPreviousPage()) {
              this.paginator.previousPage();
            }

            this.paginator.length = response.total;
            this.paginator.pageSize = response.per_page;
          }
        }),
        map((response) => {
          return response.data;
        }),
        catchError(() => {
          return of<ShiftScheduleInterface[]>([]);
        })
      ).pipe(map((data)=>{
        return data;
      }))
  }
}
