import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountGuardService implements CanActivate {

  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!this.accountService.isAuthenticated()) {
      this.router.navigateByUrl('auth/login', {skipLocationChange: false}).then(()=>{
        window.location.reload();
      })
      return false;
    }
    return true;
  }
}
