import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from "moment";
import { CardInterface } from '../../card.interface';
import { DriversFotoPerfilComponent } from "src/app/drivers/drivers-foto-perfil/drivers-foto-perfil.component";
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
import { PainelMotoristaService } from "src/app/painel-motorista-loreal/shared/painelmotorista.service";
import { PainelMotoristaListComponent } from "src/app/painel-motorista-loreal/painel-motorista-list/painelmotorista-list.component";
import { TransformMethodUtil } from 'src/app/shared/util/transformMethod';
import { DetailsMapsComponent } from 'src/app/shared/details-maps/details-maps.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'card-driver',
  templateUrl: './card-driver.component.html',
  styleUrls: ['./card-driver.component.scss']
})
export class CardDriverDefaultComponent implements OnInit, CardInterface {
  _data = (col:any) => {

    let val;
    const data = this.data;

    if(col.transform_method){
      col.transform_params && eval(`try {val = data.${col.transform_params}} catch(exception){val = ""}`);
      let text: any;
      let dynMethod = this[col.transform_method as keyof typeof this];
      if(typeof dynMethod === 'function')
      {
        text = dynMethod.call(this, val);
      }
      else{
        let transformMethod = this.transformMethodUtil[col.transform_method as keyof typeof this.transformMethodUtil]
        if(typeof transformMethod === 'function'){
          text = transformMethod.call(this.transformMethodUtil, val);
        }
      }
      return text;
    }else if(col.data_key.indexOf(".") > 0){
      eval(`try {val = data.${col.data_key}} catch(exception){val = ""}`);
      return val;
    } else {
      return this.data[col.data_key] || "";
    }
  };

  account: any = JSON.parse(localStorage.getItem("account")!);
  data:any = {};
  dockSpaces: any = [];
  imageIcon:string|SafeStyle="";
  layout:number = 10;
  listStatus: any = [];
  selectedCard: any;
  driverDetails: [] = [];
  options:any;
  operationsAll: any[] = [];
  suboperationsAll: any[] = [];
  destinyAll: any[] = [];
  statusColor = "";
  isTripHitch = false;
  driverDetailsDefault = [[
    {
      "name": "Placa Cavalo",
      "data_key": "board_horse"
    },
    {
      "name": "CPF",
      "data_key": "cpf"
    },
    {
      "name": "Placa Carreta",
      "data_key": "board_cart"
    },
    {
      "name": "Transportadora",
      "data_key": "carrier.name"
    }
  ]];
  operations: any = [];
  suboperations: any = [];

  translateSource:any;
  _timeOnStatus: any;
  _operationDuration: any;
  constructor(
    private dialog:MatDialog,
    private sanitizer: DomSanitizer,
    private supportDataService: SupportDataService,
    private painelMotoristaService: PainelMotoristaService,
    private painelMotoristaList: PainelMotoristaListComponent,
    private transformMethodUtil : TransformMethodUtil,
  ) { }

  ngOnInit() {

    const driver = this.data.driver || {};

    if(driver.resource?.photoPerfil){
      const imageIcon = (
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(driver.resource.photoPerfil)
        ? "data:image/png;base64,"
        : ""
      ).concat(driver.resource.photoPerfil);

      this.imageIcon = this.sanitizer.bypassSecurityTrustStyle(`url("${imageIcon}")`);
    }

    this.listStatus = this.options.listStatus || [];
    this.dockSpaces = this.options.dockSpaces || [];
    this.translateSource = this.options.translateSource;

    this.modalConfig();

     //Verifica se a propriedade de Engate/Desengate existe na system_client, se sim atribui seu valor a isTripHitch
     if(this.account.system_client.resource.environment.trip_hitch) {
      this.isTripHitch = this.account.system_client.resource.environment.trip_hitch;
     }

    /**
     * This workaround is to prevent the error: ExpressionChangedAfterItHasBeenCheckedError.
     * Since the template is evaluated many times the function timeOnStatus incitate this errors because
     * its value changes, which is taken in count as an error for angular.
    */
     this._timeOnStatus = this.timeOnStatus(this.data.resource);

     this._operationDuration = this.operationDuration(this.data.resource);

      const statusColor =  this.listStatus.find((o: any) => o.id == this.data.status);

      if (statusColor) {
        this.statusColor = statusColor.color;
      }
  }

  modalConfig() {
      const cardDetails = this.account.system_client.resource.environment.pages.painel_motoristas.card_details || {};
      this.driverDetails = cardDetails.driver_details || this.driverDetailsDefault;
  }

  checkPhoto(event: any, action: string) {
    const dialogRef = this.dialog.open(DriversFotoPerfilComponent, {
      height: "560px",
      width: "528px",
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(this.data)),
      },
    });
  }

  showStatus() {
    const resource = this.data.resource || {};
    const st:any = this.listStatus.filter(
      (item: any) => item.id === resource.status
    );
    if (resource.status === "on_dock") {
      return this.getDockName(resource.dock_space_id);
    } else if(this.translateSource){
      const translated = this.translateSource.status[resource.status];
      if(translated){
        return translated;
      }
    } else {
      if (resource.status === "") {
        return " - ";
      }
      if(st.length > 0) {
        return st[0].alternate_name;
      }
    }
    return "";
    }

  //Mostra os status em cards do Painel de Motoristas de clientes que utilizam Engate/Desengate
  showTruckerStatus(): any {
    if(this.isTripHitch) {

      if(this.data.status == 'checkin') {
        this.statusColor = "#54D958";
        return "Check-in";
      } else if(this.data.status == 'checkout') {
        this.statusColor = "#3490FA";
        return "Checkout";
      }

    }
  }

  formatOperation(id: number) {
    let result;
    this.operations.map((item: any) => {
      if (item.id == id) {
        result = item.name;
      }
    });
    return result;
  }

  formatSubOperation(id: number) {
    let result;
    this.suboperations.map((item: any) => {
      if (item.id == id) {
        result = item.name;
      }
    });
    return result;
  }

  secondsToMinutes(segundos: number): any {
    if (segundos) {
      return moment
        .duration(segundos, "seconds")
        .format("DD[d] HH[h] mm[min]");
    } else {
      return null;
    }
  }

  timeOnStatus(resource: any): any {
    //Verifica se o resource existe e ou se o painel é de Engate/Desengate antes de prosseguir
    if (resource || !this.isTripHitch) {
      const status = resource.status;
      const statusTs = status + "_created_at";
      const now = moment();
      const statusTime = moment(resource[statusTs]);

      const timeDifference = {
        days: moment.duration(Math.abs(now.diff(statusTime))).days(),
        hours: moment.duration(Math.abs(now.diff(statusTime))).hours(),
        minutes: moment.duration(Math.abs(now.diff(statusTime))).minutes(),
      };

      const formatedTime = `${timeDifference.days > 0 ? timeDifference.days + 'd ' : ''}${timeDifference.hours}h ${timeDifference.minutes}min`;
      return formatedTime;

      //return moment.duration(Math.abs(now.diff(statusTime)))
      //.format("DD[d] H[h] mm[min]");
    }
  }

  operationDuration(resource: any): any {
    //Verifica se o resource existe e ou se o painel é de Engate/Desengate antes de prosseguir
    if (resource || !this.isTripHitch) {
      return moment.duration(
        moment().diff(
          moment(resource["waiting_created_at"])
        )
      ).format("DD[d] HH[h] mm[min]");
    }
  }

  showIdOrSid(resource: any) {
    if (resource.sid) {
      return resource.sid;
    } else {
      return resource.external_event_id;
    }
  }

  getOperations(){
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operationsAll = r.data.resource;
    });
  }

  getDestiny(){
    this.supportDataService.generic({name: "locations"}).subscribe((r: any) => {
      this.destinyAll = r.data.resource;
    });
  }

  showDestiny(id: number){
    return [].concat(this.destinyAll as never[])
      .reduce((o: any, item: any) => {
          if((item) instanceof Object && item.id == id){
              o.name = item.name + " - " + item["sub-area"];
          }
          return o;
      }, {name:'N.A'}).name
  }

  getSuboperations(){
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.suboperationsAll = r.data.resource;
    });
  }

  openAlertDialog(event: any) {
    const dialogRef = this.dialog.open(DetailsMapsComponent, {
      panelClass: ["dialog-large"],
      height: "500px",
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(this.data)),
      },
    });
    dialogRef.afterClosed().subscribe((data) => { });
  }

  getDockName(id: number) {
    if(this.account.department_id.length > 1&& this.account.system_client.resource.environment.docks_shared === false) {
      const deps: any[] = [];
      this.account.department_id.forEach((department: any) => {
        if(this.dockSpaces.hasOwnProperty(department)){
          const dn = this.dockSpaces[department+""].filter((item: any) => item.id == id);
          if (dn.length > 0) {
            deps.push(dn[0].name);
          }
        }
      });
      return deps[0] || "";
    } else {
      const dn = this.dockSpaces.filter((item: any) => item.id == id);
      if (dn.length > 0) {
        return dn[0].name;
      }
    }
 }

  rotateImg(img:HTMLImageElement) {
    return img.width > img.height ? 'rotate(270deg)' : "rotate(0deg)";
  }

  //Faz o checkout do motorista (trucker_checkin) para  módulo Engate/Desengate
  driverCheckout(data: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma o checkout",
        message: `Tem certeza que deseja realizar o checkout?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        data = {
          truckerCheckinId: data.trucker_checkin_id,
          driverCheckinId: data.driver_checkin_id
        }
        this.painelMotoristaService.truckerCheckout(data).subscribe({
          next: () => {
            this.painelMotoristaList.initObservers();
          }, error: (error) => {
            if (error.error && error.error.message) {
              this.dialog.open(DialogAlertComponent, {
                data: {
                  title: "Erro",
                  message: `Occoreu um erro durante a processo de checkout`,
                },
              });
            }
          }
        });
      }
    });

  }
    //#region TRANSFORM_METHOD
    // fromScheduleFieldOptions(param){
    //   let [id, option, _default = ""] = param;
    //   const found = [].concat(this.scheduleFieldsOptions[option]).filter(x=>x).find(o=>o.id == id);
    //   return found ? found.name : _default
    // }
    //#endregion
}
