import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportListComponent } from './report-list/report-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { EventReportListComponent } from './event-report-list/event-report-list.component';
import { ReportsComponent } from './reports/reports.component';
import { RouteGuard } from '../account/shared/route.guard';

const reportRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/reports',
    component: ReportsComponent,
    data: {
          title: 'Tipos de Relatórios',
          search: false,
          refresh: false,
          btnDownloadReport: false,
          btnStatusPanel: false
        }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/event-report',
    component: EventReportListComponent,
    data: {
          title: 'Relatório de Agendamentos',
          search: false,
          refresh: false,
          btnDownloadReport: true,
          btnStatusPanel: false
    }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/report',
    component: ReportListComponent,
    data: {
          title: 'Relatório de Check in',
          search: false,
          refresh: false,
          btnDownloadReport: true,
          btnStatusPanel: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(reportRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class ReportRoutingModule {
}
