import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DriversService } from '../shared/drivers.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DocumentsService } from 'src/app/documents/shared/documents.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-drivers-cnh',
  templateUrl: './drivers-cnh.component.html',
  styleUrls: ['./drivers-cnh.component.scss']
})

export class DriversCnhComponent implements OnInit {

  form = this.formBuilder.group({
    name: [this.data.item.driver.name, [Validators.required]],
      resource: this.formBuilder.group({
        cnh: [this.data.item.driver.resource.cnh, [Validators.required]],
        photo: [this.data.item.driver.resource.photo],
      })
  });

  filedata: any;
  fileEvent(e:any) {
    this.filedata = e.target.files[0];
    this.onUpload();
  }

  errors: any = {};

  constructor(
    public dialogRef: MatDialogRef<DriversCnhComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private driversService: DriversService,
    private dialog: MatDialog,
    public documentsService: DocumentsService,
  ) {}

  ngOnInit() {

  }

  onUpload() {

    if (this.isValidImage(this.filedata)) {

      const formData = new FormData();
      formData.append('file', this.filedata);
      formData.append('driver_id', this.data.item.driver.id);
      //Informa para a API que é o arquivo é uma imagem da tela de motoristas
      formData.append('file_type', 'driver_photo');
      this.filedata.inProgress = true;

      this.documentsService.upload(formData).pipe(
        map((event:any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.filedata.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }

        }),

        catchError((error: HttpErrorResponse) => {
          this.filedata.inProgress = false;
          //Caso não seja encontrado o motorista durante o processo de upload
          //Retorna mensagem de erro para o usuário
          if (error.status == 400) {

            this.dialog.open(DialogAlertComponent, {
              data: { title: error.error.title, message: error.error.message },
            });

            return of(`Motorista não encontrado, upload abortado`);
          } else {
            return of(`File upload failed.`);
          }
        })

      ).subscribe((event: any) => {
        if (typeof (event) === 'object') {

          //Chama método para atualizar a url da foto do motorista no banco
          //Campo photoType informa para a API se é o campo 'photoPerfil' (Perfil) ou 'photo' (CNH) do motorista que irá receber a nova url
          this.driversService.updateDriverPhotos({ url: event.body.url, photoType: 'photo' }, this.data.item.driver.id).subscribe(
            (data) => {
              //Se atualização da URL for feita com sucesso, atualiza o html com a nova imagem para o usuário
              this.data.item.driver.resource.photo = event.body.url;
            },
            (err) => {
              if (err.status === 422) {
                this.errors = err.error.errors;
              } else {
                this.dialog.open(DialogAlertComponent, {
                  data: { title: err.error.title, message: err.error.message },
                });
              }
            }
          );
        }

      });
    }

  }

  isValidImage(file:any) {
    if (file.size > 5000000) {
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Erro', message: 'Tamanho da imagem não pode ser maior que 5MB!' }
      });
      return false;
    }
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Erro', message: 'Tipo de arquivo não é suportado, por favor escolha um tipo PNG ou JPEG!' }
      });
      return false;
    }
    return true;
  }

  exibirAvisoFoto() {
    document.getElementById('uploadFoto')?.click();
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
