import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccountGuardService } from "../account/shared/account-guard.service";
import { GenericViewListComponent } from "./generic-view-list/generic-view-list.component";
import { GenericViewMapComponent } from "./generic-view-map/generic-view-map.component";


const GenericViewRoutes: Routes = [
  {
    path: "admin",
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "view/table",
        component: GenericViewListComponent,
        data: {
          title: "Tabela",
          search: true,
          refresh: false
        }
      },
      {
        path: "view/map/:page",
        component: GenericViewMapComponent,
        data: {
          title: "Mapa",
          search: true,
          refresh: false
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(GenericViewRoutes)],
  exports: [RouterModule]
})
export class GenericViewRoutingModule { }
