<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <mat-form-field class="tkg-input">
      <mat-label>Defina a data</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        formControlName="date"
        (dateChange)="switchDay()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="tkg-input">
      <mat-label>Hora</mat-label>
      <mat-select formControlName="time" required>
        <mat-option [value]="slot.time" *ngFor="let slot of timeSlots"
          >{{slot.slot}}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{save: false}">Cancelar</button>
  <button
    mat-raised-button
    style="color: white"
    color="primary"
    (click)="save()"
    [disabled]="!form.valid"
  >
    Salvar
  </button>
</mat-dialog-actions>
