import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from "src/app/shared/support-data.service";

@Component({
  selector: "app-schedule-window-by-weight-dialog",
  templateUrl: "./schedule-window-by-weight-dialog.component.html",
  styleUrls: ["./schedule-window-by-weight-dialog.component.scss"],
})
export class ScheduleWindowByWeightDialogComponent {
  weightRangeList: any[] = [];
  form = this.formBuilder.group({
    weight_range_id: [this.data.weight_range, Validators.required],
    operation_id: [this.data.operation_id],
    suboperation_id: [this.data.suboperation_id],
    start: [this.data.start, Validators.required],
    end: [this.data.end, Validators.required],
    slot: [this.data.slot, Validators.required],
    limitBySlotSize: [this.data.limitbyslotsize],
    limitScheduleSlot: [this.data.limitscheduleslot],
  });

  validationMsgs: any[] = [];
  account: any;
  allDataLoaded = false;
  nonEditableField = false;

  operations: any[] = [];
  vehicleTypes: any[] = [];
  suboperations: any[] = [];
  suboperationsFiltered: any[] = [];
  operationsFiltered: any[] = [];
  showSuboperation = false;

  constructor(
    public dialogRef: MatDialogRef<ScheduleWindowByWeightDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getWeightRange();
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getOperations();
    this.getSubOperations();
  }

  close() {
    this.dialogRef.close();
  }

  setLimitBySlotSize(value: boolean | null) {
    if (value) {
      this.form.get("limitScheduleSlot")!.setValidators(Validators.required);
    } else {
      this.form.get("limitScheduleSlot")!.clearValidators();
    }
    this.cdr.detectChanges();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      resource: {
        weight_range_id: this.form.value.weight_range_id,
        operation_id: this.form.value.operation_id,
        suboperation_id: this.form.value.suboperation_id,
        start: this.form.value.start,
        end: this.form.value.end,
        slot: this.form.value.slot,
        limitBySlotSize: this.form.value.limitBySlotSize,
        limitScheduleSlot: this.form.value.limitBySlotSize
          ? this.form.value.limitScheduleSlot
          : null,
      },
    };

    if (options.id) {
      this.agendamentoService
        .updateScheduleWindowByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    } else {
      this.agendamentoService
        .storeScheduleWindowByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    }
  }

  getOperations() {
    this.supportDataService
      .generic({ name: "operation_type" })
      .subscribe((r: any) => {
        this.operations = r.data.resource;
        this.operationsFiltered = r.data.resource;
        //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true
        this.operations = this.operations.filter(
          (item) => item.use_in_scheduling === true,
        );
        this.operationsFiltered = this.operations.filter(
          (item) => item.use_in_scheduling === true,
        );

        if (this.data.operation_id) {
          this.nonEditableField = true;
        }
      });
  }

  getSubOperations() {
    this.supportDataService
      .generic({ name: "suboperation_type" })
      .subscribe((r: any) => {
        this.suboperations = r.data.resource;
        if (this.data.suboperation_id) {
          this.nonEditableField = true;
          this.operationChange();
        }
      });
  }

  setSuboperationsFilter(operation: number) {
    this.suboperationsFiltered = [];
    this.form.patchValue({ suboperation_id: "" });
    const ft = this.suboperations.filter(
      (item: any) => item.operation_id === operation,
    );
    this.suboperationsFiltered = ft;

    if (this.suboperationsFiltered.length > 0) {
      this.showSuboperation = true;
      this.form.get("suboperation_id")!.setValidators(Validators.required);
      if (this.data.suboperation_id) {
        this.form.patchValue({ suboperation_id: this.data.suboperation_id });
      }
    } else {
      this.showSuboperation = false;
      this.form.patchValue({ suboperation_id: "" });
      this.form.get("suboperation_id")!.clearValidators();
    }
  }

  operationChange() {
    if (this.suboperations) {
      this.setSuboperationsFilter(this.form.get("operation_id")!.value);
    }
  }

  setOperationsFilter(department: number) {
    this.operationsFiltered = [];
    this.form.patchValue({ operation_id: "" });
    const ft = this.operations.filter(
      (item: any) => item.department_id === department,
    );
    this.operationsFiltered = ft;
    //Se não for a edição de uma janela, ativa o campo de Operações pois foi informado um Departamento
    if (!this.data.id) {
      this.form.controls.operation_id.enable();
    }

    this.form.patchValue({ operation_id: this.data.operation_id });
  }

  getWeightRange() {
    this.supportDataService
      .generic({ name: "scheduling_weight_range" })
      .subscribe((r: any) => {
        this.weightRangeList = r.data.resource;
      });
  }
}
