import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ScheduleStatusDialogComponent } from '../schedule-status-dialog/schedule-status-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { AgendamentoService } from 'src/app/multiagendamento/shared/agendamento.service';
import { UserService } from 'src/app/user/shared/user.service';

@Component({
  selector: 'app-pre-schedule-status-dialog',
  templateUrl: './pre-schedule-status-dialog.component.html',
  styleUrls: ['./pre-schedule-status-dialog.component.scss']
})
export class PreScheduleStatusDialogComponent {
  form = this.formBuilder.group({
    department_id: [this.data.department_id, Validators.required],
    operation_id: [this.data.operation_id, Validators.required],
    suboperation_id: [this.data.suboperation_id],
    groups:[this.data.groups ? JSON.parse(this.data.groups) : [], Validators.required],
    status_on_create:[this.data.status_on_create , Validators.required],
    status_on_edit:[this.data.status_on_edit ? JSON.parse(this.data.status_on_edit) : [] ],
    status_on_info_edit: [this.data.status_on_info_edit],
  });
  departments: any[] = [];
  operations: any[] = [];
  suboperations: any[] = [];
  suboperationsFiltered: any[] = [];
  operationsFiltered: any[] = [];
  groups: any[] = [];
  status_on_create: any[] = [];
  status_on_edit: any[] = [];
  status_on_info_edit:any[] = [];
  showSuboperation = false;
  nonEditableField = false;
  account: any;


  constructor(
    public dialogRef: MatDialogRef<ScheduleStatusDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getDepartments();
    this.getOperations();
    this.getSubOperations();
    this.getGroups();
    this.getCreateStatus();
    this.getEditStatus();
    this.getInfoEditStatus();
  }

  close(){
    this.dialogRef.close();
  }

  getOperations(){
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operations = r.data.resource;
      this.operationsFiltered = r.data.resource;
      //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true
      this.operations = this.operations.filter(item => item.use_in_scheduling === true);
      this.operationsFiltered = this.operations.filter(item => item.use_in_scheduling === true);

      if(this.data.operation_id){
        this.nonEditableField = true;
        this.departmentChange();
      }
    });
  }

  getSubOperations(){
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.suboperations = r.data.resource;

      if(this.data.suboperation_id){
        this.nonEditableField = true;
        this.operationChange();
      }
    });
  }

  clearFilter() {
      this.form.get('status_on_info_edit')!.setValue(null);
  }

  getDepartments(){
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departments = r.data.resource
      this.departments = this.departments.filter((item: any) => {
        return this.account.department_id.indexOf(item.id) > -1;
      })
      if(this.departments.length === 1) {
        this.form.patchValue({department_id: this.departments[0].id})
      } else if(!this.data.id) {
        //Se não possuir data.id, é um novo status de agendamento para um cliente com mais de um departamento
        //Então desativa o campo de Operações até que seja informado um Departamento
        this.form.controls.operation_id.disable();
      }
    });
  }

  getCreateStatus(){
    this.supportDataService.generic({name: 'pre_schedule_status'}).subscribe((r: any) => {
      this.status_on_create = r.data.resource;
    });
  }

  getEditStatus(){
    this.supportDataService.generic({name: 'pre_schedule_status'}).subscribe((r: any) => {
      this.status_on_edit = r.data.resource;
    });
  }

  getInfoEditStatus(){
    this.supportDataService.generic({name: 'pre_schedule_status'}).subscribe((r: any) => {
      this.status_on_info_edit = r.data.resource;
    });
  }

  getGroups(){
    const options = { pageSize: 200 };

    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
    });
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      department_id: this.form.value.department_id,
      resource: {
        operation_id: this.form.value.operation_id,
        suboperation_id: this.form.value.suboperation_id,
        groups: this.form.value.groups,
        status_on_create: this.form.value.status_on_create,
        status_on_edit: this.form.value.status_on_edit,
        status_on_info_edit: this.form.value.status_on_info_edit
      }
    }
    if(options.id) {
      this.agendamentoService.updatePreSchedulingStatus(options).subscribe((r: any) => {
        this.close();
      });
    } else {
      this.agendamentoService.storePreSchedulingStatus(options).subscribe((r: any) => {
        this.close();
      });
    }

  }

  departmentChange(){
    this.setOperationsFilter(this.form.get('department_id')!.value)
  }

  setOperationsFilter(department: number){
    this.operationsFiltered = [];
    this.form.patchValue({operation_id: ""})
    const ft = this.operations.filter((item: any) => item.department_id === department)
    this.operationsFiltered = ft;
    //Se não for a edição de um status de agendamento, ativa o campo de Operações pois foi informado um Departamento
    if(!this.data.id) {
      this.form.controls.operation_id.enable();
     }

    this.form.patchValue({operation_id: this.data.operation_id});
  }

  operationChange(){
    if(this.suboperations){
      this.setSuboperationsFilter(this.form.get('operation_id')!.value)
    }
  }

  setSuboperationsFilter(operation: number){

    this.suboperationsFiltered = [];
    this.form.patchValue({suboperation_id: ""})

    //Verifica se existem suboperações antes de realizar qualquer ação com uma possivel variavel indefinida
    if(this.suboperations) {

      const ft = this.suboperations.filter((item: any) => item.operation_id === operation)

      this.suboperationsFiltered = ft;

      if(this.suboperationsFiltered.length > 0){

        this.showSuboperation = true;

        if(this.data.suboperation_id){
          this.form.get('suboperation_id')!.setValue(this.data.suboperation_id);
        }
        this.form.get('suboperation_id')!.updateValueAndValidity();
      } else {
        this.showSuboperation = false;
        this.form.get('suboperation_id')!.setValue(null);
        this.form.get('suboperation_id')!.clearValidators();
        this.form.get('suboperation_id')!.updateValueAndValidity();
      }
    }
  }

}

