<!-- <div style="text-align:center"> -->
	<div class="webcam-wrapper">
<!-- <webcam
  [height]="500"
  [width]="450"
  [trigger]="triggerObservable"
  (imageCapture)="handleImage($event)"
  *ngIf="showWebcam"
  [allowCameraSwitch]="allowCameraSwitch"
  [switchCamera]="nextWebcamObservable"
  [videoOptions]="videoOptions"
  [imageQuality]="1"
  (cameraSwitched)="cameraWasSwitched($event)"
  (initError)="handleInitError($event)"
>
</webcam> -->

<!-- <div class="actionBtn"  title="Take Picture">📸</div>
	  <div class="actionBtn" (click)="showNextWebcam(true);" title="Switch Camera">🔁</div> -->
<div class="example-button-container">
  <button mat-raised-button (click)="callFile(); cancelWebcam()" class="save" >Salvar</button>
  <button class="one-button" (click)="triggerSnapshot()"></button>
  <button mat-raised-button (click)="cancelWebcam()" class="cancel">Cancelar</button>
</div>
	</div>

<!-- </div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
	<li>{{error | json}}</li>
</ul>  -->
