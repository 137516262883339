<h3>Upload de documentos</h3>

<div *ngIf="isMultipleUploaded" class="success">
  File uploaded and form submitted successfully.
  <br />
  <br />
</div>

<form [formGroup]="uploadForm">
  <table width="100%" cellspacing="0">
    <div formArrayName="filesToUpload" *ngIf="showList">
      <div *ngFor="let f of filesToUpload.controls; index as i">
        <tr class="tkg-tr-container">
          <td class="tkg-view-cell">
            <button
              mat-button
              color="primary"
              class="tkg-new-btn"
              (click)="visualizer(i)"
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </td>
          <td>
            <button
            mat-button
            color="primary"
            class="tkg-new-btn"
            (click)="delete(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          </td>
          <td>
            <input
              [accept]="allowed_extensions"
              [formControlName]="i"
              type="file"
              id="file{{i}}"
              class="tkg-input"
              (change)="uploadFile($event, i)"
            />
            <label
              class="tkg-input-label"
              [ngClass]="showUploadedFileName(i).length > 0 ? 'tkg-border-left-6-green' : 'tkg-border-left-6-red'"
              for="file{{i}}"
              >{{showFileTypeName(i)}}{{showUploadedFileName(i)}}</label
            >
            <label
              *ngIf="filesToUpload.controls[i].errors?.required"
              class="error"
            >
              Select the file.
            </label>
            <label
              *ngIf="filesToUpload.controls[i].errors?.inValidExt
              && !filesToUpload.controls[i].errors?.required"
              class="error"
            >
              Invalid file extension.
            </label>
          </td>
          <td class="tkg-td-percent">
            <div class="tkg-div-percent">{{percentUploaded[i]}}%</div>
          </td>
        </tr>
      </div>
    </div>
  </table>
</form>
