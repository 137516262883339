<div class="tkg-title-group"><p class="tkg-title">{{ "Business_model.block_schedule" | translate:{Default: "Bloqueio de Agendamento"} }}</p><button mat-button mat-stroked-button color="primary" class="tkg-new-btn" (click)="addEdit()">Novo</button></div>
<div class="tkg-description">{{ "Messages.set_agenda"
  | translate:{Default: "Defina o bloqueio da sua agenda a partir de vários critérios como horário de início e fim, dia inteiro, dia da semana, grupos de usuário entre outras opções."} }}
</div>
<table class="tkg-table">
  <thead>
    <tr>
      <th *ngIf="!hiddenElements?.department === true">{{ "Business_model.department" | translate:{Default: "Departamento"} }}<br/></th>
      <th>{{ "Business_model.operation" | translate:{Default: "Operação"} }}<br /></th>
      <th *ngIf="!hiddenElements?.suboperation === true">{{ "Business_model.suboperation" | translate:{Default: "Sub-operação"} }}<br /></th>
      <th>{{ "Business_model.block_day" | translate:{Default: "Dia bloqueado"} }}<br /></th>
      <th>{{ "Business_model.start" | translate:{Default: "Início"} }}<br /></th>
      <th>{{ "Business_model.end" | translate:{Default: "Fim"} }}<br /></th>
      <th>{{ "Business_model.block_type" | translate:{Default: "Tipo de bloqueio"} }}<br /></th>
      <th>{{ "Business_model.groups" | translate:{Default: "Grupo(s)"} }}<br /></th>
      <th style="width: 80px; text-align: center;">{{ "Business_model.actions" | translate:{Default: "Ações"} }}<br /></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of blocks">
      <td *ngIf="!hiddenElements?.department === true">{{item.department}}</td>
      <td>{{item.operation}}</td>
      <td *ngIf="!hiddenElements?.suboperation === true">{{item.suboperation}}</td>
      <td>{{item.allday ? ("Business_model.yes" | translate:{Default: "Sim"}) : ("Business_model.no" | translate:{Default: "Não"})}}</td>
      <td>{{item.allday ? '-' : item.start}}</td>
      <td>{{item.allday ? '-' : item.end}}</td>
      <td>{{blockType(item)}}</td>
      <td>{{showGroupName(item?.groups)}}</td>
      <td>
        <button mat-icon-button color="primary" (click)="addEdit(item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delete(item.id)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="blocks.length === 0">
      <td colspan="100%"><p class="no-record">{{ "Messages.no_register" | translate: {Default: "Nenhum registro encontrado!"} }}</p></td>
    </tr>
  </tbody>
</table>
