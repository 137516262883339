import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import { SupportDataService } from "src/app/shared/support-data.service";

@Component({
  selector: "app-formly-ss-operation",
  templateUrl: "./formly-ss-operation.component.html",
  styleUrls: ["./formly-ss-operation.component.scss"],
})
export class FormlySsOperationComponent extends FieldType {
  constructor(private supportDataService: SupportDataService) {
    super();
  }

  _formControl = new FormControl();
  filter: Observable<any>;
  ngModule: any;
  selectedItem = false;

  ngOnInit() {
    this._formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        startWith(""),
        map((value) => value),
      )
      .subscribe((value) => {
        if (typeof value === "string") {
          this.filter = this.supportDataService.getOperation({ search: value });
        }
      });
  }

  clear() {
    this.ngModule = "";
    this.formControl.setValue("");
  }

  displaySelected(opt: any) {
    if (opt) {
      this.formControl.setValue({ id: opt.id, name: opt.name });
      return opt && opt.name ? opt.name : "";
    }
  }
}
