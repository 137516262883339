import { KpiModule } from './kpis/kpi.module';
import { NgModule } from '@angular/core';
import { CardComponent } from './card.component';
import { CardDirective } from './card.directive';
import { CardNotFoundComponent } from './card-not-found.component';
import { CardsDefaultModule } from './default/cards-default.module';
import { CardsMaquinaVendasModule } from './maquina-vendas/cards-maquina-vendas.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
      MatCardModule,
      CardsDefaultModule,
      CardsMaquinaVendasModule,
      KpiModule
    ],
    declarations: [
        CardComponent,
        CardDirective,
        CardNotFoundComponent
    ],
    exports: [
        CardComponent
    ]
})
export class CardModule { }
