import { Component } from '@angular/core';

@Component({
  selector: 'app-scheduled-checkin',
  templateUrl: './scheduled-checkin.component.html',
  styleUrls: ['./scheduled-checkin.component.scss']
})
export class ScheduledCheckinComponent {

}
