<form [formGroup]="form" novalidate>
  <h2 mat-dialog-title>
    {{ (data.action == 'EDIT') ? 'Editar Remessa' : 'Nova Remessa' }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="item item-1">
        <mat-form-field>
          <input formControlName="order_number" matInput placeholder="Pedido">
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" formControlName="send_at" placeholder="Selecione uma data">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker>
          </mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-select formControlName="driver_id" placeholder="Motoristas">
            <ngx-mat-select-search [formControl]="driverFilter" placeholderLabel="Buscar motoristas"
              noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
            <mat-option *ngFor="let item of drivers" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <textarea type="text" class="notes" formControlName="notes" matInput placeholder="Observação"></textarea>
        </mat-form-field>
      </div>
      <div class="item item-2">

        <div style="text-align:center;">
          <mat-card class="list-upload">
            <mat-card-actions>
              <button mat-button color="primary" style="text-align:right;" (click)="onClick()">
                <mat-icon>library_add</mat-icon>
                Upload
              </button>
            </mat-card-actions>
            <mat-card-content class="list-content">
              <mat-list>
                <mat-list-item *ngFor="let item of documents">
                  <p matLine>
                    <!-- <a [href]="item.url" target="_blank"><span> {{item.fileName}} </span></a> -->
                    <span (click)="getFile(item)"> {{item.fileName}} </span>
                  </p>
                  <button mat-icon-button color="warn" aria-label="Apagar arquivo" (click)="deleteFile(item)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </mat-list-item>
              </mat-list>
            </mat-card-content>

          </mat-card><input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="*"
            style="display:none;" />
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="submit" mat-raised-button mat-dialog-close="true">Cancelar</button>
    <button (click)="sendDocs()" mat-raised-button color="primary" [disabled]="!form.valid">
      {{ (data.action == 'EDIT') ? 'Concluir' : 'Concluir' }}
    </button>
  </mat-dialog-actions>
</form>
