<!-- <h1 class="mat-align-center" mat-dialog-title>{{ data.action == 'approved' ? 'Aprovar' : 'Reprovar'}}</h1> -->
<h2 class="mat-align-center" mat-dialog-title>
  <!-- Confirma a {{ data.action == 'approved' ? 'APROVAÇÃO' : 'REPROVAÇÃO'}} do cadastro do motorista<br><b>{{data.item.name}}</b>? -->
  {{ data.action == 'approved' ? translateSource.approval : translateSource.disapproval}}
  {{ translateSource.msg }}<br><b>{{data.item.name}}</b>?
</h2>
<div mat-dialog-actions align="end">
  <!-- <button mat-button [mat-dialog-close]="data" cdkFocusInitial color="primary">{{ data.action == 'approved' ? 'Aprovar' : 'Reprovar'}}</button> -->
  <button mat-button color="warn" (click)="cancel()">{{translateSource.confirm_button}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial
    color="primary">{{translateSource.cancel_button}}</button>
</div>