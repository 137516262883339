<div class="events-container-box">
  <mat-toolbar color="primary" class="my-tool-bar">
    <mat-toolbar-row>
      <span>
        {{ definedDay | date: "mediumDate" }}
        <button mat-raised-button class="add-btn" (click)="schedulingDialog()">
          Novo agendamento
        </button>
        <button mat-raised-button class="up-btn" (click)="uploadDialog()">
          Upload de agendamento
        </button>
      </span>
      <form [formGroup]="dayForm" class="dayForm">
        <div>
          <div>
            <mat-form-field class="select-comp">
              <mat-label>Dia</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="day"
                (dateChange)="switchDay()"
              />
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<mat-card class="title">Agendamentos</mat-card>
<mat-accordion>
  <div *ngFor="let timeslot of timeslots">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <!-- <button mat-raised-button (click)="getTime(timeslot.time)">
            {{ timeslot.time }}
          </button> -->
          <a
            href="javascript: void(0)"
            class="noStyle"
            (click)="getTime(timeslot.time)"
          >
            {{ timeslot.time }}
          </a>
        </mat-panel-title>
        <mat-panel-description class="description">
          <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
          <span class="fill-remaining-space"></span>
          <div *ngFor="let department of qtd_departments; let i = index">
            <button
              mat-raised-button
              [ngClass]="getColor(timeslot, i)"
              *ngIf="getNameQtd(timeslot, i) && i % 2 != 0"
            >
              {{ getNameQtd(timeslot, i) }}
            </button>

            <button
              mat-raised-button
              [ngClass]="getColor(timeslot, i)"
              *ngIf="getNameQtd(timeslot, i) && i % 2 == 0"
            >
              {{ getNameQtd(timeslot, i) }}
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="expansion-span">
        <div *ngFor="let deps of timeslot?.l1_data">
          <div class="dapartment-name">
            {{ deps.name }}
          </div>

          <table class="tkg-table">
            <thead>
              <tr>
                <th>OT</th>
                <th>Transportadora<br /></th>
                <th>Motorista<br /></th>
                <th>Placa do Cavalo<br /></th>
                <th>Placa da Carreta<br /></th>
                <th>Status<br /></th>
                <th>Detalhes<br /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let event of deps?.l2_data">
                <td>{{ event.document }}</td>
                <td>{{ event.carrier_name }}</td>
                <td>{{ event.driver_name }}</td>
                <td>{{ event.board_horse }}</td>
                <td>{{ event.board_cart }}</td>
                <td>
                  <span
                    class="span-bagde"
                    [ngClass]="getClassStatus(event.status)"
                  >
                    {{ event.desc_status }}
                  </span>
                </td>
                <td>
                  <!-- <button
                    mat-raised-button
                    class="btnMaisDetalhes"
                    (click)="openDialog(event)"
                  >
                    <mat-icon>add_circle</mat-icon>
                  </button> -->
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                  <button
                  mat-menu-item
                    class="btnEditar"
                    (click)="schedulingDialog(null, event)"
                    disabled="{{ event.status == 'closed' }}"
                  >
                    Editar
                  </button>
                  &nbsp; &nbsp;
                  <button
                  mat-menu-item
                    class="btnMaisDetalhes"
                    disabled="{{ event.status != 'waiting' }}"
                    (click)="updateScheduling(event.event_id, 'confirmed')"
                  >
                    Aceitar
                    <!-- Aceitar -->
                  </button>
                  &nbsp; &nbsp;
                  <button
                  mat-menu-item
                    class="btnMaisDetalhes"
                    disabled="{{ event.status != 'waiting' }}"
                    (click)="updateScheduling(event.event_id, 'denied')"
                  >
                    Rejeitar
                    <!-- Rejeitar -->
                  </button>
                  &nbsp; &nbsp;
                  <button
                  mat-menu-item
                    class="btnExcluir"
                    (click)="cancelDialog(event)"
                  >
                    Excluir
                  </button>
                  <button
                  mat-menu-item
                    (click)="showDocuments(event)"
                  >
                    Visualizar documentos
                  </button>
                </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</mat-accordion>
