<mat-card class="title"
  >Mais informações sobre OT: <b>{{ dataSchedule?.nro_ot }}</b>
</mat-card>
<mat-list>
  <mat-list-item>
    <b>Operação:</b> &nbsp; {{ dataSchedule?.operation_name }}</mat-list-item
  >
  <mat-list-item>
    <b>Centro:</b> &nbsp;
    {{ dataSchedule?.department_external_id }}</mat-list-item
  >
  <mat-list-item>
    <b>CPF do Motorista:</b>&nbsp; {{ dataSchedule?.driver_cpf }}</mat-list-item
  >
  <mat-list-item>
    <b>Suboperação:</b>&nbsp;
    {{
      dataSchedule
        ? dataSchedule.suboperation
          ? dataSchedule.suboperation
          : dataSchedule.suboperation_type
        : ""
    }}</mat-list-item
  >
</mat-list>
