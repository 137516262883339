import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from "../../../../environments/environment";
import { IndexShiftResponseInterface, IndexShiftScheduleResponseInterface, ShiftInterface, ShiftScheduleInterface } from '../shifts';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {

  constructor(private http: HttpClient) { }

  indexShifts(options?:any):Observable<IndexShiftResponseInterface>{
    const url = `${environment.apiUrl}/shifts`;
    const params = new HttpParams({ fromObject: options })
    return this.http.get<IndexShiftResponseInterface>(url, { params }).pipe(
      map((response) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  storeShift(data:Partial<ShiftInterface>){
    const url = `${environment.apiUrl}/shifts`;

    return this.http.post<Partial<ShiftInterface>>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  updateShift(data: Partial<ShiftInterface> & {id:number, name:string}): Observable<any> {
    const url = `${environment.apiUrl}/shifts/${data.id}`;

    return this.http.put<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  destroyShift(id:number){
    const url = `${environment.apiUrl}/shifts/${id}`;

    return this.http.delete(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  indexShiftSchedules(options?:any):Observable<IndexShiftScheduleResponseInterface>{
    const url = `${environment.apiUrl}/shifts-schedules`;
    const params = new HttpParams({ fromObject: options })
    return this.http.get<IndexShiftScheduleResponseInterface>(url, { params }).pipe(
      map((response) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  storeShiftSchedules(data:Partial<ShiftScheduleInterface>){
    const url = `${environment.apiUrl}/shifts-schedules`;

    return this.http.post<Partial<ShiftInterface>>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  updateShiftSchedules(data: Partial<ShiftScheduleInterface> & {id:number}): Observable<any> {
    const url = `${environment.apiUrl}/shifts-schedules/${data.id}`;

    return this.http.put<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }

  destroyShiftSchedules(id:number){
    const url = `${environment.apiUrl}/shifts-schedules/${id}`;

    return this.http.delete(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(()=> err || new Error('Server error')))
    );
  }
}
