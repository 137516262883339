import { PlaceDialogComponent } from '../place-dialog/place-dialog.component';
import { PlaceService } from 'src/app/shared/place.service';
import {
  Component,
  OnInit
} from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { DialogConfirmComponent } from "../../../shared/dialog-confirm/dialog-confirm.component";
import { SearchService } from "../../../shared/search.service";
import { Search } from "../../../shared/search";
import { BreakpointObserver } from "@angular/cdk/layout";
import { UntypedFormControl } from "@angular/forms";
import { DynamicDialogComponent } from 'src/app/shared/dynamic-dialog/dynamic-dialog.component';

@Component({
  selector: 'app-place-list',
  templateUrl: './place-list.component.html',
  styleUrls: ['./place-list.component.scss']
})
export class PlaceListComponent implements OnInit {
  searchWatcher: Subscription;
  search: string = '';
  page: number = 1
  pageSize: number = 10;
  orderBy: string;
  sortedBy: string;
  showListEmpty = true;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[];
  columnsSchema: any;
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;

  constructor(
    private placeService: PlaceService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    public breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.searchWatcher = this.searchService.value.subscribe(
      (search: Search) => {
        this.search = search.value;
        this.getPlaces();
      }
    );

    this.getPlaces();
  }

  ngAfterViewInit() {
  }

  getPlaces() {
    const options = {
      search: this.search,
      paginate: false,
      pageSize: this.pageSize || 10,
      page: this.page || 1,
      orderBy: this.orderBy || '',
      sortedBy: this.sortedBy || ''

    }
    this.placeService.index(options).subscribe((r: any) => {
      this.dataSource.data = r.data;
      if(!this.columnsSchema) {
        this.columnsSchema = r.schema;
        this.displayedColumns = this.columnsSchema.map((col: any) => col.key);
      }
      this.showListEmpty = r.data.length === 0;
    });
  }

  open(action: string, item: any = {}) {
    const dialogRef = this.dialog.open(PlaceDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (action === "EDIT") {
          console.log("editando");
          this.snackBar.open("Local atualizado com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        } else if (action === "ADD") {
          console.log("criando");
          this.snackBar.open("Local criado com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      }
    });
  }

  open2(action: string, item: any = {}) {
    const dialogRef = this.dialog.open(DynamicDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        form_name: 'places_add_edit_form',
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {

    });
  }

  delete(name: any, id: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o local ${name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      console.log("deletando");
      if (confirm) {
        this.placeService.destroy(id).subscribe((response) => {
          this.snackBar.open("Local removido com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        });
      }
    });
  }

  clearFilter(event?:any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }

  performAction(event: any) {
    const paramsArray = event.action.method_params;
    if(paramsArray.length === 1) {
      (this[event.action.method as keyof typeof this] as Function)(event[paramsArray[0].value]);
    }
    if(paramsArray.length === 2) {
      (this[event.action.method as keyof typeof this] as Function)(event[paramsArray[0].value], event[paramsArray[1].value]);
    }

  }

  performOnChangePage(event: any) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getPlaces();
  }

  performSortChange(event: any) {
    this.orderBy = event.active;
    this.sortedBy = event.direction;
    this.getPlaces();
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
  }

}
