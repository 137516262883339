import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';
import { CollectPointsRoutingRoutes } from './collect-points-routing.module';
import { CollectPointsComponent } from './collect-points.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { CoreModule } from '../core/core.module';
import { MapsGenericComponent } from '../shared/maps-generic/maps-generic.component';
@NgModule({
    declarations: [CollectPointsComponent],
    imports: [
        CommonModule,
        CoreModule,
        CollectPointsRoutingRoutes,
        MatDialogModule,
        SharedModule,
        NgxEchartsModule,
        NgxMaskDirective, 
        NgxMaskPipe
    ],
    providers: [provideNgxMask()]
})
export class CollectPointModule { }