import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Account } from '../account';
import { environment } from 'src/environments/environment';
import { AccountService } from '../shared/account.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

type UserClients = Partial<{
  user_name: string;
  clients:{
    system_client_id: number;
    icon:string|SafeResourceUrl;
    name:string;
    location: string;
    login_state?: string;
    logged_at?: string;
    active: boolean;
  }[]
}>

@Component({
  selector: 'app-dialog-switch-account',
  templateUrl: './dialog-switch-account.component.html',
  styleUrls: ['./dialog-switch-account.component.scss']
})
export class DialogSwitchAccountComponent implements OnInit {
  private account:Account;

  isLoading = true;
  showFilter = false;
  searchControl: FormControl;
  userClients:UserClients = {
    clients:[],
    user_name:""
  };

  constructor(
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<DialogSwitchAccountComponent>,
  ){}

  ngOnInit(): void {

    this.accountService
    .identity()
    .then((account)=>{
      this.account = account;

      this.accountService.getClients<UserClients>(account.email)
      .pipe(tap(()=>this.isLoading = false))
      .subscribe({
        next: (userClients)=>{
          userClients.clients = userClients.clients?.sort(function(x,y){
            return x.system_client_id == account.system_client?.id ? -1 : y.system_client_id == account.system_client?.id ? 1 : 0;
          });

          userClients.clients?.forEach(client => {
            const imageIcon = (
              /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(client.icon as string)
              ? "data:image/png;base64,"
              : ""
            ).concat(client.icon as string);

            const access = this.accountService.realmAccess.accessedRealms
            .find((access)=> access.realm == "" + client.system_client_id && access.user == account.email);

            client.icon = this.sanitizer.bypassSecurityTrustUrl(imageIcon);
            client.login_state = access?.state;
            client.logged_at = access?.logged_at;
            client.active = account.system_client?.id == access?.realm;

          });

          this.userClients = {...userClients};
          this.showFilter = this.userClients.clients!.length > 10;

          this.searchControl = new FormControl('');
          this.searchControl.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(query => {
              if(!query)
                this.userClients = {...userClients};
              else
                this.userClients.clients = [...userClients.clients!].filter((client)=>{
                  return `${client.name} ${client.location}`.match(new RegExp(query.split(" ").join(".*"),"gi"));
                });
            });
      },
      error: (err: any) => {
        this.isLoading = false;
        const message = (err?.error?.message || !err) ? "Não foi possível prosseguir com a autenticação" : err.error;

        this.dialog.open(DialogAlertComponent, {
          data: {
            title: "Acesso inviabilizado",
            message
          }
        });
      }
    });
    })
  }

  keycloakLogin(client:NonNullable<UserClients["clients"]>[0]){
    if(this.account.system_client?.id == client.system_client_id)
      return this.dialogRef.close();

    this.accountService.login({
      realm: client.system_client_id,
      clientId: environment.keycloak.clientId,
      loginHint: this.account.email,
      urlRedirect: window.location.pathname
    })
  }
}
