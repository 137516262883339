import { SupportDataService } from "./../../shared/support-data.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { SystemNotificationsService } from "./../../shared/system-notifications.service";
import { delay, interval, switchMap, of, Subscription } from "rxjs";
export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: "app-dashboard-dynamic-structure",
  templateUrl: "./dashboard-dynamic-structure.component.html",
  styleUrls: ["./dashboard-dynamic-structure.component.scss"],
})
export class DashboardDynamicStructureComponent implements OnInit, OnDestroy {
  tiles: Tile[] = [
    { text: "KPI 1", cols: 1, rows: 1, color: "lightblue" },
    { text: "KPI 2", cols: 1, rows: 1, color: "lightgreen" },
    { text: "KPI 3", cols: 1, rows: 1, color: "lightpink" },
  ];

  breakpoint: number;
  dashboardStructure: any = [];
  cols: number;
  notifications: any = [];
  hasNotifications: boolean = false;
  intervalSubscription: Subscription;
  dashStructure: Subscription;
  systemNotific: Subscription;
  constructor(
    private supportDataService: SupportDataService,
    public systemNotifications: SystemNotificationsService,
  ) {
    this.dashboardCols();
  }
  ngOnDestroy(): void {
    this.intervalSubscription?.unsubscribe();
    this.dashStructure?.unsubscribe();
    this.systemNotific?.unsubscribe();
  }

  ngOnInit() {
    this.loadNotifications();
    this.dashStructure = this.supportDataService
      .generic({ name: "dynamic-dash-structure" })
      .subscribe((r: any) => {
        this.dashboardStructure = r.data.resource;
      });

    //Para atualizar os dados do dash a cada 1 min
    this.intervalSubscription = interval(60000)
      .pipe(
        switchMap((value: number) =>
          of(
            this.dashboardStructure?.map((item: any[]) =>
              item?.map((item: any) => {
                item.forceRefresh = value;
                return item;
              }),
            ),
          ),
        ),
      )
      .subscribe((data: any[]) => {
        this.dashboardStructure = data;
      });
  }

  dashboardCols() {
    const account = JSON.parse(localStorage.getItem("account") || "{}");
    this.cols =
      account.system_client.resource.environment.pages.dashboard_dynamic.cols ||
      {};
  }

  getKPI(kpiName: string) {}

  onResize(event: any) {
    //this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  loadNotifications() {
    this.systemNotific = this.systemNotifications.notifications().subscribe({
      next: (r: any) => {
        this.notifications = r.data;
        if (this.notifications.length > 0) this.hasNotifications = true;
      },
      error: (e: any) => {
        console.log("erro");
      },
    });
  }
}
