<mat-card>
  <mat-card-header>
    <mat-card-title class="title">{{ ("" + data.translation_title) | translate:{ Default: data.title } }}</mat-card-title>
    <button class="icon-scale" *ngIf="infoHtml" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Dash info">
      <mat-icon>info_outline</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="info-container" [innerHtml]="infoHtml"></div>
    </mat-menu>
  </mat-card-header>
  <mat-divider inset></mat-divider>
  <div class="container">
    <div echarts [options]="chartOptions" class="chart"></div>
  </div>
</mat-card>
