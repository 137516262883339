import { Component } from '@angular/core';

import { CardInterface } from '../../card.interface';
import { DashboardDynamicService } from 'src/app/dashboard-dynamic/dashboard-dynamic.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface CardDynamicTablePropsInterface{
  [key:string]:any;
  display_columns:{
    name:string;
    translation_name?:string;
    key:string;
    column_style?: string;
  }[]
}

@Component({
  selector: 'app-card-dynamic-table',
  templateUrl: './card-dynamic-table.component.html',
  styleUrls: ['./card-dynamic-table.component.scss']
})
export class CardDynamicTableComponent implements CardInterface {
  data: CardDynamicTablePropsInterface;
  options: any;
  layout: number;
  displayedColumns:string[];
  dataSource: any[];

  infoHtml: SafeHtml;

  constructor(private sanitizer:DomSanitizer,private dashboardDynamic: DashboardDynamicService) { }

  ngOnInit() {
    this.displayedColumns = this.data.display_columns?.map((item:any)=>item.name)
    if(typeof(this.data.info_html) == "string"){
      this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.info_html as string);
    }

    this.dashboardDynamic.getDynamicDashboardItem({ item: this.data.element_name})
    .subscribe((data: any) => {
      this.dataSource = data instanceof Array ? data : [data];
    });
  }
}
