import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckinComponent } from './checkin/checkin.component';
import { AccountGuardService } from '../account/shared/account-guard.service';

const checkinRoutes: Routes = [
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/checkin-web',
    component: CheckinComponent,
    data: {
      title: 'Check in',
      search: false,
      refresh: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(checkinRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CheckinRoutingModule { }
