import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { TkgCardDetailsComponent } from "./../tkg-card-details/tkg-card-details.component";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BottomSheetActionsComponent } from "src/app/checkin-loreal/bottom-sheet-actions/bottom-sheet-actions.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-tkg-card-one",
  templateUrl: "./tkg-card-one.component.html",
  styleUrls: ["./tkg-card-one.component.scss"],
})
export class TkgCardOneComponent {
  @Input() main_slots_fields: any = {};
  @Input() main_fields_labels: any = {};
  @Input() detail_slots_fields: any = {};
  @Input() detail_fields_labels: any = {};
  @Input() datasource: any = {};
  @Input() action_list: any[] = [];
  @Output() performActionOnParent: EventEmitter<any> = new EventEmitter();
  @Output() actionListController: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private checkinService: CheckinService,
    private _bottomSheet: MatBottomSheet,
  ) {}

  showDialogDetails(datasource: any) {
    const dialogRef = this.dialog.open(TkgCardDetailsComponent, {
      panelClass: ["dialog-fullscreen"],
      disableClose: false,
      data: {
        datasource: datasource,
        detail_slots_fields: this.detail_slots_fields,
        detail_fields_labels: this.detail_fields_labels,
      },
    });
  }

  showLabel(slot_number: string) {
    return this.main_fields_labels[this.main_slots_fields[slot_number]];
  }

  showFieldValue(slot_number: string) {
    return this.datasource[this.main_slots_fields[slot_number]];
  }

  // Recebe os dados e delega a execução para o serviço
  manageBtn(status: string, action: any, rowValue: any) {
    return this.checkinService.manageBtn({
      status: status,
      action: action,
      rowValue: rowValue,
    });
  }

  // Recebe os dados e delega a execução para o componente pai
  performAction(datasource: any, action: any) {
    this.performActionOnParent.emit({ datasource: datasource, action: action });
  }

  showBSActions(element: any) {
    this.actionListController.emit({ listOpen: true });
    const bottomSheetRef = this._bottomSheet.open(BottomSheetActionsComponent, {
      data: JSON.stringify({ action_list: this.action_list, id: element.id }),
    });
    bottomSheetRef.afterDismissed().subscribe((action) => {
      this.actionListController.emit({ listOpen: false });
      if (action) {
        this.performActionOnParent.emit({
          datasource: element,
          action: action,
        });
      }
    });
  }
}
