import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'app-report-list-dialog-filters',
  templateUrl: './report-list-dialog-filters.component.html',
  styleUrls: ['./report-list-dialog-filters.component.scss']
})
export class ReportListDialogFiltersComponent implements OnInit{

  public filterName: UntypedFormControl = new UntypedFormControl();
  public form = new FormGroup({
    operatorName: new FormControl('[e]'),
    valueName: new FormControl()
  });

  valueList: any[] = [];
  addedFiltersList: any[] = [];
  filters: any;
  msg: string;
  msgBlock = false;
  operatorsList: any[] = [
    {id: '[e]', name: 'igual a'},
    {id: '[ne]', name: 'diferente de'},
    {id: '[gt]', name: 'maior que'},
    {id: '[gte]', name: 'maior igual a'},
    {id: '[lt]', name: 'menor que'},
    {id: '[lte]', name: 'menor igual a'}
  ]
  filterSelected: any;
  showFilterBar = false;
  showSelectComponent = false;
  showInputComponent = false;
  showDatePickerRangeComponent = false;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http:HttpClient,
    private supportDataService: SupportDataService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<ReportListDialogFiltersComponent>,
  ) {

  }
  ngOnInit(): void {
    this.getFilters();
    if(this.data.fts) {
      this.addedFiltersList = this.data.fts;
    }
  }

  getFilters() {
    this.supportDataService.pageFilters(this.data.page).subscribe((r: any) => {
      if(r.error) {
        this.msg = r.msg;
        this.msgBlock = true;
      } else {
        this.filters = r.data;
      }

    });
  }

  changeFilter() {

    console.log('select change');
    console.log(this.filterName.value);
    const key = this.filterName.value;
    this.form.patchValue({valueName: ''});
    this.filterSelected = this.filters.filter((item: any) => item.key === key)[0];
    console.log(this.filterSelected);

    this.showSelectComponent = false;
    this.form.get('operatorName')?.enable();

    if(this.filterSelected.component_type === 'select') {
      console.log('select');
      this.form.patchValue({operatorName: '[e]'});
      this.form.get('operatorName')?.disable();
      this.showSelectComponent = true;
      this.executeHttpRequest(this.filterSelected.url_data_source).subscribe((r: any) => {
        if(this.filterSelected.ref_name) {
          this.valueList = r.data.resource;
        } else {
          this.valueList = r.data;
        }
      });
    }

    if(this.filterSelected.component_type === 'datepicker_range') {
      console.log('date picker')
      this.form.patchValue({operatorName: '[e]'});
      this.form.get('operatorName')?.disable();
      this.showDatePickerRangeComponent = true;
    }
    this.showFilterBar = true;
  }

  addFilter() {
    const key: string = this.filterName.value;
    const value = this.form.value.valueName;
    let ft: any = {};

    const op = this.form.value.operatorName || '[e]';
    const key_op = `${key}${op}`


    if(this.filterSelected.component_type === 'select') {
      ft[key] = value.id;
      ft['value_name'] = value.name;
      ft['filter_name'] = this.filterSelected.name;
    }
    if(this.filterSelected.component_type === 'input') {
      ft[key] = value;
      ft['value_name'] = value;
      ft['filter_name'] = this.filterSelected.name;
    }
    if(this.filterSelected.component_type === 'datepicker_range') {
      console.log(this.range.value.start)
      console.log(this.range.value.end)
      console.log(this.formatDate(this.range.value.start, 'pt-BR'))
      console.log(this.formatDateDB(this.range.value.start));
      ft['start'] = this.formatDateDB(this.range.value.start);
      ft['end'] = this.formatDateDB(this.range.value.end);
      // ft['filter_name'] = this.filterSelected.name;
      ft['value_name'] = `${this.formatDate(this.range.value.start, 'pt-BR')} - ${this.formatDate(this.range.value.end, 'pt-BR')}`;
    }


    ft['op'] = op;

    this.addedFiltersList.push(ft);
  }

  formatDate(date: any, locale: string) {
    const dt = new Date(date);
    const formatTo = new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric'});
    const formattedDate = formatTo.format(dt);
    return formattedDate;
  }

  formatDateDB(date: any) {
    const dt = new Date(date);
    const year = dt.toLocaleString('default', {year: 'numeric'});
    const month = dt.toLocaleString('default', {
      month: '2-digit',
    });
    const day = dt.toLocaleString('default', {day: '2-digit'});

    return [year, month, day].join('-');
  }

  cleanFilters() {
    this.addedFiltersList = [];
    this.dialogRef.close(this.addedFiltersList);
  }

  executeHttpRequest(api_url: string="", options: any={}) {
    const url = `${environment.apiUrl}/${api_url}`;
    const params = new HttpParams({ fromObject: options });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }

  deleteFilter(idx: number) {
    this.addedFiltersList.splice(idx, 1);
  }

  callBackFilter() {
    this.dialogRef.close(this.addedFiltersList);
  }

  showOperatorName(op: string) {
    return this.operatorsList.filter((item: any) => item.id === op)[0].name
  }
}
