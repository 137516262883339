<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">

  <ul *ngFor="let item of data.dataExceptions">
    <h3 *ngIf="item.name == 'ot_problem'">{{item.subTitle || "Problema O.T."}}</h3>
    <h3 *ngIf="item.name == 'route'"><b>Rotas</b></h3>
    <h3 *ngIf="item.name == 'exception_received' ">Recebido com Exceção</h3>
    <h3 *ngIf="item.name == 'loading_denied' ">Carregamento Negado</h3>
    <h3 *ngIf="item.name == 'unloading_denied' ">Descarregamento Negado</h3>
    <h3 *ngIf="item.name == 'loading_problem' ">Problema no Carregamento</h3>
    <h3 *ngIf="item.name == 'direction_problem' ">Problema ao Direcionar</h3>
    <h3 *ngIf="item.name == 'invalidated_documentation' ">Documentação Invalida</h3>
    <li *ngFor="let value of item.values">{{value}}</li>
  </ul>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button style="color: white;" color="primary" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
