import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardDynamicService {

  constructor(private http: HttpClient) { }

  getDynamicDashboardItem(options?: any): Observable<any> {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/dynamic-dashboard-items`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

}
