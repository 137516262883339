import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Account } from 'src/app/account/account';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/account/shared/account.service';

interface Menu extends Account {
  menu:{
    name:string;
    routes:any;
    resource:{
      route: string;
      title?: string;
      service?: string;
      order: number | string;
      translate_title?: string;
    }
  }[];
  resource:Record<string, any>
}

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class GenericComponent implements OnInit{
  @ViewChild('iframeService') iframe: ElementRef;

  iframeSrc: SafeResourceUrl;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private appComponent:AppComponent,
    private translate: TranslateService,
    private activatedRoute:ActivatedRoute,
    private accountService: AccountService,
  ) { }


  ngOnInit() {
    this.accountService.identity().then((account:Account)=>{
      const menu = [...account.menu, ...account.menu.map((item:any) => item.routes).flat()].find((menu) => menu.resource?.route.replace(/^\/?|\/?$/g, '') === this.router.url.replace(/^\/?|\/?$/g, '')) as unknown as Menu & Account;
      if(menu && menu.resource.service){
        this.translate.stream(menu.resource.translate_title || menu.resource.title, {Default:menu.resource.title}).subscribe((value:string)=>{
          this.appComponent.appTitle = value || this.activatedRoute.snapshot.data.title;
        });

        if(menu.resource.need_token) {
          const token = localStorage.getItem("access_token");

          this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${menu.resource.service}?token=${token}`)
        } else {
          this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(menu.resource.service);
        }
      }
    })
  }
};
