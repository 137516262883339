<mat-card>
  <mat-card-header>
    <mat-card-title>Timeline dos status da jornada</mat-card-title>
    <mat-card-subtitle>Operação: {{data?.operation_name ?? ""}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions align="end">
    <button mat-button (click)="location.back()">VOLTAR</button>
  </mat-card-actions>
</mat-card>

<ngx-timeline
  [events]="events"
  [langCode]="langCode"
  [enableAnimation]="enableAnimation"
  [reverseOrder]="true"
  [changeSideInGroup]="changeSideInGroup"
>
</ngx-timeline>
