import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CollectPointsService {

  constructor(
    private http: HttpClient,
  ) { }
 
  loadCollectionPoints() {
    const params = new HttpParams()
    const url = `${environment.apiUrl}/collectionpoints`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  loadDriversNearPoints(collectionpoint?:any) {
    console.log(collectionpoint);
    
    const params = new HttpParams({ fromObject: collectionpoint })
    const url = `${environment.apiUrl}/collectionpoints/loaddriversnearpoints`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

}
