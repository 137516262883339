<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <mat-form-field class="tkg-input">
      <mat-label>Observações</mat-label>
      <textarea matInput formControlName="observacoes"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{save: false}">Cancelar</button>
  <button
    mat-raised-button
    style="color: white"
    color="primary"
    (click)="save()"
    [disabled]="!form.valid"
  >
    Salvar
  </button>
</mat-dialog-actions>
