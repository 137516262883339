import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckinService } from '../../checkin/shared/checkin.service'

@Component({
  selector: 'app-edit-keys-dialog',
  templateUrl: './edit-keys-dialog.component.html',
  styleUrls: ['./edit-keys-dialog.component.scss']
})
export class EditKeysDialogComponent implements OnInit {
  form = this.formBuilder.group({
    localkey: ["", Validators.required]
  });
  label!: string;
  dialogResult: any = {};
  message: string = "";
  actionName!: string;

  constructor(
    public checkinService: CheckinService,
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditKeysDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.label = this.data.action.editKeys[0].name;
    this.actionName = this.data.action.name;
    this.form.patchValue({localkey: this.data.item.resource[this.data.action.editKeys[0].key]});
  }

  onSubmit() {
    this.dialogResult.id = this.data.item.id;
    this.dialogResult.message = this.data.action.dialogue_message;
    //Envia a propriedade 'action_uid' se presente na ação em support_data
    //Utilizada para gravar no driver_checkin a ocorrencia da execução de ações que não fazem a troca de status
    this.dialogResult.action_uid = this.data.action.action_uid;
    this.dialogResult[this.data.action.editKeys[0].key] = this.form.value.localkey;
    this.checkinService.updateField(this.data.action.editKeysAPI, this.dialogResult).subscribe((r:any)=>{
      if(r.status == 200) {
        this.dialogRef.close(this.dialogResult);
      }
    });

  }
}
