import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";
import { ChangeDetectorRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { debounceTime, startWith, tap } from "rxjs/operators";

@Component({
  selector: "formly-input-search-partner",
  templateUrl: "./formly-input-search-partner.component.html",
  styleUrls: ["./formly-input-search-partner.component.scss"],
})
export class FormlyInputSearchPartnerComponent
  extends FieldType<any>
  implements OnInit
{
  _formControl = new FormControl();
  listOptions: any = [];
  searching = false;
  partners: any = [];
  bpTypeList: any = [];
  selectedItem = false;
  ngModule: any;

  constructor(
    private businessPartnerService: BusinessPartnerService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value > 0) {
      this.getOnePartner(this.formControl.value);
    } else {
      this.getPartners();
    }

    this._formControl.valueChanges
      .pipe(startWith(""), debounceTime(200))
      .subscribe((value: string) => {
        if (value) {
          this.applyFilter(value);
          this.cdr.detectChanges();
        } else {
          this.resetFilter();
        }
      });
  }

  getPartners() {
    this.businessPartnerService
      .all({ bp_type_id: this.props.bp_type_id })
      .subscribe((r: any) => {
        this.partners = r.data;
        this.listOptions = r.data;
      });
  }

  getOnePartner(id: number) {
    this.businessPartnerService
      .show(this.formControl.value)
      .subscribe((r: any) => {
        this.partners.push(r);
        this.listOptions.push(r);
        this._formControl.setValue({ id: r.id, name: r.name });
      });
  }

  applyFilter(value: string) {
    if (typeof value === "string") {
      const ft = this.listOptions.filter((ft: any) =>
        ft.name.toLowerCase().includes(value.toLowerCase()),
      );
      this.listOptions = ft;
    }
  }

  resetFilter() {
    this.formControl.setValue("");
    this.formControl.defaultValue;
    this._formControl.defaultValue;
    this.ngModule = "";
    this.listOptions = [...this.partners];
  }

  formatCnpjCpf(document: string) {
    if (!document) {
      return "";
    }

    if (document.length === 14) {
      return document.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5",
      );
    } else if (document.length === 11) {
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }
    return document;
  }

  displaySelected(opt: any) {
    if (opt) {
      this.formControl.setValue({ id: opt.id, name: opt.name });
      return opt && opt.name ? opt.name : "";
    }
  }

  clear() {
    this.ngModule = "";
    this.formControl.setValue("");
    this.getPartners();
  }
}
