import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatCardModule } from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';

import { CardDashComponent } from './card-dash/card-dash.component';
import { CoreModule } from './../../../core/core.module';
@NgModule({
    imports: [
        MatDividerModule,
        MatCardModule,
        CoreModule,
        CommonModule,
        NgxEchartsModule
    ],
    exports: [CardDashComponent],
    declarations: [CardDashComponent]
})
export class CardsMaquinaVendasModule { }
