import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { GenericComponent } from './generic/generic.component';

const routes: Routes = [
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/micro-services/generic',
    component: GenericComponent,
    data:{
      title:"Micro serviço"
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MicroServicesRoutingModule { }
