<mat-expansion-panel>
  <mat-expansion-panel-header>
    <div class="tkg-filter-header"></div>
    <span class="material-icons tkg-filter"> filter_list </span>
    <span class="tkg-filter-title">Adicionar Filtro</span>
  </mat-expansion-panel-header>
  <div
    class="container"
    fxLayout
    fxLayout.xs="column"
    fxLayoutAlign="rigth"
    fxLayoutGap="20px"
  >
    <form [formGroup]="form" novalidate>
      <mat-form-field>
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="filter_type">
          <mat-option *ngFor="let filter of filters" [value]="filter.id">
            {{ filter.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="form.value.filter_type == 1">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="driver_id" placeholder="Motoristas">
          <mat-option>
          <ngx-mat-select-search clearSearchInput]="false" [searching]="searching" [formControl]="driverFilter"
            placeholderLabel="Buscar motoristas" noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of drivers" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 2">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="carrier_id" placeholder="Trasportadora">
          <mat-option>
          <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching" [formControl]="carrierFilter"
            placeholderLabel="Buscar transportadora" noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of carriers" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 6">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="operation" placeholder="Operação">
          <mat-option>
          <ngx-mat-select-search [formControl]="operationFilter" placeholderLabel="Buscar operação"
            noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of operations" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 7">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="dock_space" placeholder="Doca">
          <mat-option>
          <ngx-mat-select-search [formControl]="dockFilter" placeholderLabel="Buscar docas"
            noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of docks" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 11">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="sys_user" placeholder="Usuário final">
          <mat-option>
          <ngx-mat-select-search [formControl]="userFilter" placeholderLabel="Buscar usuário"
            noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of users" [value]="item">
            {{ item.full_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 12">
        <mat-label>Dia</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="day" />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 13">
        <input
          matInput
          [matDatepicker]="picker1"
          placeholder="Data inicial"
          formControlName="dt_start"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="form.value.filter_type == 13">
        <input
          matInput
          [matDatepicker]="picker2"
          placeholder="Data final"
          formControlName="dt_end"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <button mat-button color="primary" (click)="addFilter()">
        <span class="material-icons"> add </span>
        Adicionar
      </button>
    </form>
  </div>
</mat-expansion-panel>
<br />
<mat-card>
  <span
    class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
    *ngFor="let item of active_filters; index as idx"
  >
    <b class="tkg-chips-filter-name">{{ item.filter }}</b
    >{{ item.value }}
    <span
      class="material-icons tkg-chips-icon"
      (click)="deleteFilter(idx, item.altName)"
    >
      cancel
    </span>
  </span>
</mat-card>
<br />
<div class="box-container mat-elevation-z4">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="report-table mat-elevation-z8"
  >
    <ng-container matColumnDef="cavalo" sticky>
      <th mat-header-cell *matHeaderCellDef class="col-5">Placa Cavalo</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.cavalo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="carreta">
      <th mat-header-cell *matHeaderCellDef class="col-5">Placa Carreta</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.carreta }}
      </td>
    </ng-container>

    <ng-container matColumnDef="transportadora">
      <th mat-header-cell *matHeaderCellDef class="col-5">Transportadora</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.transportadora }}
      </td>
    </ng-container>

    <ng-container matColumnDef="operacao">
      <th mat-header-cell *matHeaderCellDef class="col-5">Operação</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.operacao }}
      </td>
    </ng-container>

    <ng-container matColumnDef="suboperation">
      <th mat-header-cell *matHeaderCellDef class="col-5">Suboperação</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.suboperation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="col-5">Status</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="col-5">ID</th>
      <td mat-cell *matCellDef="let element" class="col-5">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="sche_time">
      <th mat-header-cell *matHeaderCellDef class="col-5">Agendado para</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.sche_time }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sche_created_at">
      <th mat-header-cell *matHeaderCellDef class="col-5">Ag. criado em</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.sche_created_at }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sche_confirmed_at">
      <th mat-header-cell *matHeaderCellDef class="col-5">Ag. confirmado em</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.sche_confirmed_at }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sche_denied_at">
      <th mat-header-cell *matHeaderCellDef class="col-5">Ag. negado em</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.sche_denied_at }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sche_aproved_at">
      <th mat-header-cell *matHeaderCellDef class="col-5">Ag. aprovado em</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.sche_aproved_at }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sche_pendencia_opentech">
      <th mat-header-cell *matHeaderCellDef class="col-5">Pend. Opentech</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.sche_pendencia_opentech }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sche_pendencia_opentech_detail">
      <th mat-header-cell *matHeaderCellDef class="col-10">Detalhe pend.</th>
      <td mat-cell *matCellDef="let element" class="col-10">
        {{ element.sche_pendencia_opentech_detail }}
      </td>
    </ng-container>

    <ng-container matColumnDef="checkin_anterior">
      <th mat-header-cell *matHeaderCellDef class="col-8">Check in ant.</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.checkin_anterior }}
      </td>
    </ng-container>

    <ng-container matColumnDef="checkin">
      <th mat-header-cell *matHeaderCellDef class="col-8">Check in</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.checkin }}
      </td>
    </ng-container>

    <ng-container matColumnDef="direcionado">
      <th mat-header-cell *matHeaderCellDef class="col-8">Direcionado</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.direcionado }}
      </td>
    </ng-container>

    <ng-container matColumnDef="no_estacionamento">
      <th mat-header-cell *matHeaderCellDef class="col-8">Estacionado</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.no_estacionamento }}
      </td>
    </ng-container>

    <ng-container matColumnDef="na_doca">
      <th mat-header-cell *matHeaderCellDef class="col-8">Na doca</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.na_doca }}
      </td>
    </ng-container>

    <ng-container matColumnDef="carregado">
      <th mat-header-cell *matHeaderCellDef class="col-8">Carregado</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.carregado }}
      </td>
    </ng-container>

    <ng-container matColumnDef="descarregado">
      <th mat-header-cell *matHeaderCellDef class="col-8">Descarregado</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.descarregado }}
      </td>
    </ng-container>

    <ng-container matColumnDef="notas_prontas">
      <th mat-header-cell *matHeaderCellDef class="col-8">Notas prontas</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.notas_prontas }}
      </td>
    </ng-container>

    <ng-container matColumnDef="notas_entregues">
      <th mat-header-cell *matHeaderCellDef class="col-8">Notas entregues</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.notas_entregues }}
      </td>
    </ng-container>

    <ng-container matColumnDef="finished">
      <th mat-header-cell *matHeaderCellDef class="col-8">Finalizado</th>
      <td mat-cell *matCellDef="let element" class="col-8">
        {{ element.finished }}
      </td>
    </ng-container>

    <ng-container matColumnDef="doca">
      <th mat-header-cell *matHeaderCellDef class="col-5">Doca</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.doca }}
      </td>
    </ng-container>

    <ng-container matColumnDef="responsavel">
      <th mat-header-cell *matHeaderCellDef class="col-5">Responsável</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.responsavel }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lacre">
      <th mat-header-cell *matHeaderCellDef class="col-5">Lacre</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.lacre }}
      </td>
    </ng-container>

    <ng-container matColumnDef="parqueado">
      <th mat-header-cell *matHeaderCellDef class="col-5">Parqueado</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.parqueado }}
      </td>
    </ng-container>

    <ng-container matColumnDef="appointment">
      <th mat-header-cell *matHeaderCellDef class="col-5">Appointment</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.appointment }}
      </td>
    </ng-container>

    <ng-container matColumnDef="checkin_mht">
      <th mat-header-cell *matHeaderCellDef class="col-5">Check in MHT</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.checkin_mht }}
      </td>
    </ng-container>

    <ng-container matColumnDef="duracao_jornada" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="col-5">
        Duração da jornada
      </th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ element.duracao_jornada }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="box-container mat-elevation-z4">
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
