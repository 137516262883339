<form [formGroup]="form" novalidate>
  <div class="schedule-header">
    <h2 mat-dialog-title>
      {{ dialogTitle }}
    </h2>
    <button mat-button color="primary" aria-label="Opções" *ngIf="data.accessLevel != 'carrier'" (click)="doCheckin()" [disabled]="!['confirmed', 'denied', 'carrier_confirmed', 'carrier_denied'].includes(data.schedule.status) || disable_checkin_btn">
      <mat-icon>meeting_room</mat-icon>
      Realizar Check In
    </button>
  </div>

  <mat-dialog-content class="mat-typography">
    <div *ngIf="reasons.length > 0" class="alert-denied-list">
      <h4>Motivo(s) para negação do agendamento</h4>
      <p *ngFor="let reason of reasons">{{reason.name}}</p>
    </div>
    <div *ngIf="validates.length > 0" class="alert-denied-list">
      <h4><b>Campos obrigatórios para confirmar agendamento</b></h4>
      <p *ngFor="let validate of validates">{{validate}}</p>
    </div>
    <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div class="item item-1">
        <!-- Doca e Operação -->
        <div class="container">
          <mat-form-field>
            <mat-label>Operações</mat-label>
            <mat-select formControlName="operation_id" (selectionChange)="getTimeSlots()">
              <mat-option *ngFor="let item of operations" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>Transportadoras</mat-label>
            <mat-select formControlName="carrier_id" [disabled]="block_carrier">
              <mat-option *ngFor="let item of carriers" [value]="item.id" >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo do Veiculo</mat-label>
            <mat-select formControlName="vehicle_type" required (selectionChange)="setPlateAmount()">
              <mat-option *ngFor="let item of vehicle_types" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Tamanhos dos Baú e Carregado -->
        <div *ngIf="form.value.operation_id == 2" class="container" fxLayout fxLayout.xs="column"
          fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <mat-form-field>
            <mat-label>Tamanhos do Bau</mat-label>
            <mat-select formControlName="box_size">
              <mat-option *ngFor="let item of box_sizes" [value]="item.id">
                {{ item.size }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Carregado</mat-label>
            <mat-select formControlName="loaded">
              <mat-option value="true">
                Sim
              </mat-option>
              <mat-option [value]="false">
                Não
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Tipos -->
        <div class="container">
          <mat-form-field>
            <mat-label>Tipos</mat-label>
            <mat-select formControlName="suboperation_id" required (selectionChange)="getTimeSlots()">
              <mat-option *ngFor="let suboperation of filterSuboperations(suboperations)" [value]="suboperation.id">
                {{ suboperation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>Horário</mat-label>
            <mat-select formControlName="start_at" required>
              <mat-option *ngFor="let date of timeslots" [value]="date.ts">
                {{ date.time }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>CPF do Motorista</mat-label>
            <input matInput [textMask]="{mask: maskUtil.maskCPF()}" formControlName="cpf" required (focusout)="searchDriver()">
          </mat-form-field>
        </div>
        <!-- Nome e Numero Motorista -->
        <div class="container">
          <mat-form-field>
            <mat-label>Nome do Motorista</mat-label>
            <input matInput formControlName="driver_name" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Telefone do Motorista</mat-label>
            <input [textMask]="{mask: maskUtil.maskPhone(11)}" matInput formControlName="driver_phone" required>
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field *ngIf="plates_fields?.includes('board_horse')">
            <mat-label>{{ plates_fields.includes('board_cart') ? 'Placa cavalo' : 'Placa'}}</mat-label>
            <input minlength="7" maxlength="7" mask="AAAAAAA" onkeyup="this.value = this.value.toUpperCase();" matInput formControlName="board_horse">
            <mat-error *ngIf="form.get('board_horse')?.errors && form.get('board_horse')?.hasError('pattern')">Formato ex.: AAA1A34 ou AAA1234</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="plates_fields?.includes('board_cart') && !truckerLink">
            <mat-label>Placa da Carreta</mat-label>
            <input minlength="7" maxlength="7" mask="AAAAAAA" onkeyup="this.value = this.value.toUpperCase();" matInput formControlName="board_cart">
            <mat-icon (click)="setBoardCartValet()" disabled= "data.action == 'edit'" matSuffix>search</mat-icon>
            <mat-error *ngIf="form.get('board_cart')?.errors && form.get('board_cart')?.hasError('pattern')">Formato ex.: AAA1A34 ou AAA1234</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="truckerLink">
            <mat-label>Placa da Carreta Estacionada</mat-label>
            <mat-select minlength="7" maxlength="7" mask="AAAAAAA" onkeyup="this.value = this.value.toUpperCase();" formControlName="board_cart" placeholder="Buscar carretas desvinculadas no pátio.">
              <mat-option>
                <ngx-mat-select-search
                [formControl]="boardsFilter"
                placeholderLabel="Buscar carreta estacionada."
                noEntriesFoundLabel="'Nenhum resultado encontrado'"
              >
            </ngx-mat-select-search>
              </mat-option>

              <mat-option
                *ngFor="let boardCart of boardCarts"
                [value]="boardCart.placa_carreta"
              >
                {{ boardCart.placa_carreta }}
              </mat-option>
            </mat-select>
            <button *ngIf="!(data.action == 'edit' && data.schedule.schedule_link_checkinId)" matSuffix mat-icon-button aria-label="Clear" (click)="form.patchValue({board_cart:''}); truckerLink = false; ">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('board_cart')?.errors && form.get('board_cart')?.hasError('pattern')">Formato ex.: AAA1A34 ou AAA1234</mat-error>
          </mat-form-field>

        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>ID / Shipment</mat-label>
            <input matInput formControlName="external_event_id" required>
          </mat-form-field>
        </div>
        <div class="container" *ngIf="[3, 4].includes(form.value.suboperation_id)">
          <mat-form-field>
            <mat-label>Email do fornecedor</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>Observação</mat-label>
            <input matInput formControlName="observation">
          </mat-form-field>
        </div>
      </div>
      <div class="item item-2"   *ngIf="form.value.operation_id == 1">

        <div style="text-align:center;">
          <mat-card>
            <mat-card-actions *ngIf="data.schedule.status != 'denied' || data.schedule.status == 'carrier_denied'">
              <button mat-button color="primary" style="text-align:right;" (click)="onClick()">
                Faça aqui o upload da documentação
                <mat-icon>backup</mat-icon>
              </button>
            </mat-card-actions>
            <mat-card-content class="list-content">
              <mat-list>
                <mat-list-item *ngFor="let item of documents" required>
                  <p matLine>
                    <span (click)="getFile(item)"> {{item.fileName}} </span>
                    <button mat-icon-button color="warn" aria-label="Apagar arquivo" (click)="deleteFile(item)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </p>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
          <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="*" style="display:none;"/>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <ng-container *ngIf="loading; else elseTemplate">
      <button mat-raised-button color="warn" disabled="disabled">Por favor, aguarde ...</button>

    </ng-container>

    <ng-template #elseTemplate>
      <ng-container *ngIf="showButtons(data.schedule.status)">

        <button (click)="submit()" mat-raised-button color="primary" *ngIf="data.action == 'create'">
          Salvar
        </button>
        <ng-container *ngIf="canChangeStatus(data.schedule);">
          <button (click)="denySchedule({action: 'deny', change_status: true, status: 'denied'})" mat-raised-button color="warn"
            *ngIf="data.action == 'edit' && data.accessLevel != 'carrier'">
            Negar Agendamento
          </button>
          <button (click)="updateSchedule({action: 'aproved', change_status: true, status: 'confirmed'})" mat-raised-button color="primary" class="greenBtn"
            *ngIf="data.action == 'edit' && data.accessLevel != 'carrier' && data.schedule.status != 'confirmed'">
            Aprovar Agendamento
          </button>
        </ng-container>
        <button (click)="updateSchedule({action: 'accept', change_status: true, status: 'carrier_confirmed'})" mat-raised-button color="primary" class="greenBtn"
          *ngIf="canCarrierConfirm(data)">
          Aceitar Agendamento
        </button>
        <button (click)="updateSchedule({action: 'not_accepted', change_status: true, status: 'carrier_denied'})" mat-raised-button color="warn"
          *ngIf="canCarrierConfirm(data)">
          Recusar Agendamento
        </button>
      </ng-container>
      <button (click)="updateSchedule({action: 'edit', change_status: false, status: null})" mat-raised-button color="primary" *ngIf="canEdit(data)" class="greenBtn" [disabled]="data.schedule.status == 'closed'">
        Salvar
      </button>
      <button (click)="cancelSchedule()" mat-raised-button color="warn" *ngIf="canCancel(data)">
        Excluir
      </button>
      <button mat-raised-button mat-dialog-close="true" (click)="close()">Fechar</button>
    </ng-template>
  </mat-dialog-actions>
</form>
