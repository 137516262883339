import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AccountGuardService } from "../account/shared/account-guard.service";
import { DockPanelComponent } from "./dock-panel/dock-panel.component";

const DockRoutes: Routes = [
  {
    path: "admin",
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "dock-panel",
        component: DockPanelComponent,
        data: {
          title: "Gestão de Docas",
          search: true,
          refresh: false
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(DockRoutes)],
  exports: [RouterModule]
})
export class DockRoutingModule { }
