import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postcode'
})
export class PostcodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.toString().trim();

    if (value && [7, 8].includes(value.length)) {
      value = value.padStart(8, '0');
      return `${value.substr(0, 5)}-${value.substr(5, 3)}`;
    }
  }

}
