<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z8">
<app-paginate-sort-table
  [displayedColumns]="displayedColumns"
  [dataSource]="dataSource"
  [columnsSchema]="columnsSchema"
  [paginate]="true"
  (performActionOnParent)="performAction($event)"
  (performOnChangePageOnParent)="performOnChangePage($event)"
  (performSortChangeOnParent)="performSortChange($event)"
  >
</app-paginate-sort-table>
</div>

<button mat-mini-fab (click)="open2('ADD')" class="mat-fab-bottom-right" color="primary">
  <mat-icon>add</mat-icon>
</button>
