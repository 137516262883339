  <h2 mat-dialog-title>
    Importar agendamentos
  </h2>
  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="item item-2">
        <div style="text-align:center;">
          <mat-card class="list-upload">
            <mat-card-actions>
              <button mat-button color="primary" style="text-align:right;" (click)="onClick()" [disabled]="uploadBtnDisabled">
                Faça aqui o upload do arquivo para importação
                <mat-icon>backup</mat-icon>
              </button>
            </mat-card-actions>
            <mat-card-content class="list-content">
              <mat-list>
                <mat-list-item *ngFor="let item of documents" required>
                  <p matLine>
                    <span (click)="getFile(item)"> {{item.fileName}} </span> 
                    <button mat-icon-button color="warn" aria-label="Apagar arquivo" (click)="deleteFile(item)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </p>
                  
                </mat-list-item>
              </mat-list>
            </mat-card-content>


          </mat-card><input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" [accept]="acceptFileType"
            style="display:none;" required/>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button type="submit" mat-raised-button mat-dialog-close="true">Fechar</button>
      <button (click)="import()" style="color: white;" mat-raised-button color="primary" [disabled]="importBtnDisabled">
        Importar
      </button>
  </mat-dialog-actions>
