import { CoreModule } from './../../../core/core.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardDriverDefaultComponent } from './card-driver/card-driver.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [CardDriverDefaultComponent],
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        CoreModule,
        CommonModule
    ],
    exports: [
        CardDriverDefaultComponent,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule
    ]
})
export class CardsDefaultModule { }
