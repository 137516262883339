import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-exceptions',
  templateUrl: './dialog-exceptions.component.html',
  styleUrls: ['./dialog-exceptions.component.scss']
})
export class DialogExceptionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogExceptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

}
