<h1 class="mat-align-left tkg-title" mat-dialog-title>Controle de Exceções</h1>
<div class="tkg-top-bar" *ngIf="display_message">
  <p class="tkg-message">{{message}}</p>
</div>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="tkg-row">
      <div class="tkg-col-40">
        <mat-form-field appearance="outline" class="tkg-select-100">
          <mat-label>Exceção</mat-label>
          <mat-select formControlName="exception_id">
            <mat-option *ngFor="let item of exception_list" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="tkg-col-40">
        <mat-form-field class="tkg-input-100" appearance="outline">
          <mat-label>Observação</mat-label>
          <input matInput placeholder="Observação" formControlName="observation">
        </mat-form-field>
      </div>
      <div class="tkg-col-20">
        <button mat-fab extended color="primary" (click)="addValue()" [disabled]="!form.valid">
          <mat-icon>add</mat-icon>
          Adicionar
        </button>
      </div>
    </div>
  </form>

<app-simple-table
  *ngIf="showTable"
  [columnsSchema]="columnsSchema"
  [displayedColumns]="displayedColumns"
  [dataSource]="dataSource"
  [transformData]="transformData"
  [transformMethods]="transformMethods"
  (actionOutput)="action($event)"
  ></app-simple-table>
</div>



<div mat-dialog-actions align="end" class="tkg-dialog-btns">
  <button type="button" mat-button color="warn" (click)="cancel()">Fechar</button>
</div>
