<div *ngIf="msgBlock" class="tkg-msg-block">
  <mat-icon class="tkg-warning">warning</mat-icon> {{message}}
</div>
<mat-grid-list [cols]="columnSize" rowHeight="258px" *ngIf="showData">
  <mat-grid-tile *ngFor="let item of datasource">
    <app-tkg-card-one
      [main_slots_fields]="schema.main_slots_fields"
      [main_fields_labels]="schema.main_fields_labels"
      [detail_slots_fields]="schema.detail_slots_fields"
      [detail_fields_labels]="schema.detail_fields_labels"
      [datasource]="item"
      [action_list]="listAction"
      (actionListController)="actionListController($event)"
      (performActionOnParent)="performAction($event)"
    ></app-tkg-card-one>
  </mat-grid-tile>
</mat-grid-list>
