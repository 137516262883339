import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable, filter, map, startWith } from 'rxjs';
import { Viewport } from '../shifts';

@Component({
  selector: 'app-shift-tabs',
  templateUrl: './shift-tabs.component.html',
  styleUrls: ['./shift-tabs.component.scss']
})
export class ShiftTabsComponent implements OnInit{
  tabs = {
    shift:{
      active:true,
    },
    shift_schedules:{
      active:true,
    },
    shift_break:{
      active:false,
    },
    extras_hours_shift:{
      active:false,
    },
    inactive_days:{
      active:false,
    }
  }

  viewports:{[key:string]:Viewport} = {
    '(min-width: 200px) and (max-width: 499.98px)':'200-500',
    '(min-width: 500px) and (max-width: 799.98px)':'500-800',
    '(min-width: 800px) and (max-width: 1199.98px)':'800-1200',
    '(min-width: 1200px)':'1200-over'
  }

  protected viewport$: Observable<Viewport> = this.breakpointObserver
    .observe(Object.keys(this.viewports))
    .pipe(filter((result:BreakpointState)=>result?.matches))
    .pipe(map((result: BreakpointState) => {
      const key = Object.keys(this.viewports).find((key)=>result.breakpoints[key])! as keyof typeof this.viewports;
      return this.viewports[key];
    }));

  constructor(
    private breakpointObserver: BreakpointObserver
  ){}

  ngOnInit(): void {
    this.tabs = {...this.tabs, ...{}}
  }

}
