import { StepsFilterService } from "./shared/steps-filter.service";
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  Inject,
  ChangeDetectorRef,
  TemplateRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  Router,
  Event,
  NavigationError,
  ChildActivationEnd,
} from "@angular/router";
import { environment } from "../environments/environment";
import {
  debounceTime,
  delay,
  exhaustMap,
  filter,
  first,
  map,
  of,
  switchMap,
  throwError,
} from "rxjs";
import { AccountDetailComponent } from "./account/account-detail/account-detail.component";
import { AccountService } from "./account/shared/account.service";
import { Subscription, Observable, Subject } from "rxjs";
import { LoaderState } from "./core/loader-state";
import { LoaderService } from "./core/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalPanelStatusLorealComponent } from "./dashboard-loreal/modal-panel-status/modal-panel-status.component";
import { DynamicStatusPanelModalComponent } from "./dashboard-loreal/dynamic-status-panel-modal/dynamic-status-panel-modal.component";
import { DOCUMENT, NgTemplateOutlet } from "@angular/common";
import { EventsService } from "./shared/events.service";
import { SearchService } from "./shared/search.service";
import { RouteParamsChangeService } from "./shared/route-params-change.service";
import { MatSidenav } from "@angular/material/sidenav";
import { DialogAlertComponent } from "./shared/dialog-alert/dialog-alert.component";
import { HealthCheckNotificationComponent } from "./health-check/health-check-notification/health-check-notification.component";
import { HealthCheckService } from "./health-check/shared/health-check.service";
import { HealthCheckToastrComponent } from "./health-check/health-check-toastr/health-check-toastr.component";
import { WSService2 } from "./shared/websocket/wss2.service";
import { WssService } from "./shared/websocket/wss.service";
import { SoundService } from "./shared/sound.service";
import { DialogSwitchAccountComponent } from "./account/dialog-switch-account/dialog-switch-account.component";
import { DialogReauthenticateComponent } from "./account/dialog-reauthenticate/dialog-reauthenticate.component";
import { GlobalService } from "./shared/util/global.service";

type CustomToolbar = {
  template: TemplateRef<any>;
  reverse?: boolean;
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("drawer") drawer: any;
  @ViewChild("bar-element") toolbar: any;
  @ViewChild("appHealthCheckNotification", { static: false })
  appHealthCheckNotification: HealthCheckNotificationComponent;
  customToolbar?: CustomToolbar | null;
  private subject = new Subject<any>();
  showTitle: boolean = true;
  appTitle: string;
  headerTitle!: string;
  account: any;
  expanded: any = {
    open: false,
    title: "",
    route: [],
  };
  searchWatcher!: Subscription;
  searchEnabled!: boolean;
  loaderWatcher!: Subscription;
  loading!: boolean;
  routeData: any;
  export!: boolean;
  userAccount: any;
  topbarcolor!: string;
  shToolbar: string;
  colorToolbar = "initial";
  isAuth!: boolean;
  fontClass = "font-color-black";
  filterWatcher: Subscription;
  filterCount: String = "filter_alt";
  // Visibilidade inicial dos botões da barra superior
  btnDownloadReport = false;
  btnStatusPanel = false;
  searchShow = false;
  refreshEnabled = false;
  environment: any;
  browserLang: any;

  // account = Account;
  version: any;
  checkVersionAfter = Date.now() + 60000 * 15;

  public selectedItem = "";
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(["(max-width: 768px)"])
    .pipe(map((result: BreakpointState) => result.matches));

  filterEnabled: any;
  isDesktop: boolean;
  isWeb: boolean;
  displaySearch: boolean;
  isHandset: boolean = false;
  notification: any;
  toastrData: any;
  arrowClass = "chevron_right";
  getNotification: boolean;
  activeRoute: string;
  soundIcon = "volume_up";

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private events: EventsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private websocketService: WSService2,
    private websocketUserLogout: WssService,
    private searchService: SearchService,
    private loaderService: LoaderService,
    private healthCheckService: HealthCheckService,
    protected accountService: AccountService,
    @Inject(DOCUMENT) private document: any,
    public breakpointObserver: BreakpointObserver,
    private stepsFilterServie: StepsFilterService,
    private routeParamsChangeService: RouteParamsChangeService,
    public soundService: SoundService,
    public globalService: GlobalService,
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.XSmall,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
    // this.activateLayout();
    this.shToolbar = "h-toolbar";

    this.appTitle = "";

    translate.addLangs(["pt", "pt-BR", "en", "es"]);
    translate.setDefaultLang("pt-BR");

    this.browserLang = translate.getBrowserLang();
    translate.use(
      this.browserLang.match(/pt|pt-BR|en|es/) ? this.browserLang : "pt-BR",
    );

    const navEndEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        // console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar

        // checa se o menu deve ser marcado como ativo
        this.getPageDefinitions();
        this.topButtonsActive();
      }

      if (event instanceof ChildActivationEnd) {
        // console.log('ChildActivationEnd');
        this.getPageDefinitions();
        this.topButtonsActive();
        this.routeParamsChangeService.onChange();
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar
        // Present error to user
        // console.log('NavigationError');
        // console.log(event.error);
      }
    });

    // ToDo: Reativar
    // navEndEvents$.subscribe((event: NavigationEnd) => {
    //   gtag('config', 'G-9PRCHRV44R', {
    //     page_path: event.urlAfterRedirects,
    //   });
    // });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.isHandset = true;
      this.isWeb = false;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      this.isHandset = false;
      this.isWeb = false;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.isHandset = false;
      this.isWeb = false;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.isHandset = false;
      this.isWeb = true;
    }
  }

  toggleTitle() {
    this.showTitle = !this.showTitle;
    this.arrowClass = this.showTitle ? "chevron_right" : "chevron_left";
  }

  openSearch(event: any) {
    this.displaySearch = true;
    this.searchService.onOpen();
  }

  topButtonsActive() {
    this.routeData = this.getDataRoute();
    // Ativa os botões na barra superior
    if (this.routeData) {
      this.export =
        typeof this.routeData.export !== "undefined" &&
        this.routeData.export.show;
      this.btnDownloadReport = this.routeData.btnDownloadReport;
      this.btnStatusPanel = this.routeData.btnStatusPanel;
      this.filterEnabled = this.routeData.filter;
      this.searchEnabled = this.routeData.search;
    }
  }

  ngAfterContentChecked() {
    this.cdr?.detectChanges();
  }

  accountDetail() {
    const dialogRef = this.dialog.open(AccountDetailComponent, {
      panelClass: ["dialog-small", "dialog-fullscreen"],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      // this.logout();
      if (data) {
        this.snackBar.open(
          "Conta atualizada com sucesso! por favor faça login novamente",
          "OK",
          {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          },
        );
      }
    });
  }

  switchAccount() {
    const dialogRef = this.dialog.open(DialogSwitchAccountComponent, {
      panelClass: ["dialog-width-45vw", "dialog-fullscreen"],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      // this.logout();
      if (data) {
        this.snackBar.open(
          "Conta atualizada com sucesso! por favor faça login novamente",
          "OK",
          {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          },
        );
      }
    });
  }

  getDataRoute() {
    const data: any = [];
    const activeRoutes: ActivatedRoute[] = this.route.children;
    activeRoutes.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      data.push(activeRoute.snapshot.data);
    });

    return data.find((value: any) => {
      return value.title;
    });
  }

  logout() {
    this.isAuth = false;
    this.shToolbar = "h-toolbar";
    if (this.account) {
      this.accountService.logout();
    }
  }

  getPageDefinitions() {
    const menu = this.account?.menu;
    if (menu) {
      menu.forEach((item: any) => {
        item.active = false;
        if (item.hasOwnProperty("resource")) {
          if (
            "/" + item.resource.route == this.router.url ||
            "/" + item.resource.route_mobile == this.router.url
          ) {
            this.appTitle = item.resource.page_title[this.browserLang];
          }
        } else {
          item.routes.forEach((i: any) => {
            if (
              "/" + i.resource.route == this.router.url ||
              "/" + i.resource.route_handset == this.router.url
            ) {
              this.appTitle = i.resource.page_title[this.browserLang];
              item.active = true;
            }
          });
        }
      });
      this.account.menu = menu;
    }
  }

  ngOnInit() {
    this.loadUserAccount();

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.route.paramMap.subscribe((params) => {
          const actualPage = this.router.url.split("/");
          const envData = this.accountService.account;

          if (envData && Date.now() > this.checkVersionAfter) {
            this.accountService.checkVersion().subscribe((data) => {
              this.version = data;
              //Checar atualização a cada 30 min
              this.checkVersionAfter = Date.now() + 60000 * 30;
            });
            const rawPageData =
              envData.system_client.resource.environment.pages[
                actualPage.slice(-1)[0]
              ];

            // CONTROLE DE VERSÃO
            if (
              this.version != null &&
              this.version.version != null &&
              this.version.version != envData.system_client.resource.version
            ) {
              let snackBarRef = this.snackBar.open(
                "Aplicação desatualizada, por favor atualize!",
                "ATUALIZAR",
                {
                  verticalPosition: "top",
                },
              );
              snackBarRef.afterDismissed().subscribe((info) => {
                ["account"].forEach((key) => {
                  localStorage.removeItem(key);
                });
                this.accountService.identity().then((identity) => {
                  this.account = identity;
                });
                if (info.dismissedByAction === true) {
                  setTimeout(function () {
                    location.reload();
                  }, 2000);
                }
              });
            }
          }

          // }
        });
      });

    // Account events
    this.accountService.event
      .pipe(
        debounceTime(1000),
        exhaustMap((event) => {
          if (event?.action === "reauthenticate") {
            if (
              !this.activeRoute.match(/auth\/login/gi) &&
              !this.accountService.isAuthenticated()
            ) {
              return new Observable((subscriber) => {
                this.dialog
                  .open(DialogReauthenticateComponent, {})
                  .afterClosed()
                  .subscribe(() => {
                    subscriber.next(event);
                    subscriber.complete();
                  });
              });
            }
          }
          return of(event);
        }),
      )
      .subscribe((event: any) => {
        if (event.action === "login") {
          this.loadUserAccount();
        } else if (event.action === "logout") {
          this.isAuth = false;
          this.snackBar.dismiss();
          this.shToolbar = "h-toolbar";
          this.router.navigate(["auth/login"]);
        } else if (event.action === "update") {
          this.account = event.data;
        } else if (event.action === "create") {
          this.accountService
            .login({
              email: event.data.email,
              password: event.data.password,
            })
            .subscribe((r: any) => {});
        } else if (event.action === "password_reset") {
          this.accountService
            .login({
              email: event.data.email,
              password: event.data.password,
            })
            .subscribe();
        }
      });

    // Search events
    this.searchWatcher = this.searchService.show.subscribe((show: boolean) => {
      this.searchShow = show;
      this.displaySearch = show;
    });

    // Loader events
    this.loaderWatcher = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => setTimeout(() => (this.loading = state.show), 0),
    );

    // Pega as chaves definidas no objeto data de cada *-routing.modules.ts
    this.router.events
      .pipe(filter((evt: any) => evt instanceof NavigationEnd))
      .subscribe(() => {
        if (this.route.firstChild!.routeConfig!.data) {
          this.routeData = JSON.parse(
            JSON.stringify(this.route.firstChild!.routeConfig!.data),
          );
          this.searchEnabled = this.routeData.search;
          this.refreshEnabled = this.routeData.refresh;
        }

        // Itera a chave top_bar_buttons do environment e ativa os botões
        // registrados no array, caso ele tenha sido marcado como true na rota
        // de cada módulo
        if (this.environment) {
          if (this.environment.top_bar_buttons && this.routeData) {
            this.environment.top_bar_buttons.forEach((item: string) => {
              const key = item as keyof typeof this;
              if (this.routeData[item]) {
                this[key] = this.routeData[key];
              }
            });
          } else if (!this.isAuth) {
            this.logout();
          }
        }
      });

    // Filter events
    this.filterWatcher = this.stepsFilterServie.value.subscribe((ft: any) => {
      if (parseInt(ft.value) > 0 && parseInt(ft.value) < 10) {
        this.filterCount = `filter_${ft.value}`;
      } else if (parseInt(ft.value) > 9) {
        this.filterCount = `filter_9_plus`;
      } else {
        this.filterCount = `filter_alt`;
      }
    });

    this.activeRoute = window.location.href;
    if (
      (this.isAuth &&
        this.account.roles.indexOf("remember") !== -1 &&
        this.activeRoute.indexOf("password_reset") !== -1) ||
      this.activeRoute.indexOf("login") !== -1
    ) {
      this.toastrNotification();
      // this.wssHealthNotification();
    }
  }

  toggleSound() {
    this.soundIcon = this.soundIcon == "volume_up" ? "volume_off" : "volume_up";
    this.account.has_sound = !this.account.has_sound;
    this.soundService.changeSoundState(this.account.has_sound, this.account.id);
  }

  getToastrNotification() {
    this.healthCheckService.getToastrNotification().subscribe((r: any) => {
      this.toastrData = r;
      this.showToastrNotification();
    });
  }

  showToastrNotification() {
    this.snackBar.openFromComponent(HealthCheckToastrComponent, {
      data: this.toastrData,
      verticalPosition: "bottom",
      horizontalPosition: "right",
    });
  }

  //TODO: REVERT AFTER PANEL IS FULLY WORKING ON ALL CLIENTS
  modalPainelStatus() {
    let pages = this.account.system_client.resource.environment.pages;
    let dynamic_status_panel = false;

    for (let item in pages) {
      if (item == "dynamic_status_panel") {
        dynamic_status_panel = true;
      }
    }

    if (dynamic_status_panel) {
      this.dialog.open(DynamicStatusPanelModalComponent, {
        panelClass: ["dialog-large-black"],
        disableClose: true,
        data: {
          item: JSON.parse(JSON.stringify("")),
        },
      });
    } else {
      this.dialog.open(ModalPanelStatusLorealComponent, {
        panelClass: ["dialog-large-black"],
        disableClose: true,
        data: {
          item: JSON.parse(JSON.stringify("")),
        },
      });
    }

    this.openFullscreen();
  }

  openFullscreen() {
    const elem = this.document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    const elem = this.document.documentElement;
    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) {
      elem.msExitFullscreen();
    }
  }

  onDownload() {
    this.events.publishEvent("download_data");
  }

  onExpended(item: any) {
    this.expanded = {
      open: true,
      title: item.translation_name
        ? this.translate.instant(item.translation_name)
        : item.title,
      route: item.routes,
    };
  }

  onCloseExpended(nav: MatSidenav) {
    this.expanded = {
      open: false,
      title: "",
      route: [],
    };
    const isSmallScreen =
      this.breakpointObserver.isMatched("(max-width: 599px)");
    if (isSmallScreen) {
      nav.toggle();
    }
  }

  openFilter(event: any) {
    this.stepsFilterServie.onOpen();
  }

  private wssUserLogoutListener(): void {
    if (!this.account?.system_client?.id) return void 0;

    // User account logout
    this.websocketUserLogout
      .connect(
        `${environment.wsUrl}/users_logout/${this.account.system_client.id}`,
      )
      .pipe(delay(8000))
      .subscribe((data: any) => {
        this.accountService.identity().then((identity) => {
          let logout = false;
          switch (data?.msg?.action) {
            case "user_logout":
              logout = identity?.id == data.msg.user_id;
              break;
            case "group_logout":
              logout = identity?.group_id == data.msg.group_id;
              break;
            case "logout_all":
              logout = identity?.system_client?.id == data.msg.system_client_id;
              break;
          }
          if (logout) {
            this.dialog
              .open(DialogAlertComponent, {
                data: {
                  title: "Conta de usuário.",
                  dialog_type: "ok_only",
                  message:
                    data.msg.message ||
                    "A conta de usuário foi redefinida necessário acessar novamente.",
                },
              })
              .afterClosed()
              .pipe(switchMap(() => of(this.logout())))
              .pipe(delay(500))
              .subscribe(() => {
                //refresh application
                window.location.reload();
              });
          }
        });
      });
  }

  wssHealthNotification() {
    this.websocketService.CHANNEL = `/health/notification`;
    this.websocketService.connect().subscribe(() => {
      this.getNotification = true;
      this.toastrNotification();
    });
  }

  toastrNotification() {
    this.healthCheckService
      .getActiveToastrNotification()
      .pipe(delay(500))
      .subscribe((r: any) => {
        this.notification = r.toastr_notification;
        if (this.notification) {
          this.getToastrNotification();
        }
      });
  }

  private loadUserAccount() {
    this.accountService.identity().then((identity) => {
      if (!this.account && identity) {
        this.account = identity;
        const resource = identity.system_client?.resource;

        this.isAuth = true;
        this.shToolbar = "s-toolbar";
        this.environment = resource?.environment;
        this.colorToolbar = resource?.top_bar_background_color || "initial";
        this.fontClass = resource?.font_class || "font-color-white";

        const menuRoutes = identity.menu.reduce((pivot: any[], item: any) => {
          if (item.resource) pivot = pivot.concat(item.resource);

          if (!item.resource && item.routes?.length > 0)
            pivot = pivot.concat(item.routes.map((item: any) => item.resource));

          return pivot;
        }, []);

        const hasAccessRoute = menuRoutes.some((route: any) =>
          route.route
            .replaceAll("/", "")
            .match(window.location.pathname.replaceAll("/", "")),
        );

        this.router
          .navigate([
            hasAccessRoute ? window.location.pathname : menuRoutes[0].route,
          ])
          .then(() => {
            if (identity.menu[0]?.icon) {
              const newMenu: any = [];
              const pathName = window.location.pathname;
              identity.menu.forEach((list: any) => {
                const active = list.routes.some(
                  (route: any) => pathName.indexOf(route.resource.route) > -1,
                );
                newMenu.push({ ...list, active });
              });
              this.account.menu = newMenu;
            }
          });

        this.toastrNotification();
        // this.wssHealthNotification();
        this.wssUserLogoutListener();
      }
    });
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
