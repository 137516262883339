import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-health-check-info',
  templateUrl: './health-check-info.component.html',
  styleUrls: ['./health-check-info.component.scss']
})
export class HealthCheckInfoComponent {
  account: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    if(typeof this.data.resource == 'string') {
      this.data.resource = JSON.parse(this.data.resource);
    }
  }

  formatDate(date: any){
    let newDate = moment(date).format('DD MMM YYYY');
    return newDate
  }

  dateDifference(date: any) {
    const date1:any = new Date(date);
    const date2:any = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let hourFormat = moment(date).format('HH:mm');
      if(!diffDays || diffDays == 0)
        return ` recentemente às ${hourFormat} UTC`
    return ` há ${diffDays} dias às ${hourFormat} UTC`
  }

}
