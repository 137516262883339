<h4 mat-dialog-title>Selecione Motivo de Negar o Agendamento</h4>
<mat-dialog-content class="mat-typography">
  <div class="container">
    <mat-form-field>
      <mat-label>Motivo</mat-label>
      <mat-select [(ngModel)]="value">
        <mat-option *ngFor="let motive of denymotives" [value]="motive">
          {{motive}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="confirm()" mat-raised-button color="primary">
    Confirmar
  </button>
  <button (click)="cancel()" mat-raised-button color="secondary">
    Cancelar
  </button>
</mat-dialog-actions>
