<button mat-mini-fab (click)="cancel()" id="x">X</button>
<div class="div-title">
  <img src="assets/img/logo_dhl_small.png" class="espaco_logo" alt="Logotipo" />
  <!-- <h1 mat-dialog-title>PAINEL DE STATUS</h1> -->
  <span class="span-title">PAINEL DE STATUS</span>
  <span class="span-date"
    >Última atualização {{ lastUpdate | date: "short" }}</span
  >
</div>

<table id="customers" class="font-large" *ngIf="allDataAvailable">
  <thead>
    <tr>
      <th>Motorista</th>
      <th>Transportadora</th>
      <th *ngIf="system_client_id && system_client_id != 5 && system_client_id != 8 && system_client_id != 109">ID</th>
      <th *ngIf="system_client_id && system_client_id == 8">Data/Hora</th>
      <th *ngIf="system_client_id && system_client_id == 5 || system_client_id == 109">OT</th>
      <th>Cavalo</th>
      <th>Baú</th>
      <th *ngIf="showDepartments">Departamento</th>
      <th>Operação</th>
      <th>Status</th>
      <th>Farol</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of drivers">
      <td>{{ item?.driver?.name }}</td>
      <td>{{ item?.carrier?.name }}</td>
      <td *ngIf="system_client_id && system_client_id != 5 && system_client_id != 8">
        {{
          item?.event && item?.event.resource
            ? item?.event.resource.sid || item?.event.resource.external_event_id
            : "S/ID"
        }}
      </td>
      <td *ngIf="system_client_id && system_client_id == 8">
        {{
          item?.created_at ? (item?.created_at | date: 'dd/MM/yy HH:mm') : "S/DATA"
        }}
      </td>
      <td *ngIf="system_client_id && (system_client_id == 5 || system_client_id == 109)">
        {{
          item?.event && item?.event.resource
            ? item?.event.resource.document || "S/OT"
            : "S/OT"
        }}
      </td>
      <td>{{ item?.resource.board_horse || "S/ Cavalo" }}</td>
      <td>{{ item?.resource.board_cart || "S/ Baú" }}</td>
      <td *ngIf="showDepartments">
        {{
          nameOfDepartment(item?.resource.department_id)
        }}
      </td>
      <td>
        {{
          nameOfOperation(item?.resource.operation) ||
            nameOfOperation(item?.resource.operation_id)
        }}
      </td>
      <td>
        <div
          class="driver-status"
          [ngClass]="{
            'app-chip-waiting-panel': item?.resource.status == 'waiting',
            'app-chip-directed_park-panel':
              item?.resource.status == 'directed_park',
            'app-chip-directed_dock-panel':
              item?.resource.status == 'directed_dock',
            'app-chip-on_park-panel': item?.resource.status == 'on_park',
            'app-chip-on_dock-panel': item?.resource.status == 'on_dock',
            'app-chip-finished_loading-panel':
              item?.resource.status == 'finished_loading',
            'app-chip-shipping_finished-panel':
              item?.resource.status == 'shipping_finished',
            'app-chip-fetch_invoice-panel':
              item?.resource.status == 'fetch_invoice',
            'app-chip-fetch_invoice_dfd-panel':
              item?.resource.status == 'fetch_invoice_dfd',
            'app-chip-fetch_invoice_delivered-panel':
              item?.resource.status == 'fetch_invoice_delivered'
          }"
        >
          {{ showStatus(item?.resource) }}
        </div>
      </td>
      <td style="text-align: center">
        <div
          class="farol"
          [ngClass]="getClassAlert(item?.resource.alert)"
        ></div>
      </td>
    </tr>
  </tbody>
</table>
<!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator> -->

<div style="text-align: center; margin-top: 16px">
  <img src="assets/img/logo-maestro@2x.png" alt="Logotipo" />
</div>

<!-- <div class="mat-elevation-z8">
    <table mat-table  matSort>
        <ng-container matColumnDef="driver">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="drivername">Motorista</th>
            <td mat-cell >Luiz Alberto</td>
        </ng-container>

        <ng-container matColumnDef="carrier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="carriername">Transportadora</th>
            <td mat-cell >TransDaniel</td>
        </ng-container>

        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef>Operação</th>
            <td mat-cell>Inbound</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell>Doca 27</td>
        </ng-container>

        <ng-container matColumnDef="farol">
            <th mat-header-cell *matHeaderCellDef>Farol</th>
            <td mat-cell>Doca 27</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div> -->
