import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { DockPanelComponent } from './dock-panel/dock-panel.component';
import { DockAddEditDialogComponent } from './dock-add-edit-dialog/dock-add-edit-dialog.component';
import { DockAssignDialogComponent } from './dock-assign-dialog/dock-assign-dialog.component';
import { DockRoutingModule } from './dock-routing.module';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  imports: [CommonModule, DockRoutingModule, CoreModule, SharedModule,],
  declarations: [
    DockPanelComponent,
    DockAddEditDialogComponent,
    DockAssignDialogComponent

  ]
})
export class DockModule { }
