import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/carts`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  update(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/carts`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  unlinked(options?: any): Observable<any> {
    const httpParams = options;

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/unlinked-vehicles`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  valetOnCheckin(options?: any): Observable<any> {
    const httpParams = options;

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/drivers-valet`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }
}
