import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WssService } from 'src/app/shared/websocket/wss.service';
import { Subscription } from 'rxjs';
import { DockManagementService } from '../shared/dock-management.service';
import { SearchService } from 'src/app/shared/search.service';
import { DockAddEditDialogComponent } from '../dock-add-edit-dialog/dock-add-edit-dialog.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { WSService2 } from 'src/app/shared/websocket/wss2.service';

@Component({
  selector: 'app-dock-panel',
  templateUrl: './dock-panel.component.html',
  styleUrls: ['./dock-panel.component.scss']
})
export class DockPanelComponent implements OnInit, OnDestroy {
  searchWatcher: any;
  searchString: string = '';
  searchRef: any;
  account: any;
  wsSubscription!:Subscription;
  columns: number;
  dockListRef: any[] = [];
  dockList: any[] = [];
  dockFilter = 'all';
  dockNumbers: any = {
    total: 0,
    busy: 0,
    unbusy: 0,
    maintenance: 0
  }
  requestFilterOptions: any = {
    busy: '',
    maintenance: ''
  }
  typeList: any;
  typeFilter = '0';
  consider_type = false;
  type_number: number;
  dock_id: any;
  show_feedback = false;
  release_dock_id: any;
  departmentList: any[] = [];
  departmentFilter: '0';
  consider_department: boolean;
  department_number: any;
  docks_shared: boolean;

  constructor(
    private dockManagementService: DockManagementService,
    private supportDataService: SupportDataService,
    private searchService: SearchService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private wssService: WSService2,
  ) {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.docks_shared = this.account.system_client.resource.environment.docks_shared;
    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.Web,
        Breakpoints.XSmall,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }


  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.columns = 4;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      this.columns = 6;
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.columns = 8;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.columns = 10;
    }
  }

  ngOnInit() {
    document.addEventListener("visibilitychange", () => {
      if(document.visibilityState === 'visible') {
        this.wssService.closeConnection();
        console.log('reconect websocket');
        this.subcribeWebsocket()
        this.getDockList();
      }
    }, false);
    this.subcribeWebsocket();

    this.getDockList();
    this.getDepartments();
    this.getDockTypes();
    this.searchWatcher = this.searchService.value.subscribe((search: any) => {
      this.searchString = search.value;
      if(this.searchString) {
        this.applySearch();
      } else {
        this.dockList = JSON.parse(JSON.stringify(this.searchRef));
      }
    })
  }

  getDockList() {
    this.dockManagementService.index().subscribe((r: any) => {
      this.dockListRef = r.data;
      this.dockList = JSON.parse(JSON.stringify(this.dockListRef));
      this.getDockNumbers();
    });
  }

  getDockTypes() {
    this.supportDataService.generic({name: 'spaces_type'}).subscribe((r: any) => {
      this.typeList = r.data.resource;
    });
  }

  getDepartments() {
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departmentList = r.data.resource;
    });
  }

  getDockNumbers() {
    let dockBusy = [];
    let dockUnbusy = [];
    let dockMaintenance = [];
    this.dockList.forEach((i: any) => {
      if(i.busy) {
        dockBusy.push(1);
      } else {
        dockUnbusy.push(1);
      }
      if(i.maintenance) {
        dockMaintenance.push(1);
      }
    });
    this.dockNumbers.total = this.dockListRef.length;
    if(this.consider_type) {
      this.dockNumbers.total = this.dockListRef.filter((i: any) => i.type == this.type_number).length;
    }
    this.dockNumbers.busy = dockBusy.length;
    this.dockNumbers.unbusy = dockUnbusy.length - dockMaintenance.length;
    this.dockNumbers.maintenance = dockMaintenance.length;
    this.applyFilter();
    if(this.searchString.length > 0) {
      this.applySearch();
    }
  }

  subcribeWebsocket() {
    let user = JSON.parse(localStorage.getItem("account")!).id;
    let client = JSON.parse(localStorage.getItem("account")!).system_client.id;

    this.wssService.CHANNEL = `/docks/${client}`;
    this.wssService.connect().subscribe((msg: any) => {
      const wsMsg: any = Object.assign({}, msg);
      if(user != wsMsg.user_id) {
        this.updateData(wsMsg);
        if(wsMsg.release_dock_id) {
          this.updateReleasedData(wsMsg);
        }
      }
    });
  }

  snackBarMessage(msg: string) {
    this.snackBar.open(
      msg, "OK",
      {
        duration: 3000,
        horizontalPosition: "left",
        verticalPosition: "bottom",
      }
    );
  }

  onChangeType($event: any) {
    this.typeFilter = $event.value;
    if($event.value != "0") {
      this.consider_type = true;
      this.type_number = $event.value;
    } else {
      this.consider_type = false;
    }
    this.applyFilter();
    this.getDockNumbers();
  }

  onChangeBusy($event: any) {
    this.dockFilter = $event.value;
    this.applyFilter();
  }

  onChangeArea($event: any) {
    console.log($event);
    this.departmentFilter = $event.value;
    if($event.value != "0") {
      this.consider_department = true;
      this.department_number = $event.value;
    } else {
      this.consider_department = false;
    }
    this.applyFilter();
    this.getDockNumbers();
  }

  addEditAction(action: string, id?: number) {
    const dialogRef = this.dialog.open(DockAddEditDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify({id: id})),
        action: action,
        dock_types: this.typeList,
        departments: this.departmentList,
        docks_shared: false
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && !res.error) {
        console.log('ao fechar o modal');
        console.log(res);
        if(res.action === 4) {
          this.getDockList();
        } else {
          this.updateData(res);
          if(res.release_dock_id) {
            console.log('atualiza doca liberada');
            this.updateReleasedData(res);
          }
        }
      }
    });
  }

  updateData(res: any) {
    console.log('update data')
    /// Se o id que chegou no websocket não estiver na lista, força a atualização via API
    /// implementar
    let dock_idx: number;
    this.dockManagementService.show(res.dock_id).subscribe((i: any) => {
      this.dock_id = i.data.id;
      if(res.action == 1) {
        console.log('novo');
        console.log(i);
        this.dockListRef.push(i.data);
      } else {
        this.dockListRef.forEach((item: any, idx: number) => {
          if(item.id == i.data.id) {
            dock_idx = idx;
            this.dockListRef[idx] = i.data;
          }
        });
        this.dockList.forEach((item: any, idx: number) => {
          if(item.id == i.data.id) {
            this.dockList[idx] = i.data;
          }
        });
      }

      this.snackBarMessage(res.msg);
      this.getDockNumbers();
      this.applyFilter();
      this.show_feedback = true;
      setTimeout(()=>{
        this.show_feedback = false;
      }, 4000);
    });
  }

  updateReleasedData(res: any) {
    let dock_idx: number;
    this.dockManagementService.show(res.release_dock_id).subscribe((i: any) => {
      this.release_dock_id = i.data.id;
      this.dockListRef.forEach((item: any, idx: number) => {
        if(item.id == i.data.id) {
          dock_idx = idx;
          this.dockListRef[idx] = i.data;
        }
      });
      this.dockList.forEach((item: any, idx: number) => {
        if(item.id == i.data.id) {
          this.dockList[idx] = i.data;
        }
      });

      this.snackBarMessage(`${i.data.name} foi liberada.`);
      this.getDockNumbers();
      this.applyFilter();
      this.show_feedback = true;
      setTimeout(()=>{
        this.show_feedback = false;
      }, 4000);
    });
  }

  applyFilter() {
    if(this.dockFilter == 'all') {
      this.dockList = JSON.parse(JSON.stringify(this.dockListRef));
      if(this.consider_department) {
        this.dockList = this.dockList.filter((i: any) => (i.department_id == this.department_number));
      }
      if(this.consider_type) {
        this.dockList = this.dockList.filter((i: any) => (i.type == this.type_number));
      }
    }
    if(this.dockFilter == 'busy') {
      this.dockList = JSON.parse(JSON.stringify(this.dockListRef));
      this.dockList = this.dockList.filter((i: any) => (i.busy == true) && (i.maintenance == false));
      if(this.consider_department) {
        this.dockList = this.dockList.filter((i: any) => (i.department_id == this.department_number));
      }
      if(this.consider_type) {
        this.dockList = this.dockList.filter((i: any) => (i.type == this.type_number));
      }
      this.dockNumbers.busy = this.dockList.length;
    }
    if(this.dockFilter == 'unbusy') {
      this.dockList = JSON.parse(JSON.stringify(this.dockListRef));
      this.dockList = this.dockList.filter((i: any) => (i.busy == false) && (i.maintenance == false));
      if(this.consider_department) {
        this.dockList = this.dockList.filter((i: any) => (i.department_id == this.department_number));
      }
      if(this.consider_type) {
        this.dockList = this.dockList.filter((i: any) => (i.type == this.type_number));
      }
      this.dockNumbers.unbusy = this.dockList.length;
    }
    if(this.dockFilter == 'maintenance') {
      this.dockList = JSON.parse(JSON.stringify(this.dockListRef));
      this.dockList = this.dockList.filter((i: any) => i.maintenance == true);
      if(this.consider_department) {
        this.dockList = this.dockList.filter((i: any) => (i.department_id == this.department_number));
      }
      if(this.consider_type) {
        this.dockList = this.dockList.filter((i: any) => (i.type == this.type_number));
      }
      this.dockNumbers.maintenance = this.dockList.length;
    }

    this.searchRef = this.dockList;

  }

  applySearch() {
    if(this.searchString.length > 0) {
      const search = this.searchString.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
      this.dockList = JSON.parse(JSON.stringify(this.searchRef));
      this.dockList = this.dockList.filter((i: any) => {
        const dockName = i.name.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
        return dockName.includes(search);
      });
    }
  }

  ngOnDestroy(): void {
    this.wssService.closeConnection();
  }


}

