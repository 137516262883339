import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-loading-table',
  templateUrl: './loading-table.component.html',
  styleUrls: ['./loading-table.component.scss'],
  standalone: true,
  imports: [MatCardModule]
})
export class LoadingTableComponent {

}
