import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HubListComponent } from './hub-list/hub-list.component';
import { HubRoutingModule } from './hub-routing.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { HubInfoComponent } from "./hub-info/hub-info.component";
import { StageAlertsComponent } from './stage-alerts/stage-alerts.component';
import { FormlyDatepickerRangeComponent } from './../shared/formly/formly-datepicker-range/formly-datepicker-range.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        HubRoutingModule,
        SharedModule,
        NgxEchartsModule,
        FormlyModule.forRoot({
            types: [
                {
                    name: "formly-datepicker-range",
                    component: FormlyDatepickerRangeComponent
                }
            ]
        }),
        FormlyMaterialModule
    ],
    declarations: [
        HubListComponent,
        HubInfoComponent,
        StageAlertsComponent
    ]
})
export class HubModule {}
