<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id_external">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Código</th>
      <td mat-cell *matCellDef="let element" class="aumentar">{{element.id_external}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="aumentar">Nome</th>
      <td mat-cell *matCellDef="let element" class="aumentar">
        <div>
          <span class="ellipsis" class="aumentar">{{element.name}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button matTooltip="Abrir" (click)="addEdit($event, 'EDIT', element)">
          <mat-icon>folder_open</mat-icon>
        </button>

        <button mat-icon-button matTooltip="Deletar" (click)="delete($event, element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>


  <button mat-mini-fab (click)="addEdit($event, 'ADD')" class="mat-fab-bottom-right" color="primary">
    <mat-icon>add</mat-icon>
  </button>
</div>
