<h2 *ngIf="showData">{{checkin?.driver_name.toUpperCase()}}</h2>
<h2 *ngIf="!showData" class="animated-background">
  <p class="background-masker"></p>
</h2>
<p *ngIf="showData" class="tkg-details">
  <mat-chip
    class="chip-text"
    [style.background-color]="checkin?.status_color"
    [style.color]="'#ffffff'"
  >
    {{checkin?.status_name}}
  </mat-chip>
  {{details}}
</p>
<div *ngIf="!showData" class="tkg-details">
  <div class="animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="animated-background">
    <div class="background-masker"></div>
  </div>
</div>
<mat-nav-list *ngIf="showData">
  <a mat-list-item (click)="setAction(item)" *ngFor="let item of actionsList">
    <span mat-line>{{item.name}}</span>
  </a>
</mat-nav-list>
