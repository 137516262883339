
import { Component, OnInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '../shared/report-service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { debounceTime, switchMap, map, catchError, tap, startWith, filter } from 'rxjs/operators';
import { CarrierService } from '../../carrier/shared/carrier.service';
import { DriversService } from '../../drivers/shared/drivers.service';
import { SupportDataService } from '../../shared/support-data.service';
import { UserService } from '../../user/shared/user.service';
import { EventsService } from '../../shared/events.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit, OnDestroy {
  private reportChunkStarted:Boolean=false;
  form = this.formBuilder.group({
    boards:["" , [Validators.min(2), Validators.pattern("(([\\d\\w])|([a-zA-Z\\w\\d])){2,7}$")]],
    filter_type: [0],
    driver_id: [0],
    carrier_id: [0],
    operation: [0],
    department: [0],
    dock_space: [0],
    sys_user: [0],
    day: [0],
    dt_start: [0],
    dt_end: [0]

  });

  listExceptions: any[] = [];

  listOtProblems: any[] = [];
  listLoadingDenied: any[] = [];
  listUnloadingDenied: any[] = [];
  listLoadingProblems: any[] = [];
  listReceivedWithException: any[] = [];
  listDirectionProblem: any[] = [];
  listInvalidatedDocumentation: any[] = [];

  active_filters: any = [];
  optionsFilters: any = {
    driver_id: null,
    carrier_id: null,
    operation: null,
    sys_user: null,
    dock_space: null,
    day: null,
    dt_start: null,
    dt_end: null,
    department: null,
    boards: null,
    export: false

  };
  searching = false;
  driverFilter: UntypedFormControl = new UntypedFormControl();
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  boardHorseFilter: UntypedFormControl = new UntypedFormControl();
  boardCartFilter: UntypedFormControl = new UntypedFormControl();
  operationFilter: UntypedFormControl = new UntypedFormControl();
  departmentFilter: UntypedFormControl = new UntypedFormControl();
  dockFilter: UntypedFormControl = new UntypedFormControl();
  dtFilter: UntypedFormControl = new UntypedFormControl();
  entryFilter: UntypedFormControl = new UntypedFormControl();
  exitFilter: UntypedFormControl = new UntypedFormControl();
  userFilter: UntypedFormControl = new UntypedFormControl();
  lacreFilter: UntypedFormControl = new UntypedFormControl();
  dayFilter: UntypedFormControl = new UntypedFormControl();
  carriers: any = [];
  drivers: any = [];
  boardHorses: any = [];
  boardCarts: any = [];
  operations: any = [];
  departments: any = [];
  docks: any = [];
  dts: any = [];
  ids: any = [];
  lacres: any = [];
  users: any = [];
  system_client_id = 0;
  user: any = [];
  options: any = [];



  displayedColumns: string[] = [];
  filters: any = [
    { id: 15, name: 'Placas', altName: 'boards' },
    { id: 1, name: 'Motorista', altName: 'driver_id' },
    { id: 2, name: 'Transportadora', altName: 'carrier_id' },
    // { id: 3, name: 'Placa cavalo', altName: 'board_horse' },
    // { id: 4, name: 'Placa carreta', altName: 'board_cart' },
    // { id: 5, name: 'Status', altName: 'status' },
    { id: 6, name: 'Operação', altName: 'operation' },
    { id: 7, name: 'Doca', altName: 'dock_space' },
    // { id: 8, name: 'DT', altName: 'dt' },
    // { id: 9, name: 'Data entrada', altName: 'entry' },
    // { id: 10, name: 'Data saída', altName: 'exit' },
    { id: 11, name: 'Usuário final', altName: 'sys_user' },
    { id: 12, name: 'Dia', altName: 'day' },
    { id: 13, name: 'Período', altName: 'range' },
    { id: 14, name: 'Área', altName: 'department' }

  ];
  dataSource = new MatTableDataSource<any>();
  pageSize: number;
  length: number;
  showListEmpty = false;
  @Output() event: EventEmitter<any> = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:true}) sort: MatSort;

  downloadObserver: Subscription


  constructor(
    public datePipe: DatePipe,
    private events: EventsService,
    public reportService: ReportService,
    private formBuilder: UntypedFormBuilder,
    public carrierService: CarrierService,
    public driverService: DriversService,
    public supportDataService: SupportDataService,
    public userService: UserService
  ) {
    this.user = JSON.parse(localStorage.getItem('account') || "{}");
    this.system_client_id = this.user.system_client.id as number;

    this.supportDataService.generic({ name: 'ot_problem' }).subscribe((r: any) => {
      this.listOtProblems = r.data.resource;
      //console.log('thingy', this.listOtProblems);
    });

    this.supportDataService.generic({ name: 'loading_denied' }).subscribe((r: any) => {
      this.listLoadingDenied = r.data.resource;
    });

    this.supportDataService.generic({ name: 'unloading_denied' }).subscribe((r: any) => {
      this.listUnloadingDenied = r.data.resource;
    });

    this.supportDataService.generic({ name: 'exception_received' }).subscribe((r: any) => {
      this.listReceivedWithException = r.data.resource;
    });

    this.supportDataService.generic({ name: 'loading_problem' }).subscribe((r: any) => {
      this.listLoadingProblems = r.data.resource;
    });

    this.supportDataService.generic({ name: 'direction_problem' }).subscribe((r: any) => {
      this.listDirectionProblem = r.data.resource;
    });

    this.supportDataService.generic({ name: 'invalidated_documentation' }).subscribe((r: any) => {
      this.listInvalidatedDocumentation = r.data.resource;
    });

  }

  ngOnInit() {
    this.displayedColumns = this.user.system_client.resource.environment.pages['report_checkin']['columns'];
    this.filters = this.user.system_client.resource.environment.pages['report_checkin']['data_filters'];

    if (this.user.department_id.length == 1) {
      this.filters = this.filters.filter(
        (item: any) => item.altName != 'department'
      );
    }

    this.optionsFilters.export = false;
    this.loadComponentsObservers();
    this.loadCarrier();
    this.loadDriver();
    this.loadOperations();
    this.initObserversOperationFilter();
    this.loadDocks();
    this.loadUsers();
    this.loadDepartments();
    // console.log(this.listEvents)
    this.downloadObserver = this.events.currentEvent.subscribe((ev: string) => {
      if (ev === 'download_data') {
        this.downloadReport();
      }
    })
  }
  ngOnDestroy() {
    this.downloadObserver?.unsubscribe()
  }

  initObserversOperationFilter(){
        this.operationFilter.valueChanges.pipe(
          startWith(''),
          debounceTime(500),
          switchMap(() => {
            this.searching = true;
            const options: any = {
              orderBy: 'updated_at',
              sortedBy: 'DESC',
              pageSize: 10,
              page: 1,
              search: `${this.operationFilter.value}` || ''
            };
            if(this.form.value.department.id) {
              options.department_id = this.form.value.department.id
            }
            return this.supportDataService.operation(options).pipe(
              catchError(() => {
              this.searching = false;
              return observableOf({data:[]});
            }));
          }),
          map((response: any) => {
            return response.data;
          }),

        ).subscribe(data => {
          this.operations = data.resource;
        });
      }

  //Adiciona o observer apenas uma única vez para que não sejam criados multiplos observers
  loadComponentsObservers(){
    /// Ao entrar na página
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          Object.assign(this.options, {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          });

          if (this.user.department_id.length == 1) {
            this.options.department = this.user.department_id[0];
          }

          const url = this.user.system_client.resource.environment.pages['report_checkin']['api_endpoint'];
          return this.reportService.index(this.options, url).pipe(
            catchError(() => {
              return observableOf({data:[],total:0,per_page:0});
            })
          );
        }),
        tap((response: Response | any) => {
          /* if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          } */
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {

          return response.data;
        })
      ).subscribe(data => {
        this.dataSource.data = data;
        //console.log('some report data', this.dataSource.data);
      });
  }

  loadReport(filter_options?:any) {
    this.paginator.pageIndex = 0;
    this.options = {
      orderBy: this.sort.active,
      sortedBy: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
    };
    if (this.user.department_id.length == 1) {
      this.options = {
        ...this.options,
        department: this.user.department_id[0]
      };
    }
    Object.assign(this.options, filter_options);

    const url = this.user.system_client.resource.environment.pages['report_checkin']['api_endpoint'];

    this.reportService.index(this.options, url)
    .pipe(
      tap((response: Response | any) => {
        if (!response.data.length && this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        }

        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
      }),
      map((response: Response | any) => {

        return response.data;
      }),
      catchError(() => {
        return observableOf({data:[]});
      })
    )
    .subscribe(data => {
      this.dataSource.data = data;
    });
  }

  loadDriver() {
    this.driverFilter.valueChanges.pipe(
      debounceTime(1000),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: 'name',
          sortedBy: 'ASC',
          page: 1,
          pageSize: 10,
          search: `${this.driverFilter.value}` || ''
        };
        return this.driverService.index(options).pipe(
          tap(() => this.searching = false),
          catchError(() => {
            return observableOf({total:0,per_page:0,data:[]});
          })
        );
      }),
      map((response: any) => {
        return response.data;
      })
    ).subscribe(data => {
      this.drivers = data;
    });
    this.driverFilter.setValue(" ", {emitEvent: true});

  }

  loadCarrier() {
    this.carrierFilter.valueChanges.pipe(
      debounceTime(800),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: 'carrier_client.updated_at',
          sortedBy: 'DESC',
          pageSize: 10,
          page: 1,
          search: `${this.carrierFilter.value}` || ''
        };
        return this.carrierService.index(options).pipe(
          tap(() => this.searching = false),
        );
      }),
      map((response: any) => {
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.carriers = data;
    });
    this.carrierFilter.setValue(" ", {emitEvent: true});
  }

  loadOperations(department_id?: number) {
    this.operations = [];
    this.operationFilter.setValue("");
  }

  loadDepartments() {
    this.departmentFilter.valueChanges.pipe(
      debounceTime(800),
      filter(search => search != "" || this.departments.length == 0),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: 'updated_at',
          sortedBy: 'DESC',
          pageSize: 10,
          page: 1,
          search: `${this.departmentFilter.value}` || ''
        };
        return this.supportDataService.departments(options);
      }),
      map((response: any) => {
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.departments = data.resource;
    });
    this.departmentFilter.setValue(" ", {emitEvent: true})

  }

  showExceptions(name:string, ids:string, otherMotives?:string) {

    if (ids && name) {

      switch (name) {

        case 'ot_problem': {
          this.listExceptions = this.listOtProblems;
          break;
        }

        case 'loading_denied': {
          this.listExceptions = this.listLoadingDenied;
          break;
        }

        case 'unloading_denied': {
          this.listExceptions = this.listUnloadingDenied;
          break;
        }

        case 'exception_received': {
          this.listExceptions = this.listReceivedWithException;
          break;
        }

        case 'loading_problem': {
          this.listExceptions = this.listLoadingProblems;
          break;
        }

        case 'direction_problem': {
          this.listExceptions = this.listDirectionProblem;
          break;
        }

        case 'invalidated_documentation': {
          this.listExceptions = this.listInvalidatedDocumentation;
          break;
        }

        default: {
          //statements;
          break;
        }

      }

      if (this.listExceptions) {
        const exception_ids = JSON.parse(ids);
        //console.log('any ids?', exception_ids);
        let exceptionNames:string[] = [];

        //console.log(this.listExceptions);
        this.listExceptions.forEach(item => {
          if (exception_ids.includes(item.value)) {
            exceptionNames.push(item.label);
          }

        });

        if(otherMotives) {

          const otherMotivesArray = JSON.parse(otherMotives);

          otherMotivesArray.forEach((item:string) => {
            exceptionNames.push(item);
          })
        }

        return exceptionNames.join(', ');
      }
    }

    return '';
  }

  loadDocks() {
    this.dockFilter.valueChanges.pipe(
      debounceTime(800),
      filter(search => search != "" || this.docks.length == 0),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: 'updated_at',
          sortedBy: 'DESC',
          pageSize: 10,
          page: 1,
          search: `${this.dockFilter.value}` || ''
        };
        return this.supportDataService.dockSpaces(options);
      }),
      map((response: any) => {
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.docks = data.resource instanceof Array ? data.resource : (data.resource['1']||[]);
    });

    this.dockFilter.setValue(" ", {emitEvent: true})

  }

  loadUsers() {
    this.userFilter.valueChanges.pipe(
      debounceTime(800),
      filter(search => search != "" || this.users.length == 0),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: 'updated_at',
          sortedBy: 'DESC',
          pageSize: 10,
          page: 1,
          search: `${this.userFilter.value}` || ''
        };
        return this.userService.index(options);
      }),
      map((response: any) => {
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.users = data;
    });
    this.userFilter.setValue(" ", {emitEvent: true})
  }

  addFilter() {
    const filter = this.filters.filter((f:any) => f.id === this.form.value.filter_type);
    if(filter['0']['altName'] == "boards" && this.form.get("boards")?.errors) {
      return ;
    }
    if (filter['0']['altName'] == 'sys_user') {
      this.active_filters.push({
        filter: filter[0]['name'],
        value: this.form.value[filter['0']['altName']]['full_name'],
        altName: filter['0']['altName'],
        search: this.form.value[filter['0']['altName']]['full_name']
      });
      this.optionsFilters[filter['0']['altName']] = [this.form.value[filter['0']['altName']]['full_name'], ...(this.optionsFilters[filter['0']['altName']]||"").split("|")].filter(Boolean).join("|");
    } else if (filter['0']['altName'] == 'day') {
      this.active_filters.push({
        filter: filter[0]['name'],
        value: this.datePipe.transform(this.form.value[filter['0']['altName']], 'dd/MM/yyyy'),
        altName: filter['0']['altName'],
        search: this.datePipe.transform(this.form.value[filter['0']['altName']], 'yyyy-MM-dd')
      });
      this.optionsFilters[filter['0']['altName']] = [this.datePipe.transform(this.form.value[filter['0']['altName']], 'yyyy-MM-dd'), ...(this.optionsFilters[filter['0']['altName']]||"").split("|")].filter(Boolean).join("|");
    } else if (filter['0']['altName'] == 'range') {
      // Busca o índice do filtro no array
      const fIndex = this.active_filters.findIndex((item:any) => item.altName == "range");
      // Se o filtro existir, remove
      if (fIndex >= 0) {
        this.active_filters.splice(fIndex, 1);
      }
      const dt_start = this.datePipe.transform(this.form.value.dt_start, 'dd/MM/yyyy');
      const dt_end = this.datePipe.transform(this.form.value.dt_end, 'dd/MM/yyyy');
      this.active_filters.push({
        filter: filter[0]['name'],
        value: `${dt_start} - ${dt_end}`,
        altName: filter['0']['altName']
      });
      this.optionsFilters.dt_start = this.datePipe.transform(this.form.value.dt_start, 'yyyy-MM-dd');
      this.optionsFilters.dt_end = this.datePipe.transform(this.form.value.dt_end, 'yyyy-MM-dd');
    } else if(filter['0']['altName'] == 'operation'){
      this.active_filters.push({
        filter: filter[0]['name'],
        value: [this.form.value.department.name, this.form.value.operation.name].filter(x=>x).join(" - "),
        altName: filter['0']['altName'],
        search:{department:this.form.value.department.id,operation:this.form.value.operation.id}
      });
      this.optionsFilters.operation = [this.form.value.operation.id, ...(this.optionsFilters.operation||"").split("|")].filter(Boolean).join("|");
      this.optionsFilters.department = [this.form.value.department.id, ...(this.optionsFilters.department||"").split("|")].filter(Boolean).join("|");
    }
    else {
      this.active_filters.push({
        filter: filter[0]['name'],
        value: (this.form.value[filter['0']['altName']]['name'] || this.form.value[filter['0']['altName']]),
        altName: filter['0']['altName'],
        search:(this.form.value[filter['0']['altName']]['id']||this.form.value[filter['0']['altName']])
      });
      this.optionsFilters[filter['0']['altName']] = [(this.form.value[filter['0']['altName']]['id'] || this.form.value[filter['0']['altName']]), ...(this.optionsFilters[filter['0']['altName']]||"").split("|")].filter(Boolean).join("|");
    }
    this.loadReport(this.optionsFilters);
  }

  deleteFilter(idx: any, altName: string) {
    const filter = { search: "", ...this.active_filters.splice(idx, 1).shift() };
    if (altName == "range") {
      this.optionsFilters.dt_start = '';
      this.optionsFilters.dt_end = '';

    } else if (altName == "operation") {
      this.optionsFilters.operation = this.optionsFilters.operation.split("|").filter((x: any) => {
        if (!(x && x != filter.search.operation)) {
          filter.search.operation = null;
          return false;
        }
        return true
      }).join("|");
      this.optionsFilters.department = this.optionsFilters.department.split("|").filter((x: any) => {
        if (!(x && x != filter.search.department)) {
          filter.search.department = null;
          return false;
        }
        return true
      }).join("|");
    } else {
      this.optionsFilters[altName] = this.optionsFilters[altName].split("|").filter((x: any) => {
        if (!(x && x != filter.search)) {
          filter.search = null;
          return false;
        }
        return true;
      }).join("|");
    }
    this.loadReport(this.optionsFilters);
  }

  downloadReport(): void {
    if(this.reportChunkStarted)
      return;

    this.reportChunkStarted = true;
    this.optionsFilters.export = true;
    const chunks:any[] = [];
    const url = this.user.system_client.resource.environment.pages['report_checkin']['api_endpoint'];

    //Divide o relatório em 8 requisições de 10000 linhas.
    const reportChunks = (numberOfChunk:number)=>{
      this.reportService.index(Object.assign({chunk:numberOfChunk},this.optionsFilters), url).pipe(
        catchError(() => {
          return observableOf({csv:"",rows:0});
        })
      )
      .subscribe((r: any) => {
        try{
          chunks.push(r.csv || "");
          if(numberOfChunk <= 10 && r.rows >= 10000){
            reportChunks(numberOfChunk + 1);
          }else if(chunks.length > 0){
            this.createFileFromBlob(
              chunks.reduce((textConcat, text, index)=>{
                if(index > 0 && text)
                  return textConcat + text.substring(text.indexOf("\n") + 1)
                return textConcat + text;
              }),
              'report.csv'
            );
            this.optionsFilters.export = false;
            this.reportChunkStarted = false;
          }

        }catch(ex){
          this.optionsFilters.export = false;
          this.reportChunkStarted = false;
        }
      });
    }
    reportChunks(0);
  }

  createFileFromBlob(file:any, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob(["\uFEFF" + file], { type: 'text/csv; charset=utf-18' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}
