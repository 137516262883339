import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { DialogAlertComponent } from "../dialog-alert/dialog-alert.component";

@Component({
  selector: "app-dialog-confirm-valet",
  templateUrl: "./dialog-confirm-valet.component.html",
  styleUrls: ["./dialog-confirm-valet.component.scss"],
})
export class DialogConfirmValetComponent implements OnInit {
  dialogResult: any;
  checkinId = this.data.item.id;
  errors: any = {};

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmValetComponent>,
    private dialog: MatDialog,
    public checkinService: CheckinService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onSubmit() {
    if (!this.data.item.valetMode) {
      this.checkinService.activateValetMode(this.checkinId)
      .subscribe({
        next:(r: any) => {
          if (r.status == 200) {
            this.dialogRef.close(this.dialogResult);
          }
        },
        error:(err: any) => {
          if (err.status === 422) {
            this.dialog.open(DialogAlertComponent, {
              data: { title: "Ops! Tem algo errado", message: Object.values(err.error.errors).map((item:any) => item.join('\n')).join('\n')},
            });
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: { title: "Ops! Tem algo errado", message: "Desculpe, tivemos um erro interno. Se o erro permanecer contate nosso suporte." },
            });
          }
        }
      });
    } else {
      this.checkinService.deactivateValetMode(this.checkinId).subscribe({
        next:(r: any) => {
          if (r.status == 200) {
            this.dialogRef.close(this.dialogResult);
          }
        },
        error:(err: any) => {
          if (err.status === 422) {
            this.dialog.open(DialogAlertComponent, {
              data: { title: "Ops! Tem algo errado", message: Object.values(err.error.errors).map((item:any) => item.join('\n')).join('\n')},
            });
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: { title: "Ops! Tem algo errado", message: "Desculpe, tivemos um erro interno. Se o erro permanecer contate nosso suporte." },
            });
          }
        }
      });
    }
  }
}
