import {Component, OnInit, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, FormControl, Validators} from "@angular/forms";
import {SupportDataService} from "../../shared/support-data.service";
import {DockManagementService} from "../../docks/shared/dock-management.service";

@Component({
  selector: "app-checkin-driver-parking-lot",
  templateUrl: "./checkin-driver-parking-lot.component.html",
  styleUrls: ["./checkin-driver-parking-lot.component.scss"]
})
export class CheckinDriverParkingLotComponent implements OnInit {
  form = this.formBuilder.group({
    directed_to: ["park"]
  });

  form1 = this.formBuilder.group({
    dock_space_id: [this.data.item.resource.dock_space_id],
    department_id: [this.data.item.resource.department_id],
    //operation: [this.data.item.resource.operation], //, [Validators.required]
    external_event_id: [{value: this.checkExternalEventId(this.data.item.event), disabled: true}]
  });

  form2 = this.formBuilder.group({
    dock_space_id: [this.data.item.resource.dock_space_id], //, [Validators.required]
    //operation: [this.data.item.resource.operation], //, [Validators.required]
    department_id: [this.data.item.resource.department_id],
    external_event_id: [{value: this.checkExternalEventId(this.data.item.event), disabled: true}]
  });


  parkingType: any;
  item: any;
  parkingSpaces: any;
  operationType: any;
  parkingSpacesFiltered: any[] = [];
  dockSpacesFiltered: any[] = [];
  dockSpaces: any;
  dialogResult: any;
  departments: any = [];
  user: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public supportData: SupportDataService,
    public dialogRef: MatDialogRef<CheckinDriverParkingLotComponent>,
    private dockManagementService: DockManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const user = JSON.parse(localStorage.getItem('account')!);
    if (user)
      this.user = user;
  }

  ngOnInit() {
    console.log('this.data')
    console.log(this.data)

    if (this.data.item.resource.dock_space_id) {
      this.form.setValue({directed_to: 'dock'});
    }
    if (this.data.item.resource.dfd_space) {
      this.form.setValue({directed_to: 'dfd'});
    }

    this.spaces();
    // this.loadParkingSpaces();
    // this.loadDockSpaces();
    this.loadNewDockSpaces();
    //this.loadOperationType();

    if (this.user.system_client.resource.environment.docks_shared != true || this.user.department_id.length > 1) {
      this.loadDepartments();
    }
  }

  checkExternalEventId(event: any) {
    if (event) {
      return event.resource ? event.resource.external_event_id : null;
    } else {
      return null;
    }

  }

  cancel(): void {
    this.dialogRef.close();
  }

  spaces() {
    this.supportData.parkingType().subscribe(
      (r: any) => {
        this.parkingType = r.data.resource;
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  loadParkingSpaces() {
    this.supportData.parkingSpaces().subscribe(
      (r: any) => {
        this.parkingSpaces = r.data.resource;
        console.log("loadParkingSpaces");
      });
  }

  loadDockSpaces() {
    const options = {
      name: 'dock_space'
    }
    this.supportData.dockSpaces(options).subscribe(
      (r: any) => {
        this.dockSpaces = r.data.resource;
        this.filterDockSpace();
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  loadNewDockSpaces() {
    this.dockManagementService.index().subscribe((r: any) => {
      if (this.user.system_client.resource.environment.docks_shared == true) {
        this.dockSpaces = r.data[1];
        this.filterDockSpace();
        this.filterParkingSpace()

      } else {
        this.dockSpaces = r.data;
        this.filterDockSpace(this.data.item.resource.department_id);
        this.filterParkingSpace(this.data.item.resource.department_id)
      }
    });
  }

  loadDepartments() {
    const options = {
      name: 'departments'
    }
    this.supportData.departments(options).subscribe(
      (r: any) => {
        this.departments = r.data.resource;
        // if (this.user.system_client.resource.environment.docks_shared == true) {
        //   this.filterDockSpace();
        //   this.filterParkingSpace()
        // } else {
        //   this.filterDockSpace(this.data.item.resource.department_id);
        //   this.filterParkingSpace(this.data.item.resource.department_id)
        //
        // }
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  loadOperationType() {
    this.supportData.operationLoreal().subscribe(
      (r: any) => {
        this.operationType = r.data.resource;
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  // loadParking() {
  //   this.filterParkingSpace(this.form.value.parking_space_type);
  // }

  // filterParkingSpace(side: string) {
  //   this.parkingSpacesFiltered = this.parkingSpaces.filter((item: any) => {
  //     return item.side === side && item.busy === false;
  //   });
  // }


  resetForm(form: number) {
    console.log(this.form.value.directed_to);
    if (form === 1) {
      this.form1.reset();
    }
    if (form === 2) {
      this.form2.reset();
    }
  }

  filterDockSpace(department_id?: any) {
    if (this.user.system_client.resource.environment.docks_shared == true) {
      this.dockSpacesFiltered = this.dockSpaces.filter((item: any) => {
        if (item.busy === false && item.maintenance === false) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      if (this.user.department_id.length > 1) {
        if (department_id) {
          this.dockSpacesFiltered = this.dockSpaces[department_id].filter((item: any) => {
            if (item.busy === false && item.maintenance === false)  {
              return true;
            } else {
              return false;
            }
          });
        } else {
          this.dockSpacesFiltered = this.dockSpaces[this.user.department_id].filter((item: any) => {
            if (item.busy === false && item.maintenance === false) {
              return true;
            } else {
              return false;
            }
          });
        }
      } else {
        this.dockSpacesFiltered = this.dockSpaces.filter((item: any) => {
          if (item.busy === false && item.maintenance === false) {
            return true;
          } else {
            return false;
          }
        });
      }
    }
  }

  filterParkingSpace(department_id?: any) {
    if (this.user.system_client.resource.environment.docks_shared == true) {
      this.parkingSpacesFiltered = this.dockSpaces.filter((item: any) => {
        if (item.busy === false && item.maintenance === false && !item.can_block) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      if (this.user.department_id.length > 1) {
        if (department_id) {
          this.parkingSpacesFiltered = this.dockSpaces[department_id].filter((item: any) => {
            if (item.busy === false && item.maintenance === false && !item.can_block) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          this.parkingSpacesFiltered = this.dockSpaces[this.user.department_id].filter((item: any) => {
            if (item.busy === false && item.maintenance === false && !item.can_block) {
              return true;
            } else {
              return false;
            }
          });
        }
      } else {
        this.parkingSpacesFiltered = this.dockSpaces.filter((item: any) => {
          if (item.busy === false && item.maintenance === false && !item.can_block) {
            return true;
          } else {
            return false;
          }
        });
      }
    }
  }

  onSubmit() {
    if (this.form.value.directed_to === "park") {
      this.dialogResult = this.form1.value;
      this.dialogResult.status = "directed_park";
      // this.dialogResult.dock_space_id = this.dockSpacesFiltered.find(
      //   (dock: any) => dock.type === 1
      // )?.id;
    }
    if (this.form.value.directed_to == "dock") {
      this.dialogResult = this.form2.value;
      this.dialogResult.status = "directed_dock";
      this.dialogResult.parking_space_type = "none";
    }

    const dialogResult = JSON.parse(JSON.stringify(this.dialogResult));

    const dock = this.dockSpacesFiltered.find(
      (dock: any) => dock.id == dialogResult.dock_space_id
    );

    dialogResult.busy = true;
    dialogResult.origin_id = 2;
    dialogResult.origin_ref_id = this.data.item.id;
    dialogResult.release_dock_id = this.data.dock_space_id;
    dialogResult.release_ref_id = this.data.item.id;
    dialogResult.can_block = dock.can_block

    // console.log("dialogResult", dialogResult)
    // console.log("dialogResult", dock)

    this.dockManagementService.update(dialogResult, dialogResult.dock_space_id).subscribe((r: any) => {

      delete dialogResult.origin_id;
      delete dialogResult.origin_ref_id;
      delete dialogResult.busy;

      // Propriedades necessárias para passar via check in
      dialogResult.dock_management_active = true;
      dialogResult.id = this.data.item.id;
      dialogResult.status = this.parkingType.find(
        (dockSpace: any) => dockSpace.id == dock.type
      )?.status;
      this.dialogRef.close(dialogResult);
    });

    this.dialogResult.id = this.data.item.id;
    this.dialogResult.prepare_exit = false;
    this.dialogRef.close(this.dialogResult);
  }
}
