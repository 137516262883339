import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { LookerComponent } from './looker-list/looker.component';
import { RouteGuard } from '../account/shared/route.guard';

const LookerRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/looker',
    component: LookerComponent,
    data: {
      title: 'Dashboard',
      search: false,
      refresh: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(LookerRoutes)],
  exports: [RouterModule]
})
export class LookerRoutingModule { }
