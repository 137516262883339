<table
  #sort="matSort"
  mat-table
  [dataSource]="viewTable ? shift.dataSource : []"
  class="mat-elevation-z8"
  style="width: 100%;"
  matSort
  [ngClass]="{'table-head': !viewTable}"
>
  <ng-container [matColumnDef]="column.key" *ngFor="let column of shift.columns;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort" class="aumentar"> {{column.name}} </th>
    <td mat-cell *matCellDef="let element" class="aumentar">
      {{
        element[column.key] | conversion:column
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" >
    <th mat-header-cell *matHeaderCellDef class="aumentar" style="width: 200px;">Ações</th>
    <td mat-cell *matCellDef="let element" style="white-space: nowrap; width: 200px;">
      <button mat-icon-button matTooltip="Abrir" (click)="open(actionEnum.EDIT, element)">
        <mat-icon>folder_open</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Deletar" (click)="destroy(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr class="mat-row" *matNoDataRow>
    <td [attr.colspan]="shift.columns.length + 1" style="background: #fafafa;">
      <div class="grid grid-3-columns">
        <mat-card *ngFor="let element of shift.dataSource">
          <mat-card-header>
            <mat-card-title>Turno</mat-card-title>
            <mat-card-subtitle>{{element.name}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div>
              Criado em:
            </div>
            <div>
              {{(element.created_at | conversion:shift.columns[1])}}
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button class="button" color="warn" mat-button (click)="destroy(element)">Excluir</button>
            <button class="button" color="primary" mat-button  (click)="open(actionEnum.EDIT, element)">Editar</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </td>
  </tr>

  <tr mat-header-row *matHeaderRowDef="shift.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns:shift.displayedColumns"></tr>

</table>

<mat-paginator #paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>

<div class="mat-fab-bottom-right add-edit-button">
  <button
    mat-mini-fab
    (click)="open(actionEnum.ADD)"
    color="primary"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
