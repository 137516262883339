import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ReportDynamicService {
  constructor(private http: HttpClient) {}

  report(report: string = "", options: any = {}): Observable<any> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );
    const headers = { "content-type": "application/json" };

    const body = httpParams;

    const url = `${environment.apiUrl}/report-dynamic/${report}`;

    return this.http
      .post<any>(url, JSON.stringify(body), { headers: headers })
      .pipe(
        map((response: Response | any) => response || {}),
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  download(report: string = "", options: any = {}): Observable<Blob> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const body = httpParams;

    const url = `${environment.apiUrl}/report-dynamic/${report}`;

    return this.http
      .post<Blob>(url, JSON.stringify(body), {
        headers: headers,
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: Response | any) => response || {}),
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  downloadXlsx(report: string = "", options: any = {}): Observable<Blob> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const body = httpParams;

    const url = `${environment.apiUrl}/report-dynamic/${report}`;

    return this.http
      .post<Blob>(url, JSON.stringify(body), {
        headers: headers,
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: Response | any) => response || {}),
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  downloadCsv(report: string = "", options: any = {}): Observable<any> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );
    const headers = { "content-type": "application/json" };

    const body = httpParams;

    const url = `${environment.apiUrl}/report-dynamic/${report}`;

    return this.http
      .post<any>(url, JSON.stringify(body), { headers: headers })
      .pipe(
        map((response: Response | any) => response || {}),
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  reportGeneric(
    name: string,
    options: Record<string, string | string[]> = {},
  ): Observable<Blob> {
    const url = `${environment.apiUrl}/report-generic/${name}`;
    const params = new HttpParams({ fromObject: options });

    return this.http
      .get<Blob>(url, { params, responseType: "blob" as "json" })
      .pipe(
        map((response: Response | any) => response || {}),
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  reportv2(reportName: string = "", options: any = {}) {
    const url = `${environment.apiUrl}/report/${reportName}`;
    const params = new HttpParams({ fromObject: options });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  reportLogs() {
    const url = `${environment.apiUrl}/report-logs`;

    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }
}
