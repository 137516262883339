<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <mat-radio-group [(ngModel)]="selectedOption">
    <mat-radio-button value="1">Apagar este evento</mat-radio-button>
    <mat-radio-button value="2">Apagar este evento e seguintes</mat-radio-button>
  </mat-radio-group>  
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" mat-dialog-close>{{data.btnCancel ? data.btnCancel : "Fechar"}}</button>
    <button mat-raised-button style="color: white;" color="primary" (click)="onConfirm()">{{data.btnConfirm ? data.btnConfirm : "Ok"}}</button>
  </mat-dialog-actions>