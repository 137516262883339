import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs';
import { PainelMotorista } from '../painelmotorista.interface';



@Injectable({
  providedIn: 'root'
})
export class PainelMotoristaService {
  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<PainelMotorista> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams})

    const url = `${environment.apiUrl}/waits-painelmotoristas`;

    return this.http.get<PainelMotorista>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  indexDinamico(options?: any): Observable<PainelMotorista> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({fromObject: httpParams});

    const account:any = JSON.parse(localStorage.getItem("account") || '{}');

    //Condicional para Jandira Engate/Desengate
    if (account.system_client?.resource?.environment?.trip_hitch === true) {
      const url = `${environment.apiUrl}/waits-painelmotoristas-dinamico-trucker`;

      return this.http.get<PainelMotorista>(url, { params }).pipe(
        map((response: Response | any) => response || {}),
        catchError(err => throwError(() => err || new Error('Server error')))
      );

    } else {
      const url = `${environment.apiUrl}/waits-painelmotoristas-dinamico`;

      return this.http.get<PainelMotorista>(url, { params }).pipe(
        map((response: Response | any) => response || {}),
        catchError(err => throwError(() => err || new Error('Server error')))
      );
    }

  }

  //Engate/Desengate
  //Função de checkout de motorista pelo botão no painel de motorista
  truckerCheckout(data: any) {
    const url = `${environment.apiUrl}/waits-painelmotoristas-trucker-checkout`;

    return this.http.put<PainelMotorista>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );

  }

   getDriverDashboardUpdates() {
    // return this.db.list('driver-dashboard').snapshotChanges();
  }

  getMapsUpdates(fbKey: string ) {
    // return this.db.list(`checkin/${fbKey}/maps`).snapshotChanges();
  }

  getLastLocation(checkin_id: any){

    const url = `${environment.apiUrl}/last-location-checkin/${checkin_id}`;

    return this.http.get<PainelMotorista>(url, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getTruckerLastLocation(trucker_checkin_id: any){

    const url = `${environment.apiUrl}/last-location-trucker-checkin/${trucker_checkin_id}`;

    return this.http.get<PainelMotorista>(url, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

}
