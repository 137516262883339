<!--Card Dashboard - Inicio desenvolvimento 23/05/2022-->
<mat-card class="card">
  <mat-card-header>
    <!--Title-->
    <mat-card-title class="title">{{data.title}}</mat-card-title>
    <button class="icon-scale" *ngIf="infoHtml" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Dash info">
      <mat-icon>info_outline</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="info-container" [innerHtml]="infoHtml"></div>
    </mat-menu>
  </mat-card-header>
  <mat-divider inset></mat-divider>
  <!--Content/Body-->
    <mat-card-content class="mat-card-content">
      <span>{{value}}</span>
    </mat-card-content>
</mat-card>
