import { ScheduleEditDialogV1Component } from "./../schedule-edit-dialog-v1/schedule-edit-dialog-v1.component";
import { ScheduleDinamicFormDialogV1Component } from "./../schedule-dinamic-form-dialog-v1/schedule-dinamic-form-dialog-v1.component";
import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { SupportDataService } from "src/app/shared/support-data.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AgendamentoService } from "./../../multiagendamento/shared/agendamento.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { EmailReportDialogV1Component } from "../../report-dynamic/email-report-dialog-v1/email-report-dialog-v1.component";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
import { UploadDialogComponent } from "src/app/events/upload-dialog/upload-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { UploadMassSchedulingComponent } from "src/app/events/upload-mass-scheduling/upload-mass-scheduling.component";
import { DinFiltersDialogComponent } from "src/app/shared/din-filters-dialog/din-filters-dialog.component";
import { compareAsc, format } from "date-fns";
import { WSService2 } from "src/app/shared/websocket/wss2.service";


@Component({
  selector: "app-schedule-list-v1",
  templateUrl: "./schedule-list-v1.component.html",
  styleUrls: ["./schedule-list-v1.component.scss"],
})
export class ScheduleListV1Component implements OnInit {
  @ViewChild("searchInput") searchInput!: ElementRef;
  dialogOpened: boolean = false;
  title = "ng-calendar-demo";
  selectedDate = new Date();
  startAt = new Date();
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  year: any;
  DayAndDate!: string;
  schedules: any = [];
  showLoader = false;
  allSuboperations: any = [];
  suboperations: any = [];
  open: BehaviorSubject<boolean> = new BehaviorSubject(false);
  visibleFilters: any = [];
  getData: string = "";
  timeSlots: any[] = [];
  tzOffset!: string;
  form = this.formBuilder.group({
    day: [format(new Date(), "yyyy-MM-dd"), [Validators.required]],
    time: null,
    search: null,
    department_id: [],
    origin: [],
    operation_id: null,
    suboperation_id: null,
    status_id: [],
    offset: this.getTimeZone(),
  });
  isSearching!: boolean;
  operations: any = [];
  origins: any = [];
  status: any = [];
  departments: any = [];
  allData: any;
  scheduleMenu: any = [];
  scheduleColumns: any = [];
  selectedDay!: string;
  showOperation = false;
  showSubOperation = false;
  showAddButton = true;
  denyScheculePreviousDay: boolean;
  account: any = {};
  filterList: any[] = [];

  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private translate: TranslateService,
    private wssService: WSService2
  ) {
    this.account = JSON.parse(localStorage.getItem("account") ?? "{}");
    this.onSelect(this.selectedDate);
  }

  ngOnInit() {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "visible") {
          if (this.wssService.socket$.closed.valueOf()) {
            this.wssService.closeConnection();
          } else {
            this.subscribeWebsocket();
          }
        }
      },
      false,
    );
    this.subscribeWebsocket();
    this.getVisibleFilters();
    this.getTimeSlots();
    this.getScheduleData();

    console.log(
      this.account.system_client.resource.environment
        .deny_scheduling_on_previous_days,
    );

    // Escuta o input de busca
    this.form.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.getScheduleData();
      });
  }
  subscribeWebsocket() {
    // console.log("subscribeWebSocket");
    let id = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wssService.CHANNEL = `/schedule/${id}`;
    this.wssService.connect().subscribe((msg: any) => {   
    if(!this.dialogOpened){
      this.getScheduleData();
    }
    
    });
  }

  onSelect(event: any) {
    this.selectedDate = event;
    this.selectedDay = `${format(new Date(event), "yyyy-MM-dd")}T00:00:00`;
    this.form.patchValue({ day: format(new Date(event), "yyyy-MM-dd") });
    this.checkAddButton();
  }

  checkAddButton() {
    if (
      this.account.system_client.resource.environment
        .deny_scheduling_on_previous_days === true
    ) {
      //verifica se o dia é menor que o atual
      let today = new Date();
      let selectedDay = new Date(this.selectedDay);

      today.setHours(0, 0, 0, 0);
      selectedDay.setHours(0, 0, 0, 0);

      if (compareAsc(today, selectedDay) > 0) {
        this.showAddButton = false;
      } else {
        this.showAddButton = true;
      }
    }
  }

  getVisibleFilters() {
    this.supportDataService
      .generic({ name: "scheduling_filters" })
      .subscribe((r: any) => {
        this.visibleFilters = r.data.resource;
        this.getDataFilters();
      });
  }

  getDataFilters() {
    this.supportDataService
      .generic({ name: "scheduling_data_filters" })
      .subscribe((r: any) => {
        this.getData = r.data.resource.join(",");
        this.getSupportDataMulti();
      });
  }

  getSupportDataMulti() {
    this.supportDataService
      .multi({ show: this.getData })
      .subscribe((r: any) => {
        this.allData = r.data;
        this.scheduleColumns = this.allData.scheduling_columns;
        this.scheduleMenu = this.allData.scheduling_menu;
        this.departments = this.allData.departments;
        this.origins = this.allData.origin;
        this.operations = this.allData.operation_type;

        //Verifica se suboperation_type existe antes de atribuir
        if (
          this.getData.includes("suboperation_type") &&
          this.allData.suboperation_type
        ) {
          this.allSuboperations = this.allData.suboperation_type;
        }

        //Tratamento de departamentos para que o usuário somente tenha como opção
        //Departamentos cadastrados em seu array department_id em user_users
        //No caso de um único departamento o campo é desabilitado e a API utiliza os departamentos
        //Presentes no department_id de user_users
        this.departments = this.departments.filter((item: any) => {
          return this.account.department_id.indexOf(item.id) > -1;
        });

        //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true
        this.operations = this.operations.filter(
          (item: any) => item.use_in_scheduling === true,
        );
        this.status = this.allData.scheduling_status;

        //Se possuir mais de um departamento, desativa o campo de filtro operações até que seja informado um departamento
        if (this.departments.length === 1) {
          this.form.controls["department_id"].disable({ emitEvent: false });
          this.form.controls["operation_id"].enable({ emitEvent: false });
          this.showOperation = true;
        } else {
          this.form.controls["operation_id"].disable({ emitEvent: false });
          this.showOperation = false;
          this.form.controls["suboperation_id"].disable({ emitEvent: false });
          this.showSubOperation = false;
        }
      });
  }

  //Direciona a ação clicada para uma função utilizando como parametro a propriedade 'action' do schedule_menu em support_data
  actions(actionName: string) {
    const actionFunction: any = this[actionName as keyof typeof this];
    return actionFunction.call(this);
  }

  new_scheduling() {
    this.open = new BehaviorSubject(false);
    this.dialogOpened = true;
    const dialogRef = this.dialog.open(ScheduleDinamicFormDialogV1Component, {
      width: "900px",
      data: {
        dialogAction: this.translate.instant("Business_model.new_schedule", {
          Default: "Novo Agendamento",
        }),
        slot: null,
        schedule: {
          id: null,
          selected_day: this.selectedDay,
          status: 1,
          schedule_type: "schedule",
          slot_type: 'time',
        },
        //Criar e editar utilizam o mesmo modal, então para a criação 'isEditingAllowed' é marcado como true para que todos os campos estejam habilitados
        isEditingAllowed: true
      },
    });
    dialogRef.afterClosed().subscribe((statusResult?: any) => {
      this.dialogOpened = false;
      //Se status for new_code_generated, abrir modal informando o usuário
      //Que o agendamento foi criado com um código diferente do informado
      if (statusResult && statusResult.status == "new_code_generated") {
        this.dialog
          .open(DialogAlertComponent, {
            data: {
              title: this.translate.instant("Business_model.created_schedule", {
                Default: "Agendamento criado",
              }),
              message:
                statusResult.message ||
                this.translate.instant("Messages.created_schedule", {
                  Default: "Agendamento criado, código alterado.",
                }),
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getScheduleData();
          });
      } else {
        this.getScheduleData();
      }
    });
  }

  uploadDialog() {
    this.open = new BehaviorSubject(false);
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: "900px",
      data: {
        fileType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getScheduleData();
    });
  }

  uploadMassSchedulingDialog() {
    this.open = new BehaviorSubject(false);
    const dialog = UploadMassSchedulingComponent;
    const dialogRef = this.dialog.open(dialog, {
      width: "900px",
      data: {},
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getScheduleData();
    });
  }

  edit_scheduling(schedule: any) {
    const status = schedule.status;
    this.open = new BehaviorSubject(false);
    const dialogRef = this.dialog.open(ScheduleEditDialogV1Component, {
      width: "600px",
      panelClass: "schedule-dialog-edit-v1",
      autoFocus: false,
      data: {
        dialogAction: this.translate.instant("Business_model.edit_status", {
          Default: "Editar Status",
        }),
        slot: {},
        schedule: schedule,
      },
    });

    dialogRef.afterClosed().subscribe((statusResult?: any) => {
      if (statusResult && statusResult.action == "edit") {

        this.dialogOpened = true;
        this.dialog
          .open(ScheduleDinamicFormDialogV1Component, {
            width: "900px",
            data: {
              dialogAction: statusResult.isEditingAllowed
                ? this.translate.instant("Business_model.edit_schedule", {
                    Default: "Editar Agendamento",
                  })
                : this.translate.instant("Business_model.view_schedule", {
                    Default: "Visualizar Agendamento",
                  }),
              slot: {},
              schedule: statusResult.schedule,
              //Valor que define se é permitido editar um agendamento que não fez o checkin ainda
              isEditingAllowed: statusResult.isEditingAllowed,
            },
          })
          .afterClosed()
          .subscribe((editResult?: any) => {
            this.dialogOpened = false;
            if (editResult?.message || status != schedule.status) {
              //Se status for new_code_generated, abrir modal informando o usuário
              //Que o agendamento foi editado com um código diferente do informado
              if (
                editResult.status &&
                editResult.status == "new_code_generated"
              ) {
                this.dialog
                  .open(DialogAlertComponent, {
                    data: {
                      title: this.translate.instant(
                        "Business_model.editing_schedule",
                        { Default: "Agendamento edição" },
                      ),
                      message:
                        editResult.message ||
                        this.translate.instant("Messages.edited_schedule", {
                          Default: "Agendamento editado, código alterado",
                        }),
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.getScheduleData();
                  });
              } else {
                this.dialog
                  .open(DialogAlertComponent, {
                    data: {
                      title: this.translate.instant(
                        "Business_model.editing_schedule",
                        { Default: "Agendamento edição" },
                      ),
                      message:
                        editResult?.message ||
                        this.translate.instant("Messages.edit_status", {
                          Default: "Status alterado com sucesso!",
                        }),
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.getScheduleData();
                  });
              }
            }
          });
      } else if (statusResult && statusResult.action == "refresh") {
        this.getScheduleData();
      }
    });
  }

  mass_upload() {
    this.open = new BehaviorSubject(false);
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  }

  // Filtra o resultado da API de agendamento por dia.
  getScheduleData() {
    this.showLoader = true;
    this.schedules = [];
    const filters = { ...this.form.value };
    // const filters = this.getSelectedFilters();
    const options = {
      ...filters,
      day: this.form.value.day,
      offset: this.form.value.offset,
    };
    if (this.form.value.search != null) {
      options.search = this.form.value.search;
    }
    this.agendamentoService.newScheduleIndex(options).subscribe({
      next: (r: any) => {
        this.formatScheduleData(r);
        this.showLoader = false;
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  getTimeSlots() {
    const options = {
      department_id: this.form.value.department_id,
      operation_id: this.form.value.operation_id,
      suboperation_id: this.form.value.suboperation_id,
      day: this.form.value.day,
      ofsset: this.form.value.offset,
    };
    this.agendamentoService.multi_time_slots(options).subscribe({
      next: (r: any) => {
        this.timeSlots = r;
      },
      error: (error) => {
        console.log("error", error);
      },
    });
  }

  formatScheduleData(data: any) {
    let scArray: any[] = [];
    data.forEach((item: any, idx: number) => {
      if (idx === 0) {
        let o = {
          time_slot: item.time_slot,
          rows: [item],
        };
        scArray.push(o);
      } else {
        let index = scArray.findIndex((i) => i.time_slot === item.time_slot);
        if (index > -1) {
          scArray[index].rows.push(item);
        }
        if (index === -1) {
          let o = {
            time_slot: item.time_slot,
            rows: [item],
          };
          scArray.push(o);
        }
      }
    });

    scArray.sort((a, b) => a.time_slot.localeCompare(b.time_slot));

    this.schedules = scArray;
  }

  setFilterDepartment(department_id: number) {
    this.setOperations(department_id);
    this.form.patchValue({ operation_id: null, suboperation_id: null });
    this.showSubOperation = false;
  }

  setFilterOperation(operation_id: number) {
    if (operation_id) {
      this.form.patchValue({ suboperation_id: null });
      this.setSuboperations(operation_id);
    }
  }

  //Abre o novo modal que gera e envia relatórios por e-mail
  generate_report() {
    this.open = new BehaviorSubject(false);
    const dialogRef = this.dialog.open(EmailReportDialogV1Component, {
      width: "900px",
      data: {
        type: "scheduling",
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.status == 200) {
          this.dialog.open(DialogAlertComponent, {
            panelClass: ["dialog-medium"],
            data: {
              dialog_type: "report_email_sent",
              message: result.message || "Relatório enviado por e-mail",
            },
          });
        } else if (result.status == 422) {
          this.dialog.open(DialogAlertComponent, {
            data: {
              title: "Erro",
              dialog_type: "error",
              message: result.message || "Erro ao criar o relatório",
            },
          });
        }
      }
    });
  }

  setOperations(department_id: number) {
    this.operations = this.allData.operation_type;
    //Filtra as operações e mostra apenas as operações do departamento informado e que possuem campo use_in_scheduling como true
    this.operations = this.operations.filter(
      (item: any) =>
        item.department_id === department_id && item.use_in_scheduling === true,
    );

    if (this.operations.length > 0) {
      this.form.controls["operation_id"].enable({ emitEvent: false });
      this.showOperation = true;
    } else {
      this.form.controls["operation_id"].disable({ emitEvent: false });
      this.showOperation = false;
    }
  }

  setSuboperations(operation_id: number) {
    this.suboperations = this.allSuboperations.filter(
      (item: any) => item.operation_id === operation_id,
    );

    if (this.suboperations.length > 0) {
      this.form.controls["suboperation_id"].enable({ emitEvent: false });
      this.showSubOperation = true;
    } else {
      this.form.controls["suboperation_id"].disable({ emitEvent: false });
      this.showSubOperation = false;
    }
  }

  cleanFilter() {
    this.form.patchValue({
      time: null,
      status_id: null,
      operation_id: null,
      origin: null,
      suboperation_id: null,
      department_id: null,
      search: null,
    });

    //Ao limpar o filtro, se possuir mais de um departamento desativa o campo de Operações novamente
    if (this.departments && this.departments.length > 1) {
      this.form.controls["operation_id"].disable({ emitEvent: false });
      this.showOperation = false;
      this.form.controls["suboperation_id"].disable({ emitEvent: false });
      this.showSubOperation = false;
    }
  }

  updateFilterList($event: any) {
    console.log("updateFilterList");
    console.log($event);
    this.filterList = $event;
    this.getScheduleData();
  }

  removeFilterItem(idx: number) {
    this.filterList.splice(idx, 1);
    this.getScheduleData();
  }

  removeAllFilters() {
    this.filterList = [];
    this.form.patchValue({ search: null });
    this.getScheduleData();
  }

  getSelectedFilters(): any {
    const filters: any[] = [];
    if (this.filterList.length) {
      this.filterList.map((item: any) => {
        const ft: any = {};
        ft[item.ref_name] = item.ref_id;
        filters.push(ft);
      });
      const filtersO: any = {};
      filters.forEach((item: any) => {
        const key = Object.keys(item)[0];
        filtersO[key] = item[key];
      });
      return filtersO;
    } else {
      return {};
    }
  }
}
