import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DinFiltersDialogComponent } from "../din-filters-dialog/din-filters-dialog.component";

@Component({
  selector: "app-web-btn-filters",
  templateUrl: "./web-btn-filters.component.html",
  styleUrls: ["./web-btn-filters.component.scss"],
})
export class WebBtnFiltersComponent {
  @Input() filterList: any = {};
  @Input() origin: any = {};

  @Output() updateFilterList: EventEmitter<any> = new EventEmitter();
  filterTitle = "filter_none";

  constructor(private dialog: MatDialog) {
    if (this.filterList.length > 0) {
      this.filterTitle = `filter_${this.filterList.length}`;
    } else {
      this.filterTitle = "filter_none";
    }
  }

  openDinFilters() {
    const dialogRef = this.dialog.open(DinFiltersDialogComponent, {
      panelClass: ["dialog-medium"],
      data: {
        origin: this.origin,
        filters: JSON.parse(JSON.stringify(this.filterList)),
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("after close");
      console.log(result);
      if (result.length > 0) {
        this.filterTitle = `filter_${result.length}`;
      } else {
        this.filterTitle = "filter_none";
      }
      this.updateFilterList.emit(result);
    });
  }
}
