import { UserService } from "./../../../user/shared/user.service";
import { ScheduleLimitsDialogComponent } from "./../schedule-limits-dialog/schedule-limits-dialog.component";
import { format, parse } from "date-fns";
import { DialogConfirmComponent } from "../../../shared/dialog-confirm/dialog-confirm.component";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ScheduleLimitDinamicDialogComponent } from "../schedule-limit-dinamic-dialog/schedule-limit-dinamic-dialog.component";
import { SupportDataService } from "src/app/shared/support-data.service";
@Component({
  selector: "app-schedule-limits",
  templateUrl: "./schedule-limits.component.html",
  styleUrls: ["./schedule-limits.component.scss"],
})
export class ScheduleLimitsComponent implements OnInit {
  limits: any[] = [];
  groups: any[] = [];
  vehicletypes: any[] = [];
  useDinamiForm: boolean = false;
  columns: any[] = [
    {
      key: "department",
      label: "Departamento",
    },
    {
      key: "operation",
      label: "Operação",
    },
    {
      key: "suboperation",
      label: "Suboperação",
    },
    {
      key: "limit",
      label: "Limite",
    },
    {
      key: "groups",
      label: "Grupo",
      transform_method: "showGroupName",
    },
  ];

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    public supportDataService: SupportDataService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const account = JSON.parse(localStorage.getItem("account") || "{}");
    this.useDinamiForm =
      account.system_client.resource.environment.schedule_limit_dinamic;

    const settings =
      account.system_client.resource.environment.settings_page || null;
    if (settings) {
      this.columns = settings.scheduling_limit.columns || this.columns;
    }

    this.getGroups();
  }

  addEdit(item?: any) {
    const dialogRef = this.useDinamiForm
      ? this.dialog.open(ScheduleLimitDinamicDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            ...item,
            groups: item && item.groups ? JSON.parse(item.groups) : [],
          },
        })
      : this.dialog.open(ScheduleLimitsDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: { ...item },
        });

    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleLimits();
    });
  }

  getScheduleLimits() {
    this.agendamentoService.indexSchedulingLimit().subscribe((r: any) => {
      this.limits = r.data;
    });
  }

  getGroups() {
    const options = { pageSize: 100 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
      this.getScheduleLimits();
    });
  }

  showGroupName(ids: string) {
    if(ids) {
      const group_ids = JSON.parse(ids);
      let groupNames: any[] = [];
      this.groups.forEach(item => {
        if(group_ids.includes(item.id)) {
          groupNames.push(item.name);
        }
      });
      return groupNames.join(', ');
    } else {
      return '';
    }
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "ATENÇÃO!",
        message: `Tem certeza que deseja APAGAR o limite de agendamento?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.agendamentoService
          .deleteSchedulingLimit(id)
          .subscribe((r: any) => {
            if (!r.error) {
              this.getScheduleLimits();
            }
          });
      }
    });
  }
}
