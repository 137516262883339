import { Component, OnInit, Inject } from "@angular/core";
import { CartService } from "../../cart/shared/cart.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VehicleSearchDialogComponent } from "../vehicle-search-dialog/vehicle-search-dialog.component";

@Component({
  selector: "app-link-vehicle-dialog",
  templateUrl: "./link-vehicle-dialog.component.html",
  styleUrls: ["./link-vehicle-dialog.component.scss"],
})
export class LinkVehicleDialogComponent implements OnInit {
  checkins: any = [];
  teste: boolean = true;
  vehicles: any = [];
  vehicle_type!: string;
  vehicle_type_name!: string;
  to_be_linked!: string;
  form = this.formBuilder.group({
    checkin_id: ["", Validators.required],
    board_horse: ["", Validators.required],
    board_cart: ["", Validators.required],
  });
  constructor(
    public dialogRef: MatDialogRef<LinkVehicleDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public cartService: CartService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.vehicle_type = this.data.item.resource.vehicle_type;
    this.getUnlinkedVehicles(this.switchVehicleType(this.vehicle_type));
    this.getValets();
    console.log("this data", this.data);
  }

  onSelect(event: any) {
    let target = event.source.selected._element.nativeElement;
    let selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };
    console.log(selectedData);
  }

  switchVehicleType(type: any) {
    if (type === "horse") {
      this.form.patchValue({ board_horse: this.data.item.resource.board });
      this.vehicle_type_name = "cavalo";
      this.to_be_linked = "cart";
      return this.to_be_linked;
    } else {
      this.form.patchValue({ board_cart: this.data.item.resource.board });
      this.vehicle_type_name = "carreta";
      this.to_be_linked = "horse";
      return this.to_be_linked;
    }
  }

  getUnlinkedVehicles(type: any) {
    const options = {
      carrier_id: this.data.item.carrier.id,
      vehicle_type: type,
    };
    this.cartService.unlinked(options).subscribe((r) => {
      this.vehicles = r.data;
    });
  }

  getValets() {
    console.log("getValets", this.data.item);
    const options = {
      carrier_id: this.data.item.carrier.id,
    };
    this.cartService.valetOnCheckin(options).subscribe((r) => {
      this.checkins = r.data;
    });
  }

  selectDriver() {
    console.log(this.form.value.checkin_id);
    console.log(this.vehicle_type);
    if (this.vehicle_type === "horse") {
      console.log("Pega o cavalo e troca a carreta");
      this.form.patchValue({
        board_cart: this.form.value.checkin_id.resource.board_cart,
      });
    } else {
      console.log("Pega a carreta e troca o cavalo");
      this.form.patchValue({
        board_horse: this.form.value.checkin_id.resource.board_horse,
      });
    }
  }
  cartSearchDialog(type: any) {
    console.log("cart search");
    const dialogRef = this.dialog.open(VehicleSearchDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        type: type,
        carrier_id: this.data.item.carrier.id,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if(data){
        if (type == "Cavalo") {
          this.form.patchValue({ board_horse: data });
        } else if (type == "Carreta") {
          this.form.patchValue({ board_cart: data });
        }
      }
    });
  }

  onSubmit() {
    const data = {
      checkin_id: this.form.value.checkin_id.id,
      resource: {
        board_horse: this.form.value.board_horse,
        board_cart: this.form.value.board_cart
        // , operation: this.data.item.checkin.resource.operation,
      },
    };
    console.log("onSubmit data", data);
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close("");
  }
}
