<div class="tool-bar  mat-elevation-z2">
  <div class="tb-container">
    <form [formGroup]="form">
      <div class="filters-bar">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Dia</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="day"
            (dateChange)="getScheduleData()"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon
            >keyboard_arrow_down</mat-icon
            >
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div [ngClass]="showDepartmentsSelec">
        <mat-form-field appearance="outline">
          <mat-label>Área</mat-label>
          <mat-select formControlName="department_id">
            <mat-option
              (click)="setFilterDepartment(null)"
              value="undefined">TODAS</mat-option
            >
            <mat-divider></mat-divider>
            <mat-option
              *ngFor="let department of departments"
              [value]="department.id"
              (click)="setFilterDepartment(department.id)"
            >
              {{ department.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Operação</mat-label>
          <mat-select formControlName="operation_id">
            <mat-option
              (click)="setFilterOperation(null)"
              value="undefined">TODAS</mat-option
            >
            <mat-divider></mat-divider>
            <mat-option
              *ngFor="let operation of operations"
              [value]="operation.id"
              (click)="setFilterOperation(operation.id)"
            >
              {{ operation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!account.system_client.resource.environment.hidden_elements?.scheduling_cell?.suboperation_filter === true">
        <mat-form-field appearance="outline">
          <mat-label>Suboperação</mat-label>
          <mat-select formControlName="suboperation_id">
            <mat-option
              (click)="setFilterSuboperation()"
              value="undefined">TODAS</mat-option
            >
            <mat-divider></mat-divider>
            <mat-option
              *ngFor="let suboperation of suboperations"
              [value]="suboperation.id"
              (click)="setFilterSuboperation()"
            >
              {{ suboperation.name }}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status_id">
              <mat-option
                (click)="setFilterStatus()"
                value="undefined">TODOS</mat-option
              >
              <mat-divider></mat-divider>
              <mat-option (click)="setFilterStatus()" value="approved">Aprovado</mat-option>
              <mat-option (click)="setFilterStatus()" value="reproved">Reprovado</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <div class="sche-btn-row">
            <button mat-icon-button class="sche-btn" *ngIf="this.account.system_client.resource.environment.scheduling_lot == true" [matMenuTriggerFor]="menu">
              <mat-icon aria-label="Actions">launch</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="uploadDialog()">
                <mat-icon>file_upload</mat-icon>Agendamento em Massa</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </form>
  </div>


</div>
<div class="time-control">
  <div class="go2slot btn-go2slot-active" [ngClass]="{'btn-go2slot-active': go2slotActive === idx}" *ngFor="let column of timeSlots; let idx=index" (click)="go2Anchor(idx)">{{column.slot}}</div>
</div>
<div class="schedule-container mat-elevation-z4" #schedule>

  <table class="tkg-table">
    <thead>
      <tr>
        <th *ngFor="let column of timeSlots; let idx=index">
          <div class="tkg-header-div" (click)="addSchedule(column)">
            <div class="tkg-slot-header" [id]="'slot_' + idx">
              <span [matBadge]="amountOfScheduleInSlot(column.slot) ? amountOfScheduleInSlot(column.slot) : 0" matBadgeOverlap="false" matBadgeSize="medium">{{ column.slot }}</span>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rows of dataGrid; let idx = index">
        <td *ngFor="let cells of rows">
          <app-cell-template
          *ngIf="cells.data_ctrl"
          [cellData]="cells"
          [cellTemplate]="cellTemplate"
          (click)="editShedule(cells)"
          ></app-cell-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
