<!-- TODO: Resolver Busca de Lista de Grupos -->
<!-- <div class="top-bar-filters">
  <div>
    <div>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" (keyup)="getSearch($event)">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div> -->

<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="actions" first>
      <th mat-header-cell *matHeaderCellDef>{{ "Business_model.actions" | translate:{Default: 'Ações'} }}</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="addEdit('EDIT', element)">{{ 'Business_model.open' | translate:{Default: 'Editar'} }}</button>
          <button mat-menu-item (click)="disconnect(element)">{{ 'Business_model.disconnet' | translate:{Default: 'Desconectar'} }}</button>
          <button mat-menu-item (click)="delete($event, element)">{{ 'Business_model.delete' | translate:{Default: 'Apagar'} }}</button>
        </mat-menu>
      </td>
    </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Business_model.name" | translate:{Default: 'Nome'} }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="ellipsis">{{element.name}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Business_model.created_in" | translate:{Default: 'Criado em'} }}</th>
        <td mat-cell *matCellDef="let element">{{element.created_at | date}}</td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<button mat-mini-fab (click)="addEdit('ADD')" class="mat-fab-bottom-right" color="primary"
  *ngIf="account?.is_superuser">
  <mat-icon>add</mat-icon>
</button>
