<form [formGroup]="form">
  <h2 mat-dialog-title>Novo agendamento</h2>

  <mat-dialog-content>
    <div
      class="container">
      <div class="item item-1">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form"
        ></formly-form>
      </div>

      <div class="item item-2">
        <div style="text-align: center">
          <mat-card class="list-upload">
            <mat-card-actions *ngIf="!data.event">
              <button
                mat-button
                color="primary"
                style="text-align: right"
                (click)="onClick()"
              >
                Faça aqui o upload na documentação
                <mat-icon>backup</mat-icon>
              </button>
            </mat-card-actions>
            <mat-card-content class="list-content">
              <mat-list>
                <mat-list-item *ngFor="let item of documents">
                  <p matLine>
                    <span (click)="getFile(item)"> {{ item.fileName }} </span>
                  </p>
                  <button
                    mat-icon-button
                    color="warn"
                    aria-label="Apagar arquivo"
                    (click)="deleteFile(item)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
          <div *ngIf="!data.event">
            <input
              type="file"
              #fileUpload
              id="fileUpload"
              name="fileUpload"
              multiple="multiple"
              accept="*"
              style="display: none"
              [required]="model.operation_type != 1 && model.operation_type != 3"
            />
            <mat-error>* Obrigatório para Outbound</mat-error>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <ng-container *ngIf="loading; else elseTemplate">
      <button mat-raised-button color="warn" disabled="disabled">
        Salvando ...
      </button>
    </ng-container>
    <ng-template #elseTemplate>
      <button type="button" mat-raised-button mat-dialog-close="true">
        Fechar
      </button>
      <button
        (click)="storeSchedule()"
        mat-raised-button
        color="primary"
        *ngIf="!data.event"
      >
        Salvar
      </button>
      <!-- <button (click)="storeSchedule()" mat-raised-button color="primary" *ngIf="data.action == 'create'">
      Salvar
    </button>-->
      <button
        (click)="storeSchedule('edit')"
        mat-raised-button
        color="primary"
        *ngIf="data.event"
      >
        Salvar
      </button>
      <!-- <button (click)="cancelSchedule()" mat-raised-button color="warn" *ngIf="data.action == 'edit' && data.schedule.status != 'denied'">
      Excluir
    </button> -->
    </ng-template>
  </mat-dialog-actions>
</form>
