<h2 mat-dialog-title>Registrar Rastreador</h2>
<div class="tkg-msg" *ngIf="showMsg">{{msg}}</div>
<mat-checkbox *ngIf="showMsg"
          class="tkg-checkbox"
          [(ngModel)]="checked"
          labelPosition="before"
          (change)="changeDevice()">
        Trocar dispositivo
      </mat-checkbox>

<mat-dialog-content class="mat-typography" *ngIf="showSelect">
  <mat-form-field class="tkg-form">
    <mat-label>Dispositivo</mat-label>
    <mat-select [disabled]="disabled">
      <div class="input-search">
        <form [formGroup]="searchForm" class="tkg-search-input-form">
          <input matInput placeholder="Pesquisar" formControlName="search">
        </form>
        <button mat-icon-button aria-label="Clear data" (click)="clearSearchString()" class="search-button-icon">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-option *ngFor="let item of list" [value]="item.id" (click)="selectedOption(item.id)">
        {{item.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>Fechar</button>
  <button mat-raised-button style="color: white;" color="primary" (click)="submit()" [disabled]="!selected">Salvar</button>
</mat-dialog-actions>
