import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-schedule-window-dialog',
  templateUrl: './schedule-window-dialog.component.html',
  styleUrls: ['./schedule-window-dialog.component.scss']
})
export class ScheduleWindowDialogComponent implements OnInit {
  form = this.formBuilder.group({
    department_id: [this.data.department_id, Validators.required],
    operation_id: [this.data.operation_id, Validators.required],
    suboperation_id: [this.data.suboperation_id],
    vehicle_type: [this.data.vehicletype_id],
    start: [this.data.start, Validators.required],
    end: [this.data.end, Validators.required],
    slot: [this.data.slot, Validators.required],
    limitBySlotSize: [this.data.limitbyslotsize],
    limitScheduleSlot: [this.data.limitscheduleslot]
  });
  departments: any[] = [];
  operations: any[] = [];
  vehicleTypes: any[] = [];
  suboperations: any[] = [];
  suboperationsFiltered: any[] = [];
  validationMsgs: any[] = [];
  operationsFiltered: any[] = [];
  account: any;
  showSuboperation = false;
  allDataLoaded = false;
  nonEditableField = false;

  constructor(
    public dialogRef: MatDialogRef<ScheduleWindowDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getDepartments();
    this.getOperations();
    this.getSubOperations();
    this.getVehicleTypes();
  }

  close(){
    this.dialogRef.close();
  }

  getOperations(){
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operations = r.data.resource;
      this.operationsFiltered = r.data.resource;
      //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true
      this.operations = this.operations.filter(item => item.use_in_scheduling === true);
      this.operationsFiltered = this.operations.filter(item => item.use_in_scheduling === true);

      if(this.data.operation_id){
        this.nonEditableField = true;
        this.departmentChange();
      }
    });
  }

  getVehicleTypes(){
    this.supportDataService.generic({name: 'vehicle_type'}).subscribe((r: any) => {
      this.vehicleTypes = r.data.resource || [];
    });
  }

  getSubOperations(){
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.suboperations = r.data.resource;
      if(this.data.suboperation_id){
        this.nonEditableField = true;
        this.operationChange();
      }
    });
  }

  setSuboperationsFilter(operation: number){
    this.suboperationsFiltered = [];
    this.form.patchValue({suboperation_id: ""})
    const ft = this.suboperations.filter((item: any) => item.operation_id === operation)
    this.suboperationsFiltered = ft;

    if(this.suboperationsFiltered.length > 0){
      this.showSuboperation = true;
      this.form.get('suboperation_id')!.setValidators(Validators.required);
      if(this.data.suboperation_id){
        this.form.patchValue({suboperation_id: this.data.suboperation_id});
      }
    } else {
      this.showSuboperation = false;
      this.form.patchValue({suboperation_id: ""})
      this.form.get('suboperation_id')!.clearValidators();
    }
  }

  getDepartments(){
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departments = r.data.resource

      if(this.departments.length === 1) {
        this.form.patchValue({department_id: this.departments[0].id})
      } else if(!this.data.id) {
        //Se não possuir data.id, é uma nova janela para um cliente com mais de um departamento
        //Então desativa o campo de Operações até que seja informado um Departamento
        this.form.controls.operation_id.disable();
      }
    });
  }

  operationChange(){
    if(this.suboperations){
      this.setSuboperationsFilter(this.form.get('operation_id')!.value)
    }
  }

  departmentChange(){
    this.setOperationsFilter(this.form.get('department_id')!.value)
  }

  setOperationsFilter(department: number){
    this.operationsFiltered = [];
    this.form.patchValue({operation_id: ""})
    const ft = this.operations.filter((item: any) => item.department_id === department)
    this.operationsFiltered = ft;
    //Se não for a edição de uma janela, ativa o campo de Operações pois foi informado um Departamento
    if(!this.data.id) {
      this.form.controls.operation_id.enable();
     }

    this.form.patchValue({operation_id: this.data.operation_id});
  }

  setLimitBySlotSize(value: boolean | null) {
    if(value) {
      this.form.get('limitScheduleSlot')!.setValidators(Validators.required);
    }else {
      this.form.get('limitScheduleSlot')!.clearValidators();
    }
    this.cdr.detectChanges();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      department_id: this.form.value.department_id,
      resource: {
        operation_id: this.form.value.operation_id,
        suboperation_id: this.form.value.suboperation_id,
        vehicle_type: this.form.value.vehicle_type,
        start: this.form.value.start,
        end: this.form.value.end,
        slot: this.form.value.slot,
        limitBySlotSize: this.form.value.limitBySlotSize,
        limitScheduleSlot: this.form.value.limitBySlotSize ? this.form.value.limitScheduleSlot : null
      }
    }

    if(options.id) {
      this.agendamentoService.updateScheduleWindow(options).subscribe((r: any) => {
        if(r.error) {
          this.validationMsgs = r.msg;
        } else {
          this.close();
        }
      });
    } else {
      this.agendamentoService.storeScheduleWindow(options).subscribe((r: any) => {
        if(r.error) {
          this.validationMsgs = r.msg;
        } else {
          this.close();
        }
      });
    }

  }


  // ToDo:
  // 1) Validar se o valor do select end é maior que o valor escolhido no select start

}
