import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service';
import { Media } from '../media.interface';
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ModalRankingPermanenciaComponent } from '../modal-ranking-permanencia/modal-ranking-permanencia.component';
import { ModalLiberacaoEntradaComponent } from '../modal-liberacao-entrada/modal-liberacao-entrada.component';
import { ModalDirecionamentoComponent } from '../modal-direcionamento/modal-direcionamento.component';

import { RefreshService } from 'src/app/shared/refresh.service';
import { ModalPanelStatusComponent } from '../modal-panel-status/modal-panel-status.component';

@Component({
  selector: 'app-default-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  @ViewChild('fs') fs: ElementRef;

  media_motorista_cd: string;
  media_aguardando_liberacao: string;
  quantidade_liberar: number;
  quantidade_direcionamento: number;
  quantidade_checkin: number;
  quantidade_estacionado: number;
  quantidade_doca: number;
  quantidade_despacho: number;
  quantidade_retirando_notas: number;
  quantidade_operacao_inbound: number;
  quantidade_operacao_outbound: number;
  quantidade_operacao_devolucao: number;
  quantidade_operacao_dfd: number;

  rk_permanencia: any;
  rk_aguardando_liberacao: any;


  // se o valor for um aumento setar UP
  setaup = 'call_made';
  // se o valor for uma queda setar DOWN
  setadown = 'call_received';
  breakpoints = Breakpoints;
  screenRatio: string;

  @Output() reloadEvent = new EventEmitter();

  constructor(
    private refreshService: RefreshService,
    private dialog: MatDialog,
    public dashboard: DashboardService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.HandsetPortrait,
      Breakpoints.Web,
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge

    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  ngOnInit() {
    this.refreshService.refresh.subscribe(() => {
      this.carregaKPIs();
    })
    this.carregaKPIs();
  }

  carregaKPIs() {
    this.tempoMedioDirecionamento();
    this.mediaAguardandoLiberacao();
    this.operacao_inbound();
    this.operacao_outbound();
    this.operacao_devolucao();
    this.operacao_dfd();
    this.ranking_permanencia();
    this.ranking_aguardando_liberacao();

    this.liberacao();
    this.direcionamento();
    this.checkin();
    this.estacionado();
    this.doca();
    this.despacho();
    this.retirando_notas();
  }

  tempoMedioDirecionamento() {
    return this.dashboard.tempoMedioMotoristaCD().subscribe((media: any) => {
      this.media_motorista_cd = this.segundosParaHoraMinuto(parseInt(media.hoje)) || '';
    });
  }

  mediaAguardandoLiberacao() {
    return this.dashboard.mediaAguardandoLiberacao().subscribe((media: any) => {
      this.media_aguardando_liberacao = this.segundosParaHoraMinuto(parseInt(media.data)) || '';
    });
  }

  operacao_inbound() {
    return this.dashboard.operacao_inbound().subscribe((media: Media) => {
      this.quantidade_operacao_inbound = media.qtde;
    });
  }

  operacao_outbound() {
    return this.dashboard.operacao_outbound().subscribe((media: Media) => {
      this.quantidade_operacao_outbound = media.qtde;
    });
  }

  operacao_devolucao() {
    return this.dashboard.operacao_devolucao().subscribe((media: Media) => {
      this.quantidade_operacao_devolucao = media.qtde;
    });
  }

  operacao_dfd() {
    return this.dashboard.operacao_dfd().subscribe((media: Media) => {
      this.quantidade_operacao_dfd = media.qtde;
    });
  }

  ranking_permanencia() {
    var limit = 5
    this.dashboard.ranking_permanencia(limit).subscribe({
      next: (r: any) => {
        this.rk_permanencia = r.data;
      }, error: (e: any) => {

      }
    });
  }

  ranking_aguardando_liberacao() {
    const option = { limit: 5 };
    this.dashboard.rankingAguardandoLiberacao(option).subscribe({
      next: (r: any) => {
        this.rk_aguardando_liberacao = r.data;
      }, error: (e: any) => {

      }
    });
  }

  liberacao() {
    return this.dashboard.liberacao().subscribe((media: Media) => {
      this.quantidade_liberar = media.qtde;
    });
  }

  direcionamento() {
    return this.dashboard.direcionamento().subscribe((media: Media) => {
      this.quantidade_direcionamento = media.qtde;
    });
  }
  checkin() {
    return this.dashboard.checkin().subscribe((media: Media) => {
      this.quantidade_checkin = media.qtde;
    });
  }

  estacionado() {
    return this.dashboard.estacionado().subscribe((media: Media) => {
      this.quantidade_estacionado = media.qtde;
    });
  }

  doca() {
    return this.dashboard.doca().subscribe((media: Media) => {
      this.quantidade_doca = media.qtde;
    });
  }

  despacho() {
    return this.dashboard.despacho().subscribe((media: Media) => {
      this.quantidade_despacho = media.qtde;
    });
  }

  retirando_notas() {
    return this.dashboard.retirando_notas().subscribe((media: Media) => {
      this.quantidade_retirando_notas = media.qtde;
    });
  }

  calcData(diff: any) {

    if (diff) {
      let dia: string;
      let hours: string;
      let minutes: string;
      let seconds: string;
      let resultado: string[];

      resultado = diff.split(":", 3);

      hours = resultado[0] + 'h';
      if (hours.includes("day") || hours.includes("days")) {
        hours = hours.replace("days", "d");
        hours = hours.replace("day", "d");
      }

      minutes = resultado[1] + 'm';
      seconds = resultado[2].substring(0, 2) + 's';

      return [hours, minutes, seconds].join(':');
    }
    return 0;
  }

  activateLayout() {

    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      //console.log('XSmall');
      this.screenRatio = '4:3';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      //console.log('Small');
      this.screenRatio = '4:3';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      //console.log('Medium');
      this.screenRatio = '7:6';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      //console.log('Large');
      this.screenRatio = '4:3';

    } else if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      //console.log('XLarge');
      this.screenRatio = '16:9';
    } else {
      this.screenRatio = '1:1';
    }
  }

  // Modals
  modalPainelStatus() {
    const dialogRef = this.dialog.open(ModalPanelStatusComponent, {
      panelClass: ['dialog-large-black'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(""))
      }
    });
  }

  modalRankingPermanencia() {
    const dialogRef = this.dialog.open(ModalRankingPermanenciaComponent, {
      panelClass: ['dialog-large'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(""))
      }
    });
  }

  modalLiberacaoEntrada() {
    const dialogRef = this.dialog.open(ModalLiberacaoEntradaComponent, {
      panelClass: ['dialog-large'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(""))
      }
    });
  }

  modalDirecionamento() {
    const dialogRef = this.dialog.open(ModalDirecionamentoComponent, {
      panelClass: ['dialog-large'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(""))
      }
    });
  }
  segundosParaHoraMinuto(segundos: (number | any)) {
    if (segundos) {
      return moment.duration(segundos, "seconds").format('DD[d] hh[h]mm[m]');
    } else {
      return null;
    }
  }
}
