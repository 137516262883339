import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { UntypedFormBuilder } from "@angular/forms";
import { Subject } from "rxjs";
import {
  filter,
  startWith,
  debounceTime,
  switchMap,
  map,
  catchError,
} from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";
import { Component, Inject, OnInit } from "@angular/core";
import { of as observableOf } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { DriversService } from "src/app/drivers/shared/drivers.service";

@Component({
  selector: "app-dialog-link-driver",
  templateUrl: "./dialog-link-driver.component.html",
  styleUrls: ["./dialog-link-driver.component.scss"],
})
export class DialogLinkDriverComponent implements OnInit {
  truckerDrivers: any;
  truckerDriversSelected: any;
  truckers: any[] = [];
  searching = false;
  protected _onDestroy = new Subject<void>();

  form = this.formBuilder.group({
    driver_id: [0],
  });
  truckerFilter: UntypedFormControl = new UntypedFormControl();
  dialogResult: any;

  constructor(
    public dialogRef: MatDialogRef<DialogLinkDriverComponent>,
    private driverService: DriversService,
    private checkinService: CheckinService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.loadDriver();
  }

  loadDriver() {
    this.truckerFilter.valueChanges
      .pipe(
        startWith("*"),
        debounceTime(500),
        filter(
          (search: any) => (search && "" + search.trim() != "") || search == "*"
        ),
        map((value: string) => value.replace("*", "")),
        switchMap((search) => {
          this.searching = true;
          const options = {
            orderBy: "name",
            sortedBy: "ASC",
            page: 1,
            pageSize: 10,
            search,
          };
          return this.driverService.getTruckerDrivers(options).pipe(
            catchError(() => {
              this.searching = false;
              return observableOf({ total: 0, per_page: 0, data: [] });
            })
          );
        })
      )
      .subscribe((data: any) => {
        const truckers = data as unknown as any[];
        if (this.data.item.resource.board_horse || (this.data.item.resource.board_cart && this.data.item.resource.totalBoard <= 1)) {
          this.truckerDrivers = truckers.filter(
            (r: any) => !r.trucker_resource.board_horse && !r.trucker_resource.board_cart
          );
        }
        else {
          this.truckerDrivers = truckers.filter(
            (r: any) => r.trucker_resource.board_horse && !r.trucker_resource.board_cart
          );
        }
      });
  }

  onSubmit() {
    this.dialogResult = {
      id: this.data.item.id,
      status: this.data.item.resource.status,
      board_horse: this.form.value.driver_id.trucker_resource.board_horse
        ? this.form.value.driver_id.trucker_resource.board_horse
        : this.data.item.resource.board_horse,
      trucker_checkin_id: this.form.value.driver_id.trucker_resource.id,
      driverId_trucker: this.form.value.driver_id.trucker_resource.driver_id,
      linkDriver: true,
    };

    if (
      this.form.value.driver_id.trucker_resource.carrier_id &&
      this.form.value.driver_id.trucker_resource.carrier_id === this.data.item.resource.carrier_id
    ) {
      this.checkinService
        .updateField("checkins", this.dialogResult)
        .subscribe((r: any) => {
          if (r.status == 200) {
            this.dialogRef.close(this.dialogResult);
          }
        });
    } else {
      this.dialog
        .open(DialogConfirmComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            title: "Atenção!",
            message:
              "Transportadoras divergentes, deseja confirmar o vínculo ?",
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.checkinService
              .updateField("checkins", this.dialogResult)
              .subscribe((r: any) => {
                if (r.status == 200) {
                  this.dialogRef.close(this.dialogResult);
                }
              });
          }
        });
    }
  }
}
