import { UntypedFormGroup } from '@angular/forms';
import { Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { interval, merge, of, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { catchError, exhaustMap, map, startWith, debounceTime } from 'rxjs/operators';

import { HubService } from '../shared/hub.service';
import { Stage, StageGroup } from '../media.interface';
import { environment } from 'src/environments/environment.prod';
import { HubInfoComponent } from '../hub-info/hub-info.component';
import { WssService } from 'src/app/shared/websocket/wss.service';
import { SupportDataService } from './../../shared/support-data.service';
import { StageAlertsComponent } from '../stage-alerts/stage-alerts.component';

@Component({
  selector: 'app-hub-list',
  templateUrl: './hub-list.component.html',
  styleUrls: ['./hub-list.component.scss']
})
export class HubListComponent implements OnInit, OnDestroy {
  dashRefreshSubscription:Subscription;
  stageGroups:StageGroup[];

  total_veiculos_operacao: number;
  total_veiculos_finalizados: number;
  waiting_on_park: number;

  breakpoints = Breakpoints;
  screenRatio: string;
  showFilter = false;

  form = new UntypedFormGroup({});
  model: any = { department_id:"" };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [{
    key: 'department_id',
    type: 'select',
    props: {
      label: 'Departamento',
      placeholder: 'Selecione o departamento',
      appearance: 'outline',
      options:[]
    },
  }];

  constructor(
    private dialog: MatDialog,
    private hubSerbice: HubService,
    private websocketService: WssService,
    private supportDataService: SupportDataService
  ) {}

  ngOnDestroy(): void {
    this.dashRefreshSubscription && this.dashRefreshSubscription.unsubscribe();
  }

  ngOnInit() {

    this.supportDataService.generic({ name: 'departments' })
    .pipe(map((x:any) => x?.data?.resource))
      .subscribe((resource: any[]=[]) => {
        this.fields[0].props!.options = [{ label: 'Limpar'}]
          .concat(
            (resource || []).map((item:any)=>{
              return {
                label:item.name,
                value:item.id
              }
            })
          );

      this.showFilter = this.fields[0].props!.options.length > 2;
    });

    const id = JSON.parse(localStorage.getItem("account") || "{}").system_client?.id;

    this.dashRefreshSubscription = merge(
      interval(30000),
      this.websocketService.connect(`${environment.wsUrl}/checkin/${id}`),
      this.form.valueChanges
    ).pipe(
      startWith({}),
      debounceTime(1000),
      exhaustMap(()=>{
        const options = {
          department_id:this.model.department_id || ""
        }
        return this.hubSerbice.dashboardListing(options).pipe(catchError(()=>of(null)))
      })
    )
    .subscribe((data)=>this.setDashData(data));
  }

  setDashData(data:any){
    if(data){
      const {
        in_operation,
        finalized,
        waiting_on_park,
        stage_groups
      } = data;

      this.total_veiculos_operacao = (in_operation || 0);

      this.total_veiculos_finalizados = (finalized || 0);

      this.waiting_on_park = (waiting_on_park || 0);

      stage_groups && this.setStageGroupsData(stage_groups);
    }
  }

  setStageGroupsData(data:StageGroup[]){
    data = data.sort((groupA:StageGroup, groupB:StageGroup) => (groupA.order || 0) - (groupB.order || 0));
    data.forEach((group:StageGroup)=>{
      group.stages = group.stages.sort((stageA, stageB) => (stageA.order || 0) - (stageB.order || 0));
    });
    this.stageGroups = data;
  }

  stageInfo(stageGroup:StageGroup, stage:Stage, stage_time?: "on_time" | "halfway_time" | "over_time"){
    this.dialog.open(HubInfoComponent, {
      panelClass: ["dialog-medium"],
      data: {
        stage_time: stage_time || "",
        title: stageGroup.title + " - " + stage.name,
        department_id:this.model.department_id || "",
        stage_group:stageGroup.group_name,
        ...stage
      }
    });
  }

  stageAlerts(stageGroup:StageGroup, stage:Stage){
    this.dialog.open(StageAlertsComponent, {
      panelClass: ["dialog-medium"],
      data: {
        title: stageGroup.title + " - " + stage.name,
        department_id:this.model.department_id || "",
        stage_group: stageGroup.group_name,
        ...stage
      }
    });
  }

}
