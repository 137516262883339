import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private http: HttpClient) { }

  index(options: any={}): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/qualcomm-devices`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error')))
    );
  }

  getDeviceList(options: any={}): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/core/view/devices`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error')))
    );
  }

  getQualcommDevices(options: any={}): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/qualcomm-devices`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response.data || {}),
      catchError((err) => throwError(() => err || new Error('Server error')))
    );
  }
}
