import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepsFilterService {
  private boxFilterSubject = new Subject<any>();
  private showSubject = new Subject<boolean>();
  private clearSubject = new Subject<any>();
  value = this.boxFilterSubject.asObservable();
  show = this.showSubject.asObservable();
  clear = this.clearSubject.asObservable();
  constructor() { }

  onChange(value: string, scope: string) {
    console.log('atualiza');
    this.boxFilterSubject.next(<any>{
      value: value,
      scope: scope,
    });
  }

  onOpen() {
    // console.log('onOpen');
    this.showSubject.next(true);
  }

  onClose() {
    // console.log('onClose');
    this.showSubject.next(false);
  }

  onClear(event?: Event) {
    // console.log('onClear');
    this.clearSubject.next(event);
  }
}
