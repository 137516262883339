import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DocumentsRoutingModule } from './documents-routing.module'
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentsDialogComponent } from './documents-dialog/documents-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        DocumentsRoutingModule
    ],
    declarations: [DocumentsListComponent, DocumentsDialogComponent]
})
export class DocumentsModule { }
