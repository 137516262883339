import { Component } from '@angular/core';

@Component({
  selector: 'app-formly-ss-timeslot',
  templateUrl: './formly-ss-timeslot.component.html',
  styleUrls: ['./formly-ss-timeslot.component.scss']
})
export class FormlySsTimeslotComponent {

}
