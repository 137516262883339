import { Injectable } from '@angular/core';
import { AccountService } from 'src/app/account/shared/account.service';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  account: any;

  constructor(
    private accountService: AccountService

  ) {
  }

  public soundState = new Howl({
    src: ['assets/sounds/notification.mp3'],
    autoplay: false,
  });

   changeSoundState(state: boolean, accountId: any) {
     this.accountService.updateSound({has_sound: state, userId: accountId}).subscribe(() => {
      this.account = JSON.parse(localStorage.getItem("account")!)
      localStorage.setItem("account", JSON.stringify({...this.account, has_sound:state}))
      if(state) {
        this.soundState.play()
      } else {
        this.soundState.stop()
      }
    })
    }
  }
