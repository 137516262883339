import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartListComponent } from './cart-list/cart-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';

const cartRoutes: Routes = [
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/carts',
    component: CartListComponent,
    data: {
      title: 'Veículos Desvinculados',
      search: false,
      refresh: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(cartRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CartRoutingModule { }
