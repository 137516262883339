import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginDetailComponent } from './login-detail/login-detail.component';
import { RegisterDetailComponent } from './register-detail/register-detail.component';
import { PasswordResetDetailComponent } from './password-reset-detail/password-reset-detail.component';
import { PasswordTokenDetailComponent } from './password-token-detail/password-token-detail.component';

const accountRoutes: Routes = [
  {
    path: 'auth',
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'login',
        component: LoginDetailComponent,
        data: { title: 'Login' }
      },
      {
        path: 'register',
        component: RegisterDetailComponent,
        data: { title: 'Register' }
      },
      {
        path: 'remember',
        component: PasswordTokenDetailComponent,
        data: { title: 'Forgot the password' }
      },
      {
        path: 'password_reset/:token/:email',
        component: PasswordResetDetailComponent,
        data: { title: 'Password reset' }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(accountRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AccountRoutingModule { }
