<div class="top-bar-filters">
  <div>

    <div *ngIf="isWeb">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select>
          <mat-option (click)="getFilterStatus('')">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let ls of listStatus" [value]="ls.id" (click)="getFilterStatus(ls.id)">
            {{ls.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="isWeb">
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="grid">
  <div *ngFor="let driver of cards" class="grid-elements">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{driver.resource.name}}</mat-card-title>
        <mat-card-subtitle><b>PLACA</b> {{driver.resource.plate}}</mat-card-subtitle>
        <mat-card-subtitle><b>CPF</b>  {{driver.resource.cpf}}</mat-card-subtitle>
        <mat-card-subtitle><b>PREVISÃO</b> {{driver.resource.prevision ? (driver.resource.prevision | date:'shortTime') : ''}}</mat-card-subtitle>
        <mat-card-subtitle><b>{{driver.resource.route}}</b></mat-card-subtitle>
      </mat-card-header>
      <!-- <mat-card-content>
        <p>
          PREVISÃO DE CHEGADA
        </p>
      </mat-card-content> -->
      <mat-card-actions class="actions">
        <div class="d-cont">
          <div class="d-left">
            <p class="journey">Status</p>
            <mat-chip-set role="list">
              <mat-chip role="listitem" [style.background-color]="showJourneyStatus(driver.checkin?.status).color" class="tkg-chips" selected>{{showJourneyStatus(driver.checkin?.status).label}}</mat-chip>
            </mat-chip-set>
          </div>
          <div class="d-center">
            <p class="journey">Chegada</p>
            <mat-chip-set role="list">
              <mat-chip role="listitem" [style.background-color]="showDisplacementStatus(driver.resource.displacement_status)?.color" class="tkg-chips" selected>{{showDisplacementStatus(driver.resource.displacement_status)?.label}}</mat-chip>
            </mat-chip-set>
          </div>
          <div class="d-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [disabled]="!driver.checkin && !showInStatus.includes(driver.checkin?.status)" (click)="updateStatus(driver.checkin_id)">
                <span>Confirmar documentos</span>
              </button>
              <button mat-menu-item (click)="openMap('event_id', driver.id)">
                <span>Ver no mapa</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>

</div>


