import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DocumentsService } from "src/app/documents/shared/documents.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { UserService } from "src/app/user/shared/user.service";

import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { PreScheduleDocumentDialogComponent } from "../pre-schedule-document-dialog/pre-schedule-document-dialog.component";

@Component({
  selector: "app-pre-schedule-document",
  templateUrl: "./pre-schedule-document.component.html",
  styleUrls: ["./pre-schedule-document.component.scss"],
})
export class PreScheduleDocumentComponent {
  document: any[] = [];
  groups: any[] = [];
  document_type: any[] = [];
  listDocuments: any[] = [];
  @Input() hiddenElements: any;

  constructor(
    private dialog: MatDialog,
    private documentoService: DocumentsService,
    private userService: UserService,
    private supportDataService: SupportDataService,
  ) {}

  ngOnInit() {
    this.getGroups();
    this.getListDocuments();
    this.getDocumentType();
  }

  getListDocuments() {
    this.supportDataService
      .generic({ name: "pre_schedule_status" })
      .subscribe((r: any) => {
        this.listDocuments = r.data.resource;

        this.getDocumentsUploadRules();
      });
  }

  getGroups() {
    const options = { pageSize: 100 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
      this.getDocumentsUploadRules();
    });
  }

  getDocumentType() {
    this.supportDataService
      .generic({ name: "pre_schedule_document_list" })
      .subscribe((r: any) => {
        this.document_type = r.data.resource;
      });
  }

  getDocumentsUploadRules() {
    this.documentoService.indexDocumentUpload().subscribe((r: any) => {
      this.document = r.data;
      console.log(r.data);
    });
  }

  addEdit(item?: any) {
    //Para máquina de vendas, verifica se status_on_create e status_on_info_edit é um id de números apenas
    //Se sim faz o JSON.parse para que ao editar status de agendamentos em MV os campos já venham preenchidos corretamente com os dados salvos
    if (item) {
      if (
        /^\d+$/.test(item.status_on_create) &&
        /^\d+$/.test(item.status_on_info_edit)
      ) {
        item.status_on_create = JSON.parse(item.status_on_create);
        item.status_on_info_edit = JSON.parse(item.status_on_info_edit);
      }
    }

    const dialogRef = this.dialog.open(PreScheduleDocumentDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: { ...item },
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getDocumentsUploadRules();
    });
  }

  showGroupName(ids: any) {
    if (ids) {
      const group_ids = JSON.parse(ids);
      let groupNames: any = [];
      this.groups.forEach((item) => {
        if (group_ids.includes(item.id)) {
          groupNames.push(item.name);
        }
      });
      return groupNames.join(", ");
    } else {
      return "";
    }
  }

  showDocumentName(ids: any) {
    if (ids) {
      const document_id = JSON.parse(ids);
      let documentNames: any = [];
      this.document_type.forEach((item) => {
        if (document_id.includes(item.id)) {
          documentNames.push(item.name);
        }
      });
      return documentNames.join(", ");
    } else {
      return "";
    }
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "ATENÇÃO!",
        message: `Tem certeza que deseja APAGAR o status de agendamento?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.documentoService.deleteDocumentUpload(id).subscribe((r: any) => {
          if (!r.error) {
            this.getDocumentsUploadRules();
          }
        });
      }
    });
  }

  showListScheduleStatusName(ids: any) {
    if (ids) {
      const list_ids = JSON.parse(ids);
      let listNames: any[] = [];

      this.listDocuments.forEach((item) => {
        if (list_ids.includes(item.id)) {
          listNames.push(item.name);
        }
      });
      return listNames.join(", ");
    } else {
      return "";
    }
  }

  showScheduleStatusName(statusId: any) {
    if (statusId) {
      let statusName;

      this.listDocuments.forEach((item) => {
        if (statusId == item.id) {
          statusName = item.name;
        }
      });

      if (statusName) {
        return statusName;
      }
      return "-";
    } else {
      return "-";
    }
    return "";
  }
}
