<h3 class="mat-align-center title">Regra adicional de checkin</h3>

<div mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Transportadora</mat-label>
        <mat-select
          placeholder="Escolha a transportadora"
          formControlName="carrier_id"
        >
          <mat-option [value]="item.id" *ngFor="let item of carriers">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Regra adicional</mat-label>
        <mat-select placeholder="Escolha a regra" formControlName="rule_id">
          <mat-option [value]="item.id" *ngFor="let item of rules">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div mat-dialog-actions class="tkg-dialog-actions-end">
      <button mat-stroked-button color="warn" (click)="close()">Cancelar</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!form.valid"
      >
        Salvar
      </button>
    </div>
  </div>
</div>


