import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ShiftInterface, ShiftActions } from '../../shifts';
import { ShiftsService } from '../../shared/shifts.service';

@Component({
  selector: 'app-shift-dialog',
  templateUrl: './shift-dialog.component.html',
  styleUrls: ['./shift-dialog.component.scss']
})
export class ShiftDialogComponent {
  shiftForm = this.formBuilder.group({
    name: [this.data.data?.name || "", [Validators.required]]
  })

  saving = false;

  constructor(
    protected shiftsService:ShiftsService,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ShiftDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {action:ShiftActions, data:ShiftInterface}
  ) {  }

  onSubmit(){
    const data = {
      ...this.shiftForm.getRawValue(),
      id:this.data.data?.id
    } as ShiftInterface & {id:number, name:string};

    this.saving = true;

    if(this.data.action == ShiftActions.ADD){
      this.shiftsService.storeShift(data)
      .subscribe(()=>this.dialogRef.close(true))
      .add(()=>this.saving = false)
    }else if(this.data.action == ShiftActions.EDIT){

      this.shiftsService.updateShift(data)
      .subscribe(()=>this.dialogRef.close(true))
      .add(()=>this.saving = false)
    }else{
      this.saving = false;
    }
  }
}
