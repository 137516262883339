import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportRoutingModule } from './report-routing.module';
import { EventReportListComponent } from './event-report-list/event-report-list.component';
import { ReportsComponent } from './reports/reports.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ReportRoutingModule
  ],
  declarations: [ReportListComponent, EventReportListComponent, ReportsComponent]
})
export class ReportListModule { }
