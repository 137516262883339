import { DashboardDynamicService } from './../../../../dashboard-dynamic/dashboard-dynamic.service';
import { SupportDataService } from './../../../support-data.service';
import { CardInterface } from './../../card.interface';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-2-info',
  templateUrl: './card-2-info.component.html',
  styleUrls: ['./card-2-info.component.scss']
})
export class Card2InfoComponent implements OnInit, CardInterface {
  value1 = 0;
  value2 = 0;
  data: any;
  layout!: number;
  options: any;

  constructor(private SupportDataService: SupportDataService,
    private dashboardDynamic: DashboardDynamicService) { }

  ngOnInit() {
    this.dashboardDynamic.getDynamicDashboardItem({ item: this.data.element_name}).subscribe((qtd: any) => {
        this.value1 = qtd.event;
        this.value2 = qtd.checkin;
    });

  }
}
