import { Injectable, Inject } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from "./../../../environments/environment.prod";
export const WS_ENDPOINT = environment.wsUrl;
export const RECONNECT_INTERVAL = 2000;


@Injectable({
  providedIn: 'root'
})
export class WSService2 {
  public CHANNEL: string = "";
  public socket$: WebSocketSubject<any>;

  constructor() {}

   public connect(): WebSocketSubject<any> {
    const url = WS_ENDPOINT + this.CHANNEL;
    console.log(`WS Connected on ${url}`);
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = webSocket({url, deserializer: (msg) => {
        let data = null;
        try{
          if(msg.data?.match(/"user_id":.*"msg":.*/gi)){
            data = JSON.parse(msg.data);
            if(data.msg?.match(/((\[[^\}]{3,})?\{s*[^\}\{]{3,}?:.*\}([^\{]+\])?)/gi)){
              data.msg = JSON.parse(data.msg);
            }
          }
        }catch(ex:any){}
        return data;
      }});
    }
    return this.socket$;
  }

  public dataUpdates$() {
    return this.connect().asObservable();
  }

  closeConnection() {
    this.connect().complete();
    this.socket$.unsubscribe();
    console.log('WS Closed');
  }

}
