import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsPlanedRoutingModule } from './events-routing.modules'
import { CoreModule } from '../core/core.module';
import { EventsPlanedListComponent } from './events-planed-list/events-planed-list.component';
//import { MapsGenericComponent } from '../shared/maps-generic/maps-generic.component'
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { UploadMassSchedulingComponent } from './upload-mass-scheduling/upload-mass-scheduling.component';
import { DndDirective } from './upload-mass-scheduling/dnd.directive';
import { MassSchedulingResultComponent } from './upload-mass-scheduling/mass-scheduling-result/mass-scheduling-result.component';


@NgModule({
    declarations: [
      EventsPlanedListComponent,
      UploadDialogComponent,
      UploadMassSchedulingComponent,
      DndDirective,
      MassSchedulingResultComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        EventsPlanedRoutingModule
    ]
})
export class EventsModule { }
