<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ (form.value.first_name) ? form.value.first_name + ' ' + ((form.value.last_name) ? form.value.last_name : '') : 'Editar' }}
  </h2>
  <mat-dialog-content class="mat-typography">

    <div class="container">
      <mat-form-field class="fullName">
        <mat-label>Primeiro nome</mat-label>
        <input type="text" formControlName="first_name" matInput placeholder="Nome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.first_name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="fullName">
        <mat-label>Último nome</mat-label>
        <input type="text" formControlName="last_name" matInput placeholder="Sobrenome">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.last_name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="email">
        <mat-label>E-mail</mat-label>
        <input type="email" formControlName="email" matInput placeholder="E-mail">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.email">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- <h3>Alertas</h3>

    <div>
      <mat-checkbox formControlName="is_alert_mail">Receber alertas2 por e-mail</mat-checkbox>
      <mat-checkbox formControlName="is_alert_phone">Receber alertas por celular</mat-checkbox>
    </div> -->


    <div>
      <!-- <div *ngIf="form.get('password').enabled"> -->
      <mat-form-field class="password">
        <mat-label>Senha</mat-label>
        <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput placeholder="Senha">
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.password">{{ error }}</div>
        </mat-hint>
        <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ?
          'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
    </div>
    <button type="button" mat-stroked-button color="primary" ngClass="margin-bottom" (click)="togglePassword()">
      Alterar senha</button>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Fechar</button>
    <button type="submit" mat-raised-button style="color: white;" color="primary"
      [disabled]="!form.valid">Salvar</button>
  </mat-dialog-actions>
</form>