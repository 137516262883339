<h1 class="mat-align-center" mat-dialog-title>Defina o Local para a carreta
  <br><b>{{data?.item.resource.board_cart}}</b><br>
  <small *ngIf="data?.item.resource.dock_space_id">Direcionado para
    {{getDockName(data?.item.resource.dock_space_id)}}</small>
</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-radio-group aria-label="Selecione o tipo de local" formControlName="directed_to" class="location-radio"
      required>
      <mat-radio-button value="park" (click)="resetForm(2)">Pátio</mat-radio-button>
      <mat-radio-button value="dock" (click)="resetForm(1)">Docas</mat-radio-button>
    </mat-radio-group>
  </form>

  <div class="dialog-message mat-align-center" *ngIf="data.action === 'denied'">Confirme a Não Liberação<br> de
    entrada do motorista <br><b>{{data.item.driver.name}}?</b></div>
  <form [formGroup]="form1" *ngIf="form.value.directed_to === 'park'">
    <mat-form-field *ngIf="data.action === 'released' ">
      <mat-select class="form-tipo-vaga" formControlName="parking_space_type" placeholder="Tipo de vaga" required>
        <mat-option *ngFor="let item of parkingType" [value]="item.value">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <form [formGroup]="form2" *ngIf="form.value.directed_to === 'dock'">

    <mat-form-field>
      <mat-select class="form-tipo-vaga" formControlName="dock_space_id" placeholder="Docas" required>
        <mat-option *ngFor="let item of dockSpacesFiltered" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>


<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" (click)="onCancelClick()">Cancelar</button>
  <button type="submit" mat-button cdkFocusInitial color="primary" [disabled]="!form1.valid"
    *ngIf="form.value.directed_to === 'park'" (click)="onSubmit()">Salvar</button>
  <button type="submit" mat-button cdkFocusInitial color="primary" [disabled]="!form2.valid"
    *ngIf="form.value.directed_to === 'dock'" (click)="onSubmit()">Salvar</button>
</div>
