import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { merge, of as observableOf, Subject } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "../shared/cart.service";
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { SupportDataService } from "../../shared/support-data.service";
import { DirectCartDialogComponent } from "../direct-cart-dialog/direct-cart-dialog.component";
import { LinkVehicleDialogComponent } from "../link-vehicle-dialog/link-vehicle-dialog.component";

//dialogos
import { DialogConfirmComponent } from "../../shared/dialog-confirm/dialog-confirm.component";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { EditKeysDialogComponent } from "../../checkin-loreal/edit-keys-dialog/edit-keys-dialog.component"
import { GenericDialogComponent } from "src/app/checkin-loreal/generic-dialog/generic-dialog.component";

@Component({
  selector: "app-cart-list",
  templateUrl: "./cart-list.component.html",
  styleUrls: ["./cart-list.component.scss"],
})
export class CartListComponent implements OnInit {
  envData: any;
  search!: string;
  pageSize!: number;

  length!: number;
  displayedColumns!: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<any>();
  dataSourceAll = new MatTableDataSource<any>();
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  filters = {};
  protected _onDestroy = new Subject<void>();

  operations: any = [];
  carriers: any = [];
  dockSpaces: any = [];
  listStatus: any = [];
  listCheckinMht: any = [];
  listAction: any = [];
  departments: any = [];
  allDataAvailable: boolean = false;
  dashBordUpdateKey!: string;
  isWeb = true;

  filterStatus!: any;
  filterStatusSelected: UntypedFormControl = new UntypedFormControl();
  filterOperationSelected: UntypedFormControl = new UntypedFormControl();
  filterCarrierSelected: UntypedFormControl = new UntypedFormControl();
  filterVehicleTypeSelected: UntypedFormControl = new UntypedFormControl();
  filterDepartmentSelected: UntypedFormControl = new UntypedFormControl();
  actualPage!: string;
  showCarrierFilter: boolean = false;
  showOperationFilter: boolean = false;
  showODepartmentFilter: boolean = false;
  showStatusFilter: boolean = false;
  ShowSearchFilter: boolean = false;
  filterLabels: any = [];
  account: any;

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static:true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static:true}) sort!: MatSort;
  vehicle_types: any;
  constructor(
    public checkinService: CheckinService,
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    public cartService: CartService,
    public supportDataService: SupportDataService
  ) {
    this.envData = JSON.parse(localStorage.getItem("account")!);

    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.XSmall,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.actualPage = this.router.url;
    this.getListStatus();
    this.getListCheckinMht();
    this.getVehicleTypes();
    this.getActions();
    this.getDockSpaces();
    this.loadCarts();
    this.getCarrier();
    this.getDepartment();
    this.labelFilterEnabled();
  }

  labelFilterEnabled() {
    this.filterLabels = this.envData.system_client.resource.environment.pages[
      this.actualPage
    ]["data_filters_label"];
  }

  activateLayout() {
    var columns = this.envData.system_client.resource.environment.pages[
      this.router.url
    ]["columns"];

    if(columns.length > 0){
      this.displayedColumns = columns;
    }else{
      if (
        this.breakpointObserver.isMatched(Breakpoints.Handset) ||
        this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)
      ) {
        this.displayedColumns = [
          "board",
          "vehicle_type",
          "lacre",
          "appointment",
          "checkin_mht",
          "event_id",
          "carrier",
          "status",
          "actions",
        ];
      } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
        this.displayedColumns = [
          "board",
          "vehicle_type",
          "lacre",
          "appointment",
          "checkin_mht",
          "event_id",
          "carrier",
          "status",
          "actions",
        ];
      } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
        this.displayedColumns = [
          "board",
          "vehicle_type",
          "lacre",
          "appointment",
          "checkin_mht",
          "event_id",
          "carrier",
          "department",
          "status",
          "actions",
        ];

        if(this.envData.department_id.length == 1){
          this.displayedColumns = this.displayedColumns.filter((item: any) => {
            return item != "department";
          });
        }
      }
    }
  }

  getCarrier() {
    const options = {
      orderBy: "name",
      sortedBy: "ASC",
      page: 1,
      pageSize: 1000,
    };

    this.carrierService.index(options).subscribe((r: any) => {
      this.carriers = r.data;
    });
  }


  formatType(value: any) {
    return value === "cart" ? "Carreta" : "Cavalo";
  }

  getFilter(field: any, value = null) {
    this.searching = true;
    let options = {
      orderBy: this.sort.active ? this.sort.active : "updated_at",
      sortedBy: this.sort.direction ? this.sort.direction : "DESC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
      page: this.paginator.pageIndex + 1,
    };

    if(value){
      this.filters[field as keyof typeof this.filters] = value
    } else {
      let filters = this.filters;
      delete filters[field as keyof typeof this.filters];
      this.filters = filters;
    }

    options = {
      ...options,
      ...this.filters
    }

    this.cartService.index(options).subscribe((r: any) => {
      this.dataSource.data = r.data;
    });
  }

  loadCarts() {
    const pageOptions = this.envData.system_client.resource.environment.pages[
      this.actualPage
    ];
    let listStatus: string;
    if (status) {
      listStatus = status;
    } else {
      listStatus = pageOptions.with_status.join("|");
    }

    /// Ao utilizar a busca
    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: this.sort.active ? this.sort.active : "updated_at",
            sortedBy: this.sort.direction ? this.sort.direction : "DESC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            page: this.paginator.pageIndex + 1,
            search: `${this.searchFilter.value}` || "",
            with_status: listStatus,
          };
          return this.cartService.index(options);
        }),
        map((response: any) => {
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
        this.allDataAvailable = true;
      });

    /// Ao entrar na página
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          };

          Object.assign(options, {
            with_status: pageOptions.with_status.join("|"),
          });

          if (pageOptions.with_operations) {
            Object.assign(options, {
              with_operations: pageOptions.with_operations.join("|"),
            });
          }

          return this.cartService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
        this.allDataAvailable = true;
      });
  }

  lastLocation(resource: any) {
    if (resource.status === "on_dock") {
      return this.getDockName(resource.dock_space_id);
    }
    if (resource.status === "on_park") {
      return "No pátio";
    }
    if (resource.status === "waiting") {
      return "Aguardando";
    }
  }

  showStatus(resource: any) {
    if (this.listStatus.length > 0 && this.allDataAvailable) {
      if ("status" in resource) {
        const st = this.listStatus.filter(
          (item: any) => item.id === resource.status
        );
        if (st.length > 0) {
          if (resource.status === "on_dock") {
            return this.getDockName(resource.dock_space_id);
          } else {
            return st[0].alternate_name;
          }
        }
      } else {
        return "";
      }
    }
  }

  showDepartment(department: number) {
    if (this.departments) {
        const vt = this.departments.filter((op: any) => op.id === department);
        if(vt.length > 0) {
          return vt[0].name;
        } else {
          return "";
        }
    }
  }

  getListStatus() {
    this.supportDataService.status().subscribe((r: any) => {
      this.listStatus = r.data.resource;
    });
  }

  getListCheckinMht() {
    this.supportDataService.checkin_mht().subscribe((r: any) => {
      this.listCheckinMht = r.data.resource;
    });
  }

  getVehicleTypes() {
    this.supportDataService.vehicle_type().subscribe({
      next: (r: any) => {
        this.vehicle_types = r.data.resource;
      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  showVehicleType(cart: any) {
    if (this.vehicle_types) {
      const vt = this.vehicle_types.filter((op: any) => op.id === cart.resource.vehicle_type);
      if(vt.length > 0) {
        return vt[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getStatusColor(status: number) {
    if (this.listStatus.length > 0 && this.allDataAvailable) {
      if (status) {
        const st = this.listStatus.filter((item: any) => {
          if (item.id === status) {
            return true;
          }

          return false;

        });
        return st[0]["color"];
      } else {
        return "#FFFFFF";
      }
    } else {
      ("");
    }
  }

  getDockSpaces() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.dockSpaces = r.data.resource;
    });
  }

  getDockName(id: number) {

    if (id > 0) {
      if(this.account.department_id && this.account.system_client.resource.environment.docks_shared === false) {

        const deps: any[] = [];
        this.account.department_id.forEach((department: number) => {
          const dn = this.dockSpaces[department].filter((item: any) => item.id == id);
          if (dn.length > 0) {
            deps.push(dn[0].name);
          }
        });
        return deps[0];
      } else {
        const dn = this.dockSpaces.filter((item: any) => item.id == id);
        if (dn.length > 0) {
          return dn[0].name;
        }
      }
    } else {
      ("Na doca");
    }
  }

  filterEnabled(filter: string) {
    const filters = this.envData.system_client.resource.environment.pages[
      this.actualPage
    ]["data_filters"];
    const ft = filters.filter((item: any) => {
      if (item === filter) {
        return true;
      }

      return false;

    });
    return ft.length > 0;
  }

  getActions() {
    this.supportDataService.actions().subscribe((r: any) => {
      this.listAction = r.data.resource[this.actualPage];
    });
  }

  getDepartment() {
    this.supportDataService.departments().subscribe((r: any) => {
      this.departments = r.data.resource;
      this.departments = this.departments.filter((item: any) => {
        return this.account.department_id.indexOf(item.id) > -1;
      })
    });
  }

  disableBtn(status: string, array_status: any) {
    const st = array_status.filter((item: any) => {
      if (status === item || item === "*") {
        return true;
      }

      return false;
    });
    return !(st.length > 0);
  }

  showOnlyIfOperationMatch(element: any, action: any) {
    if (action.show_on_operation.includes(element.resource.operation)) {
      return "show";
    } else {
      return "none";
    }
  }

  changeStatus(item: any, action: any) {
    const preData = {
      id: item.id,
      status: action.status,
    };
    const data: any = Object.assign(
      {
        change_key: action.change_key,
      },
      preData
    );

    if (action.open_dialogue) {
      if (action.component === "confirm") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(data, action);
          }
        });
      }
      if (action.component === "redirection") {
        const dialogRef = this.dialog.open(DirectCartDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          console.log("fechou modal redirecionamento");
          console.log(res);
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }
      if (action.component === "direction") {
        const dialogRef = this.dialog.open(DirectCartDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }
      if (action.component === "linkVehicle") {
        const dialogRef = this.dialog.open(LinkVehicleDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateCheckinStatus(res, action);
          }
        });
      }
      if (action.component === "editKeys") {
        const dialogRef = this.dialog.open(EditKeysDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action
          },
        });

        dialogRef.afterClosed().subscribe((data) => {
          if (data.message) {
            this.snackBar.open(data.message, "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          }
          this.loadCarts();
        });
      }
      if (action.component === "genericDialog") {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }
      if (action.component === "changeOperation") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
          },
        });
        data['changeOperation'] = true;

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(data, action);
          }
        });
      }
    }
  }

  updateStatus(data: any, action: any) {
    this.cartService.update(data).subscribe({
      next: (r: any) => {
        this.loadCarts();
        this.snackBar.open("Status alterado para " + action.name, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      },
      error: (e: any) => {
        console.log("erro afterClosed");
        console.log(e);
      }
    });
  }

  updateCheckinStatus(data: any, action: any) {
    this.checkinService.updateJourney(data).subscribe({
      next: (r: any) => {
        this.loadCarts();
        this.snackBar.open("Jornada alterada com sucesso!", "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      },
      error: (e: any) => {
        console.log("erro afterClosed");
        console.log(e);
      }
    });
  }

  showId(event: any) {
    if(event){
        if (!event || !event.resource.operation_id) return "";
        if (event.resource.operation_id == 1) {
          return event.resource.sid || "";
        } else {
          return event.resource.external_event_id || "";
        }
    } else {
      return '--'
    }

  }

  clearFilter(event?: any) {
    this.dataSource.data = this.dataSourceAll.data;
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
      this.loadCarts();
    }
  }
}
