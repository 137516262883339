<div class="top-bar-filters">
  <div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Business_model.search" | translate:{Default: 'Buscar'} }}</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.name' | translate:{Default: 'Nome'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>
  </div>
</div>

<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "Business_model.actions" | translate:{Default: 'Ações'} }}</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <!-- <button mat-icon-button matTooltip="Abrir" (click)="onOpen($event, 'EDIT', element)">
          <mat-icon>folder_open</mat-icon>
        </button> -->
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="delete($event, element)">{{ "Business_model.delete" | translate:{Default: 'Apagar'} }}</button>
        </mat-menu>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Business_model.name" | translate:{Default: 'Nome'} }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span
            class="ellipsis"
            >{{ element.name }}</span
          >
        </div>
      </td>
    </ng-container>
    <ng-container *ngIf="haveExternalID" matColumnDef="external_id">
      <th mat-header-cell *matHeaderCellDef>{{ "Business_model.external_id" | translate:{Default: 'Identificador externo'} }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span
            class="ellipsis"
            >{{ element.external_id }}</span
          >
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef>{{ "Business_model.carrier_document" | translate:{Default: 'CNPJ'} }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span
            class="ellipsis"
            >{{ element.cnpj | mask: "00.000.000/0000-00" }}</span
          >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="carrier_client.created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Business_model.created_in" | translate:{Default: 'Criado em'} }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.created_at | date }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="
        displayedColumns;
        sticky: !breakpointObserver.isMatched(breakpoints.Handset)
      "
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>


</div>
<!-- Desativado temporariamente até resolver o problema multiempresa para transportadoras-->
<button
  mat-mini-fab
  (click)="onOpen($event, 'ADD')"
  class="mat-fab-bottom-right"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
