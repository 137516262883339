<ng-container *ngIf="notification && topIncidents && topIncidents[0]?.resource?.monitoring; else elseTemplate">
  <mat-toolbar *ngIf="showNotification" style="background-color: #54D959;">
    <span class="padding"><b>{{formatDate(topIncidents[0]?.created_at)}} - {{topIncidents[0]?.name}} <mat-icon class="icon">monitor_heart</mat-icon> Monitorando:</b> <p>{{topIncidents[0].resource.monitoring}}</p></span>
    <span class="spacer"></span>
    <button mat-icon-button (click)="closeNotification()">
      <mat-icon style="color: white;">close</mat-icon>
    </button>
  </mat-toolbar>
</ng-container>

<ng-template #elseTemplate>
  <ng-container *ngIf="notification && topIncidents && topIncidents[0]?.resource?.resolved; else elsePriorityTemplate">
    <mat-toolbar *ngIf="showNotification" style="background-color: #058a00;">
      <span class="padding"><b>{{formatDate(topIncidents[0]?.created_at)}} - {{topIncidents[0]?.name}} <mat-icon class="icon">done</mat-icon> Resolvido:</b> <p>{{topIncidents[0].resource.resolved}}</p></span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="closeNotification()">
        <mat-icon style="color: white;">close</mat-icon>
      </button>
    </mat-toolbar>
  </ng-container>
</ng-template>

<ng-template #elsePriorityTemplate>
  <ng-container *ngIf="notification && topIncidents && topIncidents[0]?.resource?.finded; else elsePriorityTemplate2">
    <mat-toolbar *ngIf="showNotification" style="background-color: #3490FA;">
      <span class="padding"><b>{{formatDate(topIncidents[0]?.created_at)}} - {{topIncidents[0]?.name}} <mat-icon class="icon">build</mat-icon> Encontrado:</b> <p>{{topIncidents[0].resource.finded}}</p></span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="closeNotification()">
        <mat-icon style="color: white;">close</mat-icon>
      </button>
    </mat-toolbar>
  </ng-container>
</ng-template>

<ng-template #elsePriorityTemplate2>
  <ng-container *ngIf="notification && topIncidents && topIncidents[0]?.resource?.investigating">
    <mat-toolbar *ngIf="showNotification" style="background-color: #EBA00B;">
      <span class="padding"><b>{{formatDate(topIncidents[0]?.created_at)}} - {{topIncidents[0]?.name}} <mat-icon class="icon">search</mat-icon> Investigando:</b> <p>{{topIncidents[0].resource.investigating}}</p></span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="closeNotification()">
        <mat-icon style="color: white;">close</mat-icon>
      </button>
    </mat-toolbar>
  </ng-container>
</ng-template>
