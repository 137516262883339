<div class="container">
  <div class="container-table">
    <div *ngIf="!this.account.system_client.resource.environment.trip_hitch" class="status" [style.backgroundColor]="statusColor">
      <span style="text-transform: capitalize" *ngIf="showStatus(); let status">
        {{ status }}
      </span>
    </div>

    <div *ngIf="this.account.system_client.resource.environment.trip_hitch" class="status" [style.backgroundColor]="statusColor">
      <span style="text-transform: capitalize" *ngIf="showTruckerStatus(); let statusTrucker">
        {{statusTrucker}}
      </span>
    </div>

    <div class="header">
      <div
        mat-card-avatar
        class="header-avatar"
        [style.backgroundImage]="imageIcon"
        matRipple
        [matRippleCentered]="true"
        [matRippleDisabled]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="24"
      >
        <i
          *ngIf="!imageIcon"
          _ngcontent-c17=""
          class="material-icons icon-person"
        >person</i>
      </div>
      <h1 mat-mdc-dialog-title>
        {{ data.item.driver?.name || "Sem motorista" }}
      </h1>
    </div>
    <div class="table-content">
      <table>
        <tbody>
          <tr class="tr" *ngFor="let row of driverDetails">
            <td class="td" *ngFor="let col of row">
              <div class="cell">
                <span>
                  {{ col.name }}
                </span>
                <span class="subtitle">{{ _data(col) }}</span>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="last-location footer">
      <mat-icon>room</mat-icon>
      <span>Última atualização da localização: {{(data?.item?.updated_at | date:'dd/MM' | titlecase)}} às {{(data?.item?.updated_at | date:'H:mm' : '-0600' )}}</span>
    </div>
  </div>
  <button mat-mini-fab (click)="cancel()" id="x">X</button>
  <div *ngIf="showMap" id="map" class="container-map"
    leaflet [leafletOptions]="mapOptions" (leafletMapReady)="onMapReady($event)">

  </div>
  <div *ngIf="!showMap" class="no-map">
    <mat-card>
      Dados de localização não disponíveis. Certifique-se que o checkin foi realizado pelo celular do motorista.
    </mat-card>
  </div>
</div>
