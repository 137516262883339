import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SupportDataService } from '../../shared/support-data.service';

@Component({
  selector: 'app-driver-approval-dialog',
  templateUrl: './driver-approval-dialog.component.html',
  styleUrls: ['./driver-approval-dialog.component.scss']
})
export class DriverApprovalDialogComponent implements OnInit {
  parkingType: any;
  translateSource: any;
  constructor(
    private translate: TranslateService,
    public supportData: SupportDataService,
    public dialogRef: MatDialogRef<DriverApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    translate.get('Driver.driverApprovalDialog').subscribe((res: string) => {
      this.translateSource = res;
    });
   }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
