import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ChecklistService } from '../../shared/checklist.service';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DialogComponent } from './../../multiagendamento/dialog/dialog.component';

@Component({
  selector: 'app-denydialog',
  templateUrl: './denydialog.component.html',
  styleUrls: ['./denydialog.component.scss']
})
export class DenydialogComponent {
  value = null;
  denyreasons:any[] = [];
  reasons:any[] = [];
  chkId: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public supportData: SupportDataService,
    public checklistService: ChecklistService,
    public dialogRef: MatDialogRef<DialogComponent>,
  ) { }

  ngOnInit(): void {
    this.getInvalidationReasonsList();
  }

  getInvalidationReasonsList() {
    this.supportData.dinamicTable({ name: 'invalidation_reasons_list' }).subscribe((r: any) => {
      this.denyreasons = r.data.resource;

      if ('checklist_id' in this.data.item.resource) {
        this.chkId = this.data.item.checklist.id
        this.reasons = this.data.item.checklist.resource
      } else {
        this.reasons = this.data.item.checklist.resource
      }
    });
  }

  confirm(){
    this.dialogRef.close({});
    if (this.chkId) {
      // Edita checklist
      const data = {
        id: this.chkId,
        resource: this.reasons
      }
      this.checklistService.update(data).subscribe((r: any) => {
        this.dialogRef.close({action: 'denied'});
      })
    } else {
      const data = {
        resource: this.reasons
      }
      this.checklistService.create(data).subscribe((r: any) => {
        this.chkId = r.id
        this.dialogRef.close({action: 'denied', checklist_id: this.chkId });
      })
    }
  }

  cancel(){
    this.dialogRef.close({action: 'cancel'});
  }

}
