import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimelinesService {

  private endpoint(endpoint:string){
    return `${environment.apiUrl}/${endpoint.replace(/^\//,"")}`;
  }

  constructor(private http: HttpClient) {}

  // Generic function for making HTTP GET requests
  get<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(this.endpoint(endpoint));
  }

  // Generic function for making HTTP POST requests
  post<T>(endpoint: string, body: any): Observable<T> {
    return this.http.post<T>(this.endpoint(endpoint), body);
  }

  // Generic function for making HTTP PUT requests
  put<T>(endpoint: string, body: any): Observable<T> {
    return this.http.put<T>(this.endpoint(endpoint), body);
  }

  // Generic function for making HTTP DELETE requests
  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(this.endpoint(endpoint));
  }
}
