import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { CarrierService } from "../shared/carrier.service";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { DialogAlertComponent } from "../../shared/dialog-alert/dialog-alert.component";
import { MaskUtil } from "../../shared/util/mask.util";
// import { PermissionService } from "../../permission/shared/permission.service";
import { of as observableOf } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
} from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-carrier-detail",
  templateUrl: "./carrier-detail.component.html",
  styleUrls: ["./carrier-detail.component.scss"],
})
export class CarrierDetailComponent implements OnInit {
  @ViewChild(MatSelectionList, {static: true}) private selectionList!: MatSelectionList;
  step: number = 1;
  formSearchCarrier = this.formBuilder.group({
    carrier: [[Validators.required]],
  });
  form = this.formBuilder.group({
    id: [this.data.item.id],
    name: [this.data.item.name, [Validators.required]],
    external_id: [],
    /* external_id: [(this.data.item) ? this.data.item.external_id : null], */
    cnpj: [this.data.item ? this.data.item.cnpj : null],
    active: [true],
  });
  errors: any = {};
  maskUtil = MaskUtil;
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  search = true;
  searchIsNotEmpty = false;
  carriers: any[] = [];
  showInfo = false;
  haveExternalID = false;

  constructor(
    public dialogRef: MatDialogRef<CarrierDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private carrierService: CarrierService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
    const account = JSON.parse(localStorage.getItem("account")!);
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
    this.searchIsNotEmpty = false;
    this.search = false;
    this.carriers = [];
  }

  // onSubmit() {
  //   if (this.data.action === 'EDIT') {
  //     this.carrierService.update(this.form.value, this.data.item.id)
  //       .subscribe(
  //         data => {
  //           this.dialogRef.close(data);
  //         },
  //         err => {
  //           if (err.status === 422) {
  //             this.errors = err.error.errors;
  //           } else {
  //             this.dialog.open(DialogAlertComponent, {
  //               data: {title: err.statusText, message: err.error.message}
  //             });
  //           }
  //         }
  //       );
  //   } else if (this.data.action === 'ADD') {
  //     this.form.get('id_external').setValue(Number((new Date().getTime().toString().slice(6))));
  //     this.form.get('is_casual').setValue(true);
  //     this.carrierService.create(this.form.value)
  //       .subscribe(
  //         data => {
  //           this.dialogRef.close(data);
  //         },
  //         err => {
  //           if (err.status === 422) {
  //             this.errors = err.error.errors;
  //           } else {
  //             this.dialog.open(DialogAlertComponent, {
  //               data: {title: err.statusText, message: err.error.message}
  //             });
  //           }
  //         }
  //       );
  //   }

  // }

  ngOnInit() {
    const account = JSON.parse(localStorage.getItem("account")!);
    this.haveExternalID =
      account.system_client.resource.environment.external_id === true;
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(
      false
    );
    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          console.log(this.searchFilter)
          const options = {
            search: `${this.searchFilter.value}` || "",
          };
          if (this.search) {
            return this.carrierService.indexAll(options);
          } else {
            this.search = true;
            return [];
          }
        }),
        map((response: Response | any) => {
          return response;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        if (data.length > 0) {
          this.searching = false;
          this.carriers = data;
        } else {
          this.carriers = [];
        }
        this.searchIsNotEmpty = true;
      });
  }

  selectCarrier() {
    this.step = 2;
    this.form.patchValue({
      id: this.formSearchCarrier.value.carrier[0].id,
      name: this.formSearchCarrier.value.carrier[0].name,
      cnpj: this.formSearchCarrier.value.carrier[0].cnpj,
    });
  }

  createNewCarrier() {
    this.step = 2;
    this.form.patchValue({ cnpj: this.searchFilter.value });
  }

  addCarrier() {
    let options = { ...this.form.value };
    options.name = options.name.toUpperCase()
    this.carrierService.create(options).subscribe(
      (res: any) => {
        this.dialogRef.close();
      },
      (error: any) => {
        const message = error.error
          ? error.error.message
          : this.translateService.instant('Messages.no_carrier_add', {Default:"Não foi possível adicionar transportadora"});
        this.snackBar.open(message, this.translateService.instant('Business_model.close', {Default:"Fechar"}), { duration: 3000 });
      }
    );
  }
}
