import { AlmoxarifadoComponent } from './almoxarifado/almoxarifado.component';
import { ProviderComponent } from './provider/provider.component';
import { AccountGuardService } from './../account/shared/account-guard.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { EventDepartmentsComponent } from './event-departments/event-departments.component';

const agendamentoRoutes: Routes = [
  {
    path: 'admin/multischedules',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'provider',
        component: ProviderComponent,
        data: {
          title: 'Agendamento Fornecedor',
          search: false,
          refresh: false
        }
      },
      {
        path: 'admin',
        component: AlmoxarifadoComponent,
        data: {
          title: 'Agendamento Almoxarifado',
          search: true,
          refresh: true
        }
      }
    ]
  },
  {
    path: 'admin/scheduling',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    component: EventDepartmentsComponent,
    data: {
      title: 'Agendamento',
      search: true,
      refresh: true
    }
  }
]


@NgModule({
  imports: [
    RouterModule.forRoot(agendamentoRoutes)
  ],
  exports: [RouterModule]
})
export class MultiAgendamentoRoutingModule { }
