import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from "src/app/shared/support-data.service";
import { DockManagementService } from "src/app/docks/shared/dock-management.service";

@Component({
  selector: 'app-schedule-duration-by-weight-dialog',
  templateUrl: './schedule-duration-by-weight-dialog.component.html',
  styleUrls: ['./schedule-duration-by-weight-dialog.component.scss']
})
export class ScheduleDurationByWeightDialogComponent {
  weightRangeList: any[] = [];
  form = this.formBuilder.group({
    weight_range_id: [this.data.weight_range, Validators.required],
    operation_id: [this.data.operation_id],
    duration_id: [this.data.duration_id],
    dock_id: [this.data.dock_id]
  });

  validationMsgs: any[] = [];
  account: any;
  allDataLoaded = false;
  nonEditableField = false;
  docks: any[] = [];
  operations: any[] = [];
  vehicleTypes: any[] = [];
  durations: any[] = [];
  durationFiltered: any[] = [];
  operationsFiltered: any[] = [];
  showDuration = true;


  constructor(
    public dialogRef: MatDialogRef<ScheduleDurationByWeightDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private cdr: ChangeDetectorRef,
    private dockService: DockManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getWeightRange();
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getOperations();
    this.getDuration();
    this.getDocks();
  }

  close() {
    this.dialogRef.close();
  }

  setLimitBySlotSize(value: boolean | null) {
    if (value) {
      this.form.get("limitScheduleSlot")!.setValidators(Validators.required);
    } else {
      this.form.get("limitScheduleSlot")!.clearValidators();
    }
    this.cdr.detectChanges();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      resource: {
        weight_range_id: this.form.value.weight_range_id,
        operation_id: this.form.value.operation_id,
        duration_id: this.form.value.duration_id,
        dock_id: this.form.value.dock_id,
      },
    };

    if (options.id) {
      this.agendamentoService
        .updateScheduleDurationByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    } else {
      this.agendamentoService
        .storeScheduleDurationByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    }
  }

  getOperations() {
    this.supportDataService
      .generic({ name: "operation_type" })
      .subscribe((r: any) => {
        this.operations = r.data.resource;
        this.operationsFiltered = r.data.resource;
        //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true
        this.operations = this.operations.filter(
          (item) => item.use_in_scheduling === true,
        );
        this.operationsFiltered = this.operations.filter(
          (item) => item.use_in_scheduling === true,
        );

        if (this.data.operation_id) {
          this.nonEditableField = true;
        }
      });
  }

  getDuration() {
    this.supportDataService
      .generic({ name: "durations_type" })
      .subscribe((r: any) => {
        console.log(r)
        this.durations = r.data.resource;
      });
  }

  getDocks() {
    this.dockService.index({})
    .subscribe((r: any) => {
      this.docks = r.data;
      console.log(r)
      // if (this.data.docks) {
      //   this.nonEditableField = true;
      // }
    })
  }

  setDurationFilter(operation: number) {
    this.durationFiltered = [];
    this.form.patchValue({ duration_id: "" });
    const ft = this.durations.filter(
      (item: any) => item.operation_id === operation,
    );
    this.durationFiltered = ft;

    if (this.durationFiltered.length > 0) {
      this.showDuration = true;
      
      this.form.patchValue({ duration_id: this.data.duration_id });
      
    } else {
      this.showDuration = false;
      this.form.patchValue({ duration_id: "" });
      this.form.get("duration_id")!.clearValidators();
    }
  }


  setOperationsFilter(department: number) {
    this.operationsFiltered = [];
    this.form.patchValue({ operation_id: "" });
    const ft = this.operations.filter(
      (item: any) => item.department_id === department,
    );
    this.operationsFiltered = ft;
    //Se não for a edição de uma janela, ativa o campo de Operações pois foi informado um Departamento
    if (!this.data.id) {
      this.form.controls.operation_id.enable();
    }

    this.form.patchValue({ operation_id: this.data.operation_id });
  }

  getWeightRange() {
    this.supportDataService
      .generic({ name: "scheduling_weight_range" })
      .subscribe((r: any) => {
        this.weightRangeList = r.data.resource;
      });
  }
}
