import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import {Component, OnInit, Input} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, Validators } from "@angular/forms";

import { AgendamentoService } from "./../shared/agendamento.service";
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { UploadDialogComponent } from "../../events/upload-dialog/upload-dialog.component";
import { DocumentsDialogComponent } from "../../shared/documents-dialog/documents-dialog.component";
import { ShedulingDialogComponent } from "../../scheduling/sheduling-dialog/sheduling-dialog.component";
import { DialogEventDepartmentsComponent } from "../dialog-event-departments/dialog-event-departments.component";

@Component({
  selector: 'app-event-departments',
  templateUrl: './event-departments.component.html',
  styleUrls: ['./event-departments.component.scss']
})
export class EventDepartmentsComponent implements OnInit {
  @Input() accessLevel: String;
  panelOpenState = false;
  account: any;
  today: string;
  times: any = [];
  timeslots: any[];
  definedDay: string;
  qtd_departments: any[];
  dayForm = this.formBuilder.group({
    day: [new Date(), [Validators.required]],
  });

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private agendamentoService: AgendamentoService
  ) {
    this.account = JSON.parse(localStorage.getItem('account') || "{}");
  }

  initTime(){
    this.times = [
      // { "time": "00:00", "l1_data": [] },
      // { "time": "01:00", "l1_data": [] },
      // { "time": "02:00", "l1_data": [] },
      // { "time": "03:00", "l1_data": [] },
      // { "time": "04:00", "l1_data": [] },
      // { "time": "05:00", "l1_data": [] },
      // { "time": "06:00", "l1_data": [] },
      // { "time": "07:00", "l1_data": [] },
      { "time": "08:00", "l1_data": [] },
      { "time": "09:00", "l1_data": [] },
      { "time": "10:00", "l1_data": [] },
      { "time": "11:00", "l1_data": [] },
      { "time": "12:00", "l1_data": [] },
      { "time": "13:00", "l1_data": [] },
      { "time": "14:00", "l1_data": [] },
      { "time": "15:00", "l1_data": [] },
      { "time": "16:00", "l1_data": [] },
      { "time": "17:00", "l1_data": [] },
      { "time": "18:00", "l1_data": [] },
      { "time": "19:00", "l1_data": [] },
      { "time": "20:00", "l1_data": [] },
      { "time": "21:00", "l1_data": [] },
      { "time": "22:00", "l1_data": [] },
      { "time": "23:00", "l1_data": [] }
    ];
  }

  ngOnInit() {
    this.today = moment().format("YYYY-MM-DD");
    this.definedDay = this.today;
    this.getScheduling();
  }

  switchDay() {
    this.definedDay = this.dayForm.value.day.toISOString().slice(0, 10);

    this.getScheduling();
  }

  uploadDialog() {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: "900px",
      data: {fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getScheduling();
    });
  }

  schedulingDialog(time?: number|null, event?: any){
    const dialogRef = this.dialog.open(ShedulingDialogComponent, {
      width: "900px",
      data: {
        definedDay: this.definedDay,
        time,
        event
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getScheduling();
    });
  }

  getScheduling() {
    this.initTime();

    let options = {
      date: this.definedDay,
      departments: [this.account.department_id],
      is_carrier: this.account.is_carrier,
      carrier_id: this.account.carrier_id ? this.account.carrier_id : null
    };
    this.agendamentoService.scheduling_time(options).subscribe((result:any) => {
      this.qtd_departments = result.qtd_departments;
      let newSchedule = this.times.map((item:any) => {
        result.schedules.filter((i2:any) => {
          if(i2.time === item.time) {
            item.l1_data = i2.l1_data;
          }
        })
        return item;
      });
      this.timeslots = newSchedule;
      console.log(this.timeslots);
    })

  }

  getNameQtd(obj:any, idx: number){
    if(obj.l1_data.length > 0){
      if(obj.l1_data[idx]) {
        return `${obj.l1_data[idx]['l2_data'].length} - ${obj.l1_data[idx]['name']}`
      }
    }
    return '';
  }

  getColor(obj:any, idx: number){
    if(obj.l1_data.length > 0){
      if(obj.l1_data[idx] && obj.l1_data[idx]['name'] == "PP 5") {
          return "bagde-blue";
      } else {
        return "bagde-green";
      }
    } else {
      return '';
    }
  }

  openDialog(data:any): void {

    const dialogRef = this.dialog.open(DialogEventDepartmentsComponent, {
      width: '800px',
       data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  cancelDialog(data:any): void {
    let id = data.event_id;
    this.agendamentoService.cancel(id).subscribe((r: any) => {
      this.snackBar.open("Agendamento Cancelado Com Sucesso", "OK", {
        duration: 5000,
      });
      this.getScheduling();
    }, (e: any) => {
      this.snackBar.open("Houve um erro ao cancelar agendamento", "Entendi", {
        duration: 5000,
      });
      console.log(e)
    })
  }

  showDocuments(event:any) {
    const dialogRef = this.dialog.open(DocumentsDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(event)),
        origin: 'scheduling'
      },
    });
  }

  getTime(time: number){
    this.schedulingDialog(time);
  }

  updateScheduling(event_id:number, status:string){
    let dados = {
      event_id,
      status
    }
    this.agendamentoService.updateScheduling(dados).subscribe((r:any)=>{

      if(r.status == 200) {
        this.snackBar.open(r.message, "OK", {
          duration: 5000,
        });
        this.getScheduling();
      }

      if( r.error){
        this.dialog.open(DialogAlertComponent, {
          data: {title: 'Atenção!', message: r.error.message}
        });
      }
    });
  }

  getClassStatus(status: string){
    if(status == 'waiting'){
      return "span-bagde-yellow";
    }

    if(status == 'confirmed'){
      return "span-bagde-green";
    }

    if(status == 'denied'){
      return "span-bagde-red";
    }

    if(status == 'closed'){
      return "span-bagde-gray";
    }

    return "";

  }

}
