<h3 class="mat-align-center title">{{ "Business_model.block_dock_schedule" | translate:{Default: "Bloqueio de Agendamento de Doca"} }}
</h3>

<div mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="form">
      <mat-form-field appearance="outline" [style.display]="departments.length == 1 ? 'none' : 'show'">
        <mat-label>{{ "Business_model.client" | translate:{Default: "Cliente"} }}</mat-label>
        <mat-select [placeholder]="'Business_model.choose_client' | translate:{Default: 'Escolha o Cliente'}" formControlName="department_id" (selectionChange)="departmentChange()">
          <mat-option [value]="item.id" *ngFor="let item of departments">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Business_model.dock" | translate:{Default: "Doca"} }}</mat-label>
        <mat-select [placeholder]="'Messages.choose_dock' | translate:{Default: 'Escolha a doca'}" formControlName="dock_id" [disabled]="nonEditableField">
          <mat-option [value]="item.id" *ngFor="let item of docks">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Business_model.operation" | translate:{Default: "Operação"} }}</mat-label>
        <mat-select multiple [placeholder]="'Messages.choose_operation' | translate:{Default: 'Escolha a operação'}" formControlName="operation_id" (selectionChange)="operationChange()" [disabled]="nonEditableField">
          <mat-option [value]="item.id" *ngFor="let item of operationsFiltered">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="showSuboperation">
        <mat-label>{{ "Business_model.suboperation" | translate:{Default: "Suboperação"} }}</mat-label>
        <mat-select multiple [placeholder]="'Messages.choose_suboperation' | translate:{Default: 'Escolha a suboperação'}" formControlName="suboperation_id" [disabled]="nonEditableField">
          <mat-option [value]="item.id" *ngFor="let item of suboperationsFiltered">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox class="tkg-checkbox" formControlName="allday" (click)="changeAllDayBlock()">Bloquear o dia todo</mat-checkbox>
      <br>
      <mat-form-field appearance="outline" *ngIf="!form.get('allday')!.value">
        <mat-label>{{ "Business_model.start" | translate:{Default: "Início"} }}</mat-label>
        <mat-select [placeholder]="'Messages.choose_window_start' | translate:{Default: 'Escolha o início da janela'}" formControlName="start">
          <mat-option value="00:00">00:00</mat-option>
          <mat-option value="00:15">00:15</mat-option>
          <mat-option value="00:30">00:30</mat-option>
          <mat-option value="00:45">00:45</mat-option>
          <mat-option value="01:00">01:00</mat-option>
          <mat-option value="01:15">01:15</mat-option>
          <mat-option value="01:30">01:30</mat-option>
          <mat-option value="01:45">01:45</mat-option>
          <mat-option value="02:00">02:00</mat-option>
          <mat-option value="02:15">02:15</mat-option>
          <mat-option value="02:30">02:30</mat-option>
          <mat-option value="02:45">02:45</mat-option>
          <mat-option value="03:00">03:00</mat-option>
          <mat-option value="03:15">03:15</mat-option>
          <mat-option value="03:30">03:30</mat-option>
          <mat-option value="03:45">03:45</mat-option>
          <mat-option value="04:00">04:00</mat-option>
          <mat-option value="04:15">04:15</mat-option>
          <mat-option value="04:30">04:30</mat-option>
          <mat-option value="04:45">04:45</mat-option>
          <mat-option value="05:00">05:00</mat-option>
          <mat-option value="05:15">05:15</mat-option>
          <mat-option value="05:30">05:30</mat-option>
          <mat-option value="05:45">05:45</mat-option>
          <mat-option value="06:00">06:00</mat-option>
          <mat-option value="06:15">06:15</mat-option>
          <mat-option value="06:30">06:30</mat-option>
          <mat-option value="06:45">06:45</mat-option>
          <mat-option value="07:00">07:00</mat-option>
          <mat-option value="07:15">07:15</mat-option>
          <mat-option value="07:30">07:30</mat-option>
          <mat-option value="07:45">07:45</mat-option>
          <mat-option value="08:00">08:00</mat-option>
          <mat-option value="08:15">08:15</mat-option>
          <mat-option value="08:30">08:30</mat-option>
          <mat-option value="08:45">08:45</mat-option>
          <mat-option value="09:00">09:00</mat-option>
          <mat-option value="09:15">09:15</mat-option>
          <mat-option value="09:30">09:30</mat-option>
          <mat-option value="09:45">09:45</mat-option>
          <mat-option value="10:00">10:00</mat-option>
          <mat-option value="10:15">10:15</mat-option>
          <mat-option value="10:30">10:30</mat-option>
          <mat-option value="10:45">10:45</mat-option>
          <mat-option value="11:00">11:00</mat-option>
          <mat-option value="11:15">11:15</mat-option>
          <mat-option value="11:30">11:30</mat-option>
          <mat-option value="11:45">11:45</mat-option>
          <mat-option value="12:00">12:00</mat-option>
          <mat-option value="12:15">12:15</mat-option>
          <mat-option value="12:30">12:30</mat-option>
          <mat-option value="12:45">12:45</mat-option>
          <mat-option value="13:00">13:00</mat-option>
          <mat-option value="13:15">13:15</mat-option>
          <mat-option value="13:30">13:30</mat-option>
          <mat-option value="13:45">13:45</mat-option>
          <mat-option value="14:00">14:00</mat-option>
          <mat-option value="14:15">14:15</mat-option>
          <mat-option value="14:30">14:30</mat-option>
          <mat-option value="14:45">14:45</mat-option>
          <mat-option value="15:00">15:00</mat-option>
          <mat-option value="15:15">15:15</mat-option>
          <mat-option value="15:30">15:30</mat-option>
          <mat-option value="15:45">15:45</mat-option>
          <mat-option value="16:00">16:00</mat-option>
          <mat-option value="16:15">16:15</mat-option>
          <mat-option value="16:30">16:30</mat-option>
          <mat-option value="16:45">16:45</mat-option>
          <mat-option value="17:00">17:00</mat-option>
          <mat-option value="17:15">17:15</mat-option>
          <mat-option value="17:30">17:30</mat-option>
          <mat-option value="17:45">17:45</mat-option>
          <mat-option value="18:00">18:00</mat-option>
          <mat-option value="18:15">18:15</mat-option>
          <mat-option value="18:30">18:30</mat-option>
          <mat-option value="18:45">18:45</mat-option>
          <mat-option value="19:00">19:00</mat-option>
          <mat-option value="19:15">19:15</mat-option>
          <mat-option value="19:30">19:30</mat-option>
          <mat-option value="19:45">19:45</mat-option>
          <mat-option value="20:00">20:00</mat-option>
          <mat-option value="20:15">20:15</mat-option>
          <mat-option value="20:30">20:30</mat-option>
          <mat-option value="20:45">20:45</mat-option>
          <mat-option value="21:00">21:00</mat-option>
          <mat-option value="21:15">21:15</mat-option>
          <mat-option value="21:30">21:30</mat-option>
          <mat-option value="21:45">21:45</mat-option>
          <mat-option value="22:00">22:00</mat-option>
          <mat-option value="22:15">22:15</mat-option>
          <mat-option value="22:30">22:30</mat-option>
          <mat-option value="22:45">22:45</mat-option>
          <mat-option value="23:00">23:00</mat-option>
          <mat-option value="23:15">23:15</mat-option>
          <mat-option value="23:30">23:30</mat-option>
          <mat-option value="23:45">23:45</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!form.get('allday')!.value">
        <mat-label>{{ "Business_model.end" | translate:{Default: "Fim"} }}</mat-label>
        <mat-select [placeholder]="'Messages.choose_window_end' | translate:{Default: 'Escolha o final da janela'}" formControlName="end">
          <mat-option value="00:00">00:00</mat-option>
          <mat-option value="00:15">00:15</mat-option>
          <mat-option value="00:30">00:30</mat-option>
          <mat-option value="00:45">00:45</mat-option>
          <mat-option value="01:00">01:00</mat-option>
          <mat-option value="01:15">01:15</mat-option>
          <mat-option value="01:30">01:30</mat-option>
          <mat-option value="01:45">01:45</mat-option>
          <mat-option value="02:00">02:00</mat-option>
          <mat-option value="02:15">02:15</mat-option>
          <mat-option value="02:30">02:30</mat-option>
          <mat-option value="02:45">02:45</mat-option>
          <mat-option value="03:00">03:00</mat-option>
          <mat-option value="03:15">03:15</mat-option>
          <mat-option value="03:30">03:30</mat-option>
          <mat-option value="03:45">03:45</mat-option>
          <mat-option value="04:00">04:00</mat-option>
          <mat-option value="04:15">04:15</mat-option>
          <mat-option value="04:30">04:30</mat-option>
          <mat-option value="04:45">04:45</mat-option>
          <mat-option value="05:00">05:00</mat-option>
          <mat-option value="05:15">05:15</mat-option>
          <mat-option value="05:30">05:30</mat-option>
          <mat-option value="05:45">05:45</mat-option>
          <mat-option value="06:00">06:00</mat-option>
          <mat-option value="06:15">06:15</mat-option>
          <mat-option value="06:30">06:30</mat-option>
          <mat-option value="06:45">06:45</mat-option>
          <mat-option value="07:00">07:00</mat-option>
          <mat-option value="07:15">07:15</mat-option>
          <mat-option value="07:30">07:30</mat-option>
          <mat-option value="07:45">07:45</mat-option>
          <mat-option value="08:00">08:00</mat-option>
          <mat-option value="08:15">08:15</mat-option>
          <mat-option value="08:30">08:30</mat-option>
          <mat-option value="08:45">08:45</mat-option>
          <mat-option value="09:00">09:00</mat-option>
          <mat-option value="09:15">09:15</mat-option>
          <mat-option value="09:30">09:30</mat-option>
          <mat-option value="09:45">09:45</mat-option>
          <mat-option value="10:00">10:00</mat-option>
          <mat-option value="10:15">10:15</mat-option>
          <mat-option value="10:30">10:30</mat-option>
          <mat-option value="10:45">10:45</mat-option>
          <mat-option value="11:00">11:00</mat-option>
          <mat-option value="11:15">11:15</mat-option>
          <mat-option value="11:30">11:30</mat-option>
          <mat-option value="11:45">11:45</mat-option>
          <mat-option value="12:00">12:00</mat-option>
          <mat-option value="12:15">12:15</mat-option>
          <mat-option value="12:30">12:30</mat-option>
          <mat-option value="12:45">12:45</mat-option>
          <mat-option value="13:00">13:00</mat-option>
          <mat-option value="13:15">13:15</mat-option>
          <mat-option value="13:30">13:30</mat-option>
          <mat-option value="13:45">13:45</mat-option>
          <mat-option value="14:00">14:00</mat-option>
          <mat-option value="14:15">14:15</mat-option>
          <mat-option value="14:30">14:30</mat-option>
          <mat-option value="14:45">14:45</mat-option>
          <mat-option value="15:00">15:00</mat-option>
          <mat-option value="15:15">15:15</mat-option>
          <mat-option value="15:30">15:30</mat-option>
          <mat-option value="15:45">15:45</mat-option>
          <mat-option value="16:00">16:00</mat-option>
          <mat-option value="16:15">16:15</mat-option>
          <mat-option value="16:30">16:30</mat-option>
          <mat-option value="16:45">16:45</mat-option>
          <mat-option value="17:00">17:00</mat-option>
          <mat-option value="17:15">17:15</mat-option>
          <mat-option value="17:30">17:30</mat-option>
          <mat-option value="17:45">17:45</mat-option>
          <mat-option value="18:00">18:00</mat-option>
          <mat-option value="18:15">18:15</mat-option>
          <mat-option value="18:30">18:30</mat-option>
          <mat-option value="18:45">18:45</mat-option>
          <mat-option value="19:00">19:00</mat-option>
          <mat-option value="19:15">19:15</mat-option>
          <mat-option value="19:30">19:30</mat-option>
          <mat-option value="19:45">19:45</mat-option>
          <mat-option value="20:00">20:00</mat-option>
          <mat-option value="20:15">20:15</mat-option>
          <mat-option value="20:30">20:30</mat-option>
          <mat-option value="20:45">20:45</mat-option>
          <mat-option value="21:00">21:00</mat-option>
          <mat-option value="21:15">21:15</mat-option>
          <mat-option value="21:30">21:30</mat-option>
          <mat-option value="21:45">21:45</mat-option>
          <mat-option value="22:00">22:00</mat-option>
          <mat-option value="22:15">22:15</mat-option>
          <mat-option value="22:30">22:30</mat-option>
          <mat-option value="22:45">22:45</mat-option>
          <mat-option value="23:00">23:00</mat-option>
          <mat-option value="23:15">23:15</mat-option>
          <mat-option value="23:30">23:30</mat-option>
          <mat-option value="23:45">23:45</mat-option>
          <mat-option value="23:59">23:59</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Messages.just_goups" | translate:{Default: "Válido apenas para o(s) grupo(s)"} }}</mat-label>
        <mat-select formControlName="groups" multiple>
          <mat-option *ngFor="let item of groups" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-radio-group aria-label="Select an option" formControlName="block_type" (change)="changeBlockType()">
        <mat-radio-button value="1">{{ "Time.month_day" | translate:{Default: "Dia do mês"} }}</mat-radio-button>
        <mat-radio-button value="2">{{ "Time.week_day" | translate:{Default: "Dia da semana:"} }}</mat-radio-button>
        <mat-radio-button value="3">{{ "Time.every_day" | translate:{Default: "Todos os dias"} }}</mat-radio-button>
      </mat-radio-group>
      <div *ngIf="form.get('block_type')!.value == 1">
        <mat-form-field appearance="outline" fxFlex="70">
          <mat-label>{{ "Time.month_day" | translate:{Default: "Dia do mês"} }}</mat-label>
          <input matInput [matChipInputFor]="chipGrid" [matDatepicker]="picker"  formControlName="datepicker" [min]="startDatePicker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button mat-button color="primary" (click)="catchDate()" fxFlex="30">
          <span class="material-icons"> add </span>
          {{ "Business_model.add" | translate:{Default: 'Adicionar'} }}
        </button>
        <mat-hint ngClass="mat-error">
          <div *ngIf="this.msgErrorBlockedDay == true" style="color: red">{{ "Messages.same_date" | translate:{Default: 'Data já adicionada.'} }}</div>
          <br>
        </mat-hint>
        <mat-hint ngClass="mat-error">
          <div *ngIf="this.oldDate == true" style="color: red">{{ "Messages.old_date" | translate:{Default: 'Há datas no bloqueio que foram ultrapassadas, favor remover.'} }}</div>
          <br>
        </mat-hint>
        <mat-chip-grid #chipGrid aria-label="Enter keywords" formControlName="day">
          <mat-chip-row *ngFor="let blockedDay of monthDayBlocked" (removed)="removeDate(blockedDay)">
            {{ blockedDay | date: 'dd/MM/yyyy'}}
            <button matChipRemove aria-label="'remove ' + blockedDay">
              <mat-icon [ngClass]="{'red-icon': PastDate(blockedDay)}">cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
      </div>

      <div class="flex-container padding-16-top" *ngIf="form.get('block_type')!.value == 2">
        <mat-button-toggle-group
          #weekGroup="matButtonToggleGroup"
          (change)="onChangeWeek(weekGroup.value)"
          multiple
        >
          <mat-button-toggle value="1">{{ "Time.monday_cipher" | translate:{Default: "S"} }}</mat-button-toggle>
          <mat-button-toggle value="2">{{ "Time.tuesday_cipher" | translate:{Default: "T"} }}</mat-button-toggle>
          <mat-button-toggle value="3">{{ "Time.wednesday_cipher" | translate:{Default: "Q"} }}</mat-button-toggle>
          <mat-button-toggle value="4">{{ "Time.thursday_cipher" | translate:{Default: "Q"} }}</mat-button-toggle>
          <mat-button-toggle value="5">{{ "Time.friday_cipher" | translate:{Default: "S"} }}</mat-button-toggle>
          <mat-button-toggle value="6">{{ "Time.saturday_cipher" | translate:{Default: "S"} }}</mat-button-toggle>
          <mat-button-toggle value="7">{{ "Time.sunday_cipher" | translate:{Default: "D"} }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </form>
    <div mat-dialog-actions class="tkg-dialog-actions-end">
      <button mat-stroked-button color="warn" (click)="close()">{{ "Business_model.cancel" | translate:{Default: "Cancelar"} }}</button>
      <button mat-flat-button color="primary" (click)="onSubmit()"
        [disabled]="!form.valid">{{ "Business_model.save" | translate:{Default: "Salvar"} }}
      </button>
    </div>
  </div>
</div>



