// import * as _ from "lodash";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
// import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";

import { Dock } from "../dock";
// import { Timeslot } from "./../timeslot";
import { DialogComponent } from "./../dialog/dialog.component";
import { AgendamentoService } from "./../shared/agendamento.service";
import { SupportDataService } from './../../shared/support-data.service';
// import { WebsocketService } from './../../shared/websocket/websocket.service';
import { UploadDialogComponent } from "../../events/upload-dialog/upload-dialog.component"
// import { DialogAlertComponent } from './../../shared/dialog-alert/dialog-alert.component';

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit, AfterViewInit {
  @Input() accessLevel: string;
  mode: string;
  offset = (moment().utcOffset() / 60);
  account:any = null;
  timeslots: any[] = [];
  schedules:any[] = [];
  events:any[] = [];
  dock: Dock;
  dashboardHeight:string;
  today:any;
  definedDay:any;
  dayForm = this.formBuilder.group({
    day: [new Date(), [Validators.required]],
  });
  operations: any = [];
  operation_id: null;
  suboperation_id: null;
  suboperations: any = [];
  status: any = [];
  status_id: null;
  filterOperationSelected: UntypedFormControl = new UntypedFormControl();
  filterStatusSelected: UntypedFormControl = new UntypedFormControl();
  filterSubOperationSelected: UntypedFormControl = new UntypedFormControl();

  constructor(
    public dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService
  ) {
    this.account = JSON.parse(localStorage.getItem('account') || "{}");
  }


  download(): void {
    let options = { day: this.definedDay, accessLevel: this.accessLevel };
    let day = moment(this.definedDay, 'YYYY-MM-DD').format('DD/MM/YYYY');
    let title = `Relatório ${day}.csv`
    this.agendamentoService.download(options)
      .subscribe(
        (val) => {
          this.createFileFromBlob(val, 'report.xls');
        },
        response => {
          this.createFileFromBlob(response.error.text, title)
        },
        () => {
          console.log('erro')
        });
  }

  createFileFromBlob(file:any, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob(["\ufeff" + file], { type: 'text/csv; charset=utf-18' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  switchDay() {
    this.getSchedules(this.dayForm.value.day.toISOString().slice(0, 10));
    this.definedDay = this.dayForm.value.day.toISOString().slice(0, 10);
    this.getTimeSlots();
  }

  openDialog(schedule:any): void {

      let is_past = false;

      const event = this.events.find((item:any) => item.id == schedule.id) || schedule;

      let resource = event.resource ? event.resource : {};
      let checklist = event.checklist;
      let documents = resource.documents;

      delete event['checklist'];

      let data = {
        ...resource,
        ...event,
        checklist,
      };

      delete data['resource'];
      delete data['documents'];

      data.documents = documents;

      if (this.definedDay == moment().format("YYYY-MM-DD")) {
        is_past = moment(data.start_at).unix() - moment().unix() < 0;
      }

      if (this.accessLevel === "carrier") {
        if (data.status === "available") {
          if (is_past) {
            this.snackBar.open("Escolha uma data futura", "Entendi", {
              duration: 5000,
            });
          } else {
            this.createDialog(data, this.accessLevel, "create");
          }
        }
        if (data.status === "waiting") {
          this.editDialog(data, this.accessLevel);
        }
        if (data.status === "confirmed" || data.status === "carrier_confirmed") {
          this.editDialog(data, this.accessLevel);
        }
        if (data.status === "denied" || data.status === "carrier_denied") {
          this.editDialog(data, this.accessLevel);
        }
      } else {
        if (data.status === "available") {
          this.createDialog(data, this.accessLevel, "create");
        } else {
          this.editDialog(data, this.accessLevel);
        }
      }

  }

  changeStatus(schedule:any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      data: {
        schedule,
        definedDay: this.definedDay,
        action: "change",
        timeslots: this.timeslots,
      },
    });

    dialogRef.afterClosed().subscribe(({ status, action }) => {
      if (action === "change") {
        this.changeScheduleStatus(schedule, status);
      }
    });
  }

  editDialog(schedule:any, accessLevel:any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      data: {
        schedule,
        checklist: schedule.checklist,
        accessLevel,
        definedDay: this.definedDay,
        action: "edit",
        timeslots: this.timeslots
      },
    });

    dialogRef.afterClosed().subscribe(({ data, action }) => {
      if (action === "cancel") {
        this.getSchedules(this.definedDay);
      }
      if (action == 'denied') {
        this.snackBar.open("Agendamento negado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
      if (action == 'confirmed') {
        this.snackBar.open("Agendamento confirmado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
      if (action === "edit") {
        this.snackBar.open("Agendamento realizado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
      if (action === 'dialog-close'){
        this.getSchedules(this.definedDay);
      }
    });
  }

  showDialog(schedule:any, accessLevel:any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      disableClose: true,
      data: {
        schedule,
        accessLevel,
        definedDay: this.definedDay,
        action: "show",
        timeslots: this.timeslots,
      },
    });
  }

  showPlates(item:any) {
    if(item.board_cart && item.board_horse){
      return `${item.board_horse} | ${item.board_cart}`
    }
    if(item.board_cart && !item.board_horse) {
      return `${item.board_cart}`
    }
    if(!item.board_cart && item.board_horse) {
      return `${item.board_horse}`
    }
    return "";
  }

  createDialog(schedule?:any, accessLevel?:any, action?:any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      data: {
        schedule,
        accessLevel,
        definedDay: this.definedDay,
        action: action,
        timeslots: this.timeslots
      },
    });

    dialogRef.afterClosed().subscribe(({ action }) => {
      if (action === "create") {
        this.snackBar.open("Agendamento realizado com sucesso", "OK", {
          duration: 5000,
        });
      }
      if (action === "change") {
        this.snackBar.open("Agendamento atualizado com sucesso", "OK", {
          duration: 5000,
        });
      }

      this.getSchedules(this.definedDay);

    });
  }

  ngOnInit() {
    this.getOperations();
    this.getScheduleStatus();
    this.getSuboperations();
    this.today = moment().format("YYYY-MM-DD");
    this.definedDay = this.today;
    this.getTimeSlots();
  }

  getOperations() {
    this.supportDataService.operation().subscribe((r) => {
      this.operations = r.data.resource || [];
    });
  }

  getScheduleStatus() {
    const options = {
      name: 'schedule_status'
    }
    this.supportDataService.generic(options).subscribe((r: any) => {
      this.status = r.data.resource;
    });
  }

  getSuboperations() {
    this.supportDataService.suboperation().subscribe((r) => {
      this.suboperations = r.data.resource || [];
    });
  }

  setFilterOperation(id:any) {
    this.operation_id = id;
    this.suboperation_id = null;
    this.switchDay()
  }

  setFilterSuboperation(id:any) {
    this.suboperation_id = id;
    this.switchDay()
  }

  setFilterStatus(id:any) {
    this.status_id = id;
    this.switchDay()
  }

  ngAfterViewInit() {

  }

  cancelSchedule(id:string|number) {
    this.agendamentoService.cancel(id).subscribe(() => {
      this.getSchedules(this.definedDay);
      this.snackBar.open("Cancelamento realizado com sucesso", "Ok", {
        duration: 5000,
      });
    });
  }

  changeScheduleStatus(schedule:any, status:string) {
    let resource = schedule;
    resource.status = status;
    let id = schedule.id;
  }

  getTimeSlots() {
    const options = {
      day: this.definedDay,
      operation_id: this.operation_id ? this.operation_id : null,
      suboperation_id: this.suboperation_id ? this.suboperation_id : null,
    }
    this.agendamentoService.time_slots(options).subscribe((res: any) => {
      this.timeslots = res;
      this.dashboardHeight = `${
        (this.timeslots.length + 1) * 50 + this.timeslots.length
      }px`;

      this.getSchedules(this.definedDay);
    });
  }

  getSchedules(day:any) {
    let options = {
      day,
      operation_id: this.operation_id,
      suboperation_id: this.suboperation_id,
      status_id: this.status_id,
      offset: this.offset
    };

    this.agendamentoService.index(options).subscribe((data: any[]) => {
      let items:any[] = [];
      this.events = data;
      data.forEach((item:any) => {
        if (this.accessLevel != 'carrier' || (this.account.is_carrier && this.account.carriers.length > 1) || this.account.carriers.includes(item.carrier_id))
          items.push(this.generateSchedules(item));
      });
      // const schedules = _.flatten(items);
      const schedules = [].concat(...items);
      let newSchedules:any[] = [];
      this.timeslots.forEach((ts:any) => {
        let results:any[] = [];

        if (schedules.length > 0) {
          schedules.forEach((item:any) => {
            if (moment(item.schedule_time).format("HH:mm") == moment(ts.ts).format("HH:mm")) {
              results.push(item);
            }
          });
        }

        let emptySchedule = {
          id: null,
          event_id: null,
          dock_id: null,
          dock_name: null,
          start_at: ts.ts,
          end_at: null,
          name: null,
          driver_name: null,
          carrier_name: null,
          operation_id: this.operation_id,
          suboperation: this.suboperation_id,
          // status: ts.blocked ? 'blocked' : "available",
          status: "available",
          // blocked: ts.blocked
        };

        if (results.length > 0) {
          let schedulesList:any[] = [];
          results.forEach((result:any) => {
            result.documents = result.documents || [];
            schedulesList.push(result);
          })
          newSchedules.push(schedulesList);
        } else {
          let newSchedule = [emptySchedule];
          newSchedules.push(newSchedule);
        }
      });
      this.schedules = newSchedules;
    });
  }

  // TODO: Verificar e Remover
  generateIntervals(start_interval:moment.DurationInputArg1, end_interval:moment.DurationInputArg1) {
    let start = moment.duration(start_interval).asMinutes();
    let end = moment.duration(end_interval).asMinutes()

    let intervals = [];

    if (end == 0) {
      intervals.push(moment.utc().startOf('day').add({ minutes: start }).format('H:mm'))
    } else {
      for (let i = start; i <= end; i += 30) {
        let time = moment.utc().startOf('day').add({ minutes: i }).format('H:mm');
        intervals.push(time);
      }
    }

    return intervals;
  }

  generateSchedules(schedule:any) {
    let schedules:any[] = [];
    let resource = schedule.resource;

    let day = moment(resource.start_at).format('YYYY-MM-DD');
    let start = moment(resource.start_at).format('HH:mm');
    let end = moment(resource.end_at ? resource.end_at : resource.start_at).format('HH:mm');

    let times = this.generateIntervals(start, end);

    times.forEach((time: any) => {
      let newSchedule = {
        ...schedule,
        ...resource
      }

      delete newSchedule['resource'];

      newSchedule['schedule_time'] = moment(`${day} ${time}`, 'YYYY-MM-DD HH:mm').toISOString();

      schedules.push(newSchedule);
    });

    return schedules;
  }

  uploadDialog(){


    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: "900px",
      data: {fileType: '.csv'}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getSchedules(this.definedDay);
    });
  }
}
