import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxTimelineModule } from '@frxjs/ngx-timeline';

import { TimelinesRoutingModule } from './timelines.routing';
import { JourneyStatusTimelineComponent } from './journey-status-timeline/journey-status-timeline.component';
import { JourneyStatusTimelineDialogComponent } from './journey-status-timeline/journey-status-timeline-dialog/journey-status-timeline-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { MatDialog } from '@angular/material/dialog';
import JourneyStatusTimelineRender from './journey-status-timeline/journey-status-timeline-render';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    JourneyStatusTimelineComponent,
    JourneyStatusTimelineDialogComponent
  ],
  exports:[
    JourneyStatusTimelineComponent,
    JourneyStatusTimelineDialogComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    NgxTimelineModule,
    TimelinesRoutingModule
  ],
  providers:[
    {
      provide: APP_INITIALIZER,
      useFactory: JourneyStatusTimelineRender.initializer,
      multi: true,
      deps: [MatDialog, Router]
    }
  ]
})
export class TimelinesModule { }
