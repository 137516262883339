<div class="box-container example-container">
  <table
    mat-table
    [dataSource]="dataSource"
    class="report-table mat-elevation-z8 mat-table"
    matSort
    [style.height]="!dataSource.data.length ? '100%' : 'auto'"
  >
    <div *ngFor="let col of columns">
      <ng-container
        [sticky]="stickyStart(col.data)"
        [stickyEnd]="stickyEnd(col.data)"
        matColumnDef="{{ col.data }}"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ col.title }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[col.data] }}</td>
      </ng-container>
    </div>
    <tr class="mat-row empty" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="columns.length">
        {{ "Messages.no_result" | translate: { Default: "Sem resultados" } }}
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
