<div class="top-bar-filters">
  <!-- <meta http-equiv="refresh" content="10" /> -->
  <!-- <button mat-raised-button (click)="modalPainelStatus()">
    Ver Painel de Status
  </button> -->

  <div>
    <div>
    <mat-form-field appearance="outline">
      <mat-label>Área</mat-label>
      <mat-select [(ngModel)]="value" (selectionChange)="filterDockSpace($event.value)">
        <mat-option [value]="">Limpar filtro</mat-option>
        <mat-divider></mat-divider>
        <mat-option *ngFor="let item of departments" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
    <!-- card Notificação -->
    <mat-card
      *ngFor="let notify of notifications"
    >
      <div class="card_top">
        <mat-card-header>

        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-orange">
          <i class="material-icons icon_cor36"> schedule </i>
        </div>
      </div>
      <mat-card-content>
        <div
          class="mat-display-4-2"
          style="font-size: 20px !important; margin-bottom: 40px"
        >
        {{ notify.resource.message }}

        </div>
      </mat-card-content>
    </mat-card>
</div>

  <div fxLayout="row wrap" fxLayoutGap="25px">
    <!-- card Tempo Médio Permanência -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Tempo Médio Permanência</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-orange">
          <i class="material-icons icon_cor36"> schedule </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2">
          {{ media_permanencia ? media_permanencia : "0 h" }}
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Performance -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Performance</div>
        </mat-card-header>
        <div class="fill"></div>
      </div>
      <mat-card-content>
        <div
          class="mat-display-4-2"
          style="margin-top: -30px !important; margin-bottom: -80px !important"
        >
          <div echarts [options]="chartOptionPerformace" class="chart-1"></div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Qtd. Veículos em Operação -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Qtd. Veículos em Operação</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions_car </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2">
          {{ total_veiculos_operacao ? total_veiculos_operacao : "0" }}
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Qtd. Veículos Finalizados -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Qtd. Veículos Finalizados</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-orange">
          <i class="material-icons icon_cor36"> directions_car </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2">
          {{ total_veiculos_finalizados ? total_veiculos_finalizados : "0" }}
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Período -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Período</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-blue">
          <i class="material-icons icon_cor45"> schedule </i>
        </div>
      </div>
      <mat-card-content>
        <form [formGroup]="dateForm">
          <div class="mat-display-filter">
            <div>
              <mat-form-field appearance="outline">
                <mat-label>De</mat-label>
                <input
                  matInput
                  [matDatepicker]="pickerStart"
                  formControlName="dayStart"
                  (dateChange)="switchDay()"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerStart">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>
            </div>
            <div></div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Para</mat-label>
                <input
                  matInput
                  [matDatepicker]="pickerEnd"
                  formControlName="dayEnd"
                  (dateChange)="switchDay()"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="25px">
    <!-- card ENTRADA -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Entrada</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div style="padding-bottom: 5px" [ngClass]="alteraCor(dados_entrada)">
            {{ dados_entrada ? dados_entrada["qtdVeiculosEntrada"] : "0" }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_entrada && dados_entrada["tempoAtualPermanenciaEtapa"]
                  ? dados_entrada["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div
                echarts
                [options]="miniChartOptionEntrada"
                class="chart-2"
              ></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_entrada && dados_entrada["mediaPermanenciaEntrada"]
                  ? dados_entrada["mediaPermanenciaEntrada"]
                  : "0 h"
              }}
            </div>
          </div>
          <a class="text-small link" (click)="dialogTopOfensores('entrada')">
            <i class="material-icons icon_cor45"> info </i> Ofensores
          </a>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Pátio -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Patio / Docas</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div style="padding-bottom: 5px" [ngClass]="alteraCor(dados_patio)">
            {{ dados_patio ? dados_patio["qtdVeiculosPatio"] : "0" }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_patio && dados_patio["tempoAtualPermanenciaEtapa"]
                  ? dados_patio["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div
                echarts
                [options]="miniChartOptionPatio"
                class="chart-2"
              ></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_patio && dados_patio["mediaPermanenciaPatio"]
                  ? dados_patio["mediaPermanenciaPatio"]
                  : "0 h"
              }}
            </div>
          </div>
          <a class="text-small link" (click)="dialogTopOfensores('patio')">
            <i class="material-icons icon_cor45"> info </i> Ofensores
          </a>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Carregamento -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Carregamento</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div
            style="padding-bottom: 5px"
            [ngClass]="alteraCor(dados_carregamento)"
          >
            {{
              dados_carregamento
                ? dados_carregamento["qtdVeiculosCarregamento"]
                : "0"
            }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_carregamento &&
                dados_carregamento["tempoAtualPermanenciaEtapa"]
                  ? dados_carregamento["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div
                echarts
                [options]="miniChartOptionCarregamento"
                class="chart-2"
              ></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_carregamento &&
                dados_carregamento["mediaPermanenciaCarregamento"]
                  ? dados_carregamento["mediaPermanenciaCarregamento"]
                  : "0 h"
              }}
            </div>
          </div>
          <a
            class="text-small link"
            (click)="dialogTopOfensores('carregamento')"
          >
            <i class="material-icons icon_cor45"> info </i> Ofensores</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Descarregamento -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Descarregamento</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div
            style="padding-bottom: 5px"
            [ngClass]="alteraCor(dados_descarregamento)"
          >
            {{
              dados_descarregamento
                ? dados_descarregamento["qtdVeiculosDescarregamento"]
                : "0"
            }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_descarregamento &&
                dados_descarregamento["tempoAtualPermanenciaEtapa"]
                  ? dados_descarregamento["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div
                echarts
                [options]="miniChartOptionDescarregamento"
                class="chart-2"
              ></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_descarregamento &&
                dados_descarregamento["mediaPermanenciaDescarregamento"]
                  ? dados_descarregamento["mediaPermanenciaDescarregamento"]
                  : "0 h"
              }}
            </div>
          </div>
          <a
            class="text-small link"
            (click)="dialogTopOfensores('descarregamento')"
          >
            <i class="material-icons icon_cor45"> info </i> Ofensores</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card Expedição -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Expedição</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div
            style="padding-bottom: 5px"
            [ngClass]="alteraCor(dados_expedicao)"
          >
            {{
              dados_expedicao ? dados_expedicao["qtdVeiculosExpedicao"] : "0"
            }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_expedicao && dados_expedicao["tempoAtualPermanenciaEtapa"]
                  ? dados_expedicao["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div
                echarts
                [options]="miniChartOptionExpedicao"
                class="chart-2"
              ></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_expedicao && dados_expedicao["mediaPermanenciaExpedicao"]
                  ? dados_expedicao["mediaPermanenciaExpedicao"]
                  : "0 h"
              }}
            </div>
          </div>
          <a class="text-small link" (click)="dialogTopOfensores('expedicao')">
            <i class="material-icons icon_cor45"> info </i> Ofensores</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card GR -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">GR</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div style="padding-bottom: 5px" [ngClass]="alteraCor(dados_gr)">
            {{ dados_gr ? dados_gr["qtdVeiculosGr"] : "0" }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_gr && dados_gr["tempoAtualPermanenciaEtapa"]
                  ? dados_gr["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div echarts [options]="miniChartOptionGR" class="chart-2"></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_gr && dados_gr["mediaPermanenciaGr"]
                  ? dados_gr["mediaPermanenciaGr"]
                  : "0 h"
              }}
            </div>
          </div>
          <a class="text-small link" (click)="dialogTopOfensores('gr')">
            <i class="material-icons icon_cor45"> info </i> Ofensores</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card SAIDA -->
    <mat-card
      class="example-card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Saída</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="text-small">Qtd. Veículos na Etapa</div>
          <div style="padding-bottom: 5px" [ngClass]="alteraCor(dados_saida)">
            {{ dados_saida ? dados_saida["qtdVeiculosSaida"] : "0" }}
          </div>
          <div style="margin-top: 20px">
            <div class="text-small">Tempo atual</div>
            <div class="text-small-18">
              {{
                dados_saida && dados_saida["tempoAtualPermanenciaEtapa"]
                  ? dados_saida["tempoAtualPermanenciaEtapa"]
                  : "0 h"
              }}
            </div>
          </div>

          <mat-card-content style="margin-top: 10px">
            <div class="text-small">Performance</div>
            <div
              class="mat-display-4-2"
              style="
                margin-top: -15px !important;
                margin-bottom: -50px !important;
                margin-left: -20px !important;
              "
            >
              <div
                echarts
                [options]="miniChartOptionSaida"
                class="chart-2"
              ></div>
            </div>
          </mat-card-content>
          <div>
            <div class="text-small">Média do Período</div>
            <div class="text-small-18">
              {{
                dados_saida && dados_saida["mediaPermanenciaSaida"]
                  ? dados_saida["mediaPermanenciaSaida"]
                  : "0 h"
              }}
            </div>
          </div>
          <a class="text-small link" (click)="dialogTopOfensores('saida')">
            <i class="material-icons icon_cor45"> info </i> Ofensores</a
          >
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
