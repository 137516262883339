import { map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-show-info-dialog',
  templateUrl: './show-info-dialog.component.html',
  styleUrls: ['./show-info-dialog.component.scss']
})
export class ShowInfoDialogComponent implements OnInit {

  //Titulo utilizado no HTML
  title: String;

  //Array com a label e data de cada item que vai ser visualizado pelo usuário no HTML
  itemList: any[] = [];

  infoResource: any;
  templateData: any;

  splittedDataSourceItems: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ShowInfoDialogComponent>,
    public supportDataService: SupportDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    //Se for informado um nome de um possivel info_field, buscar essa informação em support_data
    if (this.data.info_fields) {

      forkJoin([
        this.geInfoFields()
      ]).subscribe(result => {

        this.processInfoFields(result[0]);
      });
    }

  }

  //Procura em support_data o info_field com nome especificado
  geInfoFields(): Observable<any> {
    return this.supportDataService.generic({ name: this.data.info_fields });
  }

  async processInfoFields(r: any) {
    this.infoResource = r.data.resource;
    this.title = this.infoResource.title;

    //Processa cada item no template
    this.infoResource.itemTemplate.forEach((item: any) => {
      this.templateData = this.data.item.resource[item.data];

      //Verifica se o item tem propriedade customDataSource
      //Que indica que o campo deseja pegar dados de outro lugar além da resource de driver_checkin Ex: resource de event
      if (item.customDataSource) {

        //Pega a string em inteiro de customDataSource
        //E se for uma string separada por "." (Ex: event.resource) indica que os dados estão em uma outra camada
        //(Ex: this.data.item->event->resource->dados)
        //Então a string de customDataSource é quebrada e guardada em um array, o separador dessa quebra sendo "."
        this.splittedDataSourceItems = item.customDataSource.split(".");

        //Retorna templateData ao nível superior dos dados para que seja possível pegar qualquer dado de customDataSource
        this.templateData = this.data.item;

        //Para cada item em splittedDataSourceItems, templateData entrar em uma propriedade dentro do objeto
        //Ex: item[0] = event, templateData->event, item[1] = resource, templateData->resource
        for (var i = 0; i < this.splittedDataSourceItems.length; i++) {
          this.templateData = this.templateData[this.splittedDataSourceItems[i]];
        }

        //Pega os dados utilizando o nome do campo que item.data passou
        this.templateData = this.templateData[item.data];

      }

      //Se possuir data, pegar do resource de driver_checkin o campo especificado
      if (this.templateData) {

        //Se templateData for um objeto, incluir cada valor de cada parametro no array de listas
        if (this.templateData instanceof Object) {
          Object.entries(this.templateData).forEach(([key, value]) =>
            this.itemList.push(
              {
                label: item.label,
                data: value
              }
            )
          )
        //Se templateData for um array, incluir cada item no array de listas
        } else if (this.templateData instanceof Array) {
          this.templateData.forEach((temp:any) =>
            this.itemList.push(
              {
                label: item.label,
                data: temp
              }
            )
          )
        }
        //Caso seja um campo normal, pega o valor do campo e o coloca dentro do array itemList como objeto com o label determinado em info_field
        else {
          this.itemList.push(
            {
              label: item.label,
              data: this.templateData
            }
          )
        }
      }else if(this[item.transform_method as keyof this]){
        const value:any|Observable<string|any> = (this[item.transform_method as keyof this] as Function || function(){return ''}).call(this, item);
        if(value instanceof Observable){
          value.subscribe((data:string)=>{
            if(data){
              this.itemList.push(
                {
                  label: item.label,
                  data
                }
              );
            }
          })
        }else if(value){
          this.itemList.push(
            {
              label: item.label,
              data: value
            }
          )
        }
      }
    });
  }

  private valuesSubtraction(data:any):string|number {
    const transformParams = [].concat(data.transform_params);
    const values = transformParams.reduce((pivot:any, value:any, index:number)=>{
      if(index < 2 && typeof(value) == "string"){
        const v = value.split(".").reduce(
          (x, b)=>{
            return x[b] || {};
          }, this.data.item
        );

        pivot[index] = v;
        pivot[2] = !isNaN(pivot[0]) && !isNaN(pivot[1]);

      }
      return pivot;
    },[null, null, false]);

    const [valueA, valueB, done = false] = values;

    if(done)
      return valueA - valueB;

    return "";
  }

  //Pega um item pelo id na driver_checkin e retorna o label baseado em um campo informado em support_data que possui o ID e label
  private selectedItens(data:any):Observable<string|any>{
    let [selectedOptionsIndexs, endPointValues, defaultMessage = ""]:string[] = [].concat(data.transform_params);

    if(selectedOptionsIndexs && endPointValues){
      const options = selectedOptionsIndexs.split(".").reduce((pivot, v)=> pivot[v],this.data.item);
      if(options){
        return this.supportDataService.generic({ name: endPointValues })
        .pipe(map((data:any, index:number)=>{
          try{
            const foundItem: any = data.data.resource.filter((x: any) => x).find((item: any)=>item.value == options);
            if(foundItem)
              return foundItem.label;

          }catch(err){
            return "";
          }
          return "";
        }))
      }
    }
    return of(defaultMessage);
  }

  //Realiza o mesmo que selectedItens mas com suporte para um array de itens como parâmetro e retorna um array com as labels para o html
  private selectedItensList(data:any):Observable<string|any>{
    let [selectedOptionsIndexs, endPointValues, defaultMessage = ""]:string[] = [].concat(data.transform_params);

    if(selectedOptionsIndexs && endPointValues){
      const options = selectedOptionsIndexs.split(".").reduce((pivot, v)=> pivot[v],this.data.item);
      if(options){
        return this.supportDataService.generic({ name: endPointValues })
        .pipe(map((data:any, index:number)=>{
          try{
            const foundItem = [].concat(data.data.resource)
            .filter(x=>x)
            .forEach((item:any) => {
              item.props.options.forEach((opt:any) => {
                if(options.includes(opt.value)) {
                  this.itemList.push(
                    {
                      label: "",
                      data: opt.label
                    }
                  );
                }
              })
            });
              return null;

          }catch(err){
            return null;
          }
        }))
      }
    }
    return of(defaultMessage);
  }
}
