import { forkJoin, switchMap, of, catchError } from "rxjs";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { DialogDeleteRecurringScheduleComponent } from "src/app/shared/dialog-delete-recurring-schedule/dialog-delete-recurring-schedule.component";

@Component({
  selector: "app-schedule-edit-dialog-v1",
  templateUrl: "./schedule-edit-dialog-v1.component.html",
  styleUrls: ["./schedule-edit-dialog-v1.component.scss"],
})
export class ScheduleEditDialogV1Component implements OnInit {
  schedule: any;
  statusList: any[] = [];
  currentStatus: any = {};
  shceduleList: any[] = [];
  configScheduling: any;
  waitingResponse = false;
  //Variável que define se é permitido editar esse agendamento
  //Agendamentos que já foram consumidos por um check-in podem ser apenas visualizados e não mais modificados
  isEditingAllowed = true;
  userCarrier: boolean = false;

  constructor(
    private dialog: MatDialog,
    private checkinService: CheckinService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    public dialogRef: MatDialogRef<ScheduleEditDialogV1Component>,
  ) { }

  ngOnInit() {
    //Desabilitar o botão de editar até que o carregamento dos dados e status seja finalizado em forkJoin
    this.waitingResponse = true;

    this.dialogRef.disableClose = true;
    let dict = [
      ["plate", "Placa"],
      ["area", "Local"],
      ["destino", "Local"],
      ["operation_name", "Operação"],
      ["suboperation_name", "Suboperação"],
      ["vehicle_type_name", "Tipo de veículo"],
    ];

    this.schedule = this.data.schedule;

    const account = JSON.parse(localStorage.getItem("account") || "{}");

    this.userCarrier = account.is_carrier;

    this.configScheduling =
      account.system_client.resource.environment.config_scheduling;

    this.dialogRef.beforeClosed().subscribe((data) => {
      if (
        !data &&
        ((this.currentStatus.id &&
          this.data.schedule.status != this.currentStatus.id) ||
          this.waitingResponse)
      ) {
        this.dialogRef.close({ action: "refresh" });
      }
    });

    forkJoin([
      this.supportDataService.generic({ name: "scheduling_status" }),
      this.supportDataService.generic({ name: "scheduling_edit_card_details" }),
      this.agendamentoService.indexSchedulingStatus(),
      //Busca se existe um check-in com o id do agendamento atual
      this.agendamentoService.getCheckinIdWithEvent({
        event_id: this.schedule.id,
      }),
    ]).subscribe(
      ([
        statusList,
        scheduleEditCardDetails,
        statusConfig,
        driverCheckinId,
      ]) => {
        const { department_id, operation_id, suboperation_id } = this.schedule;
        const groups = account.group_id;

        if (
          scheduleEditCardDetails?.data?.resource &&
          scheduleEditCardDetails?.data?.resource.length > 0
        ) {
          dict = [...scheduleEditCardDetails.data.resource];
        }
        dict.forEach(([k, v]) => {
          this.schedule[k] &&
            this.shceduleList.push([v, this.schedule[k] || ""]);
        });

        const statusOnEdit: any = []
          .concat(statusConfig.data)
          .find((status: any) => {
            let cond1 = true,
              cond2 = true,
              cond3 = true,
              cond4 = true;

            department_id &&
              status.department_id &&
              (cond1 = department_id == status.department_id);

            operation_id &&
              status.operation_id &&
              (cond2 = operation_id == status.operation_id);

            suboperation_id &&
              status.suboperation_id &&
              (cond3 = suboperation_id == status.suboperation_id);

            groups &&
              status.groups &&
              (cond4 = !!status.groups.match(groups));

            return cond1 && cond2 && cond3 && cond4;
          });
        if (statusOnEdit?.status_on_edit) {
          this.statusList = (statusList.data?.resource || []).filter(
            (status: any) =>
              statusOnEdit.status_on_edit.match(status.id) ||
              this.data.schedule.status == status.id,
          );
        } else {
          this.statusList = statusList.data.resource;
        }

        this.selectStatus(
          this.statusList.find((s) => s.id == this.data.schedule.status) || {},
        );

        //Se foi encontrado um checkin_id que possui o id desse agendamento, a edição do mesmo não é mais permitido
        if (driverCheckinId.checkin_id) {
          this.isEditingAllowed = false;
        }
        // Se o status do agendamento for dado como necessário da edição estar desativada vindo do banco de dados, a edição do mesmo não é mais permitida;
        if (this.schedule?.disabled_edit_status) {
          const disabledEditStatusArray = JSON.parse(this.schedule.disabled_edit_status);

          for (let i = 0; i < disabledEditStatusArray.length; i++) {
            if (String(this.schedule.status) === String(disabledEditStatusArray[i])) {
              this.isEditingAllowed = false;
              break;
            }
          }
        }
        //Habilitar o botão de editar
        this.waitingResponse = false;
      },
    );
  }

  changeStatus(selectedStatus: any) {
    if (selectedStatus.id != this.currentStatus.id) {
      const data = {
        status: selectedStatus.id,
        id: this.schedule.id,
      };

      this.waitingResponse = true;

      this.agendamentoService.alterarStatusMv(data).subscribe((r: any) => {
        this.waitingResponse = false;
        if (r.error) {
          this.info(
            "Agendamento Exclusão",
            r.msg ||
            "Ocorreu um error inesperado durante o processo de alteração de status.",
          );
        } else {
          this.selectStatus(selectedStatus);
        }
      });
    }
  }

  schedulingCheckin() {
    this.dialog
      .open(DialogConfirmComponent, {
        data: {
          title: "Confirma o registro de Check In?",
          message: `Tem certeza que deseja registrar o Check in para o motorista ${this.data.schedule.driver_name}?`,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            const loginData = {
              cpf: this.schedule.cpf,
              password: null,
              resource: {
                scheduling_checkin: true,
                event_id: this.schedule.id,
                operation: this.schedule.operation_id,
                board_horse: this.schedule.board_horse,
                board_cart: this.schedule.board_cart,
                entry_type: 0,
                checkin_origem: "web",
                driver_id: this.schedule.driver_id,
              },
              system_client_id: this.schedule.system_client_id,
              driver_id: this.schedule.driver_id,
            };

            if (!this.schedule.driver_id) delete this.schedule.driver_id;

            return this.checkinService.create(loginData).pipe(
              catchError((err: any) => {
                this.dialog.open(DialogAlertComponent, {
                  data: {
                    dialog_type: "error",
                    title: "Erro ao executar o check-in.",
                    message:
                      err?.error && err?.message
                        ? err?.message
                        : "Ocorreu um erro inesperado.",
                  },
                });
                return of(false);
              }),
            );
          }
          return of(false);
        }),
      )
      .pipe(
        switchMap((data: any | boolean) => {
          if (typeof data == "boolean" || !data) return of(Boolean(data));

          if (data.error) {
            return this.dialog
              .open(DialogAlertComponent, {
                data: {
                  dialog_type: "error",
                  title: "Erro ao executar o check-in.",
                  message:
                    data?.error && data?.message
                      ? data?.message
                      : "Ocorreu um erro inesperado.",
                },
              })
              .afterClosed()
              .pipe(switchMap(() => of(false)));
          }

          return this.dialog
            .open(DialogConfirmComponent, {
              data: {
                title: "Registro de check-in",
                message: data.message,
              },
            })
            .afterClosed()
            .pipe(switchMap(() => of(true)));
        }),
      )
      .subscribe((close: boolean) => {
        close && this.dialogRef.close({ action: "refresh" });
      });
  }

  delete() {
    const id = this.schedule.id;
    if (this.data.schedule.schedule_parent_id != undefined) {
      const dialogRef = this.dialog.open(DialogDeleteRecurringScheduleComponent, {
        data: {
          title: "Deseja alterar o agendamento recorrente?",
        },
      });

      dialogRef.afterClosed().subscribe((opt) => {
        if (opt !== undefined) {
          this.waitingResponse = true;
          if (opt === "1") {
            this.agendamentoService
              .dinamicScheduleDeleteV1(id)
              .subscribe((r: any) => {
                this.waitingResponse = false;
                const message = r.error
                  ? r.msg ||
                  "Ocorreu um error inesperado durante o processo de exclusão do agendamento."
                  : "Agendamento excluído com sucesso!";

                this.info("Agendamento Exclusão", message)
                  .afterClosed()
                  .subscribe(() => {
                    this.dialogRef.close({ action: "refresh" });
                  });
              });
          } else if (opt === "2") {
            this.agendamentoService
              .dinamicRecurringScheduleDeleteV1(id)
              .subscribe((r: any) => {
                this.waitingResponse = false;
                const message = r.error
                  ? r.msg ||
                  "Ocorreu um error inesperado durante o processo de exclusão do agendamento."
                  : "Agendamento excluído com sucesso!";

                this.info("Agendamento Exclusão", message)
                  .afterClosed()
                  .subscribe(() => {
                    this.dialogRef.close({ action: "refresh" });
                  });
              });
          }
        }
      });
    } else {
      if (
        id &&
        this.schedule.status != "denied" &&
        this.configScheduling.delete == true
      ) {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirma a exclusão?",
            message: "Tem certeza que deseja excluir o agendamento?",
          },
        });

        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.waitingResponse = true;
            this.agendamentoService
              .dinamicScheduleDeleteV1(id)
              .subscribe((r: any) => {
                this.waitingResponse = false;
                const message = r.error
                  ? r.msg ||
                  "Ocorreu um error inesperado durante o processo de exclusão do agendamento."
                  : "Agendamento excluído com sucesso!";

                this.info("Agendamento Exclusão", message)
                  .afterClosed()
                  .subscribe(() => {
                    this.dialogRef.close({ action: "refresh" });
                  });
              });
          }
        });
      } else {
        this.info(
          "Agendamento Exclusão",
          "A configuração de agendamentos não permite a exclusão.",
        );
      }
    }
  }

  edit() {
    this.currentStatus.id && (this.schedule.status = this.currentStatus.id);
    this.dialogRef.close({
      action: "edit",
      schedule: this.schedule,
      isEditingAllowed: this.isEditingAllowed,
    });
  }

  private selectStatus(selectedStatus: any) {
    this.statusList = this.statusList.map((status) => {
      status.selected = status.id == selectedStatus.id;
      return status;
    });

    this.currentStatus = selectedStatus;
  }

  private info(
    title: string,
    message: string,
  ): MatDialogRef<DialogAlertComponent> {
    return this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  print() {
    this.agendamentoService
      .downloadPDF("schedule_details_pdf", this.schedule.id)
      .subscribe((r: any) => {
        const fileURL = URL.createObjectURL(r);
        window.open(fileURL, "_blank");
      });
  }
}
