// import { Configuration } from './../configuration/configuration';
import { Component, OnInit } from "@angular/core";
import { SupportDataService } from "../shared/support-data.service";

@Component({
  selector: "app-configurations",
  templateUrl: "./configurations.component.html",
  styleUrls: ["./configurations.component.scss"],
})
export class ConfigurationsComponent implements OnInit {
  account: any;
  showScheduling: boolean = false;
  showChekin: boolean = false;
  showSettingDocas: boolean = false;
  showRegistrations: boolean = false;
  configurations: any;

  constructor(private supportDataService: SupportDataService) {}

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem('account') || "{}");
    this.configurations = this.account.system_client.resource.environment.pages['configurations'];

    if(this.configurations.checkin !== false){
      this.showChekin = true;
    }

    if(this.configurations.scheduling !== false){
      this.showScheduling = true;
    }

    if(this.configurations.registrations && this.configurations.registrations !== false){
      this.showRegistrations = true;
    }
  }

}
