<h1 mat-dialog-title>Sessão expirada</h1>
<div mat-dialog-content>
  Sua sessão expirou, deseja realizar o login novamente?
</div>
<div mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    (click)="logout()"
  >
    NÃO
  </button>
  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial
    (click)="login()"
  >
    SIM
  </button>
</div>
