import { CommonModule } from '@angular/common';
import { ProviderComponent } from './provider/provider.component';
import { AgendamentoRoutingModule } from './agendamento-routing.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventsComponent } from './events/events.component';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule, MatGridTile } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DialogComponent } from './dialog/dialog.component';
import { AlmoxarifadoComponent } from './almoxarifado/almoxarifado.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { TextMaskModule } from 'angular2-text-mask';
import { DenydialogComponent } from './denydialog/denydialog.component';
// temporario
// import { FormlyComponent } from './formly/formly.component';
// import { FormlyModule } from '@ngx-formly/core';
// import { FormlyMaterialModule } from '@ngx-formly/material';
const TRACKAGE_DATETIME_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  hour12: false,
  timeZone: 'America/Sao_Paulo'
}

@NgModule({
    imports: [
        AgendamentoRoutingModule,
        MatGridListModule,
        TextMaskModule,
        MatDialogModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        CommonModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatIconModule,
        MatSelectModule,
        MatCardModule,
        MatListModule,
        // NgxMatSelectSearchModule,
        // FlexLayoutModule,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule,
        // FormlyModule.forRoot(),
        // FormlyMaterialModule,
    ],
    declarations: [ProviderComponent, AlmoxarifadoComponent, EventsComponent, DialogComponent, DenydialogComponent,
      // FormlyComponent
    ],
    providers: [],
    exports: [AgendamentoRoutingModule, MatGridTile]
})
export class AgendamentoModule { }
