<h2 class="mat-align-center" mat-dialog-title > {{this.data.action.title || "Informe o problema"}}</h2>

<h5 class="mat-align-center subtitle" *ngIf="this.data.action.show_subtitle === true" > {{this.data.action.subtitle || ""}}</h5>

<div mat-dialog-content *ngIf="showComponent">
  <form *ngIf="!(this.data.action.show_new_reason === false)"  [formGroup]="form" novalidate>
    <mat-form-field >
      <mat-label>Outro motivo</mat-label>
      <input matInput formControlName="newReason">
      <button *ngIf="form.value.newReason" matSuffix mat-icon-button aria-label="Clear" (click)="addNewReason()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <mat-selection-list #reasons [(ngModel)]="selectedReasons" *ngIf="!(this.data.action.multiple === false); else single">
    <mat-list-option color="primary" *ngFor="let reason of listReasons" [value]="reason">
      {{reason.label}}
    </mat-list-option>
  </mat-selection-list>
  <ng-template #single>
    <label>{{ this.data.action?.single_label || "Selecione uma opção"}}</label>
    <mat-radio-group color="primary" class="radio-group" aria-label="Select an option" [(ngModel)]="selectedReasons">
      <mat-radio-button class="radio-button" *ngFor="let reason of listReasons" [value]="[reason]" >{{reason.label}}</mat-radio-button>
    </mat-radio-group>
  </ng-template>
</div>

<mat-dialog-actions align="end" *ngIf="showComponent">
  <button (click)="cancel()" mat-button color="secondary">
    Cancelar
  </button>
  <button (click)="onSubmit()" mat-raised-button color="primary" style="color: white;" [disabled]="!selectedReasons || selectedReasons.length < 0">
    Confirmar
  </button>
</mat-dialog-actions>
