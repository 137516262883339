<h2>{{data.dialogAction}} Agendamento</h2>
<form [formGroup]="form">
  <mat-dialog-content>
    <div *ngIf="validationMsgs.length > 0" class="alert-denied-list">
      <h4><b>Atenção</b></h4>
      <p *ngFor="let validate of validationMsgs">{{validate}}</p>
    </div>
    <div
      class="container">
      <div class="item item-1" style="align-content: space-around; flex-direction: row;">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form"
        ></formly-form>
      </div>

      <div class="item item-2" *ngIf="showDocuments">
        <div style="text-align: center">
          <mat-card class="list-upload">
            <mat-card-actions>
              <button
                [disabled]='this.data.page === "checkin"'
                mat-button
                color="primary"
                style="text-align: right"
                (click)="upload()"
              >
                Faça aqui o upload na documentação
                <mat-icon>backup</mat-icon>
              </button>
            </mat-card-actions>
            <mat-card-content class="list-content">
              <mat-list>
                <mat-list-item *ngFor="let item of documents" required>
                  <p class="uploadLabel" matLine>
                    <button
                    mat-icon-button
                    color="warn"
                    aria-label="Apagar arquivo"
                    matTooltip="Apagar arquivo"
                    (click)="deleteFile(item)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                    <span matTooltip="Baixar arquivo" class="cursor-pointer" (click)="getFile(item)">
                      {{ item.fileName }}
                    </span>
                  </p>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
          <div *ngIf="!data.event">
            <input
              type="file"
              #fileUpload
              id="fileUpload"
              name="fileUpload"
              multiple="multiple"
              accept="*"
              style="display: none"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <ng-container *ngIf="loading; else elseTemplate">
      <button mat-raised-button color="warn" disabled="disabled">
        Salvando ...
      </button>
    </ng-container>
    <ng-template #elseTemplate>
      <div  *ngIf="data.schedule.id" >
        <button type="button" class="blueBtn" *ngIf="scheduleActions?.length > 0" mat-raised-button [matMenuTriggerFor]="menu">Ações</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let item of scheduleActions" (click)="alterStatusEvent(item.id)"  [value]="item.id">{{item.name}}</button>
        </mat-menu>
      </div>
      <button type="button" mat-raised-button mat-dialog-close="true">
        Fechar
      </button>

      <button
        class="greenBtn"
        (click)="storeSchedule()"
        mat-raised-button
        color="primary"
        *ngIf="!data.schedule.id && this.config_scheduling.create == true">
      Salvar
    </button>

      <button
        class="greenBtn"
        (click)="storeSchedule('edit')"
        mat-raised-button
        color="primary"
        *ngIf="data.schedule.id && this.config_scheduling.edit == true"
      >
        Salvar
      </button>
      <button
        (click)="removeSchedule(data.schedule.id)"
        mat-raised-button
        color="warn"
        *ngIf="data.schedule.id && data.schedule.status != 'denied' && this.config_scheduling.delete == true">
        Excluir
      </button>
    </ng-template>
  </mat-dialog-actions>
</form>
