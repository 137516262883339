<div  class="main-gap-only-secure">
  <div>
    <div>
      <img class="logo" src="assets/img/tkg_preto_sem_fundo.png" alt="Trackage">
      <mat-card>
        <mat-card-title-group>
          <h2 mat-card-title>Registrar</h2>
        </mat-card-title-group>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div>
              <mat-form-field>
                <input type="text" formControlName="first_name" matInput placeholder="Nome">
                <mat-hint ngClass="mat-error">
                  <div *ngFor="let error of errors?.first_name">{{ error }}</div>
                </mat-hint>
              </mat-form-field>
              <mat-form-field>
                <input type="text" formControlName="last_name" matInput placeholder="Sobrenome">
                <mat-hint ngClass="mat-error">
                  <div *ngFor="let error of errors?.last_name">{{ error }}</div>
                </mat-hint>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field>
                <input type="email" formControlName="email" matInput placeholder="E-mail">
                <mat-hint ngClass="mat-error">
                  <div *ngFor="let error of errors?.email">{{ error }}</div>
                </mat-hint>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field>
                <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput placeholder="Senha">
                <mat-hint ngClass="mat-error">
                  <div *ngFor="let error of errors?.password">{{ error }}</div>
                </mat-hint>
                <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              </mat-form-field>
            </div>

            <div fxLayout="row wrap" ngClass="margin-top">
              <span>
                <button type="button" mat-button [matMenuTriggerFor]="menu" color="primary">Mais opções</button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="/auth/login">Entrar</button>
                  <button mat-menu-item routerLink="/auth/remember">Esqueci a senha</button>
                </mat-menu>
              </span>
              <span="none">
                <button type="submit" [disabled]="!form.valid" mat-button mat-raised-button color="primary">
                  ENTRAR
                </button>
              </span>
            </div>

          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
