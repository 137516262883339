import { format, parse } from 'date-fns';
import { DialogConfirmComponent } from '../../../shared/dialog-confirm/dialog-confirm.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { UserService } from 'src/app/user/shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleDockBlockDialogComponent } from '../schedule-dock-block-dialog/schedule-dock-block-dialog.component';

@Component({
  selector: 'app-schedule-dock-block',
  templateUrl: './schedule-dock-block.component.html',
  styleUrls: ['./schedule-dock-block.component.scss']
})
export class ScheduleDockBlockComponent implements OnInit {
  blocks: any[] = [];
  groups: any[] = [];
  @Input() hiddenElements: any;


  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private userService: UserService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getGroups();
  }

  addEdit(item?: any) {
    const dialogRef = this.dialog.open(ScheduleDockBlockDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {...item},
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleBlocks();
    });
  }

  getScheduleBlocks() {
    this.agendamentoService.indexDockScheduleBlock().subscribe((r: any) => {
      this.blocks = r.data;
    });
  }


  getGroups(){
    const options = { pageSize: 100 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
      this.getScheduleBlocks();
    });
  }

  showGroupName(ids: string) {
    if(ids) {
      const group_ids = JSON.parse(ids);
      let groupNames: any[] = [];
      this.groups.forEach(item => {
        if(group_ids.includes(item.id)) {
          groupNames.push(item.name);
        }
      });
      return groupNames.join(', ');
    } else {
      return '';
    }
  }

  formatTimeSlot(minutes: string) {
    const transform = {
      "15": "15 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "30": "30 " + this.translate.instant("Time.minutes", {Default: "minutos"}),
      "60": "1 " + this.translate.instant("Time.hour", {Default: "hora"}),
      "120": "2 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "180": "3 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "240": "4 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "300": "5 " + this.translate.instant("Time.hours", {Default: "horas"}),
      "360": "6 " + this.translate.instant("Time.hours", {Default: "horas"})
    }

    return transform[minutes as keyof typeof transform];
  }

  blockType(item: any){ 
    if(item.block_type == 1) {
      //let dates = JSON.parse(item.day.replace(/'/g, ''));
      //return this.translate.instant("Time.each_day", {Default: "Por dia"}) + `: ${item.day ? dates.map((day: string) => format(new Date(day), 'dd/MM/yyyy')).join(", "): null}`;
      
      let dates = this.removeBrackets(item.day).split('\"');
      dates = dates.filter((date: string) => date.length === 10);

      return this.translate.instant("Time.each_day", {Default: "Por dia"}) + `: ${item.day ? dates.map((day: string) => format(parse(day, 'yyyy-MM-dd', new Date), 'dd/MM/yyyy')).join(", "): null}`;
    }
    if(item.block_type == 2) {
      const week: any = {
        "1": this.translate.instant("Time.monday", {Default: "Segunda-feira"}),
        "2": this.translate.instant("Time.tuesday", {Default: "Terça-feira"}),
        "3": this.translate.instant("Time.wednesday", {Default: "Quarta-feira"}),
        "4": this.translate.instant("Time.thursday", {Default: "Quinta-feira"}),
        "5": this.translate.instant("Time.friday", {Default: "Sexta-feira"}),
        "6": this.translate.instant("Time.saturday", {Default: "Sábado"}),
        "7": this.translate.instant("Time.sunday", {Default: "Domingo"}),
      }

      const weekdays = this.removeBrackets(item.weekday).split(", ");
      
      return this.translate.instant("Time.each_week", {Default: "Por semana: "})  + `${weekdays.map((day: string) => week[day]).join(", ")}`;
    }
    if(item.block_type == 3){
      return this.translate.instant("Time.every_day" , {Default: "Todos os dias"});
    }

    return ""
  }

  removeBrackets(days: string): string {
    // Remover os colchetes [] de uma string
    const withoutBrackets = days.replace(/\[|\]/g, "");
    return withoutBrackets;
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: this.translate.instant("Business_model.attention_upper", {Default: "ATENÇÃO"}) + '!',
        message: this.translate.instant("Business_model.delete_block_schedule", {Default: "Tem certeza que deseja APAGAR o bloqueio de agendamento?"})
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.agendamentoService.deleteDockScheduleBlock(id).subscribe((r: any) => {
          if(!r.error) {
            this.getScheduleBlocks();
          }
        });
      }
    });
  }
}
