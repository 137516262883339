import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private http: HttpClient) { }

  getLocation(name: string="", options: any={}): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/core/location/${name}`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error')))
    );
  }

  getLocationDetails(name: string="", id: number): Observable<any> {
    const url = `${environment.apiUrl}/core/location/${name}/${id}`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error')))
    );
  }
}
