import { CoreGeofenceService } from "./../geofence.service";
import { Component, Inject } from "@angular/core";
import { FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { SupportDataService } from "../support-data.service";
import { DialogResourceNameComponent } from "../dialog-resource-name/dialog-resource-name.component";
import { DialogConfirmComponent } from "../dialog-confirm/dialog-confirm.component";

@Component({
  selector: "app-flow-dialog",
  templateUrl: "./flow-dialog.component.html",
  styleUrls: ["./flow-dialog.component.scss"],
})
export class FlowDialogComponent {
  saving = false;
  manage = false;
  flows: any = [];
  display_message = false;
  message = "";
  form = this.formBuilder.group({
    flow_id: [""],
  });
  flow: any[] = [];
  data_list: any[] = [];
  originalFlow: any[] = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer.id === "tkg-flow-list") {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    if (
      event.previousContainer.id === "tkg-flow" &&
      event.container.id === "tkg-flow-list"
    ) {
      this.flow.splice(event.currentIndex, 1);
    } else {
      moveItemInArray(
        event.previousContainer.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  constructor(
    private dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FlowDialogComponent>,
    private coreGeofenceService: CoreGeofenceService,
    private checkinService: CheckinService,
    private supportDataService: SupportDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const stored_data = localStorage.getItem("account") || "{}";
    if (this.data.item.hasOwnProperty("resource")) {
      if (this.data.item?.resource.hasOwnProperty("flow_control")) {
        this.originalFlow = JSON.parse(
          JSON.stringify(this.data.item.resource.flow_control),
        );
        this.flow = this.data.item.resource.flow_control;
      }
    }

    if (this.data.hasOwnProperty("manage")) {
      this.form.addControl("name", new FormControl("", [Validators.required]));
      this.manage = this.data.manage;
      if (typeof this.data.item?.resource == "string") {
        const resource = JSON.parse(this.data.item?.resource);
        this.originalFlow = JSON.parse(JSON.stringify(resource.flow));
        this.flow = resource.flow;
        this.form.patchValue({ name: this.data.item?.name });
      }
    }

    this.getGeofences();
    this.getFlowTemplates();
  }

  close() {
    if (this.flow.length != this.originalFlow.length) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: "Confirmar fechamento com dados não salvo",
          message: `Você fez alterações no fluxo atual. Tem certeza que deseja sair?`,
          btnConfirm: "Sim",
        },
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  clean() {
    this.flow = [];
  }

  save() {
    this.saving = true;
    let data: any = {
      id: this.data.id,
      flow_control: this.flow,
    };
    // Se um status foi cadastrado na ação, adiciona
    // no objeto. Caso contrário, não altera status
    // Se já existe um fluxo definido, necessário ignorar o status
    if (
      this.data.action.status.length > 0 &&
      !this.data.item.resource.hasOwnProperty("flow_control")
    ) {
      data = { ...data, status: this.data.action.status };
    }
    this.checkinService.updateStatus(data).subscribe((res: any) => {
      this.dialogRef.close();
    });
  }

  saveTemplate() {
    if (this.data.hasOwnProperty("manage")) {
      const item = {
        name: this.form.get("name")?.value,
        list_name: "flow_template",
        flow: this.flow,
      };
      this.supportDataService.addNewItem2List(item).subscribe((r: any) => {
        this.flows = r.data.resource;
        this.dialogRef.close();
      });
    } else {
      const dialogRef = this.dialog.open(DialogResourceNameComponent, {
        panelClass: ["dialog-small"],
        disableClose: false,
        data: {
          title: "Escolha um nome para o fluxo",
          btnConfirm: "Salvar",
        },
      });

      dialogRef.afterClosed().subscribe((name: any) => {
        if (name) {
          const item = {
            name: name,
            list_name: "flow_template",
            flow: this.flow,
          };
          this.supportDataService.addNewItem2List(item).subscribe((r: any) => {
            this.flows = r.data.resource;
          });
        }
      });
    }
  }

  getGeofences() {
    let list: any[] = [];
    this.coreGeofenceService.getGeoFence().subscribe((r: any) => {
      r.data.forEach((item: any) => {
        if (item.properties.type == "s") {
          list.push(item.properties);
        }
      });
      this.data_list = list;
    });
  }

  getFlowTemplates() {
    this.supportDataService
      .generic({ name: "flow_template" })
      .subscribe((r: any) => {
        const flows = r.data.resource;
        this.flows = flows
          .filter((i: any) => i.active == true)
          .sort((a: any, b: any) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
      });
  }

  flowSelected() {
    this.flow = this.form.value.flow_id.flow;
  }
}
