<h1 class="mat-align-center" mat-dialog-title>Escolha o veículo</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-radio-group aria-labelledby="pendency-type-radio-group-label" class="pendency-type-radio-group"
      formControlName="board">
      <mat-radio-button class="pendency-type-radio-button" *ngFor="let vehicle of vehicles"
        [value]="vehicle?.resource.board">
        <div *ngIf="data.type === 'Cavalo'">
          {{vehicle?.resource.board}}
        </div>
        <div *ngIf="data.type === 'Carreta'">
          {{vehicle?.resource.board}} - {{vehicle?.event?.resource?.operation_id === 2 ? vehicle?.event?.resource?.external_event_id : vehicle?.event?.resource?.sid}} - {{showStatus(vehicle?.resource)}}
        </div>

      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>


<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" (click)="close()">Cancelar</button>
  <button type="submit" mat-button cdkFocusInitial color="primary" [disabled]="!form.valid"
    (click)="onSubmit()">Salvar</button>
</div>
