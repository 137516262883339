import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from '../shared/dashboard.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-ranking-permanencia',
  templateUrl: './modal-ranking-permanencia.component.html',
  styleUrls: ['./modal-ranking-permanencia.component.scss']
})
export class ModalRankingPermanenciaComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();

  breakpoints = Breakpoints;
  displayedColumns: string[];

  constructor(
    public dashboardService: DashboardService,
    public dialogRef: MatDialogRef<any>,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  ngOnInit() {
    this.getRankingPermanencia();
  }

  getRankingPermanencia() {
    const option = {
      limit: 100
    };
    this.dashboardService.rankingAguardandoLiberacao(option).subscribe({
      next: (r: any) => {
        console.log(r);
        this.dataSource.data = r.data;
      }, error: (e: any) => {
        console.log(e)
      }
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }


  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset) || this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      this.displayedColumns = ['driver', 'cdTime'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.displayedColumns = ['driver', 'cdTime'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['driver', 'cdTime'];
    }
  }

  segundosParaHoraMinuto(segundos: string) {
    if (segundos) {
      return moment.duration(parseInt(segundos), "seconds").format('DD[d] hh[h]mm[m]');
    } else {
      return null;
    }
  }

}
