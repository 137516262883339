
<table
  matSort
  mat-table
  #sort="matSort"
  style="width: 100%;"
  class="mat-elevation-z8"
  [ngClass]="{'table-head': !viewTable}"
  [dataSource]="viewTable ? shiftSchedules.dataSource : []"
>
  <ng-container [matColumnDef]="column.key" *ngFor="let column of shiftSchedules.columns;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort" class="aumentar">
      {{column.name}}
    </th>
    <td mat-cell *matCellDef="let element" class="aumentar">
      {{
        element.resource[column.key] | conversion:column
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" end>
    <th mat-header-cell *matHeaderCellDef class="aumentar">Ações</th>
    <td mat-cell *matCellDef="let element" style="white-space: nowrap">
      <button mat-icon-button matTooltip="Abrir" (click)="open(trashed ? actionEnum.VIEW : actionEnum.EDIT, element)">
        <mat-icon>folder_open</mat-icon>
      </button>

      <button *ngIf="!trashed" mat-icon-button matTooltip="Deletar" (click)="destroy(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr class="mat-row" *matNoDataRow>
    <td [attr.colspan]="shiftSchedules.columns.length + 1" style="background: #fafafa;">
      <div class="grid grid-3-columns">
        <mat-card *ngFor="let element of shiftSchedules.dataSource">
          <mat-card-header>
            <mat-card-title>{{element.resource?.name}}</mat-card-title>
            <mat-card-subtitle>{{element.resource?.shift_name}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div><b>Dias da semana:</b></div>
            <div>{{element.resource.weekday | conversion:shiftSchedules.columns[1]}}</div>
            <br />
            <div><b>Horário:</b></div>
            <div style="display: flex; justify-content: space-between;">
              <span>Início: {{element.resource.start}}</span>
              <span>Término: {{element.resource.end}}</span>
              <span>Duração: {{element.resource.duration}}</span>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button class="button" color="warn" mat-button (click)="destroy(element)">Excluir</button>
            <button class="button" color="primary" mat-button  (click)="open(actionEnum.EDIT, element)">Editar</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </td>
  </tr>

  <tr mat-header-row *matHeaderRowDef="shiftSchedules.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns:shiftSchedules.displayedColumns"></tr>
</table>
<mat-paginator #paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>

<div
  class="mat-fab-bottom-right add-edit-button"
>
  <button
    mat-mini-fab
    (click)="open(actionEnum.ADD)"
    color="primary">
    <mat-icon>add</mat-icon>
  </button>
</div>
