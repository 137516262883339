import { DenydialogComponent } from '../denydialog/denydialog.component';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject, Optional, ViewChild, ElementRef } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder } from '@angular/forms';
import { EventsComponent } from '../events/events.component';
import { Schedule } from '../schedule';
import { SupportDataService } from './../../shared/support-data.service';
import { Subject, of } from 'rxjs';
import { DocumentsService } from '../../documents/shared/documents.service';
import { map, catchError } from 'rxjs/operators';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { AgendamentoService } from './../shared/agendamento.service';
import * as moment from 'moment';
import { MaskUtil } from '../../shared/util/mask.util';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload!: ElementRef;
  protected _onDestroy = new Subject<void>();
  files: any[] = [];
  schedule = {} as Schedule;
  action!: String;
  carriers: any[] = [];
  maskUtil = MaskUtil;
  docks: any;
  documents: any[] = [];
  document: any;
  start_dates: any[] = [];
  end_dates = [];
  motive = null;
  operations: any = [];
  vehicle_types: any = [];

  scheduleForm = this.formBuilder.group({
    id: [this.data.schedule.id || null],
    // order_number: [this.data.schedule.order_number || ''],
    observation: [this.data.schedule.observation || ''],
    dock_id: [this.data.schedule.dock_id || ''],
    event_id: [this.data.schedule.event_id || null],
    start_at: [moment(`${this.data.definedDay} ${moment(this.data.schedule.start_at).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').toISOString() || ''],
    schedule_time: [this.data.schedule.schedule_time || ''],
    // end_at: [this.data.schedule.end_at || ''],
    carrier_id: [this.data.schedule.carrier_id || null],
    cpf: [this.data.schedule.cpf || null], 
    driver_number: [null],
    sid: [null],
    board_cart:  [this.data.schedule.board_cart.toUpperCase() || null],
    board_horse: [this.data.schedule.board_horse.toUpperCase() || null],
    driver_name: [this.data.schedule.driver_name || null],
    vehicle_type: [this.data.schedule.vehicle_type],
    operation_id: [this.data.schedule.operation_id ],
    external_event_id: [this.data.schedule.external_event_id || null],
    documents: [this.data.schedule.documents || []]
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public documentsService: DocumentsService,
    public transportadoraService: CarrierService,
    public supportDataService: SupportDataService,
    public agendamentoService: AgendamentoService,
    public dialogRef: MatDialogRef<EventsComponent>,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit(): void {
    // console.log('vehicle_type')
    // console.log(this.data.schedule.vehicle_type)
    // console.log('operation_id')
    // console.log(this.data.schedule.operation_id)

    // this.scheduleForm.patchValue({
    // vehicle_type: this.data.schedule.vehicle_type,
    // operation_id: this.data.schedule.operation_id
    // })

    // console.log('agendamento/dialog')
    this.getDocks();
    this.getCarriers();
    this.getTimes();
    this.getOperations();
    this.getVehicleTypes();
    this.action == this.data.action;
    if (this.data.schedule.documents) {
      this.documents = this.data.schedule.documents;
    }
  }

  //  Gera Os Tempos Para Seleção
  getTimes() {
    this.end_dates = this.data.timeslots;
    let start_dates: any[] = [];

    // console.log(this.data.definedDay);

    this.data.timeslots.map(({ hour }: any) => {
      let name = moment(hour).format('HH:mm');
      let value = moment(`${this.data.definedDay} ${moment(hour).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').toISOString();
      let now = moment().unix();

      // Verifica se o tempo é maior que atual
      if (now < moment(value).unix()) {
        let time = { name, value };
        start_dates.push(time)
      }
    })

    this.start_dates = start_dates;
  }

  getOperations() {
    this.supportDataService.operation().subscribe((r: any) => {
      const op = r.data.resource;

      this.operations = op.filter((operation: any) => operation.use_in_scheduling);
    });
  }

  getVehicleTypes() {
    this.supportDataService.dinamicTable({ name: 'vehicle_type' }).subscribe((r: any) => {
      r.data.resource.push(r.data.resource.splice(r.data.resource.findIndex((item: any) => {return item.name == 'Outros'}), 1)[0]);
      this.vehicle_types = r.data.resource;
    });
  }

  // Open deny dialog
  denySchedule() {
    const dialogRef = this.dialog.open(DenydialogComponent, {
      width: "600px"
    });

    dialogRef.afterClosed().subscribe(({ motive, action }) => {
      if (action == 'denied') {
        this.motive = motive;
        this.storeSchedule(action);
      }
    })
  }

  getCarriers() {
    this.transportadoraService.index().subscribe((r: any) => {
      // console.log(r)/
      this.carriers = r.data;
      // console.log('length')
      // console.log(r.data);
      if(this.carriers.length == 1){
        // console.log(r.data);
        // this.scheduleForm.value.carrier_id = r.data[0].id
        this.scheduleForm.patchValue({
          carrier_id: r.data[0].id
        })
      }
    })
  }

  getDocks() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.docks = r.data.resource;
    });
  }

  change(status: string): void {
    this.dialogRef.close({ action: 'change', status })
  }

  cancelSchedule(): void {
    let id = this.scheduleForm.value.event_id;
    this.agendamentoService.cancel(id).subscribe((r: any) => {
      this.snackBar.open("Agendamento Cancelado Com Sucesso", "OK", {
        duration: 5000,
      });
      // console.log('Cancel');
      this.dialogRef.close({ action: 'cancel' });
    }, (e: any) => {
      this.snackBar.open("Houve um erro ao cancelar agendamento", "Entendi", {
        duration: 5000,
      });
      // console.log(e)
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.documentsService.upload(formData).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.documents.push(event.body);
        }

      });
  }

  uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  storeSchedule(status: string) {
    const resource = this.scheduleForm.value;
    resource.documents = this.documents;
    resource.cpf = resource.cpf.replace(/[.-]/g, '')

    // Verifica se existe ID para editar do contrario será criado
    if (resource.event_id) {
      let event = {
        id: resource.event_id,
        active: true,
        resource
      };

      delete event['resource']['schedule_time'];
      delete event['resource']['id'];
      delete event['resource']['event_id'];

      this.agendamentoService.update({ event }, event.id).subscribe((r: any) => {
        this.dialogRef.close({ action: status });
      }, (e: any) => {
        console.log(e);
      });
    } else {
      // Set the fields of the form
      let event = { resource };

      // The default fields of creation
      event['resource']['uuid'] = Math.floor(Date.now() * Math.random());
      event['resource']['status'] = 'confirmed';
      event['resource']['documents'] = this.documents;

      // Generate the time schedules block
      // let schedules = this.generateSchedules(event);

      // Create the Scheduling
      this.agendamentoService.createWithDock({ event }).subscribe((r: any) => {
        this.dialogRef.close({ action: 'create' });
      }, (e: any) => {
        console.log(e);
      });
    }
  }

  deleteFile(item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.documentsService.delete(item).subscribe((r: any) => {
          if (r.success) {
            const idx = this.documents.findIndex(v => v.fileName === item.fileName);
            this.documents.splice(idx, idx >= 0 ? 1 : 0);
          }
        }, (e: any) => {

        });
      }
    });
  }

  getFile(item: any): void {
    this.documentsService.downloadFile(item)
      .subscribe(
        (val) => {
          this.createFileFromBlob(val, item.fileName);
        },
        response => {

        },
        () => {

        });
  }

  createFileFromBlob(file: Blob, fileName: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  generateSchedules(schedule: any) {
    let schedules: any[] = []

    let day = moment(schedule.resource.start_at).format('YYYY-MM-DD');
    let start = moment(schedule.resource.start_at).format('HH:mm');
    let end = moment(schedule.resource.end_at).format('HH:mm');

    let times = this.generateIntervals(start, end);

    times.map(time => {
      let event = {
        resource: {
          start_at: moment(`${day} ${time}`, 'YYYY-MM-DD HH:mm').toISOString(),
          end_at: schedule.resource.end_at,
          uuid: schedule.resource.uuid
        }
      };

      schedules.push(event);
    });

    return schedules;
  }


  // Gera Os Tempos A Serem Inseridos Nos Slots
  generateIntervals(start_interval: string, end_interval: string) {
    let start = moment.duration(start_interval).asMinutes();
    let end = moment.duration(end_interval).asMinutes()

    let intervals = [];

    if (end == 0) {
      intervals.push(moment.utc().startOf('day').add({ minutes: start }).format('H:mm'))
    } else {
      for (let i = start; i < end; i += 30) {
        let time = moment.utc().startOf('day').add({ minutes: i }).format('H:mm');
        // console.log(time);
        intervals.push(time);
      }
    }

    return intervals;
  }
}
