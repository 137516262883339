import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-input-custom',
  templateUrl: './formly-input-custom.component.html',
  styleUrls: ["./formly-input-custom.component.scss"]
})
export class FormlyInputCustomComponent extends FieldType<any>{
  messageError():string{
    const length = Object.keys(this.formControl.errors).length;

    if(length > 1)
      return "Verifique o preenchimento"

    if(this.formControl.hasError('required'))
      return this.field.validation?.messages?.required || "Campo obrigatório";

    if(this.formControl.hasError('pattern'))
      return this.field.validation?.messages?.pattern || "Formato inválido";

    return "";
  }
}
