<h2 mat-dialog-title>Filtrar página</h2>

<mat-dialog-content class="mat-typography">
  <div *ngIf="msgBlock" class="tkg-msg-block"><mat-icon class="tkg-warning">warning</mat-icon> {{msg}}</div>
  <br/>
  <mat-form-field appearance="outline" class="tkg-form-item">
    <mat-label>Escolha um filtro</mat-label>
    <mat-select [formControl]="filterName" (selectionChange)="changeFilter()">
      <mat-option *ngFor="let ft of filters" [value]="ft.key">
        {{ft.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <form [formGroup]="form">
  <div class="tkg-ft-row" *ngIf="showFilterBar && showSelectComponent">
    <div class="tkg-ft-item tkg-ft-item-margin-right">
      <mat-form-field appearance="outline" class="tkg-form-item">
        <mat-select formControlName="valueName">
          <mat-option *ngFor="let val of valueList" [value]="val">
            {{val.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tkg-ft-item item-right tkg-add-button">
      <button mat-button color="primary" (click)="addFilter()">
        Adicionar
        <mat-icon iconPositionEnd>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="tkg-ft-row" *ngIf="showFilterBar && showDatePickerRangeComponent">
    <div class="tkg-ft-item tkg-ft-item-margin-right">
      <mat-form-field appearance="fill">
        <mat-label>Informe o período</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Data de início inválida</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Data final inválida</mat-error>
      </mat-form-field>
    </div>
    <div class="tkg-ft-item item-right tkg-add-button">
      <button mat-button color="primary" (click)="addFilter()">
        Adicionar
        <mat-icon iconPositionEnd>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="tkg-ft-row" *ngIf="showFilterBar && showInputComponent">
    <div class="tkg-ft-item tkg-ft-item-2 tkg-ft-item-margin-right">
      <mat-form-field appearance="outline" class="tkg-form-item">
        <mat-select formControlName="operatorName">
          <mat-option *ngFor="let op of operatorsList" [value]="op.id">
            {{op.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tkg-ft-item tkg-ft-item-margin-right">
      <mat-form-field appearance="outline" class="tkg-form-item">
        <input matInput formControlName="valueName">
      </mat-form-field>
    </div>
    <div class="tkg-ft-item item-right tkg-add-button">

      <button mat-button color="primary" (click)="addFilter()">
        Adicionar
        <mat-icon iconPositionEnd>add</mat-icon>
      </button>
    </div>
  </div>
</form>

<mat-card class="tkg-card-chips">
  <span
    class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
    *ngFor="let item of addedFiltersList; index as idx">
    <b class="tkg-chips-filter-name">{{ item.filter_name }} {{showOperatorName(item.op)}}</b>
      {{ item.value_name }}
    <span
      class="material-icons tkg-chips-icon"
      (click)="deleteFilter(idx)">
      cancel
    </span>
  </span>
</mat-card>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>Fechar</button>
  <button mat-raised-button style="color: white;" color="accent" (click)="cleanFilters()" [disabled]="addedFiltersList.length === 0">Limpar</button>
  <button mat-raised-button style="color: white;" color="primary" (click)="callBackFilter()" [disabled]="addedFiltersList.length === 0">Salvar</button>
</mat-dialog-actions>

