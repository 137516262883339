import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, Inject } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
//import 'rxjs/add/observable/interval';
// import 'rxjs/add/operator/startWith';
// import 'rxjs/add/operator/switchMap';
// import { RefreshService } from 'src/app/shared/refresh.service';
import { UntypedFormBuilder } from '@angular/forms';
import { EChartsOption } from 'echarts';
import { CollectPointsService } from './shared/collect-points.service';
import { MapsGenericComponent } from '../shared/maps-generic/maps-generic.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AgmCoreModule } from '@agm/core';


@Component({
  selector: 'app-collect-points',
  templateUrl: './collect-points.component.html',
  styleUrls: ['./collect-points.component.scss']
})
export class CollectPointsComponent implements OnInit {

  @ViewChild('fs') fs!: ElementRef;

  performance_total: number = 0;
  dataSource = new MatTableDataSource<any>();
  dataSourceAll = new MatTableDataSource<any>();
  // se o valor for um aumento setar UP
  setaup = 'call_made';
  // se o valor for uma queda setar DOWN
  setadown = 'call_received';
  breakpoints = Breakpoints;
  screenRatio!: string;


  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  collection_points: any = [];
  drivers_points: any = [];
  displayedColumns: any = [];
  department_id: any;
  user: any;
  filterLabels: any = [];
  account: any;
  value: any;
  lat!: number;
  lng!: number;
  zoom = 20;
  showMap: boolean = false;
  pageSize:any ;
  page:number = 0;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    public CollectPointsService: CollectPointsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<MapsGenericComponent>,

  ) {
  }

  //Performance total
  chartOptionPerformace!: EChartsOption;
  miniChartOptionEntrada!: EChartsOption;
  miniChartOptionPatio!: EChartsOption;
  miniChartOptionCarregamento!: EChartsOption;
  miniChartOptionDescarregamento!: EChartsOption;
  miniChartOptionExpedicao!: EChartsOption;
  miniChartOptionGR!: EChartsOption;
  miniChartOptionSaida!: EChartsOption;

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.loadCollectionPoints();
    this.activateLayout();

  }

  activateLayout() {
    this.displayedColumns = ["name", "cpf", "phone", "distance"];
  }


   labelFilterEnabled() {
    //this.filterLabels = this.user.system_client.resource.environment.pages['dashboard']["data_filters_label"];
  }


  loadCollectionPoints() {
    this.CollectPointsService.loadCollectionPoints().subscribe(
      (r: any) => {
        this.collection_points = r.data;
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  loadDriversNearPoints(data: any) {
    data.pageSize =  this.paginator.pageSize ? this.paginator.pageSize : '10',
    data.page = this.paginator.pageIndex ;

    this.CollectPointsService.loadDriversNearPoints(data).subscribe(
      (r: any) => {
        this.paginator.length = r.total.count;
        this.dataSource.data = r.data;
        this.dataSource.data.sort((a, b) => a.distancia - b.distancia);
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }
}

