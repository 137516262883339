import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(private http: HttpClient) { }

  getData(viewName:string="",options: any={}):Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc'
    }, options);
    const params = new HttpParams({ fromObject: httpParams });
    const url = `${environment.apiUrl}/core/view/${viewName}`;
    return this.http.get<any>(url, { params })
    .pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}
