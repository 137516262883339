import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { DashboardJandiraListComponent } from './dashboard-jandira-list/dashboard-jandira-list.component';


const dashboardJandiraRoutes: Routes = [
  { runGuardsAndResolvers: 'always',
    path: '',
    redirectTo: '/admin/jandira/dashboard',
    pathMatch: 'full' },
  {
    path: 'admin/jandira',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'dashboard',
        component: DashboardJandiraListComponent,
        data: {
          title: 'Dashboard',
          search: false,
          refresh: false
        },
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(dashboardJandiraRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardJandiraRoutingModule {
}
