import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Media } from '../media.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardJandiraService {

  constructor(
    private http: HttpClient
  ) { }
 
  dashboardKPIs(options?: any): Observable<Media> {

    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/dashboard-kpis`;
    return this.http.get<Media>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  topOfensores(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/top_ofensores`;
    return this.http.get<Media>(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

}
