import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PainelMotoristaListComponent } from './painel-motorista-list/painelmotorista-list.component';
import { PainelMotoristaRoutingModule } from './painelmotorista-routing.module';
import { CoreModule } from '../core/core.module';
import { DetailsMapsComponent } from '../shared/details-maps/details-maps.component';
import { CardModule } from './../shared/cards/card.module';
@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        CardModule,
        PainelMotoristaRoutingModule
    ],
    declarations: [PainelMotoristaListComponent]
})
export class PainelLorealMotoristaModule { }
