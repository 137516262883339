import { Routes, RouterModule } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { CollectPointsComponent } from './collect-points.component';

const routes: Routes = [
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/collect-points',
    component: CollectPointsComponent,
    data: {
      title: 'Pontos de Coleta',
      search: true,
      refresh: true
    }
  }
];

export const CollectPointsRoutingRoutes = RouterModule.forChild(routes);
