import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { SearchService } from '../search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  search = new UntypedFormControl();
  showWatcher: Subscription;
  clearWatcher: Subscription;
  @Input() scope: string;
  @ViewChild('input') input: ElementRef;
  show: false;
  displaySearch = false;


  constructor(
    private searchService: SearchService
  ) { }

  onClear(event?: any) {
    if (this.search.value) {
      this.search.setValue('');
    }
  }

  onOpen(event?: any) {
    this.displaySearch = true;
    this.searchService.onOpen();
  }

  onClose(event?: any) {
    this.displaySearch = false;
    this.searchService.onClose();
  }

  ngOnInit() {
      this.search.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(newValue => {
        this.searchService.onChange(newValue, this.scope);
      });

    this.showWatcher = this.searchService.show
      .subscribe((show: any) => {
        this.show = show;
        this.displaySearch = show;
        setTimeout(() => this.input.nativeElement.focus(), 0);
      });

    this.clearWatcher = this.searchService.clear.subscribe(() => this.onClear());

  }

  ngOnDestroy() {
    this.showWatcher.unsubscribe();
    this.clearWatcher.unsubscribe();
  }
}
