import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CheckinService } from '../../checkin/shared/checkin.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig,  } from '@ngx-formly/core';
import { forkJoin, Observable } from 'rxjs';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-info-form-dialog-component',
  templateUrl: './info-form-dialog-component.component.html',
  styleUrls: ['./info-form-dialog-component.component.scss']
})
export class InfoFormDialogComponentComponent implements OnInit {
  dialogResult: any = {};
  message: string = "";
  title!: string;

  files = [];
  documents: any[] = [];
  loading = false;
  offset!: string;
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  account: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public checkinService: CheckinService,
    public formBuilder: UntypedFormBuilder,
    public supportDataService: SupportDataService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<InfoFormDialogComponentComponent>
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    console.log(this.data);

    console.log(' status ', this.data.action.status);

    forkJoin([
      this.getScheduleFields()
    ]).subscribe( result => {

      // Schedule Field process
      this.processScheduleFields(result[0]);
    });
  }

  getScheduleFields(): Observable<any> {
    return this.supportDataService.generic({name: this.data.action.status+'_dialog_fields'});
  }

  processScheduleFields(r: any) {

    this.fields = r.data.resource;

    this.fields =  this.fields.reduce((fields: any, field: any)=> {
      if(field.type === 'checkbox'){
        field['defaultValue'] =  false;
      }
      return [...fields,field]
    },[]);
    console.log('this.fields');
    console.log(this.fields);
  }

  onSubmit() {
    const data: any = {
      id: this.data.item.id,
      // message: this.data.action.dialogue_message
    }
    data[this.data.action.status] = this.form.value;

    if(this.data.action.status) {
      data.status = this.data.action.status
    }

    if(this.data.action.change_key) {
      data.change_key = this.data.action.change_key
    }

    this.checkinService.updateField(this.data.action.urlAPI, data)
    .subscribe({
      next:(r:any)=>{
        if(r.status == 200) {
          this.dialogRef.close(r.status);
        }
      },
      error:(res: any)=> {
        this.dialogRef.close();
        if(res.error.message) {
          this.snackBar.open(res.error.message,"Fechar",{ duration: 3000 });
        }
      }
    });
  }

}
