<div class="tkg-title-group">
  <p class="tkg-title">{{ "Business_model.schedule_window" | translate: {Default: "Cadastro de Paradas"} }}</p>
</div>

<div class="tkg-container">
  <div class="Parent">
    <div class="child1">
      <button mat-icon-button>
        <mat-icon>open_in_new</mat-icon>
      </button>

      <span class="p-title">Campos Visíveis na Tabela</span>
    </div>
    <div class="child2 divider">

  Campos
    </div>
  </div>
  <div class="Parent">
    <div class="child1">
      <button mat-icon-button>
        <mat-icon>open_in_new</mat-icon>
      </button>
      <span class="p-title">Campos do Formulário</span>
    </div>
    <div class="child2">

  Campos
    </div>
  </div>
</div>
