import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class IntegrationService {
  constructor(private http: HttpClient) {}

  getBalancaJarinu(): Observable<any> {
    const url = `${environment.apiUrl}/integration/balanca-jarinu`;
    return this.http.post<any>(url, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }
}
