import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { ChecklistService } from '../../shared/checklist.service';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-invalidate-vehicle-dialog',
  templateUrl: './invalidate-vehicle-dialog.component.html',
  styleUrls: ['./invalidate-vehicle-dialog.component.scss']
})
export class InvalidateVehicleDialogComponent implements OnInit {
  invalidationReasons: any[] = [];
  dialogResult: any = {};
  selectedReasons!: any[];
  chkId!: number;
  showComponent: boolean = false;
  checkListIds: any = [];
  checkList: any;
  form = this.formBuilder.group({
    newReason: ['']
  });
  constructor(
    public checklistService: ChecklistService,
    public supportData: SupportDataService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<InvalidateVehicleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    // Caso tenho id de checklist, deve ser consultada a API
    // para pegar os dados mais atuais
    this.getChecklistById();
  }

  getChecklistById(){
    if(this.data.item.resource?.checklist_id){
      this.checklistService.show(this.data.item.resource.checklist_id).subscribe((r: any) => {
        this.checkList = r;
        this.getInvalidationReasonsList();
        this.getCheckListIds();
      });
    } else {
      this.getInvalidationReasonsList();
      this.getCheckListIds();
    }
  }

  getInvalidationReasonsList() {
    console.log('getInvalidationReasonsList');
    this.supportData.dinamicTable({ name: 'invalidation_reasons_list' }).subscribe((r: any) => {
      if(this.checkList) {
        let originalListArray = r.data.resource;
        let checkListArray = this.checkList.resource;
        // busca ids do checklist
        let checkListIds: any[] = [];
        this.checkList.resource.forEach((item: any) => {
          if(item.solved == false) {
            checkListIds.push(item.id);
          }
        });
        console.log('checkListIds', checkListIds);
        // console.log('ids')
        // console.log(checkListIds)
        // remove os ids encontrados da lista original
        originalListArray.forEach((item: any, idx: any) => {
          if(checkListIds.includes(item.id)){
            originalListArray.splice(idx,1);
          }
        });

        let unionArray = [...originalListArray, ...checkListArray]

        unionArray = this.uniqueObjects(unionArray);
        this.invalidationReasons = unionArray.sort(function(a, b) {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          });
        this.chkId = this.checkList.id
        this.selectedReasons = this.checkList.resource

        console.log('selectedReasons', this.selectedReasons);
      } else {
        this.invalidationReasons = r.data.resource;
      }
      this.showComponent = true;
    });
  }

  uniqueObjects(array: any){
    const obj: any = {};
    const unionArray = new Array();

    for (let i = 0, len = array.length; i < len; i++) {
      obj[array[i]['id']] = array[i];
    }
    for (const key in obj) {
      unionArray.push(obj[key]);
    }

    return unionArray;
  }

  mergeArrayObjects(fullArray:any, updateArray:any){
    const newArray: any[] = [];
    fullArray.forEach((element: any, idx: any) => {
      element.solved = false;
      newArray.push(element)
      updateArray.forEach((el: any) => {
        if(element.id === el.id){
          newArray[idx] = el;
        }
      });
    });
    return JSON.parse(JSON.stringify(newArray));
  }

  getCheckListIds(){
    if(this.checkList){
      this.checkList.resource.forEach((element: any) => {
        if(element.solved == false) {
          this.checkListIds.push(element.id);
        }
      });
    }
  }

  checkPendencies(){
    if(this.selectedReasons.length > 0){
      let list = [];
      this.selectedReasons.forEach((item:any) =>{
        if(item.solved == false ) {
          list.push(1)
        }
      });
    }
  }

  confirm() {
    this.checkPendencies();
    // if(this.selectedReasons.length > 0) {
      let data: any;
      if (this.chkId) {
        // Edita checklist
        // console.log('edita checklist')
        // Se o item foi selecionado, solved tem que ser false
        this.selectedReasons.forEach((item: any) => {
          item.solved = false;
        });
        data = {
          id: this.chkId,
          checkin_id: this.data.item.id,
          resource: this.selectedReasons,
          details: {reasons: this.form.value.reasons}
        }

        this.checklistService.update(data).subscribe((r: any) => {
          this.dialogRef.close({chk_action: 'edit', checklist_id: data.id, action: 'denied', checklist: r, checkin_id: this.data.item.id});
        });

      } else {
        // Cria novo checklist
        // console.log('cria novo checklist')
        data = {
          checkin_id: this.data.item.id,
          resource: this.selectedReasons,
          details: {reasons: this.form.value.reasons}
        }
        if(this.selectedReasons.length > 0){
          this.checklistService.create(data).subscribe((r: any) => {
            this.dialogRef.close({chk_action: 'create', checklist_id: r.id, action: 'denied', checklist: r, checkin_id: this.data.item.id });
          });
        } else {
          // Apenas fecha modal
          this.dialogRef.close();
        }
      }
  }


  checkSelected(obj: any) {

    // const ck = this.checkListIds.find(item => item === obj.id);
    // if(ck && obj.solved === false) {
    //   console.log('solved false')
    //   return true;
    // } else {
    //   console.log('solved true')
    //   return false;
    // }
    if(obj.solved){
      return false;
    } else {
      return true;
    }
    return true;
  }

  cancel() {
    this.dialogRef.close();
  }

  addNewReason(): void {
    console.log('clicou')
    const maxId = this.invalidationReasons.reduce(
      (max, item) => (item.id > max ? item.id : max),
      this.invalidationReasons[0].id
    );
    console.log(maxId);
    const newItem = {
      id: maxId + 1,
      name: this.form.value.newReason,
      solved: false
    }
    this.invalidationReasons.push(newItem);
    this.selectedReasons.push({...newItem, created_at: new Date(), solved_at: null});
  }
}
