import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss']
})
export class GridListComponent implements OnInit {
  title = this.data.title;
  itens_lists: any = [];
  itens_selected: any = [];
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  itens_array: any = [];
  ids_selecteds: any = [];
  showGrid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GridListComponent>
  ) {}

  ngOnInit(): void {
    this.getData().subscribe((response: any) => {
      if (this.data.path === 'resource') {
        this.itens_lists = response.data.resource;
      } else {
        this.itens_lists = response.data;
      } 
      this.itens_array = this.itens_lists;

      this.ids_selecteds = this.data.selected_list

      if (this.ids_selecteds.length) {
        console.log("entrei no if")
        this.checkSelectItems()
      } else {
        console.log('Entrei no else')
        this.showGrid = true;
      }
    }); 

    this.searchFilter.valueChanges.pipe(
      map((value: string) => this.filterItems(value))
    ).subscribe((filteredItems: any[]) => {
      this.itens_array = filteredItems;
    });
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue('');
      this.itens_array = this.itens_lists;
    }
  }

  getData(): Observable<any> {
    const url = `${environment.apiUrl}${this.data.url}`;
    return this.http.get(url).pipe(
      map((response: any) => response),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  addRemoveItem(obj: any) {
    const idx = this.itens_selected.findIndex((item: any) => item.id === obj.id);
    if (idx !== -1) {
        this.itens_selected.splice(idx, 1);
    } else {
        this.itens_selected.push(obj);
    }
}

  filterItems(value: string): any[] {
    const filterValue = value.toLowerCase().trim();
    return this.itens_lists.filter((item: any) => {
      return this.itemSearched(item, filterValue);
    });
  }

  itemSearched(item: any, filterValue: string): boolean {
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const propertyValue = item[key];
        if (typeof propertyValue === 'string' && propertyValue.toLowerCase().includes(filterValue)) {
          return true;
        }
      }
    }
    return false;
  }

  isChecked(id: number) {
    let validation = false
    this.itens_selected.map((item: any) => {
      if(item.id === id) {
        validation = true
      } 
    });
    return validation
  } 

  checkSelectItems() {
    this.itens_array.forEach((item: any) => {
      if (this.ids_selecteds.includes(item.id)) {
        this.itens_selected.push(item);
      }
    });
    this.showGrid = true;
  }

  clearChecked() {
    this.itens_selected = []
  }

  sendDataToComponent() {
    this.dialogRef.close(this.itens_selected);
  }
}
