<mat-dialog-content class="mat-typography">
  <mat-tab-group animationDuration="300ms" #tabGroup>
    <mat-tab [label]="'Scheduling.scheduling_simple' | translate:{Default:'Agendamento simples'}">
      <div class="header">
        <span>{{ 'Scheduling.import_schedulings' | translate:{Default:'Importar agendamentos'} }}</span>
        <div>
          <button
            mat-stroked-button
            color="primary"
            [matTooltip]="'Scheduling.for_booking_scheduling' | translate:{Default:'Para o cadastro de agendamentos'}"
            matTooltipClass="custom-tooltip"
            matTooltipShowDelay="500"
            (click)="downloadTemplate()"
            [disabled]="waitSimpleTemplate"
          >
            {{ 'Scheduling.simple_scheduling_template' | translate:{Default:'Template Agendamento Simples'} }}
            <mat-icon>download</mat-icon>
          </button>
          <mat-progress-bar mode="indeterminate" *ngIf="waitSimpleTemplate" class="progress"></mat-progress-bar>
        </div>
      </div>
      <mat-card class="list-upload" appDnd (fileDropped)="simpleSchedulingFiles.length == 0 && onFilesDropped($event)">
        <mat-card-content class="list-content">
          <button (click)="simpleSchedulingFileUpload.click()" mat-button color="primary" style="text-align:right;" *ngIf="simpleSchedulingFiles.length == 0">
            {{ 'Scheduling.drop_upload_scheduling_file' | translate:{Default:'Arraste e solte ou clique aqui para o upload do arquivo de importação'} }}
            <mat-icon>backup</mat-icon>
          </button>
          <mat-list *ngIf="simpleSchedulingFiles.length > 0">
            <mat-list-item *ngFor="let item of simpleSchedulingFiles; let i = index" required>
              <div class="item">
                <div matLine class="file-item">

                  <button mat-icon-button color="warn" [attr.aria-label]="'Business_model.delete_file' | translate:{Default:'Apagar arquivo'}" (click)="deleteFile(i)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                  <span> {{item.data.name}} </span>
                </div>
                <mat-progress-bar [mode]="item.mode" *ngIf="item.progress > 0" [value]="item.progress"></mat-progress-bar>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
      <input
        type="file"
        #simpleSchedulingFileUpload
        id="simpleSchedulingFileUpload"
        name="simpleSchedulingFileUpload"
        multiple="false"
        style="display:none;"
        (change)="fileBrowseHandler($event)"
        required
        [accept]="acceptFileType"
      />
    </mat-tab>
    <mat-tab [label]="'Scheduling.scheduling_full' | translate:{Default:'Agendamento completo'}">
      <div class="header">
        <span>{{ 'Scheduling.import_schedulings' | translate:{Default:'Importar agendamentos'} }}</span>
        <div>
          <button
            mat-stroked-button
            color="primary"
            [matTooltip]="'Scheduling.for_booking_scheduling_full' | translate:{Default:'Para o cadastro dos agendamentos, motoristas e transportadoras'}"
            matTooltipClass="custom-tooltip"
            matTooltipShowDelay="500"
            (click)="downloadTemplate(true)"
            [disabled]="waitFullTemplate"
          >
          {{ 'Scheduling.full_scheduling_template' | translate:{Default:'Template Agendamento Completo'} }}
            <mat-icon align="end">download</mat-icon>
          </button>
          <mat-progress-bar mode="indeterminate" *ngIf="waitFullTemplate" class="progress"></mat-progress-bar>
        </div>
      </div>
      <mat-card
        appDnd
        class="list-upload"
        (fileDropped)="fullSchedulingFiles.length == 0 && onFilesDropped($event, false)"
      >
        <mat-card-content class="list-content">
          <button mat-button (click)="fullSchedulingFileUpload.click()" color="primary" style="text-align:right;" *ngIf="fullSchedulingFiles.length == 0">
            {{ 'Scheduling.drop_upload_scheduling_file' | translate:{Default:'Arraste e solte ou clique aqui para o upload do arquivo de importação'} }}
            <mat-icon>backup</mat-icon>
          </button>
          <mat-list *ngIf="fullSchedulingFiles.length > 0">
            <mat-list-item *ngFor="let item of fullSchedulingFiles; let i = index" required>
              <div class="item">
                <div matLine class="file-item">
                  <button mat-icon-button color="warn" [attr.aria-label]="'Business_model.delete_file' | translate:{Default:'Apagar arquivo'}" (click)="deleteFile(i, false)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                  <span> {{item.data.name}} </span>
                </div>
                <mat-progress-bar [mode]="item.mode" *ngIf="item.progress > 0" [value]="item.progress"></mat-progress-bar>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
      <input
        type="file"
        #fullSchedulingFileUpload
        id="fullSchedulingFileUpload"
        name="fullSchedulingFileUpload"
        multiple="false"
        style="display:none;"
        (change)="fileBrowseHandler($event, false)"
        required
        [accept]="acceptFileType"
      />
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close [disabled]="processingFile">{{ 'Business_model.close' | translate:{Default:'Fechar'} }}</button>
  <button
    (click)="processMassScheduling()"
    mat-raised-button
    cdkFocusInitial
    [disabled]="!(
      (tabGroup.selectedIndex === 0 && simpleSchedulingFiles.length > 0)
      ||
      (tabGroup.selectedIndex === 1 && fullSchedulingFiles.length > 0)
    ) || processingFile"
    style="color: white;"
    color="primary"
  >
    {{ 'Business_model.import' | translate:{Default:'Importar'} }}
  </button>
</mat-dialog-actions>
