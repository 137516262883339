import { MatSort } from '@angular/material/sort';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, EventEmitter, ViewChild, Output } from '@angular/core';
import { catchError, map, startWith, switchMap, tap, debounceTime } from 'rxjs/operators';

import { UserService } from '../shared/user.service';
import { AccountService } from 'src/app/account/shared/account.service';
import { GroupsDetailComponent } from '../groups-detail/groups-detail.component';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent implements OnInit {
  searchWatcher!: Subscription;
  refreshWatcher!: Subscription;
  accountWatcher!: Subscription;
  search!: string;
  pageSize!: number;
  length!: number;
  optionsFilter: any = [];
  displayedColumns!: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<any>();
  dataSourceAll = new MatTableDataSource<any>();
  searchFilter: UntypedFormControl = new UntypedFormControl();
  account: any;
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  searching!: boolean;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private accountService: AccountService,
    public breakpointObserver: BreakpointObserver,
    private translate: TranslateService
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  activateLayout() {
    this.displayedColumns = ['actions', 'name', 'created_at'];
  }

  addEdit(action: string, element: any = {}) {
    const dialogRef = this.dialog.open(GroupsDetailComponent, {
      panelClass: ['dialog-small'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(element)),
        action: action
      }

    });

    dialogRef.afterClosed().subscribe(res => {
      console.log('fechou modal lista razoes');
      console.log(res);
      if (res) {
        this.snackBar.open(res.message, 'OK', {
          duration: 3000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });

        this.loadGroups();
      }
    });
  }

  delete(event: any, data: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: this.translate.instant("Messages.confirm_delete", {Default: "Confirma a exclusão?"}),
        message: this.translate.instant("Messages.confirm_delete_item", {Default: 'Tem certeza que deseja excluir o item '}) + data.name + "?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.userService.deleteGroups(data.id).subscribe((response) => {
          this.reload();
          this.snackBar.open(response.message || this.translate.instant("Messages.delete_group", {Default: "Grupo removido com sucesso!"}), "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        });
      }
    });
  };

  ngOnInit() {
    this.accountService.identity().then(identity => {
      if (identity) {
        this.account = identity;
      }
    });
    this.loadGroups();
  }

  loadGroups() {
    /// Ao utilizar a busca
    this.searchFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.searchFilter.value}` || ''
        };
        return this.userService.userGroups(options);
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      console.log('segundo subscribe');
      this.dataSource.data = data;
      this.dataSourceAll.data = data;
    });

    /// Ao entrar na página
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active || 'name',
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            with: 'carrier',
          };

          return this.userService.userGroups(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {

          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
      });
  }

  disconnect(group:any){

    this.dialog.open(DialogConfirmComponent,{
      data:{
        title:"Confirma esta ação?",
        message: "Este group de usuários será desconectado, esta ação poderá levar alguns minutos."
      }
    })
    .afterClosed()
    .subscribe((bool)=>{
      if(bool){
        this.userService.groupLogout(group.id)
        .subscribe((data)=>{
          if(data?.success){
            this.dialog.open(DialogAlertComponent,{
              data:{
                dialog_type:"ok_only",
                title: "Solicitação de desconexão de usuário",
                message: data.message
              }
            })
          }
        })
      }
    });
  }
}
