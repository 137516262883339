<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <h3 [innerHTML]="data.message"></h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>{{data.btnCancel ? data.btnCancel : "Fechar"}}</button>
  <button mat-raised-button
  color="primary"
  (click)="onSubmit()"
  [mat-dialog-close]="true">{{data.btnConfirm ? data.btnConfirm : "Ok"}}</button>
</mat-dialog-actions>
