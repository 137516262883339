import { StepsFilterService } from './../../shared/steps-filter.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportDynamicService } from 'src/app/report-dynamic/shared/report-dynamic.service';
import { ReportListDialogFiltersComponent } from '../report-list-dialog-filters/report-list-dialog-filters.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit{
  pageParam: string;
  searchWatcher: Subscription;
  search: string = '';
  page: number = 1
  pageSize: number = 10;
  orderBy: string;
  sortedBy: string;
  showListEmpty = false;
  showLoadingData = true;
  dataSource = new MatTableDataSource<any>();
  totalItems: number;
  displayedColumns: string[];
  columnsSchema: any;
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  paginate = true;
  appliedFilters: any;
  adjustedFilters: any;
  filterWatcher: any;
  constructor(
    private reportService: ReportDynamicService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private stepsFilterService: StepsFilterService
  ) {}

  ngOnInit() {
    this.pageParam = this.route.snapshot.paramMap.get('page') || '';
    this.getReportData();

    this.filterWatcher = this.stepsFilterService.show
    .subscribe((filter: any) => {
      this.showDialogFilters();
    });
  }

  getReportData() {
    const options = {
      start: this.formatDateDB(new Date()),
      end: this.formatDateDB(new Date()),
      search: this.search,
      paginate: true,
      pageSize: this.pageSize || 10,
      page: this.page || 1,
      orderBy: this.orderBy || '',
      sortedBy: this.sortedBy || '',
      ...this.adjustedFilters

    }

    this.reportService.reportv2(this.pageParam, options).subscribe((r: any) => {
      this.showLoadingData = (this.dataSource.data.length === 0);
      this.dataSource.data = r.data;
      if(!this.columnsSchema) {
        this.columnsSchema = r.schema;
        this.displayedColumns = this.columnsSchema.map((col: any) => col.key);
        this.totalItems = r.total;
        this.showLoadingData = false;
        this.showListEmpty = (r.data.length === 0);
        this.paginate = (r.data.length > 0);
      }

    });
  }

  formatDateDB(date: any) {
    const dt = new Date(date);
    const year = dt.toLocaleString('default', {year: 'numeric'});
    const month = dt.toLocaleString('default', {
      month: '2-digit',
    });
    const day = dt.toLocaleString('default', {day: '2-digit'});

    return [year, month, day].join('-');
  }

  // clearFilter(event?:any) {
  //   if (this.searchFilter.value) {
  //     this.searchFilter.setValue("");
  //   }
  // }

  performOnChangePage(event: any) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getReportData();
  }

  performSortChange(event: any) {
    this.orderBy = event.active;
    this.sortedBy = event.direction;
    this.getReportData();
  }

  ngOnDestroy() {
    // this.searchWatcher.unsubscribe();
  }

  showDialogFilters() {
    const dialogRef = this.dialog.open(ReportListDialogFiltersComponent, {
      panelClass: ["dialog-medium"],
      data: {page: this.pageParam, fts: this.appliedFilters}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Aplica os filtros
        this.appliedFilters = result;
        // Conta a quantidade de filtros e publica no serviço
        this.countNumberOfFilters(this.appliedFilters);
        // Busca os dados com os filtros aplicados

        console.log(this.appliedFilters);
        this.adjustFilters();
      }
    });
  }

  countNumberOfFilters(filters: any) {
    const filtersKeys = Object.keys(this.appliedFilters);
    let qtdFts: any[] = [];
    filtersKeys.forEach((key: string) => {
      if(this.appliedFilters[key]) {
        if(Array.isArray(this.appliedFilters[key])){
          if(this.appliedFilters[key].length > 0) {
            qtdFts.push(1);
          }
        }
        else if(this.appliedFilters[key] !=null) {
          qtdFts.push(1);
        }
      }
    });
    this.stepsFilterService.onChange(qtdFts.length.toString(), '');
  }

  adjustFilters() {
    let filter: any = {};
    let localFilters = JSON.parse(JSON.stringify(this.appliedFilters));
    localFilters.forEach((item: any) => {
      const op = item.op;
      delete item.filter_name;
      delete item.value_name;
      delete item.op;
      // Conta a quantidade de chaves do objeto e formata cada uma
      Object.keys(item).forEach((i: any) => {
        let key_op = `${i}${op}`
        filter[key_op] = item[i];
      });

    });
    this.adjustedFilters = filter;
    this.getReportData();
  }

}

