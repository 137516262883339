import { DenydialogComponent } from './denydialog/denydialog.component';
import { CoreModule } from '../core/core.module';
import { ProviderComponent } from './provider/provider.component';
import { MultiAgendamentoRoutingModule } from './multiagendamento-routing.module';
import { NgModule } from '@angular/core';
import { EventsComponent } from './events/events.component';
import { MatGridTile } from '@angular/material/grid-list';
import { DialogComponent } from './dialog/dialog.component';
import { AlmoxarifadoComponent } from './almoxarifado/almoxarifado.component';
import { EventDepartmentsComponent } from './event-departments/event-departments.component';
import { DialogEventDepartmentsComponent } from './dialog-event-departments/dialog-event-departments.component';
import { TextMaskModule } from 'angular2-text-mask';

const TRACKAGE_DATETIME_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  hour12: false,
  timeZone: 'America/Sao_Paulo'
}

@NgModule({
    imports: [
        MultiAgendamentoRoutingModule,
        CoreModule,
        TextMaskModule
    ],
    declarations: [
      ProviderComponent, AlmoxarifadoComponent,
      EventsComponent, DialogComponent, DenydialogComponent,
      EventDepartmentsComponent, DialogEventDepartmentsComponent
    ],
    providers: [],
    exports: [MultiAgendamentoRoutingModule, MatGridTile]
})
export class MultiAgendamentoModule { }
