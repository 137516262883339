import { RegistrationsRoutingModule } from './registrations-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlaceListComponent } from './places/place-list/place-list.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { RoutesDialogComponent } from './routes/routes-dialog/routes-dialog.component';
import { RoutesListComponent } from './routes/routes-list/routes-list.component';
import { PlaceDialogComponent } from './places/place-dialog/place-dialog.component';
import { PaginateSortTableComponent } from '../shared/paginate-sort-table/paginate-sort-table.component';
import { SupportDataListComponent } from './support-data-list/support-data-list.component';
import { SupportDataDialogComponent } from './support-data-dialog/support-data-dialog.component';


@NgModule({
  declarations: [
    RoutesListComponent,
    PlaceListComponent,
    PlaceDialogComponent,
    RoutesDialogComponent,
    SupportDataListComponent,
    SupportDataDialogComponent
  ],
  imports: [
    RegistrationsRoutingModule,
    SharedModule,
    CoreModule,
    CommonModule,
    PaginateSortTableComponent
  ]
})
export class RegistrationsModule { }
