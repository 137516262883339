import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';

import { DocumentsService } from 'src/app/documents/shared/documents.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { MassSchedulingResultComponent } from './mass-scheduling-result/mass-scheduling-result.component';
import { TranslateService } from '@ngx-translate/core';


interface MassSchedulingFileInterface{
  data: File;
  progress: number;
  mode: 'indeterminate'|'determinate';
  processed: boolean;
  uploadResult?:{
    bucket?: boolean;
    fileName: string;
    path: string;
    url: string
  }
}

@Component({
  selector: 'app-upload-mass-scheduling',
  templateUrl: './upload-mass-scheduling.component.html',
  styleUrls: ['./upload-mass-scheduling.component.scss']
})
export class UploadMassSchedulingComponent {
  @ViewChild('tabGroup') tabGroup:MatTabGroup;

  processingFile = false;
  waitFullTemplate = false;
  waitSimpleTemplate = false;
  fullSchedulingFiles:MassSchedulingFileInterface[] = [];
  simpleSchedulingFiles:MassSchedulingFileInterface[] = [];
  acceptFileType = '.csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  constructor(
    private dialog: MatDialog,
    private translateService:TranslateService,
    private dialogRef: MatDialogRef<any>,
    private documentsService:DocumentsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  deleteFile(index:number, simple=true) {
    const file = simple ? this.simpleSchedulingFiles : this.fullSchedulingFiles;
    if(file[0]?.uploadResult){
      this.documentsService
      .deleteBucket({pathname:new URL(file[0].uploadResult.url).pathname, bucket_name:'mass-scheduling'})
      .subscribe(()=>{
        file.splice(index, 1);
      });
    }else{
      file.splice(index, 1);
    }
  }

  fileBrowseHandler($event:any, simple=true) {
    simple
    ? this.simpleSchedulingFiles.splice(0, 1, this.massSchedulingFile($event.target.files[0]))
    : this.fullSchedulingFiles.splice(0, 1, this.massSchedulingFile($event.target.files[0]));

    $event.target.value = "";
  }

  onFilesDropped($event:any, simple=true) {
    if(this.acceptFileType.match($event[0].type)){
      simple
      ? this.simpleSchedulingFiles.splice(0, 1, this.massSchedulingFile($event[0]))
      : this.fullSchedulingFiles.splice(0, 1, this.massSchedulingFile($event[0]));
    }
  }

  downloadTemplate(full=false){
    this.waitSimpleTemplate = !full ? true : this.waitSimpleTemplate;
    this.waitFullTemplate = full ? true : this.waitFullTemplate;

    this.documentsService.downloadSchedulingTemplate({full})
    .subscribe({
      next:(file:Blob) => {
        this.documentsService.createFileFromBlob(file, this.translateService.instant('Scheduling.bulk_schedule_template',{Default:"template-agendamento-em-massa"}) + ".xlsx");
      },
      error:(error:any)=>{
        this.dialog.open(DialogAlertComponent,{
          data:{
            title:this.translateService.instant(
              'Scheduling.error_download_scheduling_template',
              {Default:"Error ao executar o download do template de agendamento."}
            ),
            dialogType: "ok_only",
            message: error.error?.message || this.translateService.instant(
              'Scheduling.impossible_generate_template',
              {Default:"Não foi possível gerar o template de agendamento em massa"}
            )
          }
        })
      }
    })
    .add(()=>{
      this.waitSimpleTemplate = !full ? false : this.waitSimpleTemplate;
      this.waitFullTemplate = full ? false : this.waitFullTemplate;
    });
  }

  processMassScheduling(){
    const full = this.tabGroup.selectedIndex === 1;
    const file = full ? this.fullSchedulingFiles[0] : this.simpleSchedulingFiles[0];

    this.processingFile = true
    this.uploadFile(file)
    .subscribe((uploaded)=>{
        if(uploaded){
          this.batchScheduling(file, full);
        }else{
          this.processingFile = false;
        }
    });
  }

  batchScheduling(file:MassSchedulingFileInterface, full=false){
    this.documentsService.batchScheduling({...file.uploadResult, full})
    .subscribe({
      next:(result:any) => {
        file.processed = true;
        this.dialog.open(MassSchedulingResultComponent,{
          disableClose: true,
          data:{...result, fileInfo:file.uploadResult}
        })
        .afterClosed()
        .subscribe(()=>this.dialogRef.close())
      },
      error:(err:any)=>{
        this.dialog.open(DialogAlertComponent,{
          data:{
            dialog_type: "error",
            title: this.translateService.instant('Messages.unexpected_error',{Default:"Erro inesperado."}),
            message: this.translateService.instant('Scheduling.impossible_scheduling_upload',{Default:"Não foi possível executar o agendamento em massa."})
          }
        })
      }
    })
    .add(()=>{
      file.mode = "determinate";
      file.progress = 100;
      this.processingFile = false;
    });
  }

  private massSchedulingFile(file:File):MassSchedulingFileInterface{
    return {
      data: file,
      progress: 0,
      processed: false,
      uploadResult: undefined,
      mode: "indeterminate"
    }
  }

  private uploadFile(file:MassSchedulingFileInterface) {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('bucket_name', 'mass-scheduling');

    if(file.uploadResult){
      return of(true)
    }

    return this.documentsService
    .uploadBucket(formData)
    .pipe(
      map((event:any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round((event.loaded * 100) / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      filter((event)=>event),
      switchMap((event)=>{
          if(event?.body) {
            file.uploadResult = event?.body;
            return of(true)
          }
          return of(false);

      }),
      catchError((error: HttpErrorResponse) => {
        file.progress = 0;
        this.dialog.open(DialogAlertComponent,{
          data:{
            title:this.translateService.instant('Messages.error_on_uploading',{Default:"Error ao executar o upload."}),
            dialogType: "ok_only",
            message: error.error?.message
          }
        })
        return of(false);
      }))
  }
}
