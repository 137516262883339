import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { CardInterface } from '../../card.interface';

@Component({
  selector: 'app-card-dash',
  templateUrl: './card-dash.component.html',
  styleUrls: ['./card-dash.component.scss']
})
export class CardDashComponent implements OnInit, CardInterface {
  data: any;
  layout!: number;
  options: any;
  chartOptions!: EChartsOption;

  constructor() { }

  ngOnInit() {
    this.chartOptions = this.generateChartOptions(this.data.value||0);
  }

  private generateChartOptions(value:number):EChartsOption{
    const per = (value > 0 ? (value/100)-.0001 : 0);

    const color = this.data.color || "#936bc4";
    return {
      series: [
        {
          type: 'gauge',
          radius:'95%',
          startAngle: -179.9999 - (( 360 * per ) / 2),
          endAngle: 180 - (( 360 * per ) / 2),
          pointer: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 15,
              color: value > 0 ? [[per, '#f4f4f4'],[1, color]] : [[1, '#f4f4f4'],[0, '#f4f4f4']],
              shadowColor: 'rgba(0, 0, 0, 0.4)',
              shadowBlur: 3
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          data: [{
            value,
            detail: {
              valueAnimation: true
            }
          }
         ],
          detail: {
            fontSize: 24,
            color:'black',
            fontWeight:"bolder",
            offsetCenter: [0, '0%'],
            borderColor: 'none',
            formatter: '{value}%'
          }
        }
      ]
    }
  }
}
