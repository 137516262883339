import {
  debounceTime,
  filter,
  map,
  switchMap,
  catchError,
  startWith,
} from "rxjs/operators";
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { SupportDataService } from "../../shared/support-data.service";
import { of as observableOf } from "rxjs";

@Component({
  selector: "app-direct-dialog",
  templateUrl: "./direct-dialog.component.html",
  styleUrls: ["./direct-dialog.component.scss"],
})
export class DirectDialogComponent implements OnInit {
  form = this.formBuilder.group({
    dock_space_id: ["", [Validators.required]],
  });

  optionsFilters: any = {
    dock_space: null,
  };

  dockFilterValue: string = "";
  dockFilter: UntypedFormControl = new UntypedFormControl();
  docks: any = [];
  searching = false;

  dockSpaces: any;
  spacesType: any[] = [];
  spaces = [];
  nameSpace: any;

  departments: any = [];
  hasDepartment = false;
  user: any;
  dockSpacesFiltered = [];
  department_id!: number;
  item: any;
  showComponent = false;

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public supportData: SupportDataService,
    public dialogRef: MatDialogRef<DirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const user = JSON.parse(localStorage.getItem("account")!);
    if (user) {
      this.user = user;
      if (this.user.department_id) {
        this.hasDepartment = true;
      }
    }
  }

  ngOnInit() {
    this.loadSpacesType();
    this.loadSpaces();
    this.loadDocks();
    this.initObservers();
  }

  loadDocksOnClick() {
    const options = {
      orderBy: "updated_at",
      sortedBy: "DESC",
      pageSize: 10,
      page: 1,
      department_id: this.data.item.resource.department_id,
      search: `${this.dockFilter.value}` || "",
    };

    this.supportData.dockSpaces(options).subscribe(({data: spaces}) => {
      this.docks =
        spaces.resource instanceof Array
          ? spaces.resource
          : spaces.resource[this.data.item.resource.department_id] || [];
      this.docks = this.filterSpace(this.docks);
      console.log('----docas');
      console.log(this.docks);
    })

  }

  getTitle(id: string) {
    return this.docks.find((dock: any) => dock.id === id)?.name || "";
  }

  initObservers() {
    this.form
      .get("dock_space_id")!
      .valueChanges.pipe(startWith(""))
      .subscribe((text) => (this.dockFilterValue = text));
  }

  loadDocks() {
    const subscribe = this.dockFilter.valueChanges
      .pipe(
        debounceTime(800),
        filter((search) => search != "" || this.docks.length == 0),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: "updated_at",
            sortedBy: "DESC",
            pageSize: 10,
            page: 1,
            department_id: this.data.item.resource.department_id,
            search: `${this.dockFilter.value}` || "",
          };
          return this.supportData.dockSpaces(options);
        }),
        map((response: any) => {
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.docks =
          data.resource instanceof Array
            ? data.resource
            : data.resource[this.data.item.resource.department_id] || [];
          this.docks = this.filterSpace(this.docks);
          console.log('----docas');
          console.log(this.docks);
          this.showComponent = true;
          subscribe.unsubscribe()
      });
    this.dockFilter.setValue("", { emitEvent: true });
  }

  filterSpace(docks:any):any {
      this.dockSpacesFiltered = docks.filter(
        (item: any) =>
          item.busy === false &&
          item.maintenance === false
      )
      return this.dockSpacesFiltered;
  }

  onSubmit() {
    const dialogResult = this.form.value;
    const dock = this.docks.find(
      (dock: any) => dock.id == dialogResult.dock_space_id
    );
    dialogResult.id = this.data.item.id;
    dialogResult.status = this.spacesType.find(
      (dockSpace: any) => dockSpace.id == dock.type
    )?.status;
    this.dialogRef.close(dialogResult);
  }

  //Busca os tipos de areas: docas, patios, ...
  loadSpacesType() {
    this.supportData.spacesType().subscribe(
      (r: any) => {
        this.spacesType = r.data.resource;

        if (
          this.data.filterVehicleType &&
          this.data.filterVehicleType === true &&
          this.data.item.resource.vehicle_type == 1
        ) {
          this.spacesType = this.spacesType.filter((item: any) => {
            if (item.id === "externas") {
              return true;
            } else {
              return false;
            }
          });
        }
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  loadSpaces() {
    this.supportData.dockSpaces().subscribe(
      (r: any) => {
        this.dockSpaces = r.data.resource;
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  getDockName(id: number, department: number) {
    var dockSpaces: any = [];

    this.supportData.dockSpaces().subscribe(
      (r: any) => {
        dockSpaces = r.data.resource;

        if (id >= 0) {
          if (
            this.user.department_id.length > 0 &&
            department &&
            this.user.system_client.resource.environment.docks_shared === false
          ) {
            let result: any[] = [];
            this.user.department_id.forEach((element: any) => {
              if (element == department)
                result = this.dockSpaces[element].filter(
                  (item: any) => item.id === id
                );
            });
            this.nameSpace = result[0].name;
          } else {
            if (
              this.user.department_id &&
              this.user.system_client.resource.environment.docks_shared ===
                false
            ) {
              const dn = this.dockSpaces[this.user.department_id].filter(
                (item: any) => item.id === id
              );
              if (dn.length > 0) {
                this.nameSpace = dn[0].name;
              }
            } else {
              const dn = this.dockSpaces.filter((item: any) => item.id === id);
              if (dn.length > 0) {
                this.nameSpace = dn[0].name;
              }
            }
          }
        }
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }
}
