<h3 class="mat-align-center" mat-dialog-title>{{ "Business.limite_schedule" | translate:{Default: "Limite de Agendamento"} }}</h3>

<div mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="form">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
    </form>
  </div>
</div>

<div mat-dialog-actions class="tkg-dialog-actions-end">
  <button mat-stroked-button color="warn" (click)="close()">{{ "Business_model.cancel" | translate:{Default: "Cancelar"} }}</button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSubmit()"
    [disabled]="!form.valid"
  >
    {{ "Business_model.save" | translate:{Default: "Salvar"} }}
  </button>
</div>
