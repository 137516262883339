import env from '../../environment.json';

export const environment = {
  production: env.production,
  appTitle: env.appTitle,
  apiUrl: env.apiUrl,
  wsUrl: env.wsUrl,
  whitelistedDomains: env.whitelistedDomains,
  keycloak: env.keycloak,
  oauth: {
    grantType: env.oauth.grantType,
    clientId: env.oauth.clientId,
    clientSecret: env.oauth.clientSecret,
    scope: env.oauth.scope
  }
};