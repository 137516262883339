import { HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

interface Data {
  iframe_src:string;
  iframe_query_params:{
    query_string:string,
    query_values:string[]
  };
  data: any;
  [key:string]:any
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {
  @ViewChild('iframeService') iframe: ElementRef<HTMLIFrameElement>;
  iframeSrc: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: Data,
    private dialogRef: MatDialogRef<GenericDialogComponent>
  ) { }

  @HostListener('window:message', ['$event'])
  onCloseDialog(event:MessageEvent) {
    if(this.data.iframe_src.startsWith(event.origin))
      if(Object(event.data).hasOwnProperty('close'))
        this.dialogRef.close(event.data);
  }

  ngOnInit() {
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.iframe_src + this.queryString());
  }

  private queryString():string{
    const data = this.data.data;
    let queryString = this.data.iframe_query_params ? this.data.iframe_query_params.query_string : "";
    let queryValues = this.data.iframe_query_params ? this.data.iframe_query_params.query_values : [];

    if(data && queryString && queryValues){
      const params = [];
      for(const query of queryValues){
        const value = query.split(".")
        .reduce((pivot:any, key:string)=>{
          try{
            if(pivot){
              pivot = pivot[key];
            }
            return pivot || "";
          }catch(ex){
            return "";
          }
        }, data)

        params.push(value instanceof Object ? JSON.stringify(value) : value);

      }

      params.forEach((value)=>{
        queryString = queryString.replace(/=\?/, '=' + value);
      });

      queryString = (this.data.iframe_src.match(/\?$/) ? "" : "?" ) + queryString.replace(/=\?/g,"=");
    }
    return queryString;
  }

}

