import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../account/shared/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-reset-detail',
  templateUrl: './password-reset-detail.component.html',
  styleUrls: ['./password-reset-detail.component.scss']
})
export class PasswordResetDetailComponent implements OnInit, OnDestroy {
  paramsWatcher!: Subscription;
  form!: UntypedFormGroup;
  errors: any;
  hidePassword = true;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  onSubmit() {
    this.accountService.passwordReset(this.form.value)
      .subscribe(data => {
          this.snackBar.open('Senha atualizada com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        },
        err => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {title: err.statusText, message: err.error.message}
            });
          }
        }
      );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      token: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]],
    });

    this.form.get('password')!.valueChanges.subscribe(value => {
      this.form.get('password_confirmation')!.setValue(value);
    });

    this.paramsWatcher = this.route.params.subscribe(params => {
      this.form.get('token')!.setValue(params['token']);
      this.form.get('email')!.setValue(params['email']);
    });
  }

  ngOnDestroy() {
    this.paramsWatcher.unsubscribe();
  }

}
