import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { MaskUtil } from 'src/app/shared/util/mask.util';
import { SupportDataDialogComponent } from '../support-data-dialog/support-data-dialog.component';


@Component({
  selector: 'app-support-data-list',
  templateUrl: './support-data-list.component.html',
  styleUrls: ['./support-data-list.component.scss']
})
export class SupportDataListComponent implements OnInit {
  maskUtil = MaskUtil;
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  search: string;
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<any>();
  data: any[] = [];
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  pageParam: string = '';
  constructor(
    private supportDataService: SupportDataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ['name', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ['name', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['name', 'actions'];
    }
  }

  ngOnInit(): void {
    this.pageParam = this.route.snapshot.paramMap.get('page') || '';
    this.getDataSource();
  }

  getDataSource() {
    this.supportDataService.generic({name: this.pageParam}).subscribe((r: any) => {
      this.dataSource = r.data.resource.filter((ft: any) => ft.active === true);
      // this.dataSource = r.data.resource;
    });
  }

  addEdit(event: any, action: string, item: any = {}) {
    const dialogRef = this.dialog.open(SupportDataDialogComponent, {
      panelClass: ['dialog-small', 'dialog-fullscreen'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        list_name: this.pageParam,
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (action === 'EDIT') {
          this.snackBar.open('Registro atualizado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
          this.dataSource = data.resource.filter((ft: any) => ft.active === true);
        } else if (action === 'ADD') {
          // If has previous page navigate to first page or reload in self page.
          this.dataSource = data.resource.filter((ft: any) => ft.active === true);
          // (this.dataSource.paginator.hasPreviousPage()) ? this.dataSource.paginator.firstPage() : this.getJustifications();
          this.snackBar.open('Registro criado com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  delete(event: any, data: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirma a exclusão?',
        message: `Tem certeza que deseja excluir o item ${data.name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const item = {
          list_name: this.pageParam,
          id: data.id
        }

        this.supportDataService.deleteItemOnList(item).subscribe((r: any) => {
          this.dataSource = r.data.resource.filter((ft: any) => ft.active === true);
          // this.dataSource = r.data.resource;
          this.snackBar.open('Item removido com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    });
  }
}
