<h2 mat-dialog-title>{{data.title}}</h2>

  <mat-dialog-content>
    <div class="file-types">
      <button type="button" mat-raised-button (click)="fileType('csv')" color="primary">
        CSV
      </button>
      <button fileType="button" mat-raised-button (click)="fileType('xlsx')" color="primary">
        XLSX
      </button>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close="true" color="warn">
      {{ "Business_model.close" | translate:{Default: 'Fechar'} }}
    </button>
  </mat-dialog-actions>

