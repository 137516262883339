<div class="week-days-list">
    <div
      *ngFor="let day of days; let i = index"
      class="day"
      [class.selected]="selectedDays.includes(i)"
      (click)="toggleDay(i)"
    >
      {{ day }}
    </div>
  </div>
  