<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <form class="tkg-form-input">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Nome do fluxo</mat-label>
      <input type="email" matInput [formControl]="nameFormControl">
      <mat-error *ngIf="nameFormControl.hasError('required')">
        Nome é <strong>obrigado</strong>
      </mat-error>
    </mat-form-field>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>{{data.btnCancel ? data.btnCancel : "Fechar"}}</button>
  <button mat-raised-button style="color: white;" color="primary" [mat-dialog-close]="true" [disabled]="!nameFormControl.valid" (click)="save()">{{data.btnConfirm ? data.btnConfirm : "Ok"}}</button>
</mat-dialog-actions>
