import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';


const dashboardRoutes: Routes = [
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/loreal/dashboard',
    component: DashboardListComponent,
    data: {
      title: 'Dashboard',
      search: true,
      refresh: true
    }
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(dashboardRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule {
}
