import { Timeslot } from "./../timeslot";
import { AgendamentoService } from "./../shared/agendamento.service";
import { DialogComponent } from "./../dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Dock } from "../dock";
import * as moment from "moment";
// import { GenericDialogComponent } from "../../checkin-loreal/generic-dialog/generic-dialog.component";
// import { FormlyComponent } from "../formly/formly.component";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit {
  @Input() accessLevel!: string;
  mode!: string;
  account!: any;;
  // timeslots!: Timeslot[];
  timeslots!: any;
  docks!: Dock[];
  dashboardHeight!: string;
  today!: string;
  definedDay!: string;
  dayForm = this.formBuilder.group({
    day: [new Date(), [Validators.required]],
  });
  constructor(
    public dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder
  ) {}

  switchDay() {
    this.getSchedules(this.dayForm.value.day.toISOString().slice(0, 10));
    this.definedDay = this.dayForm.value.day.toISOString().slice(0, 10);
  }


  openDialog(schedule: any): void {
    let is_past = false;

    if (this.definedDay == moment().format("YYYY-MM-DD")) {
      is_past = moment(schedule.start_at).unix() - moment().unix() < 0;
    }

    if (this.accessLevel === "carrier") {
      if (schedule.status === "available") {
        if (is_past) {
          this.snackBar.open("Escolha uma data futura", "Entendi", {
            duration: 5000,
          });
        } else {
          this.createDialog(schedule, this.accessLevel, "create");
        }
      }
      if (schedule.status === "waiting") {
        this.editDialog(schedule, this.accessLevel);
      }
      if (schedule.status === "confirmed") {
        this.showDialog(schedule, this.accessLevel);
      }
      if (schedule.status === "denied") {
        this.showDialog(schedule, this.accessLevel);
      }
    } else {
      if (schedule.status === "available") {
        this.createDialog(schedule, this.accessLevel, "create");
      } else {
        this.editDialog(schedule, this.accessLevel);
      }
    }
  }

  changeStatus(schedule: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      data: {
        schedule,
        definedDay: this.definedDay,
        action: "change",
        timeslots: this.timeslots
      },
    });

    dialogRef.afterClosed().subscribe(({ status, action }) => {
      // console.log(action)
      if (action === "change") {
        this.changeScheduleStatus(schedule, status);
      }
    });
  }

  editDialog(schedule: any, accessLevel: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      data: {
        schedule,
        accessLevel,
        definedDay: this.definedDay,
        action: "edit",
        timeslots: this.timeslots
      },
    });

    dialogRef.afterClosed().subscribe(({ data, action }) => {
      if (action === "cancel") {
        this.getSchedules(this.definedDay);
      }
      if(action == 'denied') {
        this.snackBar.open("Agendamento negado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
      if(action == 'confirmed') {
        this.snackBar.open("Agendamento confirmado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
      if (action === "edit") {
        this.snackBar.open("Agendamento realizado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
    });
  }

  showDialog(schedule: any, accessLevel: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      disableClose: true,
      data: {
        schedule,
        accessLevel,
        definedDay: this.definedDay,
        action: "show",
        timeslots: this.timeslots
      },
    });
  }

  createDialog(schedule?: any, accessLevel?: any, action?: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "900px",
      data: {
        schedule,
        accessLevel,
        definedDay: this.definedDay,
        action: action,
        timeslots: this.timeslots
      },
    });


    dialogRef.afterClosed().subscribe(({ action }) => {
      if (action === "create") {
        this.snackBar.open("Agendamento realizado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      }
      if (action === "change") {
        this.snackBar.open("Agendamento atualizado com sucesso", "OK", {
          duration: 5000,
        });
        this.getSchedules(this.definedDay);
      } else {
        // console.log("Not Create");
      }
    });
  }

  ngOnInit() {
    this.today = moment().format("YYYY-MM-DD");
    this.account = JSON.parse(localStorage.getItem('account')!);
    this.definedDay = this.today;
    // console.log("this.today");
    // console.log(this.today);
    this.getTimeSlots();
  }

  ngAfterViewInit() {
    this.getSchedules(this.definedDay);
  }

  changeScheduleStatus(schedule: any, status: any) {
    let resource = schedule;
    resource.status = status;
    let id = schedule.id;
  }

  getTimeSlots() {
    // console.log('entrei getTimeSlots')
    this.agendamentoService.time_slots().subscribe((timeslots: any) => {
      const hours = [];
      const begin_at = parseInt(timeslots["start_at"].split(":"));
      const ends_at = parseInt(timeslots["ends_at"].split(":"));
      const interval = parseInt(timeslots["interval"]);

      for (let hour = begin_at; hour < ends_at; hour++) {
        hours.push({ hour: moment({ hour }) });
        if(interval < 60){
          hours.push({
            hour: moment({
              hour,
              minute: interval,
            }),
          });
        }
      }

      hours.push({ hour: moment({ hour: ends_at }) });

      this.timeslots = hours;
      this.dashboardHeight = `${
        (this.timeslots.length + 1) * 50 + this.timeslots.length
      }px`;
    });
  }

  download(): void {
    let options = { day:  this.definedDay};
    let day = moment(this.definedDay, 'YYYY-MM-DD').format('DD/MM/YYYY');
    let title = `Relatório ${day}.csv`
    this.agendamentoService.download(options)
      .subscribe(
        (val) => {
          // console.log('gera arquivo');
          this.createFileFromBlob(val, 'report.xls');
        },
        response => {
          this.createFileFromBlob(response.error.text, title)
        },
        () => {
          console.log('erro')
        });
  }

  createFileFromBlob(file: any, fileName: string) {
    // console.log('gerando');
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob([file], { type: 'text/csv' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    // const blob = new Blob([file], { type: 'text/csv' });
    // const url = window.URL.createObjectURL(blob);
    // window.open(url);
  }

  getSchedules(day: any) {
    let options = { day };
    this.agendamentoService.index(options).subscribe((docks: any) => {
      this.docks = docks.map((dock: any) => {
        let newDock = dock;
        newDock.schedules = JSON.parse(dock.events);
        let newSchedules: any = [];
        this.timeslots.map(({ hour }: any) => {
          let result = null;
          if (newDock.schedules) {
            result = newDock.schedules.find(
              (item: any) =>
                moment(item.schedule_time).format("HH:mm") ==
                moment(hour).format("HH:mm")
            );
          }

          if (result) {
            if(this.accessLevel == 'carrier' && result.carrier_id != this.account!.carrier_id) {
              result.status = 'blocked';
            }
            result.documents = JSON.parse(result.documents) || [];
            newSchedules.push(result);
          } else {
            let newSchedule = {
              id: null,
              event_id: null,
              dock_id: dock.dock_id,
              dock_name: dock.dock_name,
              start_at: moment(hour).toISOString(),
              end_at: null,
              name: null,
              driver_name: null,
              carrier_name: null,
              status: "available",
            };
            newSchedules.push(newSchedule);
          }
        });

        newDock.schedules = newSchedules;

        return newDock;
      });

      this.docks = docks;
    });
  }
}
