<!--Card Dashboard - Inicio desenvolvimento 23/05/2022-->
<mat-card class="card">
  <!--Title-->
  <mat-card-title class="title" >{{data.title}}</mat-card-title>
  <mat-divider inset></mat-divider>
  <!--Content/Body-->
    <mat-card-content class="mat-card-content">
     <div class="span1">
        <h4>{{data.label1}}</h4>
        <span>{{value1}}</span>
     </div>
     <div class="span2">
       <h4>{{data.label2}}</h4>
        <span>{{value2}}</span>
     </div>
    </mat-card-content>
</mat-card>
