import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {  MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AgendamentoService } from '../../agendamento/shared/agendamento.service';
import { webSocket } from "rxjs/webSocket";
import { environment } from "src/environments/environment.prod";
import { MapsGenericComponent } from '../../shared/maps-generic/maps-generic.component';
import { CheckinService } from "../../checkin/shared/checkin.service";
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
// import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component'
@Component({
  selector: 'app-events-planed-list',
  templateUrl: './events-planed-list.component.html',
  styleUrls: ['./events-planed-list.component.scss']
})
export class EventsPlanedListComponent implements OnInit {
  account: any;
  search: string;
  pageSize: number;
  cards: any;

  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<any>();
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  isWeb = true;
  filterStatusSelected: UntypedFormControl = new UntypedFormControl();
  filterOperationSelected: UntypedFormControl = new UntypedFormControl();
  filterCarrierSelected: UntypedFormControl = new UntypedFormControl();
  filterVehicleTypeSelected: UntypedFormControl = new UntypedFormControl();
  actualPage: string;
  showCarrierFilter: boolean = false;
  showOperationFilter: boolean = false;
  showStatusFilter: boolean = false;
  ShowSearchFilter: boolean = false;
  showInStatus: any = ['waiting'];
  listStatus:any[]=[];

  journeyStatus = [
    {id: 1, value:"not_started", label: "Não iniciada", color: "#5B656D"},
    {id: 2, value:"started", label: "Em deslocamento", color: "#26A0FC"},
    {id: 3, value:"waiting", label: "Aguardando", color:"#FCC326"},
    {id: 4, value:"on_dock", label: "Na doca", color: "#419A59"},
    {id: 5, value:"document_confirmed", label: "Em doca", color: "#E93093"},
    {id: 6, value:"finish", label: "Finalizada"}
  ]

  displacementStatus = [
    {id: 1, value: "delayed", label: "Atrasado", color: "#EC273B"},
    {id: 2, value: "on_time", label: "No prazo", color: "#5AD545"},
    {id: 3, value: "ahead", label: "Adiantado", color: "#7D77FF"},
    {id: 4, value: "no_prevision", label: "Sem previsão", color: "#5B656D"}
  ]

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  protected _onDestroy = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    public checkinService: CheckinService,
    public agendamentoService: AgendamentoService
  ) {
    this.account = JSON.parse(localStorage.getItem('account') || "{}");

    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
   }

   activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset) || this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      this.displayedColumns = ['board', 'vehicle_type', 'lacre', 'event_id', 'carrier', 'status', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.displayedColumns = ['board', 'vehicle_type', 'lacre', 'event_id', 'carrier', 'status', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['board', 'vehicle_type', 'lacre', 'event_id', 'carrier', 'status', 'actions'];
    }
  }

  ngOnInit() {
    this.actualPage = this.router.url;
    const subject = webSocket({url: `${environment.wsUrl}/checkin/${this.account?.system_client?.id}`, deserializer: () => {}});
    subject.subscribe(
      (msg:any) => {
        this.loadPlanedEvents()
      }
    );
    this.loadPlanedEvents()
  }

  loadPlanedEvents() {
    let options = {pageSize: 50}
    this.agendamentoService.planedEvents(options).subscribe((r:any)=>{
      this.cards = r.data
      console.log(this.cards)
    })
  }

  showJourneyStatus(status:string) {
    if(status){
      let st = this.journeyStatus.filter((f:any)=>{
        if(f.value === status) {
          return f
        }
      })

      return st[0]
    } else {
      return {id: 1, value:"not_started", label: "Não iniciada", color: "#5B656D"}
    }

  }

  showDisplacementStatus(status:string) {
    if(status){
      return this.displacementStatus.filter((f:any)=>f.value === status)[0];
    }
    return undefined;
  }

  openMap(type:string, id: number){
    const dialogRef = this.dialog.open(MapsGenericComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: {type, id}
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  updateStatus(checkin_id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirmar Ação!",
        message: "Tem certeza que deseja 'Confirmar documentos'",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const options = {
          id: checkin_id,
          status: 'document_confirmed'
        }
        this.checkinService.updateStatus(options).subscribe(
          (r: any) => {
            this.loadPlanedEvents();
            this.snackBar.open("Status alterado para 'Em doca'", "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          },
          (e: any) => {
            console.log("erro afterClosed");
            console.log(e);
          }
        );
      }
    });
  }

  getFilterStatus(value:any){

  }
  clearFilter(event:Event){

  }
}
