import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length === 14) {
      const first_dot = value.substr(0, 2);
      const second_dot = value.substr(2, 3);
      const third_dot = value.substr(5, 3);
      const the_bar = value.substr(8, 4);
      const hyphen = value.substr(12);
      return `${first_dot}.${second_dot}.${third_dot}/${the_bar}-${hyphen}`;
    }
    return value;
  }
}
