<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{
      form.value.name
        ? form.value.name
        : data.action == "EDIT"
        ? "Salvar"
        : "Adicionar"
    }}
  </h2>
  <!-- <h2>Adicionar</h2> -->
  <mat-dialog-content class="mat-typography">
    <div>
      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="name"
          matInput
          placeholder="Local"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.code">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="cnpj"
          matInput
          placeholder="CNPJ"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.code">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="tkg-form-item">
        <mat-label>Tipo de parada</mat-label>
        <mat-select formControlName="place_type_id">
          <mat-option *ngFor="let place of placeTypes" [value]="place.id">
            {{place.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="code"
          matInput
          placeholder="CEP"
          (change)="buscaCEP()"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.code">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="address"
          matInput
          placeholder="Endereço"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.address">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>

    </div>

    <div>

      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="number"
          matInput
          placeholder="Número"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.number">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="complement"
          matInput
          placeholder="Complemento"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.number">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>
    </div>
    <div>

      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="district"
          matInput
          placeholder="Bairro"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.district">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="city"
          matInput
          placeholder="Cidade"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.number">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field class="tkg-form-item">
        <input
          type="text"
          formControlName="state"
          matInput
          placeholder="Estado"
        />
        <!-- <mat-hint class="mat-error">
          <div *ngFor="let error of errors.number">{{ error }}</div>
        </mat-hint> -->
      </mat-form-field>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>
      Fechar
    </button>
    <button type="submit" mat-button color="primary">
      {{ data.action == "EDIT" ? "Salvar" : "Adicionar" }}
    </button>
    <!-- <button type="submit" mat-button color="primary" [disabled]="!form.valid">
      {{ data.action == "EDIT" ? "Salvar" : "Adicionar" }}
    </button> -->
  </mat-dialog-actions>
</form>
