import { RouteParamsChangeService } from "./../../shared/route-params-change.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ActivatedRoute, Router } from "@angular/router";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { StepsFilterService } from "src/app/shared/steps-filter.service";
import { StepsFilterDialogComponent } from "src/app/shared/steps-filter-dialog/steps-filter-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
import { DockAssignDialogComponent as DirectDialogComponent } from "src/app/docks/dock-assign-dialog/dock-assign-dialog.component";
import { InvalidateVehicleDialogComponent } from "../invalidate-vehicle-dialog/invalidate-vehicle-dialog.component";
import { AditionalInfoDialogComponent } from "../aditional-info-dialog/aditional-info-dialog.component";
import { FinishOptionsDialogComponent } from "../finish-options-dialog/finish-options-dialog.component";
import { EditKeysDialogComponent } from "../edit-keys-dialog/edit-keys-dialog.component";
import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";
import { OtherMotivesGenericDialogComponent } from "../other-motives-generic-dialog/other-motives-generic-dialog.component";
import { DialogChangeOperation } from "src/app/shared/dialog-change-operation/dialog-change-operation.component";
import { ReleaseDockDialogComponent } from "../release-dock-dialog/release-dock-dialog.component";
import { InfoFormDialogComponentComponent } from "../info-form-dialog-component/info-form-dialog-component.component";
import { ShowInfoDialogComponent } from "../show-info-dialog/show-info-dialog.component";
import { DocumentsDialogComponent } from "src/app/shared/documents-dialog/documents-dialog.component";
import { ScheduleDinamicFormDialogComponent } from "src/app/scheduling/schedule-dinamic-form-dialog/schedule-dinamic-form-dialog.component";
import { DialogUnlinkDriverComponent } from "src/app/shared/dialog-unlink-driver/dialog-unlink-driver.component";
import { DialogConfirmValetComponent } from "src/app/shared/dialog-confirm-valet/dialog-confirm-valet.component";
import { DialogLinkDriverComponent } from "src/app/shared/dialog-link-driver/dialog-link-driver.component";
import { CheckinDriverParkingLotComponent } from "../checkin-driver-parking-lot/checkin-driver-parking-lot.component";
import { GenericDialogComponent as MicroserviceDialog } from "../../micro-services/generic-dialog/generic-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DynamicDialogComponent } from "src/app/shared/dynamic-dialog/dynamic-dialog.component";
import { Observable, Subscription, auditTime, debounceTime, map } from "rxjs";
import { SearchService } from "src/app/shared/search.service";
import { WSService2 } from "src/app/shared/websocket/wss2.service";
import { Howl } from "howler";
import { environment } from "src/environments/environment";
import { DepartmentService } from "./../../shared/department.service";
import { SoundService } from "src/app/shared/sound.service";
import { AssignQualcommDeviceComponent } from "src/app/shared/assign-qualcomm-device/assign-qualcomm-device.component";
import { ExceptionsControlDialogComponent } from "src/app/shared/exceptions-control-dialog/exceptions-control-dialog.component";
import { FlowDialogComponent } from "src/app/shared/flow/flow-dialog.component";
import { WeightDialogComponent } from "src/app/shared/weight-dialog/weight-dialog.component";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { CallToActionConfirmComponent } from "src/app/shared/call-to-action-confirm/call-to-action-confirm.component";
import JourneyStatusTimelineRender from "src/app/timelines/journey-status-timeline/journey-status-timeline-render";

@Component({
  selector: "app-checkin-list-mobile",
  templateUrl: "./checkin-list-mobile.component.html",
  styleUrls: ["./checkin-list-mobile.component.scss"],
})
export class CheckinListMobileComponent implements OnInit, OnDestroy {
  msgBlock = false;
  message: string;
  actualPage: any;
  columnSize: number;
  m = true;
  schema!: any;
  datasource: any = [];
  showData = false;
  listAction: any = [];
  account: any;
  filterWatcher: any;
  ftOptions: any;
  departments: any = [];
  appliedFilters: any = {
    operation_id: [],
    status: [],
    carrier_id: "",
    dock_space_id: [],
  };
  searchWatcher: any;
  searchString: any;
  routeParamsChangedWatcher: any;
  updated_at: number = 2000;
  sound: any;
  websocketWatcher: any;
  needsRefreshOnWsMessage = false;
  reloadingDataSource = false;
  pauseRefreshOnWsMessage = false;
  driverCheckinIdList: any[] = [];
  // actionListSubscription: Subscription;
  departmentSubscription: Subscription;
  dataSourceSubscription: Subscription;
  schemaSubscription: Subscription;
  listActionRef: any;
  operations: any;
  listStatus: any;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private checkinService: CheckinService,
    private routeParamsChangeService: RouteParamsChangeService,
    private departmentService: DepartmentService,
    private supportDataService: SupportDataService,
    private stepsFilterService: StepsFilterService,
    private searchService: SearchService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private wssService: WSService2,
    private soundService: SoundService,
    private agendamentoService: AgendamentoService,
  ) {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.route.paramMap.subscribe((params) => {
      this.actualPage = params.get("page")!;
    });
    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.XSmall,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  ngOnInit() {
    // console.log('Inicia o ciclo de vida')
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "visible") {
          console.log("Reconecting websocket...");
          this.wssService.closeConnection();
          this.subscribeWebsocket();
          this.pauseRefreshOnWsMessage = false;
          this.needsRefreshOnWsMessage = true;
        }
      },
      false,
    );
    this.getSupportData();
    this.getSchema();
    this.activateLayout();
    // this.getDepartments();

    this.soundService.changeSoundState(this.account.has_sound, this.account.id);

    this.filterWatcher = this.stepsFilterService.show.subscribe(
      (filter: any) => {
        this.showDialogFilters();
      },
    );

    this.searchWatcher = this.searchService.value.subscribe((search) => {
      this.searchString = search.value;
      this.getDataSource("searchWatcher");
    });

    this.routeParamsChangedWatcher =
      this.routeParamsChangeService.changed.subscribe((item) => {
        this.clearAllFilters();
        this.countNumberOfFilters(this.appliedFilters);
        // Requisição secundária após detectar o evento de mudança de parâmetro da página
        this.getDataSource("routeParamsChangedWatcher");
        this.getActions();
        // this.getDepartments();
      });

    this.subscribeWebsocket();
    // this.getActions();
  }

  subscribeWebsocket() {
    // console.log("subscribeWebSocket");
    let id = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wssService.CHANNEL = `/checkin/${id}`;
    this.wssService.connect().subscribe((msg: any) => {
      // console.log("chegou mensagem");
      // console.log(msg);
      const wsMsg: any = Object.assign({}, msg);
      if (wsMsg.hasOwnProperty("action")) {
        if (wsMsg.action === 2) {
          // console.log("action 2");
          // Update -> Define mensagem de update
          // Verifica quais status são visíveis na página
          const statusShowedInThisPage =
            this.account.system_client.resource.environment.pages[
              this.actualPage
            ]["with_status"];
          console.log(statusShowedInThisPage);
          // Chama atualização se: 1) o status da atualização está incluso na lista de status da página;
          //  2) Se o driver_checkin_id está sendo mostrado na listagem (Implementar depois)
          // (porém pode ser um novo registro para ser adicionado a página)
          if (statusShowedInThisPage.includes(wsMsg.status)) {
            this.evaluateDataUpdate(wsMsg);
          } else {
            // console.log("Status de outra página");
            // Status diz respeito a outra página, então verifica se o driver_checkin_id está sendo exibido na tela. Se sim, faz o update
            if (this.driverCheckinIdList.includes(wsMsg.driver_checkin_id)) {
              // ToDo: remover apenas o id do objeto
              this.evaluateDataUpdate({ msg: null });
            }
          }
        } else {
          // Após implementação na API do app, remover este trecho (else) e descomentar o trecho acima
          if (wsMsg.msg) {
            this.snackBarMessage(wsMsg);
          } else {
            this.snackBarMessage("Novo Check in realizado");
          }
          this.evaluateDataUpdate({ msg: null });
        }
      } else {
        this.evaluateDataUpdate({ msg: null });
      }
    });
  }

  evaluateDataUpdate(wsMsg: any) {
    if (
      // this.trigger?.menuOpen === false &&
      this.reloadingDataSource === false &&
      this.pauseRefreshOnWsMessage === false
    ) {
      console.log("Atualizado via ws");
      this.getDataSource("evaluateDataUpdate");
      if (wsMsg.user_id != this.account.id) {
        if (wsMsg.msg) {
          this.snackBarMessage(wsMsg.msg);
        } else {
          this.snackBarMessage("Check in atualizado");
        }
      }
    } else {
      console.log("Atualização bloqueada");
      this.needsRefreshOnWsMessage = true;
    }
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      // console.log('smart phone');
      this.columnSize = 1;
      this.router.navigate([`admin/checkins-mobile/${this.actualPage}`]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      // console.log('tablet portrait');
      this.columnSize = 2;
      this.router.navigate([`admin/checkins-mobile/${this.actualPage}`]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      // console.log('tablet landscape');
      this.columnSize = 3;
      this.router.navigate([`admin/checkins-mobile/${this.actualPage}`]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.router.navigate([`admin/checkins/${this.actualPage}`]);
    }
  }

  getSchema() {
    this.schemaSubscription = this.checkinService
      .schema({ m: this.m })
      .subscribe((r: any) => {
        if (r.error) {
          this.msgBlock = true;
          this.message = r.message;
        } else {
          this.msgBlock = false;
          this.schema = r.data;
          // this.showData = true;
          // Requisição inicial
          this.getDataSource("getSchema");
        }
      });
  }

  clearAllFilters() {
    this.appliedFilters = { operation_id: [], status: [], carrier_id: "" };
    this.searchString = "";
  }

  getDataSource(origin: string) {
    console.log("busca dados");
    console.log(origin);
    this.reloadingDataSource = true;
    this.pauseRefreshOnWsMessage = true;
    const dateControl = Date.now();
    // Controle para evitar requisões para a API antes de 1 segundo
    if (dateControl - this.updated_at > 1000) {
      const pageOptions =
        this.account.system_client.resource.environment.pages[this.actualPage!];
      let listStatus = pageOptions.with_status.join("|");

      const options = {
        m: this.m,
        with_status: listStatus,
        operation_id: this.appliedFilters.operation_id
          ? this.appliedFilters.operation_id.join("|")
          : "",
        status: this.appliedFilters.status
          ? this.appliedFilters.status.join("|")
          : "",
        carrier_id: this.appliedFilters.carrier_id
          ? this.appliedFilters.carrier_id
          : "",
        dock_space_id: this.appliedFilters.dock_space_id
          ? this.appliedFilters.dock_space_id.join("|")
          : "",
        suboperation: "",
        motorista_destino: "",
        search: this.searchString || "",
      };

      if (pageOptions.with_operations) {
        Object.assign(options, {
          with_operations: pageOptions.with_operations.join("|"),
        });
      }

      this.dataSourceSubscription = this.checkinService
        .steps(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.msgBlock = true;
            this.message = r.message;
            this.datasource = r.data;
          } else {
            this.msgBlock = false;
            this.datasource = r.data;
          }
          this.reloadingDataSource = false;
          this.pauseRefreshOnWsMessage = false;
          this.needsRefreshOnWsMessage = false;
          this.updated_at = Date.now();
          this.showData = true;
        });
    }
  }

  getActions() {
    if (this.listActionRef) {
      this.listAction = this.listActionRef[this.actualPage!];
    }
  }

  performAction(event: any) {
    this.changeStatus(event.datasource, event.action);
  }

  showDialogFilters() {
    this.pauseRefreshOnWsMessage = true;
    const dialogRef = this.dialog.open(StepsFilterDialogComponent, {
      data: { page: this.actualPage, fts: this.appliedFilters },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Aplica os filtros
        this.appliedFilters = result;
        // Conta a quantidade de filtros e publica no serviço
        this.countNumberOfFilters(this.appliedFilters);
        // Busca os dados com os filtros aplicados

        this.getDataSource("showDialogFilters after close");
      } else {
        this.pauseRefreshOnWsMessage = false;
        if (this.needsRefreshOnWsMessage && !this.pauseRefreshOnWsMessage) {
          this.getDataSource("showDialogFilters after close 2");
        }
      }
    });
  }

  countNumberOfFilters(filters: any) {
    const filtersKeys = Object.keys(this.appliedFilters);
    let qtdFts: any[] = [];
    filtersKeys.forEach((key: string) => {
      if (this.appliedFilters[key]) {
        if (Array.isArray(this.appliedFilters[key])) {
          if (this.appliedFilters[key].length > 0) {
            qtdFts.push(1);
          }
        } else if (this.appliedFilters[key] != null) {
          qtdFts.push(1);
        }
      }
    });
    this.stepsFilterService.onChange(qtdFts.length.toString(), "");
  }

  getDepartments() {
    this.departmentSubscription = this.departmentService
      .index()
      .subscribe((r) => {
        this.departments = r.data.resource;
        this.departments = this.departments.filter((item: any) => {
          return this.account.department_id?.indexOf(item.id) > -1;
        });
      });
  }

  changeStatus(item: any, action: any) {
    const preData = {
      id: item.id,
      status: action.status,
    };
    const data: any = Object.assign(
      {
        change_key: action.change_key,
      },
      preData,
    );

    const execAction = () => {
      if (action.component === "confirm") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
            btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
            btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(data, action);
          }
        });
      }

      if (action.component === "direction") {
        if (!item.hasOwnProperty("event")) {
          item.event = { resource: { external_event_id: null } };
        }
        const dialogRef = this.dialog.open(CheckinDriverParkingLotComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (data) {
            this.updateStatus(res, action);
          }
        });
      }

      //Direcionar com opção de multiplos patios ou docas
      if (action.component === "directionMultiplePark") {
        const dialogRef = this.dialog.open(DirectDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
            department_id: item.resource.department_id,
            dock_space_id: item.resource.dock_space_id,
            vehicle_type: item.resource.vehicle_type,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            if (action.without_status === true) {
              delete res.status;
              this.updateField(res, action);
            } else {
              this.updateStatus(res, action);
            }
          }
        });
      }
      //Direcionar com opção de multiplos patios ou docas
      if (action.component === "directionMultipleVehicleType") {
        const dialogRef = this.dialog.open(DirectDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            filterVehicleType: true,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }

      if (action.component === "invalidateVehicleDialog") {
        const dialogRef = this.dialog.open(InvalidateVehicleDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Change status");
            this.updateStatus({
              id: res.checkin_id,
              checklist_id: res.checklist_id,
            });
          }
        });
      }

      if (action.component === "aditionalInfo") {
        const dialogRef = this.dialog.open(AditionalInfoDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            title: action.dialog_title,
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }

      if (action.component === "finishOptions") {
        const dialogRef = this.dialog.open(FinishOptionsDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }

      // Buscar alternativa para mapa
      if (action.component === "driverOnMap") {
      }

      if (action.component === "editKeys") {
        const dialogRef = this.dialog.open(EditKeysDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((data) => {
          if (data.message) {
            this.snackBar.open(data.message, "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          }
          this.getDataSource("Chage status 2");
        });
      }

      if (action.component === "genericDialog") {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Chage status 3");
          }
        });
      }

      if (action.component === "otherMotivesGenericDialog") {
        const dialogRef = this.dialog.open(OtherMotivesGenericDialogComponent, {
          panelClass: ["dialog-medium"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            // refresh: () => this.getDataSource("Chage status 4"),
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          this.updateStatus(res, action);
        });
      }

      if (action.component === "changeOperation") {
        const dialogRef = this.dialog.open(DialogChangeOperation, {
          data: {
            title: "Escolha a operação desejada",
            message: action.dialogue_message,
            item: item,
          },
        });
        data["changeOperation"] = true;

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Chage status 5");
          }
        });
      }

      if (action.component === "releaseDock") {
        const dialogRef = this.dialog.open(ReleaseDockDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((data) => {
          this.getDataSource("Chage status 6");
        });
      }

      if (action.component === "infoForm") {
        const dialogRef = this.dialog.open(InfoFormDialogComponentComponent, {
          panelClass: ["dialog-medium"],
          disableClose: false,
          data: {
            title: action.dialog_title,
            message: action.dialogue_message,
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res == 200) {
            this.updateStatus(data, action);
          }
        });
      }

      if (action.component === "dynamicForm") {
        this.getDynamicForm(action, item);
      }

      if (action.component === "showInfo") {
        const dialogRef = this.dialog.open(ShowInfoDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            info_fields: action.info_fields,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {});
      }

      if (action.component === "showDocuments") {
        const dialogRef = this.dialog.open(DocumentsDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          this.getDataSource("Chage status 7");
        });
      }

      if (action.component === "ScheduleDinamicFormDialog") {
        const dialogRef = this.dialog.open(ScheduleDinamicFormDialogComponent, {
          panelClass: ["dialog-medium"],
          disableClose: false,
          data: {
            schedule: {
              id: item.event.id,
              ...JSON.parse(JSON.stringify(item.event.resource)),
            },
            action: null,
            page: "checkin",
          },
        });

        dialogRef.componentInstance.forkJoinDone$
          .pipe(debounceTime(1000))
          .subscribe(() => {
            dialogRef.componentInstance.config_scheduling = {
              edit: false,
              create: false,
              delete: false,
            };

            dialogRef.componentInstance.scheduleActions = [];

            dialogRef.componentInstance.form.disable();
          });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Chage status 8");
          }
        });
      }

      if (action.component === "unlinkDriver") {
        const dialogRef = this.dialog.open(DialogUnlinkDriverComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Desvincular Motorista",
            message: action.dialogue_message,
            item: item,
          },
        });
        data["unlinkDriver"] = true;

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Chage status 9");
          }
        });
      }

      if (action.component === "valetConfirm") {
        const dialogRef = this.dialog.open(DialogConfirmValetComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Confirmar Ação",
            message: item.resource.valet_mode_activated
              ? "Deseja desabilitar o 'Modo Manobra'?"
              : "Deseja habilitar o 'Modo Manobra'?",
            item: {
              id: item.resource.id,
              valetMode: item.resource.valet_mode_activated,
            },
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Chage status 10");
          }
        });
      }

      if (action.component === "linkDriver") {
        const dialogRef = this.dialog.open(DialogLinkDriverComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Vincular motorista",
            message: action.dialogue_message,
            item: item,
          },
        });
        data["linkDriver"] = true;

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("Chage status 11");
          }
        });
      }

      if (action.component === "driverCkeckout") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: "Realmente deseja fazer Checkout desse motorista?",
            btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
            btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.truckerCheckout(data, action);
          }
        });
      }

      if (action.component === "assignQualcommDevice") {
        const dialogRef = this.dialog.open(AssignQualcommDeviceComponent, {
          panelClass: ["dialog-small"],
          data: {
            checkin_id: item.id,
            cq: "qualcomm_devices",
            device: item.resource.device,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getDataSource("assignQualcommDevice");
          }
        });
      }

      if (action.component === "getpdf") {
        this.print(item.id);
      }

      if (action.component === "removeQualcommDevice") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Confirmar Ação!",
            message: "Tem certeza que deseja 'Recuperar Rastreador'?",
            btnConfirm: "SIM",
            btnCancel: "NÃO",
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            if (item.resource.hasOwnProperty("device")) {
              let device = item.resource.device;
              let opt: any = {
                status: "device_unlinked",
                device_hystory: device,
                device: {
                  linked: false,
                  unlinked_by: this.account.id,
                  unlinked_at: new Date().toISOString(),
                },
              };

              this.checkinService
                .updateStatus({ id: item.id, ...opt })
                .subscribe((r: any) => {
                  this.getDataSource("removeQualcommDevice");
                });
            } else {
              console.log("Não existe dispositivo vinculado");
            }
          }
        });
      }

      if (action.component === "weightControl") {
        this.getWeightForm(action, item);
      }

      if (action.component === "flowControl") {
        this.getFlowControl(action, item);
      }

      if (action.component === "exceptionControl") {
        this.getExceptionControl(action, item);
      }

      if (
        (<string>action.component || "").toLowerCase() === "microservicedialog"
      ) {
        const dialogRef = this.dialog
          .open(MicroserviceDialog, {
            data: {
              data: item,
              ...action.micro_service,
            },
            ...(action.dialog_config || {}),
          })
          .afterClosed()
          .subscribe((res) => {});
      }

      if (
        <string>action?.component?.toLowerCase() === "journeytimelinestatus"
      ) {
        const events =
          this.listStatus
            ?.map((status: any) => {
              const timestamp = item.resource?.[`${status.id}_created_at`];
              const description = `Criardo por: ${item.resource?.[`${status.id}_created_by`] ?? "Não informado."}`;

              if (!timestamp) return null;

              return {
                id: status.id,
                timestamp: new Date(timestamp),
                title: status.name,
                description,
                itemPosition: "",
              };
            })
            ?.filter((item: any) => item) || [];

        const data = {
          checkin_id: item.id,
          operation_name: this.nameOfOperation(
            item.resource.operation || item.resource.operation_id,
          ),
          events,
        };
        JourneyStatusTimelineRender.open(data, action.props);
      }

      if (action.component === "callToAction") {
        const dialogRef = this.dialog.open(CallToActionConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
            btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
            btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
            id: item.id,
            component_props: action.component_props,
            status: action.status,
            device_id: item.resource.device.device_id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.snackBar.open(res.message, "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          }
        });
      }
    };

    if (action.open_dialogue) {
      if (
        item.resource.valet_mode_activated == true &&
        action.component != "valetConfirm"
      ) {
        this.moduleValetDriversAvailable(<number>item.id).subscribe((bool) => {
          bool && execAction();
        });
      } else {
        execAction();
      }
    }
  }

  updateField(data: any, action?: any) {
    this.checkinService.updateField("checkins-update-field", data).subscribe({
      next: (r: any) => {
        this.getDataSource("UpdateField");
        if (action) {
          this.snackBar.open(action.name || "Check in atualizado.", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error: (e: any) => {
        this.getDataSource("UpdateField error");
      },
    });
  }

  updateStatus(data: any, action?: any) {
    // let rowValue = this.rawData.find((x: any) => x.id == data.id);
    let rowValue: any = {};
    let snackBarMessage =
      "Status alterado para " + (action || { name: "" }).name;

    if (
      this.account.system_client.resource.environment.module_valet &&
      rowValue.resource.valet_mode_activated
    ) {
      data.action_payload = {
        ...data,
        department_id: rowValue.resource.department_id,
      };

      if (action && action.module_valet_action)
        data.module_valet_action = action.module_valet_action;

      snackBarMessage =
        "Manobra registrada para " + (action || { name: "" }).name;
    }

    this.checkinService.updateStatus(data).subscribe({
      next: (r: any) => {
        this.getDataSource("Chage status 11");
        if (action) {
          this.snackBar.open(snackBarMessage, "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error: (err: any) => {
        if (err.status === 422) {
          this.dialog.open(DialogAlertComponent, {
            data: {
              title: "Ops! Tem algo errado",
              message: Object.values(err.error.errors)
                .map((item: any) => item.join("\n"))
                .join("\n"),
            },
          });
        } else {
          this.dialog.open(DialogAlertComponent, {
            data: {
              title: "Ops! Tem algo errado",
              message:
                "Desculpe, tivemos um erro interno. Se o erro permanecer contate nosso suporte.",
            },
          });
        }
        this.getDataSource("Chage status 12");
      },
    });
  }

  getDynamicForm(action: any, item: any) {
    const dialogRef = this.dialog.open(DynamicDialogComponent, {
      panelClass: [action.dialog_size],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: action.dynamic_form_name,
        module: "checkin",
        message: action.dialogue_message,
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getDataSource("getDynamicForm");
    });
  }

  getWeightForm(action: any, item: any) {
    const dialogRef = this.dialog.open(WeightDialogComponent, {
      panelClass: ["dialog-medium"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getDataSource("getWeightForm");
    });
  }

  getFlowControl(action: any, item: any) {
    const dialogRef = this.dialog.open(FlowDialogComponent, {
      panelClass: ["dialog-small-plus"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getDataSource("getFlowControl");
    });
  }

  getExceptionControl(action: any, item: any) {
    const dialogRef = this.dialog.open(ExceptionsControlDialogComponent, {
      panelClass: ["dialog-full"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getDataSource("getExceptionControl");
    });
  }

  private moduleValetDriversAvailable(checkin_id: number): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.checkinService
        .verifyAvalaibleValetDriver(checkin_id)
        .pipe(map((x) => x?.data))
        .subscribe((response: any[] = []) => {
          if (!response || response.length == 0) {
            this.dialog
              .open(DialogConfirmComponent, {
                data: {
                  title: "Confirmar Ação!",
                  message:
                    "Nenhum manobrista disponível no momento, deseja registrar a 'Manobra'?",
                  btnConfirm: "Prosseguir",
                  btnCancel: "Cancelar",
                },
              })
              .afterClosed()
              .subscribe((result) => observer.next(result == true));
          } else if (response instanceof Object) {
            observer.next(true);
          } else {
            observer.error(
              new Error("Não foi possível prosseguir com a ação de manobra."),
            );
          }
        });
    });
  }

  truckerCheckout(data: any, action?: any) {
    this.checkinService.truckerCheckout(data).subscribe({
      next: (r: any) => {
        this.getDataSource("truckerCheckout");
        if (action) {
          this.snackBar.open("Checkout realizado com sucesso", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error: (e: any) => {
        this.getDataSource("truckerCheckout Error");
      },
    });
  }

  snackBarMessage(msg: string) {
    this.snackBar.open(msg, "OK", {
      duration: 3000,
      horizontalPosition: "left",
      verticalPosition: "bottom",
    });
  }

  actionListController(event: any) {
    this.pauseRefreshOnWsMessage = event.listOpen;
    if (this.needsRefreshOnWsMessage && !this.pauseRefreshOnWsMessage) {
      this.getDataSource("actionListController");
    }
  }

  nameOfOperation(id: number) {
    if (id && this.operations) {
      const op = this.operations.filter((item: any) => {
        return item.id === id;
      });
      if (op.length > 0) return op[0].name;
      else return "";
    }
  }

  print(id: number) {
    this.agendamentoService
      .downloadPDF("checkin_details_pdf", id)
      .subscribe((r: any) => {
        const fileURL = URL.createObjectURL(r);
        window.open(fileURL, "_blank");
      });
  }

  getSupportData() {
    const options = ["status", "actions", "operation_type"];
    this.supportDataService
      .multi({ show: options.join(",") })
      .subscribe((r: any) => {
        if (r.data.hasOwnProperty("status")) {
          this.listStatus = r.data.status;
        }
        if (r.data.hasOwnProperty("actions")) {
          this.listActionRef = r.data.actions;
          this.getActions();
        }

        if (r.data.hasOwnProperty("operation_type")) {
          this.operations = r.data.operation_type;
        }

        // if (r.data.hasOwnProperty("departments")) {
        //   this.departments = JSON.parse(
        //     JSON.stringify(r.data.departments),
        //   ).filter((item: any) => {
        //     return this.account.department_id?.indexOf(item.id) > -1;
        //   });
        // }
      });
  }

  ngOnDestroy() {
    this.wssService.closeConnection();
    this.filterWatcher.unsubscribe();
    this.searchWatcher.unsubscribe();
    this.routeParamsChangedWatcher.unsubscribe();
    this.websocketWatcher.unsubscribe();
    this.departmentSubscription?.unsubscribe();
    this.dataSourceSubscription?.unsubscribe();
    this.schemaSubscription?.unsubscribe();
  }
}
