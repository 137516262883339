import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-report-dynamic-list',
  templateUrl: './report-dynamic-list.component.html',
  styleUrls: ['./report-dynamic-list.component.scss']
})
export class ReportDynamicListComponent implements OnInit{
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, {static:true}) sort: MatSort;

  private envData:any;
  private activePage:string;
  private dt_options:any = {};

  displayedColumns: string[] = [];
  columns:any[] = []

  constructor(private activatedRoute:ActivatedRoute) {
    this.envData = JSON.parse(localStorage.getItem("account") || "{}");
  }
  ngOnInit(): void {
    this.activePage = String(this.activatedRoute.snapshot.params.page);
    this.dt_options = this.envData.system_client.resource.environment.pages.reports[this.activePage].dt_options || {};
    this.dt_options.columns = this.dt_options.columns.reduce((a:any,b:any) =>
      {
        if(!a.find( (j:any) => j.data == b.data ))
          a.push(b);
        return a
      },[]
    );

    this.columns = this.dt_options.columns;
    this.displayedColumns = this.dt_options.columns.map((col:any) => col.data);

  }

  stickyStart( col: string) {
    if(this.dt_options.sticky_start){
      return this.dt_options.sticky_start.includes(col);
    }
    return false;
  }
  stickyEnd( col: string) {
    if(this.dt_options.sticky_end){
      return this.dt_options.sticky_end.includes(col);
    }
    return false;
  }
}

