<div class="top-bar-filters">
  <div>
    <div *ngIf="isWeb">
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="order_number">
      <th mat-header-cell *matHeaderCellDef>Pedido</th>
      <td mat-cell *matCellDef="let element">{{element.resource.order_number}}</td>
    </ng-container>

    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef>Motorista</th>
      <td mat-cell *matCellDef="let element">{{element.driver.name}}</td>
    </ng-container>

    <ng-container matColumnDef="doc_date">
      <th mat-header-cell *matHeaderCellDef>Data de envio</th>
      <td mat-cell *matCellDef="let element">{{element.resource.send_at | date:"shortDate"}}</td>
    </ng-container>

    <ng-container matColumnDef="doc_notes">
      <th mat-header-cell *matHeaderCellDef>Observações</th>
      <td mat-cell *matCellDef="let element">{{element.resource.notes}}</td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button (click)="addEditDocs(element, 'EDIT')">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
<button mat-mini-fab (click)="addEditDocs('', 'ADD')" class="mat-fab-bottom-right" color="primary">
  <mat-icon>add</mat-icon>
</button>
