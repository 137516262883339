import { AlmoxarifadoComponent } from './almoxarifado/almoxarifado.component';
import { ProviderComponent } from './provider/provider.component';
import { AccountGuardService } from './../account/shared/account-guard.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const agendamentoRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'provider_schedules',
        component: ProviderComponent,
        data: {
          title: 'Agendamento Fornecedor',
          search: false,
          refresh: false
        }
      },
      {
        path: 'admin_schedules',
        component: AlmoxarifadoComponent,
        data: {
          title: 'Agendamento Almoxarifado',
          search: true,
          refresh: true
        }
      }
    ]
  }
]


@NgModule({
  imports: [
    RouterModule.forRoot(agendamentoRoutes)
  ],
  exports: [RouterModule]
})
export class AgendamentoRoutingModule { }
