<mat-form-field [appearance]="to.appearance">
  <mat-label>{{ to.label }}</mat-label>
  <input
    type="text"
    [placeholder]="props.placeholder || ''"
    matInput
    [formControl]="_formControl"
    [matAutocomplete]="auto"
    [(ngModel)]="ngModule"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displaySelected.bind(this)"
  >
    <mat-option
      *ngFor="let option of filter | async"
      [value]="option"
      (click)="selectedItem = true"
    >
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
  <button
    *ngIf="ngModule?.id"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="selectedItem = false; clear()"
    style="height: inherit; font-size: 0.9em"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
