import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { CheckinCarrierRulesDialogComponent } from "../checkin-carrier-rules-dialog/checkin-carrier-rules-dialog.component";

@Component({
  selector: "app-checkin-carrier-rules",
  templateUrl: "./checkin-carrier-rules.component.html",
  styleUrls: ["./checkin-carrier-rules.component.scss"],
})
export class CheckinCarrierRulesComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private carrierService: CarrierService
  ) {}

  rules: any[] = [];

  ngOnInit() {
    this.getRules();
  }
  getRules() {
    this.carrierService.withRule().subscribe((r) => {
      this.rules = r.data || [];
    });
  }
  addEditRuleCarrier(item?: any) {
    const dialogRef = this.dialog.open(CheckinCarrierRulesDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        ...item,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getRules();
    });
  }
  deleteRuleCarrier(id: string) {
    this.carrierService.removeRule(id).subscribe((r) => {
      this.getRules();
    });
  }
}
