import { CardTableComponent } from './kpis/card-table/card-table.component';
import { Card1InfoComponent } from './kpis/card-1-info/card-1-info.component';
import { Card2InfoComponent } from './kpis/card-2-info/card-2-info.component';
import { CardDriverDefaultComponent } from './default/card-driver/card-driver.component';
import { CardChartsBarComponent } from './kpis/card-charts-bar/card-charts-bar.component';
import { CardDynamicTableComponent } from './kpis/card-dynamic-table/card-dynamic-table.component';
import { CardDashComponent as CardDashMVComponent } from './maquina-vendas/card-dash/card-dash.component';
import { CardEchartsComponent } from './kpis/card-echarts/card-echarts.component';

const cards = {
  card_echarts: CardEchartsComponent,
  card_driver_default: CardDriverDefaultComponent,
  card_dash_mv: CardDashMVComponent,
  card_1_info: Card1InfoComponent,
  card_2_info: Card2InfoComponent,
  card_table: CardTableComponent,
  card_charts_bar: CardChartsBarComponent,
  card_dynamic_table: CardDynamicTableComponent
}

export default cards;
