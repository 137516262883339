import { NgFor, NgIf } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  MatFormFieldModule,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {
  FormlyFormOptions,
  FormlyFieldConfig,
  FormlyModule,
} from "@ngx-formly/core";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";
import { SupportDataService } from "../support-data.service";

@Component({
  selector: "app-din-filters-dialog",
  templateUrl: "./din-filters-dialog.component.html",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgFor,
    NgIf,
    FormlyModule,
  ],
  styleUrls: ["./din-filters-dialog.component.scss"],
})
export class DinFiltersDialogComponent implements OnInit {
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  schema: any = {
    schema: {
      title: "Filtros",
      type: "object",
      widget: { formlyConfig: { fieldGroupClassName: "tkg-schema" } },
      required: ["filterType"],
      properties: {
        filterType: {
          type: "string",
          title: "Escolha um tipo de filtro",
          widget: {
            formlyConfig: {
              className: "tkg-item",
              type: "enum",
              props: {
                appearance: "outline",
                options: [],
                change: (field: any, $event: any) => {
                  this.change(field, $event);
                },
              },
            },
          },
        },
        // select5: {
        //   type: "string",
        //   title: "Select 5: with widget attr",
        //   widget: {
        //     formlyConfig: {
        //       type: "enum",

        //       props: {
        //         valueProp: (option: any) => option,
        //         compareWith: (o1: any, o2: any) => o1?.value === o2?.value,
        //         options: [
        //           {
        //             value: "1",
        //             label: "option 1",
        //           },
        //           {
        //             value: "2",
        //             label: "option 2",
        //           },
        //         ],
        //       },
        //     },
        //   },
        // },
        // driver_id: {
        //   type: "string",
        //   title: "Motorista",
        //   widget: {
        //     formlyConfig: {
        //       type: "SelectSearchDriver",
        //       props: {
        //         label: "Motorista ",
        //         appearance: "outline",
        //         placeholder: "Escolha um motorista"
        //       },
        //     },
        //   },
        // },
        // first_name: {
        //   type: "string",
        //   title: "First name",
        // },
      },
    },
    model: {},
  };
  change_to: string = "";

  din_filters: any;
  showForm: boolean;

  selected_filters: any[] = [];

  constructor(
    private formlyJsonschema: FormlyJsonschema,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService,
    private dialogRef: MatDialogRef<DinFiltersDialogComponent>,
  ) {
    this.getFilters();
    console.log("data");
    console.log(this.data);
    this.selected_filters = this.data.filters;
  }

  getFilters() {
    this.supportDataService
      .generic({ name: this.data.origin })
      .subscribe((r: any) => {
        this.din_filters = r.data.resource;
        this.schema.schema.properties.filterType.widget.formlyConfig.props.options =
          this.din_filters.filters_available;
        console.log(this.din_filters);
        this.startForm();
      });
  }

  ngOnInit(): void {}

  startForm() {
    this.form = new FormGroup({});
    this.options = {};
    this.fields = [this.formlyJsonschema.toFieldConfig(this.schema.schema)];
    this.model = this.schema.model;
    this.showForm = true;
  }

  change(field: any, event: any) {
    console.log("on change");
    const filterType = this.form.get("filterType");
    console.log(filterType?.value);
    console.log(field);

    if (this.change_to.length > 0) {
      delete this.schema.schema.properties[this.change_to];
      delete this.model[this.change_to];
    }

    this.schema.schema.properties[filterType?.value] =
      this.din_filters.json_schema_properties[filterType?.value];

    this.fields = [this.formlyJsonschema.toFieldConfig(this.schema.schema)];
    this.change_to = filterType?.value;
  }

  add() {
    const filterType: any = this.form.get("filterType");
    console.log(filterType?.value);
    const filterLabel = this.schema.schema.properties[filterType?.value].title;
    console.log(filterLabel);
    const filterValue = this.form.get(filterType?.value);
    console.log(filterValue?.value);
    this.selected_filters.push({
      ref_name: filterType?.value,
      ref_id: filterValue?.value.id,
      label: filterLabel,
      value: filterValue?.value.name,
    });
    console.log(this.schema.model);
    console.log(this.selected_filters);
  }

  remove(i: any) {
    console.log(i);
    this.selected_filters.splice(i, 1);
  }

  removeAll() {
    this.selected_filters = [];
  }

  removeAllAndApply() {
    this.selected_filters = [];
    this.dialogRef.close(this.selected_filters);
  }

  save() {
    this.dialogRef.close(this.selected_filters);
  }
}
