import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Media } from '../media.interface';

@Injectable({
  providedIn: 'root'
})
export class HubService {

  constructor(
    private http: HttpClient
  ) { }

  dashboardListing(options?: any): Observable<Media> {

    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/checkinstage/hub`;
    return this.http.get<Media>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  topOfensores(options?: any): Observable<Media> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/top_ofensores`;
    return this.http.get<Media>(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  //Busca as jornadas acima, entre ou abaixo do tempo configurado.
  //TODO: overtime, between and onTime
  stageInfo(options?: any): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/stage-view`;
    return this.http.get(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  stageAlerts(options?: any): Observable<any> {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/stage-alerts`;
    return this.http.get(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

}
