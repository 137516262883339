<div class="events-container-box">
  <mat-toolbar color="primary" class="my-tool-bar">
    <mat-toolbar-row>
      <div>
        <span>{{ definedDay | date: "mediumDate" }}</span>
        <button
          mat-raised-button
          class="add-btn"
          (click)="openDialog({ status: 'available' })"
        >
          Novo pré-agendamento
        </button>
        <button mat-raised-button class="down-btn" (click)="download()">
          Baixar Relatório
        </button>
        <button
          mat-raised-button
          class="up-btn"
          (click)="uploadDialog()"
          *ngIf="accessLevel != 'carrier'"
        >
          Upload de agendamento
        </button>
      </div>
      <div class="fields-filter">
        <form [formGroup]="dayForm">
          <div class="grid">
            <div>
              <mat-form-field class="select-comp">
                <mat-label>Dia</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="day"
                  (dateChange)="switchDay()"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="select-comp">
                <mat-label>Operação</mat-label>
                <mat-select [formControl]="filterOperationSelected">
                  <mat-option
                    (click)="setFilterOperation('undefined')"
                    value="undefined"
                    >Todas</mat-option
                  >
                  <mat-divider></mat-divider>
                  <mat-option
                    *ngFor="let operation of operations"
                    [value]="operation.id"
                    (click)="setFilterOperation(operation.id)"
                  >
                    {{ operation.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="operation_id == 1">
              <mat-form-field class="select-comp">
                <mat-label>Suboperação</mat-label>
                <mat-select [formControl]="filterSubOperationSelected">
                  <mat-option
                    (click)="setFilterSuboperation('undefined')"
                    value="undefind"
                    >Todas</mat-option
                  >
                  <mat-divider></mat-divider>
                  <mat-option
                    *ngFor="let suboperation of suboperations"
                    [value]="suboperation.id"
                    (click)="setFilterSuboperation(suboperation.id)"
                  >
                    {{ suboperation.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="select-comp">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="filterStatusSelected">
                  <mat-option
                    (click)="setFilterStatus('undefined')"
                    value="undefind"
                    >Todos</mat-option
                  >
                  <mat-divider></mat-divider>
                  <mat-option
                    *ngFor="let st of status"
                    [value]="st.id"
                    (click)="setFilterStatus(st.id)"
                  >
                    {{ st.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-grid-list
    cols="12"
    [rowHeight]="dashboardHeight"
    class="event-container"
  >
    <mat-grid-tile colspan="1" rowspan="2">
      <div class="hours-container">
        <mat-grid-list cols="12" rowHeight="50px">
          <mat-grid-tile class="time" colspan="12" rowspan="2">
            Horários
          </mat-grid-tile>
          <mat-grid-tile
            class="time"
            *ngFor="let timeslot of timeslots"
            colspan="12"
            rowspan="2"
          >
            {{ timeslot.time }}
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-grid-tile>

    <mat-grid-tile rowspan="2" colspan="11">
      <div class="docks-container">
        <div class="dock-item">
          <div class="dock-header dock-section">Agendamentos</div>
          <div class="dock-event" *ngFor="let schedule of schedules">
            <div
              class="dock-section"
              [ngClass]="item.status"
              (click)="openDialog(item)"
              *ngFor="let item of schedule"
            >
              <div>
                <h5 *ngIf="accessLevel != 'carrier' || (this.account.is_carrier && this.account.carriers.length > 1)">
                  {{ item.carrier_name }}
                </h5>
                <h5>
                  {{ item.sid || item.external_event_id }}
                </h5>
                <h5>{{ item.driver_name }}</h5>
                <h5>{{ item.cpf }}</h5>
                <h5>{{ showPlates(item) }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-grid-tile>

  </mat-grid-list>
</div>
