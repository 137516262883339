import { CardModule } from './../shared/cards/card.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatGridListModule} from '@angular/material/grid-list';
import {DashboardDynamicRoutingRoutes} from './dashboard-dynamic-routing.module'
import {DashboardDynamicStructureComponent} from './dashboard-dynamic-structure/dashboard-dynamic-structure.component'

@NgModule({
  declarations: [DashboardDynamicStructureComponent],
  imports: [
    CommonModule,
    DashboardDynamicRoutingRoutes,
    MatGridListModule,
    CardModule
  ],
  exports: [
    DashboardDynamicStructureComponent
  ]
})
export class DashboardDynamicModule { }
