import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentsService } from '../../documents/shared/documents.service';

@Component({
  selector: 'app-documents-dialog',
  templateUrl: './documents-dialog.component.html',
  styleUrls: ['./documents-dialog.component.scss']
})
export class DocumentsDialogComponent implements OnInit {

  documents:any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DocumentsDialogComponent>,
    public documentsService: DocumentsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    //console.log('ok', this.data);

    if (this.data) {


      if (this.data.origin == 'scheduling') {
        this.documents = this.data.item.files;
      } else if(this.data.item.event && (this.data.item.event.resource.files || this.data.item.event.resource.documents)) {
        this.documents = this.data.item.event.resource.files || this.data.item.event.resource.documents || [];
      }

    }

  }

  getFile(item: any): void {
    console.log('item');
    console.log(item);
    this.documentsService.downloadFile(item)
      .subscribe((val) => {
          this.createFileFromBlob(val, item.fileName);
      });
  }

  createFileFromBlob(file: Blob, fileName: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

}
