<h3 *ngIf="data.title">{{data.title}}</h3>
<form [formGroup]="form">
  <formly-form
    class="grid grid-filter"
    [model]="model"
    [fields]="fields"
    [options]="options"
    [form]="form"
  ></formly-form>
</form>

<mat-list role="list">
  <mat-list-item role="listitem" *ngFor="let item of alertsSource">{{item.message || ""}}</mat-list-item>
  <mat-list-item role="listitem" class="no-data" *ngIf="alertsSource.length === 0">Sem resultados</mat-list-item>
</mat-list>

<mat-paginator
  [pageSize]="10"
  [pageSizeOptions]="[10, 25, 50, 100]"
>
</mat-paginator>

<mat-dialog-actions align="end">
  <button type="button" mat-button color="primary" mat-dialog-close>Fechar</button>
</mat-dialog-actions>
