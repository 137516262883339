<div mat-dialog-content *ngIf="showForm">
  <form [formGroup]="form">
    <div>
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
      <div class="tkg-add-filter">
        <button
          mat-raised-button
          color="primary"
          style="color: white"
          cdkFocusInitial
          (click)="add()"
        >
          Adicionar
          <mat-icon iconPositionEnd>add</mat-icon>
        </button>
      </div>
    </div>
    <!-- </div> -->
  </form>
  <div class="tkg-selected-filters">
    <span
      class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
      *ngFor="let item of selected_filters; index as idx"
    >
      <b class="tkg-chips-filter-name">{{ item.label }}</b
      >{{ item.value }}
      <span class="material-icons tkg-chips-icon" (click)="remove(idx)">
        cancel
      </span>
    </span>
  </div>
</div>
<div mat-dialog-actions class="tkg-actions">
  <div class="tkg-left">
    <button mat-stroked-button color="warn" mat-dialog-close>Cancelar</button>
    <button
      mat-button
      (click)="removeAllAndApply()"
      [disabled]="selected_filters.length === 0"
    >
      <mat-icon>delete</mat-icon> Remover e Fechar
    </button>
    <button
      mat-button
      (click)="removeAll()"
      [disabled]="selected_filters.length === 0"
    >
      <mat-icon>delete</mat-icon> Remover
    </button>
  </div>
  <div class="tkg-right">
    <button
      mat-flat-button
      color="primary"
      style="color: #fff"
      (click)="save()"
      [disabled]="selected_filters.length === 0"
    >
      <mat-icon>save</mat-icon> Aplicar
    </button>
  </div>
</div>
