import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Media } from '../media.interface';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _refresh = new Subject<void>();

  constructor(
    private http: HttpClient
  ) { }

  get refresh() {
    return this._refresh;
  }

  tempoMedioMotoristaCD(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/media_motorista_cd`;
    return this.http.get<Media>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  mediaAguardandoLiberacao(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/media_aguardando_liberacao`;
    return this.http.get<Media>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  rankingAguardandoLiberacao(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/ranking_aguardando_liberacao`;
    return this.http.get<Media>(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  operacao_inbound(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_operacao_inbound`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  operacao_outbound(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_operacao_outbound`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  operacao_devolucao(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_operacao_devolucao`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  operacao_dfd(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_operacao_dfd`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  ranking_permanencia(limit?: number): Observable<Media> {
    const params = new HttpParams().set('limit', (limit || '').toString())
    const url = `${environment.apiUrl}/ranking_permanencia`;
    return this.http.get<Media>(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  ranking_operacao_inbound(limit?: number): Observable<Media> {
    const params = new HttpParams().set('limit', (limit || '').toString())
    const url = `${environment.apiUrl}/ranking_operacao_inbound`;
    return this.http.get<Media>(url, { params: params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  liberacao(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_liberacao`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  direcionamento(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_direcionamento`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  checkin(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_checkin`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  estacionado(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_estacionado`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  retirando_notas(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_retirando_notas`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  doca(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_doca`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  despacho(options?: any): Observable<Media> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/quantidade_despacho`;
    return this.http.get<Media>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }
}
