import { Injectable } from '@angular/core';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DepartmentService } from "src/app/shared/department.service";
import * as moment from 'moment';
import 'moment-duration-format';

@Injectable({
    providedIn: "root"
  })
export class TransformMethodUtil{

    operation: any;
    dockSpaces: any;
    account: any;
    vehicle_types: any;
    scheduleFieldsOptions: any;
    suboperations: any;
    listStatus: any;
    isTripHitch: any;
    departments: any;
    destinyAll: any;

    constructor(
        private supportDataService: SupportDataService,
        private departmentService: DepartmentService,
    ){
        const account: string = localStorage.getItem("account") || '';
        this.account = JSON.parse(account);

        this.departmentService.index().subscribe((r: any) => {
          this.departments = r.data.resource;
          this.departments = this.departments.filter((item: any) => {
            return this.account.department_id.indexOf(item.id) > -1;
          })
        })

        this.supportDataService.status().subscribe((r: any) => {
          this.listStatus = r.data.resource;
        });

        this.supportDataService.generic({name: 'operation_type'}).subscribe({next: (r: any) => {
            this.operation = r.data.resource;
          }});

          this.supportDataService.dockSpaces().subscribe((r: any) => {
            this.dockSpaces = r.data.resource;
          });

          this.supportDataService.vehicle_type().subscribe({
            next: (r: any) => {
              this.vehicle_types = r.data.resource;
            },
            error: (e: any) => {
            }
    });

          this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
            this.suboperations = r.data.resource;
          });

          this.supportDataService.generic({name: "locations"}).subscribe((r: any) => {
            this.destinyAll = r.data.resource;
          });

          if (this.account.system_client.resource.environment.trip_hitch) {
            this.isTripHitch = this.account.system_client.resource.environment.trip_hitch;
          }
    }

showStatus(resource: any) {
  if(resource){
  if (this.listStatus.length > 0) {
    const st = this.listStatus.filter(
      (item: any) => item.id === resource.status
    );
    if (resource.status === "on_dock") {
      return this.getDockName(resource.dock_space_id, resource.department_id);
    } else {
      return st[0].alternate_name;
    }
  }
}
else{
  return "";
}
}

showOperation(id: number){
  if(id)     {
    let result = "";
    if(this.operation){
    this.operation.map((item: any) => {
        if (item.id == id) {
        result = item.name;
        }
    });
    }
    return result;
  }
  else{
    return "";
  }
}

showOperationName(id: number){
  if(id){
  let result;
  if(this.operation && this.operation.length > 0){
    this.operation.map((item: any) => {
      if (item.id == id) {
        result = item.name;
      }
    });
  }
  return result;
}
else{
  return "";
}

}

  showDockNumber(resource: any) {
    try {

      if (resource && resource[0] > 0) {
        if (resource.length > 1) {
          let dn = this.dockSpaces[parseInt(resource[1])].filter(
            (item: any) => item.id === parseInt(resource[0])
          );
          if (dn.length > 0) {
            return dn[0].name;
          }
        } else {
          if (this.account.system_client.resource.environment.docks_shared === true) {
            let dn = this.dockSpaces.filter(
              (item: any) => item.id === parseInt(resource[0])
            );
            if (dn.length > 0) {
              return dn[0].name;
            }
          }

          let dn = this.dockSpaces[1].filter(
            (item: any) => item.id === parseInt(resource[0])
          );

          if (dn.length > 0) {
            return dn[0].name;
          }
        }
      }
      else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }

  showVehicleType(ev: any) {

    if (ev) {
      let event: any = []
      if (!Array.isArray(ev)) {
        event.push(ev)
      }
      else {
        event[0] = ev
      }

      try {

        if (event.length > 0 && this.vehicle_types && this.vehicle_types.length > 0) {
          if (
            !event[0] ||
            !event[0].resource ||
            !event[0].resource.vehicle_type
          ) {
            return "";
          }

          const vt = this.vehicle_types.filter(
            (op: any) => op.id === event[0].resource.vehicle_type
          );
          if (vt.length > 0) {
            return vt[0].label;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  }

showSubOperation(ev: any) {
  let event:any = []

  if(ev){

  if(!Array.isArray(ev))
  {
    event.push(ev)
  }
  else{
    event[0] = ev
  }
  if (event.length > 0) {

    if (!event[0] || (!event[0].suboperation && !event[0].suboperation_id))
      return ({name:'',...this.suboperations.find((op: any) => op.id === event[0].suboperation_id)}).name;
  }

  return "";    }
  else{
    return "";
  }
}

showSubOperationName(id: number) {
  let result;

  if (this.suboperations && this.suboperations.length > 0) {
    this.suboperations.map((item: any) => {
      if (item.id == id) {
        result = item.name;
      }
    });
  }
  return result;

}

timeOnStatus(resource: any) {
  if (resource || !this.isTripHitch) {
    const status = resource.status;
    const statusTs = status + "_created_at";
    const now = moment();
    const statusTime = moment(resource[statusTs]);
    const mins = now.diff(statusTime, "minutes");
    return moment
      .utc(moment.duration(mins, "minutes").asMilliseconds())
      .format("HH:mm");
  }
  else{
    return "";
  }
}

showVehicleTypeDF(vtype: any) {
  if(vtype){
  const vt = this.vehicle_types.filter(
    (item: any) => item.id === vtype[0]
  );
  if (vt.length > 0) {
    return vt[0].name;
  } else {
    return "--";
  }
}
else{
  return "";
}

}

showPriorityVehicle(event: any) {
  if(event){
    if (event.resource && event.resource.priority_vehicle){
    if (parseInt(event.resource.priority_vehicle) == 1) {
      return 'Sim';
    }
    if (parseInt(event.resource.priority_vehicle) == 2) {
      return 'Não';
    }
    return "";
  }
}
  return "";
}

getDockName(id: number, department: number) {
  if(id && department){
  if (id >= 0) {
    if (
      this.account.department_id.length > 0 &&
      department &&
      this.account.system_client.resource.environment.docks_shared === false
    ) {
      let result: any[] = [];
      this.account.department_id.forEach((element: any) => {
        if (element == department)
          result = this.dockSpaces[element].filter(
            (item: any) => item.id == id
          );
      });
      return result[0].name;
    } else {
      if (
        this.account.department_id &&
        this.account.system_client.resource.environment.docks_shared === false
      ) {
        const dn = this.dockSpaces[this.account.department_id].filter(
          (item: any) => item.id == id
        );
        if (dn.length > 0) {
          return dn[0].name;
        }
      } else {
        const dn = this.dockSpaces.filter((item: any) => item.id == id);
        if (dn.length > 0) {
          return dn[0].name;
        }
      }
    }
  }
}
else {
  return "";
}
}

showStepTime(resource: any) {

  if (resource && resource[0]) {
    return resource[0];
  } else {
    return "";
  }
}

showNameViaId(ev: any) {
  let event:any = []

  if(ev){

  if(!Array.isArray(ev))
  {
    event.push(ev)
  }
  else{
    event[0] = ev
  }

  if (event.length > 0) {
    if (!event[0]) return "";
    return (
      this.departments.filter((op: any) => op.id === parseInt(event[0]))[0].name ||
      ""
    );
  } else {
    return "";
  }
}
else{
  return "";
}
}

showException(exceptions: any) {
  return exceptions ? exceptions : "";
}

showMaterial(ev: any) {

  let event:any = []
  if(ev){

  if(!Array.isArray(ev))
  {
    event.push(ev)
  }
  else{
    event[0] = ev
  }

  if (event.length > 0) {
    if (!event[0] || !event[0].extra_data.motorista_destino)
      return "";

    if (event[0].extra_data.motorista_destino)
      return ({name:'',...this.destinyAll.find((op: any) => op.id === event[0].extra_data.motorista_destino)}).detail;

    return ({name:'',...this.destinyAll.find((op: any) => op.id === event[0].extra_data.motorista_destino)}).detail;

  }
  return "";
}
else{
  return "";
}
}

showId(ev: any) {

  if(ev){
  let event:any = []

  if(!Array.isArray(ev))
  {
    event.push(ev)
  }
  else{
    event[0] = ev
  }

  if (event.length > 0) {
    if (!event[0] || !event[0].operation_id) return "";
    if (event[0].operation_id == 1) {
      return event[0].sid || "";
    } else {
      return event[0].external_event_id || "";
    }
  } else {
    return "";
  }
}
else{
  return ""
}
}

timeElapsedToNow(timestamp: any) {
  if(timestamp){
  const now = moment();
  const tsEvaluated = moment(timestamp[0]);
  return moment
    .duration(now.diff(tsEvaluated), "milliseconds")
    .format("dd[d]:hh[h]:mm[min]");
  }
  else{
    return "";
  }
}

showIdOrSid(resource: any) {
  if(resource){
  if (resource.sid) {
    return resource.sid;
  } else {
    return resource.external_event_id;
  }}
  else{
    return ""
  }
}

showDepartmentName(id: number) {
  let result;

  if(id){
    if (this.departments && this.departments.length > 0) {
      this.departments.map((item: any) => {
        if (item.id == id) {
          result = item.name;
        }
      });
    }
    return result;
  }
  else{
    return ""
  }

}

  operationDurationByDateStatus(dateTime: string, format = "DD[d], hh[h] mm[min]"): string {

    try {
      if (dateTime) {
        return moment.duration(
          moment().diff(
            moment(dateTime)
          )
        ).format(format);
      }
      return "";
    }
    catch (error) {
      return "";
    }
  }

}


