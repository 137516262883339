import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { CheckinRoutingModule } from './checkin-routing.module';
import { CheckinComponent } from './checkin/checkin.component';
import { CheckinDialogComponent } from './checkin-dialog/checkin-dialog.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { VehicleSearchDialogComponent } from './vehicle-search-dialog/vehicle-search-dialog.component';
import { SharedModule } from '../shared/shared.module';
// import { DirectDialogComponent } from './direct-dialog/direct-dialog.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        CheckinRoutingModule,
        TextMaskModule
        // NgxMatSelectSearchModule
    ],
    declarations: [
        CheckinComponent,
        CheckinDialogComponent,
        VehicleSearchDialogComponent,
        // DirectDialogComponent
    ]
})
export class CheckinModule { }
