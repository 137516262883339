<h2 mat-dialog-title>Documentos</h2>
<mat-dialog-content class="mat-typography">
  <mat-list>
    <mat-list-item *ngFor="let item of documents" required>
      <p matLine>
        <span > {{ item.fileName }} </span>
      </p>
      <button
        mat-icon-button
        aria-label="Baixar Arquivo"
        (click)="getFile(item)"
      >
        <mat-icon>cloud_download</mat-icon>
      </button>
    </mat-list-item>
    <p *ngIf="data.item.event == undefined || documents.length == 0" matLine>
      <span >
        Sem documentos para visualização
      </span>
    </p>

  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
