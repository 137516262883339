import { Subject } from "rxjs";
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { MaskUtil } from '../../../shared/util/mask.util';
import { PlaceService } from '../../../shared/place.service';
import { SupportDataService } from '../../../shared/support-data.service';
import { DialogAlertComponent } from "../../../shared/dialog-alert/dialog-alert.component";

@Component({
  selector: 'app-place-dialog',
  templateUrl: './place-dialog.component.html',
  styleUrls: ['./place-dialog.component.scss']
})
export class PlaceDialogComponent implements OnInit {
  form = this.formBuilder.group({
    name: [this.data.item.name, [Validators.required]],
    cnpj: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.cnpj : ''],
    place_type_id: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.place_type_id : ''],
    document: [this.data.item.document],
    code: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.code : ''],
    address: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.address : ''],
    number: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.number : ''],
    complement: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.complement : ''],
    district: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.district : ''],
    city: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.city : ''],
    state: [this.data.item.hasOwnProperty('resource') ? this.data.item.resource.state : '']
  });
  maskUtil = MaskUtil;
  errors: any = {};
  placeTypes:any[] = [];
  searching: boolean = false;
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PlaceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private placeService: PlaceService,
    private supportDataService: SupportDataService

  ) { }

  ngOnInit() {
    console.log(this.data);
    this.getPlaceType();
  }

  onSubmit() {
    if (this.data.action === "EDIT") {
      const options = {...this.form.value, id: this.data.item.id};
      this.placeService.update(options).subscribe(
        (data) => {
          this.dialogRef.close(data);
        },
        (err) => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: { title: err.statusText, message: err.error.message },
            });
          }
        }
      );
    } else if (this.data.action === "ADD") {
      this.placeService.create(this.form.value).subscribe(
        (data) => {
          this.dialogRef.close(data);
        },
        (err) => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: { title: err.statusText, message: err.error.message },
            });
          }
        }
      );
    }
  }

  buscaCEP() {
    this.placeService.buscaCEP(this.form.value.code).subscribe((r: any) => {
      console.log(r.data);
      this.form.patchValue({...r.data})
    });
  }

  getPlaceType() {
    return this.supportDataService.generic({name: 'place_type'}).subscribe((r: any) => {
      this.placeTypes = r.data.resource;
    });
  }

}
