<h2 class="dialog-title"> {{ "Business_model.generate_report" | translate:{Default: "Gerar Relatório"} }}</h2>
<h3>{{ "Business_model.filters" | translate:{Default: "Filtros"} }}</h3>
<mat-divider></mat-divider>

<div mat-dialog-content>
  <form [formGroup]="form">

    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>{{ "Business_model.period_required" | translate:{Default: "Período (Obrigatório)"} }}</mat-label>
      <mat-date-range-input [required]="true" [min]="this.minDate" [max]="this.maxDate" [formGroup]="form"
        [rangePicker]="picker">
        <input readonly #startInput matStartDate formControlName="dt_start"/>
        <input readonly #endInput matEndDate formControlName="dt_end"/>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "Business_model.driver" | translate:{Default: "Motorista"} }}</mat-label>
      <mat-select [disableRipple]="true" formControlName="driver_id"
        [placeholder]="'Messages.choose_driver' | translate:{Default: 'Escolha o motorista'}">
        <mat-option>
          <ngx-mat-select-search [searching]="searchingDriver" [clearSearchInput]="false" [placeholderLabel]="'Messages.search' | translate:{Default: 'Pesquisar'}"
            formControlName="driverFilter" [noEntriesFoundLabel]="''"></ngx-mat-select-search>
        </mat-option>
        <mat-option>{{ "Business_model.clear" | translate:{Default: "Limpar"} }}</mat-option>
        <mat-option *ngFor="let item of driverListOptions" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!(this.account.is_carrier && this.account.carriers?.length == 1)">
      <mat-label>{{ "Business_model.carrier" | translate:{Default: "Transportadora"} }}</mat-label>
      <mat-select [disableRipple]="true" formControlName="carrier_id"
        [placeholder]="'Messages.choose_carrier' | translate:{Default: 'Escolha a transportadora'}">
        <mat-option>
          <ngx-mat-select-search [searching]="searchingCarrier" [clearSearchInput]="false" [placeholderLabel]="'Messages.search' | translate:{Default: 'Pesquisar'}"
            formControlName="carrierFilter" [noEntriesFoundLabel]="''"></ngx-mat-select-search>
        </mat-option>
        <mat-option>{{ "Business_model.clear" | translate:{Default: "Limpar"} }}</mat-option>
        <mat-option *ngFor="let item of carrierListOptions" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.display]="this.account.department_id.length == 1 ? 'none' : 'show'">
      <mat-label>{{ "Business_model.department" | translate:{Default: "Departamento"} }}</mat-label>
      <mat-select [placeholder]="'Messages.choose_department' | translate:{Default: 'Escolha o departamento'}"
        formControlName="department" (selectionChange)="departmentChange()">
        <mat-option>{{ "Business_model.clear" | translate:{Default: "Limpar"} }}</mat-option>
        <mat-option [value]="item.id" *ngFor="let item of departmentsFiltered">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="showOperation">
      <mat-label>{{ "Business_model.operation" | translate:{Default: "Operação"} }}</mat-label>
      <mat-select [placeholder]="'Messages.choose_operation' | translate:{Default: 'Escolha a operação'}"
        formControlName="operation" (selectionChange)="operationChange()">
        <mat-option>{{ "Business_model.clear" | translate:{Default: "Limpar"} }}</mat-option>
        <mat-option [value]="item.id" *ngFor="let item of operationsFiltered">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="showSubOperation">
      <mat-label>{{ "Business_model.suboperation" | translate:{Default: "Suboperação"} }}</mat-label>
      <mat-select [placeholder]="'Messages.choose_suboperation' | translate:{Default: 'Escolha a sub-operação'}"
        formControlName="suboperation">
        <mat-option>{{ "Business_model.clear" | translate:{Default: "Limpar"} }}</mat-option>
        <mat-option [value]="item.id" *ngFor="let item of subOperationsFiltered">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="file_type_radio">
      <mat-label>{{ "Business_model.file_type_required" | translate:{Default: "Formato do Arquivo (Obrigatório)"} }}*</mat-label>
      <mat-radio-group formControlName="file_type" [required]="true">
        <mat-radio-button value="csv">CSV</mat-radio-button>
        <mat-radio-button value="xlsx">XLSX</mat-radio-button>
      </mat-radio-group>
    </div>

  </form>
</div>

<button mat-button color="primary" (click)="addFilters()">
  <span class="material-icons">add</span>
  {{ "Business_model.generate_report_add_filters" | translate:{Default: "ADICIONAR FILTROS"} }}
</button>

<br />
<h4>{{ "Business_model.applied_filters" | translate:{Default: "Filtros aplicados"} }}</h4>
<mat-card>
  <span class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon" *ngFor="let item of activeFilters;">
    <b class="tkg-chips-filter-name">{{ item.label }}</b><span class="tkg-chips-ellipsis">{{ item.name }}</span>
    <span class="material-icons tkg-chips-icon" (click)="deleteFilter(item)">
      cancel
    </span>
  </span>
</mat-card>
<br />
<mat-divider></mat-divider>
<br />

<mat-dialog-actions align="end">
  <button mat-button mat-stroked-button color="primary" mat-dialog-close style="color: #888888;">{{
    "Business_model.generate_report_cancel_button" | translate:{Default: "CANCELAR"} }}</button>
  <button mat-button mat-flat-button color="primary" class="btn-primary" (click)="generateEmailReport()"
    [disabled]="isSubmitDisabled()">{{ "Business_model.generate_report_generate_button" | translate:{Default: "GERAR RELATÓRIO"} }}</button>
</mat-dialog-actions>
