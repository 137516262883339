<div class="tkg-cell" [ngClass]="cellClass(cellData)">

  <ng-container *ngFor="let item of cellTemplate">
    <ng-container *ngIf="cellPriority(cellData) === true ; else elsePriorityTemplate">
      <span class="tkg-cell-row tkg-cell-item" *ngIf="ItemCellTemplate(item) !== 'priority'">
        {{bindTemplate(item)}}
      </span>
      <span class="tkg-cell-row tkg-cell-item" *ngIf="ItemCellTemplate(item) === 'priority'">
        <mat-chip-listbox>
          <mat-chip-option
            [style.background-color]="ItemCellTemplateBackGroundColor(item)"
            [style.color]="ItemCellTemplateColor(item)">
            {{bindTemplate(item)}}
          </mat-chip-option>
        </mat-chip-listbox>
      </span>
    </ng-container>

    <ng-template #elsePriorityTemplate>
      <span class="tkg-cell-row tkg-cell-item">
        {{bindTemplate(item)}}
      </span>
    </ng-template>
  </ng-container>
</div>
