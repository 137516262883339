import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Document } from '../documents.interface';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient
  ) { }

  index(options?: any): Observable<Document> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/documents`;

    return this.http.get<Document>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  create(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/documents`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  update(data: any, id: string, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/documents`;
    const options = { params: params };

    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  delete(data: any): Observable<Document> {
    const url = `${environment.apiUrl}/documents/delete`;

    return this.http.put<Document>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  upload(formData: any) {
    const url = `${environment.apiUrl}/documents/upload`;
    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteBucket(data: any): Observable<Document> {
    const url = `${environment.apiUrl}/documents/delete/bucket`;

    return this.http.put<Document>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  uploadBucket(formData: any) {
    const url = `${environment.apiUrl}/documents/upload/bucket`;
    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadFileBucket(data: any): Observable<Blob> {
    const url = `${environment.apiUrl}/documents/download/bucket`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post<Blob>(url, data, { headers: headers, responseType: 'blob' as 'json' });
  }

  downloadFile(data: any): Observable<Blob> {
    const url = `${environment.apiUrl}/documents/download`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post<Blob>(url, data, { headers: headers, responseType: 'blob' as 'json' });
  }

  downloadSchedulingTemplate(options:any={}): Observable<Blob> {
    const url = `${environment.apiUrl}/documents/mass-scheduling-template`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post<Blob>(url, options, { headers: headers, responseType: 'blob' as 'json' });
  }

  importLoreal(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-loreal`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  importBraskem(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-braskem`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  importBraskemTriunfo(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-braskem-triunfo`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  importCLM(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-clm`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  importLorealJarinu(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-loreal-jarinu`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  importJandira(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-jandira`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  importDHLPepsico(data: any, params?: any): Observable<Document> {
    const url = `${environment.apiUrl}/import-dhl-pepsico`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  batchScheduling(data:any, params?:any): Observable<Document>{
    const url = `${environment.apiUrl}/import-batch-scheduling`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }

  createFileFromBlob(file: Blob, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  storeDocumentUpload(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/docupload`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  indexDocumentUpload(params?:any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/docupload`;
    const options = { params: params };

    return this.http.get<any>(url,options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateDocumentUpload(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/docupload`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  deleteDocumentUpload(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/docupload/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

}
