import { format } from 'date-fns';
import { map, catchError } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { FormlyFormOptions, FormlyFieldConfig,  } from '@ngx-formly/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, ElementRef, Inject, OnInit, ViewChild,  AfterViewChecked, ChangeDetectorRef } from '@angular/core';

import { DriversService } from 'src/app/drivers/shared/drivers.service';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DocumentsService } from 'src/app/documents/shared/documents.service';
import { AgendamentoService } from 'src/app/multiagendamento/shared/agendamento.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-schedule-dinamic-form-dialog',
  templateUrl: './schedule-dinamic-form-dialog.component.html',
  styleUrls: ['./schedule-dinamic-form-dialog.component.scss']
})
export class ScheduleDinamicFormDialogComponent implements OnInit, AfterViewChecked {
  @ViewChild("fileUpload") fileUpload: ElementRef;

  files:{ data: File, inProgress: boolean, progress: number, uploaded:boolean }[] = [];
  documents: any[] = [];
  loading = false;
  offset: string;
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  validationMsgs: any[] = [];
  showForm = false;
  forkJoinDone$= new BehaviorSubject<void>(void 0);
  allSuboperations: any[];
  allOperations: any[];
  suboperationsFt: any[];
  operationsFt: any[];
  fields: FormlyFieldConfig[] = [];
  aditionalFields: any = {};
  dataVehicleType: any[];
  account: any;
  use_department: any;
  scheduleActions: any;
  config_scheduling: any;
  showDocuments: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public supportDataService: SupportDataService,
    public agendamentoService: AgendamentoService,
    public documentsService: DocumentsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ScheduleDinamicFormDialogComponent>,
    public driverService: DriversService,
    public carrierService: CarrierService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.use_department = this.account.system_client.resource.environment.use_department;
    this.config_scheduling = this.account.system_client.resource.environment.config_scheduling;

    this.offset = this.getTimeZone();
    forkJoin([
      this.getScheduleFields(),
      this.getDepartaments(),
      this.getOperations(),
      this.getSuboperations(),
      this.getCarriers(),
      this.getVehicleType(),
    ]).subscribe( result => {

      // Schedule Field process
      this.processScheduleFields(result[0]);
      this.processOperations(result[2]);
      this.processDepartaments(result[1]);
      this.processSuboperations(result[3]);
      this.processCarriers(result[4]);
      this.processVehicleType(result[5]);
      this.setDataIntoForm();
      this.forkJoinDone$.next();
    });
    this.getScheduleActions();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getScheduleFields(): Observable<any> {
    return this.supportDataService.generic({name: 'scheduling_dialog_fields'});
  }

  processScheduleFields(r: any) {
    this.fields =  r.data.resource.reduce((fields:any,field:any)=> {
      if(field.type === 'checkbox'){
        field['defaultValue'] =  false;
      }
      return [...fields,field]
    },[]);


    let idx = this.fields.map((o) => o.key).indexOf('cpf');
    if(idx > -1){
      this.setCpfFieldOptions();
    }

    idx = this.fields.map((o) => o.key).indexOf('documents');
    if(idx > -1){
      this.showDocuments = true;
    }

    idx = this.fields.map((o) => o.key).indexOf('palletization');
    if(idx > -1){
      this.fields[idx].props!.change = (field)=>{ this.showInputs(field.formControl!.value) };
    }
  }

  getDepartaments(): Observable<any> {
    return this.supportDataService.generic({name: 'departments'});
  }

  getScheduleActions() {
    if(this.data.schedule.id) {
    const options = {
      department_id: this.data.schedule['department_id'] ? this.data.schedule['department_id'] : null,
      operation_id: this.data.schedule['operation_id'],
      suboperation_id: this.data.schedule['suboperation_id'] ? this.data.schedule['suboperation_id'] : null
    }
    this.agendamentoService.getSchedulingActions(options).subscribe((r: any) => {
      this.scheduleActions = r.data;
    });
    }
  }

  processDepartaments(r: any) {
    // Verifica se existe apenas 1 departamento e atribui o valor padrão.
    // Caso exista mais de um, mostra a listagem
    const idx = this.fields.map((o) => o.key).indexOf('department_id')
    let departments = r.data.resource;
    this.fields[idx].props!.options = departments;
    if(departments.length === 1) {
      this.fields[idx].defaultValue = departments[0].id;
      this.setOperations(this.fields[idx].defaultValue);
      this.fields[idx].className ='display-none';
    }
    this.fields[idx].props!.change = (field, $event)=>{
      if(this.fields.map((o) => o.key).indexOf('operation_id') > -1){
        this.setOperations(field.formControl!.value)
      }
    }
  }

  getOperations(): Observable<any> {
    return this.supportDataService.generic({name: 'operation_type'});
  }

  processOperations(r: any) {
    this.allOperations = r.data.resource;
    const idx = this.fields.map((o) => o.key).indexOf('operation_id');
    if(idx > -1){
      this.fields[idx].props!.options = this.allOperations
      this.fields[idx].props!.change = (field, $event)=>{
        if(this.fields.map((o) => o.key).indexOf('suboperation_id') > -1){
          this.setSuboperations(field.formControl!.value)
        }

        this.getTimeSlots(this.form.value.department_id,this.form.value.operation_id,this.form.value.suboperation_id)
      };
    }
  }


  getSuboperations(): Observable<any> {
    return this.supportDataService.generic({name: 'suboperation_type'});
  }

  processSuboperations(r: any) {
    this.allSuboperations = r.data.resource;
    const idx = this.fields.map((o) => o.key).indexOf('suboperation_id');
    if(idx > -1){
      this.fields[idx].props!.change = ()=>{
        this.getTimeSlots(this.form.value.department_id,this.form.value.operation_id,this.form.value.suboperation_id)
      };
    }
  }

  getVehicleType(): Observable<any> {
    return this.supportDataService.generic({name: 'vehicle_type'});
  }

  //TODO: Pensar em alguma forma de deixar essa func geral.
  showInputs(field:any) {
    let idx = 0;
      idx = this.fields.map((o) => o.key).indexOf('pallet_quantity');
      this.fields[idx] && (this.fields[idx].hide = (field != 2));

      idx = this.fields.map((o) => o.key).indexOf('pallet_type');
      this.fields[idx] && (this.fields[idx].hide = (field != 2));
  }

  showBoard(field:any){
    let qtdPlacas = 0;
    this.dataVehicleType.filter((item: any) => {
      if (item.id == field) {
        qtdPlacas = item.plate_amount;
      }
    });

    let idx = 0;

    //ocultar as placas novamente
    this.fields.forEach( (item: any)=> {
      if((item.key).substring(0, 5) == 'board'){
        this.fields[this.fields.map((o) => o.key).indexOf(item.key)].hide = true;
      }
    });

    if(qtdPlacas == 1){
      idx = this.fields.map((o) => o.key).indexOf('board_horse');
      this.fields[idx].hide = false;
    }

    if(qtdPlacas >= 2){
      idx = this.fields.map((o) => o.key).indexOf('board_horse');
      this.fields[idx].hide = false;

      idx = this.fields.map((o) => o.key).indexOf('board_cart');
      this.fields[idx].hide = false;

      if(qtdPlacas > 2){
        for(let x=2; x<qtdPlacas; x++){
          idx = this.fields.map((o) => o.key).indexOf('board_cart'+x);
          this.fields[idx].hide = false;
        }

      }
    }

  }

  processVehicleType(r: any) {
    const idx = this.fields.map((o) => o.key).indexOf('vehicle_type');
    if(idx > -1){
      this.fields[idx].props!.options = r.data.resource;
      this.dataVehicleType = r.data.resource;
      this.fields[idx].props!.change = (field)=>{ this.showBoard(field.formControl!.value) };
    }
  }

  getCarriers(): Observable<any> {
    return this.carrierService.index({pageSize: 1000});
  }

  processCarriers(r: any) {
    let idx = this.fields.map((o) => o.key).indexOf('carrier_id');
    if(idx > -1){
      this.fields[idx].props!.options = r.data;

      //Verifica se é usuário de transportadora e se possui apenas uma transportadora cadastrada
      //Se sim passa o id de indice 0 do array carriers do usuário como valor padrão e desabilita o input do campo
      if(this.account.is_carrier && this.account.carriers.length === 1){
        this.fields[idx].props!.disabled = true;
        this.fields[idx].defaultValue = this.account.carriers[0];
      }
    }
  }

  // Busca os slots
  getTimeSlots(department_id: number,operation_id: number,suboperation_id: number) {
    const idx = this.fields.map((o) => o.key).indexOf('start_at');
    this.fields[idx].props!.options = [];
    this.form.patchValue({
      start_at: []
    });

    if(operation_id){

      let ts:null|any = null;

      if(this.data.slot) {
        ts = this.data.slot.ts
      }
      if(this.data.schedule.start_at) {
        ts = this.data.schedule.start_at
      }
      const options = {
        department_id: this.use_department === false? 1 : department_id,
        operation_id: operation_id,
        suboperation_id: suboperation_id,
        day: ts ? format(new Date(ts), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        ofsset: this.offset
      }
      this.agendamentoService.multi_time_slots(options).subscribe({
        next:(r: any)=> {
          const idx = this.fields.map((o) => o.key).indexOf('start_at');
          this.fields[idx].props!.options = r;
          this.form.patchValue({
            start_at: ts
          });
        },
        error:(error) => {
          console.log('error', error);
        }
      });
    }
  }

  setDataIntoForm() {
    if(this.data.schedule.id){
      if(typeof(this.data.schedule?.documents && this.data.schedule?.documents > 0)) {
        this.documents = this.data.schedule.documents || [];
      }
      this.fields.forEach((item: any) => {
        let idx = this.fields.map((o) => o.key).indexOf(item.key);
        this.fields[idx].defaultValue = item.type === 'checkbox'? this.data.schedule[item.key] || false : this.data.schedule[item.key];
        if(item.key === 'department_id') {
          this.setOperations(this.data.schedule[item.key])
        }
        if(item.key === 'operation_id') {
          this.setSuboperations(this.data.schedule[item.key])
        }
        if(item.key === 'days_week'){
          try{
            this.fields[idx].defaultValue = this.data.schedule[item.key] ? JSON.parse(this.data.schedule[item.key]) : []
          }catch{
            this.fields[idx].defaultValue = []
          }
        }
        if(item.key === 'vehicle_type') {
           this.showBoard(this.data.schedule[item.key]);
        }
        if(item.key === 'cpf') {
          this.getDriverByCpf(this.data.schedule[item.key]);
        }
        if(item.key === 'documents') {
          this.showDocuments = true;
        }

      });
      this.getTimeSlots(
        this.data.schedule['department_id'],
        this.data.schedule['operation_id'],
        this.data.schedule['suboperation_id'],
      );
    }
  }

  setSuboperations(operation:any){
    const idx = this.fields.map((o) => o.key).indexOf('suboperation_id');
    if(idx > -1){
      this.suboperationsFt = this.allSuboperations.filter((item) => item.operation_id === operation);
      this.fields[idx].props!.options = this.suboperationsFt;
    }
  }

  setOperations(department:any){
    const idx = this.fields.map((o) => o.key).indexOf('operation_id');
    if(idx > -1){
      this.operationsFt = this.allOperations.filter((item) => item.department_id === department);
      this.fields[idx].props!.options = this.operationsFt;
    }
  }

  setCpfFieldOptions() {
    const idx = this.fields.map((o) => o.key).indexOf('cpf');
    this.fields[idx].parsers = [
      value => {
        if(value){
          this.getDriverByCpf(value);
        }
        return value;
      }
    ];
    if(this.data.schedule && this.data.schedule.cpf){
      this.getDriverByCpf(this.data.schedule.cpf);
    }
  }

  upload() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0, uploaded: false });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      !file.uploaded && this.uploadFile(file);
    });
  }

  uploadFile(file:any) {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('bucket_name', 'scheduling');
    file.inProgress = true;

    this.documentsService.uploadBucket(formData).pipe(
      map((event:any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        this.files = this.files.filter(f=>f!=file);

        if(error.error?.message){
          this.dialog.open(DialogAlertComponent,{
            data:{
              title:"Error ao executar o upload.",
              dialogType: "ok_only",
              message: error.error?.message
            }
          })
        }
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          file.uploaded = true;
          this.documents.push(event.body);
        }
      });
  }

  deleteFile(item:any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const pathname = new URL(item.url).pathname;
        const service = item.bucket ? "deleteBucket" : "delete";
        this.documentsService[service]({...item, pathname, bucket_name:'scheduling'})
        .subscribe({
          next: (r: any) => {
            if (r.success) {
              const idx = this.documents.findIndex(v => v.fileName === item.fileName);
              this.documents.splice(idx, idx >= 0 ? 1 : 0);
              this.files.splice(idx, idx >= 0 ? 1 : 0);
            }
          }, error: (error: any) => {
            if(error.error?.message){
              this.dialog.open(DialogAlertComponent,{
                data:{
                  title:"Error ao executar a exclusão.",
                  dialogType: "ok_only",
                  message: error.error?.message
                }
              })
            }
          }
        });
      }
    });
  }

  createFileFromBlob(file: Blob, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  getFile(item: any): void {
    const pathname = new URL(item.url).pathname;
    const service = item.bucket ? "downloadFileBucket" : "downloadFile";
    this.documentsService[service]({...item, pathname, bucket_name:"scheduling"})
      .subscribe({
        next:(val:any) => {
          this.createFileFromBlob(val, item.fileName);
        },
        error:(error:any)=>{
          if(error.error?.message){
            this.dialog.open(DialogAlertComponent,{
              data:{
                title:"Error ao executar o upload.",
                dialogType: "ok_only",
                message: error.error?.message
              }
            })
          }
        }
      });
  }

  getDriverByCpf(cpf: any){

    if(cpf.length > 10){
      const options = {
        cpf: cpf
      }
      if(!this.data.event || this.data.event.cpf != cpf){
        this.driverService.showViaCpf(options).subscribe((r:any) => {
          if(r.id){
            this.form.patchValue({
              driver_name: r.name,
              driver_phone: r.resource.phone,
              driver_cnh: r.resource.cnh
            });

            this.aditionalFields.driver_id = r.id
            let idx = this.fields.map((o) => o.key).indexOf('driver_name');
              this.fields[idx].props!.disabled = true;

              if(this.fields.map((o) => o.key).indexOf('driver_phone') > -1) {
              idx = this.fields.map((o) => o.key).indexOf('driver_phone');
              this.fields[idx].props!.disabled = true;
            }

              if(this.fields.map((o) => o.key).indexOf('driver_cnh') > -1) {
            idx = this.fields.map((o) => o.key).indexOf('driver_cnh');
            this.fields[idx].props!.disabled = true;
            }

            if(idx > -1){
              this.fields[idx].props!.disabled = true;
            }
          }
        });
      }
    }
    else {
      this.aditionalFields.driver_id = null;
      this.form.value.cpf = null;
      this.form.patchValue({
        driver_name: null,
        driver_phone: null,
        driver_cnh: null,
      });
    }
  }

  storeSchedule($type?: string) {
    const data = {
      ...this.aditionalFields,
      ...this.form.value,
      documents: this.documents,
      status: 'waiting',
      carrier_id:this.form.controls.carrier_id.value
    }

      //Incluir número de agendamento se o mesmo existe e foi informado
      if(this.form.getRawValue().hasOwnProperty('schedule_code')) {
        data.schedule_code = this.form.getRawValue().schedule_code ? this.form.getRawValue().schedule_code : null
      }

    if(this.form.status === "VALID") {
      if($type && $type == 'edit'){
        data['action'] = 'edit';
        data['id'] = this.data.schedule.id;

        this.agendamentoService.dinamicScheduleUpdate(data).subscribe((r: any) => {
          if(r.error) {
            this.validationMsgs = r.msg;
          //Se mensagem retornar com status new_code_generated, fechar com o status e mensagem como parametros
          //Para que seja aberto o modal informando o usuário que foi utilizado um código diferente do informado
          }else if(r.status == 'new_code_generated') {
            this.close({status: r.status, message:r.msg});
          }else {
            this.close({message:"Agendamento editado com sucesso!"});
          }
        });

      }else{
        this.agendamentoService.dinamicScheduleCreate(data).subscribe((r: any) => {
          if(r.error) {
            this.validationMsgs = r.msg;
          //Se mensagem retornar com status new_code_generated, fechar com o status e mensagem como parametros
          //Para que seja aberto o modal informando o usuário que foi utilizado um código diferente do informado
          }else if(r.status == 'new_code_generated') {
            this.close({status: r.status, message:r.msg});
          }else {
            this.close({message:"Agendamento criado com sucesso!"});
          }
        });
      }
    }
  }

  close(message?:any) {
    this.dialogRef.close(message);
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  alterStatusEvent($status: string){
    const data = {
      ...this.aditionalFields,
      ...this.form.value,
      documents: this.documents,
      id: this.data.schedule.id,
      status: $status
    }

    this.agendamentoService.alterarStatus(data).subscribe((r: any) => {
      if(r.error) {
        this.validationMsgs = r.msg;
      } else {
        this.close();
      }
    });
  }

  removeSchedule(id: number){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o agendamento?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.agendamentoService.cancel(id).subscribe((r:any) =>{
          if(r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        })
      }
    });

  }

}
