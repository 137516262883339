import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { ShiftsService } from '../../shared/shifts.service';
import { ShiftScheduleInterface, ShiftActions, WeekDay  } from '../../shifts';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-shift-shift-dialog',
  templateUrl: './shift-shift-dialog.component.html',
  styleUrls: ['./shift-shift-dialog.component.scss']
})
export class ShiftShiftDialogComponent implements OnInit {
  formData:Record<string, any> = (this.data.data || {resource:{}}).resource;

  saving = false;
  turnOfday = false;
  weekDay = WeekDay;
  shiftShifts:any[] = [];
  dialogAction = ShiftActions;
  exception = !!this.formData.date_exception;

  shiftForm = this.formBuilder.group({
    name: [this.formData.name || "", [Validators.required]],
    shift_shifts_id: [this.formData.shift_shifts_id || "", [Validators.required]],
    start: [this.formData.start ? moment(this.formData.start, 'HH:mm') : moment().startOf('isoWeek'), [Validators.required]],
    end: [this.formData.end ? moment(this.formData.end, 'HH:mm') : moment().startOf('isoWeek'), [Validators.required]],
    duration:[this.formData.duration || "00:00", [Validators.required, Validators.pattern(/^([0-2][0-3]|0[0-9]):[0-5][0-9]$/)]],
    weekday:[
      this.formData.weekday ||
      [ WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday],
      [Validators.required]
    ],
  })

  constructor(
    private dialog: MatDialog,
    private shiftService:ShiftsService,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ShiftShiftDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {data:ShiftScheduleInterface,[key:string]:any}
  ) { }

  ngOnInit() {

    this.shiftService.indexShifts({orderBy:"name", sortedBy:"asc", pageSize:200})
    .pipe(map((response)=>response.data ?? []))
    .subscribe((data)=>{
      this.shiftShifts = data;
    })
    .add(()=>{
      if(this.data?.data?.resource?.shift_shifts_id)
        this.shiftForm.get("shift_shifts_id")?.setValue(this.data.data.resource.shift_shifts_id);
    })
    const start = this.shiftForm.get("start");
    const end = this.shiftForm.get("end");
    const duration = this.shiftForm.get("duration");

    start?.valueChanges.subscribe((value:any)=>{
      if(duration?.value?.match(/^([0-2][0-3]|0[0-9]):[0-5][0-9]$/)){
        end?.setValue(moment(value).add(moment.duration(duration?.value)), { emitEvent: false })
      }
    });

    end?.valueChanges.subscribe((value)=>{
      if(start?.value && value){
        const diff = moment.duration(value.diff(start.value));
        duration?.setValue(moment.utc(diff.asSeconds()*1000).format('HH:mm'), { emitEvent: false });
      }
    })

    duration?.valueChanges.subscribe((value:string)=>{
      if(value.match(/^([0-2][0-3]|0[0-9]):[0-5][0-9]$/) && start?.value){
        end?.setValue(moment(start?.value).add(moment.duration(value)), { emitEvent: false })
      }
    })

    this.shiftForm.valueChanges.subscribe(()=>{
      if(start?.value && duration?.value?.match(/^([0-2][0-3]|0[0-9]):[0-5][0-9]$/)){
        this.turnOfday = (
          moment.duration(start.value.format("HH:mm")).asMilliseconds()
          +
          moment.duration(duration?.value).asMilliseconds()
        ) > moment.duration("23:59").asMilliseconds();
      }
    });

  }

  onSubmit(){
    this.saving = true;
    this.save()
    .subscribe((data:any)=>{
      if(data){
        this.dialog.open(DialogAlertComponent,{
          data:{
            title:"Turno",
            message:"Dados salvos com sucesso."
          }
        })
        .beforeClosed()
        .subscribe(()=>this.dialogRef.close(true))
      }
    })
    .add(()=>this.saving = false)
  }

  private save():Observable<any>{
    const resource = this.shiftForm.getRawValue() as ShiftScheduleInterface["resource"];
    const data:ShiftScheduleInterface = {
      ...this.data.data,
      resource,
      name: "shift"
    };

    data.resource.start = moment(data.resource.start).format("HH:mm");
    data.resource.end = moment(data.resource.end).format("HH:mm");

    if(this.data.action === this.dialogAction.ADD)
      return this.shiftService.storeShiftSchedules(data)

    if(this.data.action === this.dialogAction.EDIT)
      return this.shiftService.updateShiftSchedules({...data, id:this.data.data.id!})

    return of(null)
  }

}
