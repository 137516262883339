<h2 class="tkg-dialog-title" mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content>
  <h3>{{message}}</h3>
  <div class="form-container">
    <formly-form
      [model]="model"
      [fields]="fields"
      [options]="options"
      [form]="form"
    ></formly-form>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">
    Cancelar
  </button>
  <button
    type="submit"
    dkFocusInitial
    mat-raised-button
    color="primary"
    style="color: white"
    (click)="onSubmit()"
    [disabled]="!form.valid"
  >
    Ok
  </button>
</div>
