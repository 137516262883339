import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Search } from './search';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchSubject = new Subject<Search>();
  private showSubject = new Subject<boolean>();
  private clearSubject = new Subject<any>();
  value = this.searchSubject.asObservable();
  show = this.showSubject.asObservable();
  clear = this.clearSubject.asObservable();

  constructor() { }

  onChange(value: string, scope: string) {
    this.searchSubject.next(<Search>{
      value: value,
      scope: scope,
    });
  }

  onOpen() {
    this.showSubject.next(true);
  }

  onClose() {
    this.showSubject.next(false);
  }

  onClear(event?: Event) {
    this.clearSubject.next(event);
  }
}
