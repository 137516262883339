import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DashboardDynamicService } from './../../../../dashboard-dynamic/dashboard-dynamic.service';
import { CardInterface } from './../../card.interface';
import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  operacao: string;
  tempo: number;
}

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss']
})
export class CardTableComponent implements OnInit, CardInterface {
  value = 0;
  data: any;
  layout!: number;
  options: any;
  infoHtml: SafeHtml;

  displayedColumns = ['name', 'data'];
  dataSource = [];

  constructor(private sanitizer:DomSanitizer,private dashboardDynamic: DashboardDynamicService) {
   }

   ngOnInit() {
    if(typeof(this.data.info_html) == "string"){
      this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.info_html as string);
    }
    this.dashboardDynamic.getDynamicDashboardItem({ item: this.data.element_name}).subscribe((obj: any) =>
    {
      this.dataSource = obj
   })
  }

}
