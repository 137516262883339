<form [formGroup]="form">
  <h2 class="tkg-dialog-title" mat-dialog-title>{{data.title}}</h2>

  <div mat-dialog-content>
    <!-- <h3>{{message}}</h3> -->
    <div class="form-container">
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input type="text" formControlName="name" matInput placeholder="Nome" />
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.name">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CPF/CNPJ</mat-label>
        <input
          type="text"
          formControlName="cpf_cnpj"
          matInput
          placeholder="CPF/CNPJ"
        />
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.cpf_cnpj">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Código</mat-label>
        <input
          type="text"
          formControlName="code"
          matInput
          placeholder="Código"
        />
        <mat-hint class="mat-error">
          <div *ngFor="let error of errors.code">{{ error }}</div>
        </mat-hint>
      </mat-form-field>
      <div class="add-item">
        <div class="item-left">Adicionar contato</div>
        <div class="item-right">
          <button mat-icon-button aria-label="Add" (click)="addContact()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div
        formArrayName="contacts"
        *ngFor="let contact of $any(form).get('contacts')['controls']; let i = index;"
      >
        <div [formGroupName]="i" class="tkg-contacts">
          <mat-form-field>
            <mat-label>Nome</mat-label>
            <input
              type="text"
              formControlName="name"
              matInput
              placeholder="Nome"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              type="text"
              formControlName="email"
              matInput
              placeholder="Email"
            />
          </mat-form-field>
          <button
            mat-icon-button
            aria-label="Apagar"
            (click)="removeContact(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close="true">
      Cancelar
    </button>
    <button
      type="submit"
      dkFocusInitial
      mat-raised-button
      color="primary"
      style="color: white"
      (click)="save()"
    >
      Salvar
    </button>
  </div>
</form>
