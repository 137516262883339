import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";

import { User } from "../user";
import { Account } from "../../account/account";
import { environment } from "../../../environments/environment";
import { AccountService } from "../../account/shared/account.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
  ) {}

  index(options?: any): Observable<User> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );

    const params = new HttpParams({ fromObject: httpParams });

    const url = `${environment.apiUrl}/users`;

    return this.http.get<User>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  update(data: any, id: string, params?: any): Observable<User> {
    const url = `${environment.apiUrl}/users/${id}`;
    const options = { params: params };

    return this.http.put<User>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      tap((response: User) => {
        this.accountService.identity().then((account: Account) => {
          if (response.id === account.id) {
            this.accountService.identity(true).then((renewed: Account) => {
              localStorage.setItem("account", JSON.stringify(renewed));
              this.accountService.event.emit({
                action: "update",
                data: renewed,
              });
            });
          }
        });
      }),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  create(data: any, params?: any): Observable<User> {
    const url = `${environment.apiUrl}/users`;
    const options = { params: params };

    return this.http.post<User>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/users/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  userGroups(options?: any): Observable<any> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );

    const params = new HttpParams({ fromObject: httpParams });

    const url = `${environment.apiUrl}/user-groups`;

    return this.http.get<User>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  createGroups(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/user-groups`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateGroups(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/user-groups`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteGroups(id: string, params?: any) {
    const url = `${environment.apiUrl}/user-groups/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  pageModules(options?: any): Observable<any> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc",
      },
      options,
    );

    const params = new HttpParams({ fromObject: httpParams });

    const url = `${environment.apiUrl}/modules`;

    return this.http.get<User>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  userLogout(id: number | string): Observable<any> {
    const url = `${environment.apiUrl}/user-logout/${id}`;

    return this.http.get<User>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  groupLogout(id: number | string): Observable<any> {
    const url = `${environment.apiUrl}/group-logout/${id}`;

    return this.http.get<User>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  userLogoutAll(): Observable<any> {
    const url = `${environment.apiUrl}/user-logout-all`;

    return this.http.get<User>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  bpType(): Observable<any> {
    const url = `${environment.apiUrl}/business-partners-type`;

    return this.http.get<User>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }
}
