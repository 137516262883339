import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, FormControl } from '@angular/forms';
import { forkJoin, of as observableOf } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, debounceTime, tap } from 'rxjs/operators';
import { DriversService } from 'src/app/drivers/shared/drivers.service';
import { CarrierService } from '../../carrier/shared/carrier.service';
import { SupportDataService } from '../../shared/support-data.service';
import { ReportDynamicService } from '../shared/report-dynamic.service';

@Component({
  selector: 'app-email-report-dialog-v1',
  templateUrl: './email-report-dialog-v1.component.html',
  styleUrls: ['./email-report-dialog-v1.component.scss']
})

export class EmailReportDialogV1Component implements OnInit{
  reportChunkStarted=false;
  account:any = {};

  form = this.formBuilder.group({
    driver_id: new FormControl(),
    driverFilter: new FormControl(),
    carrier_id: new FormControl(),
    carrierFilter: new FormControl(),
    department: new FormControl(),
    operation: new FormControl(),
    suboperation: new FormControl(),
    file_type: new FormControl(),
    dt_start: new FormControl<Date | null>(null),
    dt_end: new FormControl<Date | null>(null)
  });

  departmentsList: any = [];
  departmentsFiltered: any = [];
  operationsList: any = [];
  operationsFiltered: any = [];
  subOperationsFiltered: any = [];
  subOperationsList: any = [];
  showOperation = false;
  showSubOperation = false;
  searchingDriver = false;
  searchingCarrier = false;
  minDate: Date;
  maxDate: Date;
  driverListOptions: any = [];
  carrierListOptions: any = [];

  activeFilters:any[]=[]

  constructor(
    public dialogRef: MatDialogRef<EmailReportDialogV1Component>,
    @Inject(MAT_DIALOG_DATA) public data: {type: string},
    private datePipe: DatePipe,
    private supportDataService:SupportDataService,
    private carrierService:CarrierService,
    private driversService:DriversService,
    private reportDynamicService:ReportDynamicService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.account = JSON.parse(localStorage.getItem("account") ?? "{}");

    //Seta o limite minímo e máximo do campo de data 'Período'
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    let yearToUse = currentYear;
    let monthToUse = currentMonth - 3;

    if (monthToUse < 0) {
      //Caso monthToUse seja negativo (O que ocorre se o mês atual - 3 meses resulte em um mês do ano passado [Ex: Janeiro])
      //Subtrair 1 ano do ano atual e adicionar 12 a monthToUse para pegar o mês correto no ano passado
      yearToUse = currentYear - 1;
      monthToUse = 12 + monthToUse;
    }

    //Seta maxDate como o último dia do mês atual
    this.maxDate = new Date(currentYear, currentMonth + 1, 0);

    //Seta minDate como o primeiro dia do mês de 4 meses atrás
    this.minDate = new Date(yearToUse, monthToUse, 1);

  }

  ngOnInit() {

    //TODO IMPORTANT: Wait for HMG to correctly have the report_carrier_user_filter code in its branch
    //Then stash, create a new branch off hmg and use the stash changes in your new branch, keep code organized

    forkJoin([
      this.loadDepartments(),
      this.loadOperations(),
      this.loadSubOperations(),
      this.loadDrivers(),
      this.loadCarriers()
    ])
      .subscribe(([departments, operations, subOperations, drivers, carriers]) => {

        this.departmentsList = departments;
        this.operationsList = operations;
        this.subOperationsList = subOperations;
        this.driverListOptions = drivers;
        this.carrierListOptions = carriers;

        //Tratamento Departamentos
        if (this.account.department_id.length == 1) {

          this.form.controls.department.disable();
          this.form.controls.operation.enable();
          this.showOperation = true;
          this.departmentChange();

        } else {

          this.departmentsFiltered = this.departmentsList.filter((item: any) => {
            return this.account.department_id.indexOf(item.id) > -1;
          })
          this.form.controls.operation.disable();
          this.showOperation = false;
        }

        //Tratamento Usuário de Transportadora, se possuir uma única transportadora o campo é desabilitado
        if (this.account.is_carrier && this.account.carriers?.length == 1) {
          this.form.controls.carrier_id.disable();
        }

      });

      //Controla o retorno de resultados de pesquisa do campo de motoristas
      this.form.controls.driverFilter.valueChanges.pipe(
        debounceTime(300),
        tap(()=>this.searchingDriver = true)
      ).subscribe((search:string)=>{
        this.driversService.index({
          search: (search ?? ''),
          orderBy: 'name',
          sortedBy: 'ASC',
          page: 1,
          pageSize: 20
        }).pipe(
          map((result:any)=>result.data || []),
          catchError(() => observableOf([]))
        )
        .pipe(tap(()=>this.searchingDriver = false))
        .subscribe((options:any[])=>{
          if (this.form.controls.driver_id.value) {
            this.driverListOptions = [this.form.controls.driver_id.value, ...options.filter(i => i.id !== this.form.controls.driver_id.value.id)];
            this.form.controls.driver_id.setValue(this.form.controls.driver_id.value, { emitEvent: false });
          } else {
            this.driverListOptions = options;
          }
        })
      })

      //Controla o retorno de resultados de pesquisa do campo de transportadoras
      this.form.controls.carrierFilter.valueChanges.pipe(
        debounceTime(300),
        tap(()=>this.searchingCarrier = true)
      ).subscribe((search:string)=>{
        this.carrierService.index({
          search: (search ?? ''),
          orderBy: 'carrier_client.updated_at',
          sortedBy: 'DESC',
          pageSize: 20,
          page: 1
        }).pipe(
          map((result: any) => result.data || []),
          catchError(() => observableOf([]))
        )
          .pipe(tap(() => this.searchingCarrier = false))
          .subscribe((options: any[]) => {
            if (this.form.controls.carrier_id.value) {
              this.carrierListOptions = [this.form.controls.carrier_id.value, ...options.filter(i => i.id !== this.form.controls.carrier_id.value.id)];
              this.form.controls.carrier_id.setValue(this.form.controls.carrier_id.value, { emitEvent: false });
            } else {
              this.carrierListOptions = options;
            }
          })
      })
  }

  private loadDepartments() {
    return this.supportDataService.departments()
      .pipe(
        map((response: any) => response.data.resource)
      )
      .pipe(
        catchError(() => observableOf([]))
      )
  }

  private loadOperations(){
    return this.supportDataService.operation()
    .pipe(
      map((response: any) => response.data.resource)
    )
    .pipe(
      catchError(() =>  observableOf([]))
    )
  }

  private loadSubOperations() {
    return this.supportDataService.suboperation()
      .pipe(map(r => r.data.resource || [])).pipe(
        catchError(() => observableOf([]))
      )
  }

  private loadDrivers() {
    return this.driversService.index({
      orderBy: 'name',
      sortedBy: 'ASC',
      page: 1,
      pageSize: 20
    })
      .pipe(
        map((response: any) => response.data)
      )
      .pipe(
        catchError(() => observableOf([]))
      )
  }

  private loadCarriers() {
    return this.carrierService.index({
      orderBy: 'carrier_client.updated_at',
      sortedBy: 'DESC',
      pageSize: 20,
      page: 1
    })
      .pipe(
        map((response: any) => response.data)
      )
      .pipe(
        catchError(() => observableOf([]))
      )
  }

  departmentChange() {
    if(this.account.department_id.length == 1) {
      this.operationsFiltered = this.operationsList.filter((op: any) => op.department_id === this.account.department_id[0]);
    } else {
      this.operationsFiltered = this.operationsList.filter((op: any) => op.department_id === this.form.controls.department.value);
    }

    //Desabilita e limpa o valor do campo de operações dependendo se o cliente tem departamento único e se o departamento possui operações
    if (this.operationsFiltered.length > 0) {
      this.form.controls.operation.setValue(null);
      this.form.controls.operation.enable();

      this.form.controls.suboperation.setValue(null);
      this.form.controls.suboperation.disable();

      this.showOperation = true;
      this.showSubOperation = false;

    } else {
      this.form.controls.operation.setValue(null);
      this.form.controls.operation.disable();

      this.form.controls.suboperation.setValue(null);
      this.form.controls.suboperation.disable();

      this.showOperation = false;
      this.showSubOperation = false;
    }
  }

  //
  operationChange() {
    //Desabilita e limpa o valor do campo de suboperações dependendo se a operação possui suboperações
    if (this.subOperationsList.length > 0) {
      this.subOperationsFiltered = this.subOperationsList.filter((op: any) => op.operation_id === this.form.controls.operation.value);
      if (this.subOperationsFiltered.length > 0) {
        this.form.controls.suboperation.setValue(null);
        this.form.controls.suboperation.enable();
        this.showSubOperation = true;
      } else {
        this.form.controls.suboperation.setValue(null);
        this.form.controls.suboperation.disable();
        this.showSubOperation = false;
      }
    }
  }

  addFilters() {
    const arrayFilters = Object.entries(this.form.value).filter((filterItem: any) =>
      filterItem[1] &&
      filterItem[0] !== 'driverFilter' &&
      filterItem[0] !== 'carrierFilter'
    );

    this.handleFilters(arrayFilters);

    this.form.controls.suboperation.disable();
    this.showSubOperation = false;

    if (this.account.department_id.length > 1) {
      this.form.controls.operation.disable();
      this.showOperation = false;
    }

    this.form.reset();
  }

  //Atribui o filtor para activeFilters dependendo do tipo de filtro
  handleFilters(arrayFilters: any[]) {
    let date_start: any;
    let date_end: any;

    arrayFilters.forEach((item: any) => {
      switch (item[0]) {

        case 'driver_id':
          this.deleteFilter(this.activeFilters.find(filter => filter.key == item[0] && filter.value == item[1].id));
          this.activeFilters.push({
            key: item[0],
            label: 'Motorista',
            value: item[1].id,
            name: item[1].name
          });
          break;

        case 'carrier_id':
          this.deleteFilter(this.activeFilters.find(filter => filter.key == item[0] && filter.value == item[1].id));
          this.activeFilters.push({
            key: item[0],
            label: 'Transportadora',
            value: item[1].id,
            name: item[1].name
          });
          break;

        case 'department':
          this.deleteFilter(this.activeFilters.find(filter => filter.key == item[0] && filter.value == item[1]));

          this.activeFilters.push({
            key: item[0],
            label: 'Departamento',
            value: item[1],
            name: this.departmentsFiltered.find((dept: any) => dept.id == item[1]).name ?? ''
          });
          break;

        case 'operation':
          this.deleteFilter(this.activeFilters.find(filter => filter.key == item[0] && filter.value == item[1]));

          this.activeFilters.push({
            key: item[0],
            label: 'Operação',
            value: item[1],
            name: this.operationsFiltered.find((op: any) => op.id == item[1]).name ?? ''
          });
          break;

        case 'suboperation':
          this.deleteFilter(this.activeFilters.find(filter => filter.key == item[0] && filter.value == item[1]));

          this.activeFilters.push({
            key: item[0],
            label: 'Suboperação',
            value: item[1],
            name: this.subOperationsFiltered.find((subop: any) => subop.id == item[1]).name ?? ''
          });
          break;

        case 'file_type':
          this.deleteFilter(this.activeFilters.find(filter => filter.key == item[0]));

          this.activeFilters.push({
            key: item[0],
            label: 'Formato do Arquivo',
            value: item[1],
            name: item[1] === 'xlsx' ? 'XLSX' : 'CSV'
          });
          break;

        case 'dt_start':
          date_start = this.datePipe.transform(item[1], 'yyyy-MM-dd');
          break;

        case 'dt_end':
          date_end = this.datePipe.transform(item[1], 'yyyy-MM-dd');
          break;

        default:
          break;
      }
    })

    if (date_start && date_end) {
      this.deleteFilter(this.activeFilters.find(filter => filter.key == 'range'));

      this.activeFilters.push({
        key: 'range',
        label: 'Período',
        name: `${this.datePipe.transform(date_start, "dd/MM/yyyy")} - ${this.datePipe.transform(date_end, "dd/MM/yyyy")}`,
        mult: [
          { key: "dt_start", value: date_start },
          { key: "dt_end", value: date_end }
        ]
      });
    }
  }

  deleteFilter(activeFilter: any) {
    this.activeFilters = this.activeFilters.filter(x=>x!=activeFilter);
  }

  private getFilters(){
    let options: any = {};
    let filterNames: any = {};

    this.activeFilters.forEach(f=>{
      if(f.mult){
        f.mult.forEach((o: any) =>{
          !options[o.key] && (options[o.key] = new Set());
          options[o.key].add(o.value)
        })
      }else{
        !options[f.key] && (options[f.key] = new Set());
        options[f.key].add(f.value);
      }

      //Nome do filtro e o valor que foi selecionado (Ex: operation, valor: 'Inbound')
      //Como essas informações já estão aqui, passar elas para API salva o trabalho de mais requisições no banco em support_data
      //Como é possivel especificar mais de uma opção para cada filtro (Ex: 2 Operações) se a key já existir em filterNames
      //O seu valor é concatenado com a f.name (Ex: Operação: Inbound + ', ' + Outbound)
      if (filterNames.hasOwnProperty(f.key) && filterNames[f.key]) {
        filterNames[f.key] += ', ' + f.name;
      } else {
        filterNames[f.key] = f.name;
      }
    })

    Object.keys(options).forEach((k) => {

      const opt = Array.from(options[k]);
      options[k] = opt.join('|');
    })

    //Adiciona filterNames para as options que serão passadas para a API
    options['filter_names'] = filterNames;
    return options;
  }

  //Gera o relatório e o envia por e-mail
  generateEmailReport(): void {
    const options: any = this.getFilters();
    options.email = "true";

    this.reportDynamicService.report(this.data.type, options).subscribe({
      next: (result: any) => {
          this.dialogRef.close(result);
      },
      error: (err: any) => {
        this.dialogRef.close(err.error);
       }
    })
  }

  isSubmitDisabled(): boolean {
    //Verifica se o filtro de data e tipo de arquivo foram incluídos, se sim habilita o botão de 'Gerar Relatório'
      return !(
        (this.activeFilters.some((obj) => obj.hasOwnProperty('key') && obj['key'] === 'range')) &&
        (this.activeFilters.some((obj) => obj.hasOwnProperty('key') && obj['key'] === 'file_type'))
      );
  }

  downloadReport(): void {
    if(this.reportChunkStarted)
      return;

    const options:any = this.getFilters();
    options.export = "true";

    this.reportChunkStarted = true;

    const chunks: any[] = [];

    const reportChunks = (numberOfChunk:number)=>{
      options.chunk = numberOfChunk;
      this.reportDynamicService.report('scheduling', options)
      .pipe(
        catchError(() => {
          return observableOf({csv:"",rows:0});
        })
      )
      .subscribe(
        (r: any) => {
          try{
            chunks.push(r.csv || "");
            if(numberOfChunk <= 10 && r.rows >= 10000){
              reportChunks(numberOfChunk + 1);
            }else if(chunks.length > 0){
              this.createFileFromBlob(
                chunks.reduce((textConcat, text, index)=>{
                  if(index > 0 && text)
                    return textConcat + text.substring(text.indexOf("\n") + 1)
                  return textConcat + text;
                }),
                'report.csv'
              );
              this.reportChunkStarted = false;
            }

          }catch(ex){
            this.reportChunkStarted = false;
          }
      })
    }
    reportChunks(0);
  }

  private createFileFromBlob(file: any, fileName: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob(["\uFEFF" + file], { type: 'text/csv; charset=utf-18' });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}
