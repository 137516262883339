import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DashboardJandiraService } from '../shared/dashboard-jandira.service';
import * as moment from 'moment';

export interface PeriodicElement {
  name: string;
  status: string;
  time: string;
}

@Component({
  selector: 'app-ofensores-dialog',
  templateUrl: './ofensores-dialog.component.html',
  styleUrls: ['./ofensores-dialog.component.scss']
})

export class OfensoresDialogComponent implements OnInit {

  nameOfensor: string;

  displayedColumns: string[] = ['name', 'status', 'time'];
  dataSource: PeriodicElement[] = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dashboardService: DashboardJandiraService
  ) { }

  ngOnInit() {

    if(this.data.etapa == 'entrada'){
      this.nameOfensor = 'Entrada';
    }
    if(this.data.etapa == 'patio'){
      this.nameOfensor = 'Pátio';
    }
    if(this.data.etapa == 'carregamento'){
      this.nameOfensor = 'Carregamento';
    }
    if(this.data.etapa == 'descarregamento'){
      this.nameOfensor = 'Descarregamento';
    }
    if(this.data.etapa == 'expedicao'){
      this.nameOfensor = 'Expedição';
    }
    if(this.data.etapa == 'gr'){
      this.nameOfensor = 'GR';
    }
    if(this.data.etapa == 'saida'){
      this.nameOfensor = 'Saída';
    }

    this.topOfensores();

  }

  segundosParaHoraMinuto(segundos: string) {
    if (segundos) {
      return moment.duration(parseFloat(segundos), "seconds").format('DD[d] hh[h]mm[m]');
    } else {
      return null;
    }
  }

  topOfensores() {
    const limit = 5;
    const options = {
      limit,
      etapa: this.data.etapa,
      start: this.data.definedDay.start,
      end: this.data.definedDay.end
    };
    return this.dashboardService.topOfensores(options).subscribe((data: any) => {
      for(let dado of data){
        dado.time = this.segundosParaHoraMinuto(dado.time);
      }
      this.dataSource = data;
    });
  }

}
