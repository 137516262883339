import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CarrierRoutingModule } from '../carrier/carrier-routing.module';
import { CarrierListComponent } from '../carrier/carrier-list/carrier-list.component';
import { CarrierDetailComponent } from '../carrier/carrier-detail/carrier-detail.component';
import { CarrierService } from '../carrier/shared/carrier.service';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        CarrierRoutingModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TextMaskModule,
        NgxMaskPipe
    ],
    declarations: [
        CarrierListComponent,
        CarrierDetailComponent
    ],
    providers: [
        CarrierService,
        provideNgxMask()
    ]
})
export class CarrierModule { }
