
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JourneyStatusTimelineComponent } from './journey-status-timeline/journey-status-timeline.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { ROUTE_JOURNEY_TIMELINE_STATUS } from './timelines.const';

const routes: Routes = [
  {
    path:'',
    children:[
      {
        path: ROUTE_JOURNEY_TIMELINE_STATUS,
        component: JourneyStatusTimelineComponent
      },
    ],
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always'
  },
];



@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class TimelinesRoutingModule { }
