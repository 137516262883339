import { CoreGeofenceService } from "./../../shared/geofence.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreService } from "src/app/shared/core.service";
import { WSService2 } from "src/app/shared/websocket/wss2.service";

@Component({
  selector: "app-generic-view-map",
  templateUrl: "./generic-view-map.component.html",
  styleUrls: ["./generic-view-map.component.scss"],
})
export class GenericViewMapComponent implements OnInit {
  renderMap = false;
  pageParam: string;
  center: any;
  zoom: number = 15;
  geoJSONPoints: any;
  geoJSONlayers: any;
  constructor(
    private route: ActivatedRoute,
    private coreAPIService: CoreService,
    private coreGeofenceService: CoreGeofenceService,
    private wssService: WSService2,
  ) {}

  ngOnInit(): void {
    try {
      this.wssService.closeConnection();
    } catch (e: any) {
      console.log(e);
    }
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "visible") {
          this.wssService.closeConnection();
          console.log("reconect websocket");
          this.getGeoJSONMarkers();
          this.subcribeWebsocket();
        }
      },
      false,
    );
    this.subcribeWebsocket();
    this.route.params.subscribe((params: any) => {
      this.pageParam = params.page || "";
      this.startMapResources();
    });
  }

  private startMapResources() {
    this.getCenterOfTheGeoFence();
    this.getGeoJSONMarkers();
    this.getGeoJSONgeofences();
  }

  private getGeoJSONMarkers() {
    this.coreAPIService.getLocation(this.pageParam).subscribe((r: any) => {
      if (r.error == false) {
        this.geoJSONPoints = r.data;
        this.adjustGeoJSON2LeafletCoordinates();
      }
    });
  }

  private getGeoJSONgeofences() {
    this.coreGeofenceService.getGeoFence().subscribe((r: any) => {
      this.geoJSONlayers = r.data;
      this.renderMap = true;
    });
  }

  private getCenterOfTheGeoFence() {
    this.coreGeofenceService.getCenterOfGeoFence().subscribe((r: any) => {
      if (r.error == false) {
        this.center = [r.data.lat, r.data.lng];
      }
    });
  }

  private subcribeWebsocket() {
    console.log("subscribe websocket");
    let client = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wssService.CHANNEL = `/last_location/${client}`;
    this.wssService.connect().subscribe((msg: any) => {
      if (msg.x && msg.y) {
        this.updateGeoJsonFromXY(msg.id, msg.x, msg.y);
      }
    });
  }

  private updateGeoJsonFromXY(id: number, x: number, y: number) {
    this.geoJSONPoints.filter((ft: any) => {
      if (ft.properties.id === id) {
        ft.geometry.coordinates = [x, y];
      }
    });

    this.geoJSONPoints = this.geoJSONPoints.slice();
  }

  /*
    Transformação necessária pois a biblioteca leaflet adota o padrão de coordenadas [lat, lng] ou [y, x]
    e o formato geoJSON utiliza o padrão [lng, lat] ou [x, y]
  */
  private adjustGeoJSON2LeafletCoordinates() {
    this.geoJSONPoints.forEach((item: any) => {
      item.geometry.coordinates = [
        item.geometry.coordinates[1],
        item.geometry.coordinates[0],
      ];
    });
  }
}
