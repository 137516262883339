import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, tap, debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { DocumentsService } from '../shared/documents.service';
import { Document } from '../documents.interface';
import { DocumentsDialogComponent } from '../documents-dialog/documents-dialog.component'
@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit {
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<Document>();
  dataSourceAll = new MatTableDataSource<Document>();
  pageSize: number;
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  isWeb = true;
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public documentsService: DocumentsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });

  }

  ngOnInit() {
    this.loadDocuments();
  }

  loadDocuments() {
    /// Ao utilizar a busca
    this.searchFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          orderBy: this.sort.active ? this.sort.active : 'updated_at',
          sortedBy: this.sort.direction ? this.sort.direction : 'DESC',
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.paginator.pageIndex + 1,
          search: `${this.searchFilter.value}` || ''
        };
        return this.documentsService.index(options);
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.dataSource.data = data;
      this.dataSourceAll.data = data;
    });


    /// Ao entrar na página
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            with: 'carrier',
          };

          return this.documentsService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {

          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
      });
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset) || this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      this.displayedColumns = ['order_number', 'driver', 'doc_date', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.displayedColumns = ['order_number', 'driver', 'doc_date', 'doc_notes', 'actions'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['order_number', 'driver', 'doc_date', 'doc_notes', 'actions'];
    }
  }

  addEditDocs(item: any, action: string) {
    console.log(item);
    let data = {};
    if (typeof (item) === 'object') {
      data = item;
    } else {
      data = {
        resource: {
          order_number: '',
          driver_id: '',
          send_at: '',
          notes: '',
          documents: []
        }
      };
    }
    const dialogRef = this.dialog.open(DocumentsDialogComponent, {
      width: '900px',
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(data)),
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(data => {

      if (data != 'true') {
        if (action === 'ADD' || action === 'EDIT') {
          this.loadDocuments();
          this.snackBar.open('Operação realizada com sucesso!', 'OK', {
            duration: 3000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });

        }
      }
    });
  }

}
