import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { Observable, Subject, catchError, map, merge, of, startWith, switchMap, tap } from 'rxjs';

import { ShiftsService } from '../../shared/shifts.service';
import { ShiftDialogComponent } from '../../dialogs/shift-dialog/shift-dialog.component';
import { IndexShiftResponseInterface, ShiftActions, ShiftInterface, Viewport } from '../../shifts';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-tab-shift',
  templateUrl: './tab-shift.component.html',
  styleUrls: ['./tab-shift.component.scss']
})
export class TabShiftComponent implements AfterViewInit{
  @Input() viewport$:Observable<Viewport>;

  @ViewChild('sort', {static: false}) sort: MatSort;
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;


  pageSize = 1;
  viewTable = true;
  actionEnum = ShiftActions;
  shiftSubject$ = new Subject<void>();

  columns:Array<[string, string, boolean, string?, (string|any[])?]>=[
    ["Nome","name", true],
    ["Criado em", "created_at", false, "datetime", "dd/MM/yyyy hh:mm"],
    //["Ativo","active", false, "booelan", ["Sim","Não"]]
  ];

  shift = {
    columns:this.columns.map(item=>({
      key:item[1],
      name:item[0],
      sort:item[2],
      pipe:item[3],
      format:item[4]
    })),
    displayedColumns:[...this.columns.map(item=>item[1]),'actions'],
    dataSource:[] as ShiftInterface[]
  }

  constructor(
    private dialog: MatDialog,
    private shiftsService: ShiftsService
  ){}

  ngAfterViewInit(): void {
    this.initService()
    .subscribe((data)=>{
      this.shift.dataSource = data;
    });

    this.viewport$.subscribe((viewport)=>{
      this.viewTable = !((["200-500", "500-800"] as any[]).includes(viewport));
    })
  }

  open(action:ShiftActions, data?:any){
    return this.dialog.open(ShiftDialogComponent, {
      disableClose:true,
      data:{
        action,
        data
      },
      maxWidth:540,
      minWidth:320
    })
    .beforeClosed()
    .subscribe((reload:boolean)=>{
      reload && this.shiftSubject$.next();
    })
  }

  destroy(element:ShiftInterface){
    const message = `Deseja excluir o item "${element.name}"?`;
    const title = "Exclusão de turno.";

    this.dialog.open(DialogConfirmComponent, {
      data:{
        title,
        message
      }
    })
    .beforeClosed()
    .subscribe((destroy:boolean)=>{
      destroy
      &&
      this.shiftsService
      .destroyShift(element.id!)
      .subscribe(()=>this.shiftSubject$.next())
    })
  }

  private initService(){
    this.sort.sort(<MatSortable>{
        id: 'name',
        start: 'asc'
      }
    );

    this.sort.sortChange.subscribe(() =>this.paginator.pageIndex = 0);

    return  merge(...[this.sort.sortChange, this.paginator.page, this.shiftSubject$].filter(i=>i))
      .pipe(
        startWith(null),
        switchMap(() => {
          this.shift.dataSource = [];

          const options:any = {
            page: this.paginator?.pageIndex + 1,
            orderBy: this.sort?.active,
            sortedBy: this.sort?.direction
          };

          return this.shiftsService.indexShifts(options);
        }),
        tap((response: IndexShiftResponseInterface) => {
          if(this.paginator){
            if (!response.data.length && this.paginator?.hasPreviousPage()) {
              this.paginator.previousPage();
            }

            this.paginator.length = response.total;
            this.paginator.pageSize = response.per_page;
          }
        }),
        map((response) => {
          return response.data;
        }),
        catchError(() => {
          return of<ShiftInterface[]>([]);
        })
      ).pipe(map((data)=>{
        return data;
      }))
  }
}
