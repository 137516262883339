import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';
import { HealthCheckComponent } from './health-check/health-check.component';
import { HealthCheckListComponent } from './health-check-list/health-check-list.component';

const HealthRoutes: Routes = [
  // {
  //   canActivate: [AccountGuardService],
  //   runGuardsAndResolvers: 'always',
  //   path: 'admin/healthCheck',
  //   component: HealthCheckComponent,
  //   data: {
  //     title: 'Health Check',
  //     search: true,
  //     refresh: true
  //   }
  // },
  // {
  //   canActivate: [AccountGuardService],
  //   runGuardsAndResolvers: 'always',
  //   path: 'admin/healthCheckList',
  //   component: HealthCheckListComponent,
  //   data: {
  //     title: 'Health Check',
  //     search: true,
  //     refresh: true
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forChild(HealthRoutes)],
  exports: [RouterModule]
})
export class HealthCheckRoutingModule { }
