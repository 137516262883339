import { of as observableOf } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { debounceTime, map, catchError, switchMap, startWith, tap } from "rxjs/operators";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";

import { StageInfo } from "../media.interface";
import { HubService } from "./../shared/hub.service";
import { CheckinService } from "src/app/checkin/shared/checkin.service";


@Component({
  selector: "app-hub-info",
  templateUrl: "./hub-info.component.html",
  styleUrls: ["./hub-info.component.scss"],
})
export class HubInfoComponent implements OnInit {
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  account: any;
  envData: any;
  dataSource: any;
  itens: StageInfo[]
  searching = false;
  displayedColumns: string[] = ["board_horse", "board_cart", "driver_name", "operation_name", "suboperation_name", "stage_time", ];

  constructor(
    public hubService: HubService,
    public checkinService: CheckinService,
    public dialogRef: MatDialogRef<HubInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.envData = JSON.parse(localStorage.getItem("account") || "{}");
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.data = {
      ...this.data,
      pageSize: this.paginator.pageSize || 10,
      page: 1
    };

    this.paginator.page
    .pipe(startWith(null))
    .pipe(tap(()=>{
      setTimeout(()=>{
        if(this.searching){
          this.itens = [];
          this.dataSource = [];
        }
      }, 1200)
    }))
    .pipe(switchMap(()=>{
      this.searching = true;
      const options = {
        ...this.data,
        pageSize: this.paginator?.pageSize || 10,
        page: this.paginator?.pageIndex || 0
      };

      return this.hubService
      .stageInfo(options)
      .pipe(
        map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
    }))
    .subscribe({
      next:(itens) => {
        this.searching = false;
        if (itens instanceof Array) {
          this.dataSource = itens;
        }
      },
      error:()=>{
        this.searching = false;
      }
    })
  }
}
