import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { LookerService } from '../shared/looker.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-looker',
  templateUrl: './looker.component.html',
  styleUrls: ['./looker.component.scss']
})
export class LookerComponent implements OnInit {
  dashboards: any;
  selectedDashboardId: any;
  constructor(private lookerService: LookerService, private sanitizer: DomSanitizer) { }

  account: any;
  urlLooker: SafeResourceUrl | undefined;
  objLooker: any;
  superUser: boolean;

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account')!);
    this.dashboards = this.account?.system_client?.resource?.environment?.looker_dashboard;
    if (this.dashboards && this.dashboards.length > 0) {
      this.selectedDashboardId = this.dashboards[0];
      this.getLookerEmbed({ value: this.selectedDashboardId });
    }
  }

  getLookerEmbed(event?: MatSelectChange | any) {
    this.lookerService.embedLooker(event?.value).subscribe((r: any) => {
      this.urlLooker = this.sanitizer.bypassSecurityTrustResourceUrl(r.url);
    });
  }
}
