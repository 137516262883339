import { UserService } from './../../../user/shared/user.service';
import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from 'src/app/shared/support-data.service';


@Component({
  selector: 'app-schedule-antecedence-dialog',
  templateUrl: './schedule-antecedence-dialog.component.html',
  styleUrls: ['./schedule-antecedence-dialog.component.scss']
})
export class ScheduleAntecedenceDialogComponent implements OnInit {
  form = this.formBuilder.group({
    department_id: [this.data.department_id, Validators.required],
    operation_id: [this.data.operation_id, Validators.required],
    suboperation_id: [this.data.suboperation_id],
    antecedence: [this.data.antecedence ? this.data.antecedence : "0", Validators.required],
    workday: [this.data.workday ? this.data.workday : true, Validators.required],
    groups:[this.data.groups ? JSON.parse(this.data.groups) : [], Validators.required]
  });
  departments: any[] = [];
  operations: any[] = [];
  suboperations: any[] = [];
  suboperationsFiltered: any[] = [];
  operationsFiltered: any[] = [];
  groups: any[] = [];
  showSuboperation = false;
  nonEditableField = false;
  account: any;

  constructor(
    public dialogRef: MatDialogRef<ScheduleAntecedenceDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.getDepartments();
    this.getOperations();
    this.getSubOperations();
    this.getGroups();
  }

  close(){
    this.dialogRef.close();
  }

  getOperations(){
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operations = r.data.resource;
      this.operationsFiltered = r.data.resource;
      //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true
      this.operations = this.operations.filter(item => item.use_in_scheduling === true);
      this.operationsFiltered = this.operations.filter(item => item.use_in_scheduling === true);

      if(this.data.operation_id){
        this.nonEditableField = true;
        this.departmentChange();
      }
    });
  }

  departmentChange(){
    this.setOperationsFilter(this.form.get('department_id')!.value)
  }

  setOperationsFilter(department: number){
    this.operationsFiltered = [];
    this.form.patchValue({operation_id: ""})
    const ft = this.operations.filter((item: any) => item.department_id === department)
    this.operationsFiltered = ft;
    //Se não for a edição de uma antecedência, ativa o campo de Operações pois foi informado um Departamento
    if(!this.data.id) {
      this.form.controls.operation_id.enable();
     }

    this.form.patchValue({operation_id: this.data.operation_id});
  }

  getSubOperations(){
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.suboperations = r.data.resource;
      if(this.data.suboperation_id){
        this.nonEditableField = true;
        this.operationChange();
      }
    });
  }

  setSuboperationsFilter(operation: number){
    this.suboperationsFiltered = [];
    this.form.patchValue({suboperation_id: ""})
    const ft = this.suboperations.filter((item: any) => item.operation_id === operation)
    this.suboperationsFiltered = ft;

    if(this.suboperationsFiltered.length > 0){
      this.showSuboperation = true;
      this.form.get('suboperation_id')!.setValidators([Validators.required]);
      if(this.data.suboperation_id){
        this.form.get('suboperation_id')!.setValue(this.data.suboperation_id);
      }
      this.form.get('suboperation_id')!.updateValueAndValidity();
    } else {
      this.showSuboperation = false;
      this.form.get('suboperation_id')!.setValue(null);
      this.form.get('suboperation_id')!.clearValidators();
      this.form.get('suboperation_id')!.updateValueAndValidity();
    }
  }

  getDepartments(){
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departments = r.data.resource
      this.departments = this.departments.filter((item: any) => {
        return this.account.department_id.indexOf(item.id) > -1;
      })
      if(this.departments.length === 1) {
        this.form.patchValue({department_id: this.departments[0].id})
      } else if(!this.data.id) {
        //Se não possuir data.id, é um nova antecedência para um cliente com mais de um departamento
        //Então desativa o campo de Operações até que seja informado um Departamento
        this.form.controls.operation_id.disable();
      }
    });
  }

  getGroups(){
    const options = { pageSize: 200 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
    });
  }

  operationChange(){
    if(this.suboperations){
      this.setSuboperationsFilter(this.form.get('operation_id')!.value)
    }
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      department_id: this.form.value.department_id,
      resource: {
        operation_id: this.form.value.operation_id,
        suboperation_id: this.form.value.suboperation_id,
        antecedence: this.form.value.antecedence,
        workday: this.form.value.workday,
        groups: this.form.value.groups
      }
    }

    if(this.data.id) {
      this.agendamentoService.updateSchedulingAntecedence(options).subscribe((r: any) => {
        this.close();
      });
    } else {
      this.agendamentoService.createSchedulingAntecedence(options).subscribe((r: any) => {
        this.close();
      });
    }
  }
}
