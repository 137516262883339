import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'formly-input-seach-cnpj',
  templateUrl: './formly-input-search-cnpj.component.html',
  styleUrls: ['./formly-input-search-cnpj.component.scss']
})

export class FormlyInputSearchCnpjComponent extends FieldType<any> implements OnInit{

  formControlFilter = new FormControl();
  listOptions:any[] = [];
  searching = false;

  ngOnInit(): void {
    if(this.props.service){
      const {
        startWith:searchValue
      } = this.props.service;

      this.formControlFilter.valueChanges.pipe(
        startWith(searchValue),
        debounceTime(1000),
        tap(()=>this.searching = true)
      ).subscribe((value:string)=>{
        this.props.service.service(value)
        .pipe(tap(()=>this.searching = false))
        .subscribe((options:any[])=>{
          this.listOptions = [this.formControl.value, ...options].filter(i=>i);
          this.formControl.setValue(this.formControl.value, {emitEvent:false});
        })
      })
    }

    if(typeof(this.field.defaultValue) == "object" ) {
      this.formControl.setValue(this.field.defaultValue);
    } else if (this.field.defaultValue) {
      const carrier = this.field.props.options?.find((item: any) => item.id == this.field.defaultValue);
      if (carrier) {
        this.formControl.setValue(carrier);
      }
    }
  }
}
