import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { MatTabsModule } from "@angular/material/tabs";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { FormlyMaterialModule } from "@ngx-formly/material";

import { DashboardLorealModule } from "../dashboard-loreal/dashboard.module";
import { DashboardModule } from "../dashboard/dashboard.module";
import { DashboardDynamicModule } from "../dashboard-dynamic/dashboard-dynamic.module";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";

import { CoreModule } from "src/app/core/core.module";
import { CardModule } from "./../shared/cards/card.module";
import { DashboardV1RoutingRoutes } from "./dashboard-v1-routing.module";
import { DashboardListComponent } from "./dashboard-list/dashboard-list.component";

import { FormlyInputSearchComponent } from "../shared/formly/formly-input-search/formly-input-search.component";
import { FormlyAutocompleteComponent } from "../shared/formly/formly-autocomplete/formly-autocomplete.component";
import { FormlyDatepickerRangeComponent } from "../shared/formly/formly-datepicker-range/formly-datepicker-range.component";

@NgModule({
  declarations: [DashboardListComponent],
  imports: [
    CoreModule,
    CommonModule,
    CardModule,
    MatButtonModule,
    DashboardV1RoutingRoutes,
    MatTabsModule,
    ReactiveFormsModule,
    DashboardLorealModule,
    MatDialogModule,
    FormlyModule.forRoot({
      types: [
        {
          name: "formly-autocomplete",
          component: FormlyAutocompleteComponent,
        },
        {
          name: "formly-datepicker-range",
          component: FormlyDatepickerRangeComponent,
        },
        {
          name: "formly-input-search",
          component: FormlyInputSearchComponent,
        },
      ],
    }),
    FormlyMaterialModule,
  ],
  exports: [DashboardModule, DashboardDynamicModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class DashboardV1Module {}
