import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar} from '@angular/material/snack-bar';
import { FormlyFormOptions, FormlyFieldConfig,  } from '@ngx-formly/core';
import { DepartmentService } from 'src/app/shared/department.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DocumentsService } from '../../documents/shared/documents.service';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AgendamentoService } from 'src/app/multiagendamento/shared/agendamento.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import { EventsComponent } from 'src/app/multiagendamento/events/events.component';
import { DriversService } from './../../drivers/shared/drivers.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';

@Component({
  selector: 'app-sheduling-dialog',
  templateUrl: './sheduling-dialog.component.html',
  styleUrls: ['./sheduling-dialog.component.scss']
})
export class ShedulingDialogComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload!: ElementRef;
  operations: any = [];
  suboperations: any = [];
  vehicle_types: any = [];
  files: any[] = [];
  isCarrier!: boolean;
  documents: any[] = [];
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields!: FormlyFieldConfig[];
  driver: any;
  loading = false;
  account: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public supportDataService: SupportDataService,
    public departmentService: DepartmentService,
    public agendamentoService: AgendamentoService,
    public documentsService: DocumentsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EventsComponent>,
    public driverService: DriversService,
    private snackBar: MatSnackBar,
    public CarrierService: CarrierService,
  ) {}

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.isCarrier = this.account.is_carrier;
    this.getVehicleType();

    if (this.data.event && this.data.event.files) {
      this.documents = this.data.event.files;
    }

    this.getDepartaments();
    this.getCarriers();

    this.fields = [
      {
        key: 'department_id',
        type: 'select',
        props: {
          label: 'Departamento',
          placeholder: 'Escolha um departamento',
          required: true,
          options: [],
          disabled: this.data.event? true : false
        },
        parsers: [
          value => {
            if(value){
              this.getOperation(value);
            }
            return value;
          }
        ]
      },
        {
          key: 'operation_type',
          type: 'select',
          props: {
            label: 'Operação',
            placeholder: 'Escolha o tipo de operação',
            required: true,
            options: [],
            valueProp: 'id',
            disabled: this.data.event? true : false
          },
          parsers: [
            value => {
              if(value){
                this.getSubOperation(value);
              }
              return value;
            }
          ]
        },
        {
          key: 'suboperation_type',
          type: 'select',
          props: {
            label: 'Suboperação',
            placeholder: 'Escolha uma suboperação',
            required: true,
            options: [],
            valueProp: 'id',
            labelProp: 'name',
            disabled: this.data.event? true : false
          }
        },
        {
          key: 'vehicle_type',
          type: 'select',
          props: {
            label: 'Tipo de Veículo',
            placeholder: 'Escolha o tipo de veículo',
            required: true,
            options: [],
            valueProp: 'id',
            disabled: this.data.event? true : false
          },
        },
        {
          key: 'carrier_id',
          type: 'select',
          props: {
            label: 'Transportadora',
            placeholder: 'Escolha uma transportadora',
            required: true,
            options: [],
           // disabled: this.data.event? true : false,
            change: ()=>{
              this.form.patchValue({
                driver_name: "",
                driver_phone: "",
                cpf: "",
              });
          },
          },
          hideExpression: ((this.isCarrier ? true : false) && this.account.carriers.length === 1)
        },
        {
          key: 'time',
          type: 'select',
          defaultValue: (this.data.time)? this.data.time : null,
          props: {
            label: 'Horário',
            placeholder: 'Escolha um horário',
            required: true,
            options: [
              { value: '08:00', label: '08:00' },
              { value: '09:00', label: '09:00' },
              { value: '10:00', label: '10:00' },
              { value: '11:00', label: '11:00' },
              { value: '12:00', label: '12:00' },
              { value: '13:00', label: '13:00' },
              { value: '14:00', label: '14:00' },
              { value: '15:00', label: '15:00' },
              { value: '16:00', label: '16:00' },
              { value: '17:00', label: '17:00' },
              { value: '18:00', label: '18:00' },
              { value: '19:00', label: '19:00' },
              { value: '20:00', label: '20:00' },
              { value: '21:00', label: '21:00' },
              { value: '22:00', label: '22:00' },
              { value: '23:00', label: '23:00' },
            ],
            //linha abaixo comentada para habilitar a edição do horario do agendamento, caso volte a não permitir a edição, apenas descomentar.

           // disabled: this.data.event? true : false
          },
        },
        {
          key: 'document',
          type: 'input',
          props: {
            type:"number",
            pattern:"^\\d*$",
            label: 'Nº da OT',
            placeholder: 'OT',
            required: true,
            disabled: this.data.event? true : false
          },
          hideExpression: 'model.operation_type != 2 && model.operation_type != 4',
        },
        {
          key: 'nro_nota',
          type: 'formly-input-custom',
          props: {
            appearance:'fill',
            type:"number",
            pattern:"^\\d*$",
            label: 'Número da Nota',
            placeholder: 'Número da Nota',
            required: true,
            disabled: this.data.event? true : false
          },
          hideExpression: 'model.operation_type != 1',
        },
        {
          key: 'cpf',
          type: 'formly-input-custom',
          props: {
            appearance:'fill',
            mask:'000.000.000-00',
            label: 'CPF do motorista',
            placeholder: 'CPF do motorista',
            required: true
          },
          parsers: [
            value => {
              if(value){
                this.getDriverByCpf(value);
              }
              return value;
            }
          ]
        },
        {
          key: 'driver_name',
          type: 'input',
          props: {
            label: 'Nome do motorista',
            placeholder: 'Nome do motorista',
            required: true,
            disabled: true
          },
        },
        {
          key: 'driver_phone',
          type: 'formly-input-custom',
          props: {
            mask:'(00) 00000-0000',
            appearance:'fill',
            label: 'Telefone do motorista',
            placeholder: 'telefone do motorista',
            required: true,
            disabled: false
          },
          hideExpression: 'model.operation_type != 1',
        },
        {
          key: 'board_horse',
          type: 'input',
          props: {
            label: 'Placa',
            placeholder: 'Placa',
            required: true,
            maxLength: 7,
            pattern:"^[a-zA-Z]{3}\\d\\w\\d\\d$"
          },
          validation:{
            messages:{
              pattern:"Formato ex.: AAA1A34 ou AAA1234"
            }
          },
          expressionProperties: {
            'props.required': (model: any) => {
              // [1,2,4,5,6,7,9,10,11]
              if (model && model.vehicle_type) {
                return [1,2,4,5,6,7,9,10,11].indexOf(model.vehicle_type) === -1;
              }
              return false;
            },
          },
          hideExpression:(model: any) => {
              if(this.data.event && !this.data.event.vehicle_type && this.data.event.board_horse && !this.data.event.board_cart){
                return false;
              }
              if (model && model.vehicle_type) {
                return [1,2,4,5,6,7,9,10,11].indexOf(model.vehicle_type) > -1;
              }
              return true;
            }
        },
        {
          key: 'board_horse',
          type: 'input',
          props: {
            label: 'Placa de cavalo',
            placeholder: 'Placa de cavalo',
            maxLength: 7,
            pattern:"^[a-zA-Z]{3}\\d\\w\\d\\d$"
          },
          validation:{
            messages:{
              pattern:"Formato ex.: AAA1A34 ou AAA1234"
            }
          },
          expressionProperties: {
            'props.required': (model: any) => {
              if (model && model.vehicle_type) {
                return [1,2,4,5,6,7,9,10,11].indexOf(model.vehicle_type) > -1;
              }
              return false;
            },
          },
          hideExpression:(model: any) => {
            if(this.data.event && !this.data.event.vehicle_type && this.data.event.board_horse){
              return false;
            }
            if (model && model.vehicle_type) {
              return [1,2,4,5,6,7,9,10,11].indexOf(model.vehicle_type) === -1;
            }
            return true;
          },
        },
        {
          key: 'board_cart',
          type: 'input',
          props: {
            label: 'Placa de carreta',
            placeholder: 'Placa de carreta',
            maxLength: 7,
            pattern:"^[a-zA-Z]{3}\\d\\w\\d\\d$"
          },
          validation:{
            messages:{
              pattern:"Formato ex.: AAA1A34 ou AAA1234"
            }
          },
          expressionProperties: {
            'props.required': ()=>(model: any, formState: any, field: FormlyFieldConfig) => {
              if (model && model.vehicle_type) {
                return [1,2,4,5,6,7,9,10,11].indexOf(model.vehicle_type) > -1;
              }
              return false;
            },
          },
          hideExpression: (model: any) => {
            if(this.data.event && !this.data.event.vehicle_type && this.data.event.board_cart){
              return false;
            }
            if (model && model.vehicle_type) {
              return [1,2,4,5,6,7,9,10,11].indexOf(model.vehicle_type) === -1;
            }
            return true;
          },
        },
        {
          key: 'type_supplies',
          type: 'input',
          props: {
            label: 'Tipo de insumo',
            placeholder: 'Tipo de insumo',
            required: true,
            disabled: this.data.event? true : false
          },
          hideExpression: 'model.operation_type != 1',
        },
        {
          key: 'qtd_produtos',
          type: 'formly-input-custom',
          props: {
            appearance: 'fill',
            type:"number",
            pattern:"^\\d*$",
            label: 'Quantidade dos produtos',
            placeholder: 'Quantidade dos produtos',
            required: true,
            min: 1,
            disabled: this.data.event? true : false
          },
          hideExpression: 'model.operation_type != 1',
        },
    ];

    if(this.data.event){
        let idx = 0;
        for (const [key, item] of Object.entries(this.data.event)) {
          idx = this.fields.map((o) => o.key).indexOf(`${key}`);
          if(idx != -1){
            this.fields[idx].defaultValue = `${item}`;
          }
        }

        idx = this.fields.map((o) => o.key).indexOf('carrier_id');
        this.fields[idx].defaultValue = this.data.event.carrier_id;

        idx = this.fields.map((o) => o.key).indexOf('vehicle_type');
        this.fields[idx].defaultValue = parseInt(this.data.event.vehicle_type);

        idx = this.fields.map((o) => o.key).indexOf('department_id');
        this.fields[idx].defaultValue = parseInt(this.data.event.department_id);

        this.getOperation(this.fields[idx].defaultValue);

        idx = this.fields.map((o) => o.key).indexOf('operation_type');
        this.fields[idx].defaultValue = parseInt(this.data.event.operation_id);

        this.getSubOperation(this.fields[idx].defaultValue);

        idx = this.fields.map((o) => o.key).indexOf('suboperation_type');
        this.fields[idx].defaultValue = (this.data.event.suboperation_id ?? this.data.event.suboperation);

    }
  }


  getVehicleType(){
    let option:any = {name: 'vehicle_type'}
    this.supportDataService.generic(option).subscribe((r:any)=>{
      let idx = this.fields.map((o) => o.key).indexOf('vehicle_type')
      this.fields[idx].props!.options = r.data.resource
    })
  }

  getDepartaments(){
    this.departmentService.index().subscribe((r:any) => {
       let idx = this.fields.map((o) => o.key).indexOf('department_id')
       let dados: any[] = [];

       let departments = r.data.resource;

       departments = departments.filter((item: any) => {
         return this.account.department_id.indexOf(item.id) > -1;
       })

      departments.forEach((element: any) => {
        dados.push({
            'value': element.id,
            'label': element.name
        })
      });

      this.fields[idx].props!.options = dados;
      if((dados || []).length <= 1){
        this.fields[idx].className = 'display-none';
       !this.data.event && dados[0] && this.form.patchValue({department_id:dados[0].value})
      }

    })
  }

  getOperation(department_id: number){
    let option:any = {name: 'operation_type'}
    this.supportDataService.generic(option).subscribe((r:any)=>{
        this.operations = r.data.resource;
        this.setOperation(department_id);
      })
  }

  setOperation(department_id: number){
    let idx = this.fields.map((o) => o.key).indexOf('operation_type');
    this.fields[idx].props!.options = this.operations.filter((item: any) => {
        if(item.department_id == department_id){
          this.fields[idx].defaultValue =  parseInt(item.id);
          this.model.operation_type = parseInt(item.id);
          return item;
        }
    });
  }

  getSubOperation(operation_id: any) {
    let option:any = {name: 'suboperation_type'}
    this.supportDataService.generic(option).subscribe((r:any)=>{
        this.suboperations = r.data.resource;
        this.setSubOperation(operation_id);
      })
  }

  setSubOperation(operation_id: number){
    let idx = this.fields.map((o) => o.key).indexOf('suboperation_type');
    this.fields[idx].props!.options = this.suboperations.filter((item: any) => {
        if(item.operation_id == operation_id && item.department_id == this.model.department_id){
          this.fields[idx].defaultValue =  isNaN(item.id) ? item.id : parseInt(item.id);
          this.model.suboperation_type = this.fields[idx].defaultValue;
          return item;
        }
    });
  }

  getCarriers() {
    this.CarrierService.indexAllClient().subscribe((r: any) => {
      let idx = this.fields.map((o) => o.key).indexOf('carrier_id');
      let dados: any[] = [];

      r.forEach((element: any) => {
        dados.push({
            'value': element.id,
            'label': element.name
        })
      });

      this.fields[idx].props!.options = dados;
    })
  }

  // TO DO
  //colocar mascara nos campos
  storeSchedule(action?: string) {
    this.loading = true;
    if (this.form.valid && ((this.files.length > 0 && action != 'edit') || (this.files.length == 0 && (this.model.operation_type == 1 || this.model.operation_type == 3)&& action != 'edit') || (this.files.length == 0 && action == 'edit'))) {

      let dados = {
        ...this.model
      }

      if(this.isCarrier && this.account.carrier_id && this.account.carriers.length === 1) {
        dados.carrier_id = this.account.carrier_id;
      }

      dados.files = this.documents;
      dados.status = 'confirmed';
      dados.time = this.model.time;

      //Pega o dia e hora selecionados pelo usuário e converte em data com o formato especificado dentro de format()
      //São adicionadas 3 horas pois esse agendamento utilizado por Braskem Duque de Caxias (system_client_id: 5)
      //Trabalha com a remoção de 3 horas para a listagem e validação de checkins pelo totem
      dados.start_at = moment(`${this.data.definedDay} ${this.model.time}`).add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss+00:00') || '';

      if(action == 'edit'){
        dados.event_id = this.data.event.event_id;
        dados.edit = true;
        this.agendamentoService.updateScheduling(dados).subscribe((r:any)=>{

          if(r.status == 200) {
            this.snackBar.open(r.message, "OK", {
              duration: 5000,
            });
            this.dialogRef.close(this.data);
          }

          if(r.error){
            this.loading = false;
            this.dialog.open(DialogAlertComponent, {
              data: {title: 'Atenção!', message: r.error.message}
            });
          }
        });
      }else{
        this.agendamentoService.createScheduling(dados).subscribe((r:any)=>{

          if(r.status == 200) {
            this.snackBar.open(r.message, "OK", {
              duration: 5000,
            });
            this.dialogRef.close(this.data);
          }

          if( r.error){
            this.loading = false;
            this.dialog.open(DialogAlertComponent, {
              data: {title: 'Atenção!', message: r.error.message}
            });
          }
        });
      }
    }else{
      this.loading = false;
      this.dialog.open(DialogAlertComponent, {
        data: {title: 'Atenção!', message: 'Por favor, preencha todos os campos obrigatórios'}
      });
    }
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.documentsService.upload(formData).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total!);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((ev: any) => {
        if (typeof (ev) === 'object') {
          this.documents.push(ev.body);
        }
      });
  }

  deleteFile(item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.documentsService.delete(item).subscribe({
          next: (r: any) => {
            if (r.success) {
              const idx = this.documents.findIndex((v: any) => v.fileName === item.fileName);
              this.documents.splice(idx, idx >= 0 ? 1 : 0);
              this.files.splice(idx, idx >= 0 ? 1 : 0);
            }
          }, error: (e: any) => {

          }
        });
      }
    });
  }

  getFile(item: any): void {
    this.documentsService.downloadFile(item)
      .subscribe(
        (val) => {
          this.createFileFromBlob(val, item.fileName);
        },
        response => {

        },
        () => {

        });
  }

  createFileFromBlob(file: Blob, fileName: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        const fileData: Object = { data: file, inProgress: false, progress: 0 };
        this.files.push(fileData);
        this.uploadFile(fileData);
      }
    };
    fileUpload.click();
  }

  getDriverByCpf(cpf?: any){

    if(cpf.length > 10){
      const options = {
        cpf: cpf,
        is_carrier: this.isCarrier,
        carrier_id: null

      }

      if(this.isCarrier && this.account.carriers.length === 1) {
        options.carrier_id = this.account.carrier_id;
      } else {
        options.carrier_id = this.form.value.carrier_id ? this.form.value.carrier_id : null;
      }

      if(!this.data.event || this.data.event.cpf != cpf){
        this.driverService.showViaCpf(options).subscribe((r:any) => {
          if(r.id){
            this.form.patchValue({
              driver_name: r.name,
              driver_phone: r.resource.phone
            });

            let idx = this.fields.map((o) => o.key).indexOf('driver_name');
            this.fields[idx].props!.disabled = true;

            idx = this.fields.map((o) => o.key).indexOf('driver_phone');
            this.fields[idx].props!.disabled = true;
          }else{
            this.form.patchValue({
              driver_name: "",
              driver_phone: "",
              cpf: "",
            });

            let idx = this.fields.map((o) => o.key).indexOf('driver_name');
            this.fields[idx].props!.disabled = false;

            idx = this.fields.map((o) => o.key).indexOf('driver_phone');
            this.fields[idx].props!.disabled = false;
          }
        });
      }
    }

    if(cpf.length < 2){
      this.form.patchValue({
        driver_name: "",
        driver_phone: ""
      });
    }
  }

}
