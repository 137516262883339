import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../account/shared/account.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { TranslateService } from '@ngx-translate/core';
import { Account } from "../../account/account";
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { environment } from 'src/environments/environment';

type UserClients = Partial<{
  user_name: string;
  clients:{
    system_client_id: number;
    icon:string|SafeResourceUrl;
    name:string;
    location: string;
    login_state?: string;
    logged_at?: string
  }[]
}>

@Component({
  selector: 'app-login-detail',
  templateUrl: './login-detail.component.html',
  styleUrls: ['./login-detail.component.scss']
})
export class LoginDetailComponent implements OnInit {
  @ViewChild('stepper',{static:true}) stepper: MatStepper;

  account!: Account;
  showFilter = false;
  translateSource: any;
  form!: UntypedFormGroup;
  searchControl: FormControl;
  userClients:UserClients={clients:[], user_name:""};

  secondFormGroup = this.formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isEditable = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    translate.get('account.login-detail').subscribe((res: string) => {
      this.translateSource = res;
    });
  }

  onSubmit() {

    const email = this.form.get("email")!.value;

    this.accountService.getClients<UserClients>(email)
    .subscribe({
        next: (userClients)=>{
          if(userClients.clients?.length == 1){
            this.accountService.login({
              realm: userClients.clients[0].system_client_id,
              clientId: environment.keycloak.clientId,
              loginHint:email
            });
          }else{
            userClients.clients?.forEach(client => {
              const imageIcon = (
                /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(client.icon as string)
                ? "data:image/png;base64,"
                : ""
              ).concat(client.icon as string);

              const access = this.accountService.realmAccess.accessedRealms
              .find((access)=> access.realm == "" + client.system_client_id && access.user == email);

              client.icon = this.sanitizer.bypassSecurityTrustUrl(imageIcon);
              client.login_state = access?.state;
              client.logged_at = access?.logged_at;

            });
            this.userClients = {...userClients};
            this.showFilter = this.userClients.clients!.length > 10;

            this.searchControl = new FormControl('');
            this.searchControl.valueChanges
              .pipe(debounceTime(500), distinctUntilChanged())
              .subscribe(query => {
                if(!query)
                  this.userClients = {...userClients};
                else
                  this.userClients.clients = [...userClients.clients!].filter((client)=>{
                    return `${client.name} ${client.location}`.match(new RegExp(query.split(" ").join(".*"),"gi"));
                  });
              });

            this.stepper.selectedIndex == 0 && this.stepper.next();
          }
      },
      error: (err: any) => {
        const message = (err?.error?.message || !err) ? "Não foi possível prosseguir com a autenticação" : err.error;

        this.dialog.open(DialogAlertComponent, {
          data: {
            title: "Acesso inviabilizado",
            message
          }
        });
      }
    });
  }

  ngOnInit() {
    const user = window.location.hash.substring(1);
    this.form = this.formBuilder.group({
      email: [user, [Validators.required, Validators.email]]
    });

    if(user?.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/gi)){
      this.stepper.selectedIndex = 1;
      this.onSubmit();
    }
  }

  keycloakLogin(client:NonNullable<UserClients["clients"]>[0]){
    this.accountService.login({
      realm:String(client.system_client_id),
      clientId:environment.keycloak.clientId,
      loginHint:this.form.get("email")!.value
    })
  }
}
