import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DocumentsService } from 'src/app/documents/shared/documents.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-mass-scheduling-result',
  templateUrl: './mass-scheduling-result.component.html',
  styleUrls: ['./mass-scheduling-result.component.scss']
})
export class MassSchedulingResultComponent implements OnInit{
  messages:string[]=[];
  showButtonDownload = false;
  waitDownload = false;

  constructor(
    private dialog: MatDialog,
    private translateService:TranslateService,
    private documentsService:DocumentsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.messages = this.data.data.messages;
    this.showButtonDownload = this.data.data.invalid > 0;
  }

  downloadValidatedFile(){
    this.waitDownload = true;
    const fileInfo = this.data.fileInfo;
    this.documentsService
    .downloadFileBucket({pathname:new URL(fileInfo.url).pathname, bucket_name:'mass-scheduling'})
    .subscribe({
      next:(fileBlob:any) => {
        this.documentsService.createFileFromBlob(fileBlob, fileInfo.fileName.replace(/\d+-/gi, ''));
      },
      error:(error:any)=>{
        if(error.error?.message){
          this.dialog.open(DialogAlertComponent,{
            data:{
              title:this.translateService.instant(
                'Messages.error_occurred',
                {Default:"Ocorreu um erro."}
              ),
              dialogType: "ok_only",
              message: error.error?.message || this.translateService.instant(
                'Messages.impossible_file_download',
                {Default:"Não foi possível fazer o download do arquivo."}
              )
            }
          })
        }
      }
    })
    .add(()=>this.waitDownload = false);
  }

}
