import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthCheckComponent } from './health-check/health-check.component';
import { HealthCheckRoutingModule } from './health-check-routing.module';
import { MatIconModule } from '@angular/material/icon';
import * as echarts from 'echarts';
import { NgxEchartsModule } from "ngx-echarts";
import { MatDividerModule } from '@angular/material/divider';
import { HealthCheckInfoComponent } from './health-check-info/health-check-info.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { HealthCheckListComponent } from './health-check-list/health-check-list.component';
import { HealthCheckNotificationComponent } from './health-check-notification/health-check-notification.component';
import { HealthCheckToastrComponent } from './health-check-toastr/health-check-toastr.component';



@NgModule({
  declarations: [
    HealthCheckComponent,
    HealthCheckInfoComponent,
    HealthCheckListComponent,
    HealthCheckNotificationComponent,
    HealthCheckToastrComponent
  ],
  imports: [
    CommonModule,
    HealthCheckRoutingModule,
    MatIconModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule
  ],
  exports: [
    HealthCheckNotificationComponent
  ]
})
export class HealthCheckModule { }
