<h2 mat-dialog-title>{{ data.title }}</h2>
<p>Selecione abaixo a opção desejada</p>
  <mat-dialog-content class="mat-typography">
    <mat-radio-group
      [formControl]="unlinkSelected"
      aria-label="Escolha uma opção"
    >
      <mat-radio-button value="2">Apenas Motorista</mat-radio-button>
      <mat-radio-button [disabled]="isDisabled" value="1"
        >Motorista com Cavalo</mat-radio-button
      >
    </mat-radio-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="warn" mat-dialog-close>Fechar</button>
    <button
    style="color: white;"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      [mat-dialog-close]="true"
    >
      Salvar
    </button>
  </mat-dialog-actions>

