<h1 mat-dialog-title>{{ 'Scheduling.file_processing_result' | translate:{Default:'Resultado do processamento do arquivo'} }}</h1>
<div mat-dialog-content>
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let message of messages">
      {{message}}
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions align="end">
  <div class="btn-download">
    <button
      mat-stroked-button
      color="primary"
      [matTooltip]="'Scheduling.download_file_with_validation' | translate:{Default:'Faça o download do arquivo com as mensagens de validações'}"
      matTooltipClass="custom-tooltip"
      matTooltipShowDelay="500"
      (click)="downloadValidatedFile()"
      [disabled]="waitDownload"
      *ngIf="showButtonDownload"
    >
      {{ 'Business_model.download_file' | translate:{Default:'Baixar arquivo'} }}
      <mat-icon>download</mat-icon>
    </button>
    <mat-progress-bar mode="indeterminate" *ngIf="waitDownload" class="progress"></mat-progress-bar>
  </div>
  <button mat-raised-button mat-dialog-close>{{ 'Business_model.close' | translate:{Default:'Fechar'} }}</button>
</div>
