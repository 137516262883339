<h1 class="mat-align-center" mat-dialog-title>
  Vincular {{ vehicle_type_name }} {{ data.item.resource.board }}
</h1>
<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Manobrista ativo</mat-label>
    <mat-select formControlName="checkin_id" (selectionChange)="selectDriver()">
      <mat-option *ngFor="let checkin of checkins" [value]="checkin">
        {{ checkin.driver.name }} - {{ checkin.carrier.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container>
    <mat-form-field>
      <mat-label>Placa Cavalo</mat-label>
      <input
        matInput
        formControlName="board_horse"
        mask="AAAAAAA"
        onkeyup="this.value = this.value.toUpperCase();"
        [readonly]="data.item.resource.vehicle_type == 'Cavalo'"
      />
      <button mat-icon-button matSuffix (click)="cartSearchDialog('Cavalo')" [disabled]="data.item.resource.vehicle_type == 'Cavalo'">
        <mat-icon class="btn-search">pageview</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Placa Carreta</mat-label>
      <input
        matInput
        formControlName="board_cart"
        mask="AAAAAAA"
        onkeyup="this.value = this.value.toUpperCase();"
        [readonly]="data.item.resource.vehicle_type == 'Carreta'"
      />
      <button mat-icon-button matSuffix (click)="cartSearchDialog('Carreta')" [disabled]="data.item.resource.vehicle_type == 'Carreta'">
        <mat-icon class="btn-search">pageview</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>
</form>

<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" (click)="close()">
    Cancelar
  </button>
  <button
    type="submit"
    mat-button
    cdkFocusInitial
    color="primary"
    [disabled]="!form.valid"
    (click)="onSubmit()"
  >
    Salvar
  </button>
</div>
