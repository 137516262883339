import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookerComponent } from './looker-list/looker.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { LookerRoutingModule } from './looker-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [
    LookerComponent
  ],
  imports: [
    MatSelectModule,
    MatFormFieldModule,
    LookerRoutingModule,
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule
  ],
  exports: [
    LookerComponent
  ]
})
export class LookerModule { }
