import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';
import { PlaceListComponent } from './places/place-list/place-list.component';
import { RoutesListComponent } from './routes/routes-list/routes-list.component';
import { GenericViewListComponent } from '../generic-view/generic-view-list/generic-view-list.component';
import { SupportDataListComponent } from './support-data-list/support-data-list.component';
// import { DeviceListComponent } from './devices/device-list/device-list.component';


const registrationsRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/registrations/places',
    component: PlaceListComponent,
    data: {
      title: 'Paradas',
      search: true,
      refresh: false,
    }
  },
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/registrations/routes',
    component: RoutesListComponent,
    data: {
      title: 'Rotas',
      search: false,
      refresh: false,
    }
  },
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/registrations/:page',
    component: GenericViewListComponent,
    data: {
      title: 'Dispositivos',
      search: false,
      refresh: false,
    }
  },
  {
    path: 'admin/support-data/:page',
    component: SupportDataListComponent,
    data: {
      title: 'Cadastros',
      search: false,
      refresh: false,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(registrationsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RegistrationsRoutingModule { }
