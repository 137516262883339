import { OnInit, Component, Input, ViewChild, Type } from '@angular/core';

import CardsBase from './cards-base';
import { CardDirective } from './card.directive';
import { CardInterface } from './card.interface';
import { CardNotFoundComponent } from './card-not-found.component';

@Component({
  selector: 'card-dynamic',
  template: `<ng-template cardDirective></ng-template>`,
  styles:[':host{display:flex; flex: 1 0 0;}']
})

export class CardComponent implements OnInit, CardInterface {
  @ViewChild(CardDirective, {static: true}) cardDirective!: CardDirective;
  @Input() card!: any;
  @Input() layout: number = 1;
  @Input() data: any = {};
  @Input() options: Object = {};

  constructor() { }
  ngOnInit() {
    let Card:Type<any> = CardsBase[this.card as keyof typeof CardsBase];

    if(!Card)
      Card = CardNotFoundComponent;

    const viewContainerRef = this.cardDirective.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<CardInterface>(Card);
    componentRef.instance.layout = this.layout;
    componentRef.instance.options = this.options || {};
    componentRef.instance.data = this.data || {};
  }

}
