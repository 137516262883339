import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { SupportDataService } from '../support-data.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { DockManagementService } from 'src/app/docks/shared/dock-management.service';

export interface GenericList1 {
  id: number,
  name: string
}

export interface GenericList2 {
  id: string,
  name: string
}

@Component({
  selector: 'app-steps-filter-dialog',
  templateUrl: './steps-filter-dialog.component.html',
  styleUrls: ['./steps-filter-dialog.component.scss']
})
export class StepsFilterDialogComponent implements OnInit{
  envData: any;
  filters2ShowList: any;
  // Transportadora
  carrierList: GenericList1[] = [];
  filteredOptionsCarrier: Observable<GenericList1[]>;
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  showCarrierFilter = false;

  // Status
  statusList: GenericList2[] = [];
  statusFilter: UntypedFormControl = new UntypedFormControl();
  filteredOptionsStatus: Observable<GenericList2[]>;
  showStatusFilter = false;

  // Operação
  operationList: GenericList2[] = [];
  operationFilter: UntypedFormControl = new UntypedFormControl();
  // filteredOptionsOperation: Observable<GenericList2[]>;
  showOperationFilter = false;

  // Suboperação
  suboperationList: GenericList2[] = [];
  suboperationFilter: UntypedFormControl = new UntypedFormControl();
  filteredOptionsSuboperation: Observable<GenericList2[]>;
  showSuboperationFilter = false;

  // Docas
  docksList: GenericList1[] = [];
  docksFilter: UntypedFormControl = new UntypedFormControl();
  filteredOptionsDocks: Observable<GenericList1[]>;
  showDocksFilter = false;
  departmentsRef: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private carrierService: CarrierService,
    private dockManagementService: DockManagementService,
    private supportDataService: SupportDataService,
    private dialogRef: MatDialogRef<StepsFilterDialogComponent>,
  ) {
    this.envData = JSON.parse(localStorage.getItem("account")!);
    this.filters2ShowList = this.envData.system_client.resource.environment.pages[this.data.page].data_filters;
  }

  ngOnInit() {
    console.log('filtros existentes');
    console.log(this.data);
    if(this.filters2ShowList.includes('carrier')) {
      this.getCarrier();
    };
    if(this.filters2ShowList.includes('status')) {
      this.getStatus();
    }
    if(this.filters2ShowList.includes('operation')) {
      this.getOperations();
    }
    if(this.filters2ShowList.includes('dock_space')) {
      this.getDockSpaces();
    }
  }

  displayFn(carrier: any): string {
    return carrier && carrier.name ? carrier.name : '';
  }

  private _filterCarrier(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.carrierList.filter(option => option.name.toLowerCase().includes(filterValue));
  }


  applyFilters() {
    const carrier = this.carrierFilter.value;
    const status = this.statusFilter.value;
    const operation = this.operationFilter.value;
    const docas = this.docksFilter.value;
    const fts = {
      operation_id: operation ? operation : null,
      status: status ? status : null,
      carrier_id: carrier ? carrier.id : null,
      dock_space_id: docas ? docas : null,
    };
    console.log('---- FTS ----');
    console.log(fts);
    this.dialogRef.close(fts);
  }

  clearFilters() {
    const fts = {
      operation_id: null,
      status: null,
      carrier_id: null,
      dock_space_id: null,
    };
    this.dialogRef.close(fts);
  }

  getCarrier() {
    this.carrierService.index({pageSize: 1000}).subscribe((r: any) => {
      this.carrierList = r.data;
      this.filteredOptionsCarrier = this.carrierFilter.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filterCarrier(name as string) : this.carrierList.slice();
        }),
      );
      this.showCarrierFilter = true;
      if(this.data.fts.carrier_id) {
        const carrier = this.carrierList.filter((item: any) => item.id == this.data.fts.carrier_id)
        this.carrierFilter.setValue(carrier[0]);
        this.carrierFilter.updateValueAndValidity();
      }
    });
  }

  getStatus() {
    this.supportDataService.generic({name: 'status'}).subscribe((r: any) => {
      const status4Page = this.envData.system_client.resource.environment.pages[this.data.page].with_status;
      this.statusList = r.data.resource.filter((item: any) => status4Page.includes(item.id));
      this.showStatusFilter = true;
      if(this.data.fts.status) {
          this.statusFilter.setValue(this.data.fts.status);
          this.statusFilter.updateValueAndValidity();
      }
    });
  }

  getOperations() {
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operationList = r.data.resource;
      this.showOperationFilter = true;
      if(this.data.fts.operation_id) {
        this.operationFilter.setValue(this.data.fts.operation_id);
        this.operationFilter.updateValueAndValidity();
      }
    });
  }

  getDockSpaces() {
    // Pega os registros do novo módulo de gestão de docas
    this.dockManagementService.index().subscribe((r: any) => {
      this.docksList = r.data;
      this.showDocksFilter = true;
    });
  }
}
