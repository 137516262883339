import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
})
export class CommentsComponent {
  form = new FormGroup({
    observacoes: new FormControl(""),
  });
  constructor(
    public dialogRef: MatDialogRef<CommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log("data");
    console.log(this.data);
    if (this.data.item.hasOwnProperty("observacoes")) {
      if (this.data.item?.observacoes) {
        this.form.patchValue({ observacoes: this.data.item?.observacoes });
      }
    }
  }

  save() {
    console.log(this.form.value);
    this.dialogRef.close({ save: true, ...this.form.value });
  }
}
