import { Routes, RouterModule } from '@angular/router';
import { AccountGuardService } from './account/shared/account-guard.service';
import { RouteGuard } from './account/shared/route.guard';
import { HealthCheckComponent } from './health-check/health-check/health-check.component';

const routes: Routes = [

];

export const AppRoutes = RouterModule.forChild(routes);
