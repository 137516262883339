import { Component } from '@angular/core';
import { CardInterface } from '../../card.interface';
import { EChartsOption } from 'echarts';
import { DashboardDynamicService } from 'src/app/dashboard-dynamic/dashboard-dynamic.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-card-echarts',
  templateUrl: './card-echarts.component.html',
  styleUrls: ['./card-echarts.component.scss']
})
export class CardEchartsComponent implements CardInterface {
  data: any;
  options: any;
  layout: number;
  chartOptions: EChartsOption;
  infoHtml: SafeHtml;

  constructor(private sanitizer:DomSanitizer,private dashboardDynamic: DashboardDynamicService){}

  ngOnInit() {
    if(typeof(this.data.info_html) == "string"){
      this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.info_html as string);
    }
    this.dashboardDynamic.getDynamicDashboardItem({ item: this.data.element_name})
    .subscribe((data: {data_chart:EChartsOption|string}) => {
      this.chartOptions = typeof(data.data_chart) == "string" ? JSON.parse(data.data_chart) : data.data_chart;
    });
  }
}
