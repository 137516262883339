<h2 class="mat-align-center" mat-dialog-title>Finalizar jornada
</h2>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-radio-group aria-label="Escolha uma opção" class="finish-options-radio-group"
      formControlName="finish_option_id">
      <mat-radio-button color="primary" class="finish-options-radio-button" *ngFor="let item of options" [value]="item.id"
        [checked]="item.checked">
        {{item.name}}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>


<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">Cancelar</button>
  <button type="submit" mat-raised-button cdkFocusInitial style="color: white;" color="primary" (click)="onSubmit()">Salvar</button>
</div>
