<mat-tab-group>
  <!-- <mat-tab [label]="'Business_model.docks' | translate" *ngIf="showSettingDocas">
    <app-docks-settings></app-docks-settings>
  </mat-tab> -->
  <mat-tab [label]="'Business_model.schedule_options' | translate" *ngIf="showScheduling">
    <app-scheduling></app-scheduling>
  </mat-tab>
  <mat-tab label="Opções de checkin" *ngIf="showChekin">
    <app-checkin></app-checkin>
  </mat-tab>
  <mat-tab label="Opções de cadastros" *ngIf="showRegistrations">
    <app-registrations-settings></app-registrations-settings>
  </mat-tab>
</mat-tab-group>
