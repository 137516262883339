<div class="top-bar-filters">
  <!-- <meta http-equiv="refresh" content="10" /> -->
  <!-- <button mat-raised-button (click)="modalPainelStatus()">
    Ver Painel de Status
  </button> -->

  <div>
    <div>
    <mat-form-field appearance="outline">
      <mat-label>Pontos de Coleta</mat-label>
      <mat-select [(ngModel)]="value" (selectionChange)="loadDriversNearPoints(value)">
        <mat-option [value]="">Limpar filtro</mat-option>
        <mat-divider></mat-divider>
        <mat-option *ngFor="let item of collection_points" [value]="item.resource">
          {{item.resource.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
</div>

  <div class="mat-elevation-z8">
    <table
     mat-table
     [dataSource]="dataSource"
     matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nome
        </th>
        <td mat-cell *matCellDef="let element">{{ element.driver_resource.name }}</td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>
          CPF
        </th>
        <!-- TODO FIX anterior -  {{ element.driver_resource.cpf | mask: "000.000.000-00" }}-->
        <td mat-cell *matCellDef="let element">
          {{ element.driver_resource.cpf | mask: "000.000.000-00" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>
          Telefone
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.driver_resource.phone | mask: "(00) 00000-0000" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef>
          Distancia
        </th>
        <td mat-cell *matCellDef="let element">{{ element.distancia }} Km</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
      (page)="loadDriversNearPoints(value)"
    >
    </mat-paginator>
  </div>
