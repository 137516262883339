import "moment-duration-format";
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MatPaginator } from "@angular/material/paginator";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { of as observableOf, Subject, Subscription } from "rxjs";
import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/scrolling";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { catchError, map, switchMap, debounceTime, tap } from "rxjs/operators";
import { Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy, ElementRef } from "@angular/core";

import { AppComponent } from 'src/app/app.component';
import { environment } from "src/environments/environment.prod";
import { DepartmentService } from "src/app/shared/department.service";
import { SupportDataService } from "../../shared/support-data.service";
import { PainelMotoristaService } from "../shared/painelmotorista.service";
import { WssService } from "src/app/shared/websocket/wss.service";
import {DockManagementService} from "../../docks/shared/dock-management.service";
@Component({
  selector: "app-painelmotorista-list",
  templateUrl: "./painelmotorista-list.component.html",
  styleUrls: ["./painelmotorista-list.component.scss"],
})
export class PainelMotoristaListComponent implements OnInit, OnDestroy {
  @Output() reloadEvent = new EventEmitter();
  @ViewChild('paginatorTop') paginatorTop: MatPaginator;
  @ViewChild('paginatorBottom') paginatorBottom: MatPaginator;
  @ViewChild('paginatorElement', {static: true}) paginatorElement: { nativeElement: any; };
  @ViewChild('filterElement') filterElement:ElementRef<HTMLElement>;

  card:string;
  cardLayout:number;
  gridColumns:string;
  filterSticky:boolean;
  paginatorFixed:boolean;
  trip_hitch:boolean;
  scrollSubscriptation:Subscription;
  driverSubscriptation:Subject<Subscription>;

  filterWidth: string = "25%";
  account: any = JSON.parse(localStorage.getItem("account") || "{}");
  departments: any = [];
  allDataAvailable: boolean = false;

  searchFilter: UntypedFormControl = new UntypedFormControl();
  searchFilterDT: UntypedFormControl = new UntypedFormControl();
  searching = false;
  filterStatusSelected: UntypedFormControl = new UntypedFormControl();
  filterDepartmentSelected:UntypedFormControl = new UntypedFormControl();
  filterOperationSelected:UntypedFormControl = new UntypedFormControl();
  operationsOptions:any = [];
  allOperationsOptions:any = [];
  optionDepartamentSelected:boolean = false;
  isWeb = true;
  private _drivers: any = [];
  wsSubscription: Subscription;
  get drivers(){ return this._drivers;}
  set drivers(value:any){
    this._drivers = value;
    this.dataDriversChange.next(value);
  }
  translateSource: any;
  dockSpaces: any = [];
  listStatus: any = [];

  wsHost: string;
  private socket: WebSocketSubject<any>;
  pageSize: number;
  showListEmpty = false;
  showDepartments: boolean = false;
  filterLabels: any = [];

  dataDriversChange: Subject<any> = new Subject();

  constructor(
    private translate: TranslateService,
    private painelMotoristaService: PainelMotoristaService,
    public supportDataService: SupportDataService,
    private departmentService: DepartmentService,
    private scrollDispatcher: ScrollDispatcher,
    private appComponent : AppComponent,
    public activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private websocketService: WssService,
    private dockManagementService: DockManagementService
  ) {
    translate.get("driver.painelmotorista").subscribe((res: string) => {
      this.translateSource = res;
    });
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    if(this.account.department_id.length > 1){
      this.showDepartments = true;
    }
  }

  ngOnDestroy(): void {
    this.scrollSubscriptation?.unsubscribe();
    this.wsSubscription?.unsubscribe();
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  ngOnInit() {
    this.trip_hitch = false;
    // Feito para o botão aparecer quando adicionar a propriedade 'btnStatusPanel' dentro do system_clients
    this.appComponent.btnStatusPanel =
        this.account.system_client.resource.environment.top_bar_buttons.includes('btnStatusPanel') || [];
    //Flag Engate e Desengate || Buscar Operações somente quando for engate e desengate
    if(this.account.system_client.resource.environment.trip_hitch){
      this.trip_hitch = this.account.system_client.resource.environment.trip_hitch;
      this.getOperations();
      this.filterStatusSelected.setValue('checkin');
    }

    this.pageConfig();
    this.viewPortChange();
    this.getDepartments();
    this.labelFilterEnabled();
    this.getListStatus();
    this.getDockSpaces();
    this.initObservers();

    this.wsSubscription = this.websocketService
    .connect(`${environment.wsUrl}/checkin/${this.account.system_client.id}`)
    .pipe(
      debounceTime(3000)
    )
    .subscribe({
      next:(msg) => {
        this.loadCheckin();
      },
      error:()=>{

      }
    });
  }

  getDepartments() {
    this.departmentService.index().subscribe((r) => {
      this.departments = r.data.resource;
      this.departments = this.departments.filter((item: any) => {
        return this.account.department_id.indexOf(item.id) > -1;
      })
    })
  }

  getOperations() {
    this.supportDataService.operation().subscribe((r:any) => {
      this.operationsOptions = r.data.resource;
      //comments
      this.allOperationsOptions = r.data.resource;
      this.optionDepartamentSelected = false;
    })
  }

  filterOperationDepartament(department_id?: any) {
    this.operationsOptions = this.allOperationsOptions.filter((item: any) => {
      return item.department_id == department_id;
    })
    this.optionDepartamentSelected = true;
  }

  // Adicionado Marcos
  getListStatus() {
    const excludedStatus:string[] = this.account?.system_client?.resource?.environment?.pages?.dynamic_status_panel?.exclude_status || [];
    this.supportDataService.status().subscribe((r: any) => {
      this.listStatus = r.data.resource;
      this.listStatus = this.listStatus.filter((item:any)=>!excludedStatus.includes(item.id));
    });
  }

  // getDockSpaces() {
  //   this.supportDataService.dockSpaces().subscribe((r: any) => {
  //     this.dockSpaces = [];
  //   });
  // }

  getDockSpaces() {
    // Pega os registros do novo módulo de gestão de docas
    this.dockManagementService.index().subscribe((r: any) => {
      this.dockSpaces = r.data;
      //Se cliente possuir filtro de docas para essa tela, chama a função que gera a lista de docas para o filtro
      if (this.departments) {
        //Verifica se possui mais de 1 departamento, se sim popula dockSpacesFilterList
        //Com docas separadas primeiro por departamentos, caso possua apenas 1 departamento
        //dockSpacesFilterList recebe todas as docas que possuem o mesmo department_id
        if (this.departments.length > 1) {
          this.dockSpaces = JSON.parse(JSON.stringify(this.departments));
          this.dockSpaces.forEach((item: any) => {
            item['docks'] = this.dockSpaces.filter((ft: any) => ft.department_id === item.id);
          });
        } else {
          this.dockSpaces = this.dockSpaces.filter((ft: any) => ft.department_id === this.departments[0].id);
        }
      }

    });
  }

  initObservers(){
    /// Ao utilizar a busca
    this.driverSubscriptation = this.searchFilter.valueChanges
    .pipe(debounceTime(500))
    .pipe(
      switchMap(() => {
        try{
          this.searching = true;
          const options = {
            search: this.searchFilter.value || "",
            pageSize: this.paginatorTop.pageSize || 10,
            page: this.paginatorTop.pageIndex,
            status: this.filterStatusSelected.value || '',
            department_id: this.filterDepartmentSelected.value || "",
            operation_id: this.filterOperationSelected.value || ""
          };
          return this.painelMotoristaService.indexDinamico(options)
          .pipe(
            catchError(() => {
              return observableOf({ data: [], total: 0, per_page: 0 });
            })
          );

        }catch(ex){
          return observableOf({ data: [], total: 0, per_page: 0 });
        }
      })
    )
    .pipe(
      tap((response: Response | any) => {
        if (!response.data.length && this.paginatorTop.hasPreviousPage()) {
          this.paginatorTop.previousPage();
        }

        this.showListEmpty = response.data.length === 0;
        this.paginatorTop.length = response.total;
        this.paginatorTop.pageSize = response.per_page;

        this.paginatorBottom.length = response.total;
        this.paginatorBottom.pageSize = response.per_page;
      })
    )
    .pipe(
      map((response: any) => {
        return response.data;
      })
    )
    .pipe(
      catchError((error) => {
        console.log(error);
        this.searching = false;
        return observableOf([]);
      })
    ) as Subject<Subscription>;

    this.driverSubscriptation.subscribe((data) => {
      this.drivers = data;
      this.allDataAvailable = true;
    });

    this.filterStatusSelected.valueChanges.subscribe(_result=>this.driverSubscriptation.next(new Subscription()));
    this.filterDepartmentSelected.valueChanges.subscribe(_result=>this.driverSubscriptation.next(new Subscription()));
    this.filterOperationSelected.valueChanges.subscribe(_result=>this.driverSubscriptation.next(new Subscription()));
    this.driverSubscriptation.next(new Subscription());
  }

  loadCheckin() {
    this.driverSubscriptation.next(new Subscription());
  }

  clearFilter(_event?:any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }

  alterPage(event:any){
    this.paginatorTop.pageIndex = this.paginatorBottom.pageIndex = event.pageIndex;
    this.paginatorTop.pageSize = this.paginatorBottom.pageSize = event.pageSize;
    this.loadCheckin();
  }

  filterEnabled(filter: string) {
    const filters = this.account.system_client.resource.environment.pages['painel_motoristas']["data_filters"];
    this.filterWidth = 100 / filters.length + "%";
    return filters.filter((item:any) => item === filter).length > 0;
  }

  labelFilterEnabled() {
    this.filterLabels = this.account.system_client.resource.environment.pages['painel_motoristas']["data_filters_label"];
  }

  /**Page configurations*/
  pageConfig(){
    let pageConfig:any = {
      card: "card_driver_default",
      card_layout: 2,
      filter_sticky: true,
      paginator_fixed: true,
      columns: 3
    };

    const clientPageConfig = this.account.system_client.resource.environment.pages['painel_motoristas']['page_config'] || {};

    Object.assign(pageConfig, clientPageConfig);

    this.card = pageConfig.card;

    this.cardLayout = pageConfig.card_layout;

    this.filterSticky = pageConfig.filter_sticky;

    this.paginatorFixed = pageConfig.paginator_fixed;

    this.gridColumns = ({2:'grid-2-columns',3:'grid-3-columns', 4:'grid-4-columns'})[pageConfig.columns as 2|3|4] || "grid-3-columns";

    this.removeShadowOnStickyEnd();

    this.dataDriversChange
    .pipe(debounceTime(500))
    .subscribe(() => {
      this.paginatorFooterShadow(
        this.scrollDispatcher.scrollContainers.entries().next().value[0]
      );
    });
  }

  /**Function for styling */
  private removeShadowOnStickyEnd(){
    if(!this.scrollSubscriptation){
      this.scrollSubscriptation = this.scrollDispatcher
      .scrolled()
      .subscribe((cdk)=>{
        if(cdk){
          this.filterFieldsShadow(cdk);
          this.paginatorFooterShadow(cdk);
        }

        if(!this.paginatorFixed && !this.filterSticky){
          this.scrollSubscriptation.unsubscribe();
        }
      });
    }
  }

  /**Function for styling */
  private paginatorFooterShadow(cdk:CdkScrollable){
    if(this.paginatorFixed){
      let el = this.paginatorElement.nativeElement;
      if(cdk && cdk.measureScrollOffset('bottom') > 1 && ['none',''].includes(el.style.boxShadow)){
        el.classList.add("paginator-fixed-shadow");
      }else if(!cdk || (el.style.boxShadow != "none" && cdk.measureScrollOffset('bottom') <= 1)){
        el.classList.remove("paginator-fixed-shadow");
      }
    }
  }

  /**Function for styling */
  private filterFieldsShadow(cdk:CdkScrollable){
    if(this.filterSticky){
      let el = this.filterElement.nativeElement;
      if(el && ['none',''].includes(el.style.boxShadow) && el.getBoundingClientRect().top <= 1){
        el.classList.add("filter-sticky-shadow");
      }else if(el && el.getBoundingClientRect().top > 1){
        el.classList.remove("filter-sticky-shadow");
      }
    }
  }

  /**Function for styling */
  private viewPortChange(){
    this.breakpointObserver
    .observe(['(max-width: 768px)'])
    .subscribe((state: BreakpointState) => {
     state.matches && this.paginatorFixed
     ? this.paginatorElement.nativeElement.classList.add("paginator-fixed-full")
     : this.paginatorElement.nativeElement.classList.remove("paginator-fixed-full");
    });
  }
}
