import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  envData: any;

  constructor(
    private http: HttpClient
  ) {
    this.envData = JSON.parse(localStorage.getItem("account") || '{}');
  }

  getIncidents() {
    const url = `${environment.apiUrl}/healthCheck`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getTopIncidents() {
    const url = `${environment.apiUrl}/healthCheckTopIncidents`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getIncidentsBetweenDates(options: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/healthCheckBetween`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  disableUserNotification(data: any) {
    const url = `${environment.apiUrl}/disableNotification`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  disableUserToastrNotification(data: any) {
    const url = `${environment.apiUrl}/disableToastrNotification`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getActiveNotification() {
    const url = `${environment.apiUrl}/getActiveNotification`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getActiveToastrNotification() {
    const url = `${environment.apiUrl}/getActiveToastrNotification`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getToastrNotification() {
    const url = `${environment.apiUrl}/getToastrNotification`;

    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}
