import { CheckinService } from './../../checkin/shared/checkin.service';
import { UntypedFormControl } from '@angular/forms';
import { SupportDataService } from './../support-data.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-dialog-change-operation",
  templateUrl: "./dialog-change-operation.component.html",
  styleUrls: ["./dialog-change-operation.component.scss"],
})
export class DialogChangeOperation implements OnInit {
  operations: any[] = [];
  subOperations: any[] = [];
  allData: any;
  getData: string = "";
  AllOperations: any;
  AllSubOperations: any;
  AllDepartments: any;
  operationSelected: UntypedFormControl = new UntypedFormControl();
  subOperationSelected: UntypedFormControl = new UntypedFormControl();
  showSubOperation = true;
  dialogResult: any;

  constructor(
    private supportDataService: SupportDataService,
    private checkinService: CheckinService,
    public dialogRef: MatDialogRef<DialogChangeOperation>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getOperations();
    this.getSubOperations();
  }

  getOperations() {
    this.supportDataService
      .generic({ name: "operation_type" })
      .subscribe((r: any) => {
        this.operations = r.data.resource.filter(
          (item: any) =>
            item.department_id == this.data.item.resource.department_id
        );
        this.operationSelected.setValue(
          this.data.item.resource.operation_id ||
            this.data.item.resource.operation
        );
      });
  }

  getSubOperations() {
    this.supportDataService
      .generic({ name: "suboperation_type" })
      .subscribe((r: any) => {
        this.AllSubOperations = r.data.resource;
        this.subOperations = r.data.resource.filter(
          (item: any) =>
            item.department_id == this.data.item.resource.department_id &&
            item.operation_id ==
              (this.data.item.resource.operation_id ||
                this.data.item.resource.operation)
        );

        // Condicional para desabilitar caixa de suboperações em operações que não possui nenhuma suboperação
        if (this.subOperations.length > 0) {
          this.subOperationSelected.setValue(
            this.data.item.resource.suboperation_id ||
              this.data.item.resource.suboperation
          );
        } else {
          this.subOperationSelected.setValue(null);
          this.subOperationSelected.disable();
          this.showSubOperation = false;
        }
      });
  }

  changeOperations() {
    this.subOperations = this.AllSubOperations.filter(
      (item: any) =>
        item.department_id == this.data.item.resource.department_id &&
        item.operation_id == this.operationSelected.value
    );

    // Condicional para desabilitar caixa de suboperações em operações que não possui nenhuma suboperação
    if (this.subOperations.length > 0) {
      this.subOperationSelected.setValue(null);
      this.subOperationSelected.enable();
      this.showSubOperation = true;
    } else {
      this.subOperationSelected.setValue(null);
      this.subOperationSelected.disable();
      this.showSubOperation = false;
    }
  }

  onSubmit() {
    this.dialogResult = {
      operation_id: this.operationSelected.value,
      suboperation_id: this.subOperationSelected.value,
      id: this.data.item.id,
      changeOperation: true,
    };
    this.checkinService
      .updateField("checkins-update-field", this.dialogResult)
      .subscribe((r: any) => {
        if (r.status == 200) {
          this.dialogRef.close(this.dialogResult);
        }
      });
  }
}
