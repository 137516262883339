import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ModalRankingPermanenciaComponent } from './modal-ranking-permanencia/modal-ranking-permanencia.component';
import { ModalLiberacaoEntradaComponent } from './modal-liberacao-entrada/modal-liberacao-entrada.component';
import { ModalDirecionamentoComponent } from './modal-direcionamento/modal-direcionamento.component';
import { ModalPanelStatusLorealComponent } from './modal-panel-status/modal-panel-status.component';
import { ModalRankingAguardandoLiberacaoComponent } from './modal-ranking-aguardando-liberacao/modal-ranking-aguardando-liberacao.component';
import { DynamicStatusPanelModalComponent } from './dynamic-status-panel-modal/dynamic-status-panel-modal.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        DashboardRoutingModule
    ],
    declarations: [
      DashboardListComponent, ModalRankingPermanenciaComponent,
      ModalLiberacaoEntradaComponent, ModalDirecionamentoComponent,
      ModalPanelStatusLorealComponent, ModalRankingAguardandoLiberacaoComponent,
      DynamicStatusPanelModalComponent
    ],
    exports: [
        DashboardListComponent
    ]
})
export class DashboardLorealModule { }
