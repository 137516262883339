import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TelemetryService } from '../../shared/telemetry.service'

@Component({
  selector: 'app-maps-generic',
  templateUrl: './maps-generic.component.html',
  styleUrls: ['./maps-generic.component.scss']
})
export class MapsGenericComponent implements OnInit {
  lat!: number;
  lng!: number;
  zoom = 20;
  showMap: boolean = false;

  constructor(
    public telemetry: TelemetryService,
    public dialogRef: MatDialogRef<MapsGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getTelemetry()
    setInterval(() => {
      this.getTelemetry();
  }, 30000);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getTelemetry(){
    const options = {
      type: this.data.item.type,
      id: this.data.item.id
    }
    this.telemetry.last(options).subscribe((r:any) => {
      this.lat = r.resource.lat
      this.lng = r.resource.lng
      this.showMap = true
    })
  }
}
