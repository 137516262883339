<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="showComponent">
  <h2 class="mat-align-center" mat-dialog-title>
    Defina o local de direcionamento para o motorista
    <br />
      <p><b>{{data.item?.driver?.name || ""}}</b></p>
    <br />

  </h2>

  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <input
        (click)="this.loadDocksOnClick()"
        placeholder="Digite aqui o local de direcionamento"
        matInput
        formControlName="dock_space_id"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getTitle.bind(this)">
        <mat-option *ngFor="let item of (docks | filterValue: 'name' : dockFilterValue)" [value]="item.id" >
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div mat-dialog-actions align="end">
      <button type="button" mat-button color="warn" mat-dialog-close>
        Cancelar
      </button>
      <button
        type="submit"
        mat-raised-button
        cdkFocusInitial
        style="color: white;"
        color="primary"
        [disabled]="!form.valid"
      >
        Confirmar
      </button>
    </div>
  </div>
  <span class="fx-spacer"></span>


</form>
