import { SupportDataService } from './../../shared/support-data.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { interval } from 'rxjs';
// import 'rxjs/add/operator/startWith';
// import 'rxjs/add/operator/switchMap';
// import { RefreshService } from 'src/app/shared/refresh.service';
import { UntypedFormBuilder } from '@angular/forms';
import { EChartsOption } from 'echarts';
import { OfensoresDialogComponent } from '../ofensores-dialog/ofensores-dialog.component';
import { DashboardJandiraService } from '../shared/dashboard-jandira.service';
import { SystemNotificationsService } from './../../shared/system-notifications.service';

@Component({
  selector: 'app-dashboard-jandira-list',
  templateUrl: './dashboard-jandira-list.component.html',
  styleUrls: ['./dashboard-jandira-list.component.scss']
})
export class DashboardJandiraListComponent implements OnInit {

  @ViewChild('fs') fs: ElementRef;

  media_permanencia: any;
  total_veiculos_operacao: number;
  total_veiculos_finalizados: number;

  dados_entrada: any;
  dados_patio: any;
  dados_carregamento: any;
  dados_descarregamento: any;
  dados_expedicao: any;
  dados_gr: any;
  dados_saida: any;
  performance_total: number = 0;

  // se o valor for um aumento setar UP
  setaup = 'call_made';
  // se o valor for uma queda setar DOWN
  setadown = 'call_received';
  breakpoints = Breakpoints;
  screenRatio: string;
  notifications: any = [];
  hasNotifications : boolean = false;
  definedDay:any = {
    start: null,
    end: null
  };

  dateForm = this.formBuilder.group({
    dayStart: [new Date()],
    dayEnd: [new Date()],
  });

  @Output() reloadEvent = new EventEmitter();
  departments: any = [];
  department_id: any;
  user: any;
  filterLabels: any = [];
  account: any;
  value: any;

  constructor(
    private dialog: MatDialog,
    public dashboard: DashboardJandiraService,
    public breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    public supportData: SupportDataService,
    public systemNotifications : SystemNotificationsService
  ) {
  }

  //Performance total
  chartOptionPerformace: EChartsOption;
  miniChartOptionEntrada: EChartsOption;
  miniChartOptionPatio: EChartsOption;
  miniChartOptionCarregamento: EChartsOption;
  miniChartOptionDescarregamento: EChartsOption;
  miniChartOptionExpedicao: EChartsOption;
  miniChartOptionGR: EChartsOption;
  miniChartOptionSaida: EChartsOption;

  EChartsOption(value: number): EChartsOption {
    return {
        series: [{
          name: 'Performance',
            type: 'gauge',
            detail: {
                valueAnimation: true,
                offsetCenter: [0, '35%'],
                  formatter: function (dataValue) {
                      return Math.round(dataValue) + '%';
                  },
                color: '#000000',
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle : 'normal'
            },
            data: [{value: value,}],
            axisLine:{
              lineStyle: {
                    color: [[(value/100), '#FCC326'], [1, '#F5F5F5']],
                    width: 20,
                    //shadowColor: ['rgba(252, 195, 38,0.45)', 0],
                    shadowColor: 'rgba(252, 195, 38,0.45)',
                shadowBlur: 5,
                shadowOffsetX: 1,
                shadowOffsetY: 1
              }
            },
            startAngle: 180,
            endAngle: 0,
            splitNumber: 0,
            itemStyle: {
                color: '#FCC326',
                shadowColor: 'rgba(252, 195, 38,0.45)',
                shadowBlur: 5,
                shadowOffsetX: 1,
                shadowOffsetY: 1
            },
            pointer: {
                length: '50%',
                width: 3,
            },
            axisLabel: {
                show: false
            },
        }]
    }
  };

  switchDay() {
    this.definedDay.start = this.dateForm.value.dayStart.toISOString().slice(0, 10);
    this.definedDay.end = this.dateForm.value.dayEnd.toISOString().slice(0, 10);

    this.dashboardKPIs();
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "[]");

    this.labelFilterEnabled();
    this.loadDepartments();
    this.dashboardKPIs();
    this.loadNotifications();

    const secondsCounter = interval(60000);

    secondsCounter.subscribe(n => {
      this.dashboardKPIs();
    });

  }

  segundosParaHoraMinuto(segundos: any) {
    if (segundos) {
      return moment.duration(parseFloat(segundos), "seconds").format('DD[d] hh[h]mm[m]');
    } else {
      return null;
    }
  }

  dialogTopOfensores(etapa: string){
    const dialogRef = this.dialog.open(OfensoresDialogComponent, {
      width: "900px",
      data: {
        etapa,
        definedDay: this.definedDay
      },
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }


  tempoMedioPermanencia(valor: any) {
    this.media_permanencia = this.segundosParaHoraMinuto(parseInt(valor));
  }

  qtdVeiculos(valor: any) {
    this.total_veiculos_operacao = valor.operacao;
    this.total_veiculos_finalizados= valor.finalizados;
  }

  dashboardKPIs() {
    return this.dashboard.dashboardKPIs(Object.assign(this.definedDay, {department_id: this.department_id})).subscribe((valor: any) => {
      this.tempoMedioPermanencia(valor.tempoMedioPermanencia);
      this.qtdVeiculos(valor.qtdVeiculos);
      this.performanceTotal(valor.performanceTotal);
      this.entrada(valor.entrada);
      this.patio(valor.patio);
      this.carregamento(valor.carregamento);
      this.descarregamento(valor.descarregamento);
      this.expedicao(valor.expedicao);
      this.gr(valor.gr);
      this.saida(valor.saida);
    });
  }

  entrada(valor: any) {
    this.dados_entrada = valor;
    this.dados_entrada['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_entrada['tempoAtualPermanenciaEtapa']));
    this.dados_entrada['mediaPermanenciaEntrada'] = this.segundosParaHoraMinuto(parseInt(this.dados_entrada['mediaPermanenciaEntrada']));
    this.miniChartOptionEntrada = this.EChartsOption(this.dados_entrada['performanceEtapa']);
  }

  patio(valor: any) {
    this.dados_patio = valor;
    this.dados_patio['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_patio['tempoAtualPermanenciaEtapa']));
    this.dados_patio['mediaPermanenciaPatio'] = this.segundosParaHoraMinuto(parseInt(this.dados_patio['mediaPermanenciaPatio']));
    this.miniChartOptionPatio = this.EChartsOption(this.dados_patio['performanceEtapa']);
  }

  carregamento(valor: any) {
    this.dados_carregamento = valor;
    this.dados_carregamento['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_carregamento['tempoAtualPermanenciaEtapa']));
    this.dados_carregamento['mediaPermanenciaCarregamento'] = this.segundosParaHoraMinuto(parseInt(this.dados_carregamento['mediaPermanenciaCarregamento']));
    this.miniChartOptionCarregamento = this.EChartsOption(this.dados_carregamento['performanceEtapa']);
  }

  descarregamento(valor: any) {
    this.dados_descarregamento = valor;
    this.dados_descarregamento['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_descarregamento['tempoAtualPermanenciaEtapa']));
    this.dados_descarregamento['mediaPermanenciaDescarregamento'] = this.segundosParaHoraMinuto(parseInt(this.dados_descarregamento['mediaPermanenciaDescarregamento']));
    this.miniChartOptionDescarregamento = this.EChartsOption(this.dados_descarregamento['performanceEtapa']);
  }

  expedicao(valor: any) {
    this.dados_expedicao = valor;
    this.dados_expedicao['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_expedicao['tempoAtualPermanenciaEtapa']));
    this.dados_expedicao['mediaPermanenciaExpedicao'] = this.segundosParaHoraMinuto(parseInt(this.dados_expedicao['mediaPermanenciaExpedicao']));
    this.miniChartOptionExpedicao = this.EChartsOption(this.dados_expedicao['performanceEtapa']);
  }

  gr(valor: any) {
    this.dados_gr = valor;
    this.dados_gr['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_gr['tempoAtualPermanenciaEtapa']));
    this.dados_gr['mediaPermanenciaGr'] = this.segundosParaHoraMinuto(parseInt(this.dados_gr['mediaPermanenciaGr']));
    this.miniChartOptionGR = this.EChartsOption(this.dados_gr['performanceEtapa']);
  }

  saida(valor: any) {
    this.dados_saida = valor;
    this.dados_saida['tempoAtualPermanenciaEtapa'] = this.segundosParaHoraMinuto(parseInt(this.dados_saida['tempoAtualPermanenciaEtapa']));
    this.dados_saida['mediaPermanenciaSaida'] = this.segundosParaHoraMinuto(parseInt(this.dados_saida['mediaPermanenciaSaida']));
    this.miniChartOptionSaida = this.EChartsOption(this.dados_saida['performanceEtapa']);
  }

  performanceTotal(valor: any){
    this.chartOptionPerformace = {
      series: [{
            name: 'Performance',
              type: 'gauge',
              detail: {
                  valueAnimation: true,
                  offsetCenter: [0, '35%'],
                    formatter: function (value) {
                        return Math.round(value) + '%';
                    },
                  color: '#000000',
                  fontSize: 16,
                  fontWeight: 'normal',
                  fontStyle : 'normal'
              },
              data: [{value: valor.total,}],
              axisLine:{
                lineStyle: {
                      color: [[(valor.total/ 100), '#FCC326'], [1, '#F5F5F5']],
                      width: 30,
                      //shadowColor: ['rgba(252, 195, 38,0.45)', 0],
                      shadowColor: 'rgba(252, 195, 38,0.45)',
                  shadowBlur: 5,
                  shadowOffsetX: 1,
                  shadowOffsetY: 1
                }
              },
              startAngle: 180,
              endAngle: 0,
              splitNumber: 0,
              itemStyle: {
                  color: '#FCC326',
                  shadowColor: 'rgba(252, 195, 38,0.45)',
                  shadowBlur: 5,
                  shadowOffsetX: 1,
                  shadowOffsetY: 1
              },
              pointer: {
                  length: '50%',
                  width: 3,
              },
              axisLabel: {
                  show: false
              },
          }]
    };
  }

   labelFilterEnabled() {

  }

  filterDockSpace(department_id?: any) {
    console.log('filterDockSpace ', department_id);
    this.department_id = department_id;
    this.dashboardKPIs();
  }

  loadDepartments() {
    const options = {
      name: 'departments'
    }
    this.supportData.departments(options).subscribe({
      next: (r: any) => {
        this.departments = r.data.resource;

        this.departments = this.departments.filter((item: any) => {
          return this.account.department_id.indexOf(item.id) > -1;
        })
      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  loadNotifications() {
    this.systemNotifications.notifications().subscribe({
      next: (r: any) => {
        this.notifications = r.data;
        if (this.notifications.length > 0) this.hasNotifications = true


      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  alteraCor(valor?: any){

    if(valor && valor["percentualEtapa"] > 0 && valor["percentualEtapa"] <= 95){
      return 'divVerde';
    }

    if(valor && valor["percentualEtapa"] > 95 && valor["percentualEtapa"] <= 100){
      return 'divAmarela';
    }

    if(valor && valor["percentualEtapa"] > 100){
      return 'divVermelha';
    }

    return 'divNeutra';
  }

}

