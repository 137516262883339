import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { DevicesService } from "./../devices.service";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { DialogConfirmComponent } from "../dialog-confirm/dialog-confirm.component";

@Component({
  selector: "app-assign-qualcomm-device",
  templateUrl: "./assign-qualcomm-device.component.html",
  styleUrls: ["./assign-qualcomm-device.component.scss"],
})
export class AssignQualcommDeviceComponent implements OnInit {
  // Input de busca de dispositivos
  // Lista de busca informa: Nome | Bateria | Em uso/Pronto para Uso
  // Se o dispositivo estiver em uso, desassociar antes de associar em novo checkin
  // Caso negativo, associar ao checkin
  list: any[] = [];
  listOriginal: any[];
  searchForm = new FormGroup({
    search: new FormControl(""),
  });
  selected!: string;
  disabled = true;
  user = JSON.parse(localStorage.getItem("account")!);
  msg: string;
  showMsg = false;
  showSelect = false;
  checked = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private componentRef: MatDialogRef<AssignQualcommDeviceComponent>,
    private devicesService: DevicesService,
    private checkinService: CheckinService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getList();

    this.searchForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value: any) => {
        // Realiza a busca do termo digitado
        this.searchTerm(value.search);
      });
  }

  getList() {
    this.devicesService.index({ cq: this.data.cq }).subscribe((r: any) => {
      this.listOriginal = r.data;
      this.list = this.listOriginal;
      this.disabled = false;
      this.check();
    });
  }

  // Realiza a busca do termo digitado no campo de busca
  searchTerm(term: string) {
    this.devicesService
      .index({ search: term, cq: this.data.cq })
      .subscribe((r: any) => {
        this.listOriginal = r.data;
        this.list = this.listOriginal;
      });
  }
  // Limpa o campo de busca
  clearSearchString() {
    this.searchForm.patchValue({ search: "" });
    this.getList();
  }

  selectedOption(opt: string) {
    this.selected = opt;
  }

  submit() {
    let data: any = {
      id: this.data.checkin_id,
      device: {
        device_id: this.selected,
        linked_at: new Date().toISOString(),
        linked_by: this.user.id,
        unlinked_at: "",
        unlinked_by: null,
        linked: true,
      },
    };
    // Se um status foi cadastrado na ação, adiciona
    // no objeto. Caso contrário, não altera status
    if (this.data.action.status.length > 0) {
      data = { ...data, status: this.data.action.status };
    }
    const device_selected = this.listOriginal.filter(
      (item: any) => item.id === this.selected,
    );
    // Apenas se o id do checkin for diferente

    if (this.data.cq.length > 0 && device_selected[0].assigned) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: "Confirmar Ação!",
          message:
            "Dispositivo já vinculado a um check in ativo. Deseja remover o vínculo anterior?",
          btnConfirm: "Sim",
          btnCancel: "Fechar",
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.checkinService.updateStatus(data).subscribe((r: any) => {
            this.componentRef.close(data);
          });
        }
      });
    } else {
      this.checkinService.updateStatus(data).subscribe((r: any) => {
        this.componentRef.close(data);
      });
    }
  }

  check() {
    if (this.data.device) {
      if (this.data.device.linked) {
        this.list = this.list.filter(
          (item: any) => item.id !== this.data.device.device_id,
        );
        let device: any[] = JSON.parse(JSON.stringify(this.listOriginal));
        let vinculado: string = "";
        device.filter((i: any) => {
          if (i.id === this.data.device.device_id) {
            vinculado = i.name.split(" | ");
            this.msg = `Este check in já está vinculado ao dispositivo ${vinculado[0]}. Deseja trocar o dispositivo?`;
            this.showMsg = true;
          }
        });
      } else {
        this.showSelect = true;
      }
    } else {
      this.showSelect = true;
    }
  }

  changeDevice() {
    this.showSelect = !this.showSelect;
  }
}
