<div [ngStyle]="(step === 1 && { display: 'show' }) || { display: 'none' }">
  <h2 mat-dialog-title>
    {{ "Business_model.carrier_document" | translate:{Default: "Pesquise pelo CNPJ da transportadora na base de dados do Maestro"} }}
  </h2>
  <div  fxLayoutAlign="space-around stretch">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Business_model.search" | translate:{Default: "Buscar"} }}</mat-label>
        <input matInput [placeholder]="'Business_model.carrier_document' | translate:{Default:'CNPJ'}"
          type="text"
          [dropSpecialCharacters]="true"
          mask="00.000.000/0000-00"
          [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.clean_filter' | translate:{Default: 'Limpar pesquisa'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>
    <div
      class="no-records"
      *ngIf="searchIsNotEmpty && carriers.length === 0"
    >
      <p class="rec-title">{{ "Messages.no_register" | translate:{Default: "Nenhum registro encontrado"} }}</p>
      <button
        type="button"
        mat-button
        color="primary"
        (click)="createNewCarrier()"
      >
        {{ "Messages.create_new_carrier" | translate:{Default: "Criar nova transportadora"} }}
      </button>
    </div>
    <div class="tkg-list">
      <form [formGroup]="formSearchCarrier">
        <mat-selection-list #carrier formControlName="carrier">
          <mat-list-option
            *ngFor="let carrier of carriers"
            [disabled]="carrier.registered"
            [value]="carrier"
          >
            <div class="list-row">
              <div class="carrier_name">{{ carrier.name }}</div>
              <div
                [ngClass]="
                  carrier.registered
                    ? 'carrier-registered'
                    : 'carrier-not-registered'
                "
              >
                {{ carrier.registered ? ("Business_model.registered" | translate:{Default: "Cadastrada"}) : ("Business_model.no_registered" | translate:{Default: "Não cadastrada"}) }}
              </div>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </form>
    </div>
    <mat-dialog-actions align="end">
      <button type="button" mat-button color="warn" mat-dialog-close>
        {{ "Business_model.close" | translate:{Default: "Fechar"} }}
      </button>
      <button
        type="submit"
        mat-button
        color="primary"
        [disabled]="!(carrier.selectedOptions.selected.length > 0)"
        (click)="selectCarrier()">
        {{ "Business_model.next" | translate:{Default: "Próximo"} }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div [ngStyle]="(step === 2 && { display: 'block' }) || { display: 'none' }">
  <h2 mat-dialog-title>{{ "Business_model.carrier_sign" | translate:{Default: "Cadastro de transportadora"} }}</h2>
  <div  fxLayoutAlign="space-around stretch">
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <mat-form-field>
            <!--Parametro onkeyup passa o valor do campo nome para caracteres maiúsculos ao terminar de digitar-->
            <input
              type="text"
              formControlName="name"
              matInput
              placeholder="Nome"
              onkeyup="this.value = this.value.toUpperCase();"
            />
          </mat-form-field>
          <mat-form-field *ngIf="haveExternalID">
            <input
              type="text"
              formControlName="external_id"
              matInput
              placeholder="ID externo"
            />
          </mat-form-field>
          <mat-form-field>
            <!--Parametro dropSpecialCharacters quando marcado como falso mantêm os caracteres da máscara de CNPJ ao passar o valor para salvar-->
            <input
              type="text"
              formControlName="cnpj"
              matInput
              [placeholder]="'Business_model.carrier_document' | translate:{Default:'CNPJ'}"
              mask="00.000.000/0000-00"
              [dropSpecialCharacters]="true"
            />
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button color="warn" mat-dialog-close>
        {{ "Business_model.close" | translate:{Default: "Fechar"} }}
      </button>
      <button
        type="submit"
        mat-raised-button
        style="color: white;"
        color="primary"
        [disabled]="!form.valid"
        (click)="addCarrier()"
      >
        {{ "Business_model.registering" | translate:{Default: "Cadastrar"} }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
