
<!-- Filter fields -->
<div class="top-bar-filters container-filters" [class.filter-sticky]="filterSticky" #filterElement>
  <div class="container-filters">
    <div
      *ngIf="isWeb && filterEnabled('status') && !trip_hitch"
    >
      <mat-form-field appearance="outline">
        <mat-label>{{
          this.filterLabels ? this.filterLabels.status : "Business_model.status" | translate:{Default: "Status"}
        }}</mat-label>
        <mat-select  [formControl]="filterStatusSelected">
          <mat-option>{{ "Business_model.clean_filter" | translate:{Default: 'Limpar Filtro'} }}</mat-option>
          <mat-divider></mat-divider>
          <mat-option
            *ngFor="let ls of listStatus"
            [value]="ls.id"
          >
            {{ (ls.translation || ls.name) | translate:{Default: ls.name} }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
    *ngIf="isWeb && filterEnabled('status') && trip_hitch"
  >
    <mat-form-field appearance="outline">
      <mat-label>{{
        this.filterLabels ? this.filterLabels.status : "Business_model.status" | translate:{Default: "Status"}
      }}</mat-label>
      <mat-select  [formControl]="filterStatusSelected">
        <mat-option>{{ "Business_model.clean_filter" | translate:{Default: 'Limpar filtro'} }}</mat-option>
        <mat-divider></mat-divider>
        <mat-option
          [value]="'checkin'"
        >
        {{ "Business_model.checkin" | translate:{Default: "Checkin"} }}
        </mat-option>
        <mat-option
        [value]="'checkout'"
      >
      {{ "Business_model.checkout" | translate:{Default: "Checkout"} }}
      </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

    <div
      *ngIf="isWeb && showDepartments"
    >
      <mat-form-field appearance="outline">
        <mat-label>{{
          "Business_model.area" | translate:{Default: "Área"}
        }}</mat-label>
        <mat-select [formControl]="filterDepartmentSelected" (selectionChange)="filterOperationDepartament($event.value)">
          <mat-option>{{ "Business_model.clean_filter" | translate:{Default: "Limpar filtro"} }}</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let dp of departments" [value]="dp.id">
            {{ (dp.tranlation || dp.name) | translate:{Default: dp.name} }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div
      *ngIf="isWeb  && trip_hitch"
    >
      <mat-form-field appearance="outline">
        <mat-label>{{
          "Business_model.operation" | translate:{Default: "Operação"}
        }}</mat-label>
        <mat-select [formControl]="filterOperationSelected " [disabled] ="!optionDepartamentSelected" >
          <mat-option>{{ "Business_model.clean_filter" | translate:{Default: "Limpar filtro"} }}</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let opOption of operationsOptions" [value]="opOption.id">
            {{ (opOption.translation || opOption.name) | translate:{Default: opOption.name} }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="isWeb && filterEnabled('search')"
    >
      <mat-form-field appearance="outline">
        <mat-label>{{
          this.filterLabels ? this.filterLabels.search : "Business_model.search" | translate:{Default: "Buscar"}
        }}</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.clean_search' | translate:{Default: 'Limpar pesquisa'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>


  </div>
</div>

<!-- Paginator top -->
<div class="paginator-top" [hidden]="showListEmpty">
  <mat-paginator
    #paginatorTop
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
    (page)="alterPage($event)"
  ></mat-paginator>
</div>

<!-- Cards grid -->
<div class="grid" [ngClass]="gridColumns">

    <card-dynamic *ngFor="let element of drivers"
      [layout]="cardLayout"
      [card]="card"
      [data]="element"
      [options]="{'listStatus':listStatus,'dockSpaces':dockSpaces}"
    >
    </card-dynamic>

</div>

<!-- Paginator footer -->
<div [hidden]="showListEmpty" [class.paginator-fixed]="paginatorFixed" #paginatorElement>
  <mat-paginator
    #paginatorBottom
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
    (page)="alterPage($event)"
  ></mat-paginator>
</div>

