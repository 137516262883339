import { Component } from '@angular/core';
import { SupportDataService } from '../../support-data.service';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';

@Component({
  selector: 'app-formly-ss-flow',
  templateUrl: './formly-ss-flow.component.html',
  styleUrls: ['./formly-ss-flow.component.scss']
})
export class FormlySsFlowComponent  extends FieldType {

  constructor(private supportDataService: SupportDataService) {
    super();
  }

  _formControl = new FormControl();
  filter:Observable<any>;
  ngModule:any;
  selectedItem = false;

  ngOnInit(){

    this._formControl.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          startWith(''),
          map(value => value)
          ).subscribe(value => {
            if(typeof value === 'string') {
              this.filter = this.supportDataService.getFlowTemplates({search: value});
            }
          });

  }

  clear(){
    this.ngModule = "";
    this.formControl.setValue("");
  }

  displaySelected(opt: any) {
    if(opt){
      this.formControl.setValue(opt.flow);
      return opt && opt.name ? opt.name : '';
    }
  }
}
