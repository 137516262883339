import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CartListComponent } from './cart-list/cart-list.component';
import { CartRoutingModule } from './cart-routing.module';
import { DirectCartDialogComponent } from './direct-cart-dialog/direct-cart-dialog.component';
import { LinkVehicleDialogComponent } from './link-vehicle-dialog/link-vehicle-dialog.component';
import { VehicleSearchDialogComponent } from './vehicle-search-dialog/vehicle-search-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        CartRoutingModule
    ],
    declarations: [
        CartListComponent,
        DirectCartDialogComponent,
        LinkVehicleDialogComponent,
        VehicleSearchDialogComponent
    ]
})
export class CartModule { }
