<div class="top-bar-filters">
  <div>

    <div>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter">
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon matSuffix (click)="clearFilter($event)" matTooltip="Limpar pesquisa" ngClass="app-search-clear"
          *ngIf="searchFilter.value">clear</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z8" [hidden]="showListEmpty">
<table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z8" *ngIf="!showListEmpty">
  <ng-container *ngFor="let col of cols" [matColumnDef]="col">
    <th mat-header-cell *matHeaderCellDef class="aumentar col-5" [mat-sort-header]="col">
      {{formatHeader(col)}}
    </th>
    <td mat-cell *matCellDef="let element" class="col-5">

      <ng-container *ngIf="col === 'actions'; else elseTemplate">

          <ng-container matColumnDef="actions" stickyEnd>

            <button mat-icon-button matTooltip="Abrir" (click)="open('EDIT', element)">
              <mat-icon>folder_open</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Deletar" (click)="delete(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>

      </ng-container>
      <ng-template #elseTemplate>
        {{element[col]}}
      </ng-template>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="cols"></tr>
  <tr mat-row *matRowDef="let element; columns: cols;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
</table>
<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<button mat-mini-fab (click)="open('ADD', null)" class="mat-fab-bottom-right" color="primary">
  <mat-icon>add</mat-icon>
</button>
