import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Checkin } from 'src/app/checkin/checkin.interface';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CheckinService } from 'src/app/checkin/shared/checkin.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-modal-direcionamento',
  templateUrl: './modal-direcionamento.component.html',
  styleUrls: ['./modal-direcionamento.component.scss']
})
export class ModalDirecionamentoComponent implements OnInit {

  search: string;
  pageSize: number;

  dataSource = new MatTableDataSource<Checkin>();

  breakpoints = Breakpoints;
  displayedColumns: string[];
  operations: any = [];

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:true}) sort: MatSort;

  constructor(
    public checkinService: CheckinService,
    public dialogRef: MatDialogRef<any>,
    public supportDataService: SupportDataService,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }

  ngOnInit() {
    this.loadCheckins();
    this.getOperations();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  loadCheckins() {
    this.dataSource.sort = this.sort;
    this.dataSource.sort.sort(<MatSortable>{
      id: 'id',
      start: 'desc'
    }
    );

    // If the wait changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            status: 'waiting',
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            with: 'carrier',
          };

          if (this.search) {
            Object.assign(options, {
              search: this.search,
            });
          }
          return this.checkinService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          response.data.forEach(function (item: any) {
            item.status = {
              name: 'Não direcionado',
              code: 4,
              type: 'shipment',
            };
          });

          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        }
        )
      ).subscribe(data => {

        this.dataSource.data = data;
      });
  }

  getOperations() {
    this.supportDataService.operation()
      .subscribe({
        next: (r: any) => {
          this.operations = r.data.resource;
        }, error: (e: any) => {
          console.log('erro');
        }
      });
  }

  nameOfOperation(id: number) {
    if (id) {
      const op = this.operations.filter((item: any) => {
        return item.id === id;
      });
      if (op.length > 0)
        return op[0].name;
      else
        return ""
    }
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset) || this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      this.displayedColumns = ['driver', 'carrier', 'operation', 'status'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      this.displayedColumns = ['driver', 'carrier', 'cnh', 'operation'];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.displayedColumns = ['driver', 'carrier', 'cnh', 'operation', 'entry'];
    }
  }

}
