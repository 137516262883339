<h2 class="mat-align-center" mat-dialog-title>
  {{
    data.action === "EDIT"
      ? ("Business_model.edit" | translate: { Default: "Editar" })
      : ("Business_model.add" | translate: { Default: "Adicionar" })
  }}
  {{ "Business_model.group" | translate: { Default: "Grupo" } }}
</h2>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div>
      <mat-form-field class="firstInput" appearance="fill">
        <mat-label>{{
          "Business_model.group_name" | translate: { Default: "Nome do Grupo" }
        }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <!-- <mat-form-field class="firstInput" appearance="fill">
        <mat-label>Associar com Tipo de Parceiro</mat-label>
        <mat-select formControlName="bp_type_id">
          <mat-option *ngFor="let bptype of bpTypeList" [value]="bptype.id">
            {{ bptype.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
    <div class="box">
      <mat-form-field appearance="fill" class="secondInput">
        <mat-label>{{
          "Business_model.module" | translate: { Default: "Módulo" }
        }}</mat-label>
        <mat-select formControlName="module" multiple>
          <mat-option *ngFor="let item of pageModules" [value]="item">
            {{
              "" + item.resource?.translation
                | translate: { Default: item.name }
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-stroked-button color="primary" (click)="addPage()">
        {{ "Business_model.add" | translate: { Default: "Adicionar" } }}
        <mat-icon style="font-size: 19px">add_box</mat-icon>
      </button>
    </div>
    <mat-card>
      <span
        class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
        *ngFor="let item of modules; index as idx"
      >
        {{ item.name }}
        <span class="material-icons tkg-chips-icon" (click)="removePage(idx)">
          cancel
        </span>
      </span>
    </mat-card>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">
    {{ "Business_model.cancel" | translate: { Default: "Cancelar" } }}
  </button>
  <button
    type="submit"
    style="color: white"
    mat-raised-button
    cdkFocusInitial
    color="primary"
    [disabled]="!form.valid"
    (click)="onSubmit()"
  >
    {{ "Business_model.save" | translate: { Default: "Salvar" } }}
  </button>
</div>
