import { filter } from "rxjs";
import { FieldType } from "@ngx-formly/core";
import { debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-formly-datepicker-range",
  templateUrl: "./formly-datepicker-range.component.html",
  styleUrls: ["./formly-datepicker-range.component.scss"],
})
export class FormlyDatepickerRangeComponent
  extends FieldType<any>
  implements OnInit
{
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  private emitEvent = true;
  constructor() {
    super();
  }
  ngOnInit(): void {
    this.range.valueChanges
    .pipe(
      debounceTime(1000),
        filter(() =>{
          if(!this.emitEvent){
            this.emitEvent = true;
            return false;
          }
          const start = this.range.get("start")?.value;
          const end = this.range.get("end")?.value;
          return Boolean(start && end);
        })
      )
      .subscribe(() =>{
        this.formControl.setValue(this.range.getRawValue());
      });

    const [start, end] = this.field.defaultValue || [null, null];

    if (start && end) {
      this.range.get("start")?.patchValue(start, { emitEvent: false });
      this.range.get("end")?.patchValue(end, { emitEvent: false });
    }

    this.formControl.valueChanges.subscribe((value:any)=>{
      this.emitEvent = false;
      this.range.get('start')?.setValue(value?.start, { emitEvent: false });
      this.range.get('end')?.setValue(value?.end, { emitEvent: false });
    });
  }
}
