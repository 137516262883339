import { DialogAlertComponent } from './../../shared/dialog-alert/dialog-alert.component';
import { ScheduleDinamicFormDialogComponent } from './../schedule-dinamic-form-dialog/schedule-dinamic-form-dialog.component';
import { AgendamentoService } from './../../multiagendamento/shared/agendamento.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from 'src/app/events/upload-dialog/upload-dialog.component';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {
  @ViewChild('schedule') schedule!: ElementRef;
  dataGrid!:any[][];
  timeSlots!: any[];
  scheduleRows!: any[];
  scheduleCount: any = {};
  tzOffset!: string;
  form = this.formBuilder.group({
    day: [new Date(), [Validators.required]],
    department_id: [],
    operation_id: new UntypedFormControl({value: null, disabled: true}),
    suboperation_id: new UntypedFormControl({value: null, disabled: true}),
    status_id: [],
    offset: this.getTimeZone()
  });
  operations: any;
  cellTemplate!: any[];
  status: any;
  allSuboperations: any;
  suboperations: any;
  departments: any;
  showDepartmentsSelec: string = 'hide-department';
  go2slotActive: number = 0;
  account: any;
  config_scheduling: any;

constructor(
  private dialog: MatDialog,
  private formBuilder: UntypedFormBuilder,
  private supportDataService: SupportDataService,
  private agendamentoService: AgendamentoService
) {}

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.config_scheduling = this.account.system_client.resource.environment.config_scheduling;

    if(this.account.department_id.length == 1) {
      this.form.controls['operation_id'].enable();
    }

    this.getDepartments();
    this.getOperations();
    this.getSuboperations();
    this.getStatus();
    this.getCellTemplate();
    this.getScheduleData();
  }

  transformScheduleData() {
    // Processa o resultado da API e joga os agendamentos
    // no mesmo horário para linhas diferentes
    const newRows = this.createRowsFromData(this.scheduleRows);

    // Cria o grid com os dados para serem exibidos na tabela
    this.dataGrid = this.createDataGrid(newRows, this.timeSlots);

    // Conta agendamentos por slot
    this.countAmountOfScheduleInSlot(this.dataGrid);
  }

  // Faz o merge dos slots do dia com os registros gravados,
  // gerando um grid contendo slots vazios e slots agendados
  createDataGrid(rows: any, columns: any) {
    let grid: any[] = [];
    if(columns.length > 0) {
      rows.forEach((element: any) => {
        let newColumns = [...columns];
        element.forEach((el: any) => {
          const criteria = (i: any) => i.slot == el.slot;
          const idx = newColumns.findIndex(criteria)
          newColumns[idx] = el;
        });
        grid.push(newColumns)
      });
    }
    return grid;
  }

  // Transforma a listagem de agendamentos recebido da API, separando em linhas os
  // agendamentos com slots iguais
  createRowsFromData(data: any[]) {
    if(data.length > 0) {
      let rowData: any[] = [[]];
      let rowCtrl: any[] = [[]];
      let tempResult: any = {}

      for(let { slot } of data)
      tempResult[slot] = {
          slot,
          count: tempResult[slot] ? tempResult[slot].count + 1 : 1
      }

      let result = Object.values(tempResult)
      let amountOfRows = Math.max.apply(Math, result.map(function(o: any) { return o.count; }))
      let emptyArray = new Array(amountOfRows);

      for (let i = 0; i < emptyArray.length; ++i) {
        rowCtrl[i] = [];
        rowData[i] = [];
      }

      data.forEach((item: any, idx: number) => {
        for (let i = 0; i < emptyArray.length; ++i) {
          if(!rowCtrl[i].includes(item.slot)) {
            rowCtrl[i].push(item.slot);
            rowData[i].push(item);
            break
          }
        }
      })
      return rowData;
    } else {
      return [];
    }

  }

  // Cria objeto contendo a quantidade de agendamentos por slot
  countAmountOfScheduleInSlot(grid: any) {
    // Itera o grid
    // Itera a linha por cada horário
    // Checa se já existe o horário no objeto this.scheduleCount
    // se não existe, cria a chave de horário com valor 1
    // se existe, soma 1 na chave
    grid.forEach((row: any) => {
      row.forEach((cell: any) => {
        if(cell.slot in this.scheduleCount) {
          if(cell.data_ctrl) {
            this.scheduleCount[cell.slot] = this.scheduleCount[cell.slot] + 1;
          }
        } else {
          if(cell.data_ctrl) {
            this.scheduleCount[cell.slot] = 1
          }
        }
      });
    });
  }

  // Retorna a quantidade de agendamento do slot
  amountOfScheduleInSlot(slot: string) {
    if(slot in this.scheduleCount) {
      return this.scheduleCount[slot];
    } else {
      return false;
    }
  }

  // Cria novo agendamento
  addSchedule(slot: any) {

    if(this.config_scheduling && this.config_scheduling.create == true){
      const dialogRef = this.dialog.open(ScheduleDinamicFormDialogComponent, {
        width: "900px",
        data: {
          dialogAction: 'Novo',
          slot: slot,
          schedule: {id: null}
        },
      });

      dialogRef.afterClosed().subscribe((statusResult?:any) => {
        //Se status for new_code_generated, abrir modal informando o usuário
        //Que o agendamento foi criado com um código diferente do informado
        if(statusResult.status && statusResult.status == 'new_code_generated'){
          this.dialog.open(DialogAlertComponent,{
            data: {
              title:"Agendamento criado",
              message: (statusResult.message) || "Agendamento criado, código alterado"
            }
          }).afterClosed().subscribe(()=>{
            this.getScheduleData();
          })
        } else {
            this.getScheduleData();
        }
      });
    }
  }

  // Edita um agendamento
  editShedule(schedule: any) {
    const dialogRef = this.dialog.open(ScheduleDinamicFormDialogComponent, {
      width: "900px",
      data: {
        dialogAction: 'Editar',
        slot: {},
        schedule: schedule
      },
    });

    dialogRef.afterClosed().subscribe((statusResult?:any) => {
      //Se status for new_code_generated, abrir modal informando o usuário
      //Que o agendamento foi editado com um código diferente do informado
      if(statusResult && statusResult.status == 'new_code_generated'){
        this.dialog.open(DialogAlertComponent,{
          data: {
            title:"Agendamento edição",
            message: (statusResult.message) || "Agendamento editado, código alterado"
          }
        }).afterClosed().subscribe(()=>{
          this.getScheduleData();
        })
      } else {
          this.getScheduleData();
      }
    });
  }

  getOperations() {
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      const allOperations = r.data.resource;
      this.operations = allOperations.filter((item: any) => item.use_in_scheduling);

      if(this.account.department_id.length == 1) {
        this.setOperations(this.account.department_id[0]);
      }
    });
  }

  getSuboperations() {
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.allSuboperations = r.data.resource;
    });
  }

  getDepartments(){
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departments = r.data.resource
      this.departments = this.departments.filter((item: any) => {
        return this.account.department_id.indexOf(item.id) > -1;
      })
      if(this.departments.length === 1) {
        this.form.patchValue({department_id: this.departments[0].id});
      } else {
        this.showDepartmentsSelec = 'show-department';
      }
    });
  }

  setOperations(department_id: number) {
    this.operations = this.operations.filter((item: any) => item.department_id === department_id);
  }

  setSuboperations(operation_id: number) {
    this.suboperations = this.allSuboperations.filter((item: any) => item.operation_id === operation_id);
  }

  getStatus() {
    this.supportDataService.generic({name: 'scheduling_status'}).subscribe((r: any) => {
      this.status = r.data.resource;
    });
  }

  // Busca os slots
  getTimeSlots() {
    const options = {...this.form.value}
    options.day = format(options.day, 'yyyy-MM-dd')
    this.agendamentoService.multi_time_slots(options).subscribe((res: any) => {
      this.timeSlots = res;
      this.transformScheduleData();
    }, (error) => {
      console.log('error', error);
    });
  }

  // Busca o modelo de dados a ser exibido nas células
  getCellTemplate() {
    this.supportDataService.generic({name: 'scheduling_cell_template'}).subscribe((r: any) => {
      this.cellTemplate = r.data.resource;
    });
  }

  // Filtra o resultado da API de agendamento por dia.
  // Recebe dia atual como
  getScheduleData() {
    this.scheduleCount = {};
    const options = {...this.form.value}
    options.day = format(options.day, 'yyyy-MM-dd')
    this.agendamentoService.newScheduleIndex(options).subscribe((r: any) => {
      this.scheduleRows = r;
      this.getTimeSlots();
    });
  }

  setFilterDepartment(id: number|null) {
    if(id) {
      this.setOperations(id);
      this.form.controls['operation_id'].enable();
    } else {
      this.form.patchValue({suboperation_id: null});
      this.form.controls['operation_id'].disable();
    }
    this.form.patchValue({operation_id: null});
    this.getScheduleData()
  }

  setFilterOperation(id: number|null) {
    if(id) {
      this.form.patchValue({suboperation_id: null});
      this.setSuboperations(id);
      this.form.controls['suboperation_id'].enable();
      this.getScheduleData();
    } else {
      this.form.patchValue({operation_id: null});
      this.form.patchValue({suboperation_id: null});
      this.form.controls['suboperation_id'].disable();
      this.getScheduleData();
    }
  }

  setFilterSuboperation() {
    this.getScheduleData()
  }

  setFilterStatus() {
    this.getScheduleData()
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  go2Anchor(idx: number) {
    this.go2slotActive = idx;
    this.schedule.nativeElement.focus();
    document.querySelector('#slot_'+idx)!.scrollIntoView();
  }

  uploadDialog() {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: "900px",
      data: {fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getScheduleData();
    });
  }
}
