<h1 class="mat-align-center" mat-dialog-title>Defina o Local para o motorista
    <p><br><b>{{data.item.driver_name}}</b><br></p>
  <!-- <small *ngIf="data?.item.resource.dock_space_id">Direcionado para a doca {{data.item.resource.dock_space_id}}</small> -->
</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-radio-group aria-label="Selecione o tipo de local" formControlName="directed_to" class="location-radio"
      required>
       <mat-radio-button value="park" (click)="resetForm(2)">Pátio</mat-radio-button>
      <mat-radio-button value="dock" (click)="resetForm(1)">Docas</mat-radio-button>
    </mat-radio-group>
  </form>

  <div class="dialog-message mat-align-center" *ngIf="data.action === 'denied'">Confirme a Não Liberação<br> de
    entrada do motorista <br><b>{{data.item.driver_name}}?</b></div>
  <form [formGroup]="form1" *ngIf="form.value.directed_to === 'park'">
    <mat-form-field *ngIf="this.user.system_client.resource.environment.docks_shared === false || this.user.department_id.length > 1">
      <mat-select class="form-tipo-vaga" formControlName="department_id" placeholder="Area" required (selectionChange)="filterParkingSpace($event.value)">
        <mat-option *ngFor="let item of departments" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select class="form-tipo-vaga" formControlName="dock_space_id" placeholder="Tipo de vaga" required
        >
        <mat-option *ngFor="let item of parkingSpacesFiltered" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field>
      <mat-select class="form-tipo-vaga" formControlName="operation" placeholder="Operação" disableOptionCentering
        required>
        <mat-option *ngFor="let item of operationType; index as i" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- ToDo - Mostrar o sid ao invés de external_event_id quando for inbound -->
    <mat-form-field *ngIf="form1.get('external_event_id')?.value > 0">
      <input matInput placeholder="ID/Shipment" formControlName="external_event_id">
    </mat-form-field>
  </form>

  <form [formGroup]="form2" *ngIf="form.value.directed_to === 'dock'">

    <mat-form-field *ngIf="this.user.system_client.resource.environment.docks_shared === false || this.user.department_id.length > 1">
      <mat-select class="form-tipo-vaga" formControlName="department_id" placeholder="Area" required (selectionChange)="filterDockSpace($event.value)">
        <mat-option *ngFor="let item of departments" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select class="form-tipo-vaga" formControlName="dock_space_id" placeholder="Docas" required>
        <mat-option *ngFor="let item of dockSpacesFiltered" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field>
      <mat-select class="form-tipo-vaga" formControlName="operation" placeholder="Operação" disableOptionCentering
        required>
        <mat-option *ngFor="let item of operationType; index as i" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field *ngIf="form1.get('external_event_id')?.value > 0">
      <input matInput placeholder="ID/Shipment" formControlName="external_event_id">
    </mat-form-field>
  </form>
</div>


<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">Cancelar</button>
  <button type="submit" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form1.valid"
    *ngIf="form.value.directed_to === 'park'" (click)="onSubmit()">Salvar</button>
  <button type="submit" mat-raised-button cdkFocusInitial color="primary" style="color: white;" [disabled]="!form2.valid"
    *ngIf="form.value.directed_to === 'dock'" (click)="onSubmit()">Salvar</button>
</div>
