import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterValue'
})
export class FilterValuePipe implements PipeTransform {
  transform(list: any[], field:string, text: string): any {
    if(typeof(text) == "string") {
      return list.filter(item =>
        item[field]?.toLowerCase().includes(text?.toLowerCase())
      );
    }
    return list;
  }
}
