import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookerService {
  envData: any;

  constructor(
    private http: HttpClient
  ) {
    this.envData = JSON.parse(localStorage.getItem("account") || '{}');
  }

  embedLooker(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/embedLooker`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error"))
    );
  }
}
