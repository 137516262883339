<mat-expansion-panel>
  <mat-expansion-panel-header>
    <div class="tkg-filter-header"></div>
    <span class="material-icons tkg-filter"> filter_list </span>
    <span class="tkg-filter-title">Adicionar Filtro</span>
  </mat-expansion-panel-header>
  <div
    class="container"
    fxLayout
    fxLayout.xs="column"
    fxLayoutAlign="rigth"
    fxLayoutGap="20px"
  >
    <form [formGroup]="form" novalidate>
      <mat-form-field>
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="filter_type">
          <mat-option *ngFor="let filter of filters" [value]="filter.id">
            {{ filter.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="(form.value.filter_type == 14 || form.value.filter_type == 6) && this.user.department_id.length > 1">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="department" placeholder="Área"  (selectionChange)="loadOperations($event.value.id)">
          <mat-option>
            <ngx-mat-select-search
              [formControl]="departmentFilter"
              placeholderLabel="Buscar área"
              noEntriesFoundLabel="Nenhum resultado encontrado"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of departments" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="form.value.filter_type == 1">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="driver_id" placeholder="Motoristas">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching" [formControl]="driverFilter"
              placeholderLabel="Buscar motoristas" noEntriesFoundLabel="Nenhum resultado encontrado"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of drivers" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 2">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="carrier_id" placeholder="Trasportadora">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching" [formControl]="carrierFilter"
              placeholderLabel="Buscar transportadora"
              noEntriesFoundLabel="Nenhum resultado encontrado"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of carriers" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 6">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="operation" placeholder="Operação" [disabled]="!this.form.value.department">
          <mat-option>
            <ngx-mat-select-search
              [formControl]="operationFilter"
              placeholderLabel="Buscar operação"
              noEntriesFoundLabel="Nenhum resultado encontrado"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of operations" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 7">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="dock_space" placeholder="Doca">
          <mat-option>
            <ngx-mat-select-search
              [formControl]="dockFilter"
              placeholderLabel="Buscar docas"
              noEntriesFoundLabel="Nenhum resultado encontrado"
            ></ngx-mat-select-search>
        </mat-option>
          <mat-option *ngFor="let item of docks" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 11">
        <mat-label>Tipo de filtro</mat-label>
        <mat-select formControlName="sys_user" placeholder="Usuário final">
          <mat-option>
            <ngx-mat-select-search
              [formControl]="userFilter"
              placeholderLabel="Buscar usuário"
              noEntriesFoundLabel="Nenhum resultado encontrado"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of users" [value]="item">
            {{ item.full_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 12">
        <mat-label>Dia</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="day" />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 13">
        <input
          matInput
          [matDatepicker]="picker1"
          placeholder="Data inicial"
          formControlName="dt_start"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="form.value.filter_type == 13">
        <input
          matInput
          [matDatepicker]="picker2"
          placeholder="Data final"
          formControlName="dt_end"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="form.value.filter_type == 15">
        <mat-label>Tipo de filtro</mat-label>
          <input style="text-transform: uppercase;" autocomplete="off" minlength="2" maxlength="7" formControlName="boards" placeholder="Placas" matInput pattern="[\w]{2,}" />
            <mat-error *ngIf="form.get('boards')?.errors">
      Informe parte ou a placa completa. (De 2 a 7 caracteres).
            </mat-error>
      </mat-form-field>

      <button mat-button color="primary" (click)="addFilter()">
        <span class="material-icons"> add </span>
        Adicionar
      </button>
    </form>
  </div>
</mat-expansion-panel>
<br />
<mat-card>
  <span
    class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
    *ngFor="let item of active_filters; index as idx"
  >
    <b class="tkg-chips-filter-name">{{ item.filter }}</b
    >{{ item.value }}
    <span
      class="material-icons tkg-chips-icon"
      (click)="deleteFilter(idx, item.altName)"
    >
      cancel
    </span>
  </span>
</mat-card>
<br />

<div class="box-container example-container mat-elevation-z4">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="report-table mat-elevation-z8"
  >
    <!-- Loreal -->
    <div *ngIf="system_client_id == 3 || system_client_id == 23">
      <ng-container matColumnDef="driver" sticky>
        <th mat-header-cell *matHeaderCellDef class="col-8">Motorista</th>
        <td mat-cell *matCellDef="let element" class="col-8">
          {{ element.driver }}
        </td>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef class="col-5">Transportadora</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.carrier }}
        </td>
      </ng-container>

      <ng-container matColumnDef="board-horse">
        <th mat-header-cell *matHeaderCellDef class="col-5">Placa Cavalo</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.board_horse }}
        </td>
      </ng-container>

      <ng-container matColumnDef="board-cart">
        <th mat-header-cell *matHeaderCellDef class="col-5">Placa Carreta</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.board_cart ? element.board_cart : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operation">
        <th mat-header-cell *matHeaderCellDef class="col-5">Operação</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.operation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="suboperation" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Suboperação</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.suboperation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="col-5">Status</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.status }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="pendency_opentech"
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Pend. Opentech</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.pendency_opentech }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dock">
        <th mat-header-cell *matHeaderCellDef class="col-5">Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.dock }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lacre" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Lacre</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.lacre }}
        </td>
      </ng-container>

      <ng-container matColumnDef="appointment" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Appointment</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.appointment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checkin_mht" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Checkin MHT</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checkin_mht }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dt" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">DT</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.dt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="id" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">ID</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="entry" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="checkin"
        stickyEnd
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checkin }}
        </td>
      </ng-container>

      <ng-container matColumnDef="exit">
        <th mat-header-cell *matHeaderCellDef class="col-5">Saí­da</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finished }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting">
        <th mat-header-cell *matHeaderCellDef class="col-5">Aguardando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="waiting_direction"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Ag. Direcionamento
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting_direction }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="validated_document"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Doc. Validada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.validated_document }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="invalidated_document"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Doc. Invalidada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.invalidated_document }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="exception_received"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Recebido com Exceção
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.exception_received }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ot_billed" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">O.T. Faturada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ot_billed }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ot_problem" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">O.T. Problema</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ot_problem }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="confirmed_entry"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Entrada Confirmada
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.confirmed_entry }}
        </td>
      </ng-container>
      <ng-container matColumnDef="denied_entry" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada Negada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.denied_entry }}
        </td>
      </ng-container>
      <ng-container matColumnDef="on_park" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">No Pátio</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_park }}
        </td>
      </ng-container>
      <ng-container matColumnDef="on_dock" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Na Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_dock }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loading" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loading }}
        </td>
      </ng-container>
      <ng-container matColumnDef="unloading" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Descarregando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloading }}
        </td>
      </ng-container>
      <ng-container matColumnDef="enlonamento" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Enlonamento</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.enlonamento }}
        </td>
      </ng-container>
      <ng-container matColumnDef="desenlonamento" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Desenlonamento</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.desenlonamento }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="finish_desenlonamento"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Desenlonamento Finalizado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finish_desenlonamento }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="document_received"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Documentação Recebida
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.document_received }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ready_to_leave" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Saíndo do Armazém
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ready_to_leave }}
        </td>
      </ng-container>
      <ng-container matColumnDef="exit_release" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Saída Liberada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.exit_release }}
        </td>
      </ng-container>
      <ng-container matColumnDef="directed" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Direcionado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.directed }}
        </td>
      </ng-container>

      <ng-container matColumnDef="parked" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Estacionado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_park }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_dock" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_doc }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loaded" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loaded }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discharged" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.discharged }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="fetched_invoice"
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Notas Prontas</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.fetched_invoice }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="invoice_delivered"
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Notas retiradas</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.invoice_delivered }}
        </td>
      </ng-container>
      <ng-container matColumnDef="finished" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Finalizado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finished }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checkin_closed" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Finalizado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finished }}
        </td>
      </ng-container>
      <ng-container matColumnDef="last_user" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Usuário final</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finish_created_by }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="journey_duration"
        stickyEnd
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Tempo no CD</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.journey_duration }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="department"
        stickyEnd
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Planta</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.department }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ot" stickyEnd *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">OT</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ot ? element.ot : "" }}
        </td>
      </ng-container>
    </div>

    <!-- Braskem -->
    <div *ngIf="[5, 109].includes(+system_client_id)">
      <ng-container matColumnDef="driver" sticky>
        <th mat-header-cell *matHeaderCellDef class="col-8">Motorista</th>
        <td mat-cell *matCellDef="let element" class="col-8">
          {{ element.driver }}
        </td>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef class="col-5">Transportadora</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.carrier }}
        </td>
      </ng-container>

      <ng-container matColumnDef="board-horse">
        <th mat-header-cell *matHeaderCellDef class="col-5">Placa Cavalo</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.board_horse }}
        </td>
      </ng-container>

      <ng-container matColumnDef="board-cart">
        <th mat-header-cell *matHeaderCellDef class="col-5">Placa Carreta</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.board_cart ? element.board_cart : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operation">
        <th mat-header-cell *matHeaderCellDef class="col-5">Operação</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.operation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="suboperation" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Suboperação</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.suboperation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="col-5">Status</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.status }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="pendency_opentech"
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Pend. Opentech</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.pendency_opentech }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dock">
        <th mat-header-cell *matHeaderCellDef class="col-5">Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.dock }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lacre" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Lacre</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.lacre }}
        </td>
      </ng-container>

      <ng-container matColumnDef="appointment" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Appointment</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.appointment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checkin_mht" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Checkin MHT</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checkin_mht }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dt" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">DT</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.dt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="id" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">ID</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="entry" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="checkin"
        stickyEnd
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checkin }}
        </td>
      </ng-container>

      <ng-container matColumnDef="exit">
        <th mat-header-cell *matHeaderCellDef class="col-5">Saí­da</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finished }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting">
        <th mat-header-cell *matHeaderCellDef class="col-5">Aguardando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="waiting_direction"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Ag. Direcionamento
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting_direction }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="direction_problem"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Prob. ao Direcionar
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.direction_problem }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="direction_problem_list"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Prob. ao Direcionar
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{  showExceptions('direction_problem', element.direction_problem_list, element.other_direction_problem_list) }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="validated_document"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Doc. Validada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.validated_document }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="invalidated_document"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Doc. Invalidada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.invalidated_document }}
        </td>
      </ng-container>
      <ng-container
      matColumnDef="invalidated_documentation_list"
      *ngIf="[5, 109].includes(+system_client_id)"
    >
      <th mat-header-cell *matHeaderCellDef class="col-5">Lista Doc. Invalidada</th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ showExceptions('invalidated_documentation', element.invalidated_documentation_list, element.other_invalidated_documentation_list) }}
      </td>
    </ng-container>
      <ng-container
        matColumnDef="exception_received"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Recebido com Exceção
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.exception_received }}
        </td>
      </ng-container>
      <ng-container
      matColumnDef="exception_received_list"
      *ngIf="[5, 109].includes(+system_client_id)"
    >
      <th mat-header-cell *matHeaderCellDef class="col-5">
        Lista Recebido com Exceção
      </th>
      <td mat-cell *matCellDef="let element" class="col-5">
        {{ showExceptions('exception_received', element.exception_received_list, element.other_exception_received_list) }}
      </td>
    </ng-container>
      <ng-container matColumnDef="ot_billed" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">O.T. Faturada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ot_billed }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ot_problem" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">O.T. Problema</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ot_problem }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ot_problem_list" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Lista O.T. Problemas</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ showExceptions('ot_problem', element.ot_problem_list, element.other_ot_problem_list) }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="confirmed_entry"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Entrada Confirmada
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.confirmed_entry }}
        </td>
      </ng-container>
      <ng-container matColumnDef="denied_entry" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada Negada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.denied_entry }}
        </td>
      </ng-container>
      <ng-container matColumnDef="on_park" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">No Pátio</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_park }}
        </td>
      </ng-container>
      <ng-container matColumnDef="on_dock" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Na Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_dock }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loading" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loading }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loading_problem_list" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Lista Probs. Carregamento</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ showExceptions('loading_problem', element.loading_problem_list, element.other_loading_problem_list) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="unloading" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Descarregando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloading }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loading_denied" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Carregamento Negado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loading_denied }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loading_denied_list" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Lista Carr. Negado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ showExceptions('loading_denied', element.loading_denied_list , element.other_loading_denied_list) }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="unloading_denied"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Descarregamento Negado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloading_denied }}
        </td>
      </ng-container>
      <ng-container matColumnDef="unloading_denied_list" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Lista Descar. Negado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ showExceptions('unloading_denied', element.unloading_denied_list, element.other_unloading_denied_list) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="enlonamento" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Enlonamento</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.enlonamento }}
        </td>
      </ng-container>
      <ng-container matColumnDef="desenlonamento" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Desenlonamento</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.desenlonamento }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="finish_desenlonamento"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Desenlonamento Finalizado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finish_desenlonamento }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="document_received"
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Documentação Recebida
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.document_received }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ready_to_leave" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Saíndo do Armazém
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ready_to_leave }}
        </td>
      </ng-container>
      <ng-container matColumnDef="exit_release" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Saída Liberada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.exit_release }}
        </td>
      </ng-container>
      <ng-container matColumnDef="directed" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Direcionado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.directed }}
        </td>
      </ng-container>

      <ng-container matColumnDef="parked" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Estacionado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_park }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_dock" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_doc }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loaded" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loaded }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discharged" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.discharged }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="fetched_invoice"
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Notas Prontas</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.fetched_invoice }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="invoice_delivered"
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Notas retiradas</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.invoice_delivered }}
        </td>
      </ng-container>
      <ng-container matColumnDef="finished" *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">Finalizado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finished }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checkin_closed" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Finalizado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finished }}
        </td>
      </ng-container>
      <ng-container matColumnDef="last_user" *ngIf="system_client_id == 3 || system_client_id == 23">
        <th mat-header-cell *matHeaderCellDef class="col-5">Usuário final</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finish_created_by }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="journey_duration"
        stickyEnd
        *ngIf="system_client_id == 3 || system_client_id == 23"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Tempo no CD</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.journey_duration }}
        </td>
      </ng-container>
      <ng-container
        matColumnDef="department"
        stickyEnd
        *ngIf="[5, 109].includes(+system_client_id)"
      >
        <th mat-header-cell *matHeaderCellDef class="col-5">Planta</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.department }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ot" stickyEnd *ngIf="[5, 109].includes(+system_client_id)">
        <th mat-header-cell *matHeaderCellDef class="col-5">OT</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.ot ? element.ot : "" }}
        </td>
      </ng-container>
    </div>

    <!-- Jandira -->
    <div *ngIf="system_client_id == 8">
      <ng-container matColumnDef="driver" sticky>
        <th mat-header-cell *matHeaderCellDef class="col-8">Motorista</th>
        <td mat-cell *matCellDef="let element" class="col-8">
          {{ element.driver }}
        </td>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef class="col-5">Transportadora</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.carrier }}
        </td>
      </ng-container>

      <ng-container matColumnDef="board-horse">
        <th mat-header-cell *matHeaderCellDef class="col-5">Placa Cavalo</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.board_horse }}
        </td>
      </ng-container>

      <ng-container matColumnDef="board-cart">
        <th mat-header-cell *matHeaderCellDef class="col-5">Placa Carreta</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.board_cart ? element.board_cart : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef class="col-5">Área</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.department ? element.department : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operation">
        <th mat-header-cell *matHeaderCellDef class="col-5">Operação</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.operation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="col-5">Status</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dock">
        <th mat-header-cell *matHeaderCellDef class="col-5">Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.dock ? element.dock : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checkin">
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checkin }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Aguardando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.waiting_time }}
        </td>
      </ng-container>

      <ng-container matColumnDef="denied_entry_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Entrada Negada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.denied_entry_time ? element.denied_entry_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="denied_entry_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.denied_entry_by ? element.denied_entry_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="confirm_entry_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Entrada Confirmada
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.confirm_entry_time ? element.confirm_entry_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="confirm_entry_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.confirm_entry_by ? element.confirm_entry_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_park_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Bolsão GR</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_park_time ? element.on_park_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_park_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_park_by ? element.on_park_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_dock_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Na Doca</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_dock_time ? element.on_dock_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_dock_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.on_dock_by ? element.on_dock_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loading_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loading_time ? element.loading_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loading_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loading_by ? element.loading_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loaded_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Carregado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loaded_time ? element.loaded_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loaded_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.loaded_by ? element.loaded_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="unloading_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Descarregando</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloading_time ? element.unloading_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="unloading_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloading_by ? element.unloading_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="unloaded_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Descarregado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloaded_time ? element.unloaded_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="unloaded_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.unloaded_by ? element.unloaded_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expedition_finished_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Expedição finalizada
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{
            element.expedition_finished_time
              ? element.expedition_finished_time
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expedition_finished_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{
            element.expedition_finished_by ? element.expedition_finished_by : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="release_exit_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Saída liberada</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.release_exit_time ? element.release_exit_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="release_exit_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.release_exit_by ? element.release_exit_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checklist_ok_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Checklist ok</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checklist_ok_time ? element.checklist_ok_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checklist_ok_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.checklist_ok_by ? element.checklist_ok_by : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checklist_reprovado_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">
          Checklist Reprovado
        </th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{
            element.checklist_reprovado_time
              ? element.checklist_reprovado_time
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="checklist_reprovado_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{
            element.checklist_reprovado_by ? element.checklist_reprovado_by : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="finish_time">
        <th mat-header-cell *matHeaderCellDef class="col-5">Finalizado</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finish_time ? element.finish_time : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="finish_by">
        <th mat-header-cell *matHeaderCellDef class="col-5">Feito Por</th>
        <td mat-cell *matCellDef="let element" class="col-5">
          {{ element.finish_by ? element.finish_by : "" }}
        </td>
      </ng-container>
    </div>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="box-container mat-elevation-z4">
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
