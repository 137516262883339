import { PlanningService } from './../../shared/planning.service';
import { Router } from '@angular/router';
import { DynamicDialogComponent } from 'src/app/shared/dynamic-dialog/dynamic-dialog.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { UntypedFormControl } from '@angular/forms';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { merge, of as observableOf, Subscription } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
} from "rxjs/operators";
import { SearchService } from 'src/app/shared/search.service';
import { Search } from 'src/app/shared/search';
import { RefreshService } from 'src/app/shared/refresh.service';
import { AccountService } from 'src/app/account/shared/account.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {
  searchWatcher: Subscription;
  refreshWatcher: Subscription;
  accountWatcher: Subscription;
  pageSize = 10;
  search: string;
  actualPage: string;
  expandedElement:any = undefined;
  envData: any;
  showFabMenu = true;
  showListEmpty = true;
  supportData: any;
  fabMenu: any;
  columns: any;
  cols:string[] = [];
  rows = [];
  listAction = [];
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  // private ELEMENT_DATA: any[] = [];
  displayedColumns: string[] = [];
  dataSource: any[] = [];
  dataSourceAll = new MatTableDataSource<any>();
  account: any;
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private accountService: AccountService,
    private planningService: PlanningService,
    private supportDataService: SupportDataService,
    private refreshService: RefreshService,
    private searchService: SearchService,

  ) { }

  ngOnInit() {
    this.actualPage = this.router.url.split('/')[2];
    this.envData = JSON.parse(localStorage.getItem("account") || "{}");
    // this.fabMenu = this.envData.system_client.resource.environment.pages[this.actualPage]['fab_menu'];
    this.getActions();
    this.getColumns();
    this.getPlannings();

    this.sort.sort(<MatSortable>{
      id: "place_places.created_at",
      start: "desc",
    });
    this.accountService.identity().then((identity) => {
      if (identity) {
        this.account = identity;
      }
    });

    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: this.sort.active ? this.sort.active : "name",
            sortedBy: this.sort.direction ? this.sort.direction : "DESC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            search: `${this.searchFilter.value}` || "",
          };
          return this.planningService.index(options);
        }),
        map((response: Response | any) => {
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        console.log("atualiza no subcribe");
        this.dataSource = data;
        // this.dataSourceAll.data = data;
      });

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.searchWatcher = this.searchService.value.subscribe(
      (search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      }
    );

    this.refreshWatcher = this.refreshService.refresh.subscribe(
      (refresh: Event) => {
        this.reload({ refresh: refresh });
      }
    );

    this.accountWatcher = this.accountService.event.subscribe((event) => {
      if(event?.data instanceof Object){
        const index = this.dataSource.findIndex(
          (item) => item.id === event.data.id
        );
        if (index !== -1) {
          Object.assign(this.dataSource[index], event.data);
        }
      }
    });

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active ? this.sort.active : "first_name",
            sortedBy: this.sort.direction ? this.sort.direction : "ASC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            page: this.paginator.pageIndex + 1,
          };
          return this.planningService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => response.data)
        )
        .pipe(
          catchError(() => {
            return observableOf([])}
          )
        )
      .subscribe((data) => {
        this.dataSource = data;
        this.dataSourceAll.data = data;
      });
  }


  clearFilter(event?:any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }

  open(action: any, item: any) {
    console.log(action);
    const dialogRef = this.dialog.open(DynamicDialogComponent, {
      panelClass: ['dialog-medium'],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: 'add_new_route',
        module: 'checkin',
        message: action.dialogue_message,
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: null
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getPlannings();
    });
  }

  delete(data:any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir a programação ${data.name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      console.log("deletando");
      if (confirm) {
        this.planningService.destroy(data.id).subscribe((response) => {
          this.getPlannings();
          this.snackBar.open("Programação removida com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        });
      }
    });
  }


  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  getPlannings() {
    this.planningService.index({pageSize: 10}).subscribe((r: any) => {
    this.dataSource = r.data;
    this.formatData(this.dataSource);
    if(this.dataSource.length > 0) {
      this.showListEmpty = false;
    }

    });
  }

  getActions() {
    this.supportDataService.actions().subscribe((r: any) => {
      this.listAction = r.data.resource[this.actualPage];
    });
  }

  // Executa a ação cadastrada para o fab menu
  actions(click: string, action: any, item: any) {
    return (this[click as keyof this] as Function)(action, item);
  }

  formatHeader(item:any) {
    let result;

    this.columns.map((col:any) => {
      if (col.name == item) {
        result = col.label;
      } else if (item == "actions") {
        result = "Ações";
      }
    });
    return result;
  }

  prepareParams(dataObj:any, paramArray:any) {
    let params:any[] = [];
    paramArray.forEach((element:any) => {
      let paramsA = element.split(".");
      let paramValue;
      if (paramsA.length > 1) {
        // paramValue = paramsA.reduce((o, i) => o[i], dataObj);
        // Objeto com mais de 2 níveis
        /*paramValue = paramsA.reduce((o, i) => {
          if (o) {
            if (dataObj[o]) {
              return dataObj[o][i];
            }
          }
        });*/
        paramValue = paramsA.reduce((o:any, i:any, index:number, values:any) => {
          return o[i] ? o[i] : values.filter((v:any)=>v).join(".");
        }, dataObj);
      } else {
        paramValue = dataObj[element];
      }

      params.push(paramValue);
    });

    return params;
  }

  processColumns(colValue:any, column:any, data:any) {
    try{
      if (column.transform_field) {
        const params = this.prepareParams(data, column.transform_params);
        return (this[column.transform_method as keyof this] as Function)(params);
      } else {
        return colValue;
      }
    }catch(error){
      return "";
    }
  }

  getColumns() {
    this.columns =
      this.envData.system_client.resource.environment.pages[this.actualPage]["columns"];
      //Ordena os objetos do array columns em ordem crescente do parametro "cols" de cada coluna
      this.columns.sort((a:any, b:any) => a.cols - b.cols);
  }

  formatData(data:any) {

    let rows:any[] = [];
    let cols:any[] = [];

    this.columns.map((c:any) => cols.push(c.name));

    if(this.envData.system_client.resource.environment.pages[this.actualPage].action_column !== false) {
      cols.push("actions");
    }

    data.map((i:any) => {
      // console.log(i)
      let obj:any = {};
      this.columns.map((j:any) => {
        if (j) {

          // let value = _.get(i, j.key);
          let value = i[j.key];

          // let value = j.name == 'operation_id' ? this.formatOperation(_.get(i, j.key)) : _.get(i, j.key);
          // value: j.name == 'operation' ? this.formatOperation(value) : value,
          obj[j.name] = {
            value: this.processColumns(value, j, i),
            name: j.name,
            format: j.format ? j.format : null,
            type: j.type,
            label: j.label,
          };
        }
      });
      obj["actions"] = {
        value: i,
        name: "actions",
        format: null,
        type: "object",
        label: "actions",
      };
      rows.push(obj);
    });

    this.cols = cols;

    return rows;
  }

  schedule(element: any) {
    console.log(element)
  }

}


