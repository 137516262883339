<div class="tkg-title-group"><p class="tkg-title">{{ "Business_model.schedule_advance" | translate:{Default: "Antecedência do Agendamento"} }}</p><button mat-button mat-stroked-button color="primary" class="tkg-new-btn" (click)="addEdit()">Novo</button></div>
<div class="tkg-description">{{ "Messages.schedule_advance" | translate:{Default: "Defina a antecedência mínima para que um agendamento seja criado. Por exemplo, você pode definir que o agendamento de descarga deve ser realizado com antecedência mínima de 2 dias para os usuários do grupo Transportadores."} }}</div>
<table class="tkg-table">
  <thead>
    <tr>
      <th *ngIf="!hiddenElements?.department === true">{{ "Business_model.department" | translate:{Default: "Departamento"} }}<br /></th>
      <th>{{ "Business_model.operation" | translate:{Default: "Operação"} }}<br /></th>
      <th *ngIf="!hiddenElements?.suboperation === true">{{ "Business_model.suboperation" | translate:{Default: "Sub-operação"} }}<br /></th>
      <th>{{ "Business_model.advance" | translate:{Default: "Antecedência"} }}<br /></th>
      <th>{{ "Time.only_business_day" | translate:{Default: "Apenas dia útil"} }}<br /></th>
      <th>{{ "Business_model.groups" | translate:{Default: "Grupo(s)"} }}<br /></th>
      <th style="width: 80px; text-align: center;">{{ "Business_model.actions" | translate:{Default: "Ações"} }}<br /></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of antecedences">
      <td *ngIf="!hiddenElements?.department === true">{{item.department}}</td>
      <td>{{item.operation}}</td>
      <td *ngIf="!hiddenElements?.suboperation === true">{{item.suboperation}}</td>
      <td>{{item.antecedence}}</td>
      <td>{{item.workday ? ("Business_model.yes" | translate:{Default: "Sim"})  : ("Business_model.no" | translate:{Default: "Não"})}}</td>
      <td>{{showGroupName(item.groups)}}</td>
      <td>
        <button mat-icon-button color="primary" (click)="addEdit(item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="delete(item.id)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="antecedences.length === 0">
      <td colspan="100%"><p class="no-record">{{ "Messages.no_register" | translate: {Default: "Nenhum registro encontrado!"} }}</p></td>
    </tr>
  </tbody>
</table>
