<div class="tkg-grid">
  <div class="schedule-grid">
    <div id="schedule-0">
      <div class="lateral-itens">
        <p class="data">{{ selectedDate | date: "mediumDate" }}</p>
        <mat-calendar
          [selected]="selectedDate"
          [startAt]="startAt"
          [maxDate]="maxDate"
          (selectedChange)="onSelect($event)"
        >
        </mat-calendar>

        <form [formGroup]="form">
          <mat-form-field class="example-full-width" appearance="outline">
            <input
              matInput
              [placeholder]="
                'Business_model.search' | translate: { Default: 'Buscar' }
              "
              value=""
              formControlName="search"
            />
          </mat-form-field>

          <!-- <app-web-btn-filters
            [filterList]="filterList"
            origin="scheduling_din_filters"
            (updateFilterList)="updateFilterList($event)"
          ></app-web-btn-filters> -->
          <div class="tkg-selected-filters" *ngIf="filterList.length">
            <span
              class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
              *ngFor="let item of filterList; index as idx"
              (click)="removeFilterItem(idx)"
            >
              <span class="material-icons tkg-chips-icon"> cancel </span>
              <b class="tkg-chips-filter-name">{{ item.label }}</b>
              <span class="tkg-chips-filter-value">{{ item.value }}</span>
            </span>
          </div>
          <mat-form-field
            appearance="outline"
            *ngIf="visibleFilters.includes('time_slot')"
          >
            <mat-label>{{
              "Business_model.time" | translate: { Default: "Horário" }
            }}</mat-label>
            <mat-select formControlName="time">
              <mat-option *ngFor="let slot of timeSlots" [value]="slot.time">
                {{ slot.slot }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="visibleFilters.includes('status')"
          >
            <mat-label>{{
              "Business_model.status" | translate: { Default: "Status" }
            }}</mat-label>
            <mat-select formControlName="status_id">
              <mat-option *ngFor="let st of status" [value]="st.id">
                {{
                  st.translation || st.name | translate: { Default: st.name }
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="visibleFilters.includes('department')"
            [style.display]="
              this.account.department_id.length == 1 ? 'none' : 'show'
            "
          >
            <mat-label>{{
              "Business_model.area" | translate: { Default: "Área" }
            }}</mat-label>
            <mat-select formControlName="department_id">
              <mat-option
                *ngFor="let dp of departments"
                (click)="setFilterDepartment(dp.id)"
                [value]="dp.id"
              >
                {{
                  dp.translation || dp.name | translate: { Default: dp.name }
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="visibleFilters.includes('operation') && showOperation"
          >
            <mat-label>{{
              "Business_model.operation" | translate: { Default: "Operação" }
            }}</mat-label>
            <mat-select formControlName="operation_id">
              <mat-option
                *ngFor="let operation of operations"
                [value]="operation.id"
                (click)="setFilterOperation(operation.id)"
              >
                {{
                  operation.translation || operation.name
                    | translate
                      : {
                          Default: operation.name
                        }
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="visibleFilters.includes('suboperation') && showSubOperation"
          >
            <mat-label>{{
              "Business_model.suboperation"
                | translate: { Default: "Suboperação" }
            }}</mat-label>
            <mat-select formControlName="suboperation_id">
              <mat-option
                *ngFor="let suboperation of suboperations"
                [value]="suboperation.id"
              >
                {{
                  suboperation.translation || suboperation.name
                    | translate
                      : {
                          Default: suboperation.name
                        }
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="visibleFilters.includes('origin')"
          >
            <mat-label>{{
              "Business_model.origin" | translate: { Default: "Origem" }
            }}</mat-label>
            <mat-select formControlName="origin">
              <mat-option *ngFor="let origin of origins" [value]="origin.id">
                {{
                  origin.translation || origin.name
                    | translate: { Default: origin.name }
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="tkg-btn-clean-ft">
            <a mat-flat-button (click)="cleanFilter()">
              <mat-icon>delete</mat-icon
              >{{
                "Business_model.clean_filters"
                  | translate: { Default: "Limpar Filtros" }
              }}
            </a>
          </div>
        </form>
      </div>
    </div>

    <div id="schedule-1">
      <div class="tkg-spinner-container" *ngIf="showLoader">
        <div class="spinner-and-text">
          <mat-progress-spinner
            class="tkg-spinner"
            color="#1fd91d"
            mode="indeterminate"
            value="100"
          >
          </mat-progress-spinner>
          <div class="load-txt">
            {{
              "Business_model.loading_schedule"
                | translate: { Default: "Carregando agendamentos..." }
            }}
          </div>
        </div>
      </div>

      <div
        class="tkg-nodata-container"
        *ngIf="schedules.length == 0 && !showLoader"
      >
        <div class="txt-container">
          <div class="nodata-txt">
            {{
              "Messages.no_schedules"
                | translate
                  : {
                      Default:
                        "Sem
            agendamentos para essa data"
                    }
            }}
          </div>
          <div class="nodata-txt">
            {{
              "Messages.new_schedules"
                | translate
                  : {
                      Default:
                        "Clique no botão
            abaixo e crie um novo agendamento!"
                    }
            }}
          </div>
        </div>
      </div>
      <div class="tkg-table-container">
        <table class="tkg-table" *ngIf="schedules.length > 0">
          <thead class="table-header">
            <ng-container *ngFor="let item of scheduleColumns">
              <th>
                {{
                  item.translation || item.name
                    | translate: { Default: item.name }
                }}
              </th>
            </ng-container>
          </thead>
          <ng-container *ngFor="let data of schedules">
            <tr class="tkg-tr" (click)="edit_scheduling(data.rows[0])">
              <td [attr.rowspan]="data.rows.length" class="tkg-time">
                <div class="circle">{{ data.rows.length }}</div>
                {{ data.time_slot }}
              </td>
              <td
                style="
                  border-top: 2px solid #efefef;
                  padding-top: 8px;
                  width: 152px;
                "
              >
                <div
                  [ngClass]="{'tkg-status-plus': data.rows[0]['status_name'].length > 12, 'tkg-status': data.rows[0]['status_name'].length < 13}"
                  [ngStyle]="{ background: data.rows[0]['status_color'] }"
                  [style.border-left]="data.rows[0]?.priority_name === 'Sim' ? '14px solid #54d959' : ''"
                >
                  {{ data.rows[0]["status_name"] }}
                </div>
              </td>
              <ng-container
                *ngFor="let item of scheduleColumns; let idx = index"
              >
                <td
                  *ngIf="idx > 1"
                  style="border-top: 2px solid #efefef; padding-top: 8px"
                  class="tkg-td"
                >
                  {{ data.rows[0][item.key] }}
                </td>
              </ng-container>
            </tr>
            <ng-container *ngFor="let i of data.rows; let idx = index">
              <tr *ngIf="idx != 0" class="tkg-tr" (click)="edit_scheduling(i)">
                <td style="width: 152px" >
                  <div
                  [style.border-left]="data.rows[0]?.priority_name === 'Sim' ? '14px solid #54d959' : ''"
                    class="tkg-status"
                    [ngStyle]="{ background: i.status_color }"
                  >
                    {{ i.status_name }}
                  </div>
                </td>
                <ng-container
                  *ngFor="let col of scheduleColumns; let idx2 = index"
                >
                  <td *ngIf="idx2 > 1">{{ i[col.key] }}</td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="scheduleMenu?.length > 0 && showAddButton === true">
  <button mat-mini-fab class="mat-fab-bottom-right" [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon>
    <mat-menu class="mat-fab-menu-item" #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let item of scheduleMenu"
        (click)="actions(item.action)"
      >
        {{ item.translation || item.name | translate: { Default: item.name } }}
      </button>
    </mat-menu>
  </button>
</div>
