import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardJandiraListComponent } from './dashboard-jandira-list/dashboard-jandira-list.component';
import { DashboardJandiraRoutingModule } from './dashboard-jandira-routing.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { OfensoresDialogComponent } from './ofensores-dialog/ofensores-dialog.component';

export function chartModule(): any {
  return echarts
}

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        DashboardJandiraRoutingModule,
        SharedModule,
        NgxEchartsModule,
    ],
    declarations: [DashboardJandiraListComponent, OfensoresDialogComponent]
})
export class DashboardJandiraModule { }
