import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { UserService } from "src/app/user/shared/user.service";

@Component({
  selector: "app-schedule-limit-dinamic-dialog",
  templateUrl: "./schedule-limit-dinamic-dialog.component.html",
  styleUrls: ["./schedule-limit-dinamic-dialog.component.scss"],
})
export class ScheduleLimitDinamicDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    public dialogRef: MatDialogRef<ScheduleLimitDinamicDialogComponent>,
    public supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private userService: UserService
  ) { }

  account: any;
  departmentos: any[] = [];
  operations: any[] = [];
  suboperations: any[] = [];
  vehicletypes: any[] = [];
  groups: any[] = [];

  fields: FormlyFieldConfig[] = [];
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getFields();
  }

  getFields() {
    this.supportDataService
      .generic({ name: "scheduling_limit_dialog_fields" })
      .subscribe((r) => {
        this.fields = (r.data.resource || []).map((field: any) => 
          ({ 
            ...field, 
            defaultValue: this.data ? this.data[field.key] || null : null 
          })
        );
        this.getDepartaments();
        this.getOperations();
        this.getSuboperations();
        this.getVehicleType();
        this.getGroups();
      });
  }
  

  getGroups() {
    const options = { pageSize: 200 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
      const idx = this.fields.map((o) => o.key).indexOf("groups");
      if (idx > -1) {
        this.fields[idx].props!.options = this.groups;
      }
    });
  }
  getDepartaments() {
    this.supportDataService.generic({ name: "departments" }).subscribe((r) => {
      const idx = this.fields.map((o) => o.key).indexOf("department_id");
      if (idx > -1) {
        this.departmentos = r.data.resource;
        this.departmentos = this.departmentos.filter((item: any) => {
          return this.account.department_id.indexOf(item.id) > -1;
        });
        this.fields[idx].props!.options = this.departmentos;
        if (this.departmentos.length === 1) {
          this.fields[idx].defaultValue = this.data["department_id"]
            ? this.data["department_id"]
            : this.departmentos[0].id;
          this.fields[idx].hide = true;
        } else {
          this.fields[idx].props!.change = (field, $event) => {
            if (this.fields.map((o) => o.key).indexOf("operation_id") > -1) {
              this.setOperations(field.formControl!.value);
            }
          };
        }
      }
    });
  }
  getOperations() {
    this.supportDataService
      .generic({ name: "operation_type" })
      .subscribe((r) => {
        this.operations = r.data.resource || [];
        const idx = this.fields.map((o) => o.key).indexOf("operation_id");
        if (idx > -1) {
          this.fields[idx].props!.options = this.operations;
          this.fields[idx].props!.change = (field, $event) => {
            if (this.fields.map((o) => o.key).indexOf("suboperation_id") > -1) {
              this.setSuboperations(field.formControl!.value);
            }
          };
        }
      });
  }

  getSuboperations() {
    this.supportDataService
      .generic({ name: "suboperation_type" })
      .subscribe((r) => {
        this.suboperations = r.data.resource || [];
        const idx = this.fields.map((o) => o.key).indexOf("suboperation_id");
        if (idx > -1) {
          this.fields[idx].props!.options = this.suboperations;
          this.setSuboperations(this.data['operation_id']);
        }
      });
  }
  
  setSuboperations(operation: any) {
    const idx = this.fields.map((o) => o.key).indexOf("suboperation_id");
    if (idx > -1) {
      const suboperations = this.suboperations.filter(
        (item) => item.operation_id === operation
      );
      this.fields[idx].props!.options = suboperations || [];
      this.fields[idx].defaultValue = this.data["suboperation_id"] || null;
      this.fields[idx].hide = suboperations.length <= 0;
    }
  }
  
  getVehicleType() {
    this.supportDataService.generic({ name: "vehicle_type" }).subscribe((r) => {
      const idx = this.fields.map((o) => o.key).indexOf("vehicle_type");
      if (idx > -1) {
        this.fields[idx].props!.options = r.data.resource;
        this.vehicletypes = r.data.resource;
      }
    });
  }

  setOperations(department: any) {
    const idx = this.fields.map((o) => o.key).indexOf("operation_id");
    if (idx > -1) {
      const operations = this.operations.filter(
        (item) => item.department_id === department
      );
      this.fields[idx].props!.options = operations;
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      department_id:
        this.departmentos.length === 1
          ? this.departmentos[0].id
          : this.form.value.department_id,
      resource: {
        department_id:
          this.departmentos.length === 1
            ? this.departmentos[0].id
            : this.form.value.department_id,
        ...this.form.value,
        limit: Number(this.form.value.limit),
      },
    };

    if (options.id) {
      this.agendamentoService
        .updateSchedulingLimit(options)
        .subscribe((r: any) => {
          this.close();
        });
    } else {
      this.agendamentoService
        .createSchedulingLimit(options)
        .subscribe((r: any) => {
          this.close();
        });
    }
  }
}
