import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { SupportDataService } from '../support-data.service';
import { RegistrationService } from '../registration.service';
import { GenericService } from '../generic.service';


@Component({
  selector: 'app-exceptions-control-dialog',
  templateUrl: './exceptions-control-dialog.component.html',
  styleUrls: ['./exceptions-control-dialog.component.scss']
})
export class ExceptionsControlDialogComponent {

  exception_list: any = [];

  columnsSchema: any[] = [];
  transformData: any = {};
  transformMethods: any = {}
  displayedColumns: string[] = [];
  dataSource: any[] = [];

  form = this.formBuilder.group({
    exception_id: ["", Validators.required],
    observation: [""]
  });

  display_message = false;
  message = '';
  user: any;
  showTable = false;

  constructor(
    // private dialog: MatDialog,
    private genericService: GenericService,
    private registrationService: RegistrationService,
    private supportDataService: SupportDataService,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExceptionsControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // const stored_data = localStorage.getItem("account") || '{}';
    // this.user = JSON.parse(stored_data);
    this.getExceptionList();
    this.getData();


  }

  addValue() {

    const data = {
      checkin_id: this.data.item.id,
      ...this.form.value
    }
    this.registrationService.createExcepiton(data).subscribe((r: any) => {
      this.form.reset();
      this.getData();
    });

  }

  save() {

  }

  cancel() {
    this.dialogRef.close();
  }


  getData() {
    const options = {
      id: this.data.item.id,
      search: false,
      paginate: false,
      pageSize: 0,
      page: 1,
      orderBy: '',
      sortedBy: '',
      // ...this.adjustedFilters

    }

    this.genericService.getData('exceptions', options).subscribe((r: any) => {
      if(!r.error) {
          this.showTable = false;
          this.columnsSchema = r.schema;
          if(this.displayedColumns.length === 0 ) {
            this.displayedColumns = this.columnsSchema.map((col: any) => col.key);
            this.displayedColumns.push('action');
          }
          console.log(this.displayedColumns)
          this.dataSource = r.data;
          this.showTable = true;
      } else {
        // this.showLoadingData = false;
        // this.message = r.message;
        // this.showMessage = true;
      }

    });
  }

  getExceptionList() {
    this.supportDataService.generic({name: 'exception_list'}).subscribe((r: any) => {
      this.exception_list = r.data.resource.filter((ft: any) => ft.active === true);
    });
  }

  action(event: any) {
    if(event.type === 'update') {
      console.log('atualiza registro');
      const data = {
        id: event.element.id,
        solved: true
      }
      this.registrationService.updateException(data).subscribe((r: any) => {
        this.getData();
      });
    }
  }




}
