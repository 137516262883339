import { Moment } from "moment";
export enum ShiftActions {
  EDIT,
  ADD,
  VIEW,
}

export enum WeekDay {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const Months = [
  {value:1, name: "Janeiro"},
  {value:2, name: "Fevereiro"},
  {value:3, name: "Março"},
  {value:4, name: "Abril"},
  {value:5, name: "Maio"},
  {value:6, name: "Junho"},
  {value:7, name: "Julho"},
  {value:8, name: "Agosto"},
  {value:9, name: "Setembro"},
  {value:10, name: "Outubro"},
  {value:11, name: "Novembro"},
  {value:12, name: "Dezembro"}
];

export const WeekDayNames = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

export type Viewport = "200-500" | "500-800" | "800-1200" | "1200-over" | undefined;

export interface ShiftInterface {
  id?:number;
  name:string;
  system_client_id?:number;
  active:boolean;
  created_at:string;
  updated_at:string;
}

export interface ShiftScheduleInterface extends Pick<ShiftInterface,"id"|"system_client_id"|"active"|"created_at"|"updated_at">{
  name: "shift" | "shift_break" | "overtime" | "downtime"
  resource:{
    name: string;
    shift_shifts_id:number;
    weekday: (0|1|2|3|4|5|6)[];
    start: Date|string|Moment;
    end: Date|string|Moment;
    duration: string;
  }
}

export interface IndexShiftResponseInterface {
  total:string|number;
  per_page:string|number;
  data:ShiftInterface[];
}

export interface IndexShiftScheduleResponseInterface extends IndexShiftResponseInterface{
  data:ShiftScheduleInterface[]
}
