import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpClient
  ) { }


  index(options?: any, url?: string): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    if(!url){
      url = `${environment.apiUrl}/report-journeys`;
    }else{
      url = `${environment.apiUrl}/${url}`;
    }

    //console.log('url', url)

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  indexBraskem(options?: any): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/report-journeys-braskem`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  indexJandira(options?: any): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/report-journeys-jandira`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  eventReport(options?: any): Observable<any> {
    const httpParams = Object.assign({
      page: 1,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }, options);

    const params = new HttpParams({ fromObject: httpParams })

    const url = `${environment.apiUrl}/report-events`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

}
