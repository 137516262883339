import { Injectable } from '@angular/core';
import { Observable, tap, of, catchError } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { AccountService } from 'src/app/account/shared/account.service';

@Injectable({
  providedIn: 'root'
})
export class WssService {
  private subjectList:{[key:string]:WebSocketSubject<void>} = {}
  constructor(private accountService:AccountService) { }

  public connect(url:string): WebSocketSubject<void>|Observable<void> {
    if(!this.subjectList[url]){
      this.subjectList[url] = webSocket({url, deserializer: (msg) => {
        let data = null;
        try{
          if(msg.data?.match(/"user_id":.*"msg":.*/gi)){
            data = JSON.parse(msg.data);
            if(data.msg?.match(/((\[[^\}]{3,})?\{s*[^\}\{]{3,}?:.*\}([^\{]+\])?)/gi)){
              data.msg = JSON.parse(data.msg);
            }
          }
        }catch(ex:any){}
        return data;
      }});
    }

    return this.subjectList[url].asObservable()
    .pipe(
      tap(()=>{
        if(!this.accountService.isAuthenticated()){
          Object.keys(this.subjectList).forEach((key)=>{
            this.subjectList[key].complete();
          })
        }
    }))
    .pipe(catchError(()=>of()));

  }

}
