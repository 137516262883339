import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-input-tags-outline',
  templateUrl: './formly-input-tags-outline.component.html',
  styleUrls: ['./formly-input-tags-outline.component.scss']
})
export class FormlyInputTagsOutlineComponent extends FieldType<any> {
  tagArray:string[] = [];
  tagInput = new FormControl('');
  //Pattern em regex que verifica se a string possui colchetes
  hasBrackets = /[[\]]/m
  allowedPattern: RegExp;

  //Ativa a mensagem de erro em <mat-hint>
  //<mat-error> depende de formControl para que funcione em um input, então nesse caso é utilizado <mat-hint> para mostrar a mensagem de erro
  showMsgError = false;

  ngOnInit() {
    const pattern = this.field.props?.allowedPattern || '.*'; 
    this.allowedPattern = new RegExp(pattern);

    //Se formControl possuir valor, está sendo feita a edição dos dados
    if(this.formControl.value) {

      //Testa o value de formControl, se possuir colchetes [] é um array de tags em string por estar guardado no banco
      //Então é feita o parse e é atribuido para o tagArray
      if(/\[.*\]/.test(this.formControl.value)) {
        this.tagArray = JSON.parse(this.formControl.value);
      } else {
        //Se não possuir colchetes [] é apenas uma string, então passa ela para o tagArray
        //E o tagArray é então atribuido ao formControl
        this.tagArray.push(this.formControl.value);
        this.formControl.patchValue(this.tagArray);
      }

      this.formControl.setValue(this.tagArray, {emitEvent:false})
    }

    //Desativa ambos FormControls se parametro isEditingAllowed foi passado
    //Que sinaliza que o usuário está apenas visualizando o agendamento
    if(!this.field.props?.isEditingAllowed) {
      this.formControl.disable();
      this.tagInput.disable();
    }

  }

  //Passa o input para o tagArray
  pushToArray() {
    const value = this.tagInput.value || '';
    // Divide a string de entrada em várias tags usando o espaço como separador
    const tags = value.split(' ').filter(tag => tag.trim() !== '');

    tags.forEach(tag => {
      // Se o valor informado não possuir caracteres especiais, adiciona o valor para o tagArray e para o formControl
      if (this.allowedPattern.test(tag)) {
        this.tagArray.push(tag);
        this.formControl.patchValue(this.tagArray);
        this.showMsgError = false;
      } else {
        // Se o valor informado possuir caracteres especiais, ativa a mensagem de erro em <mat-hint>
        this.showMsgError = true;
      }
    });
    this.tagInput.setValue('', {emitEvent: false});
  }

  //Remove um item do tagArray e atualiza o formControl
  deleteTag(inputItem:string){
    this.tagArray = this.tagArray.filter(x=>x!=inputItem);
    this.formControl.patchValue(this.tagArray);
  }

}
