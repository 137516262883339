<h1 class="mat-align-left tkg-title" mat-dialog-title>Controle de Pesagem</h1>
<div class="info">
  <p><b>Motorista </b>{{ data.item.driver_name }}</p>
  <p><b>Transportadora </b>{{ data.item.carrier_name }}</p>
  <p>
    <b>Placas </b>{{ data.item.resource.board_cart }}
    {{ data.item.resource.board_horse }}
  </p>
</div>
<div class="tkg-top-bar" *ngIf="display_message">
  <p class="tkg-message">{{ message }}</p>
</div>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="tkg-row">
      <div class="tkg-col-40">
        <mat-form-field appearance="outline" class="tkg-select-100">
          <mat-label>Tipo de pesagem</mat-label>
          <mat-select formControlName="weight_type_id">
            <mat-option *ngFor="let item of weight_type" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="tkg-col-40">
        <mat-form-field class="tkg-input-100" appearance="outline">
          <mat-label>Peso (kg)</mat-label>
          <input matInput placeholder="Peso" formControlName="weight" />
        </mat-form-field>
      </div>
      <div class="tkg-col-20">
        <button
          mat-fab
          extended
          color="primary"
          *ngIf="!loadingWeight"
          (click)="balanca()"
          [disabled]="!form.value.weight_type_id"
        >
          <mat-icon
            svgIcon="weight"
            aria-hidden="false"
            aria-label="Balança"
          ></mat-icon>
          Buscar Peso
        </button>
        <button
          mat-fab
          extended
          disabled
          ngClass="spinner"
          *ngIf="loadingWeight"
        >
          <div class="tkg-loading-text">Buscando...</div>
        </button>
      </div>
      <div class="tkg-col-20">
        <button
          mat-fab
          extended
          color="primary"
          (click)="addValue2DS()"
          [disabled]="!form.valid"
        >
          <mat-icon>add</mat-icon>
          Adicionar
        </button>
      </div>
    </div>
  </form>

  <app-simple-table
    [columnsSchema]="columnsSchema"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [transformData]="transformData"
    [transformMethods]="transformMethods"
    [activeActions]="activeActions"
    (actionOutput)="actionOutput($event)"
  ></app-simple-table>
</div>

<div class="tkg-bottom-bar">
  <div class="tkg-col">
    <div class="tkg-col"><b>PESO LÍQUIDO</b> {{ summary?.net }} kg</div>
  </div>
  <div class="tkg-col"><b>DIFERENÇA</b> {{ summary?.diff }} kg</div>
</div>

<div mat-dialog-actions align="end" class="tkg-dialog-btns">
  <button type="button" mat-button color="warn" (click)="cancel()">
    Fechar
  </button>
  <button
    type="submit"
    cdkFocusInitial
    mat-raised-button
    style="color: white"
    color="primary"
    (click)="save()"
  >
    Salvar
  </button>
</div>
