<div class="top-bar-filters">
  <div class="container-filters">
    <div *ngIf="filterEnabled('carrier')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.carrier }}</mat-label>
        <mat-select [formControl]="filterCarrierSelected">
          <mat-option (click)="getFilterCarrier('')"
            >{{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar
            filtro"} }}</mat-option
          >
          <mat-divider></mat-divider>
          <mat-option
            *ngFor="let tr of transportes"
            [value]="tr.id"
            (click)="getFilterCarrier(tr.id)"
          >
            {{tr.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="((this.account.department_id?.length || 0) > 1) && filterEnabled('department')"
    >
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.department }}</mat-label>
        <mat-select
          [formControl]="filterDepartmentSelected"
          [(value)]="selectedDepartment"
        >
          <mat-option (click)="getFilterDepartment('')"
            >{{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar
            filtro"} }}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option
            *ngFor="let dp of departments"
            [value]="dp.id"
            (click)="getFilterDepartment(dp.id)"
          >
            {{dp.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterEnabled('motorista_destino')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.motorista_destino }}</mat-label>
        <mat-select [formControl]="filterDestinySelected">
          <mat-option
            >{{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar
            filtro"} }}</mat-option
          >
          <mat-divider></mat-divider>
          <mat-option *ngFor="let tr of destinyAll" [value]="tr.id">
            {{tr.name}} - {{tr["sub-area"]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterEnabled('operation')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.operation }}</mat-label>
        <mat-select
          [formControl]="filterOperationSelected"
          [(value)]="selectedOperation"
          [disabled]="!selectedDepartment && (this.account.department_id?.length || 0) > 1"
        >
          <mat-option (click)="getFilterOperation('')">
            {{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar
            filtro"} }}
          </mat-option>
          <mat-divider></mat-divider>
          <div *ngIf="selectedDepartment || showOptionsOperations">
            <mat-option
              *ngFor="let st of operations"
              [value]="st.id"
              (click)="getFilterOperation(st.id)"
            >
              {{st.name}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterEnabled('suboperation')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.suboperation }}</mat-label>
        <mat-select [formControl]="filterSubOperationSelected" (closed)="getFilterSubOperation()" [disabled]="!filterOperationSelected" multiple>
          <mat-option class="tkg-clear-filter-option" (click)="clearMultiselectFilter('suboperation')">
            {{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar filtro"} }}
          </mat-option>
          <mat-divider></mat-divider>
          <div *ngIf="selectedOperation || showOptionsSuboperations">
            <mat-option *ngFor="let st of suboperations" [value]="st.id" (click)="setSubOperationFilterOptions(st.id)">
              {{st.name}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterEnabled('dock_space')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.dock_space }}</mat-label>
        <!--Caso mais de um departamento, dockSpacesFilterList separado por departamentos e então as docas desse departamento-->
        <mat-select (closed)="getFilterDockSpace()" *ngIf="departments?.length > 1" [formControl]="filterDockSpaceSelected" multiple>
        <mat-optgroup *ngFor="let dklist of dockSpacesFilterList; let indexOfelement=index;" [label]="dklist.name">
          <mat-option class="tkg-clear-filter-option" (click)="clearMultiselectFilter('dock')">
            {{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar filtro"} }}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let dock of dklist.docks" [value]="dock.id" (click)="setDockSpaceFilterOptions(dock.id)">
            {{dock.name}}
          </mat-option>
        </mat-optgroup>
        </mat-select>
        <!--Caso departamento único, dockSpacesFilterList contém todas as docas somente-->
        <mat-select (closed)="getFilterDockSpace()" *ngIf="!(departments?.length > 1)" [formControl]="filterDockSpaceSelected" multiple>
          <mat-option class="tkg-clear-filter-option" (click)="clearMultiselectFilter('dock')">
            {{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar filtro"} }}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let dock of dockSpacesFilterList" [value]="dock.id" (click)="setDockSpaceFilterOptions(dock.id)">
            {{dock.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterEnabled('status')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.status }}</mat-label>
        <mat-select
          [formControl]="filterStatusSelected"
          [(value)]="selectedStatus"
        >
          <mat-option (click)="getFilterStatus('')"
            >{{ 'Checkin.checkinList.clear_filter' | translate:{Default: "Limpar
            filtro"} }}</mat-option
          >
          <mat-divider></mat-divider>
          <mat-option
            *ngFor="let ls of filterPageStatus(listStatus)"
            [value]="ls.id"
            (click)="getFilterStatus(ls.id)"
          >
            {{ls.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterEnabled('search')">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels.search }}</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.clean_filter' | translate:{Default: 'Limpar pesquisa'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>

    <!--Filtro dinâmico de dados do agendamento-->
    <form [formGroup]="eventFiltersForm">
      <div formArrayName="eventFilters">
        <div *ngFor="let filter of eventFilterList; let i = index">
          <mat-form-field appearance="outline">
            <mat-label>{{ this.filterLabels[filter] ?? 'Filtro' }}</mat-label>
            <input matInput [formControlName]="i" />
            <mat-icon
              matSuffix
              *ngIf="!eventFiltersForm.get('eventFilters')?.get(i.toString())?.value"
              >search</mat-icon
            >
            <mat-icon
              matSuffix
              (click)="clearEventFilter(i.toString())"
              [matTooltip]="'Business_model.clean_filter' | translate:{Default: 'Limpar pesquisa'}"
              ngClass="app-search-clear"
              *ngIf="eventFiltersForm.get('eventFilters')?.get(i.toString())?.value"
              >clear</mat-icon
            >
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container">
  <!--Table-->
  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      class=""
    >
      <ng-container
        *ngFor="let col of cols"
        [matColumnDef]="col"
        [sticky]="col === 'actions' || col === 'status'"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="col"
          [disabled]="sorted(col)"
        >
          {{formatHeader(col)}}
        </th>
        <td mat-cell *matCellDef="let element; index as idx">
          <ng-container *ngIf="element[col].type === 'date'; else elseTemplate">
            {{element[col].value ? formatDate(element[col].value,
            element[col].format) : ""}}
          </ng-container>

          <ng-template #elseTemplate>
            <ng-container
              *ngIf="element[col].name === 'status'; else elsePriorityTemplate"
            >
              <mat-chip-set (click)="showBSActions(element)">
                <mat-chip
                  class="chip-text"
                  [style.background-color]="element.status.value.color"
                  [style.color]="'#ffffff'"
                >
                  {{element.status.value.name}}
                </mat-chip>
              </mat-chip-set>
            </ng-container>
          </ng-template>

          <ng-template #elsePriorityTemplate>
            <ng-container
              *ngIf="element[col].name === 'priority'; else elseHighWeightTemplate"
            >
              <mat-chip-set>
                <mat-chip
                  class="chip-text"
                  [style.background-color]="element.priority.value.color"
                  [style.color]="'#ffffff'"
                >
                  {{element.priority.value.name}}
                </mat-chip>
              </mat-chip-set>
            </ng-container>
          </ng-template>

          <ng-template #elseHighWeightTemplate>
            <ng-container
              *ngIf="element[col].name === 'highWeight'; else elseLinkedTemplate"
            >
              <mat-chip-set>
                <mat-chip
                  class="chip-text"
                  [style.background-color]="element.highWeight.value.color"
                  [style.color]="'#ffffff'"
                >
                  {{element.highWeight.value.name}}
                </mat-chip>
              </mat-chip-set>
            </ng-container>
          </ng-template>

          <ng-template #elseLinkedTemplate>
            <ng-container
              *ngIf="element[col].name === 'trucker_checkin_id'; else elseIfTemplate"
            >
              <mat-chip-set>
                <mat-chip
                  class="chip-text"
                  [style.background-color]="element.id_trucker.value.color"
                  [style.color]="'#FFFFFF'"
                >
                  {{element.id_trucker.value.name}}
                </mat-chip>
              </mat-chip-set>
            </ng-container>
          </ng-template>

          <ng-template #elseIfTemplate>
            <ng-container
              *ngIf="(actionsColumn && element[col].name === 'actions'); else elseAnotherIfTemplate"
            >
              <ng-container>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  (menuClosed)="onActionMenuClosed()"
                >
                  <mat-icon matTooltip="Ações">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    (click)="changeStatus(element.actions.value, action)"
                    *ngFor="let action of listAction"
                    [disabled]="disableBtn(element.actions.value.resource.status, action, element[col]?.value) || block_actions"
                    [style.display]="showOnlyIfOperationMatch(element.actions.value, action)"
                  >
                    {{action.name}}
                  </button>
                </mat-menu>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #elseAnotherIfTemplate>
            <ng-container
              *ngIf="element[col].name === 'exception'; else elseAnotherIfNoTemplate"
            >
              <ng-container>
                <button
                  mat-icon-button
                  (click)="showDialogExceptions(element[col].value)"
                  [disabled]="!element[col].value[0].route && (!element[col].value[0].ot_problem && !element[col].value[0].exception_received && !element[col].value[0].loading_denied &&
                        !element[col].value[0].unloading_denied && !element[col].value[0].loading_problem &&
                        !element[col].value[0].direction_problem && !element[col].value[0].invalidated_documentation)"
                >
                  <mat-icon matTooltip="Exceções"
                    >report_gmailerrorred</mat-icon
                  >
                </button>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #elseAnotherIfNoTemplate
            >{{showCellValue(element[col].value)}}</ng-template
          >
        </td>
      </ng-container>
      <tr class="mat-row empty" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="cols.length">
          <app-data-loader *ngIf="!allDataAvailable"></app-data-loader>
          <div style="margin: 10px 0">
            {{ searching ? ("Business_model.processing_results" |
            translate:{Default: "Processando resultados ..."}) :
            ("Business_model.no_results" | translate:{Default: "Sem
            resultados"}) }}
          </div>
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="cols; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: cols;"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement === element ? null : element"
      ></tr>
    </table>
  </div>

  <!--Paginator-->
  <div class="paginator">
    <mat-paginator
      [pageSize]="paginatorPageSize"
      [length]="paginatorLength"
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<!-- FAB -->
<!--To-Do-Fix-->
<!-- <ngx-float-button icon="add" class="mat-fab-bottom-right" color="#1fd91d" [open]="open" *ngIf="showFabMenu">
  <ng-container *ngFor="let element of fabMenu">
    <ngx-float-item-button [icon]="element.icon" [content]="element.name" (click)="actions(element.action, element, element.item)"></ngx-float-item-button>
  </ng-container>
</ngx-float-button> -->
<!--To-Do-Fix-->

<button
  *ngIf="addButton"
  mat-mini-fab
  (click)="plusBtn()"
  class="mat-fab-bottom-right"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
