import { Component } from '@angular/core';

@Component({
  selector: 'app-registrations-settings',
  templateUrl: './registrations-settings.component.html',
  styleUrls: ['./registrations-settings.component.scss']
})
export class RegistrationsSettingsComponent {

}
