import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cell-template',
  templateUrl: './cell-template.component.html',
  styleUrls: ['./cell-template.component.scss']
})
export class CellTemplateComponent implements OnInit {
  @Input('cellData') cellData!: object;
  @Input('cellTemplate') cellTemplate!: any[];

  constructor() { }

  ngOnInit() {
  }

  cellClass(element: any) {
      return element.status
  }

  cellPriority(element : any){
     return element.priority
  }

  ItemCellTemplate(element : any){
    return element[0].value
 }

 ItemCellTemplateColor(element : any){
  return element[0].color
}

ItemCellTemplateBackGroundColor(element : any){
  return element[0].background_color
}

  bindTemplate(row:any[]) {
    let textArray:any[] = [];
    row.forEach((element:any) => {

      if(element.type === 'key'){

        if(element.value === "priority" && this.cellData[element.value as keyof typeof this.cellData])
        {
          textArray.push(element.label)
        }
        else if (element.value === "priority" && !this.cellData[element.value as keyof typeof this.cellData])
        {
          textArray.push("")
        }
        else{
        textArray.push(this.cellData[element.value as keyof typeof this.cellData])
      }
      }
      if(element.type === 'string') {
        textArray.push(element.value)
      }
    });
    let text = textArray.join(" ");
    let resText;
    if(text.length > 30) {
      resText = `${text.substring(0,30)}...`;
    } else {
      resText = text;
    }
    return  resText.toUpperCase();
  }

}
