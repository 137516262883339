import { EChartsOption } from 'echarts';
import { DashboardDynamicService } from './../../../../dashboard-dynamic/dashboard-dynamic.service';
import { CardInterface } from './../../card.interface';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface PeriodicElement {
  operacao: string;
  tempo: number;
}

@Component({
  selector: 'app-card-charts-bar',
  templateUrl: './card-charts-bar.component.html',
  styleUrls: ['./card-charts-bar.component.scss']
})
export class CardChartsBarComponent implements  OnInit, CardInterface {
  value = 0;
  data: any;
  layout!: number;
  options: any;

  displayedColumns = ['name', 'data'];
  dataSource = [];
  chartOptions!: EChartsOption;
  infoHtml: SafeHtml;

  constructor(private sanitizer:DomSanitizer,private dashboardDynamic: DashboardDynamicService) {
   }


   ngOnInit() {
    if(typeof(this.data.info_html) == "string"){
      this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.info_html as string);
    }
    this.dashboardDynamic.getDynamicDashboardItem({ item: this.data.element_name}).subscribe((obj: any) =>
    {
      console.log("obj", obj)
      this.chartOptions = this.generateChartOptions(obj)
     })


  }

  private generateChartOptions(obj:any):EChartsOption {
   /*  const per = (value > 0 ? (value/100)-.0001 : 0);
    const data: number[] = [10, 100, 200, 300, 400, 500 , 600];
    const color = this.data.color || "#936bc4"; */
    let source: any [] = [];

    source.push(['Veículos','Status'])

    obj.forEach((item: any) => {
      source.push([item.data, item.name])
    });

    const options: any = {
      title: {
        subtext: 'Status'
      },
      dataset: {
        source
      },
      grid: {
        left: "4%",
        right: "4%",
        bottom: "8%",
        top: "4%",
        containLabel: true
      },
      xAxis: {
        name: 'Veículos',
        nameGap: 25,
        nameLocation: "middle"
      },
      yAxis: {
        type: 'category'
      },
      series: [
        {
          type: 'bar',
          itemStyle: { normal: { color: '#1ae40dd1' } },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          label:{position:"outside", show:true},
          encode: {
            // Map the "amount" column to X axis.
            x: 'Veículos',
            // Map the "product" column to Y axis
            y: 'Status'
          }
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          id: 'insideX',
          yAxisIndex: 0,
          zoomOnMouseWheel: false,
          moveOnMouseMove: false,
          moveOnMouseWheel: true
        }
      ],
    };

    return options;
  }
}
