
import { Observable, Subject, of } from 'rxjs';
import {Component, OnInit, Output, EventEmitter} from '@angular/core';
// import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-drivers-foto-perfil-webcam',
  templateUrl: './drivers-foto-perfil-webcam.component.html',
  styleUrls: ['./drivers-foto-perfil-webcam.component.scss']
})
export class DriversFotoPerfilWebcamComponent implements OnInit {

  constructor(private dialog: MatDialog,){}
  @Output() public fileEvent = new EventEmitter<any>();
  // @Output() public pictureTaken = new EventEmitter<WebcamImage>();
  @Output() cancel = new EventEmitter<boolean>();
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string | undefined;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  // public errors: WebcamInitError[] = [];\
  errors = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  public ngOnInit(): void {
    // WebcamUtil.getAvailableVideoInputs()
    //   .then((mediaDevices: MediaDeviceInfo[]) => {
    //     this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    //   });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    // this.showWebcam = !this.showWebcam;
  }

  // public handleInitError(error: WebcamInitError): void {
  //   this.errors.push(error);
  // }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    // this.nextWebcam.next(directionOrDeviceId);
  }


  isValidImage(file: any) {
    if (file.size > 5000000) {
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Erro', message: 'Tamanho da imagem não pode ser maior que 5MB!' }
      });
      return false;
    }
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Erro', message: 'Tipo de arquivo não é suportado, por favor escolha um tipo PNG ou JPEG!' }
      });
      return false;
    }
    return true;
  }

  // public handleImage(webcamImage: WebcamImage): void {
  public handleImage(): void {

    // const byteCharacters = atob(webcamImage.imageAsBase64);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    // byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray],{type: 'image/jpeg'});
    // const file = new File([blob], "name.jpeg",{type: 'image/jpeg'});
    // // this.pictureTaken.emit(webcamImage);
    // this.file = file;
  }

    private file: File;

  callFile(){
    this.fileEvent.emit({target:{files:[this.file]}});
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public cancelWebcam() {
    this.cancel.emit(true);
  }
}
