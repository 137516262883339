<div class="top-bar-filters">
  <div>
    <div></div>
    <div class="filter-itens">
      <mat-form-field appearance="outline">
        <mat-label
          >{{ "Business_model.search" | translate:{Default: "Buscar"}
          }}</mat-label
        >
        <input matInput placeholder="" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.clean_filter' | translate:{Default:'Limpar pesquisa'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
      <button
        mat-stroked-button
        color="primary"
        (click)="disconnect('all', null)"
      >
        {{ "Business_model.disconnect_all" | translate:{Default: 'Desconectar
        todos usuários'} }}
      </button>
    </div>
  </div>
</div>

<app-list-empty *ngIf="showListEmpty"></app-list-empty>

<div class="mat-elevation-z8" [hidden]="showListEmpty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="actions" first>
      <th mat-header-cell *matHeaderCellDef>
        {{ "Business_model.actions" | translate:{Default: 'Ações'} }}
      </th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="open('EDIT', element)">
            {{ "Business_model.edit" | translate:{Default: 'Editar'} }}
          </button>
          <button mat-menu-item (click)="disconnect('user', element)">
            {{ "Business_model.disconnect" | translate:{Default: 'Desconectar'}
            }}
          </button>
          <button mat-menu-item (click)="delete(element)">
            {{ "Business_model.delete" | translate:{Default: 'Deletar'} }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Business_model.name" | translate:{Default: "Nome"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis">{{element.first_name}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Business_model.email" | translate:{Default: "E-mail"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span class="ellipsis">{{element.email}}</span>
        </div>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="is_staff">
      <th mat-header-cell *matHeaderCellDef>Funcionário</th>
      <td mat-cell *matCellDef="let element">{{element.is_staff | stateBoolean}}</td>
    </ng-container>

    <ng-container matColumnDef="is_superuser">
      <th mat-header-cell *matHeaderCellDef>Super usuário</th>
      <td mat-cell *matCellDef="let element">{{element.is_superuser | stateBoolean}}</td>
    </ng-container> -->

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Business_model.group" | translate:{Default: "Grupo"} }}
      </th>
      <td mat-cell *matCellDef="let element">{{element.group}}</td>
    </ng-container>

    <ng-container matColumnDef="is_carrier">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Business_model.user_carrier" | translate:{Default: "Usuário
        Transportadora"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.is_carrier | stateBoolean}}
      </td>
    </ng-container>

    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Business_model.active" | translate:{Default: "Ativo"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.is_active | stateBoolean}}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Business_model.created_in" | translate:{Default: 'Criado em'} }}
      </th>
      <td mat-cell *matCellDef="let element">{{element.created_at | date}}</td>
    </ng-container>

    <ng-container matColumnDef="alerts">
      <th mat-header-cell *matHeaderCellDef><!-- Alertas --></th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <!-- <button mat-icon-button [color]="(element.is_alert_mail) ? 'primary' : 'accent'"
          [matTooltip]="(element.is_alert_mail) ? 'Desativar alertas por e-mail' : 'Ativar alertas por e-mail'"
          (click)="toggleAlert('mail', element)">
          <mat-icon>email</mat-icon>
        </button> -->

        <!-- <button mat-icon-button [color]="(element.is_alert_phone) ? 'primary' : 'accent'"
          [matTooltip]="(element.is_alert_phone) ? 'Desativar alertas por celular' : 'Ativar alertas por celular'"
          (click)="toggleAlert('phone', element)">
          <mat-icon>smartphone</mat-icon>
        </button> -->
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: !breakpointObserver.isMatched(breakpoints.Handset);"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>

<button
  mat-mini-fab
  (click)="open('ADD')"
  class="mat-fab-bottom-right"
  color="primary"
  *ngIf="account?.is_superuser"
>
  <mat-icon>add</mat-icon>
</button>
