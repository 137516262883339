
<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title class="title">{{data.title}}</mat-card-title>
    <button class="icon-scale" *ngIf="infoHtml" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Dash info">
      <mat-icon>info_outline</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="info-container" [innerHtml]="infoHtml"></div>
    </mat-menu>
  </mat-card-header>

  <mat-divider inset></mat-divider>

  <div class="scroll">
    <table mat-table [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{data.label1}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>{{data.label2}}</th>
        <td mat-cell *matCellDef="let element"> {{element.data}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


</mat-card>




