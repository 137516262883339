import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HealthCheckService } from '../shared/health-check.service';
import moment from 'moment';
import * as echarts from 'echarts';
import { MatDialog } from '@angular/material/dialog';
import { HealthCheckInfoComponent } from '../health-check-info/health-check-info.component';
import { W } from '@angular/cdk/keycodes';
import { HealthCheckListComponent } from '../health-check-list/health-check-list.component';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { debug } from 'console';
import { STANDARD_DROPDOWN_ADJACENT_POSITIONS } from '@angular/cdk/overlay';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent implements OnInit {
  currentWeek = parseInt(moment(new Date()).format('w')) - 1;
  data: any
  incidentsMaestro: any
  incidentsApp: any
  forkJoinDone$= new BehaviorSubject<void>(void 0);
  incidentsNode: any
  chart: echarts.ECharts;
  chart2: echarts.ECharts;
  chart3: echarts.ECharts;
  weekOfMonth: number
  pageSize = 7;
  firstDayOfWeek = moment('2023').add((this.currentWeek), 'weeks').startOf('week').format('YYYY-MM-DD');
  lastDayOfWeek = moment('2023').add((this.currentWeek), 'weeks').endOf('week').format('YYYY-MM-DD');
  topIncidents: any[] = [];
  cavalo: any;
  weekIncidents: any = {}
  AllWeekIncidents: any;
  data2: any;
  data3: any;

  constructor(
    public healthCheckService: HealthCheckService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    forkJoin([
    this.healthCheckService.getTopIncidents(),
    this.getIncidentsBetweenDates(),
    ]).subscribe((result: any) => {
      // Schedule Field process
      this.topIncidents = result[0];
      this.AllWeekIncidents = result[1];
      this.incidentsMaestro = result[1].filter((item: any) => item.resource.app == "maestro");
      this.incidentsApp = result[1].filter((item: any) => item.resource.app == "app");
      this.incidentsNode = result[1].filter((item: any) => item.resource.app == "integration");
      this.renderChart();
    });
  }

  getIncidentsBetweenDates() {
    const options = {
      firstDayOfWeek: this.firstDayOfWeek,
      lastDayOfWeek: this.lastDayOfWeek
    }
   return this.healthCheckService.getIncidentsBetweenDates(options);
  }

  incidentsBetweenDates() {
    const options = {
      firstDayOfWeek: this.firstDayOfWeek,
      lastDayOfWeek: this.lastDayOfWeek
    }

   this.healthCheckService.getIncidentsBetweenDates(options).subscribe((r: any) => {
    this.AllWeekIncidents = r;

    this.incidentsMaestro = r.filter((item: any) => item.resource.app == "maestro");

    this.incidentsApp = r.filter((item: any) => item.resource.app == "app");

    this.incidentsNode = r.filter((item: any) => item.resource.app == "integration");

    this.renderChart();
  });
  }

  formatDate(date: any){
    let newDate = moment(date).format('DD/MM/YYYY');
    return newDate
  }

  getIncidentById(id: number) {
    let IncidentSelected = this.topIncidents.find((item: any) => item.id == id);
    this.dialog.open(HealthCheckInfoComponent, {
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {
        ...IncidentSelected
      },
    });
  }

  renderChart(){
    this.data = Array.from({length: 53}).map((_, index) => ([
      {value:1, itemStyle: {color: this.changeColorEvent(0, 'maestro')}},
      {value:1, itemStyle: {color: this.changeColorEvent(1, 'maestro')}},
      {value:1, itemStyle: {color: this.changeColorEvent(2, 'maestro')}},
      {value:1, itemStyle: {color: this.changeColorEvent(3, 'maestro')}},
      {value:1, itemStyle: {color: this.changeColorEvent(4, 'maestro')}},
      {value:1, itemStyle: {color: this.changeColorEvent(5, 'maestro')}},
      {value:1, itemStyle: {color: this.changeColorEvent(6, 'maestro')}}
    ]));

    this.data2 = Array.from({length: 53}).map((_, index) => ([
      {value:1, itemStyle: {color: this.changeColorEvent(0, 'app')}},
      {value:1, itemStyle: {color: this.changeColorEvent(1, 'app')}},
      {value:1, itemStyle: {color: this.changeColorEvent(2, 'app')}},
      {value:1, itemStyle: {color: this.changeColorEvent(3, 'app')}},
      {value:1, itemStyle: {color: this.changeColorEvent(4, 'app')}},
      {value:1, itemStyle: {color: this.changeColorEvent(5, 'app')}},
      {value:1, itemStyle: {color: this.changeColorEvent(6, 'app')}}
    ]));

    this.data3 = Array.from({length: 53}).map((_, index) => ([
      {value:1, itemStyle: {color: this.changeColorEvent(0, 'integracao')}},
      {value:1, itemStyle: {color: this.changeColorEvent(1, 'integracao')}},
      {value:1, itemStyle: {color: this.changeColorEvent(2, 'integracao')}},
      {value:1, itemStyle: {color: this.changeColorEvent(3, 'integracao')}},
      {value:1, itemStyle: {color: this.changeColorEvent(4, 'integracao')}},
      {value:1, itemStyle: {color: this.changeColorEvent(5, 'integracao')}},
      {value:1, itemStyle: {color: this.changeColorEvent(6, 'integracao')}}
    ]));

    this.chart = echarts.init(document.getElementById('barChart')!);
    this.chart2 = echarts.init(document.getElementById('barChart2')!);
    this.chart3 = echarts.init(document.getElementById('barChart3')!);
    this.updateChart();

    window.addEventListener('resize', () => {
      this.chart.resize();
      this.chart2.resize();
      this.chart3.resize();
    });
  }

  updateChart(){
        const start = this.currentWeek * this.pageSize;
        const end = start + this.pageSize;

        const option = {
          xAxis: {
            type: 'category',
            data: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
          },
          yAxis: {
            axisLabel: {
              show: false, // Define para não mostrar os valores do eixo Y
            },
          },
          series: [{
            data: this.data[this.currentWeek],
            type: 'bar'
          }],

          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: (params: any) => {
              let weekIncidents: any = {}

              this.incidentsMaestro?.forEach((incident: any) => {
                let incidentIndex = moment(incident.created_at).diff(this.firstDayOfWeek, 'days')

                weekIncidents = {
                ...weekIncidents,
                [incidentIndex]: Object.keys(weekIncidents).some((t: any) => t == incidentIndex) ? [...weekIncidents[incidentIndex], incident] : [incident]
                }
              })

              let tooltipContent = '';

              for (let i = 0; i < params.length; i++) {
                const barIndex = params[i].dataIndex; // Índice da barra

                if(weekIncidents[barIndex]) {
                    let tooltipPersonalizado = `
                    <div style="
                      width: 200px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding: 0 40px;
                      justify-content: center;
                      ">
                    <div style="
                      color:black;

                    ">
                      <b><p>${moment(weekIncidents[barIndex].at(0).created_at).locale('pt-br').format('DD MMMM YYYY')}</p></b>
                    <br>
                    <p style="
                    color: rgb(172, 172, 172);">
                      OBSERVAÇÃO:
                  </p>
                  <div style="
                  text-wrap: wrap;
                  padding: 5px 10px">
                    ${weekIncidents[barIndex].at(0).name}
                  </div>
                <div>
                <p style="
                  width: 100%;
                  background-color: #1C983F;
                  color: white;
                  padding: 5px;
                  display: flex;
                  justify-content: center;
                ">
                  Clique para mais informações
                </p>
                        </div>
                      </div>
                    </div>
                      `;
                    tooltipContent += `${tooltipPersonalizado}<br>`;
                }else {
                  let tooltipPersonalizado = 'Nenhum incidente registrado neste dia'
                  tooltipContent += `${tooltipPersonalizado}<br>`;
                }

              }

              return tooltipContent;

            }
          },
        };
        this.chart.on('click', () => {
          this.onChartEvent()
        });
        this.chart.setOption(option);


        const option2 = {
          xAxis: {
            type: 'category',
            data: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
          },
          yAxis: {
            axisLabel: {
              show: false, // Define para não mostrar os valores do eixo Y
            },
          },
          series: [{
            data: this.data2[this.currentWeek],
            type: 'bar'
          }],

          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: (params: any) => {
              let weekIncidents: any = {}

              this.incidentsApp?.forEach((incident: any) => {
                let incidentIndex = moment(incident.created_at).diff(this.firstDayOfWeek, 'days')

                weekIncidents = {
                ...weekIncidents,
                [incidentIndex]: Object.keys(weekIncidents).some((t: any) => t == incidentIndex) ? [...weekIncidents[incidentIndex], incident] : [incident]
                }
              })

              let tooltipContent = '';

              for (let i = 0; i < params.length; i++) {
                const barIndex = params[i].dataIndex; // Índice da barra

                if(weekIncidents[barIndex]) {
                    let tooltipPersonalizado = `
                    <div style="
                      width: 200px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding: 0 40px;
                      justify-content: center;
                      ">
                    <div style="
                      color:black;

                    ">
                      <b><p>${moment(weekIncidents[barIndex].at(0).created_at).locale('pt-br').format('DD MMMM YYYY')}</p></b>
                    <br>
                    <p style="
                    color: rgb(172, 172, 172);">
                      OBSERVAÇÃO:
                  </p>
                  <div style="
                  text-wrap: wrap;
                  padding: 5px 10px">
                    ${weekIncidents[barIndex].at(0).name}
                  </div>
                <div>
                <p style="
                  width: 100%;
                  background-color: #1C983F;
                  color: white;
                  padding: 5px;
                  display: flex;
                  justify-content: center;
                ">
                  Clique para mais informações
                </p>
                        </div>
                      </div>
                    </div>
                      `;
                    tooltipContent += `${tooltipPersonalizado}<br>`;
                }else {
                  let tooltipPersonalizado = 'Nenhum incidente registrado neste dia'
                  tooltipContent += `${tooltipPersonalizado}<br>`;
                }

              }

              return tooltipContent;

            }
          },
        };
        this.chart2.on('click', () => {
          this.onChartEvent()
        });
        this.chart2.setOption(option2);

        const option3 = {
          xAxis: {
            type: 'category',
            data: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
          },
          yAxis: {
            axisLabel: {
              show: false, // Define para não mostrar os valores do eixo Y
            },
          },
          series: [{
            data: this.data3[this.currentWeek],
            type: 'bar'
          }],

          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: (params: any) => {
              let weekIncidents: any = {}

              this.incidentsNode?.forEach((incident: any) => {
                let incidentIndex = moment(incident.created_at).diff(this.firstDayOfWeek, 'days')

                weekIncidents = {
                ...weekIncidents,
                [incidentIndex]: Object.keys(weekIncidents).some((t: any) => t == incidentIndex) ? [...weekIncidents[incidentIndex], incident] : [incident]
                }
              })

              let tooltipContent = '';

              for (let i = 0; i < params.length; i++) {
                const barIndex = params[i].dataIndex; // Índice da barra

                if(weekIncidents[barIndex]) {
                    let tooltipPersonalizado = `
                    <div style="
                      width: 200px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      padding: 0 40px;
                      ">
                    <div style="
                      color:black;

                    ">
                      <b><p>${moment(weekIncidents[barIndex].at(0).created_at).locale('pt-br').format('DD MMMM YYYY')}</p></b>
                    <br>
                      <p style="
                        color: rgb(172, 172, 172);">
                          OBSERVAÇÃO:
                      </p>
                      <div style="
                      text-wrap: wrap;
                      padding: 5px 10px">
                        ${weekIncidents[barIndex].at(0).name}
                      </div>
                    <div>
                    <p style="
                      width: 100%;
                      background-color: #1C983F;
                      color: white;
                      padding: 5px;
                      display: flex;
                      justify-content: center;
                    ">
                      Clique para mais informações
                    </p>
                        </div>
                      </div>
                    </div>
                      `;
                    tooltipContent += `${tooltipPersonalizado}<br>`;
                }else {
                  let tooltipPersonalizado = 'Nenhum incidente registrado neste dia'
                  tooltipContent += `${tooltipPersonalizado}<br>`;
                }

              }

              return tooltipContent;

            }
          },
        };
        this.chart3.on('click', () => {
          this.onChartEvent()
        });
        this.chart3.setOption(option3);
  }

  previousPage(){
    if (this.currentWeek > 0) {
      this.currentWeek--;
      this.firstDayOfWeek = moment('2023').add((this.currentWeek), 'weeks').startOf('week').format('YYYY-MM-DD');
      this.lastDayOfWeek = moment('2023').add((this.currentWeek), 'weeks').endOf('week').format('YYYY-MM-DD');
      this.incidentsBetweenDates();
    }
  }

  nextPage(){
    if (this.currentWeek < this.data.length - 1) {
      this.currentWeek++;
      this.firstDayOfWeek = moment('2023').add((this.currentWeek), 'weeks').startOf('week').format('YYYY-MM-DD');
      this.lastDayOfWeek = moment('2023').add((this.currentWeek), 'weeks').endOf('week').format('YYYY-MM-DD');
      this.incidentsBetweenDates();
    }
  }

  onChartEvent() {
    this.dialog.open(HealthCheckListComponent, {
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {
        weekIncidents: this.AllWeekIncidents
      },
    });
  }


  changeColorEvent(barIndex: any, service: any) {
    let weekIncidents: any = {}
    let serviceIncidents = this.incidentsMaestro;
    //TODO: Pensar em uma forma melhor para fazer isso
    if(service == 'app') {
      serviceIncidents = this.incidentsApp;
    } else if (service == 'integracao') {
      serviceIncidents = this.incidentsNode;
    }

    serviceIncidents?.forEach((incident: any) => {
      let incidentIndex = moment(incident.created_at).diff(this.firstDayOfWeek, 'days')

        weekIncidents = {
        ...weekIncidents,
      [incidentIndex]: Object.keys(weekIncidents).some((t: any) => t == incidentIndex) ? [...weekIncidents[incidentIndex], incident] : [incident]
      }
    })

    if(weekIncidents[barIndex]) {
      let lowColor = {
        red: 33,
        green: 151,
        blue: 38
      };
      let mediumColor = {
        red: 31,
        green: 237,
        blue: 9
      };
      let highColor = {
        red: 193,
        green: 0,
        blue: 13
      };

      // buscar o valor entre 0 e 1, considerando o valor '1' como 1 hora (trabalhar em minutos)
      let color1 = lowColor;
      let color2 = mediumColor;
      let fade = this.normalizeValue(weekIncidents[barIndex].at(0).resource.totalTime, 0, 60);

      // Do we have 3 colors for the gradient? Need to adjust the params.
      if (highColor) {
        fade = fade * 2;

        // Find which interval to use and adjust the fade percentage
        if (fade >= 1) {
          fade -= 1;
          color1 = mediumColor;
          color2 = highColor;
        }
      }

      let diffRed = color2.red - color1.red;
      let diffGreen = color2.green - color1.green;
      let diffBlue = color2.blue - color1.blue;

      let colorRedMath:any = Math.floor(color1.red + (diffRed * fade))
      let colorGreenMath:any = Math.floor(color1.green + (diffGreen * fade))
      let colorBlueMath:any = Math.floor(color1.blue + (diffBlue * fade))

      let gradient = {
        red: parseInt(colorRedMath, 10),
        green: parseInt(colorGreenMath, 10),
        blue: parseInt(colorBlueMath, 10),
      };

      return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
    } else {
      return 'rgb(' + 33 + ',' + 151 + ',' + 38 + ')'
    }
    }

    normalizeValue(value: any, minValue: any, maxValue: any) {
      // Normaliza o valor entre 0 e 1
      const normalizedValue = (value - minValue) / (maxValue - minValue);

      return normalizedValue;
  }
}
