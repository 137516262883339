<div class="dialog-header">
  <mat-icon>switch_account</mat-icon>
  <h1 mat-dialog-title>Trocar de conta</h1>
</div>

<h2 class="h1-user" *skeleton="isLoading; height: '45px'; width:'inherit'; className:'sklt-h1-user'">
  Olá {{ userClients.user_name }}, selecione um perfil
</h2>
<mat-form-field
  *ngIf="showFilter"
  class="example-form-field"
  appearance="outline"
>
  <mat-label>Filtrar cliente</mat-label>
  <mat-icon matPrefix>search</mat-icon>
  <input matInput type="text" [formControl]="searchControl">
  <button
    *ngIf="searchControl.value"
    matSuffix mat-icon-button
    aria-label="Clear"
    (click)="searchControl.setValue('',{emitEvent:true})"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<div mat-dialog-content>
  <mat-list *skeleton="isLoading; height: '60px'; width:'inherit'; repeat:4; className: 'sklt'">
    <mat-list-item
      *ngFor="let client of userClients.clients"
      (click)="keycloakLogin(client)"
      [class.active-account]="client.active"
    >
      <img
        matListItemIcon
        class="client-icon"
        [src]="client.icon"
      />
      <div matListItemTitle>{{client.name}}</div>
      <div matListItemLine>{{client.location}}</div>
      <mat-icon
        *ngIf="client.login_state == 'keycloak-accepted'"
        matListItemIcon
        color="primary"
        class="icon-login-state"
        [matTooltip]="'Logado desde ' + client.logged_at +'
        Obs.: o login expirará após longo período de inatividade.'"
        matTooltipClass="tooltip-break-line"
      >
        done_all
      </mat-icon>
      <mat-icon
        *ngIf="client.login_state == 'keycloak-failed'"
        matListItemIcon
        color="warn"
        class="icon-login-state"
        matTooltip="Ocorreu uma falha durante a autenticação"
      >
      error_outline
      </mat-icon>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary">FECHAR</button>
</div>
