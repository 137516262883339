<div class="mainLogin">
  <div class="containerLogin">
    <div
      style="width: 100%; padding: 24px 0 24px 60px;"
      [ngStyle]="(stepper.selectedIndex == 1 ? {'align-self': 'start' } : {})"
    >
      <img class="logo" src="assets/img/tkg_maestro.png" alt="Trackage">
      <mat-stepper linear #stepper>
        <mat-step [stepControl]="form">
          <h2 class="title3">Insira seu e-mail</h2>
          <form [formGroup]="form" class="login-form">
            <div>
              <mat-form-field appearance="outline" class="tkg-row">
                <mat-label>E-mail</mat-label>
                <input type="email" formControlName="email" matInput placeholder="E-mail">
              </mat-form-field>
              <input type="password" hidden>
            </div>

            <!--<div>
              <a href="/auth/remember" class="linkSenha">Esqueceu a senha?</a>
            </div>
            -->
            <div>
              <p>Acesse os nossos
                  <a href="https://www.trackage.com.br/termos-de-uso/" target="_blank" class="linkSenha">
                    Termos de Uso
                  </a>
                    e a
                  <a href="https://www.trackage.com.br/politica-de-privacidade/" target="_blank" class="linkSenha">
                    Política de Privacidade
                  </a>
              </p>
            </div>

            <div ngClass="margin-top">
              <span>
                <button
                  (click)="onSubmit()"
                  [disabled]="!form.valid"
                  style="color: white;"
                  mat-button mat-raised-button color="primary"
                >
                  ENTRAR
                </button>
              </span>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
          <form [formGroup]="secondFormGroup">
            <div>
              <h2
                class="title3"
                style="font-size: 1.2em;"
                *skeleton="userClients.clients?.length == 0; height: '45px'; width:'inherit'; className:'sklt-h1-user'"
              >
                Olá {{ userClients.user_name }}, selecione um perfil
              </h2>

              <mat-form-field
                class="client-filter"
                *ngIf="showFilter"
                appearance="outline"
              >
                <mat-label>Filtrar cliente</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput type="text" [formControl]="searchControl">
                <button
                  *ngIf="searchControl.value"
                  matSuffix mat-icon-button
                  aria-label="Clear"
                  (click)="searchControl.setValue('',{emitEvent:true})"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <mat-divider *ngIf="showFilter"></mat-divider>
            <mat-list
              *skeleton="userClients.clients?.length == 0; height: '60px'; width:'inherit'; repeat:4; className: 'sklt'"
            >
              <mat-list-item *ngFor="let client of userClients.clients" (click)="keycloakLogin(client)">
                <img
                  matListItemIcon
                  class="client-icon"
                  [src]="client.icon"
                />
                <div matListItemTitle>{{client.name}}</div>
                <div matListItemLine>{{client.location}}</div>
                <mat-icon
                  *ngIf="client.login_state == 'keycloak-accepted'"
                  matListItemIcon
                  color="primary"
                  class="icon-login-state"
                  [matTooltip]="'Logado desde ' + client.logged_at +'
                  Obs.: o login expirará após longo período de inatividade.'"
                  matTooltipClass="tooltip-break-line"
                >
                  done_all
                </mat-icon>
                <mat-icon
                  *ngIf="client.login_state == 'keycloak-failed'"
                  matListItemIcon
                  color="warn"
                  class="icon-login-state"
                  matTooltip="Ocorreu uma falha durante a autenticação"
                >
                error_outline
                </mat-icon>
              </mat-list-item>
            </mat-list>
            <button
              mat-button
              matStepperPrevious
              mat-raised-button
              color="primary"
              style="color: white;"
            >
              VOLTAR
            </button>
          </form>
        </mat-step>
      </mat-stepper>
  </div>
  </div>
  <div class="containerImg">
    <img class="imageTrackage" src="assets/img/image_bg.png" alt="" />
    <div class="containerTitle zoom-in-out-box">
    	<span class="span1">Uma Logística</span>
      <span class="span1">Melhor.</span>
      <span class="span1">Uma Vida</span>
      <span class="span1">Melhor.</span>
    </div>
  </div>
</div>
