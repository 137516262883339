import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/material";
import { FieldTypeConfig } from "@ngx-formly/core";
import { SupportDataService } from "src/app/shared/support-data.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-formly-select-support-data",
  templateUrl: "./formly-select-support-data.component.html",
  styleUrls: ["./formly-select-support-data.component.scss"],
})
export class FormlySelectSupportDataComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  // _formControl = new FormControl();
  list: any[] = [];
  selectedItem = false;

  constructor(private supportDataService: SupportDataService) {
    super();
  }

  ngOnInit() {
    this.startRequest();
  }

  startRequest() {
    if (this.props?.support_data_by_name) {
      this.supportDataService
        .byName({
          name: this.props?.support_data_by_name,
        })
        .subscribe((r: any) => {
          this.list = r;
        });
    }
  }
}
