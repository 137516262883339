<h1 mat-dialog-title class="tkg-dialog-title">{{data.action == 'ADD' ? 'Adicionar ' : 'Editar '}}Doca</h1>
<div *ngIf="data.action == 'EDIT'">
  <div class="msg" [ngClass]="bg" *ngIf="showMsg">
    <p><b>{{msgTitle}}</b>
    <br />
    {{msg}}
    </p>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" *ngIf="hasData">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <mat-form-field class="form-item">
        <input
          type="text"
          formControlName="name"
          matInput
          placeholder="Nome"
          [disabled]="disable_on_save"
        />
      </mat-form-field>
      <mat-form-field class="form-item">
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="type" [disabled]="disable_on_save">
          <mat-option *ngFor="let item of typeList" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="form-item" *ngIf="!this.data.docks_shared && this.data.departments.length > 1">
        <mat-label>Área</mat-label>
        <mat-select formControlName="department_id" [disabled]="disable_on_save">
          <mat-option *ngFor="let item of departmentList" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle formControlName="can_block" class="form-item slider-botton" [disabled]="disable_on_save">Bloqueia a vaga ao ocupar</mat-slide-toggle>
      <mat-slide-toggle formControlName="busy" class="form-item slider-botton" [disabled]="disable_on_save">Ocupada</mat-slide-toggle>
      <mat-slide-toggle formControlName="maintenance" class="form-item slider-botton" [disabled]="disable_on_save">Manutenção</mat-slide-toggle>
      <mat-slide-toggle formControlName="automatic_queue" class="form-item slider-botton" [disabled]="disable_on_save">Permite chamada automática para a doca</mat-slide-toggle>


    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" *ngIf="data.action == 'EDIT'" (click)="onDelete(data.item.id)">REMOVER</button>
  <button mat-button mat-dialog-close>CANCELAR</button>
  <button mat-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || disable_on_save">{{data.action == 'ADD' ? 'ADICIONAR ' : 'ATUALIZAR '}}</button>
</mat-dialog-actions>
