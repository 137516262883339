import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CheckinListComponent } from './checkin-list/checkin-list.component';
import { CheckinListMobileComponent } from './checkin-list-mobile/checkin-list-mobile.component';
import { CheckinRoutingModule } from './checkin-routing.module';
import { CheckinDriverParkingLotComponent } from './checkin-driver-parking-lot/checkin-driver-parking-lot.component';
import { InvalidateVehicleDialogComponent } from './invalidate-vehicle-dialog/invalidate-vehicle-dialog.component';
import { AditionalInfoDialogComponent } from './aditional-info-dialog/aditional-info-dialog.component';
import { FinishOptionsDialogComponent } from './finish-options-dialog/finish-options-dialog.component';
import { EditKeysDialogComponent } from './edit-keys-dialog/edit-keys-dialog.component';
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { OtherMotivesGenericDialogComponent } from './other-motives-generic-dialog/other-motives-generic-dialog.component';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FormlyMaterialModule } from "@ngx-formly/material";
import { DirectDialogComponent } from '../checkin/direct-dialog/direct-dialog.component';
import { ReleaseDockDialogComponent } from './release-dock-dialog/release-dock-dialog.component';
import { InfoFormDialogComponentComponent } from './info-form-dialog-component/info-form-dialog-component.component';
import { ShowInfoDialogComponent } from './show-info-dialog/show-info-dialog.component';
import { DialogActionsComponent } from '../checkin/dialog-actions/dialog-actions.component';
import { BottomSheetActionsComponent } from './bottom-sheet-actions/bottom-sheet-actions.component';
import { OptionsDialogComponent } from './options-dialog/options-dialog.component';
import { ScheduledCheckinComponent } from './scheduled-checkin/scheduled-checkin.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    CheckinRoutingModule,
    FormlyModule.forRoot({}),
    FormlyMaterialModule,
    FormlyMatToggleModule
  ],
  declarations: [
    CheckinListComponent,
    CheckinListMobileComponent,
    CheckinDriverParkingLotComponent,
    InvalidateVehicleDialogComponent,
    AditionalInfoDialogComponent,
    FinishOptionsDialogComponent,
    EditKeysDialogComponent,
    GenericDialogComponent,
    OtherMotivesGenericDialogComponent,
    DirectDialogComponent,
    ReleaseDockDialogComponent,
    InfoFormDialogComponentComponent,
    ShowInfoDialogComponent,
    DialogActionsComponent,
    BottomSheetActionsComponent,
    OptionsDialogComponent,
    ScheduledCheckinComponent
  ],
  providers: [
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ],
})
export class CheckinLorealModule { }
