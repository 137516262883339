<div class="notify_card" *ngFor="let notify of notifications">
  <div>
    <span class="notify-description">
      {{ notify.resource.message }}
    </span>
    <div class="fill">
    </div><i class="material-icons icon_cor36">query_builder</i>
  </div>
</div>

<mat-grid-list
  gutterSize="20px"
  class="grid-list"
  *ngFor="let structures of dashboardStructure"
  [cols]="cols || 12"
  rowHeight="300px"
  (window:resize)="onResize($event)"
>
  <mat-grid-tile
    class="grid-title"
    *ngFor="let structure of structures"
    [colspan]="structure.cols"
    [rowspan]="structure.row ? structure.row : 1"
    [style.background]="structure.color"
  >
    <card-dynamic
      [card]="structure.element_type"
      [data]="structure"
    >
    </card-dynamic>
  </mat-grid-tile>
</mat-grid-list>

<!-- <mat-card-content>
  <div class="content-container">
    <div class="content-row content-container__odd">
        <table>
          <tbody class="cross-border">
            <tr class="tr" *ngFor="let row of driverDetails">
              <td class="td" *ngFor="let col of row">
                <span>
                  {{ col.name }}
                </span>
                <span class="subtitle">
                  {{ _data(col) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
     </div>
    </div>
</mat-card-content> -->


