import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ScheduleDurationByWeightDialogComponent } from "../schedule-duration-by-weight-dialog/schedule-duration-by-weight-dialog.component";

@Component({
  selector: "app-schedule-duration-by-weight",
  templateUrl: "./schedule-duration-by-weight.component.html",
  styleUrls: ["./schedule-duration-by-weight.component.scss"],
})
export class ScheduleDurationByWeightComponent {
  windows: any[] = [];
  @Input() hiddenElements: any;

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.getScheduleDuration();
  }

  addEdit(item?: any) {
    const dialogRef = this.dialog.open(ScheduleDurationByWeightDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: { ...item },
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleDuration();
    });
  }

  getScheduleDuration() {
    this.agendamentoService
      .indexSchedulingDurationByWeight()
      .subscribe((r: any) => {
        this.windows = r.data;
      });
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title:
          this.translate.instant("Business_model.attention_upper", {
            Default: "ATENÇÃO",
          }) + "!",
        message: this.translate.instant(
          "Business_model.delete_window_schedule",
          { Default: "Tem certeza que deseja APAGAR a janela de agendamento?" },
        ),
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.agendamentoService
          .deleteSchedulingDurationByWeight(id)
          .subscribe((r: any) => {
            if (!r.error) {
              this.getScheduleDuration();
            }
          });
      }
    });
  }
}
