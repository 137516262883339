import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tkg-card-details',
  templateUrl: './tkg-card-details.component.html',
  styleUrls: ['./tkg-card-details.component.scss']
})
export class TkgCardDetailsComponent implements OnInit {
  slots!: any[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.slots = Object.keys(data.detail_slots_fields);
  }

  ngOnInit(): void {
    console.log('detalhes');
    console.log(this.data);
  }
}
