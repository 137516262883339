import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { GridListComponent } from "../../grid-list/grid-list.component";
import { FormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-formly-dialog-checked-list",
  templateUrl: "./formly-dialog-checked-list.component.html",
  styleUrls: ["./formly-dialog-checked-list.component.scss"],
})
export class FormlyDialogCheckedListComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  selected_itens: any = [];
  id_list_selected: any = [];
  open: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ngModule: any;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
  ) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value) {
      this.id_list_selected = JSON.parse(this.formControl.value);
      this.getData().subscribe((r: any) => {
        this.selected_itens = r.data.resource.filter((ft: any) =>
          this.id_list_selected.includes(ft.id),
        );
        this.formControl.updateValueAndValidity();
      });
    }
  }

  openGridListModal(): void {
    this.open = new BehaviorSubject(false);

    const dialogRef = this.dialog.open(GridListComponent, {
      panelClass: ["dialog-large"],
      data: {
        title: "Escolha o Serviço",
        url: "/support-data/web?name=services_list",
        path: "resource",
        selected_list: this.id_list_selected,
      },
    });
    dialogRef.afterClosed().subscribe((gridList?: any) => {
      this.selected_itens = gridList;
      this.id_list_selected = this.getIdFromObject(this.selected_itens);
      this.formControl.setValue(this.id_list_selected);
    });
  }

  getIdFromObject(objectList: any) {
    let id_list: number[] = [];
    objectList = this.selected_itens;
    objectList.forEach((item: { id: any }) => {
      id_list.push(item.id);
    });
    return id_list;
  }

  getData(): Observable<any> {
    const url = `${environment.apiUrl}/support-data/web?name=services_list`;
    return this.http.get(url).pipe(
      map((response: any) => response),
      catchError((err: any) =>
        throwError(() => err || new Error("Server error")),
      ),
    );
  }
}
