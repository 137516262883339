<div class="top-bar-filters">
  <div class="container-filters">  
    <div fxFlex="25%" *ngIf="!isCarrier" fxFlex.xs="calc(50%-25px)">
      <mat-form-field appearance="outline">
        <mat-label>{{
          "Driver.driversList.carrier_label" | translate
        }}</mat-label>
        <mat-select [formControl]="filterCarrierSelected">
          <mat-option (click)="getFilterCarrier('')">{{
            "Driver.driversList.clear_filter" | translate
          }}</mat-option>
          <mat-divider></mat-divider>
          <mat-option
            *ngFor="let carrier of carriers"
            [value]="carrier.id"
            (click)="getFilterCarrier(carrier.id)"
          >
            {{ carrier.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{
          "Driver.driversList.status_label" | translate
        }}</mat-label>
        <mat-select  [formControl]="filterStatusSelected">
          <mat-option (click)="getFilterStatus('')">{{
            "Driver.driversList.clear_filter" | translate
          }}</mat-option>
          <mat-divider></mat-divider>
          <mat-option
            *ngFor="let ls of listStatus"
            [value]="ls.active"
            (click)="getFilterStatus(ls.active)"
          >
            {{ ls.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{
          "Driver.driversList.search_label" | translate
        }}</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          matTooltip="Limpar pesquisa"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.actions" | translate:{Default:"Ações"} }}
      </th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editarDriver($event, element, 'EDIT')">
            {{ "Driver.driversList.actions.edit_driver" | translate:{Default:"Editar Motorista"} }}
          </button>
          <button mat-menu-item (click)="verCNH($event, element, 'CNH')">
            {{ "Driver.driversList.actions.view_cnh" | translate:{Default:"Ver CNH"} }}
          </button>
          <button mat-menu-item (click)="verFoto($event, element, 'PHOTO')">
            {{ "Driver.driversList.actions.view_profile_picture" | translate:{Default:"Ver foto de perfil"} }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Driver.driversList.table_header.driver" | translate:{Default:"Motorista"} }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="cpf">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.cpf" | translate:{Default:"CPF"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.resource.cpf | mask: "000.000.000-00" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.phone" | translate:{Default:"Telefone"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.resource.phone | mask: "(00) 00000-0000" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="carrier">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.carrier" | translate:{Default:"Transportadora"} }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.carrier_name }}</td>
    </ng-container>

    <ng-container matColumnDef="cnh">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.cnh" | translate:{Default:"CNH"} }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.resource.cnh }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.status" | translate:{Default:"Status"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-chip-listbox>
          <mat-chip-option [ngClass]="{
            'app-chip-activated': element.active == true,
            'app-chip-deactivated': element.active == false
          }">
          {{
            element.active
              ? translateSource.chip.active
              : translateSource.chip.deactivated
          }}
          </mat-chip-option>
        </mat-chip-listbox>
      </td>
    </ng-container>

    <ng-container *ngIf="isAvailableTomorrow" matColumnDef="availabletomorrow">
      <th mat-header-cell *matHeaderCellDef>
        Disponível p/ Carregar
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-chip-listbox>
          <mat-chip-option [ngClass]="{
            'app-chip-activated': element.resource.availabletomorrow  == true,
            'app-chip-deactivated': element.resource.availabletomorrow == false
          }">
          {{
            element.resource.availabletomorrow
              ? 'Sim'
              : 'Não'
          }}
          </mat-chip-option>
        </mat-chip-listbox>
      </td>
    </ng-container>

    <ng-container *ngIf="haveValetDriver" matColumnDef="valet">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Driver.driversList.table_header.valet" | translate:{Default:"Manobrista"} }}
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-chip-listbox>
          <mat-chip-option [ngClass]="{
            'app-chip-activated': element.is_valet == true,
            'app-chip-deactivated': element.is_valet == false
          }">
          {{
            element.is_valet
                ? translateSource.chip.yes
                : translateSource.chip.no
          }}
          </mat-chip-option>
        </mat-chip-listbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
    (page)="alterPage($event)"
  >
  </mat-paginator>
</div>
<button
  mat-mini-fab
  (click)="editarDriver($event, undefined, 'ADD')"
  class="mat-fab-bottom-right"
  color="primary"
  *ngIf="isSuperUser || isCarrier"
>
  <mat-icon>add</mat-icon>
</button>
