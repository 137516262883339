<div class="container">
  <mat-form-field appearance="outline">
    <mat-label>Escolha uma Dashboard</mat-label>
    <mat-select (selectionChange)="getLookerEmbed($event)" [(value)]="selectedDashboardId">
      <mat-option *ngFor="let dashboard of dashboards" [value]="dashboard">
        {{ dashboard.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<iframe class="iframe-container " [src]="urlLooker" frameborder="0" height="1080"></iframe>