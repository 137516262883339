<mat-card class="dialog-detail">
  <mat-card-header>
    <mat-card-title class="tkg-title">{{data.title}}</mat-card-title>
  </mat-card-header>
  <div class="tkg-div-list">
    <mat-list>
      <mat-list-item *ngFor="let item of data.action.dialog_options" class="tkg-item-list" (click)="action(item)">
        <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
        {{item.title}}
      </mat-list-item>
     </mat-list>
  </div>


  <mat-card-actions align="end">
    <button type="submit" dkFocusInitial mat-raised-button color="primary" style="color: white;" mat-dialog-close>FECHAR</button>
  </mat-card-actions>
</mat-card>


