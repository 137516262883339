import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import { SupportDataService } from "../../shared/support-data.service";
import { CheckinService } from 'src/app/checkin/shared/checkin.service';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {
  dialogResult: any;
  form = new UntypedFormGroup({});
  model = this.data.action.component_model;
  fields: FormlyFieldConfig[] = this.data.action.component_schema;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public checkinService: CheckinService,
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    public supportDataService: SupportDataService
  ) { }

  ngOnInit() {
     console.log(this.data)
    // console.log('this.data')
    let iterItens = Object.keys(this.data.action.component_input_provider_api)
    // console.log('iterItens');
    // console.log(iterItens);
    iterItens.forEach(element=> {
      this.getSupportData(this.data.action.component_input_provider_api, element)
    })
  }


  onSubmit() {


    this.dialogResult = {
      ...this.model
    }
    this.dialogResult.id = this.data.item.id;
    this.dialogResult.message = this.data.action.dialogue_message;
    this.checkinService.updateField(this.data.action.editKeysAPI, this.dialogResult).subscribe((r:any)=>{
      if(r.status == 200) {
        this.dialogRef.close(this.dialogResult);
      }
    });
  }

  getSupportData(providersObj:any, element: any){
    let option = {name: element}
    console.log('option')
    this.supportDataService.generic(option).subscribe((r:any)=>{
      console.log(this.fields)
      let idx = this.fields.map((o) => o.key).indexOf(element);
      const key: any = idx as keyof typeof this;
      this.fields[key].props!.options = r.data.resource

      console.log(r.data.resource)
    })
  }

}
