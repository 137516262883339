<div class="page-container">
  <div class="lateral-container">
    <div class="tkg-grid">
      <div class="schedule-grid">
        <div id="schedule-0">
          <div class="lateral-itens">
            <p class="data">{{ selectedDate | date: "mediumDate" }}</p>
            <mat-calendar
              [selected]="selectedDate"
              [startAt]="startAt"
              [maxDate]="maxDate"
              (selectedChange)="onSelect($event)"
            ></mat-calendar>
            <form [formGroup]="form">
              <mat-form-field class="example-full-width" appearance="outline">
                <input
                  matInput
                  [placeholder]="
                    'Business_model.search' | translate: { Default: 'Buscar' }
                  "
                  value=""
                  formControlName="search"
                />
              </mat-form-field>
              <app-web-btn-filters
                [filterList]="filterList"
                origin="scheduling_dock_din_filters"
                (updateFilterList)="updateFilterList($event)"
              ></app-web-btn-filters>
              <div class="tkg-selected-filters" *ngIf="filterList.length">
                <span
                  class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
                  *ngFor="let item of filterList; index as idx"
                  (click)="removeFilterItem(idx)"
                >
                  <span class="material-icons tkg-chips-icon"> cancel </span>
                  <b class="tkg-chips-filter-name">{{ item.label }}</b>
                  <span class="tkg-chips-filter-value">{{ item.value }}</span>
                </span>
              </div>
            </form>
          </div>
        </div>

        <div id="schedule-1">
          <div class="tkg-spinner-container" *ngIf="showLoader">
            <div class="spinner-and-text">
              <mat-progress-spinner
                class="tkg-spinner"
                color="#1fd91d"
                mode="indeterminate"
                value="100"
              ></mat-progress-spinner>
              <div class="load-txt">
                {{
                  "Business_model.loading_schedule"
                    | translate: { Default: "Carregando agendamentos..." }
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-container">
    <div class="time-control">
      <div
        class="go2slot btn-go2slot-active"
        [ngClass]="{ 'btn-go2slot-active': go2slotActive === idx }"
        *ngFor="let column of slotTimeList; let idx = index"
        (click)="go2Anchor(idx)"
      >
        {{ column.slot }}
      </div>
    </div>
    <div class="schedule-container" #schedule>
      <table class="dock-schedule-table">
        <thead>
          <tr>
            <th class="sticky-column">Docas</th>
            <th *ngFor="let slot of slotTimeList">{{ slot.slot }}</th>
          </tr>
        </thead>
        <tbody *ngIf="slotTimeList && slotTimeList.length">
          <tr *ngFor="let dock of scheduleDocks">
            <td class="sticky-column">{{ dock.dock_name }}</td>
            <ng-container *ngFor="let slot of slotTimeList; let idx = index">
              <ng-container *ngIf="decreaseSkip()">
                <td
                  [colSpan]="renderCell(dock.dock_id, slot.slot)"
                  (click)="!showInfos(dock.dock_id, slot.slot).blocked && handleSlotClick(dock.dock_id, slot.slot)"
                  [class.blocked]="showInfos(dock.dock_id, slot.slot).blocked"
                  style="margin: 0px; padding: 0px"
                  [id]="'slot_' + idx"
                >
                  <div
                    class="tkg-schedule-slot"
                    [ngStyle]="{
                      'background-color': showInfos(dock.dock_id, slot.slot).background_color,
                      color: showInfos(dock.dock_id, slot.slot).color
                    }"
                  >
                    <p
                      class="tkg-schedule-slot-txt"
                      [ngStyle]="{
                        color: showInfos(dock.dock_id, slot.slot).color
                      }"
                    >
                      {{ showInfos(dock.dock_id, slot.slot).text }}
                    </p>
                  </div>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
