import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';
import { HubListComponent} from './hub-list/hub-list.component';


const hubRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/hub',
    component: HubListComponent,
    data: {
      title: 'Dash On Running',
      search: true,
      refresh: true,
      btnStatusPanel: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(hubRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class HubRoutingModule {
}
