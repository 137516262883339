import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';

const documentsRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'documents',
        component: DocumentsListComponent,
        data: {
          title: 'Remessas',
          search: false,
          refresh: false
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(documentsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DocumentsRoutingModule { }
