import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsPlanedListComponent } from './events-planed-list/events-planed-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';


const eventsRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'planed-events',
        component: EventsPlanedListComponent,
        data: {
          title: 'Planed Events',
          search: true,
          refresh: true
        },
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(eventsRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class EventsPlanedRoutingModule {
}
