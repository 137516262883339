import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EventsService {
  private eventsSource = new BehaviorSubject<string>("");
  currentEvent = this.eventsSource.asObservable();

  constructor() { }

  publishEvent(event: string) {
    this.eventsSource.next(event);
  }
}
