<button
  class="tkg-filter-btn"
  mat-raised-button
  style="color: white"
  color="primary"
  (click)="openDinFilters()"
>
  Filtros
  <mat-icon iconPositionEnd>{{ filterTitle }}</mat-icon>
</button>
