import { Component, OnInit } from '@angular/core';
import { CheckinDialogComponent } from '../checkin-dialog/checkin-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  openCheckin() {
    const dialogRef = this.dialog.open(CheckinDialogComponent, {
      panelClass: ['dialog-fullscreen-np'],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(""))
      }
    });

    dialogRef.afterClosed().subscribe(data => {
    });
  }
}
