<mat-card class="dialog-detail">
  <mat-card-header>
    <mat-card-title>Detalhes</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let slot of slots">
        <span matListItemTitle>{{data.detail_fields_labels[data.detail_slots_fields[slot]]}}</span>
        <span matListItemLine *ngIf="data.detail_slots_fields[slot] === 'status_name'; else normalValue">
          <div class="status-row">
            <div class="status-box" [ngStyle]="{'background-color': data.datasource['status_color']}"></div>
            <div>{{data.datasource[data.detail_slots_fields[slot]]}}</div>
          </div>
        </span>
        <ng-template #normalValue>
          <span matListItemLine>{{data.datasource[data.detail_slots_fields[slot]]}}</span>
        </ng-template>
      </mat-list-item>
    </mat-list>

  </mat-card-content>
  <mat-card-actions align="end">
    <button type="submit" mat-button color="primary" mat-dialog-close>FECHAR</button>
  </mat-card-actions>
</mat-card>


