import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/material";
import { FieldTypeConfig } from "@ngx-formly/core";
import { debounceTime, startWith } from "rxjs/operators";
import { SupportDataService } from "src/app/shared/support-data.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-formly-ss-support-data",
  templateUrl: "./formly-ss-support-data.component.html",
  styleUrls: ["./formly-ss-support-data.component.scss"],
})
export class FormlySsSupportDataComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  _formControl = new FormControl();
  ngModule: any;
  filter: any[] = [];
  filterOriginal: any[] = [];
  selectedItem = false;

  constructor(private supportDataService: SupportDataService) {
    super();
  }

  ngOnInit() {
    this._formControl.valueChanges
      .pipe(startWith(""), debounceTime(200))
      .subscribe((value: string) => {
        if (value) {
          this.applyFilter(value);
        } else {
          this.resetFilter();
        }
      });
    this.startRequest();
  }

  applyFilter(value: string) {
    if (typeof value === "string") {
      const ft = this.filter.filter((ft: any) =>
        ft.name.toLowerCase().includes(value.toLowerCase()),
      );
      this.filter = ft;
    }
  }

  displaySelected(opt: any) {
    if (opt) {
      this.formControl.setValue(opt.id);
      return opt && opt.name ? opt.name : "";
    }
  }

  resetFilter() {
    this.formControl.setValue("");
    this.formControl.defaultValue;
    this._formControl.defaultValue;
    this.ngModule = "";
    this.filter = [...this.filterOriginal];
  }

  startRequest() {
    if (this.props?.support_data_by_name) {
      this.supportDataService
        .byName({
          name: this.props?.support_data_by_name,
        })
        .subscribe((r: any) => {
          this.filter = r;
          this.filterOriginal = [...this.filter];
        });
    }
  }
}
