import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { DashboardService } from "../shared/dashboard.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";
import { ModalRankingPermanenciaComponent } from "../modal-ranking-permanencia/modal-ranking-permanencia.component";
import { ModalLiberacaoEntradaComponent } from "../modal-liberacao-entrada/modal-liberacao-entrada.component";
import { ModalDirecionamentoComponent } from "../modal-direcionamento/modal-direcionamento.component";
import { ModalRankingAguardandoLiberacaoComponent } from "../modal-ranking-aguardando-liberacao/modal-ranking-aguardando-liberacao.component";
import { RefreshService } from "src/app/shared/refresh.service";
import { ModalPanelStatusLorealComponent } from "../modal-panel-status/modal-panel-status.component";
import { webSocket } from "rxjs/webSocket";
import { environment } from "src/environments/environment.prod";
import { SupportDataService } from "src/app/shared/support-data.service";
import { SystemNotificationsService } from './../../shared/system-notifications.service';
import * as moment from "moment";
import { WssService } from "src/app/shared/websocket/wss.service";
import { Subscription, auditTime, merge } from "rxjs";
@Component({
  selector: "app-loreal-dashboard-list",
  templateUrl: "./dashboard-list.component.html",
  styleUrls: ["./dashboard-list.component.scss"],
})
export class DashboardListComponent implements OnInit, OnDestroy {
  @ViewChild("fs") fs: ElementRef;

  media_motorista_cd: string;
  media_aguardando_liberacao: string;
  quantidade_liberar: number;
  balanco_de_massa: number = 0;
  quantidade_direcionamento: number;
  driver_status = {
    checkin: 0,
    estacionado: 0,
    saindo: 0,
    patio: 0,
    doca: 0,
    saida: 0,
  };

  quantidade_operacao_inbound: number;
  quantidade_operacao_outbound: number;
  quantidade_operacao_devolucao: number;
  quantidade_operacao_dfd: number;

  rk_permanencia: any;
  rk_aguardando_liberacao: any;

  // se o valor for um aumento setar UP
  setaup = "call_made";
  // se o valor for uma queda setar DOWN
  setadown = "call_received";
  breakpoints = Breakpoints;
  screenRatio: any;
  account: any;

  departments: any = [];
  department_id: any;
  user: any;
  filterLabels: any = [];
  notifications: any = [];
  hasNotifications : boolean = false;
  value: any;


  @Output() reloadEvent = new EventEmitter();
  wsSubscription: Subscription;

  constructor(
    private refreshService: RefreshService,
    private dialog: MatDialog,
    public dashboard: DashboardService,
    public breakpointObserver: BreakpointObserver,
    public supportData: SupportDataService,
    public systemNotifications : SystemNotificationsService,
    private websocketService: WssService
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.HandsetPortrait,
        Breakpoints.Web,
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });

    const user = JSON.parse(localStorage.getItem('account') || "[]");
    if(user)
      this.user = user;
  }

  ngOnDestroy(): void {
    this.wsSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "[]");
    this.loadNotifications();
    this.labelFilterEnabled();
    this.loadDepartments();
    this.refreshService.refresh.subscribe(() => {
      this.carregaKPIs();
    });
    this.carregaKPIs();
    let id = JSON.parse(localStorage.getItem("account") || "[]").system_client.id;

    this.wsSubscription = merge(
      this.refreshService.refresh,
      this.websocketService
      .connect(`${environment.wsUrl}/checkin/${id}`)
    )
    .pipe(auditTime(20000))//20 segundos de intervalo entre as chamadas.
    .subscribe(
      () => {
        this.carregaKPIs()
      }
    );
  }

  carregaKPIs(department_id?: any) {

    if(this.user.department_id.length == 1){
      department_id = this.user.department_id[0];
    }

    this.tempoMedioDirecionamento(department_id);
    this.mediaAguardandoLiberacao(department_id);
    this.operacao_inbound(department_id);
    this.operacao_outbound(department_id);
    this.operacao_devolucao(department_id);
    this.operacao_dfd(department_id);
    this.ranking_permanencia(department_id);
    this.ranking_aguardando_liberacao(department_id);

    this.liberacao(department_id);
    this.direcionamento(department_id);
    this.driverStatus(department_id);
    this.parkStatus(department_id);
  }

  tempoMedioDirecionamento(department_id?: any) {
    department_id = department_id? department_id : '';
    if(this.account.system_client.id != 5 || this.account.system_client.id != 109){
      return this.dashboard.tempoMedioMotoristaCD({department_id}).subscribe((media: any) => {
        this.media_motorista_cd = (this.segundosParaHoraMinuto(
          parseInt(media.hoje)
        ) || '');
      });
    }

    if(this.account.system_client.id == 5 || this.account.system_client.id == 109){
      return this.dashboard.tempoMedioMotoristaCDwithStatus({'status': 'waiting_direction', department_id}).subscribe((media: any) => {
        this.media_motorista_cd = (this.segundosParaHoraMinuto(
          parseInt(media.hoje)
        ) || '');
      });
    }

    return null
  }

  mediaAguardandoLiberacao(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.mediaAguardandoLiberacao({department_id}).subscribe((media: any) => {
      this.media_aguardando_liberacao = (this.segundosParaHoraMinuto(
        parseInt(media.data)
        ) || '');
    });
  }

  operacao_inbound(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.operacao_inbound({department_id}).subscribe((media: any) => {
      this.quantidade_operacao_inbound = media.qtde;
    });
  }

  operacao_outbound(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.operacao_outbound({department_id}).subscribe((media: any) => {
      this.quantidade_operacao_outbound = media.qtde;
    });
  }

  operacao_devolucao(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.operacao_devolucao({department_id}).subscribe((media: any) => {
      this.quantidade_operacao_devolucao = media.qtde;
    });
  }

  operacao_dfd(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.operacao_dfd({department_id}).subscribe((media: any) => {
      this.quantidade_operacao_dfd = media.qtde;
    });
  }

  ranking_permanencia(department_id?: any) {
    department_id = department_id ? department_id : '';
    this.dashboard.ranking_permanencia({ limit: 5, department_id }).subscribe({
      next: (r: any) => {
        this.rk_permanencia = r.data;
      },
      error: (e: any) => {
      }
    });
  }

  ranking_aguardando_liberacao(department_id?: any) {
    department_id = department_id ? department_id : '';
    this.dashboard.rankingAguardandoLiberacao({ limit: 5, department_id }).subscribe({
      next: (r: any) => {
        this.rk_aguardando_liberacao = r.data;
      },
      error: (e: any) => {
      }
    });
  }

  liberacao(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.liberacao({department_id}).subscribe((media: any) => {
      this.quantidade_liberar = media.qtde;
    });
  }

  direcionamento(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.direcionamento({department_id}).subscribe((media: any) => {
      this.quantidade_direcionamento = media.qtde;
    });
  }

  driverStatus(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.driverStatus({department_id}).subscribe((data: any) => {
      this.driver_status = data;
    });
  }

  parkStatus(department_id?: any) {
    department_id = department_id? department_id : '';
    return this.dashboard.parkStatus({department_id}).subscribe((data: any) => {
      this.balanco_de_massa = data;
    });
  }

  labelFilterEnabled() {
    this.filterLabels = this.user.system_client.resource.environment.pages['dashboard']["data_filters_label"];
  }

  calcData(diff: any) {
    if (diff) {
      let dia: string;
      let hours: string;
      let minutes: string;
      let seconds: string;
      let resultado: string[];

      resultado = diff.split(":", 3);

      hours = resultado[0] + "h";
      if (hours.includes("day") || hours.includes("days")) {
        hours = hours.replace("days", "d");
        hours = hours.replace("day", "d");
      }

      minutes = resultado[1] + "m";
      seconds = resultado[2].substring(0, 2) + "s";

      return [hours, minutes, seconds].join(":");
    }
    return 0;
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      //console.log("XSmall");
      this.screenRatio = "4:3";
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      //console.log("Small");
      this.screenRatio = "4:3";
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      //console.log("Medium");
      this.screenRatio = "7:6";
    } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      //console.log("Large");
      this.screenRatio = "4:3";
    } else if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      //console.log("XLarge");
      this.screenRatio = "16:9";
    } else {
      this.screenRatio = "1:1";
    }
  }

  // Modals
  modalPainelStatus() {
    const dialogRef = this.dialog.open(ModalPanelStatusLorealComponent, {
      panelClass: ["dialog-large-black"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify("")),
      },
    });
  }

  modalRankingPermanencia() {
    const dialogRef = this.dialog.open(ModalRankingPermanenciaComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify("")),
        department_id: this.department_id? this.department_id: ''
      },
    });
  }

  modalRankingAguardandoLiberacao() {
    const dialogRef = this.dialog.open(ModalRankingAguardandoLiberacaoComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify("")),
        department_id: this.department_id? this.department_id: ''
      },
    });
  }

  modalLiberacaoEntrada() {
    const dialogRef = this.dialog.open(ModalLiberacaoEntradaComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify("")),
      },
    });
  }

  modalDirecionamento() {
    const dialogRef = this.dialog.open(ModalDirecionamentoComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify("")),
      },
    });
  }
  segundosParaHoraMinuto(segundos: number ) {
    if (segundos) {
      return moment
        .duration(segundos, "seconds")
        .format("DD[d] hh[h]mm[m]");
    } else {
      return null;
    }
  }

  loadDepartments() {
    const options = {
      name: 'departments'
    }
    this.supportData.departments(options).subscribe({
      next: (r: any) => {
        this.departments = r.data.resource;

        this.departments = this.departments.filter((item: any) => {
          return this.account.department_id.indexOf(item.id) > -1;
        })

      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  filterDockSpace(department_id?: any) {
    this.department_id = department_id;
    this.carregaKPIs(department_id);
  }

  loadNotifications() {
    this.systemNotifications.notifications().subscribe({
      next: (r: any) => {
        this.notifications = r.data;
        if (this.notifications.length > 0) this.hasNotifications = true
      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }
}
