import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { SupportDataService } from "src/app/shared/support-data.service";

@Component({
  selector: "app-checkin-carrier-rules-dialog",
  templateUrl: "./checkin-carrier-rules-dialog.component.html",
  styleUrls: ["./checkin-carrier-rules-dialog.component.scss"],
})
export class CheckinCarrierRulesDialogComponent implements OnInit {
  constructor(
    private carrierService: CarrierService,
    public supportDataService: SupportDataService,
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CheckinCarrierRulesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  carriers: any[] = [];
  rules: any[] = [];
  form = this.formBuilder.group({
    carrier_id: [this.data.id || "", Validators.required],
    rule_id: [this.data.rule_id || "", Validators.required],
  });

  ngOnInit() {
    this.getCarriers();
    this.getRules();
  }
  close() {
    this.dialogRef.close();
  }
  getCarriers() {
    this.carrierService
      .index({
        orderBy: "name",
        sortedBy: "ASC",
        page: 1,
        pageSize: 200,
      })
      .subscribe((data) => {
        this.carriers = (data as any).data;
      });
  }
  getRules() {
    this.supportDataService
      .generic({ name: "aditional_checkin_rule" })
      .subscribe((r) => {
        this.rules = r.data.resource || [];
      });
  }
  onSubmit() {
    this.carrierService.storeRule(this.form.value).subscribe((r: any) => {
      this.close();
    });
  }
}
