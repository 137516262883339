import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CartService } from '../../cart/shared/cart.service'
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SupportDataService } from '../../shared/support-data.service';

@Component({
  selector: 'app-vehicle-search-dialog',
  templateUrl: './vehicle-search-dialog.component.html',
  styleUrls: ['./vehicle-search-dialog.component.scss']
})
export class VehicleSearchDialogComponent implements OnInit {

  vehicles: any = [];
  listStatus: any = [];
  dockSpaces: any = [];
  allDataAvailable: boolean = false;
  form = this.formBuilder.group({
    board: ["", Validators.required]
  });
  constructor(
    public dialogRef: MatDialogRef<VehicleSearchDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    public cartService: CartService,
    public supportDataService: SupportDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.getUnlinkedVehicles(this.data)
    this.getDockSpaces()
    this.getListStatus()
  }

  getUnlinkedVehicles(data: any) {
    const options = {
      vehicle_type: data.type,
      carrier_id: data.carrier_id
    }

    this.cartService.unlinked(options).subscribe((r) => {
      this.vehicles = r.data;
    })
  }

  onSubmit() {
    this.dialogRef.close(this.form.value.board)
  }

  close() {
    this.dialogRef.close('');
  }


  getListStatus() {

    this.supportDataService.status().subscribe((r: any) => {
      this.listStatus = r.data.resource;
    });
  }

  getDockSpaces() {
    this.supportDataService.dockSpaces().subscribe((r: any) => {
      this.dockSpaces = r.data.resource;
    });
  }

  showStatus(resource: any) {
    const st = this.listStatus.filter((item: any) => item.id === resource.status);
    if (resource.status === 'on_dock') {
      return this.getDockName(resource.dock_space_id);
    } else {
      if(st.length > 0 ){
        return st[0].alternate_name;
      } else {
        return ''
      }

    }
  }

  getDockName(id: number) {
    if (id > 0) {
      const dn = this.dockSpaces.filter((item: any) => item.id === id);
      if (dn.length > 0) {
        return dn[0].name;
      }
    } else {
      '--'
    }
  }
}
