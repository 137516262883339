<mat-card class="mat-elevation-z8 padding-16">
  <div class="animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
  <div class="margin-top animated-background">
    <div class="background-masker"></div>
  </div>
</mat-card>

