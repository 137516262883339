import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-aditional-info-dialog',
  templateUrl: './aditional-info-dialog.component.html',
  styleUrls: ['./aditional-info-dialog.component.scss']
})
export class AditionalInfoDialogComponent implements OnInit {
  user: any;
  form = this.formBuilder.group({
    localkey: ["", Validators.required]
  });
  label!: string;
  dialogResult: any = {};
  message: string = "";

  constructor(
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AditionalInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // Tests to check if it's a specific user by looking at their system_client.id,
  // If yes, set localKey validation to null
  setUserValidation(userId: number) {
    const formItem = this.form.get('localkey');

    if(userId === 8) {
      formItem!.setValidators(null);
    }
  }

  ngOnInit() {
    this.label = this.data.action.aditionalKeys[0].label;
    this.message = this.data.action.dialogue_message;
    this.user = JSON.parse(localStorage.getItem('account')!);
    this.setUserValidation(this.user.system_client.id);
  }

  onSubmit() {
    this.dialogResult.id = this.data.item.id;
    this.dialogResult.status = this.data.action.status;
    this.dialogResult.change_key = this.data.action.change_key
    this.dialogResult[this.data.action.aditionalKeys[0].key] = this.form.value.localkey;
    this.dialogRef.close(this.dialogResult);
  }

}
