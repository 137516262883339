import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import {WeekDayNames} from './shifts';

@Pipe({
  name: 'conversion'
})
export class ConversionPipe implements PipeTransform {
  private datePipe = new DatePipe("pt-BR");
  transform(value: any, args: any): any {
    switch(args?.pipe){
      case "datetime":
        return this.datePipe.transform(value, args.format || 'dd/MM/yyyy hh:mm');
      case "booelan":
        return value ? args.format[0] : args.format[1]
      case "weekdaynamely":
        return value?.map((v:number)=>WeekDayNames[v])?.join(", ")

    }
    return value;
  }
}
