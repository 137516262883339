import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteParamsChangeService {
  private changedSubject = new Subject<boolean>();
  changed = this.changedSubject.asObservable();

  constructor() { }

  onChange() {
    this.changedSubject.next(true);
  }
}
