import { UntypedFormControl } from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { CheckinService } from 'src/app/checkin/shared/checkin.service';

@Component({
  selector: 'app-dialog-unlink-driver',
  templateUrl: './dialog-unlink-driver.component.html',
  styleUrls: ['./dialog-unlink-driver.component.scss']
})
export class DialogUnlinkDriverComponent implements OnInit {
  dialogResult: any;
  unlinkSelected: UntypedFormControl = new UntypedFormControl();
  isDisabled = false;
  selected: any;
  constructor(
    public dialogRef: MatDialogRef<DialogUnlinkDriverComponent>,
    public checkinService: CheckinService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if(this.data.item.resource.totalBoard && this.data.item.resource.totalBoard === 1) {
      this.isDisabled = true;
    }
  }

  onSubmit() {
    this.selected = 2;
    this.dialogResult = {
      unlinkType: this.unlinkSelected.value,
      status: this.data.item.resource.status,
      id: this.data.item.id,
      trucker_checkin_id: this.data.item.trucker.resource.id,
      unlinkDriver: true,
      totalBoard: this.data.item.trucker.resource.totalBoard
    }
    this.checkinService.updateField('checkins', this.dialogResult).subscribe((r:any)=>{
      if(r.status == 200) {
        this.dialogRef.close(this.dialogResult);
      }
    });
  }
}
