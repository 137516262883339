import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  constructor(private http: HttpClient) { }

  index(options?: any): Observable<any> {
    const httpParams = Object.assign(
      {
        page: 1,
        orderBy: "name",
        sortedBy: "asc",
      },
      options
    );

    const params = new HttpParams({ fromObject: httpParams });

    const url = `${environment.apiUrl}/places`;

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error') ))
    );
  }

  show(id: number): Observable<any> {
    const url = `${environment.apiUrl}/places/${id}`;

    return this.http.get<any>(url,).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error') ))
    );
  }

  update(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/places`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error') ))
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/places`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error') ))
    );
  }

  destroy(id: string, params?: any) {
    const url = `${environment.apiUrl}/places/${id}`;
    const options = { params: params };

    return this.http.delete(url, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error') ))
    );
  }

  buscaCEP(cep: string): Observable<any> {
    const url = `${environment.apiUrl}/cep/${cep}`;

    return this.http.get<any>(url,).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error') ))
    );
  }
}
