<h2 class="mat-align-center" mat-dialog-title>{{data.title || "Confirmar entrada"}}</h2>

<div mat-dialog-content>
  <h3 mat-dialog-title>{{message}}</h3>
  <div class="form-container">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{label}}</mat-label>
        <input matInput formControlName="localkey">
      </mat-form-field>
    </form>
  </div>

</div>


<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">Cancelar</button>
  <button type="submit" mat-raised-button cdkFocusInitial style="color: white;" color="primary" (click)="onSubmit()"
    [disabled]="!form.valid">Salvar</button>
</div>
