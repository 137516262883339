import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormControl } from "@angular/forms";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { format } from "date-fns";

@Component({
  selector: "app-set-pre-schedule-date-time",
  templateUrl: "./set-pre-schedule-date-time.component.html",
  styleUrls: ["./set-pre-schedule-date-time.component.scss"],
})
export class SetPreScheduleDateTimeComponent {
  day: any;
  tz: string;
  timeSlots: any[] = [];
  form = new FormGroup({
    date: new FormControl(new Date()),
    time: new FormControl(""),
  });
  constructor(
    public dialogRef: MatDialogRef<SetPreScheduleDateTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public agendamentoService: AgendamentoService,
  ) {
    this.day = this.getDay();
    this.tz = this.getTimeZone();
    this.getTimeSlots(
      this.data.department_id,
      this.data.operation_id,
      this.data.suboperation_id,
    );
  }

  getDay() {
    const dayLocale = new Date().toLocaleDateString();
    const dayArray = dayLocale.split("/");
    return `${dayArray[2]}-${dayArray[1]}-${dayArray[0]}`;
  }

  save() {
    const opt = {
      start_at: `${this.day}T${this.form.value.time}${this.tz}`,
    };
    this.dialogRef.close({ save: true, ...opt });
  }

  switchDay() {
    this.day = this.form.value.date?.toISOString().split("T")[0];
    this.getTimeSlots(
      this.data.department_id,
      this.data.operation_id,
      this.data.suboperation_id,
    );
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  }

  // Busca os slots
  getTimeSlots(
    department_id: number,
    operation_id: number,
    suboperation_id: number,
    vehicle_type?: any,
  ) {
    if (operation_id) {
      const options = {
        department_id: department_id,
        operation_id: operation_id,
        suboperation_id: suboperation_id,
        day: format(new Date(this.day), "yyyy-MM-dd"),
        ofsset: this.tz,
      };

      this.agendamentoService.multi_time_slots(options).subscribe({
        next: (r: any) => {
          this.timeSlots = r;
        },
        error: (error) => {
          console.log("error", error);
        },
      });
    }
  }
}
