import { Component, NgModule, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  imports: [CommonModule, NgxDocViewerModule],
  standalone: true,
  selector: 'app-document-viewing',
  templateUrl: './document-viewing.component.html',
  styleUrls: ['./document-viewing.component.scss']
})

export class DocumentViewingComponent {
  documentUrl = this.data.document_url

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
