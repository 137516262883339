<div class="top-bar-filters">
  <!-- <meta http-equiv="refresh" content="10" /> -->
  <!-- <button mat-raised-button (click)="modalPainelStatus()">
    Ver Painel de Status
  </button> -->
  <div class="notify_card" *ngFor="let notify of notifications">
    <div>
      <span class="notify-description">
        {{ notify.resource.message }}
      </span>
      <div class="fill"></div>
      <i class="material-icons icon_cor36">query_builder</i>
    </div>
  </div>

  <div class="filters">
    <div *ngIf="this.user.department_id.length > 1">
      <mat-form-field appearance="outline">
        <mat-label>{{ this.filterLabels? this.filterLabels.department : 'Área' }}</mat-label>
        <mat-select [(ngModel)]="value" (selectionChange)="filterDockSpace($event.value)">
          <mat-option [value]="">Limpar filtro</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let item of departments" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="dash-cards">
    <!-- card 1.1 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Tempo médio dos motoristas no CD</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions_car </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4">
          {{ media_motorista_cd ? media_motorista_cd : "00:00" }}
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card 1.2 -->
    <mat-card class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Operações ativas</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-blue">
          <i class="material-icons icon_cor45"> query_builder </i>
        </div>
      </div>
      <mat-card-content class="meio_docas col-md-12">
        <div class="etapa-docas">
          <div class="col-md-3">
            Inbound
            <div class="mat-display-5">
              {{
                quantidade_operacao_inbound ? quantidade_operacao_inbound : "0"
              }}
            </div>
          </div>
          <div class="col-md-3">
            Outbound
            <div class="mat-display-5">
              {{
                quantidade_operacao_outbound
                  ? quantidade_operacao_outbound
                  : "0"
              }}
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card 1.3 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Ranking de permanência</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-orange">
          <i class="material-icons icon_cor36"> query_builder </i>
        </div>
      </div>

      <mat-card-content class="box-list">
        <div class="box-container" *ngFor="let item of rk_permanencia">
          <div class="box-item box-l">{{ item?.name }}</div>
          <div class="box-item box-r">
            {{ segundosParaHoraMinuto(item?.time_on_cd) }}
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer
        class="footer_card btn-footer"
        *ngIf="rk_permanencia && rk_permanencia.length > 0"
      >
        <button mat-raised-button (click)="modalRankingPermanencia()">
          Ver detalhes
        </button>
      </mat-card-footer>
    </mat-card>

    <!-- card 2.1 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Aguardando direcionamento</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2">
          {{ quantidade_direcionamento ? quantidade_direcionamento : "0" }}
        </div>
      </mat-card-content>
      <mat-card-footer class="footer_card">
        <button mat-raised-button (click)="modalDirecionamento()">
          Ver detalhes
        </button>
      </mat-card-footer>
    </mat-card>

    <!-- card 2.2 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top_docas">Aguardando liberação de entrada</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-blue">
          <i class="material-icons icon_cor45"> directions </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4-2">
          {{ quantidade_liberar ? quantidade_liberar : "0" }}
        </div>
      </mat-card-content>
      <mat-card-footer class="footer_card">
        <button mat-raised-button (click)="modalLiberacaoEntrada()">
          Ver detalhes
        </button>
      </mat-card-footer>
    </mat-card>

    <!-- card 2.3 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Motoristas por status</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green c-orange">
          <i class="material-icons icon_cor36"> person </i>
        </div>
      </div>
      <mat-card-content>
        <mat-grid-list class="driver_status" cols="12" rowHeight="50px">
          <mat-grid-tile class="driver_status_header" colspan="3" rowspan="1">
            Check-in
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_header" colspan="3" rowspan="1">
            Pátio
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_header" colspan="3" rowspan="1">
            Doca
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_header" colspan="3" rowspan="1">
            Processo de Saída
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_counter" colspan="3" rowspan="1">
            <span>{{ driver_status.checkin || 0 }}</span>
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_counter" colspan="3" rowspan="1">
            <span>{{ driver_status.estacionado || 0 }}</span>
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_counter" colspan="3" rowspan="1">
            <span>{{ driver_status.doca || 0 }}</span>
          </mat-grid-tile>
          <mat-grid-tile class="driver_status_counter" colspan="3" rowspan="1">
            <span>{{ driver_status.saindo || 0 }}</span>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>

    <!-- card 3.1 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">
            Tempo médio dos motoristas Aguardando liberação de entrada
          </div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions_car </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4">
          {{ media_aguardando_liberacao }}
        </div>
      </mat-card-content>
    </mat-card>

    <!-- card 3.2 -->
    <mat-card
      class="card">
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Ranking Aguardando liberação de entrada</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-orange">
          <i class="material-icons icon_cor36"> query_builder </i>
        </div>
      </div>

      <mat-card-content class="box-list">
        <div class="box-container" *ngFor="let item of rk_aguardando_liberacao">
          <div class="box-item box-l">{{ item?.name }}</div>
          <div class="box-item box-r">
            {{ segundosParaHoraMinuto(item?.time_waiting_release) }}
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer
        class="footer_card"
        *ngIf="rk_aguardando_liberacao?.length > 0"
      >
        <button mat-raised-button (click)="modalRankingAguardandoLiberacao()">
          Ver detalhes
        </button>
      </mat-card-footer>
    </mat-card>

    <!-- card 3.3 -->
    <mat-card
      class="card"
      *ngIf="this.account.system_client.id != 5"
    >
      <div class="card_top">
        <mat-card-header class="foto">
          <div class="texto_top">Balanço de Massa</div>
        </mat-card-header>
        <div class="fill"></div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions_car </i>
        </div>
      </div>
      <mat-card-content>
        <div class="mat-display-4">{{ balanco_de_massa }} %</div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
