import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DashboardV1Service {

  constructor(private http: HttpClient) {}

  getAllDashCardData(options?: any): Observable<any> {
    const httpParams = Object.assign({
    }, options);
    const params = new HttpParams({ fromObject: httpParams })
    const url = `${environment.apiUrl}/dashboard-cards-v1`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || 'Server error'))
    );
  }



}
