import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldTypeConfig } from "@ngx-formly/core";
import { FieldType } from "@ngx-formly/material";

@Component({
  selector: 'app-week-days-list',
  templateUrl: './week-days-list.component.html',
  styleUrls: ['./week-days-list.component.scss']
})
export class WeekDaysListComponent extends FieldType<FieldTypeConfig>{
  days: string[] = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  selectedDays: number[] = [];

  constructor(private translate: TranslateService) {
    super();
  }

  toggleDay(day: number): void {
    const index = this.selectedDays.indexOf(day);
    if (index > -1) {
      this.selectedDays.splice(index, 1); 
    } else {
      this.selectedDays.push(day);
    }

    this.formControl.setValue(this.selectedDays);
    this.formControl.markAsDirty(); 
    this.formControl.markAsTouched(); 
  }

  setWeek(weekday: any): string {
    const week: any = {
      "1": this.translate.instant("Time.monday", { Default: "Segunda-feira" }),
      "2": this.translate.instant("Time.tuesday", { Default: "Terça-feira" }),
      "3": this.translate.instant("Time.wednesday", { Default: "Quarta-feira" }),
      "4": this.translate.instant("Time.thursday", { Default: "Quinta-feira" }),
      "5": this.translate.instant("Time.friday", { Default: "Sexta-feira" }),
      "6": this.translate.instant("Time.saturday", { Default: "Sábado" }),
      "7": this.translate.instant("Time.sunday", { Default: "Domingo" }),
    }

    const weekdays = this.removeBrackets(weekday).split(", ");
    return this.translate.instant("Time.each_week", { Default: "Por semana: " }) + `${weekdays.map((day: string) => week[day]).join(", ")}`;
  }

  removeBrackets(days: string): string {
    return days.replace(/\[|\]/g, "");
  }
}
