import { Component } from '@angular/core';

@Component({
  selector: 'app-visual-form-builder',
  templateUrl: './visual-form-builder.component.html',
  styleUrls: ['./visual-form-builder.component.scss']
})
export class VisualFormBuilderComponent {

}
