import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(
    private http: HttpClient
  ) { }

  show(id: number): Observable<any> {
    const url = `${environment.apiUrl}/checklist/${id}`;

    return this.http.get<any>(url,).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/checklist`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  update(data: any) {
    const url = `${environment.apiUrl}/checklist`;

    return this.http.put(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}
