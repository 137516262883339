import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class DepartmentService {
  constructor(private http: HttpClient) { }

  index(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/department`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }


}
