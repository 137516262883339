import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { ErrorNotFoundComponent } from '../error-not-found/error-not-found.component';

//Retorna a primeira página de menu pages com order = 1 para esse cliente
//Variável redirectPage que guarda a url da página é definida com export pois variáveis locais não são suportadas por decorators
export let redirectPage: string = '/admin/login';

//Pega os detalhes do cliente do usuário atual
const account = JSON.parse(localStorage.getItem('account') || '{}');

//Verifica se account foi encontrado, se foi o usuário está logado
if (account) {
  //Pega a lista de todas as página cadastradas em menu_pages para esse cliente
  const menuPages = account.menu;

  //Verifica se menuPages é array e se não está vazio
  //Se for um array válido pega a primeira página com parametro order = 1 e com route não null ou undefined
  if (Array.isArray(menuPages) && menuPages.length > 0) {
    let firstPage = menuPages.filter(page => page.order === 1 && page.routes[0] || page.resource && page.resource.order === 1 && page.resource.route)[0];

    if (firstPage && firstPage.routes) {
      firstPage.resource = firstPage.routes[0].resource
    }
    //Se a primeira página e sua route não forem null ou undefined retorna a rota dessa página
    if (firstPage && firstPage.resource.route) {
      redirectPage = firstPage.resource.route;

      //Caso ao contrário pega a primeira página que tem um route não null ou undefined do array de menuPages
    } else {
        redirectPage = [...menuPages].concat(
          menuPages.reduce((pivot, item)=>{
              if(!item.resource && item.routes?.length > 0)
                pivot = pivot.concat(item.routes);
              return pivot;
            },
          [])
        )
        .filter(page => page.resource?.route)[0]?.resource?.route;
    }
  }
}

const dashboardRoutes: Routes = [

  //Faz o roteamento da página caso o usuário acesse a url base sem nenhuma url de página extra informada
  //Se redirectPage estiver vazio o usuário e redirecionado para a tela de login pois assume que não está logado
  //No evento que estiver logado e redirectPage não for uma URL válido o usuário é redirecionado a uma tela de erro
  { runGuardsAndResolvers: 'always',
    path: '',
    redirectTo: redirectPage ? redirectPage : '/admin/login',
    pathMatch: 'full' },
  {
    //Direcionamento para a ErrorNotFoundComponent
    //Verifica qualquer um passando pela url admin/login e com as propriedades canActivate e AccountGuardService verifica se foi feito o login
    //Caso não tenha um login ele é então redirecionado para a tela de login
    //Caso possua login é redirecionado para uma tela de erro
    path: 'admin/login',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: ErrorNotFoundComponent,
        data: {
          title: 'Não Encontrado',
          search: false,
          refresh: false
        }
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(dashboardRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule {
}
