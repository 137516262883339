import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriversListComponent } from './drivers-list/drivers-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';

const driversRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/drivers',
    component: DriversListComponent,
    data: {
      title: 'Motoristas',
      search: false,
      refresh: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(driversRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class DriversRoutingModule {
}
