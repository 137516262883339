import { OnDestroy, TemplateRef } from '@angular/core';
import { SupportDataService } from './../../shared/support-data.service';
import { CarrierService } from './../../carrier/shared/carrier.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { forkJoin, of as observableOf } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { AppComponent } from 'src/app/app.component';
import { DashboardV1Service } from '../shared/dashboard-v1.service';
import { SystemNotificationsService } from './../../shared/system-notifications.service';


@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit, OnDestroy {
  @ViewChild("menu") menu: TemplateRef<any>;
  form = new UntypedFormGroup({});
  model: any = {};
  dashboardComponent: string;
  schedulingDashboard = true;
  dashboardInitialSelectedIndex: number = 0;
  isOnTrial = true;
  hasNotifications : boolean = false;
  currentUser: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  defaultFields:FormlyFieldConfig[] = [
    {
      key: 'range',
      type: 'formly-datepicker-range',
      props: {
        label: 'Período',
        placeholder: 'Selecione o período',
        appearance: 'outline'
      },
   },
   {
     key: 'operation',
     type: 'formly-autocomplete',
     props: {
       label: 'Operação',
       placeholder: 'Selecione a operação',
       appearance: 'outline'
     },
   },
   {
     key: 'carrier_id',
     type: 'formly-input-search',
     props: {
       label: 'Transportadora',
       placeholder: 'Selecione a transportadora',
       appearance: 'outline'
     },
   }
  ];
  simple_dash_data: any = [];
  graphic_dash_data: any = [];
  notifications: any = [];
  constructor(
    private carrierService:CarrierService,
    private supportDataService:SupportDataService,
    private dashboardV1Service:DashboardV1Service,
    private app:AppComponent,
    public systemNotifications : SystemNotificationsService
  ) {

    const user = JSON.parse(localStorage.getItem('account') || "[]");
    if(user) {
      this.currentUser = user;
    }

   }

  ngOnDestroy() {
    this.app.customToolbar = null;
  }

  ngOnInit() {
    this.loadNotifications();

    if(this.currentUser.system_client.resource.environment.pages.dashboard) {
      //Verifica se on_trial não é null ou undefined, e então verifica se é false pois false deve ser permitido nesse caso
      if(this.currentUser.system_client.on_trial || this.currentUser.system_client.on_trial == false) {
        this.isOnTrial = this.currentUser.system_client.on_trial;
      }

      //Verifica se use_scheduling_dashboard não é null ou undefined, e então verifica se é false pois false deve ser permitido nesse caso
      if(this.currentUser.system_client.resource.environment.pages.dashboard.use_scheduling_dashboard || this.currentUser.system_client.resource.environment.pages.dashboard.use_scheduling_dashboard == false) {
        this.schedulingDashboard = this.currentUser.system_client.resource.environment.pages.dashboard.use_scheduling_dashboard;
      }

      //Verifica se dashboard_component não é null ou undefined, e então verifica se é false pois false deve ser permitido nesse caso
      if(this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_component || this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_component == false) {
        this.dashboardComponent = this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_component;
      }

      //Verifica se dashboard_initial_selected_index não é null ou undefined, e então verifica se possui valor 'yard' ou 'scheduling' se sim
      //A aba aberta inicialmente ao mudar para a tela de dashboard-v1 deve ser
      //Gestão de Pátio para dashboardInitialSelectedIndex = 'yard' ou Agendamento para dashboardInitialSelectedIndex = 'scheduling'
      if (this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_initial_selected_index
        && this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_initial_selected_index == 'yard') {

        this.dashboardInitialSelectedIndex = 1;

      } else if (this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_initial_selected_index
        && this.currentUser.system_client.resource.environment.pages.dashboard.dashboard_initial_selected_index == 'scheduling') {

        this.dashboardInitialSelectedIndex = 0;
      }

    }

    this.app.customToolbar = {template:this.menu,reverse:false};

    forkJoin([
      this.loadOperations()
    ])
    .subscribe( ([operations]) => {
      this.defaultFields[1].props!.filter = (term: any) => observableOf(term ? this.filterStates(term, operations) : operations);

      this.defaultFields[2].props!.service =
      {
        startWith:"",
        debounceTime: 300,
        service:(search:string)=>{
          const options = {
            orderBy: 'name',
            sortedBy: 'ASC',
            page: 1,
            pageSize: 20,
            search
        }
          return this.carrierService.index(options).pipe(
          map((result:any)=>result?.data || []),
          catchError(() => observableOf({data:[]}))
        )},
      };

      this.form.valueChanges
      .pipe(debounceTime(500))
      .pipe(switchMap((filters:any) => {
        return this.dashboardV1Service.getAllDashCardData({
          operation_id: filters.operation?.id || '',
          carrier_id: filters.carrier_id?.id || '',
          start_at: filters.range?.start ? moment(filters.range.start).format("YYYY-MM-DD") : '',
          end_at: filters.range?.end ? moment(filters.range.end).format("YYYY-MM-DD") : ''
        }).pipe(
          catchError(() => observableOf({data:[]}))
        );
      }))
      .subscribe(data => {
        this.simple_dash_data = data.simple_dash_data;
        this.graphic_dash_data = data.graphic_dash_data;
      })

      this.fields = this.defaultFields;

    });
  }

  private loadOperations(department_id?:any){
    return this.supportDataService.operation({
      orderBy: 'updated_at',
      sortedBy: 'DESC',
      department_id:department_id || ""
    })
    .pipe(
      map((response: any) => response.data.resource)
    )
    .pipe(
      catchError(() =>  observableOf([]))
    )
  }

  loadNotifications() {
    this.systemNotifications.notifications().subscribe({
      next: (r: any) => {
        this.notifications = r.data;
        if (this.notifications.length > 0) this.hasNotifications = true

      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  private filterStates(name: string, list:Array<any>=[]) {
    return list.filter(state => state.name.toLowerCase().indexOf((""+name).toLowerCase()) === 0);
  }

}
