import { Routes, RouterModule } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';

const routes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/dashboard-v1',
    component: DashboardListComponent,
    data: {
      title: 'Dashboard',
      search: true,
      refresh: true
    }
  }
];

export const DashboardV1RoutingRoutes = RouterModule.forChild(routes);
