<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <mat-list>
    <mat-list-item *ngFor="let item of itemList" required>
          <li><b>{{item.label}}</b> {{item.data}}</li>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button style="color: white;" color="primary" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
