import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { GroupsListComponent } from './groups-list/groups-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';

const userRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/users',
    component: UserListComponent,
    data: {
      title: 'Usuários',
      search: false,
      refresh: false,
    }
  },
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/groups',
    component: GroupsListComponent,
    data: {
      title: 'Grupos',
      search: false,
      refresh: false,
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(userRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule { }
