import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PainelMotoristaListComponent } from './painel-motorista-list/painelmotorista-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';

const painelmotoristaRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/loreal/painelmotoristas',
    component: PainelMotoristaListComponent,
    data: {
      title: 'Painel de Motoristas',
      search: true,
      refresh: true,
      btnStatusPanel: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(painelmotoristaRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class PainelMotoristaRoutingModule {
}
