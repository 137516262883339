import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-delete-recurring-schedule',
  templateUrl: './dialog-delete-recurring-schedule.component.html',
  styleUrls: ['./dialog-delete-recurring-schedule.component.scss']
})
export class DialogDeleteRecurringScheduleComponent implements OnInit {
  selectedOption: number; // Propriedade para armazenar o valor selecionado

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteRecurringScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.selectedOption = 1;
  }

  onConfirm() {
    this.dialogRef.close(this.selectedOption);
  }
}
