<mat-card>
<form [formGroup]="form">
  <h2 mat-dialog-title>
    {{ "Driver.driversFotoPerfil.profile_picture" | translate:{Default:"FOTO PERFIL"} }} -
    <span style="font-size: .85em;">{{ form.value.name }}</span>
  </h2>
  <div
    mat-dialog-content
    class="mat-typography"
    style="text-align: center"
    *ngIf="data.item.driver.resource.photoPerfil"
  >
    <div class="div-image" *ngIf="!loadComponent">
      <img
        [src]="data.item.driver.resource.photoPerfil"
        height="360"
        layout-align="center"
      />
    </div>
  </div>
  <mat-card-content *ngIf="!data.item.driver.resource.photoPerfil">
    <div style="text-align: center">
      {{ "Driver.driversFotoPerfil.no_picture" | translate:{Default:"-- Não Existe Foto de Perfil"} }}
    </div>
  </mat-card-content>
  <mat-dialog-actions align="end">
    <button type="submit" mat-button color="warn" mat-dialog-close="true">
      {{ "Driver.driversFotoPerfil.close_button" | translate:{Default:"Fechar"} }}
    </button>

    <button mat-raised-button style="color: white; margin-left: 5px;" color="primary" (click)="exibirAvisoFoto()">
      {{ "Driver.driversFotoPerfil.edit_button" | translate:{Default:"Editar"} }}
    </button>

   <!--  <button mat-button color="secondary" (click)="loadMyChildComponent()">
      Usar Webcam
    </button> -->

    <input
      type="file"
      id="uploadFoto"
      (change)="fileEvent($event)"
      style="display: none"
    />
  </mat-dialog-actions>
</form>
</mat-card>
<!--
<div class="webcamContainer">
  <div *ngIf="loadComponent">
    <app-drivers-foto-perfil-webcam
      (cancel)="loadComponent = !$event"
      (pictureTaken)="handleImage($event)"
      (fileEvent)="fileEvent($event)"
    ></app-drivers-foto-perfil-webcam>
  </div>

  <div class="snapshot" *ngIf="webcamImage">
    <img *ngIf="loadComponent"
      class="imgWebcam"
      style="
        height: 129px;
        position: relative;
        border: 1px solid white;
        border-radius: 5px;
        transform: translate(285px, -339px);
      "
      [src]="webcamImage.imageAsDataUrl"
    />
  </div>
</div> -->
