import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarrierListComponent } from './carrier-list/carrier-list.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { environment } from '../../environments/environment';

const carrierRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'carriers',
        component: CarrierListComponent,
        data: {
          title: 'Transportadoras',
          search: false,
          refresh: false,
          // export: {
          //   show: true,
          //   url: `${environment.apiUrl}/carriers/download`,
          // }
        },
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(carrierRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CarrierRoutingModule { }
