<!--Card Dashboard - Inicio desenvolvimento 22/02/2022-->
<mat-card [ngClass]="'card-'+layout">
  <!--Title-->
  <mat-card-title [ngClass]="'title-'+layout">{{data.title}}</mat-card-title>
  <mat-divider inset></mat-divider>
  <!--Content/Body-->
  <ng-container *ngTemplateOutlet="[one,two][layout-1]"></ng-container>
</mat-card>

<!--Layout 1-->
<ng-template #one>
  <mat-card-content>
    <span>{{data.value}}</span>
  </mat-card-content>
</ng-template>

<!--Layout 2-->
<ng-template #two>
  <mat-card-content>
    <div echarts [options]="chartOptions" class="chart" style="max-height: 250px;"></div>
  </mat-card-content>
</ng-template>

