import { ScheduleAntecedenceDialogComponent } from './../schedule-antecedence-dialog/schedule-antecedence-dialog.component';
// import { format, parse } from 'date-fns';
import { DialogConfirmComponent } from '../../../shared/dialog-confirm/dialog-confirm.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { UserService } from 'src/app/user/shared/user.service';

@Component({
  selector: 'app-schedule-antecedence',
  templateUrl: './schedule-antecedence.component.html',
  styleUrls: ['./schedule-antecedence.component.scss']
})
export class ScheduleAntecedenceComponent implements OnInit {
  antecedences: any[] = [];
  groups: any[] = [];
  @Input() hiddenElements: any;

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getGroups();
  }

  addEdit(item?: any) {
    const dialogRef = this.dialog.open(ScheduleAntecedenceDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {...item},
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleAntecedences();
    });
  }

  getScheduleAntecedences() {
    this.agendamentoService.indexSchedulingAntecedence().subscribe((r: any) => {
      this.antecedences = r.data;
    });
  }


  getGroups(){
    const options = { pageSize: 100 };
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
      this.getScheduleAntecedences();
    });
  }

  showGroupName(ids: string) {
    if(ids) {
      const group_ids = JSON.parse(ids);
      let groupNames: any[] = [];
      this.groups.forEach(item => {
        if(group_ids.includes(item.id)) {
          groupNames.push(item.name);
        }
      });
      return groupNames.join(', ');
    } else {
      return '';
    }
  }


  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'ATENÇÃO!',
        message: `Tem certeza que deseja APAGAR a antecedência de agendamento?`
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.agendamentoService.deleteSchedulingAntecedence(id).subscribe((r: any) => {
          if(!r.error) {
            this.getScheduleAntecedences();
          }
        });
      }
    });
  }

}
