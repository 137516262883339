import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DriversService } from '../shared/drivers.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DocumentsService } from 'src/app/documents/shared/documents.service';
// import {WebcamImage} from 'ngx-webcam';
import { of } from 'rxjs';

@Component({
  selector: 'app-drivers-foto-perfil',
  templateUrl: './drivers-foto-perfil.component.html',
  styleUrls: ['./drivers-foto-perfil.component.scss']
})

export class DriversFotoPerfilComponent implements OnInit {
  loadComponent: boolean;


  form = this.formBuilder.group({
    name: [this.data.item.driver.name, [Validators.required]],
    resource: this.formBuilder.group({
      // cnh: [this.data.item.resource.cnh, [Validators.required]],
      photoPerfil: [this.data.item.driver.resource.photoPerfil],
    })
  });


  webcamImage: any | undefined;
  filedata: any;
  documents: any[] = [];

  fileEvent(e: any) {
    this.filedata = e.target.files[0];
    console.log('files', this.filedata);
    console.log(typeof(this.filedata));
    this.onUpload();
  }

  errors: any = {};


  constructor(
    public dialogRef: MatDialogRef<DriversFotoPerfilComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private driversService: DriversService,
    private dialog: MatDialog,
    public documentsService: DocumentsService,
  ) { }

  ngOnInit() {
    //console.log('motorista data', this.data.item.driver);
  }

  onUpload() {

    if (this.isValidImage(this.filedata)) {

      const formData = new FormData();
      formData.append('file', this.filedata);
      formData.append('driver_id', this.data.item.driver.id);
      //Informa para a API que é o arquivo é uma imagem da tela de motoristas
      formData.append('file_type', 'driver_photo');
      this.filedata.inProgress = true;

      this.documentsService.upload(formData).pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.filedata.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }

        }),

        catchError((error: HttpErrorResponse) => {
          this.filedata.inProgress = false;
          //Caso não seja encontrado o motorista durante o processo de upload
          //Retorna mensagem de erro para o usuário
          if (error.status == 400) {

            this.dialog.open(DialogAlertComponent, {
              data: { title: error.error.title, message: error.error.message },
            });

            return of(`Motorista não encontrado, upload abortado`);
          } else {
            return of(`File upload failed.`);
          }
        })

      ).subscribe((event: any) => {
        if (typeof (event) === 'object') {

          //test webcam

          //Chama método para atualizar a url da foto do motorista no banco
          //Campo photoType informa para a API se é o campo 'photoPerfil' (Perfil) ou 'photo' (CNH) do motorista que irá receber a nova url
          this.driversService.updateDriverPhotos({ url: event.body.url, photoType: 'photoPerfil' }, this.data.item.driver.id).subscribe(
            (data) => {
              //Se atualização da URL for feita com sucesso, atualiza o html com a nova imagem para o usuário
              this.data.item.driver.resource.photoPerfil = event.body.url;
            },
            (err) => {
              if (err.status === 422) {
                this.errors = err.error.errors;
              } else {
                this.dialog.open(DialogAlertComponent, {
                  data: { title: err.error.title, message: err.error.message },
                });
              }
            }
          );
        }

      });
    }

  }

  loadMyChildComponent() {
    this.loadComponent = true;
  }

  exibirAvisoFoto() {
    document.getElementById('uploadFoto')?.click();
  }

  isValidImage(file: any) {
    if (file.size > 5000000) {
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Erro', message: 'Tamanho da imagem não pode ser maior que 5MB!' }
      });
      return false;
    }
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      this.dialog.open(DialogAlertComponent, {
        data: { title: 'Erro', message: 'Tipo de arquivo não é suportado, por favor escolha um tipo PNG ou JPEG!' }
      });
      return false;
    }
    return true;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  rotateImg(imgUrl: any) {
    let img = new Image();
    img.src = imgUrl;
    if (img.width > img.height) {
      return 'rotate(270deg)'
    } else {
      return 'rotate(0deg)'
    }
  }

  // handleImage(webcamImage: WebcamImage) {
  //   this.webcamImage = webcamImage;
  // }
}



      //const path = `imagens/motorista/perfil/${this.form.value.id}-${this.filedata.name}`;
      // const fileRef = this.storage.ref(path.replace(/\s/g, ''));
      // this.storage.upload(path.replace(/\s/g, ''), this.filedata).then(up => {
      //   fileRef.getDownloadURL().subscribe(url => {
      //     console.log(url)
      //     this.form.value.resource.photoPerfil = url;
      //     this.data.item.driver.resource.photoPerfil = url;
      //     console.log('--------------xxxxxxx');
      //     console.log(this.data.item.driver);
      //     this.driversService.update(this.data.item.driver, this.data.item.driver.id).subscribe(data => {
      //       console.log(data)
      //       // this.dialogRef.close(data);
      //     }, err => {
      //       if (err.status === 422) {
      //         this.errors = err.error.errors;
      //       } else {
      //         this.dialog.open(DialogAlertComponent, {
      //           data: { title: err.statusText, message: err.error.message }
      //         });
      //       }
      //     });
      //   })
      // })
