import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgendamentoService } from './../../../multiagendamento/shared/agendamento.service';
import { UserService } from 'src/app/user/shared/user.service';
import { ScheduleStatusDialogComponent } from '../schedule-status-dialog/schedule-status-dialog.component';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-schedule-status',
  templateUrl: './schedule-status.component.html',
  styleUrls: ['./schedule-status.component.scss']
})
export class ScheduleStatusComponent implements OnInit {
  status: any[] = [];
  groups: any[] = [];
  listScheduleStatus: any[] = [];
  @Input() hiddenElements: any;

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private userService: UserService,
    private supportDataService: SupportDataService
  ) { }

  ngOnInit() {
    this.getGroups();
    this.getListScheduleStatus();
  }

  getListScheduleStatus(){
    this.supportDataService.scheduleStatus().subscribe((r: any) => {
      this.listScheduleStatus = r.data.resource;

      this.getScheduleStatus();
    });
  }


  getGroups(){
    const options = {pageSize: 100};
    this.userService.userGroups(options).subscribe((r: any) => {
      this.groups = r.data;
      this.getScheduleStatus();
    });
  }

  getScheduleStatus() {
    this.agendamentoService.indexSchedulingStatus().subscribe((r: any) => {
      this.status = r.data;
    });
  }


  addEdit(item?: any) {

    //Para máquina de vendas, verifica se status_on_create e status_on_info_edit é um id de números apenas
    //Se sim faz o JSON.parse para que ao editar status de agendamentos em MV os campos já venham preenchidos corretamente com os dados salvos
    if(item) {
      if(/^\d+$/.test(item.status_on_create) && /^\d+$/.test(item.status_on_info_edit)) {
        item.status_on_create = JSON.parse(item.status_on_create);
        item.status_on_info_edit = JSON.parse(item.status_on_info_edit);
      }
    }

    const dialogRef = this.dialog.open(ScheduleStatusDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {...item},
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleStatus();
    });
  }

  showGroupName(ids: any) {
    if(ids) {
      const group_ids = JSON.parse(ids);
      let groupNames: any = [];
      this.groups.forEach(item => {
        if(group_ids.includes(item.id)) {
          groupNames.push(item.name);
        }
      });
      return groupNames.join(', ');
    } else {
      return '';
    }
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'ATENÇÃO!',
        message: `Tem certeza que deseja APAGAR o status de agendamento?`
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.agendamentoService.deleteSchedulingStatus(id).subscribe((r: any) => {
          if(!r.error) {
            this.getScheduleStatus();
          }
        });
      }
    });
  }

  showListScheduleStatusName(ids: any) {
    if(ids) {
      const list_ids = JSON.parse(ids);
      let listNames: any[] = [];

      this.listScheduleStatus.forEach(item => {
        if(list_ids.includes(item.id)) {
          listNames.push(item.name);
        }
      });
      return listNames.join(', ');
    } else {
      return '';
    }
  }

  showScheduleStatusName(statusId: any) {
    if(statusId) {
      let statusName;

      this.listScheduleStatus.forEach(item => {
        if(statusId == item.id) {
          statusName = item.name;
        }
      });

      if(statusName) {
        return statusName;
      }
      return '-';
    } else {
      return '-';
    }
    return ""
  }
}
