<button mat-mini-fab (click)="cancel()" id="x">X</button>
<h1 mat-dialog-title>Ranking de Permanência</h1>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="drivername">Motorista</th>
      <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>
    <ng-container matColumnDef="cdTime">
      <th mat-header-cell *matHeaderCellDef class="aumentar col-5">Tempo no CD</th>
      <td mat-cell *matCellDef="let element" class="aumentar col-5">
        {{segundosParaHoraMinuto(element.time_on_cd)}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
