

<div *ngIf="dialogType == 'ok_only'; else report_email_sent">
  <h2 mat-dialog-title>{{ data.title || "Janela de mensagem"}}</h2>
  <mat-dialog-content class="mat-typography">
    <p>{{data.message}}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button style="color: white;" color="primary" mat-dialog-close>OK</button>
  </mat-dialog-actions>
</div>

<ng-template #report_email_sent>
  <ng-container *ngIf="dialogType == 'report_email_sent'; else warning">

    <mat-dialog-actions align="end">
      <button mat-mini-fab style="color: white; background-color: black;" color="primary" mat-dialog-close>X</button>
    </mat-dialog-actions>

    <mat-dialog-content class="report_content">
      <i class="material-icons" style="color: #54d959;font-size: 120px;">task_alt</i>
      <div class="report_message" style="padding: 0px 0 8px 0;">
        <h3>{{data.message || "O relatório está sendo processado e será enviado para o seu e-mail."}}</h3>
        <br />
        <div style="display: flex; flex-direction: row; align-items: center;">
          <i class="material-icons" style="color: #54d959;">mail</i>
          <p style="padding-left: 10px;"> Verifique a Caixa de Entrada ou Spam</p>
        </div>
      </div>
    </mat-dialog-content>

  </ng-container>
</ng-template>

<ng-template #warning>
  <ng-container *ngIf="dialogType == 'warning'; else error" >
    <mat-dialog-content style="margin: 0;">
      <i class="material-icons" style="color: yellow;font-size: 36px;">warning</i>
      <h4 class="attention">ATENÇÃO!</h4>
      <div class="message" style="padding: 0px 0 8px 0;">
        <p *ngFor="let message of data.message?.split('\n')">{{message}}</p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-stroked-button color="primary" [mat-dialog-close]="'cancel'">CANCELAR</button>
      <button mat-flat-button color="primary" style="color:white" [mat-dialog-close]="'ok'">REGISTRAR MESMO ASSIM</button>
    </mat-dialog-actions>
  </ng-container>
</ng-template>

<ng-template #error>
  <div class="warning-title">
    <i class="material-icons">report</i>
    <h2>{{ data.title || "Mensagem de erro" }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <h3>{{data.message || "Ocorreu um erro durante a execução."}}</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" style="color: white;" [mat-dialog-close]="'ok'">OK</button>
  </mat-dialog-actions>
</ng-template>

