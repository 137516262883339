<div class="grid grid-4-columns">
  <div *ngFor="let report of reports">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>{{ ("" + report.translation_title) | translate:{Default: report.title} }}</mat-card-title>
      </mat-card-header>
      <mat-card-actions>

        <!--Relatório enviado por e-mail-->
        <button
          mat-button
          color="primary"
          (click)="generateEmailReport(report.type)"
          *ngIf="report.router && account?.system_client?.resource?.environment?.email_reports; else view_report"
        >
          {{ "Business_model.generate" | translate:{Default: "Gerar"} }}
        </button>

        <!--Relatório de visualização-->
        <ng-template #view_report>
        <button
        mat-button
        color="primary"
        [routerLink]="report.router"
        *ngIf="report.router"
      >
        {{ "Business_model.display" | translate:{Default: "Exibir"} }}
      </button>
      </ng-template>

        <button
          mat-button
          color="primary"
          (click)="downloadReport($event, report)"
          *ngIf="report.download_source"
        >
        {{ "Business_model.download" | translate:{Default: "Download"} }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
