import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-empty',
  templateUrl: './list-empty.component.html',
  styleUrls: ['./list-empty.component.scss']
})
export class ListEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
