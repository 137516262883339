<button mat-mini-fab (click)="cancel()" id="x">X</button>
<h1 mat-dialog-title>Aguardando Direcionamento</h1>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="drivername">Motorista</th>
      <td mat-cell *matCellDef="let element">{{element.driver.name}}</td>
    </ng-container>

    <ng-container matColumnDef="carrier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="carriername">Transportadora</th>
      <td mat-cell *matCellDef="let element">{{element.carrier.name}}</td>
    </ng-container>

    <ng-container matColumnDef="cnh">
      <th mat-header-cell *matHeaderCellDef>CNH</th>
      <td mat-cell *matCellDef="let element">{{element.driver.resource.cnh}}</td>
    </ng-container>

    <ng-container matColumnDef="operation">
      <th mat-header-cell *matHeaderCellDef>Operação</th>
      <td mat-cell *matCellDef="let element">{{nameOfOperation(element.resource.operation)}}</td>
    </ng-container>

    <ng-container matColumnDef="entry">
      <th mat-header-cell *matHeaderCellDef>H. Check-in</th>
      <td mat-cell *matCellDef="let element">{{element.resource.waiting_created_at | date: 'dd/MM/yy HH:mm'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
