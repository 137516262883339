import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { CoreModule } from '../core/core.module';
import { ReportDynamicRoutingModule } from './report-routing.module';
import { FileTypeSelectorComponent } from './file-type-selector/file-type-selector.component';
import { EmailReportDialogV1Component } from './email-report-dialog-v1/email-report-dialog-v1.component';
import { ReportDynamicFilterComponent } from './report-dynamic-filter/report-dynamic-filter.component';
import { ReportsDynamicPainelComponent } from './reports-dynamic-painel/reports-dynamic-painel.component';
import { ReportDynamicListComponent } from './report-dynamic-filter/report-dynamic-list/report-dynamic-list.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportListDialogFiltersComponent } from './report-list-dialog-filters/report-list-dialog-filters.component';
import { PaginateSortTableComponent } from '../shared/paginate-sort-table/paginate-sort-table.component';
import { LoadingTableComponent } from '../shared/loading-table/loading-table.component';
import { SharedModule } from '../shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { GlobalService } from '../shared/util/global.service';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    CoreModule,
    SharedModule,
    ReportDynamicRoutingModule,
    PaginateSortTableComponent,
    LoadingTableComponent
  ],
  declarations: [
    ReportsDynamicPainelComponent,
    ReportDynamicListComponent,
    ReportDynamicFilterComponent,
    FileTypeSelectorComponent,
    EmailReportDialogV1Component,
    ReportListComponent,
    ReportListDialogFiltersComponent,
  ],
  providers: [

    // TODO: Assim que for adicionado suporte para o usuário escolher o idioma do sistema a propriedade useValue
    // Pode ser alterada para pegar o locale dinamicamente, Ex:
    // { provide: MAT_DATE_LOCALE, useValue: "en-us" },
    // pt-br padrão de data DD/MM/YYYY
    GlobalService,
    { provide: MAT_DATE_LOCALE, useValue: "pt-br" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ReportDynamicListModule {}
