<div class="container">
  <div class="header" disabled>
    <h3>{{ props.label || 'Itens Selecionados' }}</h3>
    <button mat-icon-button (click)="openGridListModal()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="tkg-container-control">
    <div class="item-list" *ngIf="selected_itens.length > 0">
      <div *ngFor="let item of selected_itens" class="item">
        {{ item.name }}
      </div>
    </div>
  </div>
</div>
