<h3 class="mat-align-center" mat-dialog-title>Upload de Documentos</h3>

<div mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="form">
      <mat-form-field
        appearance="outline"
        [style.display]="departments.length === 1 ? 'none' : 'show'"
      >
        <mat-label
          >{{ "Business_model.client" | translate:{Default: "Cliente"}
          }}</mat-label
        >
        <mat-select
          [placeholder]="'Messages.choose_client' | translate:{Default: 'Escolha o cliente'}"
          formControlName="department_id"
          (selectionChange)="departmentChange()"
        >
          <mat-option [value]="item.id" *ngFor="let item of departments"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label
          >{{ "Business_model.operation" | translate:{Default: "Operação"}
          }}</mat-label
        >
        <mat-select
          [placeholder]="'Messages.choose_operation' | translate:{Default: 'Escolha a operação'}"
          formControlName="operation_id"
          (selectionChange)="operationChange()"
        >
          <mat-option [value]="item.id" *ngFor="let item of operationsFiltered"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="showSuboperation">
        <mat-label
          >{{ "Business_model.suboperation" | translate:{Default: "Suboperação"}
          }}</mat-label
        >
        <mat-select
          [placeholder]="'Messages.choose_suboperation' | translate:{Default: 'Escolha a suboperação'}"
          formControlName="suboperation_id"
          [disabled]="nonEditableField"
        >
          <mat-option
            [value]="item.id"
            *ngFor="let item of suboperationsFiltered"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label
          >{{ "Messages.avaible_status_actions" | translate:{Default:
          "Documentos a serem enviados"} }}</mat-label
        >
        <mat-select
          [placeholder]="'Messages.choose_status_actions' | translate:{Default: 'Escolha os documentos disponíveis por operação'}"
          formControlName="upload_document_type"
          multiple
        >
          <mat-option [value]="item.id" *ngFor="let item of document_type_list"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label
          >{{ "Messages.just_goups" | translate:{Default: "Válido apenas para
          o(s) grupo(s)"} }}</mat-label
        >
        <mat-select formControlName="groups" multiple>
          <mat-option *ngFor="let item of groups" [value]="item.id"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </form>
    <div mat-dialog-actions class="tkg-dialog-actions-end">
      <button mat-stroked-button color="warn" (click)="close()">
        {{ "Business_model.cancel" | translate:{Default: "Cancelar"} }}
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!form.valid"
      >
        {{ "Business_model.save" | translate:{Default: "Salvar"} }}
      </button>
    </div>
  </div>
</div>
