import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  private refreshSubject = new Subject<any>();
  refresh = this.refreshSubject.asObservable();

  constructor() { }

  onRefresh(event?: Event) {
    this.refreshSubject.next(event);
  }
}
