<mat-form-field [appearance]="to.appearance || 'outline'">
  <mat-label>{{ to.label || "" }}<span *ngIf="field.props?.required"> *</span></mat-label>
  <input
    matInput
    [type]="to.type || 'text'"
    [formControl]="formControl"
    [mask]="to.mask || null"
    [pattern]="to.pattern || null"
    [minlength]="to.minlength || null"
    [maxlength]="to.maxlength || null"
  >
  <mat-error *ngIf="formControl.invalid">
    {{ messageError() }}
  </mat-error>
</mat-form-field>
