import { DialogComponent } from './../../multiagendamento/dialog/dialog.component';
import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-denydialog',
  templateUrl: './denydialog.component.html',
  styleUrls: ['./denydialog.component.scss']
})
export class DenydialogComponent {
  value = null;
  denymotives = [
    "Pedido bloqueado",
    "Pedido sem saldo",
    "CNPJ divergente",
    "Material sem necessidade imediata"
  ];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogComponent>,
  ) { }

  confirm(){
    this.dialogRef.close({action: 'denied', motive: this.value});
  }

  cancel(){
    this.dialogRef.close({action: 'cancel', motive: this.value});
  }

}
