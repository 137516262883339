<mat-toolbar>
  <span style="margin-left: 20px;">
    <img
    src="assets/img/logo-trackage-green-black-new.png"
    width="145px"
    height="23px"
    class="espaco_logo"
    alt="Logotipo"
    />
  </span>
  <span class="spacer"></span>
 <!--  <button  mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>print</mat-icon>
    Imprimir
  </button> -->
  <button mat-dialog-close mat-icon-button>
    <mat-icon style="color: #256028;">arrow_forward</mat-icon>
  </button>
</mat-toolbar>

<div class="container">
  <div class="title">
    <h1>{{formatDate(data.created_at)}} - {{this.data.name}}</h1>
  </div>
  <div class="content">
    <h3><mat-icon>done</mat-icon>Resolvido</h3>
    <p>{{data.resource.resolved}}</p>
    <span>Postado {{dateDifference(data.resource.resolved_created_at)}}</span>
  </div>
  <div class="content">
    <h3><mat-icon>monitor_heart</mat-icon>Monitorando</h3>
    <p>{{data.resource.monitoring}}</p>
    <span>Postado {{dateDifference(data.resource.monitoring_created_at)}}</span>
  </div>
  <div class="content">
    <h3><mat-icon>build</mat-icon>Identificado</h3>
    <p>{{data.resource.finded}}</p>
    <span>Postado {{dateDifference(data.resource.finded_created_at)}}</span>
  </div>
  <div class="content">
    <h3><mat-icon>search</mat-icon>Investigando</h3>
    <p>{{data.resource.investigating}}</p>
    <span>Postado {{dateDifference(data.resource.investigating_created_at)}}</span>
  </div>
    <button type="button" class="close-button" mat-dialog-close>Voltar ao Health Check</button>
</div>


