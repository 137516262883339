<div class="events-container-box">
  <mat-toolbar color="primary" class="my-tool-bar">
    <mat-toolbar-row>
      <span>{{definedDay | date: 'mediumDate'}}
        <button mat-raised-button class="add-btn" (click)="openDialog({status: 'available'})">Novo
          pré-agendamento</button>
        <button mat-raised-button class="down-btn" (click)="download()">Baixar Relatório</button>
      </span>
      <span class="fill-remaining-space"></span>
      <span>
        <form [formGroup]="dayForm">
          <mat-form-field>
            <input matInput [matDatepicker]="picker" formControlName="day" (dateChange)="switchDay()">
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </form>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-grid-list cols="12" [rowHeight]="dashboardHeight" class="event-container">
    <mat-grid-tile colspan="1" rowspan="1">
      <div class="hours-container">
        <mat-grid-list cols="12" rowHeight="50px">
          <mat-grid-tile class="time" colspan="12" rowspan="1">
            Horários
          </mat-grid-tile>
          <mat-grid-tile class="time" *ngFor="let timeslot of timeslots" colspan="12" rowspan="1">
            {{timeslot.hour | date: 'shortTime'}}
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-grid-tile>
    <mat-grid-tile rowspan="1" colspan="11">
      <div class="docks-container">
        <div class="dock-item" *ngFor="let dock of docks">
          <div class="dock-header dock-section">{{dock.name}}</div>
          <div class="dock-section"
            [ngClass]="{'denied': schedule.status === 'denied', 'waiting': schedule.status === 'waiting', 'confirmed': schedule.status === 'confirmed', 'blocked': schedule.status === 'blocked'}"
            *ngFor="let schedule of dock.schedules" (click)="openDialog(schedule)">
            <div>
              <h5>{{schedule.driver_name}}</h5>
              <ng-container *ngIf="schedule.status !== 'blocked'">
              <h5 *ngIf="schedule.cpf">CPF: {{schedule.cpf}}</h5>
              </ng-container>
              <ng-container *ngIf="schedule.status == 'blocked'">
                <h5>Bloqueado</h5>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
