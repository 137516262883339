import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatCardModule } from "@angular/material/card";
import { DatePipe } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { DialogConfirmComponent } from "../dialog-confirm/dialog-confirm.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-simple-table",
  standalone: true,
  imports: [CommonModule, MatTableModule, MatCardModule, MatIconModule],
  templateUrl: "./simple-table.component.html",
  styleUrls: ["./simple-table.component.scss"],
})
export class SimpleTableComponent implements OnInit, OnChanges {
  @Input("displayedColumns") displayedColumns: string[];
  @Input("dataSource") dataSource: any;
  @Input("columnsSchema") columnsSchema: any;
  @Input("transformData") transformData: any;
  @Input("transformMethods") transformMethods: any;
  @Input("activeActions") activeActions: string[];
  @Output() actionOutput: EventEmitter<any> = new EventEmitter();
  dataSourceTransformed: any[] = [];

  constructor(
    public datePipe: DatePipe,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    console.log("this.activeActions");
    console.log(this.activeActions);
    // console.log(this.datePipe.transform(new Date(), 'DD/MM/YYYY'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSource.length > 0) {
      let dsCopy = JSON.parse(JSON.stringify(this.dataSource));
      const keys = Object.keys(dsCopy[0]);
      let a: any[] = [];
      dsCopy.forEach((item: any) => {
        keys.forEach((k: string) => {
          if (Object.keys(this.transformMethods).includes(k)) {
            item[k] = (this.transformMethods[k] as Function)(
              item[k],
              this.transformData[k],
            );
          }
        });
        a.push(item);
        this.dataSourceTransformed = a;
      });
    }
  }

  action(type: string, element: any) {
    if (type === "delete") {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: "Confirmar Ação!",
          message: "Tem certeza que deseja apagar esse item?",
          btnConfirm: "Sim",
          btnCancel: "Não",
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.actionOutput.emit({ type: type, element: element });
        }
      });
    } else {
      this.actionOutput.emit({ type: type, element: element });
    }
  }
}
