import { Observable, Scheduler, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Schedule } from '../schedule';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  time_slots() {
    const url = `${environment.apiUrl}/schedules/time_intervals`

    return this.http.get(url).pipe(catchError(err => throwError(err || 'Server error')))
  }

  index(option: any): Observable<Schedule> {
    const params = new HttpParams({ fromObject: option })
    const url = `${environment.apiUrl}/schedules/list`

    return this.http.get<Schedule>(url, {params}).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  download(option: any) {
    const params = new HttpParams({ fromObject: option })

    const url = `${environment.apiUrl}/schedules/download`
    return this.http.get(url, { params }).pipe(catchError(err => throwError(err || 'Server error')))
  }

  createWithDock(data: any): Observable<Schedule> {

    const url = `${environment.apiUrl}/schedules`;

    return this.http.post<Document>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  update(data: any, id: string): Observable<Schedule> {
    const url = `${environment.apiUrl}/schedules/${id}`;

    return this.http.put<Schedule>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || 'Server error'))
    );
  }

  cancel(id: number) {
    const url = `${environment.apiUrl}/schedules/${id}/cancel`;
    return this.http.put(url, this.httpOptions).pipe(catchError(err => throwError(err || 'Server error')));
  }

  planedEvents(option: object) {
    const params = new HttpParams({ ...option })
    const url = `${environment.apiUrl}/schedules/planed_events`
    return this.http.get(url, { params }).pipe(catchError(err => throwError(err || 'Server error')))
  }
}
