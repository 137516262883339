<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title class="title" >{{ ("" + data.translation_title) | translate:{ Default: data.title } }}</mat-card-title>
    <button class="icon-scale" *ngIf="infoHtml" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Dash info">
      <mat-icon>info_outline</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="info-container" [innerHtml]="infoHtml"></div>
    </mat-menu>
  </mat-card-header>
  <mat-divider inset></mat-divider>
  <div class="scroll">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.name" *ngFor="let column of data.display_columns">
        <th mat-header-cell *matHeaderCellDef [style]="column.column_style" > {{ ("" + column.translation_name) | translate:{Default:column.name} }} </th>
        <td mat-cell *matCellDef="let element"> {{element[column.key]}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</mat-card>
