<mat-toolbar>
  <span style="margin-left: 20px;">
    <img
    src="assets/img/logo-trackage-green-black-new.png"
    width="145px"
    height="23px"
    class="espaco_logo"
    alt="Logotipo"
    />
  </span>
  <span class="spacer"></span>
  <button mat-dialog-close mat-icon-button>
    <mat-icon style="color: #256028;">arrow_forward</mat-icon>
  </button>
</mat-toolbar>

<div class="container" *ngFor="let item of data.weekIncidents">
  <button type="button" class="no_style" (click)="getIncidentById(item.id)">
  <div class="title">
    <h1>{{formatDate(item.created_at)}} - {{item.name}}</h1>
  </div>
  </button>
  <div class="content">
    <p>{{item.resource.finded}}</p>
    <span>Postado {{dateDifference(item.resource.finded_created_at)}}</span>
  </div>
  <mat-divider></mat-divider>
</div>
