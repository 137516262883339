import { forkJoin } from 'rxjs';
import * as moment from "moment";
import { SupportDataService } from './../support-data.service';
import { Component, ChangeDetectorRef, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PainelMotoristaService } from 'src/app/painel-motorista-loreal/shared/painelmotorista.service';
import { DatePipe } from '@angular/common';
import { TransformMethodUtil } from 'src/app/shared/util/transformMethod';
import {latLng, MapOptions, tileLayer, Map, Marker, icon} from 'leaflet';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-details-maps',
  templateUrl: './details-maps.component.html',
  styleUrls: ['./details-maps.component.scss']
})
export class DetailsMapsComponent implements OnInit, AfterViewInit {
  _data = (col: any) => {
    let val;
    const data = this.data.item;
    if(col.transform_method){
      col.transform_params && eval(`try {val = data.${col.transform_params}} catch(exception){val = ""}`);
      let text = "";
      if(this[col.transform_method as keyof this])
      {
        text = (this[col.transform_method as keyof this] as Function) (val);
      }
      else{
        if(this.transformMethodUtil[col.transform_method as keyof typeof this.transformMethodUtil]){
          text = this.transformMethodUtil[col.transform_method as keyof typeof this.transformMethodUtil](val);
        }
      }
      return text;
    }else if(col.data_key.indexOf(".") > 0){
      eval(`try {val = data.${col.data_key}} catch(exception){val = ""}`);
      return val;
    } else {
      return data[col.data_key] || "";
    }
  };

  account: any = JSON.parse(localStorage.getItem("account") || "{}");
  lastUpdate: any;
  lat: number | null;
  lng: number | null;
  zoom = 0;
  translateSource: any;
  listStatus: any;
  dockSpaces: any = [];
  showMap = true;
  drivers: any;
  isTripHitch = false;

  driverDetails = [];

  driverDetailsDefault = [
    [
      {
        "name": "Placa Cavalo",
        "data_key": "board_horse"
      },
      {
        "name": "CPF",
        "data_key": "cpf"
      }
    ],
    [
      {
        "name": "Placa Carreta",
        "data_key": "board_cart"
      },
      {
        "name": "Transportadora",
        "data_key": "carrier.name"
      }
    ]
  ];
  imageIcon: string|SafeStyle = "";
  statusColor = "";
  operationsAll: any = [];
  suboperationsAll: any = [];
  departmentsAll: any = [];
  destinyAll: any = [];
  statusName = "";
  map: Map;
  mapOptions: MapOptions;

  constructor(
    public datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public supportDataService: SupportDataService,
    private transformMethodUtil : TransformMethodUtil,
    public dialogRef: MatDialogRef<DetailsMapsComponent>,
    public painelMotoristaService: PainelMotoristaService
  ) {

  }

  ngOnInit() {
    const driver = this.data.driver || this.data.item?.driver || {};
    if(driver.resource?.photoPerfil){
      const imageIcon = (
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(driver.resource.photoPerfil)
        ? "data:image/png;base64,"
        : ""
      ).concat(driver.resource.photoPerfil);

      this.imageIcon = this.sanitizer.bypassSecurityTrustStyle(`url("${imageIcon}")`);
    }

    //Condicional para engate/desengate
    if (this.account.system_client.resource.environment.trip_hitch) {
      this.isTripHitch = this.account.system_client.resource.environment.trip_hitch;
    }

    this.getSuboperations();
    this.getDepartments();
    this.getOperations();
    this.getDestiny();


    forkJoin([
      this.supportDataService.generic({ name: 'status' })
    ]).subscribe(([status]) => {

      const statusColor = status.data.resource.find((o: any) => o.id == this.data.item.status);
      if (statusColor)
        this.statusColor = statusColor.color;

      this.listStatus = status.data.resource;

      this.modalConfig();
    });
  }


  //Detecta mudanças depois que a view for carregada
  //Implementação feita para corrigir erro Angular: Expression has changed after it was checked
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  //Função executada quando o mapa leaflet for inicializado
  onMapReady(map: Map) {
    this.map = map;
    this.getLastLocation();
    setTimeout(() => {
      this.getLastLocation();
    }, 30000);

  }

  //Seta a camada e a posição do mapa com as coordenadas do motorista
  setMapData() {
    this.map.addLayer(
      tileLayer(
        'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          attribution: 'Map data © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
        })
    );
    this.map.setView(latLng((this.lat ?? 0), (this.lng ?? 0)), this.zoom);

  }

  //Adiciona um marcador na posição do mapa com as coordenadas do motorista
  addMarker() {
    const marker = new Marker([(this.lat ?? 0), (this.lng ?? 0)])
      .setIcon(
        icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'assets/marker-icon.png'
        }));
    marker.addTo(this.map);
  }

  formatDate(date: string) {
    let dateFormat = "";
    try {
      dateFormat = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm') ?? ""
    }
    catch (error) {}
    return dateFormat;
  }

  getDepartments() {
    this.supportDataService.generic({ name: 'departments' }).subscribe((r: any) => {
      this.departmentsAll = r.data.resource;
    });
  }

  getOperations() {
    this.supportDataService.generic({ name: 'operation_type' }).subscribe((r: any) => {
      this.operationsAll = r.data.resource;
    });
  }

  getSuboperations() {
    this.supportDataService.generic({ name: 'suboperation_type' }).subscribe((r: any) => {
      this.suboperationsAll = r.data.resource;
    });
  }

  showSubOperationName(id: number) {
    let result;

    if (this.suboperationsAll.length > 0) {
      this.suboperationsAll.map((item: any) => {
        if (item.id == id) {
          result = item.name;
        }
      });
    }
    return result;
  }

  showDepartmentName(id: number) {
    let result;

    if (this.departmentsAll.length > 0) {
      this.departmentsAll.map((item: any) => {
        if (item.id == id) {
          result = item.name;
        }
      });
    }
    return result;

  }

  showOperationName(id: number) {
    let result;

    if (this.operationsAll.length > 0) {
      this.operationsAll.map((item: any) => {
        if (item.id == id) {
          result = item.name;
        }
      });
    }
    return result;

  }

  //Pega a ultima localização do motorista e chama as funções responsaveis por mostrar o mapa e marcador no modal
  getLastLocation() {
    if (this.isTripHitch) {
      this.painelMotoristaService.getTruckerLastLocation(this.data.item.trucker_checkin_id).subscribe((res: any) => {
        if (res.data.lat == 0.0) {
          this.lat = null;
          this.lng = null;
          this.zoom = 0;
          this.showMap = false;
        } else {
          this.lat = parseFloat(res.data.lat);
          this.lng = parseFloat(res.data.lng);
          this.lastUpdate = res.data.ts;
          this.zoom = 15;
          this.setMapData();
          this.addMarker();
        }
      })
    } else {
      this.painelMotoristaService.getLastLocation(this.data.item.id).subscribe((res: any) => {
        if (res.data.lat == 0.0) {
          this.lat = null;
          this.lng = null;
          this.zoom = 0;
          this.showMap = false;
        } else {
          this.lat = parseFloat(res.data.lat);
          this.lng = parseFloat(res.data.lng);
          this.lastUpdate = res.data.ts;
          this.zoom = 15;
          this.setMapData();
          this.addMarker();

        }
      })
    }
  }

  modalConfig() {
    const modalDetails = this.account.system_client.resource.environment.pages.painel_motoristas.modal_details || {};
    this.driverDetails = modalDetails.driver_details || this.driverDetailsDefault;
  }

  showOperation(event: any) {
    if (event.length > 0) {
      if (!event[0]) {
        return "";
      }
      const op = this.operationsAll.filter((op: any) => op.id === parseInt(event[0]));
      if (op.length > 0) {
        return op[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  showStatus() {

    const resource = this.data.item.resource || {};

    if (resource.status === "on_dock") {
      return "Na Doca"
    } else if (this.translateSource) {
      const translated = this.translateSource.status[resource.status];
      if (translated) {
        return translated;
      }
    } else if (this.listStatus instanceof Array) {

      const st: any = this.listStatus.filter(
        (item: any) => item.id === resource.status
      );

      if (resource.status === "") {
        return " - ";
      }
      if (st.length > 0) {
        return st[0].alternate_name;
      }
    }
    return "";
  }

  showTruckerStatus() {
    if (this.isTripHitch) {

      if (this.data.item.status == 'checkin') {
        this.statusColor = "#54D958";
        return "Check-in";
      } else if (this.data.item.status == 'checkout') {
        this.statusColor = "#3490FA";
        return "Checkout";
      }

    }
    return "";
  }

  timeOnStatus(resource: any) {
    if (resource || !this.isTripHitch) {
      const status = resource.status;
      const statusTs = status + "_created_at";
      const now = moment();
      const statusTime = moment(resource[statusTs]);

      const timeDifference = {
        days: moment.duration(Math.abs(now.diff(statusTime))).days(),
        hours: moment.duration(Math.abs(now.diff(statusTime))).hours(),
        minutes: moment.duration(Math.abs(now.diff(statusTime))).minutes(),
      };

      const formatedTime = `${timeDifference.days > 0 ? timeDifference.days + 'd ' : ''}${timeDifference.hours}h ${timeDifference.minutes}min`;
      return formatedTime;

    }
    return "";
  }

  timeOndayStatus(resource: any) {
    if (resource || !this.isTripHitch) {
      const status = resource.status;
      const statusTs = status + "_created_at";
      return moment.duration(Math.abs(moment().diff(moment(resource[statusTs]))))
      .format("DD[d] HH[h] mm[min]");
    }
    return "";
  }

  operationDuration(resource: any) {
    if (resource || !this.isTripHitch) {
      return moment.duration(
        Math.abs(
          moment().diff(moment(resource["waiting_created_at"])))
      ).format("DD[d] HH[h] mm[min]");
    }
    return "";
  }



  getDestiny() {
    this.supportDataService.generic({ name: "locations" }).subscribe((r: any) => {
      this.destinyAll = r.data.resource;
    });
  }

  showDestiny(id: number) {
    return [].concat
      (this.destinyAll)
      .reduce((o, item: any) => {
        if (item instanceof Object && item.id == id) {
          o.name = item.name + "; " + item["sub-area"];
        }
        return o;
      }, { name: 'N.A' }).name
  }

  cancel(): void {
    this.dialogRef.close();
  }

  showLocal(route: Array<any>){
    let result = "N.A";

    if (route?.length > 0) {
      result = route.map(
        ({name, subarea}:any)=>[name, subarea].filter(Boolean).join("; ")
      )?.join(" | ");
    }

    return result += ".";
  }
  //fazer funcao a para websocket
}
