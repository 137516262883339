import { UrlTree } from "@angular/router";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SupportDataService } from "../support-data.service";
import { BrowserModule } from "@angular/platform-browser";
import { MatIconModule } from "@angular/material/icon";
import { DocumentsService } from "src/app/documents/shared/documents.service";
import { catchError, map, of } from "rxjs";
import { HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { DialogAlertComponent } from "../dialog-alert/dialog-alert.component";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DocumentViewingComponent } from "../document-viewing/document-viewing.component";

@Component({
  selector: "app-upload-list",
  standalone: true,
  imports: [
    MatListModule,
    MatProgressBarModule,
    MatToolbarModule,
    BrowserModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: "./upload-list.component.html",
  styleUrls: ["./upload-list.component.scss"],
})
export class UploadListComponent implements OnInit {
  @Input("fileTypeList") fileTypeList: any;
  @Input("storedDocuments") documents: any;
  @Input("allowedExtensions") allowed_extensions: string =
    "image/jpg, image/jpeg, image/png, application/pdf";
  @Output() uploadedDocuments = new EventEmitter();
  documentList: any[] = [];
  documentListFT: any[] = [];
  files: {
    data: File;
    inProgress: boolean;
    progress: number;
    uploaded: boolean;
  }[] = [];
  showDocuments: any[] = [];
  doc_url: string;
  showList = false;
  constructor(
    private dialog: MatDialog,
    public documentsService: DocumentsService,
    public supportDataService: SupportDataService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.getDocumentList();
    const documentsString = this.data.schedule.documents;
    if (documentsString) {
      this.showDocuments = JSON.parse(documentsString);
    }
  }

  getDocumentList() {
    this.supportDataService
      .generic({ name: "pre_schedule_document_list" })
      .subscribe((r: any) => {
        this.documentList = r.data.resource;
        this.documentListFT = this.documentList.filter((item: any) =>
          this.fileTypeList.includes(item.id),
        );
        this.documentListFT.forEach((item: any, idx: number) => {
          this.filesToUpload.push(this.formBuilder.control("", []));
          this.percentUploaded[idx] = 0;
          this.showList = true;
        });
      });
  }

  showFileTypeName(idx: number) {
    if (typeof this.documentListFT[idx] !== "undefined") {
      return this.documentListFT[idx].name;
    }
  }

  percentCompleted: number = 0;
  isMultipleUploaded = false;
  isSingleUploaded = false;
  urlAfterUpload = "";
  percentUploaded: any = {};

  uploadFile($event: Event, i: number) {
    const target = $event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.processUpload(file, i);
  }

  processUpload(file: any, idx: number) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucket_name", "scheduling");

    this.documentsService
      .uploadBucket(formData)
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              // file.progress = Math.round((event.loaded * 100) / event.total);
              this.percentUploaded[idx] = Math.round(
                (event.loaded * 100) / event.total,
              );
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          this.files = this.files.filter((f) => f != file);

          if (error.error?.message) {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: "Error ao executar o upload.",
                dialogType: "ok_only",
                message: error.error?.message,
              },
            });
          }
          return of(`${file.name} upload failed.`);
        }),
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          file.uploaded = true;
          this.documents.push({ idx: idx, ...event.body });
          this.uploadedDocuments.emit(this.documents);
        }
      });
  }

  showUploadedFileName(idx: number) {
    const doc = this.documents.filter((item: any) => item.idx == idx);
    if (doc.length > 0) {
      return doc[0].fileName;
    } else {
      return "";
    }
  }

  visualizer(documentId: number) {
    const document: any = this.documents.filter(
      (ft: any) => ft.idx == documentId,
    );
    if (document.length > 0) {
      this.doc_url = document[0].url;

      this.dialog.open(DocumentViewingComponent, {
        width: "100%",
        height: "100%",
        data: {
          document_url: this.doc_url,
        },
      });
    } else {
      console.log("Documento não encontrado");
    }
  }

  delete(documentId: number) {
    const documentIndex = this.documents.findIndex((doc: { idx: number; }) => doc.idx === documentId);
  if (documentIndex !== -1) {
    this.documents.splice(documentIndex, 1);
    this.percentUploaded[documentId] = 0;
  } else {
    console.log('Documento não encontrado na variável local.');
  }
  }

  uploadForm = this.formBuilder.group({
    filesToUpload: this.formBuilder.array([]),
  });

  get filesToUpload(): FormArray {
    return this.uploadForm.get("filesToUpload") as FormArray;
  }
}
