import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ModalRankingPermanenciaComponent } from './modal-ranking-permanencia/modal-ranking-permanencia.component';
import { ModalLiberacaoEntradaComponent } from './modal-liberacao-entrada/modal-liberacao-entrada.component';
import { ModalDirecionamentoComponent } from './modal-direcionamento/modal-direcionamento.component';
import { ModalPanelStatusComponent } from './modal-panel-status/modal-panel-status.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        DashboardRoutingModule,
    ],
    declarations: [DashboardListComponent, ModalRankingPermanenciaComponent, ModalLiberacaoEntradaComponent, ModalDirecionamentoComponent, ModalPanelStatusComponent],
    exports: [
        DashboardListComponent
    ]
})
export class DashboardModule { }
