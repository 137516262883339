<form [formGroup]="scheduleForm" novalidate>
  <h2 mat-dialog-title>
    Pré-agendamento - {{data.schedule?.event_id}}
  </h2>
  <h4 *ngIf="data.schedule.status == 'denied'">
    Negado: {{data.schedule.motive}}
  </h4>
  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="item item-1">
        <div class="container">
          <mat-form-field>
            <mat-label>Doca</mat-label>
            <mat-select [attr.disabled]="data.schedule.status == 'confirmed' || data.schedule.status == 'denied'" formControlName="dock_id">
              <mat-option *ngFor="let item of docks" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Horário</mat-label>
            <mat-select [attr.disabled]="data.schedule.status == 'confirmed' || data.schedule.status == 'denied'" formControlName="start_at">
              <mat-option *ngFor="let date of start_dates" [value]="date?.value">
                {{ date?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field *ngIf="data.accessLevel != 'carrier'">
            <mat-label>Transportadoras</mat-label>
            <mat-select [attr.disabled]="data.schedule.status == 'confirmed' || data.schedule.status == 'denied'" formControlName="carrier_id">
              <mat-option *ngFor="let item of carriers" [value]="item.id">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>Nome do Motorista</mat-label>
            <input [readonly]="data.schedule.status == 'confirmed' || data.schedule.status == 'denied'" matInput formControlName="driver_name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>CPF do Motorista</mat-label>
            <input [readonly]="data.schedule.status == 'confirmed' || data.schedule.status == 'denied'" [textMask]="{mask: maskUtil.maskCPF()}" matInput formControlName="cpf">
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <mat-label> Operação</mat-label>
            <mat-select formControlName="operation_id">
              <mat-option *ngFor="let item of operations" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo do Veículo</mat-label>
            <mat-select formControlName="vehicle_type" required>
              <mat-option *ngFor="let item of vehicle_types" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container" *ngIf="([1,3,4,5,8,9,10].indexOf(scheduleForm.value.vehicle_type) === -1)">
          <mat-form-field>
            <mat-label>Placa de Cavalo</mat-label>
            <input minlength="7" maxlength="7" mask="AAAAAAA" onkeyup="this.value = this.value.toUpperCase();" matInput formControlName="board_horse" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Placa de Carreta</mat-label>
            <input minlength="7" maxlength="7" mask="AAAAAAA" onkeyup="this.value = this.value.toUpperCase();" matInput formControlName="board_cart" required>
          </mat-form-field>
        </div>
        <div class="container" *ngIf="([1,3,4,5,8,9,10].indexOf(scheduleForm.value.vehicle_type) !== -1)">
          <mat-form-field>
            <mat-label>Placa</mat-label>
            <input minlength="7" maxlength="7" mask="AAAAAAA" onkeyup="this.value = this.value.toUpperCase();" matInput formControlName="board_cart" required>
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <mat-label>Observação</mat-label>
            <input [readonly]="data.schedule.status == 'confirmed' || data.schedule.status == 'denied'" matInput formControlName="observation">
          </mat-form-field>
        </div>
      </div>
      <div class="item item-2">

        <div style="text-align:center;">
          <mat-card class="list-upload">
            <mat-card-actions>
              <button mat-button color="primary" style="text-align:right;" (click)="onClick()">
                Faça aqui o upload na documentação
                <mat-icon>backup</mat-icon>
              </button>
            </mat-card-actions>
            <mat-card-content class="list-content">
              <mat-list>
                <mat-list-item *ngFor="let item of documents">
                  <p matLine>
                    <span (click)="getFile(item)"> {{item.fileName}} </span>
                  </p>
                  <button mat-icon-button color="warn" aria-label="Apagar arquivo" (click)="deleteFile(item)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </mat-list-item>
              </mat-list>
            </mat-card-content>

          </mat-card><input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="*"
            style="display:none;" />
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="submit" mat-raised-button mat-dialog-close="true">Fechar</button>
    <button (click)="storeSchedule('')" mat-raised-button color="primary" *ngIf="data.action == 'create'">
      Salvar
    </button>
    <!-- <button (click)="denySchedule()" mat-raised-button color="warn" *ngIf="data.action == 'edit' && data.accessLevel != 'carrier'">
      Negar
    </button>
    <button (click)="storeSchedule('confirmed')" mat-raised-button color="primary" *ngIf="data.action == 'edit' && data.accessLevel != 'carrier' && data.schedule.status != 'confirmed'">
      Aprovar
    </button> -->
    <button (click)="storeSchedule('edit')" mat-raised-button color="primary" *ngIf="data.action == 'edit'">
      Salvar
    </button>
    <button (click)="cancelSchedule()" mat-raised-button color="warn" *ngIf="data.action == 'edit' && data.schedule.status != 'denied'">
      Excluir
    </button>
  </mat-dialog-actions>
</form>
