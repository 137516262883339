import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupportDataService } from 'src/app/shared/support-data.service';

@Component({
  selector: 'app-support-data-dialog',
  templateUrl: './support-data-dialog.component.html',
  styleUrls: ['./support-data-dialog.component.scss']
})
export class SupportDataDialogComponent implements OnInit {
  form = this.formBuilder.group({
    id: [this.data.item.id || null],
    name: [this.data.item.name, [Validators.required]],
  });
  constructor(
    public dialogRef: MatDialogRef<SupportDataDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportDataService: SupportDataService
  ) { }

  ngOnInit(): void {

  }

  submit() {
    console.log('submit');
    const item = {
      id: this.form.value.id,
      list_name: this.data.list_name,
      name: this.form.value.name
    }
    if(this.data.action === 'ADD') {
      this.supportDataService.addNewItem2List(item).subscribe((r: any) => {
        this.dialogRef.close(r.data);
      });
    } else {
      this.supportDataService.editItemOnList(item).subscribe((r: any) => {
        this.dialogRef.close(r.data);
      });
    }

  }
}
