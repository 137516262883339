import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { HealthCheckService } from '../shared/health-check.service';

@Component({
  selector: 'app-health-check-toastr',
  templateUrl: './health-check-toastr.component.html',
  styleUrls: ['./health-check-toastr.component.scss']
})
export class HealthCheckToastrComponent implements OnInit{
  notification: any;
  account: any;
  activeNotification: any;

  constructor(
    public sbRef: MatSnackBarRef<HealthCheckToastrComponent>,
    private snackBar: MatSnackBar,
    private HealthCheckService: HealthCheckService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void{
    this.account = JSON.parse(localStorage.getItem('account')!);
  }

  closeSnackbar() {
    this.HealthCheckService.disableUserToastrNotification(this.account.id).subscribe((r: any) => {
      this.activeNotification = r;
    });
    this.snackBar.dismiss();
  }
}
