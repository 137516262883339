<!--Card layout 10 - Criado em 01/01/2022
Layout padrão para clientes que não usam engate/desengate-->
<mat-card layout="10" *ngIf="layout == 10">
  <!--Header-->
  <mat-card-header [style.backgroundColor]="statusColor">
    <div  *ngIf="showStatus(); let status"
      class="mat-mdc-card-header status"
    >
      <span>
        {{status}}
      </span>
    </div>
  </mat-card-header>
  <mat-card-header>
    <div
      mat-card-avatar class="header-avatar"
      [style.backgroundImage]="imageIcon"
      matRipple
      [matRippleCentered]="true"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="true"
      [matRippleRadius]="24"
      (click)="checkPhoto($event,'PHOTO')"
    >
      <i _ngcontent-c17="" *ngIf="!imageIcon" class="material-icons icon-person">person</i>
    </div>
    <!--Title group-->
    <mat-card-title-group>
      <mat-card-title class="font-item">{{(data.driver?.name || "Sem Nome") | uppercase}}</mat-card-title>
      <mat-card-subtitle style="display: none;">Driver</mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>

  <!--Content/Body-->
  <mat-card-content>
    <div class="content-container">
      <div class="content-row content-container__odd">
          <table>
            <tbody class="cross-border">
              <tr class="tr" *ngFor="let row of driverDetails">
                <td class="td" *ngFor="let col of row">
                  <span>
                    {{ col.name }}
                  </span>
                  <span class="subtitle" [ngStyle]="_data(col)?.length > 22 ? {'font-size':'.85em'} : null">
                    {{ _data(col) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
      </div>
  </mat-card-content>

  <!--Footer-->
  <mat-card-footer>
    <!--Actions-->
    <mat-card-actions style="display: flex; justify-content: space-around;">
      <button mat-button (click)="openAlertDialog($event)">VER DETALHES</button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>

<!--Card layout 11 - Criado em 20/09/2022 Jandira Engage/Desengate-->
<mat-card layout="11" *ngIf="layout == 11">
  <!--Header-->
  <mat-card-header [style.backgroundColor]="statusColor">
    <div *ngIf="showTruckerStatus(); let statusTrucker"
      class="mat-mdc-card-header status"
    >
      <span>
        {{statusTrucker}}
      </span>
    </div>
  </mat-card-header>
  <mat-card-header>
    <div
      mat-card-avatar class="header-avatar"
      [style.backgroundImage]="imageIcon"
      matRipple
      [matRippleCentered]="true"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="true"
      [matRippleRadius]="24"
      (click)="checkPhoto($event,'PHOTO')"
    >
      <i _ngcontent-c17="" *ngIf="imageIcon" class="material-icons icon-person">person</i>
    </div>
    <!--Title group-->
    <mat-card-title-group>
      <mat-card-title class="font-item">{{(data.driver?.name || "Sem Nome") | uppercase}}</mat-card-title>
      <mat-card-subtitle style="display: none;">Driver</mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>

  <!--Content/Body-->
  <mat-card-content>
    <div class="content-container">
      <div class="content-row content-container__odd">
          <table>
            <tbody class="cross-border">
              <tr class="tr" *ngFor="let row of driverDetails">
                <td class="td" *ngFor="let col of row">
                  <span>
                    {{ col.name }}
                  </span>
                  <span class="subtitle">
                    {{ _data(col) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
      </div>
  </mat-card-content>

  <!--Footer-->
  <mat-card-footer>
    <!--Actions-->
    <mat-card-actions style="display: flex; justify-content: space-around;">
      <button mat-button (click)="openAlertDialog($event)">VER DETALHES</button>
      <button  mat-button (click)="driverCheckout(data)" *ngIf="data.status == 'checkin' " ><i class="material-icons">logout</i></button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>

<!--Card, invalid layout number-->
<mat-card *ngIf="![10,11].includes(layout)">
  Invalid number to card layout
</mat-card>
