import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder } from "@angular/forms";
import { SupportDataService } from "../../shared/support-data.service";

@Component({
  selector: 'app-direct-cart-dialog',
  templateUrl: './direct-cart-dialog.component.html',
  styleUrls: ['./direct-cart-dialog.component.scss']
})
export class DirectCartDialogComponent implements OnInit {
  form = this.formBuilder.group({
    directed_to: ["park"]
  });
  form1 = this.formBuilder.group({
    operation: [this.data.item.resource.operation], //, [Validators.required]
    document_number: [this.data.item.resource.document_number]
  });

  form2 = this.formBuilder.group({
    dock_space_id: [this.data.item.resource.dock_space_id], //, [Validators.required]
  });


  parkingType: any;
  parkingSpaces: any;
  operationType: any;
  parkingSpacesFiltered = [];
  dockSpacesFiltered: any = [];
  dockSpaces: any = [];
  dialogResult: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public supportData: SupportDataService,
    public dialogRef: MatDialogRef<DirectCartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.item.resource.dock_space_id) {
      this.form.setValue({ directed_to: 'dock' });
    }
    this.loadDockSpaces();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  loadDockSpaces() {
    this.supportData.dockSpaces().subscribe({
      next: (r: any) => {
        this.dockSpaces = r.data.resource;
        this.filterDockSpace();
      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  getDockName(id: number) {
    if (id > 0) {
      const dn = this.dockSpaces.filter((item: any) => item.id === id);
      if (dn.length > 0) {
        return dn[0].name;
      }
    } else {
      'Na doca'
    }
  }

  resetForm(form: number) {
    console.log(this.form.value.directed_to);
    if (form === 1) {
      this.form1.reset();
    }
    if (form === 2) {
      this.form2.reset();
    }
  }

  filterDockSpace() {
    this.dockSpacesFiltered = this.dockSpaces.filter((item: any) => {
      if (item.busy === false && item.maintenance === false) {
        return true;
      } else {
        return false;
      }
    });
  }

  onSubmit() {
    if (this.form.value.directed_to === "park") {
      this.dialogResult = this.form1.value;
      this.dialogResult.status = "on_park";
      this.dialogResult.dock_space_id = 0;
    }
    if (this.form.value.directed_to == "dock") {
      this.dialogResult = this.form2.value;
      this.dialogResult.status = "on_dock";
    }
    this.dialogResult.id = this.data.item.id;
    this.dialogRef.close(this.dialogResult);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
