import { Component, OnInit } from '@angular/core';
import { NgxTimelineEvent } from '../timelines.interface';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxTimelineEventChangeSideInGroup } from '@frxjs/ngx-timeline';

@Component({
  selector: 'app-journey-status-timeline',
  templateUrl: './journey-status-timeline.component.html',
  styleUrls: ['./journey-status-timeline.component.scss']
})
export class JourneyStatusTimelineComponent implements OnInit {
  data:any;
  langCode = "pt";
  enableAnimation = true;
  events:NgxTimelineEvent[] = []
  changeSideInGroup = NgxTimelineEventChangeSideInGroup.ALL

  constructor(
    private route: ActivatedRoute,
    public location: Location
    ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const data = history.state.data;
      this.events = data.events as NgxTimelineEvent[];
      this.data = data;
    });
  }
}
