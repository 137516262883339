import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DashboardDynamicService } from './../../../../dashboard-dynamic/dashboard-dynamic.service';
import { SupportDataService } from './../../../support-data.service';
import { CardInterface } from './../../card.interface';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-1-info',
  templateUrl: './card-1-info.component.html',
  styleUrls: ['./card-1-info.component.scss']
})
export class Card1InfoComponent implements OnInit, CardInterface {
  value = 0;
  data: any;
  options: any;
  layout!: number;
  infoHtml: SafeHtml;

  constructor(private sanitizer:DomSanitizer, private SupportDataService: SupportDataService,
    private dashboardDynamic: DashboardDynamicService) { }

  ngOnInit() {
    if(typeof(this.data.info_html) == "string"){
      this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.info_html as string);
    }
    this.dashboardDynamic.getDynamicDashboardItem({ item: this.data.element_name}).subscribe((qtd: any) => {
      this.value = qtd.data;
    });
  }
}
