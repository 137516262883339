import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteGuard } from '../account/shared/route.guard';
import { ConfigurationsComponent } from './configurations.component'
import { LanguagesComponent } from './languages/languages.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { ShiftTabsComponent } from './shifts/shift-tabs/shift-tabs.component';

const ConfigurationsRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/configurations',
    component: ConfigurationsComponent,
    data: {
      title: 'Configurações',
      search: false,
      refresh: false
    }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/configurations/languages',
    component: LanguagesComponent,
    data: {
      title: 'Idiomas',
      search: false,
      refresh: false
    },
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/shifts',
    component: ShiftTabsComponent,
    data: {
      title: 'Turnos',
      search: false,
      refresh: false
    },
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ConfigurationsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ConfigurationsRoutingModule { }
