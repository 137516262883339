
<div class="grid grid-4-columns">
  <div>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Relatório de Check in</mat-card-title>
      </mat-card-header>
      <mat-card-actions>
        <button mat-button color="primary" routerLink="/admin/report">Exibir</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Relatório de Agendamentos</mat-card-title>
      </mat-card-header>
      <mat-card-actions>
        <button mat-button color="primary" routerLink="/admin/event-report">Exibir</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
