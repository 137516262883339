import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-call-to-action-confirm',
  templateUrl: './call-to-action-confirm.component.html',
  styleUrls: ['./call-to-action-confirm.component.scss']
})
export class CallToActionConfirmComponent implements OnInit {
  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<CallToActionConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
  }


  save() {


    const props = this.data.component_props;
    console.log(props);
    let data: any = {
      action: props.action,
      data: {}
    };

    if(props.hasOwnProperty('include_keys')) {
      const keys: any = props.include_keys;
      keys.map((i: string) => {
        data['data'][i] = this.data[i];
      });
    }

    if(props.hasOwnProperty('include_data')) {
      Object.assign(data['data'], props.include_data);
    }

    this.callAPI(data, props.api).subscribe((r:any) => {
      console.log(r);
      this.dialogRef.close(r);
    });
    console.log(data);
    console.log(props.api);


  }

  callAPI(data: any, api_url?: string): Observable<any> {
    const url = `${environment.apiUrl}${api_url}`;
    // const options = { params: params };

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error('Server error') ))
    );
  }
}
