import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { UserService } from "../shared/user.service";

@Component({
  selector: "app-groups-detail",
  templateUrl: "./groups-detail.component.html",
  styleUrls: ["./groups-detail.component.scss"],
})
export class GroupsDetailComponent implements OnInit {
  form = this.formBuilder.group({
    id: [this.data.item.id || null],
    name: [this.data.item.name, Validators.required],
    // bp_type_id: [this.data.item.bp_type_id],
    module: [""],
  });

  modules: any = [];
  modules_ids = [];
  pageModules: any = [];
  bpTypeList: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<GroupsDetailComponent>,
    private changeDetection: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
  ) {}

  ngOnInit() {
    this.getPageModules();
    this.getBPType();
  }

  getPageModules() {
    this.userService.pageModules().subscribe((r: any) => {
      // Caso edição, pega os ids dos módulos e filtra
      // pageModules em modules
      if (this.data.action === "EDIT") {
        r.data.forEach((item: any) => {
          const menu_pages_ids = JSON.parse(this.data.item.menu_pages_id);
          this.pageModules = r.data.filter(
            (item: any) => !menu_pages_ids.includes(item.id),
          );
          if (menu_pages_ids.includes(item.id)) {
            this.modules.push(item);
          }
        });
      } else {
        this.pageModules = r.data;
      }
    });
  }

  addPage() {
    this.form.value.module.forEach((item: any) => {
      this.modules.push(item);
    });
    this.pageModules = this.pageModules?.filter(
      (item: any) => !this.form.value.module.find((x: any) => x.id === item.id),
    );
    this.changeDetection.detectChanges();
  }

  removePage(idx: number) {
    this.pageModules.push(...this.modules.splice(idx, 1));
  }

  onSubmit() {
    let pages_ids: any[] = [];
    this.modules.forEach((item: any) => {
      pages_ids.push(item.id);
    });
    const data = {
      id: this.form.value.id,
      name: this.form.value.name,
      menu_pages_id: pages_ids,
      // bp_type_id: this.form.value.bp_type_id,
    };
    if (this.data.action == "ADD") {
      this.userService.createGroups(data).subscribe((r: any) => {
        this.dialogRef.close(r);
      });
    }
    if (this.data.action == "EDIT") {
      this.userService.updateGroups(data).subscribe((r: any) => {
        this.dialogRef.close(r);
      });
    }
  }

  getBPType() {
    this.userService.bpType().subscribe((r: any) => {
      this.bpTypeList = r.data;
    });
  }
}
