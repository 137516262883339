import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UserService } from "../shared/user.service";

import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";

import { User } from "../user";
import { merge, of as observableOf, Subscription } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
} from "rxjs/operators";
import { UserDetailComponent } from "../user-detail/user-detail.component";
import { DialogConfirmComponent } from "../../shared/dialog-confirm/dialog-confirm.component";
import { SearchService } from "../../shared/search.service";
import { Search } from "../../shared/search";
import { DialogAlertComponent } from "../../shared/dialog-alert/dialog-alert.component";
import { AccountService } from "../../account/shared/account.service";
import { RefreshService } from "../../shared/refresh.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit, OnDestroy {
  searchWatcher!: Subscription;
  refreshWatcher!: Subscription;
  accountWatcher!: Subscription;
  search!: string;
  pageSize!: number;
  length!: number;
  displayedColumns!: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<User>();
  dataSourceAll = new MatTableDataSource<User>();
  data: User[] = [];
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  account: any;
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  pageEvent!: PageEvent;

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private refreshService: RefreshService,
    private searchService: SearchService,
    public breakpointObserver: BreakpointObserver, // private permissionService: PermissionService,
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  getSearch(event: any) {
    let val = event.target.value;
    // this.dataSource.data = 'requisicao api'
    this.dataSource.data = this.dataSourceAll.data.filter((item: any) => {
      return item.first_name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    });
  }

  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.displayedColumns = ["actions", "first_name"];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.displayedColumns = ["actions", "first_name", "email"];
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      // this.displayedColumns = ['first_name', 'email', 'is_staff', 'is_superuser', 'is_carrier', 'is_active', 'alerts', 'actions'];
      this.displayedColumns = [
        "actions",
        "first_name",
        "email",
        "group",
        "is_carrier",
        "is_active",
        "alerts",
      ];
    }

    // const permissions = ['superuser', 'users.show', 'users.update', 'users.destroy'];
    // this.permissionService.toggleSomePermission(permissions, this.displayedColumns, 'actions');
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  toggleAlert(mode: string, item: User) {
    let communication: string;
    let action: string;
    const row = Object.assign({}, item);

    if (mode === "phone") {
      communication = "telefone";
      action = item.is_alert_phone ? "desativado" : "ativado";
      row.is_alert_phone = !item.is_alert_phone;
    } else if (mode === "mail") {
      communication = "e-mail";
      action = item.is_alert_mail ? "desativado" : "ativado";
      row.is_alert_mail = !item.is_alert_mail;
    }

    this.userService.update(row, row.id.toString()).subscribe({
      next: (data) => {
        Object.assign(item, data);

        this.snackBar.open(
          `Alertas por ${communication} ${action} com sucesso!`,
          "OK",
          {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          },
        );
      },
      error: (err) => {
        this.dialog.open(DialogAlertComponent, {
          data: { title: err.statusText, message: err.error.message },
        });
      },
    });
  }

  open(action: string, item: any = { resource: {} }) {
    const dialogRef = this.dialog.open(UserDetailComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (action === "EDIT") {
          console.log("editando");
          this.snackBar.open("Usuário atualizado com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        } else if (action === "ADD") {
          console.log("criando");
          // If has previous page navigate to first page or reload in self page.
          this.paginator.hasPreviousPage()
            ? this.paginator.firstPage()
            : this.reload();
          //this.reload();
          this.snackBar.open("Usuário criado com sucesso!", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      }
      this.ngOnInit();
    });
  }

  delete(data: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Deletar o usuário.",
        message: `Tem certeza que deseja deletar o usuário ${data.full_name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        data.is_active = false;
        this.userService
          .update(data, data.id, data.is_active)
          .subscribe((response) => {
            this.reload();
            this.snackBar.open("Usuário deletado com sucesso!", "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          });   
      }
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.sort.sort(<MatSortable>{
      id: "user_users.created_at",
      start: "desc",
    });

    this.accountService.identity().then((identity) => {
      if (identity) {
        this.account = identity;
      }
    });

    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: this.sort.active ? this.sort.active : "first_name",
            sortedBy: this.sort.direction ? this.sort.direction : "DESC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            search: `${this.searchFilter.value}` || "",
          };
          return this.userService.index(options);
        }),
        map((response: Response | any) => {
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        console.log("atualiza no subcribe");
        this.dataSource.data = data;
        // this.dataSourceAll.data = data;
      });

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.searchWatcher = this.searchService.value.subscribe(
      (search: Search) => {
        this.search = search.value;
        this.reload({ search: search.value });
      },
    );

    this.refreshWatcher = this.refreshService.refresh.subscribe(
      (refresh: Event) => {
        this.reload({ refresh: refresh });
      },
    );

    this.accountWatcher = this.accountService.event.subscribe((event) => {
      if (event?.data instanceof Object) {
        const index = this.dataSource.data.findIndex(
          (item) => item.id === event.data.id,
        );
        if (index !== -1) {
          Object.assign(this.dataSource.data[index], event.data);
        }
      }
    });

    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            orderBy: this.sort.active ? this.sort.active : "first_name",
            sortedBy: this.sort.direction ? this.sort.direction : "ASC",
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            page: this.paginator.pageIndex + 1,
          };
          return this.userService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.showListEmpty = response.data.length === 0;
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => response.data),
      )
      .pipe(
        catchError(() => {
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
      });
    // this.getUsers();
    if(this.searchFilter.value){
      this.searchFilter.setValue(this.searchFilter.value);
    }
    
  }

  ngOnDestroy() {
    this.searchWatcher.unsubscribe();
    this.accountWatcher.unsubscribe();
    this.refreshWatcher.unsubscribe();
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }

  getUsers() {
    const options = {
      page: this.paginator.pageIndex + 1,
      orderBy: "first_name",
      sortedBy: "ASC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
    };
    this.userService
      .index(options)
      .pipe(
        map((response: Response | any) => {
          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any) => {
        this.dataSource.data = data;
        /* this.dataSourceAll = r.data */
      });
  }

  disconnect(action: "all" | "user", user: any) {
    const message =
      action == "all"
        ? "Todos os usuários serão desconectados, esta ação poderá levar alguns minutos."
        : "O usuário será desconectado, esta ação poderá levar alguns minutos.";

    this.dialog
      .open(DialogConfirmComponent, {
        data: {
          title: "Confirma esta ação?",
          message,
        },
      })
      .afterClosed()
      .subscribe((bool) => {
        if (bool) {
          (action == "all"
            ? this.userService.userLogoutAll()
            : this.userService.userLogout(user.id)
          ).subscribe((data) => {
            if (data?.success) {
              this.dialog.open(DialogAlertComponent, {
                data: {
                  dialog_type: "ok_only",
                  title: "Solicitação de desconexão de usuário",
                  message: data.message,
                },
              });
            }
          });
        }
      });
  }
}
