<mat-form-field appearance="outline">
  <mat-label>{{ field.props.label || "Tags" }}</mat-label>
  <input matInput placeholder="{{ field.props?.placeholder || ''}}" [formControl]="tagInput">
  <mat-hint *ngIf="field.props.required && !tagArray" style="color:red; margin: 2px;">
    *Campo obrigatório
  </mat-hint>
  <mat-hint *ngIf="showMsgError == true">*Caracteres especiais não permitidos para esse campo</mat-hint>
  <button *ngIf="(tagInput?.value && tagInput!.value!.length > 0) && field.props?.isEditingAllowed" matSuffix mat-icon-button color="primary" (click)="pushToArray()">
    <span class="material-icons">add</span>
  </button>
    <span
      class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
      *ngFor="let item of tagArray;"
    >
      <span class="tkg-chips-ellipsis" ng-submit="activeFilters">{{item}}</span>
      <span
        *ngIf="field.props?.isEditingAllowed"
        class="material-icons tkg-chips-icon"
        (click)="deleteTag(item)"
      >
        cancel
      </span>
    </span>
</mat-form-field>
<!--Input que tem seu valor passado para ser salvo no agendamento, permanece escondido e recebe o valor de tagInput-->
<input [formControl]="formControl" matInput hidden >
