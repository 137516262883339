<app-loading-table *ngIf="showLoadingData"></app-loading-table>
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z8">
  <app-paginate-sort-table
    *ngIf="!showListEmpty"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [totalItems]="totalItems"
    [columnsSchema]="columnsSchema"
    tableId="report_docks"
    [paginate]="paginate"
    (performOnChangePageOnParent)="performOnChangePage($event)"
    (performSortChangeOnParent)="performSortChange($event)"
    >
  </app-paginate-sort-table>
</div>
