<mat-card class="tkg-card">
  <div class="tkg-row tkg-top-16">
    <div class="tkg-column-100 tkg-header tkg-align-left">{{ main_fields_labels[main_slots_fields['slot_1']]}}</div>
  </div>
  <div class="tkg-row">
    <div class="tkg-column-100 tkg-align-left" *ngIf="datasource[main_slots_fields['slot_1']]; else slot100">{{ datasource[main_slots_fields['slot_1']] }}</div>
  </div>
  <div class="tkg-row tkg-top-4">
    <div class="tkg-column-50 tkg-header tkg-align-left">{{ main_fields_labels[main_slots_fields['slot_2']] }}</div>
    <div class="tkg-column-50 tkg-header tkg-align-left">{{ main_fields_labels[main_slots_fields['slot_3']] }}</div>
  </div>
  <div class="tkg-row">
    <div class="tkg-column-50 tkg-align-left" *ngIf="datasource[main_slots_fields['slot_2']]; else slot50">{{ datasource[main_slots_fields['slot_2']].length > 12 ? datasource[main_slots_fields['slot_2']].substring(0, 13) + '...' : datasource[main_slots_fields['slot_2']] }}</div>
    <div class="tkg-column-50 tkg-align-left" *ngIf="datasource[main_slots_fields['slot_3']]; else slot50">{{ datasource[main_slots_fields['slot_3']].length > 12 ? datasource[main_slots_fields['slot_3']].substring(0, 13) + '...' : datasource[main_slots_fields['slot_3']] }}</div>
  </div>
  <div class="tkg-row tkg-top-4">
    <div class="tkg-column-50 tkg-header tkg-align-left">{{ main_fields_labels[main_slots_fields['slot_4']] }}</div>
    <div class="tkg-column-50 tkg-header tkg-align-left">{{ main_fields_labels[main_slots_fields['slot_5']] }}</div>
  </div>
  <div class="tkg-row">
    <div class="tkg-column-50 tkg-align-left" *ngIf="datasource[main_slots_fields['slot_4']]; else slot50">{{ datasource[main_slots_fields['slot_4']]}}</div>
    <div class="tkg-column-50 tkg-align-left" *ngIf="datasource[main_slots_fields['slot_5']]; else slot50">{{ datasource[main_slots_fields['slot_5']]}}</div>
  </div>

  <mat-card-actions>
      <button mat-flat-button class="tkg-action-status" (click)="showBSActions(datasource)" [ngStyle]="{'background-color': datasource[main_slots_fields['slot_7']]}" *ngIf="datasource[main_slots_fields['slot_6']]; else slot100">
        {{ datasource[main_slots_fields['slot_6']] }}
      </button>

  </mat-card-actions>
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>

<ng-template #slot100>
  <div class="animated-background">
    <div class="background-masker"></div>
  </div>
</ng-template>
<ng-template #slot50>
  <div class="tkg-column-50 tkg-align-left">
    <div class="animated-background">
      <div class="background-masker"></div>
    </div>
  </div>

</ng-template>
