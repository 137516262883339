<h2 mat-dialog-title>{{ data.title }}</h2>
<p>Selecione abaixo o nome do motorista que deseja vincular a essa jornada.</p>
<form [formGroup]="form" ngDefaultControl>
  <mat-form-field>
    <mat-label>Motorista</mat-label>
    <mat-select formControlName="driver_id" placeholder="Digite ou escolha um motorista para vínculo.">
      <ngx-mat-select-search
        [formControl]="truckerFilter"
        placeholderLabel="Buscar motoristas"
        noEntriesFoundLabel="Nenhum resultado encontrado">
      </ngx-mat-select-search>
      <mat-option></mat-option>
      <mat-option
        *ngFor="let truckerDriver of truckerDrivers"
        [value]="truckerDriver"> 
        {{ truckerDriver.driver_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>Fechar</button>
  <button mat-raised-button color="primary" style="color: white;" (click)="onSubmit()" [mat-dialog-close]="true">Salvar</button>
</mat-dialog-actions>
