import { Component, Inject, OnInit } from "@angular/core";
import { UserService } from "../shared/user.service";
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { DialogAlertComponent } from "../../shared/dialog-alert/dialog-alert.component";
import { CarrierService } from "../../carrier/shared/carrier.service";
import { Carrier } from "../../carrier/carrier";
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  filter,
  tap,
  takeUntil,
} from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { DepartmentService } from "./../../shared/department.service";
import { TranslateService } from "@ngx-translate/core";
import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";
import { BusinessPartnerTypeService } from "src/app/account/shared/business-partner-type.service";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit {
  form = this.formBuilder.group({
    first_name: ["", [Validators.required]],
    last_name: ["", [Validators.required]],
    email: ["", [Validators.required, Validators.email]],
    is_superuser: [false, [Validators.required]],
    is_carrier: [false],
    is_partner: [false],
    bp_type_id: [null],
    bp_id: [null],
    carriers: [[]],
    group_id: [[Validators.required]],
    is_active: [true, [Validators.required]],
    is_alert_mail: [false, [Validators.required]],
    is_alert_phone: [false, [Validators.required]],
    department_id: [[1], [Validators.required]],
  });

  errors: any = {};
  hidePassword = true;

  account: any;
  carriersList: Carrier[] = [];
  departments: any = [];
  carrierFilter: UntypedFormControl = new UntypedFormControl();
  searching: boolean = false;
  protected _onDestroy = new Subject<void>();
  groups: any;
  use_departments = false;
  bpTypeList: any[] = [];
  bpList: any;
  bpListDisabled = true;
  is_business_partner: any;

  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private carrierService: CarrierService,
    private departmentService: DepartmentService,
    private translate: TranslateService,
    private businessPartnerService: BusinessPartnerService,
    private businessPartnerTypeService: BusinessPartnerTypeService,
  ) {
    console.log("+++++");
    console.log(this.data);
  }

  onSubmit() {
    if (this.data.action === "EDIT") {
      console.log(this.form.value);
      this.userService.update(this.form.value, this.data.item.id).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (err) => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: { title: err.statusText, message: err.error.message },
            });
          }
        },
      });
    } else if (this.data.action === "ADD") {
      this.userService.create(this.form.value).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (err) => {
          if (err.status === 422 && err?.error?.errors) {
            this.errors = err.error.errors;
            this.errors.first_name = [
              this.translate.instant("Messages.first_name_error", {
                Default: "Nome não pode ter mais que 30 letras.",
              }),
            ];
            this.errors.last_name = [
              this.translate.instant("Messages.last_name_error", {
                Default: "Sobrenome deve ter menos que 150 caracteres.",
              }),
            ];
            // this.errors.password = [
            //   this.translate.instant("Messages.password_error", {
            //     Default: "Senha deve ter no mínimo 8 caracteres.",
            //   }),
            // ];
            this.errors.email = [
              this.translate.instant("Messages.email_error", {
                Default: "Email já cadastrado.",
              }),
            ];
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: err.statusText ?? "Erro",
                message: err.error.message ?? "Ocorreu um erro inesperado",
              },
            });
          }
        },
      });
    }
  }

  ngOnInit() {
    // const subscription = this.form.get("is_carrier")!
    //   .valueChanges as Subject<Subscription>;
    // subscription.subscribe((value) => {
    //   if (value) {
    //     this.form.get("carriers")!.setValidators(Validators.required);
    //   } else {
    //     this.form.get("carriers")!.clearValidators();
    //   }
    //   this.form.patchValue({
    //     carriers: this.form.get("carriers")!.value || "",
    //   });
    // });
    // subscription.next(this.data.item.is_carrier);

    // Adiciona ao formulário a compo senha ao clicar em adicionar
    if (this.data.action === "ADD") {
      this.form.addControl(
        "password",
        new FormControl("", [Validators.required]),
      );
    }

    // this.loadCarrier();
    this.getBPTypeList();
    this.loadUserGroups();

    this.account = JSON.parse(localStorage.getItem("account")!);
    this.is_business_partner = this.account.system_client.resource.is_business_partner || false;
    
    this.getDepartments();
    if (this.account.system_client.resource.environment) {
      if (
        this.account.system_client.resource.environment.use_department === true
      ) {
        this.use_departments = true;
        this.getDepartments();
      }
    }

    this.carrierFilter.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        startWith({}),
        debounceTime(500),
        switchMap(() => {
          const options = { pageSize: 1000 };

          if (this.carrierFilter.value) {
            Object.assign(options, {
              search: `${this.carrierFilter.value}`,
            });
          }

          return this.carrierService.index(options);
        }),
        map((response: any) => response.data),
      )
      .subscribe((data) => (this.carriersList = data));

    if (this.data.action === "EDIT") {
      this.form.patchValue({
        ...this.data.item,
        is_partner: this.data.item.resource.is_partner || false,
        bp_type_id: this.data.item.resource.bp_type_id,
        bp_id: this.data.item.resource.bp_id,
      });
      if (this.data.item.resource.is_partner) {
        this.getBPList();
        console.log(this.form.value);
        //this.form.get("bp_id")!.updateValueAndValidity();
      } else {
        this.form.patchValue({
          ...this.data.item,
          is_partner: false,
          bp_type_id: null,
          bp_id: null,
        });
      }
    }
  }

  loadCarrier() {
    const options = { pageSize: 1000 };
    return this.carrierService.index(options).subscribe({
      next: (r: any) => {
        this.carriersList = r.data;
      },
      error: (e: any) => {
        console.log(e);
      },
    });
  }

  loadUserGroups() {
    const options = { pageSize: 200 };
    return this.userService.userGroups(options).subscribe({
      next: (r: any) => {
        this.groups = r.data;
      },
      error: (e: any) => {
        console.log(e);
      },
    });
  }

  getDepartments() {
    this.departmentService.index().subscribe((r: any) => {
      this.departments = r.data.resource;
      if (r.length == 1) this.form.value.department_id = r.data.resource[0].id;
    });
  }

  getBPTypeList() {
    this.businessPartnerTypeService.all().subscribe((r: any) => {
      this.bpTypeList = r.data;
    });
  }

  getBPList() {
    this.businessPartnerService
      .all({ bp_type_id: this.form.get("bp_type_id")?.value })
      .subscribe((r: any) => {
        this.bpList = r.data;
        this.bpListDisabled = !this.bpListDisabled;
      });
  }
}
