<div class="top-bar-filters" *ngIf="showFilter">
  <form [formGroup]="form" class="form-container">
    <formly-form
      class="grid grid-filter"
      [model]="model"
      [fields]="fields"
      [options]="options"
      [form]="form"
    ></formly-form>
  </form>
</div>

<div class="card-summary">
  <!-- card Qtd. Veículos em Operação -->
  <mat-card class="card-hub">
    <mat-card-header>
      <div class="card-hub-title">Veículos em Operação</div>
      <div class="circulo c-green">
        <i class="material-icons icon_cor"> directions_car </i>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="mat-display-4-2">
        {{ total_veiculos_operacao ? total_veiculos_operacao : "0" }}
      </div>
    </mat-card-content>
  </mat-card>

  <!-- card Qtd. Veículos Finalizado -->
  <mat-card class="card-hub">
    <mat-card-header>
      <div class="card-hub-title">Veículos Finalizados</div>
      <div class="circulo c-blue">
        <i class="material-icons icon_cor45"> directions_car </i>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="mat-display-4-2">
        {{ total_veiculos_finalizados ? total_veiculos_finalizados : "0" }}
      </div>
    </mat-card-content>
  </mat-card>

  <!-- card Qtd. Veículos em Patio -->
  <mat-card class="card-hub">
    <mat-card-header>
      <div class="card-hub-title">Veículos Aguardando no Pátio</div>
      <div class="circulo c-orange">
        <i class="material-icons icon_cor36"> directions_car </i>
      </div>
    </mat-card-header>

    <mat-card-content>
      <div class="mat-display-4-2">
        {{ waiting_on_park ? waiting_on_park : "0" }}
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngFor="let group of stageGroups" class="stage-groups">
  <span class="stage-groups-title">{{group.title}}</span>
  <hr>
  <div class="stages">
    <mat-card *ngFor="let stage of group.stages" class="card-stage">
      <mat-card-header>
        <div class="card-stage-title">{{ stage.name }}</div>
        <div class="circulo c-green">
          <i class="material-icons icon_cor"> directions </i>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="mat-display-4-2 text-center">
          <div class="divNeutra" style="font-size: 18px;">Total {{ stage.total || 0 }} Veículos</div>
          <div style="margin-top: 5px;" class="divVerde" (click)="stageInfo(group, stage, 'on_time')">
            {{ stage?.green || "0" }}
          </div>
          <div style="margin-top: 5px;" class="divAmarela" (click)="stageInfo(group, stage, 'halfway_time')">
            {{ stage?.yellow || "0" }}
          </div>
          <div style="margin-top: 5px;" class="divVermelha" (click)="stageInfo(group, stage, 'over_time')">
            {{ stage?.red || "0" }}
          </div>

          <span class="text-small link" (click)="stageAlerts(group, stage)">
            <i class="material-icons icon_cor36"> info </i>Alertas
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
