<h1 class="mat-align-left tkg-title" mat-dialog-title>Controle de Fluxo</h1>
<div class="tkg-top-bar" *ngIf="display_message">
  <p class="tkg-message">{{message}}</p>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" class="tkg-form-select">
    <mat-form-field appearance="outline" class="tkg-select-100" *ngIf="manage">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name" appearance="outline" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="tkg-select-100">
      <mat-label>Modelo</mat-label>
      <mat-select formControlName="flow_id" (selectionChange)="flowSelected()">
        <mat-option *ngFor="let item of flows" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div cdkDropListGroup class="dd-group">
    <div class="dd-container">
      <h2>Fluxo a ser executado</h2>
      <div
        id="tkg-flow"
        cdkDropList
        [cdkDropListData]="flow"
        class="tkg-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div class="tkg-box" *ngFor="let item of flow" cdkDrag>
          <div class="tkg-custom-placeholder" *cdkDragPlaceholder></div>
          {{item.name}}
        </div>
      </div>
      <div class="tkg-empty-list-text" *ngIf="flow.length === 0">
        Clique e arraste para área acima os itens ao lado, na sequência
        desejada!
      </div>
      <div class="tkg-flow-btn" *ngIf="flow.length > 0">
        <button type="button" mat-button color="primary" (click)="clean()">
          Limpar Fluxo
        </button>
        <button
          type="button"
          mat-button
          color="primary"
          *ngIf="!manage"
          (click)="saveTemplate()"
        >
          Salvar como modelo
        </button>
      </div>
    </div>

    <div class="dd-container">
      <h2>Lista de locais</h2>
      <div
        id="tkg-flow-list"
        cdkDropList
        [cdkDropListData]="data_list"
        class="tkg-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div class="tkg-box" *ngFor="let item of data_list" cdkDrag>
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" class="tkg-dialog-btns">
  <button
    type="button"
    mat-button
    color="warn"
    (click)="close()"
    *ngIf="!saving"
  >
    Fechar
  </button>
  <button
    type="submit"
    cdkFocusInitial
    mat-raised-button
    style="color: white"
    color="primary"
    (click)="save()"
    [disabled]="flow.length === 0"
    *ngIf="!saving && !manage"
  >
    Salvar
  </button>
  <button
    type="submit"
    cdkFocusInitial
    mat-raised-button
    style="color: white"
    color="primary"
    (click)="saveTemplate()"
    [disabled]="!form.valid"
    *ngIf="!saving && manage"
  >
    Salvar como Modelo
  </button>
  <button
    type="submit"
    cdkFocusInitial
    mat-raised-button
    color="primary"
    disabled
    *ngIf="saving"
  >
    Salvando...
  </button>
</div>
