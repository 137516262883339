
    <button mat-mini-fab (click)="cancel()" id="x">X</button>
    <h1 mat-dialog-title>Localização </h1>

      <!--To-do-fix-->
      <!-- <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng" mapTypeId="satellite" *ngIf="showMap">
        <agm-marker  [latitude]="lat" [longitude]="lng" >

        </agm-marker>
      </agm-map> -->
      <!--To-do-fix-->

    <!-- <div mat-dialog-actions>
      <button mat-button (click)="cancel()">Cancelar</button>
      <button mat-button (click)="cancel()" color="primary">Ok</button>
    </div> -->
