<button mat-mini-fab (click)="cancel()" id="x">X</button>
<div class="div-title">
  <img [src]="logo" class="espaco_logo" alt="Logotipo">
  <span class="span-title">PAINEL DE STATUS</span>
  <span class="span-date">Última atualização {{ lastUpdate | date: 'short' }}</span>
</div>

<table id="customers" class="font-large">
  <thead>
    <tr>
      <th>Motorista</th>
      <th>Transportadora</th>
      <th>Operação</th>
      <th>Status</th>
      <th>Farol</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of drivers">
      <td>{{item.driver.name}}</td>
      <td>{{item.carrier.name}}</td>
      <td>{{nameOfOperation(item.resource.operation) || nameOfOperation(item.resource.operation_id)}}</td>
      <td>

        <div class="driver-status" [ngClass]="{
          'app-chip-waiting-panel': item.resource.status == 'waiting',
          'app-chip-directed_park-panel': item.resource.status == 'directed_park',
          'app-chip-directed_dock-panel': item.resource.status == 'directed_dock',
          'app-chip-on_park-panel': item.resource.status == 'on_park',
          'app-chip-on_dock-panel': item.resource.status == 'on_dock',
          'app-chip-finished_loading-panel': item.resource.status == 'finished_loading',
          'app-chip-shipping_finished-panel': item.resource.status == 'shipping_finished',
          'app-chip-fetch_invoice-panel': item.resource.status == 'fetch_invoice',
          'app-chip-fetch_invoice_dfd-panel': item.resource.status == 'fetch_invoice_dfd',
          'app-chip-fetch_invoice_delivered-panel': item.resource.status == 'fetch_invoice_delivered'
          }">
          {{showStatus(item.resource)}}
        </div>
      </td>
      <td style="text-align: center">
        <div class="farol" [ngClass]="getClassAlert(item.resource.alert)"></div>
      </td>
    </tr>
  </tbody>
</table>
<!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator> -->

<div style="text-align: center; margin-top: 16px;">
  <img style="width: 263px; height: 44px;" src="assets/img/logo-trackage-horizontal-white-new.png" alt="Logotipo">
</div>
