<mat-form-field  [appearance]="props.appearance">
  <mat-label>{{props.label}}</mat-label>
  <mat-select [disableRipple]="true" [formControl]="formControl" [placeholder]="props.placeholder || ''">
    <mat-option>
      <ngx-mat-select-search
        [searching]="searching"
        [clearSearchInput]="false"
        [placeholderLabel]="props.placeholder || ''"
        [formControl]="formControlFilter"
        [noEntriesFoundLabel]="''"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option>{{ "Business_model.clean" | translate:{Default:"Limpar"} }}</mat-option>
    <mat-option
      *ngFor="let item of listOptions"
      [value]="item"
    >
      {{ item.name || ""}} 
      <br> {{formatCpf(item.document || this.field?.defaultValue) || "Sem CPF cadastrado"}}
    </mat-option>
  </mat-select>
</mat-form-field>
