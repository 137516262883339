import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { ReportsDynamicPainelComponent } from './reports-dynamic-painel/reports-dynamic-painel.component';
import { ReportDynamicFilterComponent } from './report-dynamic-filter/report-dynamic-filter.component';
import { RouteGuard } from '../account/shared/route.guard';
import { ReportListComponent } from './report-list/report-list.component';

const reportRoutes: Routes = [
  {
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    path: 'admin/report-dynamic',
    component: ReportsDynamicPainelComponent,
    data: {
          title: 'Tipos de Relatórios',
          search: false,
          refresh: false,
          btnDownloadReport: false,
          btnStatusPanel: false
        }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/report-dynamic/:page',
    component: ReportDynamicFilterComponent,
    data: {
      title: 'Relatório',
      search: false,
      btnDownloadReport: true,
      refresh: false
    }
  },
  {
    canActivate: [AccountGuardService],
    runGuardsAndResolvers: 'always',
    path: 'admin/report-list/:page',
    component: ReportListComponent,
    data: {
      title: 'Relatório',
      search: false,
      btnDownloadReport: false,
      filter: true,
      refresh: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(reportRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class ReportDynamicRoutingModule {
}
