import { Component, OnInit } from '@angular/core';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../account/shared/account.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register-detail',
  templateUrl: './register-detail.component.html',
  styleUrls: ['./register-detail.component.scss']
})
export class RegisterDetailComponent implements OnInit {
  form!: UntypedFormGroup;
  errors: any;
  hidePassword = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  onSubmit() {
    this.accountService.create(this.form.value)
      .subscribe(data => {},
        err => {
          if (err.status === 422) {
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {title: err.statusText, message: err.error.message}
            });
          }
        }
      );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }
}
