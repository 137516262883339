import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportDataService } from '../../shared/support-data.service'

@Component({
  selector: 'app-release-dock-dialog',
  templateUrl: './release-dock-dialog.component.html',
  styleUrls: ['./release-dock-dialog.component.scss']
})
export class ReleaseDockDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReleaseDockDialogComponent>,
    private supportDataService: SupportDataService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    console.log('doca', this.data.item.resource);

  }

  onSubmit() {
    const dockObj = {
      busy: false,
      id: this.data.item.resource.dock_space_id,
      department_id: this.data.item.resource.department_id,
      driver_checkin_id: this.data.item.resource.id
    };

    this.supportDataService.updateDockSpace(dockObj, dockObj.id).subscribe((r:any)=>{
      if(r.status == 200 || r.status == 'success') {
        this.dialogRef.close();
      }
    },(res: any)=> {
      this.dialogRef.close();
      if(res.error.message) {
        this.snackBar.open(res.error.message,"Fechar",{ duration: 3000 });
      }
    });
  }

}
