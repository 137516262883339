import { Subject, of as observableOf } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, catchError, startWith, debounceTime, switchMap } from 'rxjs/operators';
import { Component, OnInit, Inject, Optional, ViewChild, ElementRef } from '@angular/core';

import { Schedule } from '../schedule';
import { MaskUtil } from '../../shared/util/mask.util';
import { EventsComponent } from '../events/events.component';
// import { Driver } from 'src/app/drivers/drivers.interface';
import { AgendamentoService } from './../shared/agendamento.service';
import { CheckinService } from '../../checkin/shared/checkin.service';
import { CarrierService } from 'src/app/carrier/shared/carrier.service';
import { DriversService } from './../../drivers/shared/drivers.service';
import { SupportDataService } from './../../shared/support-data.service';
import { DocumentsService } from '../../documents/shared/documents.service';
import { DialogConfirmComponent } from '../../shared/dialog-confirm/dialog-confirm.component';
import { DialogActionsComponent } from '../../checkin/dialog-actions/dialog-actions.component';
import { InvalidateVehicleDialogComponent } from 'src/app/checkin-loreal/invalidate-vehicle-dialog/invalidate-vehicle-dialog.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;
  protected _onDestroy = new Subject<void>();
  files:{ data: any; inProgress: boolean; progress: number; }[] = [];
  dialogTitle: string;
  loading = false;
  account:any = {};
  schedule = {} as Schedule;
  action: string;
  carriers:any[] = [];
  maskUtil = MaskUtil;
  documents:any[] = [];
  document: any;
  start_dates = [];
  end_dates = [];
  checklist_id = null;
  reasons:any[] = [];
  validates = [];
  suboperations: any = [];
  operations: any = [];
  vehicle_types: any = [];
  box_sizes: any = [];
  show:boolean = false;
  driver: any;
  disable_checkin_btn = false;
  plates_fields: any[] = [];
  block_carrier = false;
  private board_pattern:string = '^[a-zA-Z]{3}\\d\\w\\d\\d$';
  boardsFilter: UntypedFormControl = new UntypedFormControl();
  boardCarts: any[] = [];
  searching = false;

  form = this.formBuilder.group({
    id: [this.data.schedule.id || null],
    suboperation_id: [this.data.schedule.suboperation_id || null, Validators.required],
    sid: [this.data.schedule.sid || ''],
    external_event_id: [this.data.schedule.external_event_id || '', this.data.schedule.suboperation_id == 10 ? Validators.required : ''],
    vehicle_type: [this.data.schedule.vehicle_type || null, Validators.required],
    cpf: [this.data.schedule.cpf || '', this.data.schedule.operation_id == 2 ? Validators.required : ''],
    box_size: [this.data.schedule.box_size || null],
    operation_id: [this.data.schedule.operation_id || 1],
    loaded: [this.data.schedule.loaded || false],
    order_number: [this.data.schedule.order_number || ''],
    board_cart:  [this.data.schedule.board_cart || '', [this.data.schedule.vehicle_type == 2 ? Validators.required : Validators.pattern(this.board_pattern), Validators.pattern(this.board_pattern)]],
    board_horse: [this.data.schedule.board_horse || '', [Validators.required, Validators.pattern(this.board_pattern)]],
    origin: [this.data.schedule.origin || ''],
    nf: [this.data.schedule.nf || ''],
    observation: [this.data.schedule.observation || ''],
    email: [this.data.schedule.email || ''],
    event_id: [this.data.schedule.id || null],
    checklist_id: [this.data.schedule.checklist_id || null],
    start_at: [this.data.schedule.start_at],
    schedule_time: [this.data.schedule.schedule_time || ''],
    end_at: [this.data.schedule.end_at || ''],
    carrier_id: [this.data.schedule.carrier_id || null, Validators.required],
    driver_id: [this.data.schedule.driver_id],
    driver_phone: [this.data.schedule.driver_phone || '', Validators.required],
    driver_name: [this.data.schedule.driver_name || '', Validators.required],
    status:  [this.data.schedule.status || ''],
    documents: [this.data.schedule.documents || [] , this.data.schedule.suboperation_id != 7 && this.data.schedule.operation_id == 1 ? Validators.required : '']
  });
  timeslots: any;
  definedDay: string = this.data.definedDay;
  truckerLink: boolean = false;


  getErrorMessage() {
    if (this.form.value.board_cart.hasError('required')) {
      return 'Campo obrigatório';
    }
    return undefined;
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    public documentsService: DocumentsService,
    public transportadoraService: CarrierService,
    public supportDataService: SupportDataService,
    public checkinService: CheckinService,
    public agendamentoService: AgendamentoService,
    public driverService: DriversService,
    public dialogRef: MatDialogRef<EventsComponent>
  ) {
    try{
      this.account = JSON.parse(localStorage.getItem('account') || "{}");
    }catch(ex:any){
      this.account = {}
    }
  }


  ngOnInit(): void {
    this.getCarriers();
    this.getOperations();
    this.getSubOperations();
    this.getBoxSizes();
    this.getVehicleTypes();
    this.getTimeSlots();
    this.loadUnlinkedCarts();
    this.getInvalidationReasonsList();

    this.dialogTitle = ["Pré-agendamento", this.data.schedule?.external_event_id || this.data.schedule?.sid].filter(i=>i).join(" - ");

    //Verifica se é usuário de transportadora e se possui apenas uma transportadora cadastrada
    //Se sim passa o id de indice 0 do array carriers do usuário como valor padrão
    if(this.account.is_carrier && this.account.carriers.length === 1){
      this.form.patchValue({carrier_id: this.account.carriers[0]});
      this.block_carrier = true;
    }
    if (this.data.schedule.documents instanceof Array) {
      this.documents = this.data.schedule.documents;
    }
    // Search driver via cpf
    if(this.data.schedule.cpf){
      this.getDriverByCpf(this.data.schedule.cpf);
    }

    this.disable_checkin_btn = this.data.schedule.checkin_id ? true : false;
  }

  filterSuboperations(suboperations:any[]) {
    let subs:any[] = []

    suboperations.forEach((sub: any) => {
      if(this.form.value.operation_id === sub.operation_id) subs.push(sub);
    });

    return subs;
  }

  getDriverByCpf(cpf:number){
    const options = {
      cpf: cpf
    }
    this.driverService.showViaCpf(options).subscribe((r:any) => {
      this.driver = r;
    });
  }

  getOperations() {
    this.supportDataService.operation().subscribe((r: any) => {
      const op = r.data.resource;

      this.operations = op.filter((operation:any) => operation.use_in_scheduling);
    });
  }

  getSubOperations() {
    this.supportDataService.suboperation().subscribe((r: any) => {
      this.suboperations = r.data.resource;
    });
  }

  getVehicleTypes() {
    this.supportDataService.dinamicTable({ name: 'vehicle_type', visible: true }).subscribe((r: any) => {
      r.data.resource
        .push(r.data.resource.splice(r.data.resource.findIndex((item:any) => {return item.name == 'Outros'}), 1)[0]);
      const vtypes = r.data.resource;
      this.vehicle_types = vtypes.filter((item:any) => item.visible === true);
      if(this.data.action === 'edit') {
        this.setPlateAmount();
      }
    });
  }

  getBoxSizes() {
    this.supportDataService.dinamicTable({ name: 'box_sizes' }).subscribe((r: any) => {
      r.data.resource.sort((a:any, b:any) => a.size - b.size);
      this.box_sizes = r.data.resource;
    });
  }

  getInvalidationReasonsList() {
    if(this.data.checklist){
      this.reasons = this.data.checklist.resource.filter((item:any)=>item === false)
    }
  }

  // Open deny dialog
  denySchedule(action: any) {
    const dialogRef = this.dialog.open(InvalidateVehicleDialogComponent, {
      width: "600px",
      data: {
        item: {
          resource: this.data.schedule,
          checklist: this.data.checklist
        },
        action: 'denied'
      }
    });

    dialogRef.afterClosed().subscribe( (dData: any) => {
      if(dData){

        this.data.checklist = dData.checklist;

        if(this.hasPendenciesSolved() === false) {
          this.checklist_id = dData.checklist_id;
        }

        // Salva negando
        this.updateSchedule({...action, checklist_id: dData.checklist_id});

      }

    })
  }

  hasPendenciesSolved(){
    if(this.data.checklist){
      return !(
        this.data.checklist.resource.filter(
          (item:any) => item.solved === false
        ).length > 0
      );
    }
    return true;
  }

  getCarriers() {
    this.transportadoraService.index({pageSize: 1000}).subscribe((r: any) => {
      this.carriers = r.data;
    })
  }

  cancelSchedule(): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'ATENÇÃO!',
        message: `Você tem certeza que deseja excluir esse agendamento?`
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        let id = this.form.value.id;
        this.agendamentoService.cancel(id).subscribe({
          next: (r: any) => {
            this.snackBar.open("Agendamento Cancelado Com Sucesso", "OK", {
              duration: 5000,
            });
            this.dialogRef.close({ action: 'cancel' });
          }, error: (e: any) => {
            this.snackBar.open("Houve um erro ao cancelar agendamento", "Entendi", {
              duration: 5000,
            });
            console.log(e)
          }
        })
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  uploadFile(file:any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.documentsService.upload(formData).pipe(
      map((event:any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return observableOf(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.documents.push(event.body);
        }

      });
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        const fileData = { data: file, inProgress: false, progress: 0 };
        this.files.push(fileData);
        this.uploadFile(fileData);
      }
    };
    fileUpload.click();
  }


  submit(): void {
    let checkin = this.boardCarts.find(x => x.placa_carreta === this.form.value.board_cart);
    this.loading = true;
    const resource = this.form.value;
    resource.documents = this.documents;
    resource.schedule_link_checkinId = this.truckerLink && checkin ? checkin.id : "";
    resource.id = resource.id;
    resource.external_event_id = resource.external_event_id;
    resource.sid = resource.sid;
    resource.driver_phone = resource.driver_phone ? resource.driver_phone.replace(/\D+/g, '') : null;
    resource.cpf = resource.cpf ? resource.cpf.replace(/[.-]/g, '') : null;

    this.agendamentoService.dinamicScheduleCreate(resource).subscribe((r: any) => {
      this.loading = false;
      if(r.error) {
        if(r.error_type = 'fields_validation') {
          this.validates = r.msg;
        }
      } else {
        this.close();
      }
    });
  }

  updateSchedule(actions: any): void {
    //TODO: PAREI AQUI, mandar no paylod/submit resource.schedule_link_checkinId = this.truckerLink && checkin ? checkin.id : "";
     let checkin = this.boardCarts.find(x => x.placa_carreta === this.form.value.board_cart);
    this.loading = true;
    const options = {
      ...this.form.getRawValue(),
      ...actions
    }
    options.driver_phone = options.driver_phone ? options.driver_phone.replace(/\D+/g, '') : null;
    options.schedule_link_checkinId = this.truckerLink && checkin ? checkin.id : "";
    options.cpf = options.cpf ? options.cpf.replace(/[.-]/g, '') : null;
    this.agendamentoService.dinamicScheduleUpdate(options).subscribe((r: any) => {
      this.loading = false;
      if(r.error) {
        if(r.error_type == 'fields_validation') {
          this.validates = r.msg;
        }
      } else {
        this.close();
      }
    });
  }


  canChangeStatus(schedule:any) {
    if (schedule.status == 'carrier_denied' || schedule.status == 'closed') {
      return false;
    }
    if (
      schedule.operation_id == 2 &&
      schedule.status == 'carrier_confirmed'
    ) {
      if (this.account.group_id == 13 || this.account.group_id == 23 || this.account.group_id == 93 || this.account.group_id == 190) {
        return true;
      }
      return false;
    }
    return true;
  }

  canCarrierConfirm(data:any) {
    if (
      data.action == 'edit' &&
      data.accessLevel == 'carrier' &&
      data.schedule.status == 'waiting' &&
      data.schedule.status != 'carrier_denied' &&
      data.schedule.status != 'carrier_confirmed' &&
      data.schedule.operation_id == 2
    ) {
      return true;
    }

    return false;
  }

  canCancel(data:any) {
    if (
      data.action == 'edit' &&
      (
        data.schedule.status == 'waiting' ||
        data.schedule.status == 'confirmed' ||
        data.schedule.status == 'carrier_confirmed' ||
        data.schedule.status == 'carrier_denied' ||
        data.schedule.status == 'denied'
      ) && (this.account.group_id === 13 || this.account.group_id === 93)
      ) {
      return true;
    }
    return false;
  }

  canEdit(data:any) {
    if (data.action == 'edit') {
      return true;
    }
    return false;
  }

  showButtons(status:string) {
    if (status == 'confirmed') {
      return false;
    }
    return true;
  }

  // ok
  deleteFile(item:any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.documentsService.delete(item).subscribe({
          next: (r: any) => {
            if (r.success) {
              const idx = this.documents.findIndex(v => v.fileName === item.fileName);
              this.documents.splice(idx, idx >= 0 ? 1 : 0);
              this.files.splice(idx, idx >= 0 ? 1 : 0);
            }
          }, error: (e: any) => {

          }
        });
      }
    });
  }

  // ok
  getFile(item: any): void {
    this.documentsService.downloadFile(item)
      .subscribe(
        (val) => {
          this.createFileFromBlob(val, item.fileName);
        },
        response => {

        },
        () => {

        });
  }

  createFileFromBlob(file: Blob, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  close() {
    this.dialogRef.close({action: 'dialog-close'})
  }

  dialogAction(action:any, message?:string) {
    const dialogRef = this.dialog.open(DialogActionsComponent, {
      panelClass: ['dialog-small'],
      disableClose: true,
      data: {
        item: action,
        msg: message
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      // Fecha diálogo
      console.log('fecha diálogo');
    });
  }

  // ok
  doCheckin() {

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma o registro de Check In?",
        message: `Tem certeza que deseja registrar o Check in para o motorista ${this.data.schedule.driver_name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const loginData = {
          cpf: this.data.schedule.cpf,
          password: null,
          resource: {
            operation: this.data.schedule.operation_id,
            board_horse: this.data.schedule.board_horse,
            board_cart: this.data.schedule.board_cart,
            entry_type: 0,
            checkin_origem: 'web'
          },
          system_client_id: this.data.schedule.system_client_id,
          driver_id: this.driver.driver_id
        }
        this.checkinService.create(loginData).subscribe((r: any) => {
          if (r.status === 200) {
            let action = 'checkinOk';
            if(r.error === true)
              action = 'checkinErrorBoard';

            this.dialogAction(action, r.message);
            this.disable_checkin_btn = true;
            this.close();
          } else {
            this.dialogAction('checkinErrorBoard', r.message)
          }
        });
      }
    });
  }

  setBoardCartValet(){
    this.truckerLink = true
    this.form.patchValue({truckerLink: this.truckerLink})
  }

  // ok
  setPlateAmount(): void {
    const vt = this.form.value.vehicle_type;

    const vtFiltered = this.vehicle_types.filter((item: any) => item.id == vt)[0];

    this.plates_fields = vtFiltered.fields;
    // Caso informe apenas 1 placa, remove do form a segunda (caso tenha sido definida)
    const plate_types = ['board_horse', 'board_cart'];

    const difference = plate_types
      .filter(x => !this.plates_fields.includes(x))
      .concat(this.plates_fields.filter(x => !plate_types.includes(x)));

    if (this.plates_fields.length === 1) {
      this.form.patchValue({[difference[0]]:null});
    }

    if(this.data.action == 'edit' && this.data.schedule.schedule_link_checkinId) {
      this.truckerLink = true;
      this.form.get('vehicle_type')?.disable();
    }

  }

  searchDriver(): void {
    const cpf = this.form.value.cpf ? this.form.value.cpf.replace(/[.-]/g, '') : null;
    this.driverService.showViaCpf({cpf}).subscribe((r:any) => {
      this.driver = r;
      if(this.driver.has_row){
        this.form.patchValue(
          {
            driver_name: this.driver.name,
            driver_phone: this.driver.resource.phone,
            driver_id: this.driver.id
          });
      }
    });
  }

  getTimeSlots() {
    const options = {
      day: this.definedDay,
      operation_id: this.form.value.operation_id ? this.form.value.operation_id : null,
      suboperation_id: this.form.value.suboperation_id ? this.form.value.suboperation_id : null,
    }
    this.agendamentoService.time_slots(options).subscribe((res: any) => {
      this.timeslots = res;
    });
  }

  loadUnlinkedCarts() {
    this.boardsFilter.valueChanges
      .pipe(
        startWith(""),
        debounceTime(500),
        /*filter(
          (search: any) => (search && "" + search.trim() != "") || search == "*"
        ),*/
        map((value: string) => value.replace("*", "")),
        switchMap((search) => {
          this.searching = true;
          const options = {
            orderBy: "name",
            sortedBy: "ASC",
            page: 1,
            pageSize: 10,
            search,
            schedule_driver_checkin_id: this.data.schedule.schedule_link_checkinId || null
          };
          return this.driverService.getUnlinkedDrivers(options).pipe(
            catchError(() => {
              this.searching = false;
              return observableOf([]);
            })
          );
        })
      )
      .subscribe((data) => {
        this.boardCarts = (data || []).filter(item => item);
      });
  }
}
