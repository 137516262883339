import { Component, Inject, OnInit} from '@angular/core';
import { DialogAlertComponent } from '../../shared/dialog-alert/dialog-alert.component';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../shared/account.service';
import { Account } from '../account';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  public form = new FormGroup({
    first_name: new FormControl('',[Validators.required]),
    last_name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.email]),
    password: new FormControl('',[Validators.required])
  });
  errors: any = {};
  hidePassword = true;

  constructor(
    public dialogRef: MatDialogRef<AccountDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    // private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
  ) { }

  onSubmit() {
    this.accountService.update(this.form.value, this.accountService.token().jti)
      .subscribe(
        data => {
          this.dialogRef.close(data);
        },
        err => {
          if (err.status === 422) {
            console.log(err);
            this.errors = err.error.errors;
          } else {
            this.dialog.open(DialogAlertComponent, {
              data: {title: err.statusText, message: err.error.message}
            });
          }
        }
      );
  }

  togglePassword() {
    if (this.form.get('password')!.disabled) {
      this.form.get('password')!.enable();
    } else {
      this.form.get('password')!.disable();
    }
  }

  ngOnInit() {
    const accountStorage: string = localStorage.getItem('account') || '';
    const account: Account = JSON.parse(accountStorage);

    // this.form = this.formBuilder.group({
    //   first_name: [account.first_name, [Validators.required]],
    //   last_name: [account.last_name, [Validators.required]],
    //   email: [account.email, [Validators.required, Validators.email]],
    //   password: ['', [Validators.required]],
    //   is_alert_mail: [account.is_alert_mail || false, [Validators.required]],
    //   is_alert_phone: [account.is_alert_phone || false, [Validators.required]],
    // });

    this.form.patchValue({
      first_name: account.first_name,
      last_name: account.last_name,
      email: account.email
    });

    // Start disabled
    this.togglePassword();
  }
}
