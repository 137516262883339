<app-loading-table *ngIf="showLoadingData"></app-loading-table>
<div class="message" *ngIf="showMessage">
  <p class="tkg-message">{{ message }}</p>
</div>
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z4">
  <app-paginate-sort-table
    *ngIf="!showLoadingData"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [totalItems]="totalItems"
    [columnsSchema]="columnsSchema"
    [actions]="action_list"
    (action)="action($event)"
    tableId="report_docks"
    [paginate]="paginate"
    (performOnChangePageOnParent)="performOnChangePage($event)"
    (performSortChangeOnParent)="performSortChange($event)"
  >
  </app-paginate-sort-table>
</div>
<button
  *ngIf="showAddButton"
  mat-mini-fab
  (click)="onClickAddButton()"
  class="mat-fab-bottom-right"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
