import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { CoreModule } from '../core/core.module';
import { LoginDetailComponent } from './login-detail/login-detail.component';
import { RegisterDetailComponent } from './register-detail/register-detail.component';
import { PasswordTokenDetailComponent } from './password-token-detail/password-token-detail.component';
import { PasswordResetDetailComponent } from './password-reset-detail/password-reset-detail.component';
import { AccountRoutingModule } from './account-routing.module';
import { DialogSwitchAccountComponent } from './dialog-switch-account/dialog-switch-account.component';
import { SharedModule } from '../shared/shared.module';
import { DialogReauthenticateComponent } from './dialog-reauthenticate/dialog-reauthenticate.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        AccountRoutingModule,
        SharedModule
    ],
    declarations: [
        AccountDetailComponent,
        LoginDetailComponent,
        RegisterDetailComponent,
        PasswordTokenDetailComponent,
        PasswordResetDetailComponent,
        DialogSwitchAccountComponent,
        DialogReauthenticateComponent,
    ]
})
export class AccountModule { }
