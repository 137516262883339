
<div class="container">
  <button mat-icon-button (click)="closeSnackbar()">
    <mat-icon style="color: black;">close</mat-icon>
  </button>
  <div class="title_content">
    <h5>Olá, {{account.full_name}}</h5>
  </div>
  <div class="title text">{{ data[0]?.resource?.title }}</div>
  <div class="text">{{ data[0]?.resource?.body }}</div>
</div>
