import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportDynamicService } from '../shared/report-dynamic.service';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { FileTypeSelectorComponent } from '../file-type-selector/file-type-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { EmailReportDialogV1Component } from 'src/app/report-dynamic/email-report-dialog-v1/email-report-dialog-v1.component';

interface DOMEvent<T extends EventTarget> extends Event {
  readonly target: T
}
interface Report {
  title: string;
  translation_title?: string;
  type?: string;
  router?: string;
  download_source?: string;
  order?: number;
  file_name?: string;
  show?: boolean;
}
const defaultReports:Report[] = [
  {
    translation_title: "Business_model.checkin_report",
    type: 'checkin',
    title: "Relatório de Check-in",
    router: "/admin/report-dynamic/checkin",
    order: 1
  },
  {
    translation_title: "Business_model.schedule_report",
    type: 'scheduling',
    title: "Relatório de Agendamentos",
    router: "/admin/report-dynamic/scheduling",
    order: 2
  },
  {
    translation_title: "Business_model.dock_report",
    type: 'docks',
    title: "Relatório de Docas",
    router: "/admin/report-list/report-docks",
    order: 3
  }
];
@Component({
  selector: 'app-reports',
  templateUrl: './reports-dynamic-painel.component.html',
  styleUrls: ['./reports-dynamic-painel.component.scss']
})
export class ReportsDynamicPainelComponent implements OnInit {
  reports:Report[]=[];
  account:any = {};
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private supportDataService:SupportDataService,
    private reportDynamicService: ReportDynamicService
  ) { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.supportDataService.reportGeneric()
    .subscribe((data)=>{
      this.reports = defaultReports
      .filter((reportA:Report)=>!(data as Report[]).some((reportB:Report)=>reportB.router == reportA.router))
      .concat(...(data as Report[]))
      .filter(item=>item.show != false)
      .sort((a,b)=>{
        if(!a.order || !b.order)
          return 10000

        return (a.order - b.order);
      });
    })
  }

  //Abre o modal para solicitar o relatório por e-mail
  generateEmailReport(type?: string) {

    if (type) {
      const dialogRef = this.dialog.open(EmailReportDialogV1Component, {
        width: "900px",
        data: {
          type: type
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.status == 200) {
            this.dialog.open(DialogAlertComponent, {
              panelClass: ["dialog-medium"],
              data: {
                dialog_type: "report_email_sent",
                message: result.message || "Relatório enviado por e-mail"
              }
            })
          } else if (result.status == 422) {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: "Erro",
                dialog_type: "error",
                message: result.message || "Erro ao criar o relatório"
              }
            })
          }
        }
      })
    }
  }

  downloadReport($event:MouseEvent|DOMEvent<HTMLElement>, report:Report){
    this.dialogFileType().subscribe((fileType)=>{
      if(report.download_source && fileType && ['csv','xlsx'].includes(fileType)){
        this.reportDynamicService
        .reportGeneric(report.download_source, {file_type:fileType})
        .subscribe({
          next:(data:Blob)=>{
            const fileName = (report.file_name || (`${report.title}-${Math.random().toString(36).slice(2, 7)}`))+"."+fileType;
            this.downloadFileFromBlob(data, fileName)
          },
          error:async(error)=>{
            let message = this.translate.instant("Messages.erro_download", {Default:"Ocorreu um erro durante a exportação do relatório."});
            if(error.status == 422){
              try{
                message = JSON.parse(await error.error.text()).message;
              }catch(ex){}
            }
            this.dialog.open(DialogAlertComponent, {
              data: {title: this.translate.instant("Business_model.report_download", {Default:"Download de relatório"}), message}
            });
          }
        })
      }
    })
  }

  private downloadFileFromBlob(dataFile:Blob, fileName:string) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = window.URL.createObjectURL(dataFile);
    a.setAttribute("download", fileName);

    document.body.appendChild(a).click();

    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  private dialogFileType(): Observable<string | void | undefined>{
    return this.dialog.open<FileTypeSelectorComponent, any, string|void>(FileTypeSelectorComponent, {
      panelClass: ['dialog-small'],
      disableClose: false,
      data: {
        title: this.translate.instant("Messages.export_file", {Default:"Exportar arquivo como..."}),
        item: {},
      },
    })
    .afterClosed()
  }
}
