import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckinListComponent } from './checkin-list/checkin-list.component';
import { CheckinListMobileComponent } from './checkin-list-mobile/checkin-list-mobile.component';
import { AccountGuardService } from '../account/shared/account-guard.service';
import { RouteGuard } from '../account/shared/route.guard';

const checkinRoutes: Routes = [
  // {
  //   canActivate: [AccountGuardService, RouteGuard],
  //   runGuardsAndResolvers: 'always',
  //   path: 'admin/checkins/:page',
  //   component: CheckinListComponent,
  //   data: {
  //     title: 'Portaria',
  //     search: false,
  //     refresh: false
  //   }
  // }
  {
    path: 'admin',
    canActivate: [AccountGuardService, RouteGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'checkins/:page',
        component: CheckinListComponent,
        data: {
          title: '',
          search: false,
          refresh: false,
          filter: false
        }
      },
      {
        path: 'checkins-mobile/:page',
        component: CheckinListMobileComponent,
        data: {
          title: 'Check in',
          search: true,
          refresh: false,
          filter: true
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(checkinRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CheckinRoutingModule { }
