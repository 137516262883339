<form [formGroup]="form">
  <h2 mat-dialog-title>{{ data.action === 'EDIT' ? 'Editar ' : 'Adicionar '}}</h2>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="outline" class="tkg-input-select">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
  </mat-dialog-content>
  </form>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>Cancelar</button>
    <button type="submit" mat-button color="primary" [disabled]="false" (click)="submit()">Salvar</button>
  </mat-dialog-actions>
