import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'formly-input-seach-driver',
  templateUrl: './formly-input-search-driver.component.html',
  styleUrls: ['./formly-input-search-driver.component.scss']
})

export class FormlyInputSearchDriverComponent extends FieldType<any> implements OnInit{

  formControlFilter = new FormControl();
  listOptions:any[] = [];
  searching = false;

  ngOnInit(): void {
    if(this.props.service){
      const {
        startWith:searchValue
      } = this.props.service;

      this.formControlFilter.valueChanges.pipe(
        startWith(searchValue),
        debounceTime(1000),
        tap(()=>this.searching = true)
      ).subscribe((value:string)=>{
        this.props.service.service(value)
        .pipe(tap(()=>this.searching = false))
        .subscribe((options:any[])=>{
          this.listOptions = [this.formControl.value, ...options].filter(i=>i);
          this.formControl.setValue(this.formControl.value, {emitEvent:false});
        })
      })
    }

    this.formControl.setValue(this.field.defaultValue);
  }

  formatCpf(cpf: string){
    if (!cpf) return 'Sem CPF cadastrado.';
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

}
