import { Component, Input } from '@angular/core';
import { HealthCheckService } from '../shared/health-check.service';
import moment from 'moment';

@Component({
  selector: 'app-health-check-notification',
  templateUrl: './health-check-notification.component.html',
  styleUrls: ['./health-check-notification.component.scss']
})
export class HealthCheckNotificationComponent {
  public showNotification = true;

  topIncidents: any;
  account: any;
  notification: any;
  activeNotification: any;
  constructor(public healthCheckService: HealthCheckService) {
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem('account')!);
    this.getActiveNotification();
  }

  closeNotification() {
    this.healthCheckService.disableUserNotification(this.account.id).subscribe((r: any) => {
      this.activeNotification = r;
    });
    this.showNotification = false;
  }

  formatDate(date: any){
    let newDate = moment(date).format('DD/MM/YYYY');
    return newDate
  }

  getActiveNotification() {
    this.healthCheckService.getActiveNotification().subscribe((r: any) => {
      this.notification = r.notification;
      if(this.notification)
       this.getTopIncidents();
    });
  }

  getTopIncidents() {
    this.healthCheckService.getTopIncidents().subscribe((r: any) => {
      this.topIncidents = r;
    });
  }
}
