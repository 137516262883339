
<h2 mat-dialog-title>Filtros</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field class="example-full-width ptop16" appearance="outline" *ngIf="showCarrierFilter">
    <mat-label>Transportadora</mat-label>
    <input type="text" matInput [formControl]="carrierFilter" [matAutocomplete]="auto1">
    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let carrier of filteredOptionsCarrier | async" [value]="carrier">
        {{carrier.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Status</mat-label>
    <mat-select multiple [formControl]="statusFilter">
      <mat-option *ngFor="let status of statusList" [value]="status.id">{{status.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Operação</mat-label>
    <mat-select multiple [formControl]="operationFilter">
      <mat-option *ngFor="let operation of operationList" [value]="operation.id">{{operation.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Docas</mat-label>
    <mat-select multiple [formControl]="docksFilter">
      <mat-option *ngFor="let docks of docksList" [value]="docks.id">{{docks.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="warn" class="tkg-btn">X</button>
  <button mat-flat-button mat-dialog-close (click)="clearFilters()" color="accent" class="tkg-btn-accent tkg-btn">Limpar</button>
  <button mat-flat-button cdkFocusInitial class="tkg-btn-primary tkg-btn" (click)="applyFilters()">Aplicar</button>
</mat-dialog-actions>
