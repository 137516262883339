import { Component, OnInit } from '@angular/core';
import { CardInterface } from './card.interface';

@Component({
  selector: 'app-card-not-found',
  template: `
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Sorry, card not found.</mat-card-title>
        <mat-card-subtitle>System client card configuration mismatch.</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="assets/img/tkg_maestro.png" alt="Image">
      <mat-card-content>
        <p>
          We are working to fix it.
        </p>
      </mat-card-content>
    </mat-card>
  `,
  styles: [`
    .example-card {
      max-width: 400px;
      padding: 8px 12px;
    }
  `,
  `.mat-card-image{
    width: 300px;
    height: 40px;
    display: block;
    margin: 0 0 20px 0;
  }`,
  `
    .example-header-image {
      background-image: url('/assets/img/logo-maestro@2x.png');
      background-size: cover;
    }
  `]
})
export class CardNotFoundComponent implements OnInit, CardInterface {
  layout!: number;
  data: any;
  options!: Object[];
  constructor() { }

  ngOnInit() {
  }

}
