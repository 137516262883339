<div class="tkg-title-group">
  <p class="tkg-title">Regras adicionais para transportadoras</p>
  <button
    mat-button
    mat-stroked-button
    color="primary"
    class="tkg-new-btn"
    (click)="addEditRuleCarrier()"
  >
    Nova
  </button>
</div>
<table class="tkg-table">
  <thead>
    <tr>
      <th>Transportadora<br /></th>
      <th>Regra<br /></th>
      <th style="width: 80px; text-align: center">Ações<br /></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of rules">
      <td>{{ item.name }}</td>
      <td>{{ item.rule_name }}</td>
      <td>
        <button mat-icon-button color="primary" (click)="addEditRuleCarrier(item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="deleteRuleCarrier(item.id)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="rules.length === 0">
      <td colspan="100%">
        <p class="no-record">Nenhum registro encontrado!</p>
      </td>
    </tr>
  </tbody>
</table>
