import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GenericComponent } from './generic/generic.component';
import { MicroServicesRoutingModule } from './micro-services-routing.module';
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';

@NgModule({
  declarations: [
    GenericComponent,
    GenericDialogComponent
  ],
  imports: [
    CommonModule,
    MicroServicesRoutingModule
  ],
  entryComponents:[
    GenericDialogComponent
  ]
})
export class MicroServicesModule { }
