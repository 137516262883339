import moment from "moment";
import { Observable, delay, of, switchMap, tap } from 'rxjs';
import { Router } from "@angular/router";
import { KeycloakConfig } from "keycloak-js";
import { KeycloakEventType, KeycloakOptions, KeycloakService } from "keycloak-angular";

import { environment } from "../../environments/environment";
import { AccountService } from "../account/shared/account.service";

interface RealmAccess {
  access?:{
    userId: string;
    sessionId: string;
    hash: string;
    realm: string;
    clientId: string;
    user: string;
    state: "keycloak-waiting-validation" | "keycloak-accepted" | "keycloak-failed";
    logged: boolean,
    logged_at?: string
  },
  accessedRealms:NonNullable<RealmAccess["access"]>[]
}

export default class KeycloakInitializer{
  private static keycloak: KeycloakService;
  private static accountService: AccountService;
  private static memoAccess: RealmAccess["access"];

  private static config: KeycloakConfig = {
    url: environment.keycloak.url,
    realm: 'master',
    clientId: environment.keycloak.clientId
  };

  private static options: KeycloakOptions & { config: KeycloakConfig } = {
    enableBearerInterceptor: true,
    config: KeycloakInitializer.config as KeycloakConfig,
    initOptions: {
      onLoad: 'check-sso',
      redirectUri: environment.keycloak.redirectUri,
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      checkLoginIframe: false
    },
    loadUserProfileAtStartUp: false
  };

  static get realmAccess():RealmAccess{
    const realmAccess = localStorage.getItem("realm_access");

    const realm = (
      realmAccess
      ? JSON.parse(realmAccess)
      : { accessedRealms:[] }
      ) as RealmAccess;

    return realm;
  }

  private static set realmAccess(value:RealmAccess){
    localStorage.setItem("realm_access", JSON.stringify(value));
    KeycloakInitializer.memoAccess = value.access;
  }

  static initializer(keycloak: KeycloakService, accountService: AccountService, router:Router){
    KeycloakInitializer.visibilityChange();

    KeycloakInitializer.keycloak = keycloak;
    KeycloakInitializer.accountService = accountService;

    const realmAccess = KeycloakInitializer.realmAccess;
    const access = realmAccess.access;

    if(!access?.realm)
      return ()=>Promise.any([false]);

    keycloak.keycloakEvents$.subscribe({
      next(event) {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken(30).then();
        }else if (event.type == KeycloakEventType.OnAuthRefreshError) {
          accountService.event.emit({action:'reauthenticate'});
        }
      },
      error:(ex:any)=>{}
    })

    KeycloakInitializer.options.config.realm = access.realm;
    KeycloakInitializer.options.config.clientId = access.clientId;
    KeycloakInitializer.options.initOptions!.redirectUri = environment.keycloak.redirectUri + window.location.pathname

    return ()=>keycloak.init({...KeycloakInitializer.options})
    .then((value)=>{
      if(!value){
        KeycloakInitializer.logout();
      }

      if(value && KeycloakInitializer.isAuthenticated()){
        access.logged = true;
        access.state = "keycloak-accepted";
        access.userId = keycloak.getKeycloakInstance()?.profile?.id || "";
        access.sessionId = keycloak.getKeycloakInstance()?.sessionId || "";
        let accessIndex = realmAccess.accessedRealms.findIndex(item=>item?.hash === access.hash);

        if(accessIndex == -1){
          realmAccess.accessedRealms.push({...access, logged_at: moment().format('DD/MM/YYYY HH:mm')});
        }else{
          realmAccess.accessedRealms[accessIndex] = {...access};
        }

        KeycloakInitializer.realmAccess = realmAccess;

        keycloak.getKeycloakInstance().onTokenExpired = ()=>{
          accountService.event.emit({action:'reauthenticate'});
        }
      }
    })
    .catch((ex:any)=>{
      access.state = "keycloak-failed";
      let accessIndex = realmAccess.accessedRealms.findIndex(item=>item?.hash === access.hash);

      if(accessIndex == -1){
        realmAccess.accessedRealms.push({...access});
      }else{
        realmAccess.accessedRealms[accessIndex] = {...access};
      }

      KeycloakInitializer.realmAccess = realmAccess;
    });
  }

  static token():string{
    if(!KeycloakInitializer.isAuthenticated())
      return "";

    if(KeycloakInitializer.keycloak.isTokenExpired(30))
      KeycloakInitializer.keycloak.updateToken(30).then();

    return KeycloakInitializer.keycloak?.getKeycloakInstance().token || "";
  }

  static async login(realm:string, clientId:string, loginHint:string, urlRedirect="/"){
    const realmAccess = KeycloakInitializer.realmAccess;
    let access:any = realmAccess.access || {};

    if(!(access?.clientId == clientId && access?.realm == realm && access?.user == loginHint)){
      access = realmAccess.accessedRealms
      .find((access)=>access.clientId == clientId && access.realm == realm && access.user == loginHint);

      if(!access){
        access = {
          hash: (Math.random()*1e32).toString(36),
          realm,
          clientId,
          user: loginHint,
        };
      }
    }

    access.logged = false;
    access.state = "keycloak-waiting-validation";

    realmAccess.access = access;

    KeycloakInitializer.realmAccess = realmAccess;

    KeycloakInitializer.options.config.realm = realm;

    KeycloakInitializer.accountService.clearAccount();

    KeycloakInitializer.options.initOptions!.redirectUri = window.location.origin + urlRedirect  + "#skip-route-access";

    const login$ = new Observable((subscription)=>{
      const keycloakLogin = ()=>{
        const options = {...KeycloakInitializer.options};

        of(KeycloakInitializer.keycloak.init(options).then(()=>{}))
        .pipe(delay(100))
        .pipe(switchMap(()=>{
            const keycloakInstance = KeycloakInitializer.keycloak.getKeycloakInstance();
            const url = keycloakInstance?.createLoginUrl({
              ...options.initOptions,
              redirectUri: window.location.origin + urlRedirect + "#skip-route-access",
              loginHint
            });
            return of(url);
        }))
        .pipe(tap((url:string)=>{
          //Cookie -> KC_RESTART
          try{
            const iframe:NodeListOf<HTMLIFrameElement> | null = document.querySelectorAll(`iframe[src*="${environment.keycloak.url}"]`);
            if(iframe)
              iframe[0].src = url;

          }catch(ex){}
        }))
        .pipe(delay(100))
        .subscribe((url:string)=>{
          window.location.assign(url);
        });
      }
      /*
        Não é possível termos dois usuarios acessando o mesmo realm e client
      */
      const acessInUse = realmAccess.accessedRealms.find((access)=>{
        return access.realm == realm && access.clientId == clientId && access.user != loginHint
      })

      if(acessInUse?.logged){
        /**
        * Realizar o Logout para a troca de usuário
        */
        KeycloakInitializer.accountService
        .logoutUserKeycloak(acessInUse.realm, acessInUse.sessionId)
        .subscribe(()=>{
          realmAccess.accessedRealms = realmAccess.accessedRealms.filter(item=>item.hash != acessInUse.hash);
          KeycloakInitializer.realmAccess = realmAccess;
          subscription.next(keycloakLogin());
          subscription.complete()
        })
      }else{
        subscription.next(keycloakLogin());
        subscription.complete()
      }
    })

    login$.subscribe();

    return login$
  }

  static logout():Promise<void>{
    const realmAccess = KeycloakInitializer.realmAccess;

    realmAccess.accessedRealms = realmAccess.accessedRealms.filter(item=>item.hash != realmAccess.access?.hash);

    const redirectUri =  realmAccess.accessedRealms.reduce(
      (pivot:undefined|string, item)=>{
        if(!pivot && item.user === realmAccess.access?.user && item.logged)
          pivot = window.location.origin + '/auth/login#' + item.user;
        return pivot
      },
      undefined
    );

    delete realmAccess.access;

    KeycloakInitializer.realmAccess = realmAccess;

    return KeycloakInitializer.keycloak.logout(redirectUri || (window.location.origin + '/auth/login'));
  }

  static isAuthenticated(){
    return Boolean(KeycloakInitializer.keycloak?.getKeycloakInstance()?.authenticated);
  }

  private static visibilityChange(){
    document.addEventListener("visibilitychange", ()=>{
      if (document.visibilityState === 'visible') {
        if(KeycloakInitializer.memoAccess?.hash){
          if(KeycloakInitializer.memoAccess?.hash != KeycloakInitializer.realmAccess.access?.hash){
            window.location.href = window.location.origin + "#skip-route-access";
          }
        }
      }
    });
  }

}
