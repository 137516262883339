<div class="iframe-modal">
  <iframe
    *ngIf="iframeSrc"
    #iframeService
    [src]="iframeSrc"
    frameBorder="0"
    class="iframe-main"
  >
  </iframe>
</div>
