import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxTimelineEvent } from "../../timelines.interface";
import { NgxTimelineEventChangeSideInGroup } from "@frxjs/ngx-timeline";
import { TimelinesService } from "../../services/timelines.service";

@Component({
  selector: "app-journey-status-timeline-dialog",
  templateUrl: "./journey-status-timeline-dialog.component.html",
  styleUrls: ["./journey-status-timeline-dialog.component.scss"],
})
export class JourneyStatusTimelineDialogComponent implements OnInit {
  langCode = "pt";
  enableAnimation = true;
  changeSideInGroup = NgxTimelineEventChangeSideInGroup.ALL;
  events: NgxTimelineEvent[] = [];
  headerData: any;
  tlData: any = [];

  constructor(
    private timelineService: TimelinesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JourneyStatusTimelineDialogComponent>,
  ) {
    console.log("------");
    console.log(this.data);
  }

  ngOnInit(): void {
    this.timelineService
      .get<any>(`/checkins/timeline/${this.data?.data?.checkin_id ?? -1}`)
      .subscribe({
        next: (r = {}) => {
          // console.log(data.data);
          this.tlData = r.data;
          this.tlData.events.map((i: any) => {
            i.timestamp = new Date(i.timestamp);
          });
          this.events = (this.tlData.events ?? []) as NgxTimelineEvent[];
        },
        error: () => {
          this.events = (this.tlData?.data?.events ?? []) as NgxTimelineEvent[];
        },
      });
  }
}
