import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { ScheduleWindowByWeightDialogComponent } from "../schedule-window-by-weight-dialog/schedule-window-by-weight-dialog.component";
import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";

@Component({
  selector: "app-schedule-window-by-weight",
  templateUrl: "./schedule-window-by-weight.component.html",
  styleUrls: ["./schedule-window-by-weight.component.scss"],
})
export class ScheduleWindowByWeightComponent {
  windows: any[] = [];
  @Input() hiddenElements: any;

  constructor(
    private dialog: MatDialog,
    private agendamentoService: AgendamentoService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.getScheduleWindows();
  }

  addEdit(item?: any) {
    const dialogRef = this.dialog.open(ScheduleWindowByWeightDialogComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: { ...item },
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.getScheduleWindows();
    });
  }

  getScheduleWindows() {
    this.agendamentoService
      .indexSchedulingWindowByWeight()
      .subscribe((r: any) => {
        this.windows = r.data;
      });
  }

  formatTimeSlot(minutes: string) {
    const transform: { [key: string]: string } = {
      "5":
        "5 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "10":
        "10 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "15":
        "15 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "20":
        "20 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "25":
        "25 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "30":
        "30 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "40":
        "40 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "45":
        "45 " + this.translate.instant("Time.minutes", { Default: "minutos" }),
      "60": "1 " + this.translate.instant("Time.hour", { Default: "hora" }),
      "120": "2 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "180": "3 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "240": "4 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "300": "5 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "360": "6 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "480": "8 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "600": "10 " + this.translate.instant("Time.hours", { Default: "horas" }),
      "720": "12 " + this.translate.instant("Time.hours", { Default: "horas" }),
    };

    return transform[minutes];
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title:
          this.translate.instant("Business_model.attention_upper", {
            Default: "ATENÇÃO",
          }) + "!",
        message: this.translate.instant(
          "Business_model.delete_window_schedule",
          { Default: "Tem certeza que deseja APAGAR a janela de agendamento?" },
        ),
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.agendamentoService
          .updateScheduleWindowByWeight(id)
          .subscribe((r: any) => {
            if (!r.error) {
              this.getScheduleWindows();
            }
          });
      }
    });
  }
}
