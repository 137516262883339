import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { AccountService } from './account.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  matchRouter: any;
  // isWeb: boolean;
  deviceType: string;
  constructor(
    private accountService: AccountService,
    private dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    // this.breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
    //   if (result.matches) {
    //     this.isWeb = true;
    //   } else {
    //     this.isWeb = false;
    //   }
    // });
    this.breakpointObserver
    .observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Web,
      Breakpoints.XSmall,
    ])
    .subscribe((result) => {
      if (result.matches) {
        this.activateLayout();
      }
    });
  }
  activateLayout() {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      // console.log('smart phone');
      this.deviceType = 'handset';
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      // console.log('tablet portrait');
      this.deviceType = 'tablet';
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      // console.log('tablet landscape');
      this.deviceType = 'tablet';

    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.deviceType = 'web';
    }
}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const identity = await this.accountService.identity();
    const routes = identity.menu.map((item: any) => {
      //old menu
      if (item.resource?.route) {
        let itemRoute;
        if(this.deviceType == 'web') {
          itemRoute = item.resource.route;
        }
        if(this.deviceType == 'tablet') {
          itemRoute = item.resource.route_mobile;
        }
        if(this.deviceType == 'handset') {
          itemRoute = item.resource.route_handset;
        }

        return itemRoute;
      }

      //new menu
      if (item.routes instanceof Array) {
        return item.routes.map((item: any) => {
          let itemRoute;
          if(this.deviceType == 'web') {
            itemRoute = item.resource.route;
          }
          if(this.deviceType == 'tablet') {
            itemRoute = item.resource.route_mobile;
          }
          if(this.deviceType == 'handset') {
            itemRoute = item.resource.route_handset;
          }

          return itemRoute;
        });
      }
    }).flat(1);

    const matchRoute = routes.find((item: any) => item == state.url.replace(/^\//, ''));
    const fragment = state.url.match("#skip-route-access");
    if (!!matchRoute || fragment)
      return true

    await this.dialog.open(DialogAlertComponent, {
      data: {title: "Ops! Tem algo errado", message: "Desculpe. Você será redirecionado, acesso a página não autorizado."}
    }).afterClosed().subscribe((result: any) =>{
      this.router.navigate([`/${routes[0]}`])
      return result
    })
    return false
  }

}
