import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AccountService } from '../account/shared/account.service';
import { CommonModule } from '@angular/common';

@Component({
  imports:[MatIconModule, CommonModule],
  standalone:true,
  selector: 'app-error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.scss']
})
export class ErrorNotFoundComponent implements OnInit, OnDestroy  {
  private dialogRef:MatDialogRef<DialogBackdrop>;
  loading = false;

  constructor(
    public dialog: MatDialog,
    private accountService:AccountService
  ) { }

  ngOnDestroy(): void {
    this.dialogRef?.close();
  }

  ngOnInit() {
    if(this.accountService.isAuthenticated()){
      this.loading = true;
      this.dialogRef = this.dialog.open(DialogBackdrop, {panelClass: 'transparent'});
    }
  }

}

@Component({
  selector: 'dialog-backdrop',
  templateUrl: './dialog-backdrop.component.html',
  standalone: true,
  imports: [MatProgressSpinnerModule],

})
export class DialogBackdrop {
  constructor(public dialogRef: MatDialogRef<DialogBackdrop>) {}
}
