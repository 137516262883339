<h2 class="mat-align-center" mat-dialog-title>{{data.action.name}}
</h2>

<div mat-dialog-content>
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>
</div>


<div mat-dialog-actions align="end">
  <button type="button" mat-button color="warn" mat-dialog-close="true">Cancelar</button>
  <button type="submit" mat-raised-button cdkFocusInitial style="color: white;" color="primary" (click)="onSubmit()">Salvar</button>
</div>
