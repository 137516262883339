import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { SupportDataService } from "src/app/shared/support-data.service";
import { ChecklistService } from "../../shared/checklist.service";
import { UntypedFormBuilder } from "@angular/forms";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
@Component({
  selector: "app-other-motives-generic-dialog",
  templateUrl: "./other-motives-generic-dialog.component.html",
  styleUrls: ["./other-motives-generic-dialog.component.scss"],
})
export class OtherMotivesGenericDialogComponent implements OnInit {
  listReasons: any[] = [];
  dialogResult: any;
  selectedReasons: any[] = [];
  showComponent: boolean = false;
  form = this.formBuilder.group({
    newReason: [""],
  });

  constructor(
    public checklistService: ChecklistService,
    public checkinService: CheckinService,
    public supportData: SupportDataService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<OtherMotivesGenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.getReasonsList();
  }

  getReasonsList() {
    this.supportData
      .generic({ name: [this.data.action.component_schema[0].key] })
      .subscribe((r: any) => {
        this.listReasons = r.data.resource;

        if (this.listReasons) {
          this.showComponent = true;
        } else {
          this.showComponent = false;
        }
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    let exceptionsArray: any[] = [];
    let otherExceptionsArray: any[] = [];
    let toConcat: any[] = [];

    toConcat
      .concat(this.selectedReasons!)
      .filter((x) => x)
      .forEach((item: any) => {
        if (item.value) {
          exceptionsArray.push(item.value);
        } else if (item.id) {
          otherExceptionsArray.push(item.label);
        }
      });

    this.dialogResult = {
      [this.data.action.component_schema[0].key]: exceptionsArray,
      id: this.data.item.id,
      message: this.data.action.dialogue_message,
    };

    if (this.data.action.component_model.status)
      this.dialogResult.status = this.data.action.component_model.status;

    if (this.data.action.multiple === false && exceptionsArray[0])
      this.dialogResult[this.data.action.component_schema[0].key] =
        exceptionsArray[0];

    if (otherExceptionsArray.length > 0) {
      this.dialogResult["other_" + this.data.action.component_schema[0].key] =
        otherExceptionsArray;
    }

    this.dialogResult.id = this.data.item.id;
    this.dialogResult.message = this.data.action.dialogue_message;

    const updateFiled = () =>
      this.checkinService
        .updateField(this.data.action.editKeysAPI, this.dialogResult)
        .subscribe((r: any) => {
          this.dialog
            .open(DialogAlertComponent, {
              panelClass: ["dialog-small"],
              disableClose: true,
              data: {
                title: "Check in ações",
                dialog_type: r.status == 200 ? "ok_only" : "error",
                message: r.status == 200 ? "Sucesso!" : "Erro ao registrar.",
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.data.refresh && this.data.refresh();
            });
        });

    if (this.data.action.alert_type) {
      const dialogRef = this.dialog.open(DialogAlertComponent, {
        panelClass: ["dialog-small"],
        disableClose: true,
        data: {
          dialog_type: this.data.action.alert_type,
          message: this.dialogResult.message,
        },
      });

      dialogRef.beforeClosed().subscribe((res) => {
        if (res === "ok") {
          this.dialogRef.close();
          updateFiled();
        } else {
          this.dialogRef.close();
        }
      });
    } else {
      updateFiled();
      this.dialogRef.close(this.dialogResult);
    }
  }

  addNewReason(): void {
    const newItem = {
      id: this.form.value.newReason,
      label: this.form.value.newReason,
    };
    this.listReasons.push(newItem);
  }
}
