import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class SystemNotificationsService {
  constructor(private http: HttpClient) { }

  notifications() {
    const url = `${environment.apiUrl}/systemnotifications`;
    return this.http.get(url).pipe(
      map((data: Response | any) => data || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

}
