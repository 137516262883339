import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-resource-name',
  templateUrl: './dialog-resource-name.component.html',
  styleUrls: ['./dialog-resource-name.component.scss']
})
export class DialogResourceNameComponent {
  nameFormControl = new FormControl('', Validators.required);
  constructor(
    public dialogRef: MatDialogRef<DialogResourceNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.nameFormControl.value)
  }
}
