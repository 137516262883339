import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { Component, Inject, OnDestroy } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { Subscription } from "rxjs";
@Component({
  selector: "app-bottom-sheet-actions",
  templateUrl: "./bottom-sheet-actions.component.html",
  styleUrls: ["./bottom-sheet-actions.component.scss"],
})
export class BottomSheetActionsComponent implements OnDestroy {
  dataInfo: any;
  actionsList: any[] = [];
  driver: any;
  status: any;
  details: string = "";
  checkin: any;
  schema: any;
  showData: boolean = false;
  checkinData: Subscription;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private checkinService: CheckinService,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetActionsComponent>,
  ) {
    this.data = JSON.parse(this.data);
    this.dataInfo = this.data.info;
    this.getCheckin();
  }

  getCheckin() {
    this.checkinData = this.checkinService
      .checkinItem(this.data.id, {})
      .subscribe((r: any) => {
        this.checkin = r.data[0];
        this.schema = r.schema;

        this.formatActions();
        this.formatDetails();
      });
  }

  setAction(action: any): void {
    this.bottomSheetRef.dismiss(action);
  }

  formatDetails() {
    let values: any = [];
    this.schema.forEach((i: any) => {
      if (this.checkin[i.key]) {
        if (this.checkin[i.key].length > 0) {
          values.push(`${i.label}: ${this.checkin[i.key].toUpperCase()}`);
        }
      }
    });
    this.details = values.join(" / ");
  }

  formatActions() {
    let status = this.checkin.status_id;
    let operation = parseInt(this.checkin.operation_id);
    this.data.action_list.forEach((item: any) => {
      if (
        (item.active_on_status.includes(status) ||
          item.active_on_status[0] == "*") &&
        item.show_on_operation.includes(operation)
      ) {
        this.actionsList.push(item);
      }
      this.showData = true;
    });
  }

  ngOnDestroy(): void {
    this.checkinData?.unsubscribe();
  }
}
