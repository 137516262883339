// import { animation } from "@angular/animations";
import { Component, Inject } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";

@Component({
  selector: "app-bp-add-dialog",
  templateUrl: "./bp-add-dialog.component.html",
  styleUrls: ["./bp-add-dialog.component.scss"],
})
export class BpAddDialogComponent {
  form = new FormGroup({
    name: new FormControl("", [Validators.required]),
    code: new FormControl(""),
    cpf_cnpj: new FormControl("", [Validators.required]),
    contacts: new FormArray([]),
  });
  errors: any = {};

  constructor(
    public dialogRef: MatDialogRef<BpAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private businessPartnerService: BusinessPartnerService,
  ) {
    console.log("modal bp");
    console.log(this.data);
    if (this.data.id) {
      console.log("edição");
      this.editForm();
    }
  }

  addContact(name = "", email = "") {
    let contacts = this.form.get("contacts") as FormArray;
    contacts.push(
      this.formBuilder.group({
        name: [name, [Validators.required]],
        email: [email, [Validators.required]],
      }),
    );
  }

  removeContact(i: any) {
    let contacts = this.form.get("contacts") as FormArray;
    contacts.removeAt(i);
  }

  editForm() {
    this.businessPartnerService.show(this.data.id).subscribe((r: any) => {
      const data = r.resource;
      let contacts = this.form.get("contacts") as FormArray;

      data.contacts.forEach((i: any) => {
        contacts.push(
          this.formBuilder.group({
            name: ["", [Validators.required]],
            email: ["", [Validators.required]],
          }),
        );
      });

      this.form.patchValue(data);
    });
  }

  conctactGroup() {}

  save() {
    let data = { ...this.form.value };
    console.log(data);
    if (this.data.id) {
      this.businessPartnerService
        .update({ ...data, id: this.data.id })
        .subscribe((r: any) => {
          this.dialogRef.close();
        });
    } else {
      if (this.data.action.hasOwnProperty("use_additional_info")) {
        const use_info: any[] = this.data.action.use_additional_info;
        use_info.forEach((item: any) => {
          Object.assign(data, item);
        });
      }
      this.businessPartnerService.create(data).subscribe((r: any) => {
        this.dialogRef.close();
      });
    }
  }
}
